import { useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  disablePortalUsers,
  enablePortalUsers,
  portalUsers,
  unbindPortalUsers,
} from "../../../api/portalUsers";
import { CustomPagination } from "../../../components/CustomPagination";
import DialogInOne from "../../../components/DialogInOne";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpTextButton, { sxOpTextButton } from "../../../components/OpTextButton";
import YubiPrompter from "../../../components/Prompter";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import useYubiPrompt from "../../../hooks/useYubiPrompt";
import {
  dataGridDefaults,
  enumPortalUserOperStatus,
  enumPortalUserYubiKeyStatus,
  FeatureCodes,
} from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./UserApproval";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "userApprovalManagement";
const Pkey = FeatureCodes.tools.UserApprovalManagement.TabUserApproval;
const api = {
  portalUsers,
  enablePortalUsers,
  disablePortalUsers,
  unbindPortalUsers,
};
export default function UserApprovalList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
}) {
  const { hasPermission } = usePermission();
  const { t, te } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping } = props;
  const listRes = useQuery({
    queryKey: ["userApproval", zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.portalUsers(zusParams.body),
  });
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { rows = [], count = 0 } = listRes.data || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });
  const operationBtns = (params: GridRenderCellParams) => {
    const { oper_status = "", yubi_key_status = "" } =
      params.row?.metaData || {};
    const isDisabled = {
      enableBtn: enumPortalUserOperStatus.Enabled === oper_status,
      disableBtn: enumPortalUserOperStatus.Disabled === oper_status,
      unbindBtn:
        enumPortalUserYubiKeyStatus.Unbound === yubi_key_status ||
        enumPortalUserYubiKeyStatus.Pending === yubi_key_status,
    };
    const { email = "" } = params.row;
    return (
      <Box sx={sxOpTextButton}>
        <OpTextButton
          text={t("enable")}
          isHidden={!hasPermission(Pkey.EnableApprovalPermission)}
          isDisabled={isDisabled.enableBtn}
          onClick={() =>
            zusDialog.open("operationDialog", { dialogName: "enable", email })
          }
        />
        <OpTextButton
          text={t("disable")}
          isHidden={!hasPermission(Pkey.DisableApprovalPermission)}
          isDisabled={isDisabled.disableBtn}
          onClick={() =>
            zusDialog.open("operationDialog", {
              dialogName: "disable",
              email,
            })
          }
        />
        <OpTextButton
          text={t("unbind")}
          isHidden={!hasPermission(Pkey.UnbindYubikey)}
          isDisabled={isDisabled.unbindBtn}
          onClick={() =>
            zusDialog.open("operationDialog", { dialogName: "unbind", email })
          }
        />
      </Box>
    );
  };
  async function onConfirmEnable() {
    const apiRes = await getResAfterYubi(enablePortalUsers, {
      email: zusDialog.meta?.email,
    });

    if (!apiRes) return;
    alerting("success", t("enable_success"));
    listRes.refetch();
    zusDialog.closeAll();
  }
  async function onConfirmDisable() {
    const apiRes = await getResAfterYubi(api.disablePortalUsers, {
      email: zusDialog.meta?.email,
    });

    if (!apiRes) return;
    alerting("success", t("disable_success"));
    listRes.refetch();
    zusDialog.closeAll();
  }
  async function onConfirmUnbind() {
    const apiRes = await getResAfterYubi(api.unbindPortalUsers, {
      email: zusDialog.meta?.email,
    });

    if (!apiRes) return;
    alerting("success", t("unbind_success"));
    listRes.refetch();
    zusDialog.closeAll();
  }
  const columns: GridColDef[] = [
    useGenGridCol("email", t("login_email")),
    useGenGridCol("oper_status", t("approval_permission")),
    useGenGridCol("pub_id", t("yubi_id")),
    useGenGridCol("yubi_key_status", t("yubi_key_status")),
    useGenGridCol("1", t("operation"), {
      renderCell: operationBtns,
      minWidth: 250,
    }),
  ].filter((col) => {
    if (
      col.field === "1" &&
      !(
        hasPermission(Pkey.EnableApprovalPermission) ||
        hasPermission(Pkey.DisableApprovalPermission) ||
        hasPermission(Pkey.UnbindYubikey)
      )
    ) {
      return false;
    }
    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
      <YubiPrompter {...prompterConfig} />
      <LocalDialogs
        {...{
          onConfirmEnable,
          onConfirmDisable,
          onConfirmUnbind,
        }}
      />
    </>
  );
}
function LocalDialogs(props: any) {
  const { onConfirmEnable, onConfirmDisable, onConfirmUnbind } = props;
  const { t } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const dialogName: "enable" | "disable" | "unbind" | "" =
    zusDialog.meta?.dialogName;
  const dialogTitle = {
    "": "",
    enable: t("confirm_to_enable_approval_permission"),
    disable: t("confirm_to_disable_approval_permission"),
    unbind: t("confirm_to_unbind"),
  }[dialogName];
  const dialogOnConfirm = {
    "": () => {},
    enable: onConfirmEnable,
    disable: onConfirmDisable,
    unbind: onConfirmUnbind,
  }[dialogName];
  const dialogConfig = {
    title: dialogTitle,
    self: {
      open: zusDialog.match("operationDialog"),
      onClose: zusDialog.close,
    },
    content: (
      <div>
        {t("login_email")}: {zusDialog.meta?.email}
      </div>
    ),
    onCancel: zusDialog.close,
    onConfirm: dialogOnConfirm,
  } as const;
  return <DialogInOne {...dialogConfig} />;
}
