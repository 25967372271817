import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import EnumRiskLevel from '@wallet-manager/node-types/dist/types/postgres/const/RiskLevel';

import { getWalletManagement, listFace } from '../../../api/merchant';
import { MerchantWalletParamsFace } from '../../../api/types';
import { ChainSingleSelection } from '../../../components/ChainSelection';
import { useAssets, useChains } from '../../../components/FetchConfig';
import GeneralBtn from '../../../components/GeneralBtn';
import { NodeTypesEnumMultiSelection } from '../../../components/GeneralSelection';
import MpTextField from '../../../components/MpTextField';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../../../components/MuiGenerals';
import AddAddressBookDialog from '../../../components/WalletManagement/AddAddressBookDialog';
import AssignWalletAddressDialog from '../../../components/WalletManagement/AssignWalletAddressDialog';
import WalletManagementTabList from '../../../components/WalletManagement/WalletManagementTabList';
import { useAlerting, usePermission, useTabs } from '../../../hooks';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import {
  enumChainIdToType,
  enumWalletStatus,
  enumWalletType,
  FeatureCodes,
  tableConfig
} from '../../../utils/constant';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findChainInfoByChainTypeOnly,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  separateBatchStrings,
  sortItemsAlphabetically,
  toDisplayTime
} from '../../../utils/helper';
import { DivideLine, genField, useRefresh } from '../../../utils/HelperComp';
import { customSx } from '../../../utils/styling';
import { useZusDialogStore, zusRefetchStore } from '../../../zustand/store';

const initSearchParams = {
  displayNamesString: "",
  clientIdsString: "",
  walletNamesString: "",
  walletTypesArray: [],
  asset_names: [],
  chain_name: "",
  walletAddressesArray: "",
  statusArray: ["1"],
  is_check_point_certified: "",
  risk_levels: [],
};

const Pkey = FeatureCodes.walletManagement.WalletManagement.TabWalletManagement;
//is_is_check_point_certified
export default function WalletManagement() {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [operationBol, setOperationBol] = useState(false);

  const [walletStatus, setWalletStatus] = useState("");
  const [refreshBool, refresh] = useRefresh();

  const onConfirmWalletStatus = () => {
    setOperationBol(false);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          list={list}
          setList={setList}
          setPage={setPage}
          page={page}
          refreshBool={refreshBool}
          refresh={refresh}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <WalletManagementTabList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            refresh={refresh}
          />
        </Tabs>
      </Container>
      <Dialog
        open={operationBol}
        onClose={() => setOperationBol(false)}
        fullWidth={true}
      >
        <DialogTitle>{t("walletStatus")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("walletStatusError")}</InputLabel>
            <Select
              name={t("enableOrDisable")}
              value={walletStatus}
              onChange={(e) => setWalletStatus(e.target.value)}
            >
              {Object.entries(enumWalletStatus)
                .filter(([key]) => walletStatusArr.includes(key))
                .map(([key, value]) => (
                  <MenuItem key={key} value={Number(key)}>
                    {t(`${value}`.toLowerCase())}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmWalletStatus}
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOperationBol(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const profile = useAppSelector(selectProfile);
  const { alerting } = useAlerting();
  const { list, setList, setPage, page, refreshBool, refresh } = props;
  const shouldRefetch = zusRefetchStore((state) => state.refetchWalletMgtTab);
  const { selectChainByPieces } = useChains();
  const { hasPermission } = usePermission();
  const [openAssignWallet, setOpenAssignWallet] = useState(false);
  const [openAddAddressBook, setOpenAddAddressBook] = useState(false);
  const [fields, setFields] =
    useState<MerchantWalletParamsFace>(initSearchParams);
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  const getParams = () => {
    const {
      walletTypesArray,
      asset_names,
      walletAddressesArray,
      statusArray,
      displayNamesString,
      walletNamesString,
      clientIdsString,
      chain_name,
      is_check_point_certified,
      ...rest
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const display_names = separateBatchStrings(displayNamesString);
    const wallet_names = separateBatchStrings(walletNamesString);
    const client_ids = separateBatchStrings(clientIdsString);
    const walletAddresseString = separateBatchStrings(walletAddressesArray);
    const defaultStatusArr = ["1", "-1"];
    return {
      ...rest,
      display_names,
      wallet_names,
      client_ids,
      asset_names,
      wallet_types: walletTypesArray,
      wallet_address: walletAddresseString,
      chain_type,
      chain_id,
      is_check_point_certified,
      statuses: statusArray.length === 0 ? defaultStatusArr : statusArray,
      page: page,
      pageSize: tableConfig.pageSize,
      withSupportedChain: true,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const getWallets = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getWalletManagement({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    getWallets();
  }, [page, refreshBool, shouldRefetch, cacheParams]);

  const { selectAssetNamesByChain } = useAssets();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(() => {
    setFields((params) => ({ ...params, asset_names: [] }));
  }, [fields.chain_name]);

  const onExport = async () => {
    if (list.count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getWalletManagement({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, list.count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { t, translate, isExport: true });
    const config = {};
    downloadFiles(`Wallet Report`, res, config);
  };

  const onReset = () => {
    const { statusArray, ...rest } = initSearchParams;
    const newParams = { ...rest, statusArray: [] };
    setFields(newParams);
  };

  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };

  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFields((fields) => ({ ...fields, [type]: value }));
  };
  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    // if (filterField === "is_check_point_certified") {
    setFields((fields) => ({ ...fields, is_check_point_certified: "" }));
    // }
  };
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const RiskLevelWithoutNoData = (function () {
    if (!("NoData" in EnumRiskLevel)) {
      throw Error("EnumRiskLevel should have NoData Key");
    }
    const { NoData, ...rest } = EnumRiskLevel;
    return rest;
  })();

  const F = genField({ t }, [
    [
      "display_name",
      <MpTextField
        fullWidth={true}
        name="display_name"
        label={t("phDisplayName")}
        value={fields.displayNamesString}
        onChange={onChange("displayNamesString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.display_names.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_name",
      <MpTextField
        fullWidth={true}
        name="wallet_name"
        label={t("phWalletName")}
        value={fields.walletNamesString}
        onChange={onChange("walletNamesString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_names.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "client_id",
      <MpTextField
        fullWidth={true}
        name="client_id"
        label={t("phClientId")}
        value={fields.clientIdsString}
        onChange={onChange("clientIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.client_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    // [
    //   "asset_name",
    //   <AssetMultiSelection
    //     label={t("phAsset_name")}
    //     setChoices={setAssets}
    //     choices={fields.asset_names}
    //     allItems={assetsNames}
    //   />,
    // ],
    [
      "wallet_type",
      <FormControl>
        <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
        <Select
          multiple
          name="walletTypesArray"
          value={fields.walletTypesArray}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "walletAddress",
      <MpTextField
        fullWidth={true}
        name="walletAddressesArray"
        label={t("phWalletAddress")}
        value={fields.walletAddressesArray}
        onChange={onChange("walletAddressesArray")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_address.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "walletStatus",
      <FormControl>
        <InputLabel id="select-label">{t("phWalletStatus")}</InputLabel>
        <Select
          multiple
          name="statusArray"
          value={fields.statusArray}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletStatus))
            .filter(([key, val]: any) => walletStatusArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={key}>
                {t(`${value}`.toLowerCase())}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "risk_level",
      <NodeTypesEnumMultiSelection
        label={t("ph_risk_level")}
        value={fields.risk_levels}
        onChange={(risk_levels) => setFields((f) => ({ ...f, risk_levels }))}
        enumData={RiskLevelWithoutNoData}
        disabled={!profile.chain_analysis}
        isNoSorting
      />,
    ],
    // [
    //   "is_certified_balance",
    //   <FormControl>
    //     <InputLabel>{t("ph_certified_balance")}</InputLabel>
    //     <Select
    //       name="is_check_point_certified"
    //       value={fields.is_check_point_certified}
    //       onChange={onSelectChange("is_check_point_certified")}
    //     >
    //       {Object.entries(enumCertifiedBalanceObj).map(([value, name], i) => (
    //         <MenuItem
    //           key={i}
    //           value={value}
    //           onClick={(e) => handleClick(e, value, "is_check_point_certified")}
    //         >
    //           {translate(
    //             `enumConstants.${enumCertifiedBalanceObj[String(value)]}`
    //           )}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   </FormControl>,
    // ],
  ]);
  return (
    <>
      <AddAddressBookDialog
        openAddAddressBook={openAddAddressBook}
        setOpenAddAddressBook={setOpenAddAddressBook}
        refresh={refresh}
      />
      <AssignWalletAddressDialog
        openAssignWallet={openAssignWallet}
        setOpenAssignWallet={setOpenAssignWallet}
        refresh={refresh}
      />
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.ImportAddress) && (
          <Button
            variant="outlined"
            onClick={() => setOpenAddAddressBook(true)}
          >
            {t("addAddressBook")}
          </Button>
        )}
        {hasPermission(Pkey.AssignWalletAddress) && (
          <Button variant="outlined" onClick={() => setOpenAssignWallet(true)}>
            {t("createAddress")}
          </Button>
        )}
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.display_name}
        {F.wallet_type}
        {F.wallet_name}
        {F.client_id}
        {F.chain_name}
        {/* {F.asset_name} */}
        {F.walletAddress}
        {F.walletStatus}
        {F.risk_level}
        {/* {F.is_certified_balance} */}
      </Box>
      <Box sx={customSx.filterB}>
        <Button color="secondary" variant="contained" onClick={onSearch}>
          {t("search")}
        </Button>
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const walletStatusArr = ["-1", /* "0", */ "1"];
const walletTypeArr = ["0", "1", "2", "3", "4", "5"];
const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: any
): any[][] => {
  const { t, translate, isExport } = config || {};
  const tEnum = (key: string) => translate(`enumConstants.${key}`);
  // const { selectChainByPieces } = useChains();
  const res = array.map((item: any) => {
    const asset_name = item?.wallet_balances?.asset_name;
    const decimal = getDecimal({ ...item, asset_name });
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const status = walletStatusArr.includes(String(item.status))
      ? tEnum(enumWalletStatus[String(item.status)])
      : "";
    const walletType = walletTypeArr.includes(String(item.wallet_type))
      ? tEnum(enumWalletType[String(item.wallet_type)])
      : "";
    const isDefault = item.is_default ? tEnum("Yes") : tEnum("No");

    const isETHChainTypeWithSupportedChain =
      item.supported_chains &&
      item.chain_type === Number(enumChainIdToType.ETH);

    const getChainNameByChainTypeOnlyOrBothChainIdAndChainType = item.chain_id
      ? findChainInfo(String(item.chain_type), item.chain_id)?.name
      : findChainInfoByChainTypeOnly(String(item.chain_type))?.name;

    const mappedResult = [
      [
        "rawData",
        "",
        {
          wallet_id: item.id,
          status: item.status,
        },
      ],
      ["display_name", t("display_name"), item.display_name],
      ["client_id", t("client_id"), item.client_id],
      ["wallet_type", t("wallet_type"), walletType],
      ["is_default", t("is_default"), isDefault],
      ["risk_level", t("risk_level"), tEnum(EnumRiskLevel[item.risk_level])],
      [
        "chain_name",
        t("chain_name"),
        isETHChainTypeWithSupportedChain
          ? item.supported_chains
              .map(
                (i: { chain_type: number; chain_id: string }) =>
                  findChainInfo(String(i.chain_type), i.chain_id)?.name
              )
              .sort((a: string, b: string) => a.localeCompare(b))
              .join(", ")
          : getChainNameByChainTypeOnlyOrBothChainIdAndChainType,
        // selectChainByPieces({ chain_name: item.chain_type }),
      ],
      ["wallet_address", t("walletAddress"), item.wallet_address],
      ["status", t("walletStatus"), status],
      // ["asset_name", t("asset_name"), asset_name],
      // ["balance", t("balance"), toAmount(item?.wallet_balances.balance)],
      //
      // [
      //   "latest_checkpoint_id",
      //   t("latest_checkpoint_id"),
      //   item.wallet_balances.latest_check_point_id,
      // ],
      // [
      //   "latest_checked_blocked_no",
      //   t("latest_checked_blocked_no"),
      //   item.wallet_balances.latest_check_point_block_number,
      // ],
      // [
      //   "certified_with_on_chain_balance",
      //   t("certified_with_on_chain_balance"),
      //   translate(
      //     `enumConstants.${
      //       enumCertifiedBalanceObj[
      //         String(item.wallet_balances.is_check_point_certified)
      //       ]
      //     }`
      //   ),
      // ],
      // [
      //   "last_certified_checkpointId",
      //   t("last_certified_checkpointId"),
      //   item.wallet_balances.last_certified_check_point_id,
      // ],
      //
      [
        "assignWalletTime",
        t("assignWalletTime"),
        toDisplayTime(item.assign_wallet_time),
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
    ];
    return isExport ? mappedResult.slice(1) : mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
