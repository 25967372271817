import {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useTranslate } from "react-admin";
import { create as createZusStore } from "zustand";

import AddIcon from "@mui/icons-material/Add";
import EnumWalletType from "@wallet-manager/node-types/dist/types/postgres/const/WalletType";
import { WalletType } from "@wallet-manager/node-types/dist/src/postgres/const";

import {
  batchWithdraw,
  checkCurrentBalanceApi,
  fetchAllAssets,
  getWalletManagement,
} from "../../api/merchant";
import excelSVGLink from "../../assets/icons/Excel.svg";
import AddressBookSuggestion from "../../components/AddressBookSuggestion";
import AddressSelection from "../../components/AddressSelection";
import { AssetSingleSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useAssets, useChains } from "../../components/FetchConfig";
import MpTextField from "../../components/MpTextField";
import { Box, Button, Container } from "../../components/MuiGenerals";
import {
  NodeTypesEnumSingleSelection,
} from "../../components/GeneralSelection";
import YubiPrompter from "../../components/Prompter";
import {
  useAlerting,
  useFileUpload,
  usePermission,
  useYubiPrompt,
} from "../../hooks";
import { chainKeyAndName, FeatureCodes } from "../../utils/constant";
import {
  amountDivideDecimals,
  containsOnlyNumbers,
  downloadFiles,
  findDecimalByChainNameAndAsset,
  toDBInDecimals,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";

type IchainKeyAndName = "" | keyof typeof chainKeyAndName;
interface IlocalZusStore {
  chain_name: IchainKeyAndName;
  setChainName: (chain: IchainKeyAndName) => void;
}
const Pkey = FeatureCodes.assetManagement.BatchWithdraw;

export const useLocalZusStore = createZusStore<IlocalZusStore>((set) => ({
  chain_name: "",
  setChainName: (chain_name) => set(() => ({ chain_name })),
}));

const sx = {
  filterContainer: { paddingBottom: "2rem" },
  table: {
    backgroundColor: "#EAEAEA",
    p: 2,
    my: 2,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "> span:first-of-type": {
      flex: 1,
      display: "flex",
      "& span": {
        flex: 1,
      },
      "& span:first-of-type": {
        marginRight: "3.5rem",
      },
    },
    "& Button": {
      color: "black",
      backgroundColor: "white",
    },
  },
  tableContent: {
    my: 2,
    maxHeight: "50vh",
    overflowY: "auto",
  },
  tableRow: {
    columnGap: "1rem",
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-root": { flex: 1 },
    my: 1,
  },
  aboveTable: {
    display: "flex",
    justifyContent: "space-between",
    py: 2,
    "& .MuiButton-root": {
      color: "black",
      borderColor: "#AAAAAA",
    },
    "& span": {
      display: "flex",
      columnGap: "2rem",
      alignSelf: "center",
    },
  },
  belowTable: {
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    gap: "1rem",
    "> .MuiBox-root:nth-of-type(2)": {
      display: "flex",
      gap: "2rem",
    },
  },
  walletAddressInputBox: {
    div: {
      padding: 0,
    },
  },
  addressBookIcon: {
    marginLeft: 0,
    marginRight: "0.5rem",
    width: "40px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};
interface fieldsFace {
  chain_name: IchainKeyAndName;
  asset_name: string;
  hot_wallet_address: string;
  wallet_type: number;
}
const initFields = {
  chain_name: "",
  asset_name: "",
  hot_wallet_address: "",
  wallet_type: WalletType.HotWallet,
} as const;
type ordersFace = {
  to_address: string;
  amount: string;
  to_wallet_tag: string;
  display_name: string;
  wallet_type: string;
}[];
const initOrder = {
  to_address: "",
  amount: "",
  to_wallet_tag: "",
  display_name: "",
  wallet_type: "",
};
const templateOrder = [
  {
    to_address: "Receiving Address",
    to_wallet_tag: "Wallet Tag",
    amount: "0.01",
  },
];
interface FreeObj {
  [code: string]: any;
}

export default function BatchWithdraw() {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const [orders, setOrders] = useState<ordersFace>([initOrder]);
  const [enableAsset, setEnableAsset] = useState<any>([{}]);
  useEffect(() => {
    const getEnabledAssets = async () => {
      const assetsRes = await fetchAllAssets("1");
      let assets: FreeObj = {};
      for (let one of assetsRes) {
        assets[`${one.chain_type}_${one.chain_id}_${one.asset_name}`] = one;
      }
      setEnableAsset(assets);
    };
    getEnabledAssets();
  }, []);

  const resetInputs = () => {
    setFields(initFields);
    setOrders([initOrder]);
  };

  const { selectChainByPieces } = useChains();
  const chainObj = selectChainByPieces({
    chain_name: fields.chain_name,
  });
  const chain_type = String(chainObj.chain_type || "");
  const chain_id = String(chainObj.chain_id || "");
  const decimals = findDecimalByChainNameAndAsset(
    chain_type,
    chain_id,
    fields.asset_name
  );
  const { asset_name, hot_wallet_address } = fields;
  const readyNext = asset_name && hot_wallet_address;
  const params = {
    decimals,
    chain_type,
    chain_id,
    asset_name,
    hot_wallet_address,
  };
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false} style={sx.filterContainer}>
        <FilterBar
          fields={fields}
          setFields={setFields}
          enableAsset={enableAsset}
        />
        <Balance
          {...{
            chain_type,
            chain_id,
            asset_name,
            decimals,
            wallet_address: hot_wallet_address,
          }}
        />{" "}
      </Container>
      {readyNext && (
        <>
          <DivideLine />
          <Container
            style={customSx.datagridContainer}
            maxWidth={false}
            disableGutters
          >
            <ReceiveAddresses
              inputParams={params}
              orders={orders}
              setOrders={setOrders}
              resetInputs={resetInputs}
              walletType={fields.wallet_type}
            />
          </Container>
        </>
      )}
    </Box>
  );
}
export function FilterBar(props: {
  fields: fieldsFace;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
  enableAsset: any;
}) {
  const { fields, setFields, enableAsset } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);

  enum EnumWithdrawFilterWalletTypes  {
    HotWallet = EnumWalletType.HotWallet ,
    InvokerWallet = EnumWalletType.InvokerWallet,
  };

  const getAddressSelectionWalletType = (wallet_type: EnumWalletType ) => {
    if (wallet_type === EnumWalletType.HotWallet) {
      return [EnumWalletType.HotWallet];
    }
    if (wallet_type === EnumWalletType.InvokerWallet) {
      return [EnumWalletType.InvokerWallet];
    }
    return [] as WalletType[];
  }

  const { selectEnableAssetByChain } = useAssets();
  const all_asset_names = selectEnableAssetByChain(
    {
      chain_name: fields.chain_name,
    },
    enableAsset
  );
  const localZusStore = useLocalZusStore();
  const setAssets = (asset_name: string) =>
    setFields((fields) => ({ ...fields, asset_name }));
  const setAddress = (hot_wallet_address: string) =>
    setFields((fields) => ({ ...fields, hot_wallet_address }));

  useEffect(() => {
    setAssets("");
    localZusStore.setChainName(fields.chain_name);
  }, [fields.chain_name]);

  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) =>
          setFields((f) => ({
            ...f,
            chain_name,
            asset_name: "",
            hot_wallet_address: "",
            wallet_type: WalletType.HotWallet,
          }))
        }
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetSingleSelection
        label={t("phAsset_name")}
        setChoice={setAssets}
        choice={fields.asset_name}
        allItems={all_asset_names}
      />,
    ],
    [
      "wallet_type",
      <NodeTypesEnumSingleSelection
      label={t("ph_wallet_type")}
      onChange={(e) =>
        setFields((f: any) => ({
          ...f,
          hot_wallet_address: "",
          wallet_type: parseInt(e.target.value),
        }))
      }
      value={fields.wallet_type ? String(fields.wallet_type) : ""}
      enumData={EnumWithdrawFilterWalletTypes}
      isNoSorting
      clearSelect={()=>{}}
      />
    ],
    [
      "from_address",
      <AddressSelection
        chain_name={fields.chain_name}
        setGatherAddress={setAddress}
        gather_address={fields.hot_wallet_address}
        wallet_types={getAddressSelectionWalletType(fields.wallet_type)}
      />,
    ]
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.asset_name}
        {F.wallet_type}
        {F.from_address}
      </Box>
    </>
  );
}
type ordersActions = "reset" | "setField" | "setAll" | "insert" | "deleteOne";
function orderReducer(
  state: ordersFace,
  action: { type: ordersActions; payload?: any }
) {
  const { type, payload } = action;
  const fn = {
    reset: () => [{ ...initOrder }],
    setAll: () => payload,
    // setAll: () =>
    //   state
    //     .filter((item) => item.to_address !== "" || item.amount !== "")
    //     .concat(payload),
    setField: () =>
      state.map((order, index) => {
        const name = payload.name as keyof typeof order;
        if (index === payload.i) {
          order[name] = payload.value;
        }
        return order;
      }),
    insert: () => state.concat({ ...initOrder }),
    deleteOne: () => [
      ...state.slice(0, payload),
      ...state.slice(payload + 1, state.length),
    ],
  }[type] || [initOrder];
  return fn();
}
interface inputParamsFace {
  decimals: string;
  chain_type: string;
  chain_id: string;
  asset_name: string;
  hot_wallet_address: string;
}
export function ReceiveAddresses(props: {
  inputParams: inputParamsFace;
  walletType: number;
  orders: ordersFace;
  setOrders: Dispatch<SetStateAction<ordersFace>>;
  resetInputs: () => void;
}) {
  const translate = useTranslate();
  const { hasPermission } = usePermission();

  const t = (key: string) => translate(`assetManagement.${key}`);
  const { setOrders, inputParams, resetInputs, walletType } = props;
  const { alerting } = useAlerting();
  const { onFileChange, fileContent, setFileContent } = useFileUpload();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  let fileUploader = useRef<HTMLInputElement>(null);
  const { selectChainByPieces } = useChains();
  const localZusStore = useLocalZusStore();
  const chain_name = localZusStore.chain_name;
  const getParams = () => {
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    return {
      chain_type,
      chain_id,
      statuses: [1],
      pageSize: 20,
      page: 0,
    };
  };
  const params = getParams();

  const newContentRef = useRef<{ inputItem: any[]; alertItem: any[] }>({
    inputItem: [],
    alertItem: [],
  });
  const [fetching, setFetching] = useState(false);
  const [rendering, setRendering] = useState(false);

  const getDisplayWalletType = (selectedWalletType: number ) => {
    if (selectedWalletType === EnumWalletType.HotWallet) {
      return [EnumWalletType.HotWallet, 
              EnumWalletType.InvokerWallet, 
              EnumWalletType.SettlementWallet, 
              EnumWalletType.ExternalWallet, 
              EnumWalletType.SweepDestWallet
            ];
    }else if (selectedWalletType === EnumWalletType.InvokerWallet) {
      return [EnumWalletType.HotWallet, EnumWalletType.InvokerWallet];
    }

    return [] as WalletType[];
  }

  const dataInAddressBookArrRef = useRef<
    {
      display_name: string;
      wallet_type: number;
      wallet_address: string;
    }[]
  >([]);

  useEffect(() => {
    if (!fileContent) {
      return;
    }
    const [keys, ...content] = fileContent;

    const walletAddressSet = new Set<string>();

    content.forEach((item: any) => {
      if (item[0]) {
        walletAddressSet.add(item[0].trim());
      }
    });

    const walletAddressArr = Array.from(walletAddressSet);

    const pageSize = 20;

    const newContentFn = async () => {
      for (let index = 0; index < walletAddressArr.length; index += pageSize) {
        setFetching(true);
        const result = await getWalletManagement({
          ...params,
          wallet_address: walletAddressArr.slice(index, index + pageSize),
        });
        if (!result) {
          return;
        }
        if (result && result?.rows) {
          dataInAddressBookArrRef.current = [
            ...dataInAddressBookArrRef.current,
            ...result?.rows,
          ];
        }

        setFetching(false);
      }
    };

    newContentFn();
  }, [fileContent]);

  useEffect(() => {
    if (!fileContent || fetching) {
      return;
    }

    if (
      !!dataInAddressBookArrRef.current.length ||
      hasPermission(Pkey.FreeInputWalletAddress)
    ) {
      setRendering(true);
      const [keys, ...content] = fileContent;

      for (const item of content) {
        const [address, tag, amount] = item;
        if (address) {
          const trimmedAddress = address.trim();
          const dataInAddressBook = dataInAddressBookArrRef.current.find(
            (item) => item.wallet_address === trimmedAddress
          );

          if (
            !hasPermission(Pkey.FreeInputWalletAddress) &&
            !dataInAddressBook
          ) {
            newContentRef.current.alertItem = [
              ...newContentRef.current.alertItem,
              trimmedAddress,
            ];
          } else {
            newContentRef.current.inputItem = [
              ...newContentRef.current.inputItem,
              {
                [keys[0]]: trimmedAddress,
                [keys[1]]: tag,
                [keys[2]]: amount,
                display_name: dataInAddressBook
                  ? dataInAddressBook.display_name
                  : "",
                wallet_type: dataInAddressBook
                  ? dataInAddressBook.wallet_type
                  : "",
              },
            ];
          }
        }
      }
      // if (
      //   !hasPermission(Pkey.FreeInputWalletAddress) &&
      //   !!newContentRef.current.alertItem.length
      // ) {
      //   alerting("error", t("import_address_error"));
      //   // alerting(
      //   //   "error",
      //   //   `${t(
      //   //     "import_address_error"
      //   //   )}\n(${newContentRef.current.alertItem.join(",\n")})`
      //   // );
      // }
      setRendering(false);
    } else {
      alerting("warning", t("import_address_error"));
    }
  }, [fetching]);

  const resetImportData = () => {
    newContentRef.current.inputItem = [];
    newContentRef.current.alertItem = [];
    dataInAddressBookArrRef.current = [];
    setFileContent(undefined);
    if (fileUploader.current?.value) {
      fileUploader.current.value = "";
    }
  };

  useEffect(() => {
    if (!fileContent || !newContentRef.current.inputItem[0]) {
      return;
    }

    if (
      !hasPermission(Pkey.FreeInputWalletAddress) &&
      !!newContentRef.current.alertItem.length
    ) {
      resetImportData();
      return alerting("warning", t("import_address_error"));
    }

    dispatch({ type: "setAll", payload: newContentRef.current.inputItem });
    resetImportData();
  }, [rendering, dataInAddressBookArrRef.current]);

  const [orders, dispatch] = useReducer(orderReducer, [{ ...initOrder }]);
  useEffect(() => {
    setOrders(orders);
  }, [orders]);

  const rawTotalAmount =
    orders.reduce(
      (acc: number, order: ordersFace[0]) => acc + Number(order.amount),
      0
    ) || 0;
  const totalAmount = parseFloat(rawTotalAmount.toFixed(8));
  const decimals = Number(inputParams.decimals);

  const toSubmit = async () => {
    const isMissing = orders.some((o: any) => {
      return o.to_address === "" || !o.amount;
    });
    if (isMissing || !decimals) {
      return alerting("warning", t("cannot_empty"));
    }
    const newOrders = orders.map((o: any) => ({
      ...o,
      to_address: o.to_address.trim(),
      decimals,
      amount: toDBInDecimals(o.amount, decimals),
    }));

    const batchWithdrawSendingBasicParams: any = {
      chain_type: inputParams.chain_type,
      chain_id: inputParams.chain_id,
      asset_name: inputParams.asset_name,
      decimals: inputParams.decimals,
      hot_wallet_address: inputParams.hot_wallet_address,
      wallet_type : walletType,
    };


    const params = {
      ...batchWithdrawSendingBasicParams,
      orders: newOrders,
    };

    const res = await getResAfterYubi(batchWithdraw, params);
    if (!res) {
      return;
    }

    const { batch_id } = res;
    const message = `${t("withdraw_succeed")}, batch id ${batch_id}`;
    alerting("success", message);
    resetInputs();
  };

  const onReset = () => {
    dispatch({ type: "reset" });
  };

  const download = () => {
    downloadFiles("Batch Withdraw Template", templateOrder, {
      needDate: false,
    });
  };

  const importExcel = () => {
    const dom = fileUploader.current;
    if (!dom) {
      return;
    }
    dom.click();
  };

  return (
    <Box>
      <Box sx={sx.aboveTable}>
        <YubiPrompter {...prompterConfig} />
        <span>
          {t("total_count")}:{orders.length}
        </span>
        <span>
          <Button
            variant="outlined"
            onClick={download}
            startIcon={<img src={excelSVGLink} />}
          >
            {t("download_template")}
          </Button>
          <input
            type="file"
            accept=".csv"
            onChange={onFileChange}
            ref={fileUploader}
            style={{ display: "none" }}
          />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={importExcel}
          >
            {t("import_excel")}
          </Button>
        </span>
      </Box>
      <AddressList orders={orders} dispatch={dispatch} displayWalletType={getDisplayWalletType(walletType)}/>
      <Box sx={sx.belowTable}>
        <Box>
          {t("total_amount")} {totalAmount}
        </Box>
        <Box>
          <Button onClick={toSubmit} color="secondary" variant="contained">
            {t("submit")}
          </Button>
          <Button onClick={onReset} color="info" variant="contained">
            {t("reset")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
function AddressList(props: {
  orders: ordersFace;
  dispatch: Dispatch<{
    type: ordersActions;
    payload?: any;
  }>;
  displayWalletType: WalletType[];
}) {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);

  const localZusStore = useLocalZusStore();
  const { orders, dispatch, displayWalletType } = props;
  const enableTag =
    localZusStore.chain_name === "XRP" || localZusStore.chain_name === "XRPT";
  const chain_name = localZusStore.chain_name;

  const { hasPermission } = usePermission();
  const onDelete = (index: number) => {
    dispatch({ type: "deleteOne", payload: index });
  };

  const onInput = (
    // e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string,
    i: number,
    name: keyof typeof initOrder
  ) => {
    const resp = name === "amount" ? containsOnlyNumbers(value) : true;
    if (!resp) {
      return;
    }
    return dispatch({ type: "setField", payload: { i, value, name } });
  };

  const walletTypeArr = displayWalletType.map((value) => String(value));


  return (
    <Box sx={sx.table}>
      <Box sx={sx.tableHeader}>
        <span>
          <span>{t("wallet_address")}</span>
          {enableTag && <span>{t("tag")}</span>}
          <span>{t("withdraw_amount")}</span>
        </span>
        <Button
          onClick={() => dispatch({ type: "insert" })}
          startIcon={<AddIcon />}
        >
          {t("add_item")}
        </Button>
      </Box>
      <Box sx={sx.tableContent}>
        {orders.map((o, i) => (
          <Box key={i} sx={sx.tableRow}>
            <span>{i + 1}</span>
            <AddressBookSuggestion
              item={o}
              isAllowFreeInput={hasPermission(Pkey.FreeInputWalletAddress)}
              walletTypes={walletTypeArr}
              chain_name={chain_name}
              setField={(name: keyof typeof initOrder, value: string) =>
                onInput(value, i, name)
              }
            />
            {enableTag && (
              <MpTextField
                value={o.to_wallet_tag}
                onChange={(e) => onInput(e.target.value, i, "to_wallet_tag")}
                placeholder={`(${te("optional")})`}
              />
            )}
            <MpTextField
              type="string"
              value={o.amount}
              onChange={(e) => onInput(e.target.value, i, "amount")}
            />
            {orders.length > 1 && (
              <Button
                sx={{
                  width: "30px",
                  height: "30px",
                  minWidth: "0px !important",
                  marginLeft: "-8px",
                  fontSize: "24px",
                }}
                onClick={() => onDelete(i)}
              >
                ×
              </Button>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
function Balance(p: {
  chain_type: string;
  chain_id: string;
  asset_name: string;
  decimals: string;
  wallet_address: string;
}) {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const [rawBalance, setRawBalance] = useState("");
  const isValid =
    p.chain_type && p.chain_id && p.asset_name && p.wallet_address;
  useEffect(() => {
    if (!isValid) {
      return;
    }
    async function fetch() {
      const res = await checkCurrentBalanceApi(p);
      setRawBalance(res?.balance || "0");
    }
    fetch();
  }, [p.chain_type, p.chain_id, p.asset_name, p.wallet_address, isValid]);
  if (!isValid) {
    return <></>;
  }
  const balance = amountDivideDecimals(rawBalance, Number(p.decimals));
  return (
    <div style={{ paddingTop: "1rem" }}>
      {t("available_balance")}:{balance} {p.asset_name}
    </div>
  );
}
