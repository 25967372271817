import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme } from "@mui/system/createTheme";
import { useTranslation } from "../hooks";
import { Box } from "./MuiGenerals";
import { Iprefix } from "../hooks/useTranslation";

export default function GridBox(props: {
  translatePrefix: Iprefix;
  data: Array<[string, JSX.Element]>;
  containerSx?: SxProps<Theme>;
  itemSx?: SxProps<Theme>;
}) {
  const { translatePrefix, data, containerSx, itemSx } = props;
  const { t } = useTranslation(translatePrefix);
  return (
    <Box
      sx={{
        ".MuiInputLabel-shrink": {
          display: "none",
        },
        display: "grid",
        gridTemplateColumns: "minmax(0,5fr) minmax(0,5fr)",
        columnGap: "20px",
        fontSize: "0.6875rem",
        ...containerSx,
      }}
    >
      {data.map(([label, CustomElement]) => (
        <Box
          key={label}
          sx={{
            display: "flex",
            margin: "12px 0px",
            flexWrap: "wrap",
            alignItems: "center",
            ...itemSx,
          }}
        >
          <Box sx={{ marginRight: "8px", flex: "1" }}>{t(label)}:</Box>
          <Box
            sx={{
              flex: "3",
              minWidth: "0",
              ">div": { width: "100%", margin: 0 },
            }}
          >
            {CustomElement}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
