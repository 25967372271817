import axios from "./axiosInstance";
import { IlistResult, IportalUserProposalsParams, Ipage } from "./types";

export default {
  portalUserProposals,
  approve1PortalUserProposals,
  approve2PortalUserProposals,
  rejectPortalUserProposals,
};
export async function portalUserProposals(
  params: IportalUserProposalsParams & Ipage,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<never, IlistResult<IportalUserProposalsRes>>(
    `/merchant_portal/portal_user_proposals`,
    input
  );
}
interface IportalUserProposalsRes {
  id: string;
  portal_user_id: string;
  act: number;
  old_val: null | string;
  new_val: null | string;
  date_created: string;
  date_last_modified: null | string;
  date_appr1: null | string;
  date_appr2: null | string;
  appr_by1: null | string;
  appr_by2: null | string;
  created_by: string;
  last_modified_by: null | string;
  merchant_id: string;
  status: number;
  portal_user: {
    id: string;
    pub_id: null | string;
    pub_id_in_dec: null | string;
    oper_status: number;
    yubi_key_status: number;
    merchant_id: string;
    email: string;
    date_created: string;
    date_last_yubi_key_updated: null | string;
    date_last_modified: string;
  };
}

export async function approve1PortalUserProposals(
  params: { otp: string; id: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_user_proposals/approve1`,
    input
  );
}
export async function approve2PortalUserProposals(
  params: { otp: string; id: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_user_proposals/approve2`,
    input
  );
}
export async function rejectPortalUserProposals(
  params: { otp: string; id: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_user_proposals/reject`,
    input
  );
}
