import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { customSx } from "../utils/styling";

export function TableHeadComponent(props: any) {
  const { header } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={customSx.tableCell}>
          <Typography variant="h6">{header}</Typography>
        </TableCell>
        <TableCell sx={customSx.tableCell}></TableCell>
      </TableRow>
    </TableHead>
  );
}
