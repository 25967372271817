import { isNumber } from "lodash";
import { useTranslate } from "react-admin";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SxProps } from "@mui/system";

import { sortAlphabetically } from "../utils/helper";

/*
  usage
  <SingleSelection
    label={t("ph_lang")}
    value={fields.lang}
    onChange={onSelectChange("lang")}
    enumData={enumLanguage}
    clearSelect={() => unselectClicked("lang")}
    sx={{
      formControl: { opacity: 0.4 },
      select: { backgroundColor: "blue" },
    }}
  />,
 */
export function SingleSelection(p: {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  enumData: Record<string, string>;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string, value: any) => string;
  clearSelect: () => void;
  disabled?: boolean;
  sx?: { formControl?: SxProps; select?: SxProps };
  isNoSorting?: boolean;
  disabledItemList?: string[];
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);
  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const selectionArray = defaultArrayFn(
    p.isNoSorting
      ? Object.entries(p.enumData)
      : sortAlphabetically(Object.entries(p.enumData))
  );

  const sx = p.sx || { formControl: {}, select: {} };
  return (
    <FormControl sx={sx.formControl}>
      {p.label && <InputLabel>{p.label}</InputLabel>}
      <Select
        name={p.label}
        value={p.value}
        onChange={p.onChange}
        sx={sx.select}
        disabled={p.disabled}
        MenuProps={{ sx: { height: "320px" } }}
      >
        {selectionArray.map(([name, value], i) => {
          const isDisabledItem = p.disabledItemList?.includes(name);

          return (
            <MenuItem
              key={i}
              value={value}
              onClick={p.clearSelect}
              disabled={isDisabledItem}
            >
              {p?.nameFn ? p.nameFn(name, value) : te(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export function NodeTypesEnumSingleSelection(p: {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent<string>) => void;
  enumData: Record<string, any>;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  clearSelect: () => void;
  sx?: { formControl?: SxProps; select?: SxProps };
  isNoSorting?: boolean;
  disabled?: boolean;
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);
  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const enumObjEntries = Object.entries(p.enumData);
  const isValueNumber = enumObjEntries.some(([_key, value]) => isNumber(value));
  const displayEnumArr = isValueNumber
    ? enumObjEntries
        .filter(([_key, value]) => isNumber(value))
        .map(([key, value]) => [key, String(value)])
    : enumObjEntries;
  const selectionArray = defaultArrayFn(
    p.isNoSorting ? displayEnumArr : sortAlphabetically(displayEnumArr)
  );

  const sx = p.sx || { formControl: {}, select: {} };
  return (
    <FormControl sx={sx.formControl}>
      {p.label && <InputLabel>{p.label}</InputLabel>}
      <Select
        name={p.label}
        value={p.value}
        onChange={p.onChange}
        sx={sx.select}
        disabled={p.disabled}
        MenuProps={{ sx: { height: "320px" } }}
      >
        {selectionArray.map(([name, value], i) => {
          return (
            <MenuItem key={i} value={value} onClick={p.clearSelect}>
              {p?.nameFn ? p.nameFn(name) : te(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export function MultiSelection(p: {
  label: string;
  value: string[];
  onChange: (e: SelectChangeEvent<string[]>) => void;
  enumData: Record<string, string>;
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  clearSelect?: () => void;
  isNoSorting?: boolean;
  disabled?: boolean;
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);
  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const blankFn = () => {};
  const clearSelect = p.clearSelect || blankFn;
  const selectionArray = !p.isNoSorting
    ? defaultArrayFn(sortAlphabetically(Object.entries(p.enumData)))
    : defaultArrayFn(Object.entries(p.enumData));

  return (
    <FormControl>
      <InputLabel>{p.label}</InputLabel>
      <Select
        name={p.label}
        value={p.value}
        onChange={p.onChange}
        disabled={p.disabled}
        multiple
        MenuProps={{ sx: { height: "320px" } }}
      >
        {selectionArray.map(([name, value], i) => {
          return (
            <MenuItem key={i} value={value} onClick={clearSelect}>
              {p?.nameFn ? p.nameFn(name) : te(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export function NodeTypesEnumMultiSelection(p: {
  label: string;
  value: string[];
  onChange: (arr: string[]) => void;
  enumData: Record<string, any>;
  enumDataSelectable?: number[];
  arrayFn?: (array: string[][]) => string[][];
  nameFn?: (str: string) => string;
  clearSelect?: () => void;
  isNoSorting?: boolean;
  disabled?: boolean;
}) {
  function isSelfFn<T>(arr: T): T {
    return arr;
  }
  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);
  const defaultArrayFn = p.arrayFn ? p.arrayFn : isSelfFn;
  const blankFn = () => {};
  const clearSelect = p.clearSelect || blankFn;
  const enumObjEntries = Object.entries(p.enumData);
  const isValueNumber = enumObjEntries.some(([_key, value]) => isNumber(value));
  const displayEnumArr = isValueNumber
    ? enumObjEntries
        .filter(([_key, value]) => isNumber(value))
        .map(([key, value]) => [key, String(value)])
    : enumObjEntries;

  const selectionArray = defaultArrayFn(
    p.isNoSorting ? displayEnumArr : sortAlphabetically(displayEnumArr)
  );

  const onChange = (e: SelectChangeEvent<string[]>) => {
    const result = [
      ...(Array.isArray(e.target.value) ? e.target.value : [e.target.value]),
    ];
    p.onChange(result);
  };
  return (
    <FormControl>
      <InputLabel>{p.label}</InputLabel>
      <Select
        name={p.label}
        value={p.value}
        onChange={onChange}
        multiple
        disabled={p.disabled}
        MenuProps={{ sx: { height: "320px" } }}
      >
        {selectionArray.map(([name, value], i) => {
          return (
            <MenuItem
              key={i}
              value={value}
              onClick={clearSelect}
              disabled={
                p.enumDataSelectable
                  ? !p.enumDataSelectable.includes(Number(value))
                  : undefined
              }
            >
              {p?.nameFn ? p.nameFn(name) : te(name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
