import endOfYesterday from 'date-fns/endOfYesterday';
import startOfYesterday from 'date-fns/startOfYesterday';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';

import { getClientReportByWallet, listFace } from '../../api/merchant';
import { AssetMultiSelection } from '../../components/AssetSelection';
import { ChainSingleSelection } from '../../components/ChainSelection';
import { CustomPagination } from '../../components/CustomPagination';
import { useDatePicker } from '../../components/DatePicker';
import { useAssets, useChains } from '../../components/FetchConfig';
import GeneralBtn from '../../components/GeneralBtn';
import { Box, Container } from '../../components/MuiGenerals';
import { useAlerting, usePermission } from '../../hooks';
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig
} from '../../hooks/useColumnHide';
import {
  dataGridDefaults,
  FeatureCodes,
  tableConfig
} from '../../utils/constant';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  moreThan2Months,
  separateBatchStrings
} from '../../utils/helper';
import { DivideLine, genField, useGenGridCol } from '../../utils/HelperComp';
import { customSx } from '../../utils/styling';
import { useZusDialogStore } from '../../zustand/store';

const Pkey = FeatureCodes.report.ClientReport.TabSummary;
export default function ClientReportSummary(props: {
  Tabs: (props: any) => JSX.Element;
}) {
  const { Tabs } = props;
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  return (
    <>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          page={page}
          setPage={setPage}
          setSearchBtnClick={setSearchBtnClick}
          isSearchBtnClick={isSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <TheList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </>
  );
}
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
  clientIdsString: string;
  walletNamesString: string;
  walletAddress: string;
}
const initFields = {
  asset_names: [],
  chain_name: "",
  clientIdsString: "",
  walletNamesString: "",
  walletAddress: "",
};

function FilterBar(props: any) {
  const { count, setList, page, setPage, isSearchBtnClick, setSearchBtnClick } =
    props;
  const { hasPermission } = usePermission();

  const translate = useTranslate();
  const t = (key: string, option?: Object) =>
    translate(`report.${key}`, option);
  const tc = (key: string) => translate(`common.${key}`);
  const defaultDate = {
    start: { defaultDate: startOfYesterday() },
    end: { defaultDate: endOfYesterday() },
  };
  const DateObj = useDatePicker({
    ...defaultDate,
    // isTimezoneConvert: false,
    isEndOfSecond: false,
  });
  const [pageSize] = useState(tableConfig.pageSize);
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces } = useChains();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const { walletNamesString, chain_name, ...rest } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const walletNames = separateBatchStrings(walletNamesString);
    return {
      ...rest,
      chain_type,
      chain_id,
      walletNames,
      mark_date_from: DateObj.start,
      mark_date_to: DateObj.end,
      page,
      pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getClientReportByWallet({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, getClientReportByWallet, isSearchBtnClick]);
  const onSearch = async () => {
    const isExceedMonths = moreThan2Months(
      new Date(params.mark_date_from),
      new Date(params.mark_date_to)
    );
    if (isExceedMonths)
      return alerting("warning", t("date_range_exceed", { months: 2 }));
    setPage(0);
    setCacheParams(params);
    setSearchBtnClick(true);
  };
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiPagingFn = (page: number, pageSize: number, signal: any) =>
      getClientReportByWallet({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiPagingFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { translate });
    const config = {};
    downloadFiles(`Client Summary Report`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.clearDate();
  };
  const F = genField({ t }, [
    ["date", <DateObj.Picker type="date" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_names",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.date}
        {F.chain_name}
        {F.asset_names}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { translate } = config;
  const t = (key: string) => translate(`report.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const mappedResult = [
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      ["asset_name", t("asset_names"), item.asset_name],
      [
        "opening_balance",
        t("opening_balance"),
        item.opening_balance === null ? "0" : toAmount(item.opening_balance),
      ],
      [
        "in_client_deposit",
        t("in_client_deposit"),
        toAmount(item.in_client_deposit),
      ],
      [
        "in_withdraw_deposit",
        t("in_withdraw_deposit"),
        toAmount(item.in_withdraw_deposit),
      ],
      [
        "in_client_wallet_topup",
        t("in_client_wallet_topup"),
        toAmount(item.in_client_wallet_topup),
      ],
      // [
      //   "in_settlement-deposit",
      //   t("in_settlement-deposit"),
      //   toAmount(item["in_settlement-deposit"]),
      // ],
      [
        "in_balance_adjustment",
        t("in_balance_adjustment"),
        toAmount(item.in_balance_adjustment),
      ],
      ["in_caution", t("in_caution"), toAmount(item.in_caution)],
      [
        "out_sweep_to_hot_wallet",
        t("out_sweep_to_hot_wallet"),
        toAmount(item.out_sweep_to_hot_wallet),
      ],
      ["out_sweep", t("out_sweep"), toAmount(item.out_sweep)],
      [
        "out_balance_adjustment",
        t("out_balance_adjustment"),
        toAmount(item.out_balance_adjustment),
      ],
      ["out_caution", t("out_caution"), toAmount(item.out_caution)],
      ["trans_fee", t("trans_fee"), toAmount(item.trans_fee)],
      ["closing_balance", t("closingBalance"), toAmount(item.closing_balance)],
    ].filter((col) => col);
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

interface propsFace {
  list: listFace;
  listMapping: any;
  page: number;
  isSearchBtnClick: boolean;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
}
function TheList(props: propsFace) {
  const { list, listMapping, setPage, page, isSearchBtnClick } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const content: GridRowsProp = listMapping("key", list.rows, {
    translate,
  });

  const columns: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("opening_balance", t("opening_balance")),
    useGenGridCol("in_client_deposit", t("in_client_deposit")),
    useGenGridCol("in_withdraw_deposit", t("in_withdraw_deposit")),
    useGenGridCol("in_client_wallet_topup", t("in_client_wallet_topup")),
    useGenGridCol("in_balance_adjustment", t("in_balance_adjustment")),
    useGenGridCol("in_caution", t("in_caution")),
    useGenGridCol("out_sweep_to_hot_wallet", t("out_sweep_to_hot_wallet")),
    useGenGridCol("out_sweep", t("out_sweep")),
    useGenGridCol("out_balance_adjustment", t("out_balance_adjustment")),
    useGenGridCol("out_caution", t("out_caution")),
    useGenGridCol("trans_fee", t("trans_fee")),
    useGenGridCol("closing_balance", t("closingBalance")),
  ];

  const totalRecords = list.count;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("summary")
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
