import { useState, SetStateAction, KeyboardEvent, MouseEvent } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import { InputAdornment } from "../components/MuiGenerals";
import SearchIcon from "@mui/icons-material/Search";
import {
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
} from "../components/MuiGenerals";
import { useTranslate } from "react-admin";
import { sortItemsAlphabetically } from "../utils/helper";
import MpTextField from "./MpTextField";
interface singlePropsFace {
  label?: string;
  searchLabel?: string;
  setChoice: (value: SetStateAction<any>) => void;
  choice: string;
  nameField?: string;
  allItems: string[];
}
interface multiPropsFace {
  label?: string;
  searchLabel?: string;
  setChoices: (choices: string[]) => void;
  choices: string[];
  nameField?: string;
  allItems: string[];
  addRecordProps?: boolean;
}
export function AssetSingleSelection(props: singlePropsFace) {
  const {
    label = "",
    searchLabel = "Type to search",
    choice,
    setChoice,
    nameField = "",
    allItems,
  } = props;
  const [rawKeyword, setKeyword] = useState("");
  const keyword = rawKeyword.toLowerCase();
  const allMenuItems = allItems.map((value, i) => {
    const isShow =
      keyword === "" ? true : value.toLowerCase().includes(keyword);
    const sx = isShow ? {} : { display: "none" };
    return (
      <MenuItem key={i} value={value} sx={sx}>
        {value}
      </MenuItem>
    );
  });
  // const onSelectChange = (e: SelectChangeEvent<string>) => {
  //   setChoice(e.target.value);
  // };
  const onSelect = (e: MouseEvent<HTMLElement>) => {
    const value = (e.target as HTMLElement).dataset.value || "";
    const output = value === choice ? "" : value;
    setChoice(output);
  };
  if (!allItems.concat("").includes(choice)) return <></>;
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        name={nameField}
        value={choice}
        onClick={onSelect}
        onClose={() => setKeyword("")}
      >
        <SearchField searchLabel={searchLabel} setKeyword={setKeyword} />
        {allMenuItems}
      </Select>
    </FormControl>
  );
}
export function AssetMultiSelection(props: multiPropsFace) {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  const {
    label = "",
    searchLabel = t("typeToSearch"),
    choices,
    setChoices,
    nameField = "",
    allItems,
    addRecordProps,
  } = props;
  const [rawKeyword, setKeyword] = useState("");
  const keyword = rawKeyword.toLowerCase();
  const allMenuItems = sortItemsAlphabetically(allItems).map(
    (value: string, i: string) => {
      const isShow =
        keyword === "" ? true : value.toLowerCase().includes(keyword);
      const sx = isShow ? {} : { display: "none" };
      return (
        <MenuItem key={i} value={value} sx={sx}>
          {value}
        </MenuItem>
      );
    }
  );
  const onSelectChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value;
    const newValue = typeof value === "string" ? value.split(",") : value;
    setChoices(newValue);
  };
  return (
    <FormControl fullWidth={addRecordProps}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={nameField}
        multiple
        value={choices}
        onChange={onSelectChange}
        onClose={() => setKeyword("")}
      >
        <SearchField searchLabel={searchLabel} setKeyword={setKeyword} />
        {allMenuItems}
      </Select>
    </FormControl>
  );
}
function SearchField(props: any) {
  const { searchLabel, setKeyword } = props;
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Escape") {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };
  return (
    <ListSubheader>
      <MpTextField
        size="small"
        autoFocus
        placeholder={searchLabel}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" children={<SearchIcon />} />
          ),
        }}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </ListSubheader>
  );
}
