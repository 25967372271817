/*
Depercated, due to the data pass data into setPrompter is not updated.
Use DialogInOne.tsx instead

usage
import Prompter, {usePrompter } from "../components/Prompter";
  
  const [prompter, setPromter] = usePrompter();
  const onTest = () => {
    setPromter(() => alert("yeah"), { title: "test" });
  };

  <button onClick={onTest}>test</button>
  <Prompter {...prompter} />
*/
import { useTranslate } from "react-admin";
import { useState } from "react";
import {
  IconButton,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../components/MuiGenerals";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
export interface PrompterFace {
  onClose: Function;
  open: boolean;
  title: string;
  content?: string | React.ReactNode;
  yesText?: string;
  noText?: string;
  onYes?: Function;
  onNo?: Function;
  dialogMinWidth?: string;
}
export type setupPromoterFace = (
  onYes: Function,
  rest: {
    title: string;
    onClose?: Function;
    open?: boolean;
    content?: string | React.ReactNode;
    yesText?: string;
    noText?: string;
    onNo?: Function;
    dialogMinWidth?: string;
    displayCloseButton?: boolean;
    customDialogActionSx?: any;
  }
) => void;
export function usePrompter(): [PrompterFace, setupPromoterFace] {
  const [state, setState] = useState<PrompterFace>(initPrompter);
  const setupPromoter: setupPromoterFace = (onYes, rest) => {
    setState({
      open: true,
      onYes: onYes,
      onClose: () => setState(initPrompter),
      ...rest,
    });
  };
  return [state, setupPromoter];
}
const f = () => {};
export const initPrompter = {
  onClose: f,
  open: false,
  yesText: "",
  noText: "",
  onYes: f,
  onNo: f,
  title: "",
  content: "",
  dialogMinWidth: "20rem",
  displayCloseButton: false,
  customDialogActionSx:{}
};
export default function Prompter(props: PrompterFace) {
  const {
    open,
    onClose,
    yesText,
    noText,
    onYes,
    onNo,
    title,
    content,
    dialogMinWidth,
    displayCloseButton,
    customDialogActionSx
  } = {
    ...initPrompter,
    ...props,
  };

  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);
  const displayYesText = yesText || te("yes");
  const displayNoText = noText || te("no");

  const onClickYes = async () => {
    const result = await onYes();
    if (result !== false) {
      onClose();
    }
  };
  const onClickNo = () => {
    onNo();
    onClose();
  };

  return (
    <Dialog
      onClose={() => onClickNo()}
      open={open}
      sx={{ "& .MuiPaper-root": { minWidth: dialogMinWidth } }}
    >
      {
        displayCloseButton ? 
        <DialogTitle sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row"
        }}>
          <Box>{title}</Box>
          <Box>
            <IconButton onClick={()=>onClickNo()}>
              <CloseIcon/>
            </IconButton>
          </Box>
        </DialogTitle>
        :
        <DialogTitle>{title}</DialogTitle>
      }
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={customDialogActionSx}>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => onClickYes()}
        >
          {displayYesText}
        </Button>
        <Button color="error" variant="contained" onClick={() => onClickNo()}>
          {displayNoText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
