import { decodeJwt } from "./../utils/helper";
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { ENDPOINT_HOST } from "../utils/config";
import { appError, networkError } from "../utils/errorHandler";
import { store } from "../reducer/store";
import { logout } from "../reducer/profileSlice";
import { alerting, setRedirectTo } from "../reducer/alerterSlice";
import { setIsRefreshingToken } from "../reducer/stuffSlice";
import { getNewTokens } from "./auth";
import {
  readAccessToken,
  clearTokens,
  readRefreshToken,
  writeTokens,
  getClientDateTimeDiffFromLocalStorage,
  setClientDateTimeDiff,
} from "../utils/helper";
if (!ENDPOINT_HOST) throw Error("endpoint host is undefined");
interface generalResponse {
  result: object;
  error: any;
  requestId?: string;
}
const getAxiosInstance = (baseURL: string) => {
  const config: AxiosRequestConfig = { baseURL };
  const instance: AxiosInstance = axios.create(config);
  const isRefreshingToken = store.getState().stuff.isRefreshingToken;

  instance.interceptors.request.use(
    async (config) => {
      const isIgnoreToken = "ignoreToken" in config;

      if (isIgnoreToken || isRefreshingToken) return config;

      // const isInvalidAccessToken = !(await ensureAccessToken());

      // if (isInvalidAccessToken) {
      //   throw appError({
      //     code: 1001009,
      //     message: "Invalid Access Token",
      //     requestId: "",
      //   });
      // }

      await ensureAccessToken();

      const accessToken = readAccessToken();

      if (accessToken) {
        config.headers = {
          Authorization: `Bearer ${accessToken} `,
        };
      }

      return config;
    },
    (error: AxiosError) => {
      console.error(error);
    }
  );

  instance.interceptors.response.use(
    (response: AxiosResponse<Partial<generalResponse>>) => {
      const data = response.data;
      const { requestId = "" } = data;
      if ("error" in data && response.data.error != null)
        return appError({ ...data.error, requestId });
      return data.result;
    },
    (error: AxiosError<Partial<generalResponse>>) => {
      if (error?.code === "ERR_CANCELED") return;
      if (!error) return;
      return networkError(error);
    }
  );
  return instance;
};

let isIdle = true;
export async function ensureAccessToken() {
  const isRefreshingToken = store.getState().stuff.isRefreshingToken;

  if (isRefreshingToken) return;

  const preAccessToken = readAccessToken();
  const preRefreshToken = readRefreshToken();

  if (!preAccessToken) {
    return forceLogout("No Access Token");
  }

  if (!preRefreshToken) {
    return forceLogout("No Refresh Token");
  }

  const isTokenExpired = checkTokenExpired(preAccessToken, preRefreshToken);
  if (isTokenExpired.refreshBuffer) return forceLogout("Refresh Token expired");

  if (isIdle) {
    if (isTokenExpired.accessBuffer) {
      isIdle = false;
      await fetchNextTokens(preRefreshToken);

      isIdle = true;
    }
    return true;
  }
  if (isTokenExpired.access) return false;
  return true;
}

async function fetchNextTokens(preRefreshToken: string) {
  store.dispatch(setIsRefreshingToken(true));

  const newTokens =
    (await getNewTokens(preRefreshToken, { ignoreToken: true })) || {};

  const { access_token = "", refresh_token = "" } = newTokens;

  if (!access_token || !refresh_token) {
    const message = `access_token:${access_token}\n refresh_token:${refresh_token}`;

    store.dispatch(setIsRefreshingToken(false));

    return forceLogout("invalid token");
  }

  const accessTokenInfo = decodeJwt(access_token);
  setClientDateTimeDiff(accessTokenInfo?.iat);

  store.dispatch(setIsRefreshingToken(false));
  writeTokens({ access_token, refresh_token });
}

export function forceLogout(reason: string, meta: any = undefined) {
  clearTokens();
  store.dispatch(logout());
  store.dispatch(setRedirectTo("/login"));
  saveLogoutReason(reason, meta);
  return false;
}

function saveLogoutReason(reason: string, meta: any) {
  const record = JSON.parse(localStorage.getItem("logoutRecord") || "[]");
  const newRecord = record
    .slice(-9)
    .concat({ reason, at: new Date(), ...meta });
  localStorage.setItem("logoutRecord", JSON.stringify(newRecord));
}

export function checkTokenExpired(acc: string, ref: string) {
  const accessRes = decodeJwt(acc)!;
  const refreshRes = decodeJwt(ref)!;

  const now = new Date().getTime();
  const clientDateTimeDiff = getClientDateTimeDiffFromLocalStorage();
  const clientDate = now - clientDateTimeDiff;

  const buffer = 1000 * 30;
  const shortBuffer = 1000 * 3;

  return {
    access: clientDate > accessRes.exp * 1000 - shortBuffer,
    refresh: clientDate > refreshRes.exp * 1000 - shortBuffer,
    accessBuffer: clientDate > accessRes.exp * 1000 - buffer,
    refreshBuffer: clientDate > refreshRes.exp * 1000 - buffer,
  };
}

export { getAxiosInstance };
const instance = getAxiosInstance(`${ENDPOINT_HOST}`);
export default instance;
