import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import WithdrawApprovalSetting from "./WithdrawApprovalSetting";
import WithDrawApproveSettingRequestRecord from "./WithDrawApproveSettingRequestRecord";
import { usePermission, useTranslation } from "../../hooks";
import { useZusListStore } from "../../zustand/store";
import { FeatureCodes } from "../../utils/constant";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const translatePrefix = "withdrawApprovalSetting";
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index} //hidden all if no index==value
      id={`simple-tabpanel-${index}`} //showing which tab
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && ( //if value match, then display the target tab-content
        <Box>{children}</Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const WithDrawApprovalSettingSwitchTabs = () => {
  const [tabsIndex, setTabIndex] = React.useState(0); //the default index :which tab
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const clearZusList = useZusListStore((state) => state.clear);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    clearZusList();
  };
  const withdrawApprovealTabs = [
    {
      name: "withdraw_approval_setting",
      path: Pkey.TabSetting.prefix,
      index: 0,
      jsxNode: <WithdrawApprovalSetting />,
    },
    {
      name: "request_record",
      path: Pkey.TabSetting.prefix,
      index: 1,
      jsxNode: <WithDrawApproveSettingRequestRecord />,
    },
  ].filter((item) => hasPermission(item.path));

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {withdrawApprovealTabs.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {/* Real Tab Content */}
      {withdrawApprovealTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default WithDrawApprovalSettingSwitchTabs;
