import { defaultTheme } from 'react-admin';
import type { RaThemeOptions } from 'react-admin';

export const theme: RaThemeOptions = {
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        secondary: {
            main: '#4A90F7',
        },
        info: {
            main: '#9D9D9D',
            contrastText: "#fff"
        },
    },
    typography: {
        fontSize: 11
    },
    sidebar: {
        width: 320,
        closedWidth: 320,
    },
    components: {
        ...defaultTheme.components,
        // @ts-ignore react-admin doesn't add its own components
        RaLocalesMenuButton: {
            styleOverrides: {
                root: {
                    ".css-azl7ko-MuiButtonBase-root-MuiButton-root": {
                        fontSize: "0.5rem",
                    },
                    svg: {
                        "&.MuiSvgIcon-fontSizeMedium": {
                            display: "none",
                        },
                    },
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    "&.RaMenuItemLink-active": {
                        color: "#4A90F7"
                    },
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0px 20px 10px 20px',
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '.MuiTableCell-head': {
                        padding: '15px 5px 5px 5px',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
                root: {
                    backgroundColor: '#EAEAEA',
                    border: 'none',
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "white",
                        color: "#A2A2A2",
                        boxShadow: "none",
                        height: "40px"
                    },
                    '& .MuiDataGrid-cell': {
                        borderBottomColor: "white",
                    },
                    '& .MuiDataGrid-footerContainer': {
                        backgroundColor: "white",
                    },
                    svg: {
                        "&.MuiDataGrid-iconSeparator": {
                            display: "none"
                        },
                    }
                },
            },
        },
    },
};