import { useEffect, useState } from "react";

import { FeatureCode } from "@wallet-manager/node-types";
import EnumDirection from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerDirection";
import EnumTransactionType from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerTransactionType";

import { getLedgerTransactionRecordExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  NodeTypesEnumMultiSelection,
  NodeTypesEnumSingleSelection,
} from "../../../components/GeneralSelection";
import MpTextField from "../../../components/MpTextField";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance } from "../../../zustand/store";
import LedgerTransactionRecordList from "./LedgerTransactionRecordList";

const translatePrefix = "ledgerTransactionRecord";

const Pkey = FeatureCode.FeaturesLedger.LedgerTransactionRecord;

interface Ifields {
  transaction_types: string[];
  direction: string;
  client_id: string;
  sending_client_id: string;
  receiving_client_id: string;
  chain_name: string;
  asset_names: string[];
  original_order_id: string;
  merchant_order_id: string;
  ledger_transaction_id: string;
}
interface ZusParamsFace {
  trans_types: string[];
  direction: string;
  client_ids: string[];
  from_client_ids: string[];
  to_client_ids: string[];
  chain_type: number | undefined;
  chain_id: string | undefined;
  asset_names: string[];
  originalMerchantOrderIds: string[];
  merchantOrderIds: string[];
  id: string;
  date_created_from: string;
  date_created_to: string;
  pageSize: number;
  page: number;
}

const initFields = {
  transaction_types: [] as string[],
  direction: "",
  client_id: "",
  sending_client_id: "",
  receiving_client_id: "",
  chain_name: "",
  asset_names: [] as string[],
  original_order_id: "",
  merchant_order_id: "",
  ledger_transaction_id: "",
} as const;

const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;

export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);

export default function LedgerTransactionRecord() {
  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const { t, tc, te } = useTranslation(translatePrefix);
  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getLedgerTransactionRecordExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];

    const res = listMapping("name", rawRes, { t, te, omitKeys });
    const config = {};
    downloadFiles(`Ledger Transaction Record`, res, config);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <LedgerTransactionRecordList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
  };

  const getParams: () => ZusParamsFace = () => {
    const {
      asset_names,
      chain_name,
      transaction_types: trans_types,
      direction,
      ledger_transaction_id: id,
      ...rest
    } = fields;
    const transformFieldFromStrToArr = (fieldValue: string) => {
      const rawArr = fieldValue.split(",");

      const fieldValueArr = rawArr
        .filter((item) => item)
        .map((item) => item.trim());
      return fieldValueArr;
    };
    const {
      client_id: client_ids,
      sending_client_id: from_client_ids,
      receiving_client_id: to_client_ids,
      original_order_id: originalMerchantOrderIds,
      merchant_order_id: merchantOrderIds,
    } = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        transformFieldFromStrToArr(value),
      ])
    );
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const { CreationTime } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;

    return {
      ...initZusParams,
      id,
      trans_types,
      direction,
      client_ids,
      from_client_ids,
      to_client_ids,
      chain_id,
      chain_type,
      asset_names,
      originalMerchantOrderIds,
      merchantOrderIds,
      date_created_from,
      date_created_to,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    [
      "transaction_type",
      <NodeTypesEnumMultiSelection
        label={t("ph_transaction_type")}
        onChange={(transaction_types) =>
          setFields((f) => ({
            ...f,
            transaction_types,
          }))
        }
        value={fields.transaction_types}
        enumData={EnumTransactionType}
        isNoSorting
      />,
    ],
    [
      "direction",
      <NodeTypesEnumSingleSelection
        value={fields.direction}
        label={t("ph_direction")}
        enumData={EnumDirection}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, direction: "" }))}
        onChange={onChange("direction")}
      />,
    ],
    [
      "sending_client_id",
      <MpTextFieldMultiple
        value={fields.sending_client_id}
        onChange={onChange("sending_client_id")}
        label={t("ph_sending_client_id")}
        count={apiParams.from_client_ids.length}
      />,
    ],
    [
      "client_id",
      <MpTextFieldMultiple
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
        count={apiParams.client_ids.length}
      />,
    ],
    [
      "receiving_client_id",
      <MpTextFieldMultiple
        value={fields.receiving_client_id}
        onChange={onChange("receiving_client_id")}
        label={t("ph_receiving_client_id")}
        count={apiParams.to_client_ids.length}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "original_order_id",
      <MpTextFieldMultiple
        value={fields.original_order_id}
        onChange={onChange("original_order_id")}
        label={t("ph_original_order_id")}
        count={apiParams.originalMerchantOrderIds.length}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextFieldMultiple
        value={fields.merchant_order_id}
        onChange={onChange("merchant_order_id")}
        label={t("ph_merchant_order_id")}
        count={apiParams.merchantOrderIds.length}
      />,
    ],
    [
      "ledger_transaction_id",
      <MpTextField
        value={fields.ledger_transaction_id}
        onChange={onChange("ledger_transaction_id")}
        label={t("ph_ledger_transaction_id")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.transaction_type}
        {F.direction}
        {F.client_id}
        {F.sending_client_id}
        {F.receiving_client_id}
        {F.chain_name}
        {F.currency}
        {F.original_order_id}
        {F.merchant_order_id}
        {F.ledger_transaction_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    const chainInfo = findChainInfo(item.chain_type, item.chain_id);
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      ["rawData", "", item],
      ["ledger_transaction_id", t("ledger_transaction_id"), item.id],
      [
        "original_order_id",
        t("original_order_id"),
        item.original_merchant_order_id,
      ],
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      ["client_id", t("client_id"), item.client_id],

      [
        "transaction_type",
        t("transaction_type"),
        te(EnumTransactionType[item.trans_type]),
      ],
      ["direction", t("direction"), te(EnumDirection[item.direction])],
      ["sending_client_id", t("sending_client_id"), item.from_client_id],
      [
        "sending_wallet_address",
        t("sending_wallet_address"),
        item.from_address,
      ],
      ["receiving_client_id", t("receiving_client_id"), item.to_client_id],
      [
        "receiving_wallet_address",
        t("receiving_wallet_address"),
        item.to_address,
      ],
      ["chain_name", t("chain_name"), chainInfo?.name],
      ["currency", t("currency"), item.asset_name],
      [
        "transaction_amount",
        t("transaction_amount"),
        displayAmount(item.amount, decimals),
      ],
      [
        "freeze_amount",
        t("freeze_amount"),
        displayAmount(item.freeze_amount, decimals),
      ],
      [
        "post_frozen_balance",
        t("post_frozen_balance"),
        displayAmount(item.post_frozen_balance, decimals),
      ],
      [
        "post_balance",
        t("post_balance"),
        displayAmount(item.post_balance, decimals),
      ],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      ["remarks", t("remarks"), item.remarks],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
