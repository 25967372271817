import BigNumber from "bignumber.js";
import cond from "lodash/cond";
import isNil from "lodash/isNil";
import { useEffect, useRef, useState } from "react";
import { useTranslate } from "react-admin";

import { ChainType as ChainTypeConst } from "@wallet-manager/node-types/dist/src/postgres/const";
import EnumRiskLevel from "@wallet-manager/node-types/dist/types/postgres/const/RiskLevel";
import EnumWalletType from "@wallet-manager/node-types/dist/types/postgres/const/WalletType";

import { batchSweep, batchSweepPreview } from "../../api/merchant";
import AddressSelection from "../../components/AddressSelection";
import { AssetSingleSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useDatePicker } from "../../components/DatePicker";
import { useAssets, useChains } from "../../components/FetchConfig";
import {
  MultiSelection,
  NodeTypesEnumSingleSelection,
  SingleSelection,
} from "../../components/GeneralSelection";
import MpTextField from "../../components/MpTextField";
import { Box, Button, Container } from "../../components/MuiGenerals";
import { useAlerting, usePermission } from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { ProfileLoginFace, selectProfile } from "../../reducer/profileSlice";
import { EnumNoRiskWallet, FeatureCodes } from "../../utils/constant";
import {
  amountDivideDecimals,
  bigNumStrMulitpleDecimals,
  containsOnlyNumbers,
  displayAmount,
  findDecimalByChainNameAndAsset,
  onlyAllowInteger,
  removeCommasFromNumStr,
  toDBInDecimals,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import { useZusDialogStore } from "../../zustand/store";
import styles from "./batchSweep.module.scss";
import BatchSweepList from "./BatchSweepList";

const enumRawRiskLevelSelection = {
  NoAssessment: EnumRiskLevel.NoAssessment,
  NoRiskDetected: EnumRiskLevel.NoRiskDetected,
  Info: EnumRiskLevel.Info,
  Caution: EnumRiskLevel.Caution,
  Medium: EnumRiskLevel.Medium,
  High: EnumRiskLevel.High,
  Critical: EnumRiskLevel.Critical,
};
function getEnumRiskByMerchantLevel(merchantLevel: number) {
  let riskLevelObj: Record<string, string> = { risk_acceptant: "acceptant" };
  let acceptantLevelObj: Record<string, string> = {};
  for (let key_ in enumRawRiskLevelSelection) {
    const key = key_ as keyof typeof enumRawRiskLevelSelection;
    const val = enumRawRiskLevelSelection[key];
    if (val > merchantLevel) {
      riskLevelObj[key] = String(val);
    } else {
      acceptantLevelObj[key] = String(val);
    }
  }
  return { risk: riskLevelObj, acceptant: acceptantLevelObj };
}

const Pkey = FeatureCodes.assetManagement.BatchSweep;
interface fieldsFace {
  max_sweepable_address: string;
  chain_name: string;
  gather_address: string;
  invoker_address: string;
  asset_name: string;
  threshold: string;
  risk_level: string;
  acceptant_risk_level_array: string[];
  include_no_risk_level_wallet: string;
}
const initFields = {
  max_sweepable_address: "1000",
  chain_name: "",
  gather_address: "",
  invoker_address: "",
  asset_name: "",
  threshold: "",
  risk_level: "acceptant",
  acceptant_risk_level_array: [],
  include_no_risk_level_wallet: `${EnumNoRiskWallet.notIncludeNoRiskWallet}`,
};

const sx = {
  wallet_name: {
    fontWeight: "bold",
  },
  filterWarning: {
    pt: 3,
    color: "red",
  },
  preview: {
    maxWidth: "10rem",
    margin: " 0 auto",
  },
  previewHeader: {
    display: "flex",
    margin: "auto",
    maxWidth: "40rem",
    justifyContent: "space-evenly",
  },
  previewActions: {
    display: "flex",
    justifyContent: "space-around",
  },
};

export default function BatchSweep() {
  const [list, setList] = useState<any[]>([]);
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const WalletSettlementTime = useDatePicker();
  const [previewParams, setPreviewParams] = useState({
    chain_name: "",
    currency: "",
    decimals: 1,
  });
  useEffect(() => {
    setFields((fields: any) => ({ ...fields, asset_name: "" }));
  }, [fields.chain_name]);
  const setInvokerAddress = (invoker_address: string) =>
    setFields((f: any) => ({ ...f, invoker_address }));
  const { selectAssetsByChain } = useAssets();
  const { chainObj, selectChainByPieces } = useChains();
  const translate = useTranslate();
  const t = (key: string, params = {}) =>
    translate(`assetManagement.${key}`, params);
  const profile = useAppSelector(selectProfile);
  const params = getParams(fields, selectChainByPieces, profile, {
    wallet_settlement_date_from: WalletSettlementTime.start || undefined,
    wallet_settlement_date_to: WalletSettlementTime.end || undefined,
  });
  const isSecondLayerToken = ((): boolean => {
    if (!(fields.chain_name && fields.asset_name)) {
      return false;
    }
    const { chain_id, chain_type } = selectChainByPieces({
      chain_name: fields.chain_name,
    });
    const isSecond = Object.values(chainObj).some(
      (obj) =>
        obj.chain_type === chain_type &&
        obj.chain_id === chain_id &&
        obj.native_asset_name !== fields.asset_name
    );
    return isSecond;
  })();
  const sweepingParamsRef = useRef<{
    limit: string;
    threshold: string;
    decimals: string;
    chain_id: any;
    chain_type: any;
    gather_address: string;
    invoker_address: string;
    asset_name: string;
    risk_level: EnumRiskLevel[];
  }>({
    limit: "",
    threshold: "",
    decimals: "",
    chain_id: "",
    chain_type: "",
    gather_address: "",
    invoker_address: "",
    asset_name: "",
    risk_level: [],
  });
  const assets = selectAssetsByChain({ chain_name: fields.chain_name });

  const selectedAsset = assets.find(
    (item) => item.asset_name === fields.asset_name
  );
  const rawReserveBalance = selectedAsset?.min_reserve_balance || "0";
  //decimals is supposed to be string, it's '-' when no currency found
  const reserveBalance =
    amountDivideDecimals(rawReserveBalance, Number(params.decimals)) || "0";
  const selectedAssetName = selectedAsset?.asset_name || "";
  const onNewList = (rawList: any[]) => {
    if (!rawList) return;
    const chain_name = chainObj[fields.chain_name].name;
    const currency = params.asset_name;
    setPreviewParams({
      chain_name,
      decimals: Number(params.decimals),
      currency,
    });
    const list = rawList.map((item) => {
      const diff = BigNumber(item.amount).minus(BigNumber(rawReserveBalance));
      const sweepableAmount = BigNumber.max(diff, 0);
      return { ...item, sweepableAmount };
    });
    if (!list) return;
    setList(list);
    const { include_no_risk_level_wallet, ...rest } = params;
    sweepingParamsRef.current = rest;
  };

  const onSweep = (
    sweep_records?: { from_address: string; amount: string; decimals: number }[]
  ) => {
    const invoker_address = isSecondLayerToken ? fields.invoker_address : "";
    const params = { ...sweepingParamsRef.current, invoker_address };
    return batchSweep({ ...params, sweep_records });
  };
  const onReset = () => {
    setFields(initFields);
    WalletSettlementTime.resetDate();
    setList([]);
  };
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          fields={fields}
          setFields={setFields}
          WalletSettlementTime={WalletSettlementTime}
          setList={onNewList}
          reserveBalance={reserveBalance}
          selectedAssetName={selectedAssetName}
        />
      </Container>
      {list.length > 0 && (
        <>
          <DivideLine />
          <Container
            style={customSx.datagridContainer}
            maxWidth={false}
            disableGutters
          >
            <Preview
              list={list}
              sweepFn={onSweep}
              reset={onReset}
              chain_name={previewParams.chain_name}
              invoker_address={fields.invoker_address}
              setInvokerAddress={setInvokerAddress}
              currency={previewParams.currency}
              isSecondLayerToken={isSecondLayerToken}
            />
          </Container>
        </>
      )}
    </Box>
  );
}
const mustFill = ["chain_name", "gather_address", "asset_name", "threshold"];
function getParams(
  fields: fieldsFace,
  selectChainByPieces: any,
  profile: ProfileLoginFace,
  settlementTime: {
    wallet_settlement_date_from: string | undefined;
    wallet_settlement_date_to: string | undefined;
  }
) {
  const {
    chain_name,
    max_sweepable_address,
    acceptant_risk_level_array,
    risk_level,
    include_no_risk_level_wallet: include_no_risk_level_wallet_str,
    ...rest
  } = fields;
  const { chain_id, chain_type } = selectChainByPieces({ chain_name });
  const decimals = findDecimalByChainNameAndAsset(
    String(chain_type),
    String(chain_id),
    fields.asset_name
  );
  const defaultCase = () => true;
  const theRiskLevel = cond([
    [() => profile.chain_analysis === false, () => []],
    [
      () => acceptant_risk_level_array.length > 0,
      () => acceptant_risk_level_array,
    ],
    [() => risk_level !== "acceptant", () => [risk_level]],
    [defaultCase, () => []],
  ])().map((ele: string) => Number(ele));
  const include_no_risk_level_wallet = !!Number(
    include_no_risk_level_wallet_str
  );

  return {
    ...rest,
    limit: max_sweepable_address,
    threshold: toDBInDecimals(fields.threshold, decimals),
    risk_level: theRiskLevel,
    include_no_risk_level_wallet,
    decimals,
    chain_id,
    chain_type,
    ...settlementTime,
  };
}
export function FilterBar(props: any) {
  const {
    setList,
    fields,
    setFields,
    reserveBalance,
    selectedAssetName,
    WalletSettlementTime,
  } = props;
  const hasReverve = reserveBalance !== 0;
  const translate = useTranslate();
  const t = (key: string, params = {}) =>
    translate(`assetManagement.${key}`, params);
  const profile = useAppSelector(selectProfile);
  const enumRisks = getEnumRiskByMerchantLevel(profile.acceptance_level);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces, chainObj } = useChains();
  const { alerting } = useAlerting();
  const setGatherAddress = (gather_address: string) =>
    setFields((f: any) => ({ ...f, gather_address }));
  const setAssets = (asset_name: string) =>
    setFields((fields: any) => ({ ...fields, asset_name }));
  const checkErrorFields = (): Object[] =>
    mustFill.filter((key) => isNil(fields[key]));
  useEffect(() => {
    if (fields.risk_level !== "acceptant")
      setFields((f: any) => ({ ...f, acceptant_risk_level_array: [] }));
  }, [fields.risk_level]);
  useEffect(() => {
    const showDecimal = fields.chain_name && fields.asset_name;
    if (!showDecimal) return;
    if (decimals === "-") alerting("warning", t("assetNotSupported"));
  }, [fields.chain_name, fields.asset_name]);
  const zusDialog = useZusDialogStore();
  const params = getParams(fields, selectChainByPieces, profile, {
    wallet_settlement_date_from: WalletSettlementTime.start || undefined,
    wallet_settlement_date_to: WalletSettlementTime.end || undefined,
  });
  const { decimals } = params;
  const asset_names = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const onPreview = async () => {
    if (Number(fields.max_sweepable_address) < 1) {
      return alerting("warning", t("minLessThanOne"));
    }
    if (Number(fields.max_sweepable_address) > 1000) {
      return alerting("warning", t("maxLargerThanOneThousand"));
    }
    const errorFields = checkErrorFields();
    if (errorFields.length > 0) {
      const message = `Missing fields ${errorFields.join(", ")}`;
      return alerting("warning", message);
    }
    if (Number(fields.threshold) <= reserveBalance) {
      const tParams = { asset: selectedAssetName, amount: reserveBalance };
      return alerting("warning", t("preview_warning", tParams));
    }
    if (
      profile.chain_analysis &&
      fields.risk_level === "acceptant" &&
      fields.acceptant_risk_level_array.length === 0
    ) {
      return alerting(
        "warning",
        t("ph_batch_sweep_acceptant_risk_level_warning")
      );
    }
    zusDialog.openExtra("loadingDialog");
    const { invoker_address, ...previewParams } = params;
    const { sweep_records = [] } =
      (await batchSweepPreview(previewParams)) ?? {};
    zusDialog.closeExtra();
    setList(sweep_records);
  };

  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.trim();
      const name = e.target.name;
      if (name === "max_sweepable_address") {
        const isInteger = onlyAllowInteger(value);
        if (!isInteger) {
          return alerting("warning", t("allowIntOnly"));
        }
      }
      const resp = name === "threshold" ? containsOnlyNumbers(value) : true;
      if (!resp) {
        return;
      }

      setFields((fields: any) => ({ ...fields, [type]: value }));
    };
  const canPreview =
    fields.asset_name.length > 0 &&
    fields.threshold !== "" &&
    fields.gather_address.length > 0;
  const warningMessage = [
    t("minimum_reserve_requirement"),
    reserveBalance,
    selectedAssetName,
  ].join(" ");

  const isXrp = fields.asset_name === "XRP" && fields.chain_name === "XRPT";
  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) =>
          setFields((f: any) => ({ ...f, chain_name }))
        }
        choice={fields.chain_name}
      />,
    ],
    [
      "address",
      <AddressSelection
        chain_name={fields.chain_name}
        setGatherAddress={setGatherAddress}
        gather_address={fields.gather_address}
        wallet_types={[
          EnumWalletType.HotWallet,
          EnumWalletType.SweepDestWallet,
        ]} //Hot Wallet and Sweep Dest
      />,
    ],

    [
      "asset_name",
      <AssetSingleSelection
        label={t("phAsset_name")}
        setChoice={setAssets}
        choice={fields.asset_name}
        allItems={asset_names}
      />,
    ],
    [
      "threshold",
      <MpTextField
        name={"threshold"}
        label={t("phThreshold")}
        type="string"
        value={fields.threshold}
        onChange={onChange("threshold")}
        helperText={hasReverve && isXrp && warningMessage}
      />,
    ],
    [
      "max_sweepable_address",
      <MpTextField
        name={"max_sweepable_address"}
        label={t("ph_max_sweepable_address")}
        value={fields.max_sweepable_address}
        onChange={onChange("max_sweepable_address")}
      />,
    ],
    [
      "risk_level",
      <SingleSelection
        label={t("ph_batch_sweep_risk_level")}
        value={fields.risk_level}
        onChange={(e) =>
          setFields((f: any) => ({ ...f, risk_level: e.target.value }))
        }
        enumData={enumRisks.risk}
        disabled={!profile.chain_analysis}
        isNoSorting
        clearSelect={() =>
          setFields((f: any) => ({ ...f, risk_level: "acceptant" }))
        }
      />,
    ],
    [
      "acceptant_risk_level",
      <MultiSelection
        label={t("ph_batch_sweep_acceptant_risk_level")}
        value={fields.acceptant_risk_level_array}
        onChange={(e) => {
          const newArray = e.target.value;
          setFields((f: any) => ({
            ...f,
            acceptant_risk_level_array: newArray,
          }));
        }}
        disabled={fields.risk_level !== "acceptant" || !profile.chain_analysis}
        enumData={enumRisks.acceptant}
        isNoSorting
      />,
    ],
    [
      "include_no_risk_level_wallet_filter",
      <NodeTypesEnumSingleSelection
        label={""}
        value={fields.include_no_risk_level_wallet}
        onChange={(e) =>
          setFields((f: any) => ({
            ...f,
            include_no_risk_level_wallet: e.target.value,
          }))
        }
        enumData={EnumNoRiskWallet}
        isNoSorting
        clearSelect={() => {}}
      />,
    ],
    [
      "wallet_settlement_time",
      <WalletSettlementTime.Picker type={"dateTime"} />,
    ],
  ]);

  return (
    <Container disableGutters maxWidth={false}>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.asset_name}
        {F.address}
        {F.threshold}
        {F.risk_level}
        {F.acceptant_risk_level}
        {F.max_sweepable_address}
        {F.include_no_risk_level_wallet_filter}
        {F.wallet_settlement_time}
      </Box>

      <Box sx={customSx.filterB}>
        <Button
          disabled={!canPreview}
          onClick={onPreview}
          sx={sx.preview}
          color="secondary"
          variant="contained"
        >
          {t("preview")}
        </Button>
      </Box>
    </Container>
  );
}
const blankFn = () => {};
//
function Preview(props: {
  list: any[];
  reset: () => void;
  sweepFn: (
    sweep_records?: { from_address: string; amount: string; decimals: number }[]
  ) => Promise<any>;
  chain_name: string;
  invoker_address: string;
  setInvokerAddress: (invoker_address: string) => void;
  currency: string;
  isSecondLayerToken: boolean;
}) {
  const {
    list = [],
    reset = blankFn,
    sweepFn = blankFn,
    chain_name = "",
    invoker_address = "",
    setInvokerAddress,
    currency = "",
    isSecondLayerToken,
  } = props;
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const inputFieldRef = useRef<HTMLInputElement>(null);
  const previousInputFieldValue = useRef<string>("");
  const inputDivRef = useRef<HTMLDivElement>(null);
  const [specificSweepAmount, setSpecificSweepAmount] = useState("");
  const [addressArr, setAddressArr] = useState<
    { fromAddress: string; amountToSweep: string; isChecked: boolean }[]
  >([]);

  const { chainObj } = useChains();
  const utxoChainTypeArr = [
    ChainTypeConst.BTC,
    ChainTypeConst.BCH,
    ChainTypeConst.LTC,
    ChainTypeConst.ADA,
    ChainTypeConst.DOGE,
  ];
  const { chain_type, type: chain_shortname } = Object.entries(chainObj)
    .filter(([_type, chainInfo]) => chainInfo.name === chain_name)
    .map(([_type, chainInfo]) => chainInfo)[0];
  const isUTXOType = utxoChainTypeArr.includes(chain_type);
  const [newList, setNewList] = useState<any[]>([]);
  const tableBodyRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    setAddressArr(
      list.map(({ from_address: fromAddress, sweepableAmount }) => ({
        fromAddress,
        amountToSweep: amountDivideDecimals(sweepableAmount, decimals),
        isChecked: true,
      }))
    );
    setSpecificSweepAmount("");
    inputFieldRef.current!.value = "";
    setNewList(() => {
      return (list || []).map((item: any, index: number) => {
        const { amount, path, wallet_address, sweepableAmount, ...rest } = item;
        return {
          ...rest,
          originalAmount: sweepableAmount,
          amount: displayAmount(sweepableAmount, item.decimals),
          id: index + 1,
        };
      });
    });
  }, [list]);
  const t = (key: string) => translate(`assetManagement.${key}`);
  const total = list.length;
  let sum = BigNumber(0);
  for (let { sweepableAmount } of list) {
    sum = sum.plus(sweepableAmount);
  }
  const { decimals } = list[0];
  const sumString = displayAmount(sum.toFixed(), decimals);

  const totalAmountToSweep = displayAmount(
    addressArr
      .reduce((acc, { amountToSweep }) => {
        if (!Number(amountToSweep)) {
          return acc;
        }

        return acc.plus(
          BigNumber(bigNumStrMulitpleDecimals(amountToSweep, decimals))
        );
      }, BigNumber(0))
      .toFixed(),
    decimals
  );

  const onPreview = async () => {
    if (addressArr.every(({ isChecked }) => !isChecked)) {
      return alerting("error", t("no_address_warning"));
    }
    const zeroOrEmpty = addressArr.find(
      ({ amountToSweep, isChecked }) =>
        (amountToSweep === "0" || !BigNumber(amountToSweep)) && isChecked
    );
    if (zeroOrEmpty) {
      return alerting("error", t("bigger_than_0_warning"));
    }
    if (isSecondLayerToken && invoker_address == "") {
      return alerting("warning", t("no_invoker_address_warning"));
    }
    const sweepRecordArr = addressArr
      .filter(({ isChecked }) => isChecked)
      .map(({ amountToSweep, fromAddress: from_address }) => ({
        amount: bigNumStrMulitpleDecimals(amountToSweep, decimals),
        from_address,
        decimals,
      }));
    const res = await sweepFn(sweepRecordArr);
    if (!res) return;
    const message = `${
      t("cryptSweeped") + ", " + t("batch_id") + ": " + res.batch_id
    }`;
    alerting("success", message);
  };

  const autoCalBtnHandler = () => {
    if (!Number(specificSweepAmount)) {
      return alerting("error", t("bigger_than_0_warning"));
    }
    let toSweepAmount = BigNumber(
      bigNumStrMulitpleDecimals(specificSweepAmount, decimals)
    );
    if (toSweepAmount.isGreaterThan(sum)) {
      return alerting("error", t("excessive_amt_warning"));
    }

    const toSweepAmountArr: {
      fromAddress: any;
      amountToSweep: string;
      isChecked: boolean;
    }[] = [];
    for (let i = 0; i < list.length; i++) {
      const { sweepableAmount, from_address: fromAddress } = list[i];
      if (toSweepAmount.isEqualTo(BigNumber(0))) {
        toSweepAmountArr.push({
          fromAddress,
          amountToSweep: "0",
          isChecked: false,
        });
      } else if (
        BigNumber(sweepableAmount).isGreaterThanOrEqualTo(toSweepAmount)
      ) {
        toSweepAmountArr.push({
          fromAddress,
          amountToSweep: amountDivideDecimals(
            toSweepAmount.toFixed(),
            decimals
          ),
          isChecked: true,
        });
        toSweepAmount = BigNumber(0);
      } else {
        toSweepAmount = toSweepAmount.minus(BigNumber(sweepableAmount));
        toSweepAmountArr.push({
          fromAddress,
          amountToSweep: amountDivideDecimals(sweepableAmount, decimals),
          isChecked: true,
        });
      }
      if (i === list.length - 1) {
        setAddressArr(toSweepAmountArr);
        const checkboxElementArr = tableBodyRef.current
          ? (tableBodyRef.current.querySelectorAll(
              "input[type='checkbox']"
            ) as NodeListOf<HTMLInputElement>)
          : [];
        const inputFieldElementArr = tableBodyRef.current
          ? (tableBodyRef.current.querySelectorAll(
              "input[name='amountToSweep']"
            ) as NodeListOf<HTMLInputElement>)
          : [];
        checkboxElementArr.forEach(
          (ele, i) => (ele.checked = toSweepAmountArr[i].isChecked)
        );
        inputFieldElementArr.forEach((ele, i) => {
          ele.value = toSweepAmountArr[i].amountToSweep;
          if (!Number(toSweepAmountArr[i].amountToSweep)) {
            ele.setAttribute("disabled", "true");
          } else {
            ele.removeAttribute("disabled");
          }
        });
      }
    }
  };
  const F = genField({ t }, [
    [
      "chain_name_and_currency",
      <div>
        {chain_name} / {currency}
      </div>,
    ],
    ["max_address_no", <div>{list.length}</div>],
    ["max_sweepable_amount", <div>{sumString}</div>],
    ["decimals", <div>{decimals}</div>],
    [
      "address_selected",
      <div>{addressArr.filter(({ isChecked }) => isChecked).length}</div>,
    ],
    ["amount_to_sweep", <div>{totalAmountToSweep}</div>],
    [
      "specific_sweep_amount",
      <div style={{ display: "flex", height: "100%" }}>
        <MpTextField
          inputRef={inputFieldRef}
          innerRef={inputDivRef}
          disabled={isUTXOType}
          onChange={(e) => {
            const valueWithoutCommas = removeCommasFromNumStr(e.target.value);

            if (!containsOnlyNumbers(valueWithoutCommas)) {
              inputFieldRef.current!.value = previousInputFieldValue.current;
              return;
            }
            const inputDecimals = e.target.value.split(".")[1]
              ? e.target.value.split(".")[1].length
              : 0;
            if (inputDecimals > decimals) {
              inputFieldRef.current!.value = inputFieldRef.current!.value.slice(
                0,
                -1
              );

              return;
            }
            inputFieldRef.current!.value = valueWithoutCommas;
            previousInputFieldValue.current = valueWithoutCommas;
          }}
          onBlur={() => setSpecificSweepAmount(inputFieldRef.current!.value)}
          placeholder={t("ph_specific_sweep_amount")}
          sx={{
            height: "100% !important",
            div: { height: "100% !important" },
            input: { paddingTop: "12px", height: "100%" },
          }}
        />
        <Button
          disabled={isUTXOType}
          color="secondary"
          variant="contained"
          style={{ height: "32px", marginTop: "6px", marginLeft: "12px" }}
          onClick={autoCalBtnHandler}
        >
          {t("auto_cal")}
        </Button>
      </div>,
    ],
  ]);
  if (total === 0) return <></>;
  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <Box
        sx={{
          ...customSx.threeColumnsgridFilter,
          background: "#eee",
          margin: "8px 0",
          padding: "16px",
          fontSize: "14px !important",
          lineHeight: "normal",

          "div>div": {
            margin: "10px 0",
            lineHeight: "1.27rem",
            color: "#4a90f7",
            fontSize: "14px",
            height: "100%",
          },

          h6: {
            padding: "0px",
            margin: "10px 0",
            fontSize: "14px !important",
            lineHeight: "1.27rem !important",
          },
        }}
        className={styles.previewSummary}
      >
        {F.chain_name_and_currency}
        {F.max_address_no}
        {F.max_sweepable_amount}
        {F.decimals}
        {F.address_selected}
        {F.amount_to_sweep}
      </Box>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          ".MuiInputLabel-shrink": {
            display: "none",
          },
          h6: {
            fontSize: "13px !important",
            marginTop: "-10px",
            marginRight: 4,
          },
          "div>div>div": {
            width: 200,
            input: {
              paddingTop: "8px",
            },
          },
        }}
        className="gridFilter"
      >
        {F.specific_sweep_amount}
      </Box>
      <br />
      <BatchSweepList
        chain_name={chain_shortname}
        invoker_address={invoker_address}
        setInvokerAddress={setInvokerAddress}
        list={newList}
        addressArr={addressArr}
        setAddressArr={setAddressArr}
        totalAmountToSweep={totalAmountToSweep}
        sumString={sumString}
        isUTXOType={isUTXOType}
        tableBodyRef={tableBodyRef}
        isSecondLayerToken={isSecondLayerToken}
      />
      <div style={sx.previewActions}>
        {hasPermission(Pkey.CoinSweep) && (
          <Button onClick={onPreview} color="secondary" variant="contained">
            {t("coinSweep")}
          </Button>
        )}
        <Button color="info" onClick={reset} variant="contained">
          {t("reset")}
        </Button>
      </div>
    </Container>
  );
}
