import { useEffect, useState } from "react";

import EnumCreatorType from "@wallet-manager/node-types/dist/src/postgres/const/CreatorType";
import EnumRequestStatus from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerRequestStatus";
import EnumWMOperationStatus from "@wallet-manager/node-types/dist/types/postgres/const/OperationStatus";

import { getLedgerWithdrawRequestExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  NodeTypesEnumMultiSelection,
  NodeTypesEnumSingleSelection,
} from "../../../components/GeneralSelection";
import MpTextField from "../../../components/MpTextField";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Button,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance, useZusDialogStore } from "../../../zustand/store";
import { exportApprovalDetailConverter } from "../commonFunction";
import WithdrawRequestList from "./WithdrawRequestList";

const translatePrefix = "ledgerWithdrawRequest";

enum EnumCreatorTypeForDisplay {
  Portal = EnumCreatorType.Portal,
  Api = EnumCreatorType.Api,
}
interface Ifields {
  chain_name: string;
  asset_names: string[];
  sending_address: string;
  receiving_address: string;
  request_statuses: string[];
  wm_operation_statuses: string[];
  merchant_order_id: string;
  refund_order_id: string;
  transaction_hash: string;
  creator_type: string;
}
interface ZusParamsFace {
  chain_id: string | undefined;
  chain_type: number | undefined;
  asset_names: string[];
  hot_wallet_address: string;
  to_address: string;
  statuses: string[];
  wallet_manager_statuses: string[];
  merchant_order_ids: string[];
  refund_order_ids: string[];
  tx_hashes: string[];
  creator_type: string;
  date_created_from: string;
  date_created_to: string;
  date_last_modified_from: string;
  date_last_modified_to: string;
  pageSize: number;
  page: number;
}

const initFields = {
  chain_name: "",
  asset_names: [] as string[],
  sending_address: "",
  receiving_address: "",
  request_statuses: [] as string[],
  wm_operation_statuses: [] as string[],
  merchant_order_id: "",
  refund_order_id: "",
  transaction_hash: "",
  creator_type: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;
export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);
const Pkey = FeatureCodes.ledger.WithdrawRequest;

export const reversedEnumFunction: (
  enumObj: Record<string, string>
) => Record<string, string> = (enumObj) =>
  Object.fromEntries(
    Object.entries(enumObj).map(([key, value]) => [value, key])
  );

export default function WithdrawRequest() {
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();

  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getLedgerWithdrawRequestExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];
    const totalApprovalLevel = 1;
    const res = listMapping("name", rawRes, { t, te, omitKeys }).map(
      (item, rawResIndex) => ({
        ...item,
        ...exportApprovalDetailConverter(
          "withdraw",
          totalApprovalLevel,
          rawRes,
          rawResIndex,
          t,
          te
        ),
      })
    );
    const config = {};
    downloadFiles(`Withdraw Request`, res, config);
  };

  useEffect(() => {
    return () => {
      zusDialog.close();
      zusDialog.closeAll();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <Box sx={customSx.filterCmdBar}>
          {hasPermission(Pkey.CreateWithdrawRequest) && (
            <Button
              variant="outlined"
              onClick={() => zusDialog.open("create_request_dialog")}
            >
              {t("create_request")}
            </Button>
          )}
        </Box>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <WithdrawRequestList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedDate: useDatePicker(),
  };

  const getParams: () => ZusParamsFace = () => {
    const {
      chain_name,
      asset_names,
      sending_address: hot_wallet_address,
      request_statuses: statuses,
      wm_operation_statuses: wallet_manager_statuses,
      creator_type,
      receiving_address: to_address,
      ...rest
    } = fields;
    const transformFieldFromStrToArr = (fieldValue: string) => {
      const rawArr = fieldValue.split(",");

      const fieldValueArr = rawArr
        .filter((item) => item)
        .map((item) => item.trim());
      return fieldValueArr;
    };
    const {
      merchant_order_id: merchant_order_ids,
      refund_order_id: refund_order_ids,
      transaction_hash: tx_hashes,
    } = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        transformFieldFromStrToArr(value),
      ])
    );

    const { chain_id, chain_type } = selectChainByPieces({ chain_name });

    const { CreationTime, LastModifiedDate } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;
    const { start: date_last_modified_from, end: date_last_modified_to } =
      LastModifiedDate;

    return {
      ...initZusParams,
      chain_id,
      chain_type,
      asset_names,
      hot_wallet_address,
      to_address,
      statuses,
      wallet_manager_statuses,
      merchant_order_ids,
      refund_order_ids,
      tx_hashes,
      creator_type,
      date_created_from,
      date_created_to,
      date_last_modified_from,
      date_last_modified_to,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.LastModifiedDate.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "hot_wallet_address",
      <MpTextField
        value={fields.sending_address}
        onChange={onChange("sending_address")}
        label={t("hot_wallet_address")}
      />,
    ],
    [
      "receiving_address",
      <MpTextField
        value={fields.receiving_address}
        onChange={onChange("receiving_address")}
        label={t("ph_receiving_address")}
      />,
    ],
    [
      "request_status",
      <NodeTypesEnumMultiSelection
        label={t("ph_request_status")}
        onChange={(request_statuses) =>
          setFields((f) => ({ ...f, request_statuses }))
        }
        value={fields.request_statuses}
        enumData={EnumRequestStatus}
        isNoSorting
      />,
    ],
    [
      "wm_operation_status",
      <NodeTypesEnumMultiSelection
        label={t("ph_wm_operation_status")}
        onChange={(wm_operation_statuses) =>
          setFields((f) => ({ ...f, wm_operation_statuses }))
        }
        value={fields.wm_operation_statuses}
        enumData={EnumWMOperationStatus}
        isNoSorting
      />,
    ],
    [
      "merchant_order_id",
      <MpTextFieldMultiple
        value={fields.merchant_order_id}
        onChange={onChange("merchant_order_id")}
        label={t("ph_merchant_order_id")}
        count={apiParams.merchant_order_ids.length}
      />,
    ],
    [
      "refund_order_id",
      <MpTextFieldMultiple
        value={fields.refund_order_id}
        onChange={onChange("refund_order_id")}
        label={t("ph_refund_order_id")}
        count={apiParams.refund_order_ids.length}
      />,
    ],
    [
      "transaction_hash",
      <MpTextFieldMultiple
        value={fields.transaction_hash}
        onChange={onChange("transaction_hash")}
        label={t("ph_transaction_hash")}
        count={apiParams.tx_hashes.length}
      />,
    ],
    [
      "creator_type",

      <NodeTypesEnumSingleSelection
        value={fields.creator_type}
        label={t("ph_creator_type")}
        enumData={EnumCreatorTypeForDisplay}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, creator_type: "" }))}
        onChange={onChange("creator_type")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_time}
        {F.chain_name}
        {F.currency}
        {F.hot_wallet_address}
        {F.receiving_address}
        {F.request_status}
        {F.wm_operation_status}
        {F.merchant_order_id}
        {F.refund_order_id}
        {F.transaction_hash}
        {F.creator_type}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};

  const res = array.map((item: any) => {
    const { name: chain_name } =
      findChainInfo(item.chain_type, item.chain_id) || {};
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      [
        "approval_progress",
        t("approval_progress"),
        item.approvals_required === null
          ? null
          : !item.approvals_required
          ? t("auto_approve")
          : `${item.approvals}/${item.approvals_required}`,
      ],
      [
        "creator_type",
        t("creator_type"),
        te(EnumCreatorType[item.creator_type]),
      ],
      ["client_id", t("client_id"), item.client_id],
      ["chain_name", t("chain_name"), chain_name],
      ["currency", t("currency"), item.asset_name],
      [
        "transaction_amount",
        t("transaction_amount"),
        displayAmount(item.amount, decimals),
      ],
      ["transaction_hash", t("transaction_hash"), item.tx_hash],
      ["sending_address", t("hot_wallet_address"), item.hot_wallet_address],
      ["receiving_address", t("receiving_address"), item.to_address],
      [
        "wallet_tag_of_receiving_address",
        t("wallet_tag_of_receiving_address"),
        item.to_wallet_tag,
      ],
      [
        "request_status",
        t("request_status"),
        te(EnumRequestStatus[item.status]),
      ],
      [
        "wm_operation_status",
        t("wm_operation_status"),
        te(EnumWMOperationStatus[item.wallet_manager_status]),
      ],
      ["created_by", t("created_by"), item.created_by],
      ["refund_order_id", t("refund_order_id"), item.refund_order_id],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
      ["remarks", t("remarks"), item.remarks],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
