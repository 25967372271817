import { GridRenderCellParams } from "@mui/x-data-grid";
import CheckPointButton from "./CheckPointButton";

const CheckPointAction = (params: GridRenderCellParams) => {
  const checkPointId = params.value === "0" ? undefined : params.value;
  return (
    <>
      <div style={{ textAlign: "center", width: "65%", color: "#4a90f7" }}>
        {/* {params.value} */}
        {checkPointId}
      </div>
      <div
        style={{
          width: "25%",
          textAlign: "left",
        }}
      >
        {checkPointId && <CheckPointButton checkPointId={checkPointId} />}
      </div>
    </>
  );
};

export default CheckPointAction;
