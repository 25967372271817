import { ReactNode } from "react";
import { useTranslate } from "react-admin";
import { useTabs } from "../../../../hooks";

const ReSubmitsTab = ({ children }: { children: ReactNode }) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resubmit.${key}`);
  const tabsArr = [{ name: t("tableTab"), value: "" }];
  const { Tabs } = useTabs(tabsArr);

  return <Tabs>{children}</Tabs>;
};

export default ReSubmitsTab;
