import OpTextButton from "../../../components/OpTextButton";
import { useTranslate } from "react-admin";
import { useZusDialogStore } from "../../../zustand/store";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "../../../components/MuiGenerals";
import {
  approveOperations,
  rejectOperations,
  executeOperations,
} from "../../../api/signer";
import { useAlerting, usePermission, useYubiPrompt } from "../../../hooks";
import { customSx } from "../../../utils/styling";
import {
  enumMapping,
  findChainInfo,
  getDecimal,
  displayAmount,
} from "../../../utils/helper";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import {
  FeatureCodes,
  enumApprovalReason,
  enumNewApprovalStatus,
  enumOperationType,
} from "../../../utils/constant";
import ApprovalWindow from "./ApprovalWindow";
import { IoperationTab } from "./MerchantOperation";
import { AxiosResponse } from "axios";
import YubiPrompter from "../../../components/Prompter";

const Enum = {
  approvalStatus: enumMapping(enumNewApprovalStatus),
  approvalReason: enumMapping(enumApprovalReason),
};

const Pkey = FeatureCodes.assetManagement.MerchantOperation;
interface ItransactionApprovalDetails {
  seq: number;
  operated_by: string;
  approval_result: number;
  operated_date: string;
}
export default function ApprovalDialog(props: any) {
  const { setRefreshBatchTable } = props;
  const zusDialog = useZusDialogStore();
  const rowData = zusDialog.meta?.row || {};
  const tabName = (zusDialog.meta?.tabName || "all") as IoperationTab;

  const { batch_id = "-1" } = rowData;
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string, configAmt = {}) =>
    translate(`assetManagement.${key}`, configAmt);

  const { alerting } = useAlerting();
  const { email } = useAppSelector(selectProfile);
  const getRes = (
    apiFn: (apiParams: any) => Promise<AxiosResponse<any, any>>,
    apiParams: any
  ) => {
    const isWithdraw = //if the operationType is withdraw, then requrie "YubiKey"
      String(rowData.rawData.operation_type) === enumOperationType.Withdraw;
    if (!isWithdraw) {
      return apiFn(apiParams);
    }
    return getResAfterYubi(apiFn, apiParams);
  };

  const toExecute = async () => {
    const res = await getRes(executeOperations, { batch_id });

    if (!res) {
      return;
    }
    alerting("success", t("transactionExecuted"));
    setRefreshBatchTable(true);
    zusDialog.close();
  };
  const curProgress = rowData.rawData?.approval_completed; //check the progress

  const isFull =
    rowData.rawData?.approval_completed === rowData.rawData?.approval_required;
  const isOperatedBefore = rowData?.transaction_approval_details?.find(
    (item: any) => item.operated_by === email
  );
  const isCreator = rowData?.created_by === email;

  const toOperation = async (operation: "approve" | "reject") => {
    const isWithdraw =
      String(rowData.rawData.operation_type) === enumOperationType.Withdraw;

    const sweepApprovalChecking = !hasPermission(Pkey.Approve.prefix);

    const withdrawApprovalChecking = !hasPermission(
      Pkey.Approve[
        `WithdrawLevel${curProgress + 1}` as
          | "WithdrawLevel1"
          | "WithdrawLevel2"
          | "WithdrawLevel3"
          | "WithdrawLevel4"
      ]
    );

    const approvalChecking =
      operation === "approve" &&
      (isWithdraw ? withdrawApprovalChecking : sweepApprovalChecking);

    const noPermission =
      approvalChecking ||
      (operation === "reject" && !hasPermission(Pkey.Reject));

    if (noPermission) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    if (operation === "approve" && isOperatedBefore) {
      return alerting("error", t("alert_approvers_same"));
    }
    if (operation === "approve" && isCreator) {
      return alerting("error", t("alert_creator_approver_same"));
    }

    const params = { batch_id };
    if (operation === "approve") {
      const approveParams = {
        batch_id,
        ...(isWithdraw
          ? { approval_completed: curProgress }
          : { approval_completed: -1 }),
        operation_type: rowData.rawData?.operation_type,
      };
      const res = await getRes(approveOperations, approveParams);
      if (!res) return;
      alerting("success", t("transactionApproved"));
    } else if (operation === "reject") {
      const res = await getRes(rejectOperations, { batch_id });
      if (!res) return;
      alerting("success", t("transactionRejected"));
    }
    setRefreshBatchTable(true);
    zusDialog.close();
  };
  const historyRows: ItransactionApprovalDetails[] =
    rowData?.transaction_approval_details || [];
  const isOperation = zusDialog.meta?.from === "operation";

  if (zusDialog.main !== "approvalDialog") return null;
  const approvalReasons = rowData.approveReason;
  const showRejectButton =
    hasPermission(Pkey.Reject) &&
    (tabName === "all" ||
      tabName === "api_withdraw_tab" ||
      tabName === "portal_withdraw_tab" ||
      tabName === "sweep_tab");
  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.match("approvalDialog")}
        fullWidth={true}
        onClose={zusDialog.close}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "880px",
              minHeight: "300px",
            },
          },
        }}
      >
        <DialogTitle
          style={{
            padding: "3px 24px",
          }}
        >
          {t("approval")}{" "}
          <div
            style={{
              textDecoration: "none",
              textAlign: "right",
              transform: "translateY(-23px)",
            }}
          >
            <IconButton
              aria-label="close"
              onClick={zusDialog.close}
              sx={{
                position: "relative",
                right: 2,
                top: 0,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <section>
            <div
              style={{
                fontSize: "10px",
                marginLeft: "5px",
                minWidth: "150px",
                marginBottom: "20px",
              }}
            >
              {t("batch_id")}:
              <span style={{ color: "#4a90f7" }}> {rowData.batch_id}</span>
            </div>
            {approvalReasons &&
              rowData.rawData.operation_type == enumOperationType.Withdraw && (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: "10px",
                      paddingRight: "4px",
                      marginLeft: "5px",
                      marginBottom: "30px",
                      flexWrap: "nowrap",
                      minWidth: "130px",
                      transform: "translateY(4px)",
                    }}
                  >
                    {t("manual_approval")}:
                  </div>

                  <div>
                    {approvalReasons.length > 0 ? (
                      approvalReasons.map((item: any, index: number) => {
                        const decimal = getDecimal(item);
                        const subAddress =
                          item.address.substring(0, 5) +
                          "..." +
                          item.address.substring(item.address.length - 4);
                        const chkingValue =
                          item.reason_type == 5
                            ? item.checking_value
                            : displayAmount(item.checking_value, decimal);
                        const configAmount =
                          {
                            5: item.config_value,
                            7: item.config_value,
                            8: item.config_value,
                          }[item.reason_type as number] ||
                          displayAmount(item.config_value, decimal);
                        const tZparamss = {
                          time: item.checking_hour,
                          configAmt: configAmount,
                          checking_value: chkingValue,
                          asset_name: item.asset_name,
                          receivingAddress: subAddress,
                          chain_name: findChainInfo(
                            item.chain_type,
                            item.chain_id
                          )?.name,
                        };
                        return (
                          <div
                            key={index}
                            style={{
                              minWidth: "200px",
                              textAlign: "left",
                              fontSize: "10px",
                              color: "red",
                              lineHeight: "20px",
                            }}
                          >
                            {t(
                              Enum.approvalReason[item.reason_type],
                              tZparamss
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                </div>
              )}
          </section>

          <ApprovalWindow rows={historyRows} isOperation={isOperation} />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          {isOperation && (
            <>
              {isFull && (
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={toExecute}
                >
                  {t("execute")}
                </Button>
              )}
              {!isFull && (
                <OpTextButton
                  text={t("approve")}
                  // isHidden={!hasPermission(Pkey.Approve.prefix)}
                  sx={{ borderRadius: ".3rem" }}
                  onClick={() => toOperation("approve")}
                />
              )}
              <OpTextButton
                text={t("reject")}
                // isHidden={!showRejectButton}
                sx={{ borderRadius: ".3rem" }}
                onClick={() => toOperation("reject")}
              />
            </>
          )}
          <Button variant="contained" color="error" onClick={zusDialog.close}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
