import cond from 'lodash/cond';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import Tooltip from '@mui/material/Tooltip';
import { GridColDef } from '@mui/x-data-grid';

import CallbackSettingDialogs from '../components/CallBackSetting/Dialogs';
import { Typography } from '../components/MuiGenerals';
import { useAlerting } from '../hooks';
import { useAppDispatch } from '../reducer/hooks';
import { tableConfig } from '../utils/constant';
import { copyToClipboard } from './helper';

const sx = {
  cell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  addressCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#4A90F7",
    cursor: "pointer",
    textDecoration: "underline",
  },
  label: {
    padding: "25px 0px 0px 0px",
  },
};
const modelContainer = [
  { pageLocation: "messageManagement", JsxNode: <CallbackSettingDialogs /> },
  // {
  //   pageLocation: "merchantOperation",
  //   JsxNode: <MerchantOperationDialogModel />,
  // },
];
export function useGenGridCol(
  field: string,
  headerName: string,
  rest?: Partial<GridColDef>
): GridColDef {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const isDateField = (field: string) => tableConfig.dateFields.includes(field);
  const defaultCase = () => true;
  const isAddress = (field: string) =>
    tableConfig.addressFields.includes(field);
  const isFreeTextField = (field: string) =>
    tableConfig.freeTextFields.includes(field);
  const renderCell = (params: any) => {
    const { value = "", field = "" } = params;
    const isBoolean = (v: any) => typeof v === "boolean";
    const content = cond([
      [(v: any) => v === "0", () => "0"],
      [(v: any) => v === 0, () => 0],
      [(v: any) => !v, () => ""],
      [isBoolean, (v: any) => (v ? "yes" : "no")],
      // [(v: any) => isDateField(field), toDisplayTime], //stop converting timezone in here, cannot handle excel export if only convert time here
      [defaultCase, (v: any) => v],
    ])(value);
    return (
      <Tooltip title={content}>
        <span
          style={isAddress(field) ? sx.addressCell : sx.cell}
          className="csutable-cell-trucate"
          onClick={(e) => cellOnClick(e, field, value)}
        >
          {content}
        </span>
      </Tooltip>
    );
  };
  const minWidth = cond([
    [isAddress, () => tableConfig.addressWidth],
    [isDateField, () => tableConfig.dateWidth],
    [isFreeTextField, () => tableConfig.freeTextWidth],
    [defaultCase, () => tableConfig.defaultWidth],
  ])(field);

  const cellOnClick = (e: any, field: string, value: string) => {
    if (isAddress(field)) {
      copyToClipboard(value);
      alerting("success", translate(`report.copied`));
    }
  };
  return {
    field,
    headerName,
    flex: 1,
    minWidth,
    renderCell,
    headerAlign: "center",
    align: "center",
    sortable: false,
    ...rest,
  };
}

export function useRefresh(): [boolean, Function] {
  const [bool, setBool] = useState(true);
  useEffect(() => {
    if (!bool) setBool(true);
  }, [bool]);
  return [bool, () => setBool(false)];
}
export function DivideLine() {
  const lineStyle = {
    position: "relative" as const,
    width: "calc(100% + 4rem)",
    height: " 1rem",
    backgroundColor: "#fafafb",
    left: " -2rem",
  };
  return <div style={lineStyle} />;
}
// export function DivideLineForWithDrawApprovalSettingPage() {
//   const lineStyle = {
//     position: "relative" as const,
//     width: "calc(100% + 4rem)",
//     height: " 1rem",
//     backgroundColor: " rgb(241, 242, 244)",
//     left: "-2rem",
//   };
//   return <div style={lineStyle} />;
// }

export function genField(
  config: { t: (key: string) => string }, //translate
  array: [string, any][]
) {
  const { t } = config;
  const newArray = array.map(([label, content]: any) => [
    label,
    <>
      <Typography variant="subtitle2" sx={sx.label}>
        {t(label)}
      </Typography>
      <div className="filterFieldInput">{content}</div>
    </>,
  ]);
  return Object.fromEntries(newArray);
}
export const getModal = (whichPage: string) => {
  const model = modelContainer.filter(
    (item) => item.pageLocation === whichPage
  );
  return model ? model[0]?.JsxNode : <></>;
};

export const getFilteredColumns = (
  columns: GridColDef[],
  config: { fieldName: string; shouldFilter: boolean }[]
) => {
  const tranformedColumns = columns.filter(
    ({ field }) =>
      !config.find(
        ({ shouldFilter, fieldName }) => shouldFilter && field === fieldName
      )
  );

  return { filteredColumns: tranformedColumns };
};
