import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import React from "react";
import { useTranslate } from "react-admin";
import {
  approveAddressBookProposals,
  rejectAddressBookProposals,
} from "../../api/merchant";
import { OpApprove } from "../../assets/icons";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import {
  FeatureCodes,
  dataGridDefaults,
  enumChainIdToType,
  enumWalletStatus,
} from "../../utils/constant";
import {
  copyToClipboard,
  enumMapping,
  findAllETHChainInfoByChainTypeOnly,
  findChainInfo,
  findChainInfoByChainTypeOnly,
  jsonParse,
} from "../../utils/helper";
import { useGenGridCol } from "../../utils/HelperComp";

import { listFace } from "../../views/Tools/AuditLogTabView";
import { useZusDialogStore } from "../../zustand/store";
import { CustomPagination } from "../CustomPagination";
import DialogInOne from "../DialogInOne";
import { useChains } from "../FetchConfig";
import { Box, Button, DialogContent, Tooltip } from "../MuiGenerals";
import { NoRowsOverlay } from "../NoRowsOverlay";
import OpIconButton, { sxOpTextButton } from "../OpIconButton";
import YubiPrompter from "../Prompter";
import { WalletControlGroups } from "@wallet-manager/node-types/dist/src/access/const/AddressBookProposal";
import { WalletType } from "@wallet-manager/node-types/dist/src/postgres/const";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

const Enum = {
  controlGroups: enumMapping(WalletControlGroups),
};
const Pkey = FeatureCodes.walletManagement.WalletManagement.TabRecord;

interface propsFace {
  list: listFace;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  listMapping: any;
  refresh: Function;
}

const rawDataToChainDataForDisplay = (rawData: {
  new_val: string;
  chain_type: number;
  chain_id: number;
  wallet_type: number;
  id: number;
}) => {
  const { chainType: updated_chain_type, chainIds } = rawData.new_val
    ? JSON.parse(rawData.new_val)
    : { chainType: "", chainIds: "" };

  const old_chain_type = String(rawData.chain_type);
  const old_chain_id = rawData.chain_id ? String(rawData.chain_id) : "";

  const isWalletType0or4or5 =
    rawData.wallet_type === 0 ||
    rawData.wallet_type === 4 ||
    rawData.wallet_type === 5;

  const isWalletType0or5 =
    rawData.wallet_type === 0 || rawData.wallet_type === 5;

  const isWalletType1 = rawData.wallet_type === 1;

  const isHotWalletOrInvokerWalletType =
    rawData.wallet_type === 2 || rawData.wallet_type === 3;

  const displayChainType = updated_chain_type || old_chain_type;

  const isETHChainType = String(displayChainType) === enumChainIdToType.ETH;

  const displayETHSeriesDescriptionByWalletType =
    (isWalletType0or5 && "Supported ETH Series") ||
    (isWalletType1 && "All ETH Series");

  const getChainNameByChainTypeOnly =
    isETHChainType && isWalletType1
      ? findAllETHChainInfoByChainTypeOnly(displayChainType)
          ?.map((item) => item.name)
          .sort((a: string, b: string) => a.localeCompare(b))
          .join(", ")
      : findChainInfoByChainTypeOnly(displayChainType)?.name;

  const getChainNameByBothChainTypeAndId = findChainInfo(
    displayChainType,
    old_chain_id
  )?.name;

  const getNewValueChainTypeAndChainId = () => {
    const newValueRawString = rawData.new_val;

    if (!newValueRawString) return {};

    const newValueData = JSON.parse(newValueRawString);

    if (!newValueData) return {};

    const { chainType, chainIds } = newValueData;

    return { chainType, chainIds };
  };

  const getChainName = () => {
    // if hot wallet or invoker wallet
    // and chain id is changed
    // return chain name by both chain type and chain id

    if (isHotWalletOrInvokerWalletType) {
      const { chainType: newChainType, chainIds: newChainIds } =
        getNewValueChainTypeAndChainId();

      const oldChainId = String(rawData.chain_id);

      // only change chainId => newValue will also provide chainType
      const haveNewChainTypeAndChainIds = newChainType && newChainIds;

      if (haveNewChainTypeAndChainIds && !newChainIds.includes(oldChainId)) {
        const chainName = findChainInfo(newChainType, newChainIds[0])?.name;

        return chainName;
      }
    }

    if (old_chain_id) {
      return getChainNameByBothChainTypeAndId;
    }
    return getChainNameByChainTypeOnly;
  };

  // chain name
  const getChainNameByChainTypeOnlyOrBothChainIdAndChainType = getChainName();
  return {
    updated_chain_type,
    chainIds,
    old_chain_type,
    old_chain_id,
    isWalletType0or4or5,
    isWalletType0or5,
    isWalletType1,
    displayChainType,
    isETHChainType,
    displayETHSeriesDescriptionByWalletType,
    getChainNameByChainTypeOnlyOrBothChainIdAndChainType,
  };
};

const RequestRecordTabList = (props: propsFace) => {
  const translate = useTranslate();
  const zusDialog = useZusDialogStore();
  const { email } = useAppSelector(selectProfile);
  const { alerting } = useAlerting();

  const { list, setPage, listMapping, refresh } = props;
  const t = (key: string, option?: Object) =>
    translate(`walletManagement.${key}`, option);
  const totalRecords = list.count;
  const { hasPermission } = usePermission();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  const content: GridRowsProp = listMapping("key", list.rows, { translate });

  async function onApproveOrReject(isApprove: boolean) {
    const { rawData = {} } = zusDialog.meta;
    const isCreator = rawData.created_by === email;
    const noPermission =
      (isApprove && !hasPermission(Pkey.Approve)) ||
      (!isApprove && !hasPermission(Pkey.Reject));
    if (noPermission) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    if (isApprove && isCreator) {
      return alerting("error", t("alert_creator_approver_same"));
    }

    const apiFn = isApprove
      ? approveAddressBookProposals
      : rejectAddressBookProposals;
    const apiParams = { id: Number(rawData.id) };

    const apiRes = await getResAfterYubi(apiFn, apiParams);
    if (!apiRes) {
      return;
    }

    alerting("success", isApprove ? t(`approve_success`) : t(`reject_success`));
    zusDialog.closeAll();
    refresh();
  }

  const approvalButton = (params: GridRenderCellParams) => {
    const rawData: any = list.rows.find(
      (item: any) => item.id === String(params.row?.proposal_no)
    );
    if (!rawData) {
      console.error("id not found", params.row);
      return false;
    }
    const status = String(rawData.status);
    if (!rawData) return null;

    return (
      <Box sx={sxOpTextButton}>
        <OpIconButton
          title={t("approveAndRejectButton")}
          url={OpApprove}
          isDisabled={
            params.row.approval_status !==
            translate("enumConstants.PendingForApproval") /* || isDecided */
          }
          onClick={() =>
            zusDialog.open("approvalDialog", {
              rawData,
              ripeData: params.row,
            })
          }
        />
      </Box>
    );
  };

  const ChainNameColumn = (params: any) => {
    const { selectChainByPieces } = useChains();

    const id = params.row?.proposal_no;
    const item: any = list.rows.find((item: any) => item.id === id);

    const {
      isETHChainType,
      chainIds,
      isWalletType0or5,
      displayChainType,
      getChainNameByChainTypeOnlyOrBothChainIdAndChainType,
      displayETHSeriesDescriptionByWalletType,
      isWalletType1,
    } = rawDataToChainDataForDisplay(item);

    return (
      <Tooltip
        title={
          isETHChainType && chainIds && isWalletType0or5
            ? chainIds
                .map(
                  (chain_id: string) =>
                    findChainInfo(displayChainType, chain_id)?.name
                )
                .sort((a: string, b: string) => a.localeCompare(b))
                .join(", ")
            : getChainNameByChainTypeOnlyOrBothChainIdAndChainType
        }
      >
        <div>
          {isETHChainType && (isWalletType0or5 || isWalletType1)
            ? displayETHSeriesDescriptionByWalletType
            : getChainNameByChainTypeOnlyOrBothChainIdAndChainType}
        </div>
      </Tooltip>
    );
  };

  const isOperationColumnHidden = !(
    hasPermission(Pkey.Approve) || hasPermission(Pkey.Reject)
  );

  const columns: GridColDef[] = [
    useGenGridCol("1", t("operation"), {
      maxWidth: 80,
      renderCell: approvalButton,
    }),
    useGenGridCol("proposal_no", t("proposal_no"), { maxWidth: 80 }),
    useGenGridCol("proposal_type", t("proposal_type"), { maxWidth: 80 }),
    useGenGridCol("wallet_name", t("wallet_name")),
    useGenGridCol("wallet_type", t("wallet_type")),
    useGenGridCol("chain_name", t("chain_name"), {
      renderCell: ChainNameColumn,
      minWidth: 120,
    }),
    useGenGridCol("wallet_address", t("wallet_address")),
    useGenGridCol("approval_status", t("approval_status")),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("operated_by", t("operated_by"), {}),
    useGenGridCol("operation_time", t("operation_time")),
  ].filter((col) =>
    col.field === "1" && isOperationColumnHidden ? false : true
  );
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
      <YubiPrompter {...prompterConfig} />
      <LocalDialogs {...{ onApproveOrReject }} />
    </>
  );
};
const translatePrefix = "walletManagement";

function LocalDialogs(props: any) {
  const { onApproveOrReject } = props;
  const { t } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const translate = useTranslate();
  const TheButton = {
    Approve: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onApproveOrReject(true)}
      >
        {t("approve")}
      </Button>
    ),
    Reject: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onApproveOrReject(false)}
      >
        {t("reject")}
      </Button>
    ),
  };
  const { rawData = {}, ripeData = {} } = zusDialog.meta || {};

  const sx = {
    buttonGroup: { display: "flex", gap: "1rem", marginRight: "1rem" },
  };

  const updatedValues = rawData.new_val
    ? JSON.parse(rawData.new_val)
    : undefined;
  const isAddRecord = rawData.act === 10;
  const isDeleteRecord = rawData.act === 30;

  const {
    isETHChainType,
    chainIds,
    isWalletType0or5,
    displayChainType,
    getChainNameByChainTypeOnlyOrBothChainIdAndChainType,
    isWalletType0or4or5,
  } = rawDataToChainDataForDisplay(rawData);

  const chain_name =
    isETHChainType && chainIds && isWalletType0or5
      ? chainIds
          .map(
            (chain_id: string) =>
              findChainInfo(displayChainType, chain_id)?.name
          )
          .sort((a: string, b: string) => a.localeCompare(b))
          .join(", ")
      : getChainNameByChainTypeOnlyOrBothChainIdAndChainType;

  const data = [
    { title: t("proposal_no"), value: ripeData?.proposal_no },
    { title: t("proposal_type"), value: ripeData?.proposal_type },
    { title: t("chain_name"), value: chain_name },
    { title: t("wallet_name"), value: ripeData?.wallet_name },
    {
      title: t("wallet_address"),
      value: ripeData?.wallet_address,
    },
    { title: t("wallet_type"), value: ripeData?.wallet_type },
    {
      title: t("walletStatus"),
      value: isAddRecord
        ? translate(`enumConstants.${enumWalletStatus[1]}`)
        : isDeleteRecord
        ? translate(`enumConstants.${enumWalletStatus["-2"]}`)
        : translate(`enumConstants.${enumWalletStatus[updatedValues?.status]}`),
    },
  ];

  const dialogConfig = {
    title: t("approval"),
    self: {
      open: zusDialog.match("approvalDialog"),
      onClose: () => zusDialog.close(),
    },
    content: <DialogContentTable data={data} />,
    onCancel: zusDialog.close,
    isConfirmHidden: true,
    onConfirm: () => {},
    actionButtons: (
      <Box sx={sx.buttonGroup}>
        <TheButton.Approve />
        <TheButton.Reject />
      </Box>
    ),
  } as const;

  return (
    <>
      <DialogInOne {...dialogConfig} rest={{ maxWidth: "md" }} />
    </>
  );
}
const sx = {
  DialogContent: {
    padding: "2rem",
    paddingLeft: 0,
    "& .MuiBox-root": { display: "flex", marginBottom: "0.2rem" },
    "& .MuiBox-root span": { flex: 1 },
  },
};
function DialogContentTable(p: { data: { title: string; value: string }[] }) {
  const { data = [] } = p;
  const zusDialog = useZusDialogStore();
  const translate = useTranslate();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const controlGroupsTranslationMap: { [key: string]: string } = {
    "0": t("control_groups_type_unassigned"),
    "1": t("control_groups_type_client"),
    "2": t("control_groups_type_settlement"),
  };

  const addressOnClick = (wallet_address: string) => {
    copyToClipboard(wallet_address);
    alerting("success", translate("report.copied"));
  };
  const { ripeData = {} } = zusDialog.meta || {};

  const controlGroups = (function () {
    const [notJSON, json] = jsonParse(zusDialog.meta?.rawData?.new_val || "{}");
    if (notJSON) return [];
    return json?.controlGroups || [];
  })();

  // The Adjust Control Groups is only for Hot Wallet
  const newData =
    zusDialog.meta?.rawData?.wallet_type === WalletType.HotWallet
      ? data.concat({
          title: t("ph_control_groups"),
          value:
            controlGroups && controlGroups.length > 0
              ? controlGroupsTranslationMap[controlGroups[0]]
              : controlGroupsTranslationMap[0],
        })
      : data;

  return (
    <DialogContent sx={sx.DialogContent}>
      {newData.map(({ title, value }) => (
        <Box key={title}>
          <span>{title}</span>
          <span
            style={{
              width: "400px",
              wordWrap: "break-word",
              color: title === t("wallet_address") ? "#4A90F7" : undefined,
              cursor: title === t("wallet_address") ? "pointer" : undefined,
              textDecoration:
                title === t("wallet_address") ? "underline" : undefined,
            }}
            onClick={
              title === t("wallet_address")
                ? () => addressOnClick(ripeData?.wallet_address)
                : undefined
            }
          >
            {value}
          </span>
        </Box>
      ))}
    </DialogContent>
  );
}

export default React.memo(RequestRecordTabList);
