import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import React from "react";
import { listFace } from "../../api/merchant";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { CustomPagination } from "../CustomPagination";
import { NoRowsOverlay } from "../NoRowsOverlay";
import MoreOperations from "./MoreOperations";
import { useWithDrawApprovalSettingStore } from "../../zustand/store";
import { useTranslation } from "../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
// import MoreOperations from "./MoreOperations";
const translatePrefix = "withdrawApprovalSetting";
interface propsFace {
  list: listFace;
  listMapping: any;
  page: number;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
}
const RequestRecordList = (props: propsFace) => {
  const configTime = useWithDrawApprovalSettingStore(
    (state) => state.configTime
  );
  const { t, translate } = useTranslation(translatePrefix);
  const { list, listMapping, setPage, page } = props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, { t, translate });

  const cellButton = (params: GridRenderCellParams) => {
    const props = {
      params: params,
    };

    return <MoreOperations {...props} />;
  };
  const columns: GridColDef[] = [
    useGenGridCol("proposal_no", t("proposal_no")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    // useGenGridCol("edited_amount", t("edited_amount"), { minWidth: 130 }),

    useGenGridCol(
      "hourly_auto_approval_limit",
      t("request_hourly_limit", configTime),
      {
        minWidth: 180,
      }
    ),
    //New add Fields
    useGenGridCol(
      "reqEditMaxLimitWithin1Hr",
      t("reqEditMaxLimitWithin1Hr", configTime),
      {
        minWidth: 180,
      }
    ),
    useGenGridCol("reqEditMaxNoTrxAmt", t("reqEditMaxNoTrxAmt", configTime), {
      minWidth: 180,
    }),
    useGenGridCol("reqEditMaxNoTrxs", t("reqEditMaxNoTrxs", configTime), {
      minWidth: 180,
    }),
    //
    useGenGridCol("status", t("status")),
    useGenGridCol("created_by", t("created_by")),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("operated_by", t("operated_by")),
    useGenGridCol("operation_time", t("operation_time")),
    useGenGridCol("record_operation", t("operation"), {
      minWidth: 100,
      renderCell: cellButton,
    }),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
};

export default React.memo(RequestRecordList);
