import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/node-types";
import EnumRequestStatus from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerRequestStatus";

import { getLedgerTransferRequest } from "../../../api/merchant";
import { OpApprove, OpRedirect } from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton from "../../../components/OpIconButton";
import { usePermission, useTranslation } from "../../../hooks";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import ApprovalDialog from "../ApprovalDialog";
import ApprovalProgressDialog from "../ApprovalProgressDialog";
import CreateRequestDialog from "./CreateRequestDialog";
import { useZusParams } from "./TransferRequest";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "ledgerTransferRequest";
const Pkey = FeatureCode.FeaturesLedger.TransferRequest;
const PAGE = "TransferRequest";
const api = {
  getLedgerTransferRequest,
};
export default function TransferRequestList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: [
      "ledgerTransferRequest",
      zusParams.body,
      zusParams.refetchCounter,
    ],
    queryFn: () => api.getLedgerTransferRequest(zusParams.body),
    // cacheTime: 0,
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  useEffect(() => setCount(count), [listRes]);

  const ApprovalProgress = (params: GridCellParams) => {
    const { id: rowId, row } = params;
    const rawRow = rows[Number(rowId)];
    const { id, status } = rawRow || {};
    const { approval_progress, last_modified_time } = row;
    const onClick = () => {
      zusDialog.open("approvalDialog", {
        approval_progress,
        id,
        status,
        last_modified_time,
        page: PAGE,
      });
    };
    if (!approval_progress) return <></>;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "40%",
        }}
      >
        <span>{approval_progress}</span>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={onClick}
          size="1rem"
        />
      </Box>
    );
  };
  const ApproveBtn = (params: GridCellParams) => {
    const { id, row } = params;
    const {
      sending_client_id,
      receiving_client_id,
      chain_name,
      currency,
      transaction_amount,
    } = row;
    const rawData = rows[Number(id)];
    const {
      id: request_id,
      approvals: approval_completed,
      created_by,
    } = rawData || {};
    const apiParams = { request_id, approval_completed, created_by };
    const onClick = () => {
      zusDialog.open("operationDialog", {
        sending_client_id,
        receiving_client_id,
        chain_name,
        currency,
        transaction_amount,
        apiParams,
        page: PAGE,
      });
    };
    return (
      <OpIconButton
        title={t("approveBtn")}
        url={OpApprove}
        isDisabled={rawData.status !== EnumRequestStatus.Pending}
        size="1.9rem"
        onClick={onClick}
      />
    );
  };

  const hasApprovePermission = hasPermission(Pkey.Approve);
  const hasRejectPermission = hasPermission(Pkey.Reject);

  const shouldHideOperationColumn =
    !hasApprovePermission && !hasRejectPermission;

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 70,
      renderCell: ApproveBtn,
    }),
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 150,
    }),
    useGenGridCol("approval_progress", t("approval_progress"), {
      minWidth: 130,
      renderCell: ApprovalProgress,
    }),
    useGenGridCol("creator_type", t("creator_type"), { minWidth: 100 }),
    useGenGridCol("sending_client_id", t("sending_client_id"), {
      minWidth: 100,
    }),
    useGenGridCol("receiving_client_id", t("receiving_client_id"), {
      minWidth: 100,
    }),
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 100 }),
    useGenGridCol("currency", t("currency"), {
      minWidth: 100,
    }),
    useGenGridCol("transaction_amount", t("transaction_amount"), {
      minWidth: 100,
    }),
    useGenGridCol("currency_of_fee", t("currency_of_fee"), { minWidth: 100 }),
    useGenGridCol("amount_of_fee", t("amount_of_fee"), { minWidth: 100 }),
    useGenGridCol("request_status", t("request_status"), {
      minWidth: 100,
    }),
    useGenGridCol("created_by", t("created_by"), { minWidth: 100 }),
    useGenGridCol("creation_time", t("creation_time"), { minWidth: 150 }),
    useGenGridCol("last_modified_time", t("last_modified_time"), {
      minWidth: 150,
    }),
    useGenGridCol("remarks", t("remarks"), { minWidth: 100 }),
  ].filter((col) => {
    if (col.field === "operation") {
      return !shouldHideOperationColumn;
    }

    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <CreateRequestDialog />
      <ApprovalDialog />
      <ApprovalProgressDialog />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
