import EnumWalletType from "@wallet-manager/node-types/dist/types/postgres/const/WalletType";
import BigNumber from "bignumber.js";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslate } from "react-admin";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EnumRiskLevel from "@wallet-manager/node-types/dist/types/postgres/const/RiskLevel";

import { Box, Tooltip } from "../../components/MuiGenerals";
import { useAlerting } from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import {
  bigNumStrMulitpleDecimals,
  containsOnlyNumbers,
  copyToClipboard,
  removeCommasFromNumStr,
} from "../../utils/helper";
import styles from "./batchSweep.module.scss";
import AddressSelection from "../../components/AddressSelection";

// import { debounce } from "lodash";

export default function BatchSweepList(props: {
  chain_name: string;
  invoker_address: string;
  setInvokerAddress: (gather_address: string) => void;
  list: any[];
  addressArr: {
    fromAddress: string;
    amountToSweep: string;
    isChecked: boolean;
  }[];
  setAddressArr: Dispatch<
    SetStateAction<
      {
        fromAddress: string;
        amountToSweep: string;
        isChecked: boolean;
      }[]
    >
  >;
  sumString: string;
  isUTXOType: boolean;
  tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  totalAmountToSweep: string;
  isSecondLayerToken: boolean;
}) {
  const {
    list,
    addressArr,
    setAddressArr,
    sumString,
    isUTXOType,
    tableBodyRef,
    totalAmountToSweep,
    chain_name,
    invoker_address,
    setInvokerAddress,
    isSecondLayerToken,
  } = props;
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const { lang } = useAppSelector(selectProfile);
  const t = (key: string) => translate(`assetManagement.${key}`);
  const totalAmountToSweepDivRef = useRef<HTMLDivElement>(null);
  const [isAlertNeeded, setIsAlertNeeded] = useState(false);

  useEffect(() => {
    const checkboxElementArr = tableBodyRef.current
      ? (tableBodyRef.current.querySelectorAll(
          "input[type='checkbox']"
        ) as NodeListOf<HTMLInputElement>)
      : [];
    const inputFieldElementArr = tableBodyRef.current
      ? (tableBodyRef.current.querySelectorAll(
          "input[name='amountToSweep']"
        ) as NodeListOf<HTMLInputElement>)
      : [];
    checkboxElementArr.forEach(
      (ele, i) => (ele.checked = addressArr[i].isChecked)
    );
    inputFieldElementArr.forEach((ele, i) => {
      ele.value = addressArr[i].amountToSweep;
      if (!Number(addressArr[i].amountToSweep)) {
        ele.setAttribute("disabled", "true");
      } else {
        if (!isUTXOType) {
          ele.removeAttribute("disabled");
        }
      }
    });
  }, [list]);

  useEffect(() => {
    if (!totalAmountToSweepDivRef.current) {
      return;
    }

    totalAmountToSweepDivRef.current.innerText = totalAmountToSweep;
    const tooltipElement = document.querySelector(
      "#totalAmountTooltip"
    ) as HTMLDivElement;
    const tooltipInnerDivElement = tooltipElement?.querySelector(
      ".MuiTooltip-tooltip"
    ) as HTMLDivElement;

    if (!tooltipInnerDivElement) {
      return;
    }
    tooltipInnerDivElement.innerText = totalAmountToSweep;
    const tooltipElementStyle = tooltipElement.getAttribute("style");
    const removedPreviousStyleArr = tooltipElementStyle
      ?.split(";")
      .filter((item) => !item.includes("opacity") && item);
    const displayStyleStr =
      removedPreviousStyleArr?.join(";") + "; opacity: 0;";
    tooltipElement.setAttribute("style", displayStyleStr);
  }, [totalAmountToSweep]);

  useEffect(() => {
    if (isAlertNeeded) {
      alerting("error", t("bigger_than_0_warning"));
      setIsAlertNeeded(false);
    }
    // if (isAlertNeeded) {
    //   alerting("error", t("bigger_than_0_warning"));
    //   const debonucedDelay = debounce(() => setIsAlertNeeded(false), 5_000);
    //   debonucedDelay();
    // }
  }, [isAlertNeeded]);

  const handleCheckForTable = useCallback(
    (row: any, inputField: HTMLInputElement) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.checked) {
          inputField.value = "0";
          inputField.setAttribute("disabled", "true");
          setAddressArr((prev) =>
            prev.map((item, index) => {
              if (index === row.id - 1) {
                return {
                  fromAddress: row.from_address,
                  amountToSweep: "0",
                  isChecked: false,
                };
              }
              return item;
            })
          );
        } else {
          const amountWithoutCommas = removeCommasFromNumStr(row.amount);
          inputField.value = amountWithoutCommas;
          if (!isUTXOType) {
            inputField.removeAttribute("disabled");
          }
          setAddressArr((prev) =>
            prev.map((item, index) => {
              if (index === row.id - 1) {
                return {
                  fromAddress: row.from_address,
                  amountToSweep: amountWithoutCommas,
                  isChecked: true,
                };
              }
              return item;
            })
          );
        }
      },
    [setAddressArr, isUTXOType]
  );

  const handleCheckSelectAll =
    (isAllChecked: boolean) => (e: ChangeEvent<HTMLInputElement>) => {
      const checkboxElementArr = tableBodyRef.current
        ? (tableBodyRef.current.querySelectorAll(
            "input[type='checkbox']"
          ) as NodeListOf<HTMLInputElement>)
        : [];
      const inputFieldElementArr = tableBodyRef.current
        ? (tableBodyRef.current.querySelectorAll(
            "input[name='amountToSweep']"
          ) as NodeListOf<HTMLInputElement>)
        : [];

      if (isAllChecked) {
        checkboxElementArr.forEach((ele) => (ele.checked = false));
        inputFieldElementArr.forEach((ele) => {
          ele.value = "0";
          ele.setAttribute("disabled", "true");
        });
        setAddressArr(
          list.map(({ from_address: fromAddress }) => ({
            fromAddress,
            amountToSweep: "0",
            isChecked: false,
          }))
        );
      } else {
        checkboxElementArr.forEach((ele) => (ele.checked = true));
        inputFieldElementArr.forEach((ele, i) => {
          ele.value = removeCommasFromNumStr(list[i].amount);
          if (!isUTXOType) {
            ele.removeAttribute("disabled");
          }
        });
        setAddressArr(
          list.map(({ from_address: fromAddress, amount }) => ({
            fromAddress,
            amountToSweep: removeCommasFromNumStr(amount),
            isChecked: true,
          }))
        );
      }
    };

  const handleInput = useCallback(
    (
        inputFieldRef: React.RefObject<HTMLInputElement>,
        previousValueRef: React.MutableRefObject<string>,
        row: any
      ) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const valueWithoutCommas = removeCommasFromNumStr(e.target.value);

        if (!containsOnlyNumbers(valueWithoutCommas)) {
          inputFieldRef.current!.value = previousValueRef.current;
          return;
        }
        const inputDecimals = valueWithoutCommas.split(".")[1]
          ? valueWithoutCommas.split(".")[1].length
          : 0;
        if (inputDecimals > row.decimals) {
          inputFieldRef.current!.value = previousValueRef.current;
          return;
        }

        if (
          BigNumber(
            bigNumStrMulitpleDecimals(valueWithoutCommas, row.decimals)
          ).isGreaterThan(BigNumber(row.originalAmount))
        ) {
          inputFieldRef.current!.value = removeCommasFromNumStr(row.amount);
          previousValueRef.current = removeCommasFromNumStr(row.amount);
          setAddressArr((prev) =>
            prev.map((item, index) => {
              if (index === row.id - 1) {
                return {
                  fromAddress: row.from_address,
                  amountToSweep: removeCommasFromNumStr(row.amount),
                  isChecked: true,
                };
              }

              return item;
            })
          );
          return;
        }
        inputFieldRef.current!.value = valueWithoutCommas;
        previousValueRef.current = valueWithoutCommas;
        setAddressArr((prev) =>
          prev.map((item, index) => {
            if (index === row.id - 1) {
              return {
                fromAddress: row.from_address,
                amountToSweep: valueWithoutCommas,
                isChecked: true,
              };
            }
            return item;
          })
        );
      },
    [setAddressArr]
  );

  const handleInputBlur = useCallback(
    (inputFieldRef: React.RefObject<HTMLInputElement>) =>
      (e: React.FocusEvent<HTMLInputElement>) => {
        const checkBox =
          inputFieldRef.current?.parentElement?.parentElement?.querySelector(
            "input[type='checkbox']"
          ) as HTMLInputElement;
        if (!Number(inputFieldRef.current!.value)) {
          if (isAlertNeeded) {
            return;
          }

          if (!checkBox!.checked) {
            return;
          }
          setIsAlertNeeded(true);
        }

        // if (!Number(inputFieldRef.current!.value)) {
        //   e.target.focus();
        //   if (isAlertNeeded) {
        //     return;
        //   }
        //   const debouncedShowAlert = debounce(
        //     () => {
        //       if (!checkBox!.checked) {
        //         return;
        //       }
        //       setIsAlertNeeded(true);
        //     },
        //     200,
        //     {
        //       leading: false,
        //       trailing: true,
        //     }
        //   );
        //   debouncedShowAlert();
        // }
      },
    [setIsAlertNeeded]
  );

  return (
    <Box>
      <TableContainer
        sx={{ width: "100%", marginTop: "12px", marginBottom: "48px" }}
      >
        <Table aria-label="simple table" className={styles.batchSweepTable}>
          <TableHead>
            <TableRow
              sx={{
                ">th": {
                  color: "#a2a2a2",
                  background: "#fff !important",
                  height: 45,
                  padding: "0px 12px !important",
                },
              }}
            >
              <TableCell align="center" className={styles.idCol}>
                {t("sequence")}
              </TableCell>
              <TableCell align="center" className={styles.walletVersionCol}>
                {t("wallet_version")}
              </TableCell>
              <TableCell align="center" className={styles.sendingAddressCol}>
                {t("from_address")}
              </TableCell>
              <TableCell align="center" className={styles.receivingAddressCol}>
                {t("to_address")}
              </TableCell>
              <TableCell align="center" className={styles.riskLevelCol}>
                {t("risk_level")}
              </TableCell>
              <TableCell align="center" className={styles.riskLevelCol}>
                {t("risk_level_count")}
              </TableCell>
              <TableCell
                align="center"
                className={styles.selectAllCol}
                sx={{ maxWidth: lang === "en" ? 60 : 50 }}
              >
                <CheckboxHeader
                  isAllChecked={addressArr.every(({ isChecked }) => isChecked)}
                  handleCheckSelectAll={handleCheckSelectAll}
                />
              </TableCell>
              <TableCell align="center" className={styles.sweepableAmountCol}>
                {t("sweepableAmount")}
              </TableCell>
              <TableCell align="center" className={styles.sweepAmountCol}>
                {t("amount_to_sweep")}
              </TableCell>
            </TableRow>
          </TableHead>

          <CustomTableBody
            chain_name={chain_name}
            invoker_address={invoker_address}
            setInvokerAddress={setInvokerAddress}
            list={list}
            isUTXOType={isUTXOType}
            sumString={sumString}
            handleCheck={handleCheckForTable}
            handleInput={handleInput}
            handleInputBlur={handleInputBlur}
            totalAmountToSweepDivRef={totalAmountToSweepDivRef}
            tableBodyRef={tableBodyRef}
            isSecondLayerToken={isSecondLayerToken}
          />
        </Table>
      </TableContainer>
    </Box>
  );
}

function CopyableAddress(props: { address: string }) {
  const { address } = props;
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const onClickHandler = () => {
    copyToClipboard(address);
    alerting("success", translate("report.copied"));
  };
  return (
    <div
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "#4A90F7",
        cursor: "pointer",
        textDecoration: "underline",
      }}
      onClick={onClickHandler}
      {...props}
    >
      {address}
    </div>
  );
}

const CustomInputForTable = React.memo(function CustomInputForTable(props: {
  row: any;
  isUTXOType: boolean;
  handleInput: (
    inputFieldRef: React.RefObject<HTMLInputElement>,
    previousValueRef: React.MutableRefObject<string>,
    row: any
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleInputBlur: (
    inputFieldRef: React.RefObject<HTMLInputElement>
  ) => (e: React.FocusEvent<HTMLInputElement>) => void;
}) {
  const { row, isUTXOType, handleInput, handleInputBlur } = props;

  const inputFieldRef = useRef<HTMLInputElement>(null);
  const previousValueRef = useRef<string>(row.amount);

  return (
    <input
      ref={inputFieldRef}
      defaultValue={row.amount}
      className={styles.customInputField}
      disabled={isUTXOType}
      onChange={handleInput(inputFieldRef, previousValueRef, row)}
      onBlur={handleInputBlur(inputFieldRef)}
      name="amountToSweep"
    />
  );
});

const CheckboxCellForTable = React.memo(function CheckboxCell(props: {
  row: any;
  handleCheck: (
    row: any,
    inputField: HTMLInputElement
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { row, handleCheck } = props;
  const { id } = row;
  const checkboxRef = useRef<HTMLInputElement>(null);
  const [inputField, setInputField] = useState<HTMLInputElement | null>(null);
  useEffect(() => {
    const inputFieldElement =
      checkboxRef.current?.parentNode?.parentNode?.querySelector(
        "input[name='amountToSweep']"
      ) as HTMLInputElement;
    setInputField(inputFieldElement);
  }, []);

  return (
    <input
      ref={checkboxRef}
      style={{ accentColor: "#4a90f7" }}
      type={"checkbox"}
      defaultChecked={true}
      onChange={handleCheck(row, inputField!)}
    />
  );
});

function CheckboxHeader(props: {
  isAllChecked: boolean;
  handleCheckSelectAll: (
    isAllChecked: boolean
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const translate = useTranslate();
  const { isAllChecked, handleCheckSelectAll } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span>{translate("assetManagement.select_all")}</span>
      <input
        type={"checkbox"}
        style={{ margin: "0px 0px 1px 4px", accentColor: "#4a90f7" }}
        checked={isAllChecked}
        onChange={handleCheckSelectAll(isAllChecked)}
      />
    </div>
  );
}

const CustomTableBody = React.memo(function CustomTableBody(props: {
  chain_name: string;
  invoker_address: string;
  setInvokerAddress: (gather_address: string) => void;
  list: any[];
  isUTXOType: boolean;
  sumString: string;
  handleCheck: (
    row: any,
    inputField: HTMLInputElement
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleInput: (
    inputFieldRef: React.RefObject<HTMLInputElement>,
    previousValueRef: React.MutableRefObject<string>,
    row: any
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
  handleInputBlur: (
    inputFieldRef: React.RefObject<HTMLInputElement>
  ) => (e: React.FocusEvent<HTMLInputElement>) => void;
  totalAmountToSweepDivRef: React.RefObject<HTMLDivElement>;
  tableBodyRef: React.RefObject<HTMLTableSectionElement>;
  isSecondLayerToken: boolean;
}) {
  const {
    chain_name,
    invoker_address,
    setInvokerAddress,
    list,
    handleCheck,
    isUTXOType,
    sumString,
    handleInput,
    handleInputBlur,
    totalAmountToSweepDivRef,
    tableBodyRef,
    isSecondLayerToken,
  } = props;
  const { lang } = useAppSelector(selectProfile);
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  return (
    <TableBody ref={tableBodyRef}>
      {list.map((row) => {
        const riskLevelDisplayText = translate(
          `enumConstants.${EnumRiskLevel[row.risk_level]}`
        );

        return (
          <TableRow
            key={row.id}
            sx={{
              ">td": {
                borderBottom: "1px #fff solid",
                height: "40px !important",
                padding: "0px 12px",
              },
              ":hover": {
                ">td": {
                  background: "#e1e1e1 !important",
                  input: {
                    background: "#e1e1e1 !important",
                  },
                },
              },
              "&:last-child td": { border: "0px" },
            }}
          >
            <TableCell align="center" className={styles.idCol}>
              <Tooltip title={row.id}>
                <div>{row.id}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.walletVersionCol}>
              <Tooltip title={row.wallet_version}>
                <div>{row.wallet_version}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.sendingAddressCol}>
              <Tooltip title={row.from_address}>
                <span>
                  <CopyableAddress address={row.from_address} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.receivingAddressCol}>
              <Tooltip title={row.to_address}>
                <span>
                  <CopyableAddress address={row.to_address} />
                </span>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.riskLevelCol}>
              <Tooltip title={riskLevelDisplayText}>
                <div>{riskLevelDisplayText}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.riskLevelCol}>
              <Tooltip title={row.no_risk_level_count}>
                <div>{row.no_risk_level_count || 0}</div>
              </Tooltip>
            </TableCell>
            <TableCell
              align="center"
              className={styles.selectAllCol}
              sx={{ maxWidth: lang === "en" ? 60 : 50 }}
            >
              <CheckboxCellForTable row={row} handleCheck={handleCheck} />
            </TableCell>
            <TableCell align="center" className={styles.sweepableAmountCol}>
              <Tooltip title={row.amount}>
                <div>{row.amount}</div>
              </Tooltip>
            </TableCell>
            <TableCell align="center" className={styles.sweepAmountCol}>
              <CustomInputForTable
                row={row}
                isUTXOType={isUTXOType}
                handleInput={handleInput}
                handleInputBlur={handleInputBlur}
              />
            </TableCell>
          </TableRow>
        );
      })}
      <TableRow
        sx={{
          ">td": {
            color: "#4a90f7",
            background: "#fff !important",
            borderBottom: "0px",
            height: 40,
            padding: "0px 12px",
          },
        }}
      >
        {isSecondLayerToken && (
          <TableCell align="center" className={styles.idCol}>
            <Box sx={{ minWidth: "15rem", display: "flex", alignItems: "end" }}>
              <Box sx={{ color: "black", minWidth: "fit-content" }}>
                {t("invoker_wallet_address")}
              </Box>
              <AddressSelection
                chain_name={chain_name}
                setGatherAddress={setInvokerAddress}
                gather_address={invoker_address}
                wallet_types={[EnumWalletType.InvokerWallet]}
                tryDefaultWallet={true}
              />
            </Box>
          </TableCell>
        )}
        <TableCell
          align="center"
          className={styles.walletVersionCol}
        ></TableCell>
        <TableCell
          align="center"
          className={styles.sendingAddressCol}
        ></TableCell>
        <TableCell
          align="center"
          className={styles.receivingAddressCol}
        ></TableCell>
        <TableCell align="center" className={styles.riskLevelCol}></TableCell>
        <TableCell align="center" className={styles.riskLevelCol}></TableCell>
        <TableCell
          align="center"
          className={styles.selectAllCol}
          sx={{
            color: "rgba(0, 0, 0, 0.87) !important",
            maxWidth: lang === "en" ? 60 : 50,
          }}
        >
          {translate("assetManagement.total_sweep_amount")}
        </TableCell>
        <TableCell align="center" className={styles.sweepableAmountCol}>
          <Tooltip title={sumString}>
            <div>{sumString}</div>
          </Tooltip>
        </TableCell>
        <TableCell align="center" className={styles.sweepAmountCol}>
          <TotalAmountToSweep
            totalAmountToSweepDivRef={totalAmountToSweepDivRef}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
});

function TotalAmountToSweep(props: {
  totalAmountToSweepDivRef: React.RefObject<HTMLDivElement>;
}) {
  const { totalAmountToSweepDivRef } = props;

  return (
    <Tooltip
      open={true}
      id={"totalAmountTooltip"}
      className="totalAmountTooltip"
      title={" "}
    >
      <div
        ref={totalAmountToSweepDivRef}
        onMouseEnter={() => {
          const tooltipElement = document.querySelector(
            "#totalAmountTooltip"
          ) as HTMLDivElement;

          const tooltipElementStyle = tooltipElement.getAttribute("style");

          const removedPreviousStyleArr = tooltipElementStyle
            ?.split(";")
            .filter((item) => !item.includes("opacity") && item);

          const displayStyleStr =
            removedPreviousStyleArr?.join(";") + "; opacity: 1;";

          tooltipElement.setAttribute("style", displayStyleStr);
        }}
        onMouseOut={() => {
          const tooltipElement = document.querySelector(
            "#totalAmountTooltip"
          ) as HTMLDivElement;

          const tooltipElementStyle = tooltipElement.getAttribute("style");

          const removedPreviousStyleArr = tooltipElementStyle
            ?.split(";")
            .filter((item) => !item.includes("opacity") && item);

          const displayStyleStr =
            removedPreviousStyleArr?.join(";") + "; opacity: 0;";

          tooltipElement.setAttribute("style", displayStyleStr);
        }}
      ></div>
    </Tooltip>
  );
}
