import { useEffect, useState } from "react";

import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";

import { jsonParse } from "../utils/helper";
import useTranslation from "./useTranslation";

type Iconfig = { email: string; _path: string; _name: string };
type IhiderState = GridColumnVisibilityModel & Iconfig;
function isMatch(a: Iconfig, b: Iconfig) {
  return a._path === b._path && a._name === b._name;
}
function getStateByconfig(
  state: Record<string, IhiderState[]>,
  config: Iconfig
) {
  const emailState = state[config.email] || [];
  return emailState.find((ele: IhiderState) => isMatch(ele, config)) || {};
}
const KeyPrefix = "columnsHider";
function setStateByconfig(
  rawNewState: GridColumnVisibilityModel,
  config: Iconfig
) {
  const newState = {
    _name: config._name,
    _path: config._path,
    ...rawNewState,
  } as IhiderState;
  const allEmailState = getColumnsHiderStorage();
  const emailState = allEmailState[config.email] || [];
  const oldStateIndex = emailState.findIndex((s) => isMatch(s, newState));
  let newEmailState = [];
  if (oldStateIndex === -1) {
    newEmailState = emailState.concat(newState);
  } else {
    newEmailState = emailState.slice();
    newEmailState[oldStateIndex] = newState;
  }
  const newAllEmailState = JSON.stringify({
    ...allEmailState,
    [config.email]: newEmailState,
  });
  localStorage.setItem(KeyPrefix, newAllEmailState);
}
function getColumnsHiderStorage() {
  const [jsonErr, json] = jsonParse(localStorage.getItem(KeyPrefix) || "{}");
  if (jsonErr) {
    const message = "error when parsing columnsHider from localStorage ";
    console.error(message, jsonErr);
    return {};
  }
  return (json || {}) as Record<string, IhiderState[]>;
}
export function getColumnHideConfig(tableName = "main") {
  const localState = localStorage.getItem("persist:root") || "{}";
  const profile = JSON.parse(localState)?.profile || "{}";
  const email = JSON.parse(profile)?.email || "";
  const _path = window.location.pathname;
  return { email, _path, _name: tableName };
}
export default function useColumnHide(
  dataCols: GridColDef<any, any, any>[],
  config: Iconfig
) {
  const allEmailState = getColumnsHiderStorage();
  const initState = getStateByconfig(allEmailState, config);
  const [colsShown, setRawColsShown] =
    useState<GridColumnVisibilityModel>(initState);
  const setColsShown = (s: GridColumnVisibilityModel) => {
    setStateByconfig(s, config);
    setRawColsShown(s);
  };
  useEffect(() => {
    const hiddens = Object.values(colsShown).filter((bool) => !bool).length;
    const isAllHidden = hiddens >= dataCols.length;
    //show all if all columns are hidden
    if (isAllHidden) {
      setColsShown({});
    }
  }, []);

  const hasColHidden = Object.entries(colsShown).some(
    ([key, val]) => !val && dataCols.find(({ field }) => field === key)
  );
  const translatePrefix = "common";
  const { tc } = useTranslation(translatePrefix);
  const localeText = {
    columnMenuShowColumns: tc("columnMenuShowColumns"),
    columnMenuHideColumn: tc("columnMenuHideColumn"),
    columnsPanelTextFieldLabel: tc("columnsPanelTextFieldLabel"),
    columnsPanelTextFieldPlaceholder: tc("columnsPanelTextFieldPlaceholder"),
    columnsPanelShowAllButton: tc("columnsPanelShowAllButton"),
    columnsPanelHideAllButton: tc("columnsPanelHideAllButton"),
  };

  return { colsShown, setColsShown, hasColHidden, localeText };
}
export function ColumnHiddenMessage(props: { hasColHidden: boolean }) {
  const { tc } = useTranslation("common");
  if (!props.hasColHidden) {
    return <></>;
  }
  return <div>{tc("column_hidden_message")}</div>;
}
