import { useTranslate } from "react-admin";
import {
  displayAmount,
  enumMapping,
  findChainInfo,
  getDecimal,
  toDisplayTime,
} from "../../../../utils/helper";
import {
  enumNewMerchantOperationStatus,
  enumOperationType,
} from "../../../../utils/constant";
import { GetResubmitListRes } from "../../../../api/resubmit";
import { TableRow, TableColumnKey } from "../types/table";
import { GetListRes } from "../types/api";

interface UseTableRowsProps {
  apiList: GetListRes;
}

type ConvertToTableRowsType = (apiList: GetListRes) => TableRow[];

const getOperationTypeDisplayValueTranslateKey = (operationType: number) => {
  return enumMapping(enumOperationType)[operationType];
};

const getOperationStatusDisplayValueTranslateKey = (status: number) => {
  return enumMapping(enumNewMerchantOperationStatus)[status];
};

const getTotalAmountDisplayValue = ({
  chain_type,
  chain_id,
  asset_name,
  total_amount,
}: any) => {
  const decimal = getDecimal({ chain_type, chain_id, asset_name });
  const result = displayAmount(total_amount, decimal);

  return result;
};

const getBatchObjKey = (operationType: number): keyof GetResubmitListRes => {
  const key = operationType === 1 ? "withdraw" : "sweep";
  const batchObjKey = {
    withdraw: "withdraw_request_wallet_operation_batch",
    sweep: "sweep_request_wallet_operation_batch",
  };

  return batchObjKey[key] as keyof GetResubmitListRes;
};

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const translate = useTranslate();
  const te = (key: string) => translate(`enumConstants.${key}`);

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    const tableRows = apiList.rows.map((row, index): TableRow => {
      const batchObjKey = getBatchObjKey(row.operation_type);

      const operationType = te(
        getOperationTypeDisplayValueTranslateKey(row.operation_type)
      );
      const chainName =
        findChainInfo(String(row?.chain_type), row?.chain_id)?.name || "";
      const assetName = row[batchObjKey]?.asset_name;

      const totalAmount = row[batchObjKey]?.total_amount;

      const displayTotalAmount = getTotalAmountDisplayValue({
        chain_type: row?.chain_type,
        chain_id: row?.chain_id,
        asset_name: assetName,
        total_amount: totalAmount,
      });

      const status = te(getOperationStatusDisplayValueTranslateKey(row.status));

      const createdDate = toDisplayTime(row.created_date);

      return {
        id: index,
        [TableColumnKey.BatchId]: row.id,
        [TableColumnKey.OperationType]: operationType,
        [TableColumnKey.ChainName]: chainName,
        [TableColumnKey.AssetName]: assetName,
        [TableColumnKey.TotalAmount]: displayTotalAmount,
        [TableColumnKey.TotalOperation]: row.total_operation,
        [TableColumnKey.Status]: status,
        [TableColumnKey.CreatedBy]: row.created_by,
        [TableColumnKey.CreatedDate]: createdDate,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows };
};

export default useTableRows;
