import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import RetryIcon from "@mui/icons-material/Replay";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import { RiskLevel } from "@wallet-manager/node-types/dist/src/postgres/const";
import { listFace, rescanRiskLevel } from "../../api/merchant";
import { OpView } from "../../assets/icons";
import MoreDetailsDialog from "../../components/AllTransactionReport/MoreDetailsDialog";
import CheckPointAction from "../../components/Common/CheckPointAction";
import { CustomPagination } from "../../components/CustomPagination";
import { Button, Tooltip } from "../../components/MuiGenerals";
import OpIconButton from "../../components/OpIconButton";
import { useAlerting, usePermission } from "../../hooks";
import { dataGridDefaults, FeatureCodes } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

const Pkey = FeatureCodes.report.AllTransactions;
interface propsFace {
  list: listFace;
  listMapping: any;
  page: number;
  isSearchBtnClick: boolean;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
}

export default function AllTransactionReportList(props: propsFace) {
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const { list, listMapping, setPage, page, isSearchBtnClick } = props;
  const totalRecords = list.count;
  const [openTransactionDialog, setOpenTransactionDialog] = useState(false);
  const [tableCellsObject, settableCellsObject] = useState<any>({});
  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  const { alerting } = useAlerting();
  const [disableBtnHashes, setDisableBtnHashes] = useState<string[]>([]);

  useEffect(() => {
    setDisableBtnHashes([]);
  }, [list]);

  const RiskLevelWithRescanButton = (params: any) => {
    const isNoData = params.row.rawData?.risk_level === RiskLevel.NoData;
    const hasBtnPermission = hasPermission(Pkey.RescanRiskLevel);
    const isHaveRiskLevelValue =
      params.row.risk_level && params.row.risk_level.length > 0;

    const shouldShow = hasBtnPermission && isHaveRiskLevelValue;
    const isAlreadyPressed = disableBtnHashes.includes(params.row.tx_hash);
    const isButtonDisabled = !isNoData || isAlreadyPressed;

    const handleButtonClick = async () => {
      const res = await rescanRiskLevel({
        chain_id: params.row.chain_id,
        chain_type: params.row.chain_type,
        block_hash: params.row.block_hash,
        tx_hash: params.row.tx_hash,
        direction: params.row.rawData?.direction,
      });
      if (!res) {
        return;
      }
      alerting("success", t("rescan_risk_level_succeeded"));
      setDisableBtnHashes((state) => [...state, params.row.tx_hash]);
    };

    const RescanButton = () => {
      return (
        <div style={{ position: "absolute", right: "0" }}>
          <Tooltip title={translate("enumConstants.Rescan")}>
            <span>
              <Button
                style={{
                  minWidth: "18px",
                  padding: "4px",
                  borderRadius: "50%",
                }}
                color="secondary"
                variant="contained"
                onClick={handleButtonClick}
                disabled={isButtonDisabled}
              >
                <RetryIcon fontSize="small" />
              </Button>
            </span>
          </Tooltip>
        </div>
      );
    };

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span style={{ marginRight: "8px" }}>{params.row.risk_level}</span>
        {shouldShow && <RescanButton />}
      </div>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("1", t("operation"), {
      renderCell: (params) => (
        <OpIconButton
          title={t("view")}
          url={OpView}
          onClick={() => {
            setOpenTransactionDialog(true);
            settableCellsObject(params.row);
          }}
        />
      ),
    }),
    useGenGridCol("created_date", t("created_date"), {}),
    useGenGridCol("wallet_settlement_date", t("wallet_settlement_date"), {}),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 120,
    }),
    useGenGridCol("display_name", t("display_name"), {}),
    useGenGridCol("client_id", t("client_id"), {}),
    useGenGridCol("wallet_type", t("wallet_type"), { minWidth: 170 }),
    useGenGridCol("trans_type", t("trans_type"), { minWidth: 130 }),
    useGenGridCol("direction", t("direction"), {}),
    useGenGridCol("status", t("status"), { minWidth: 130 }),
    useGenGridCol("risk_level", t("risk_level"), {
      minWidth: 180,
      renderCell: RiskLevelWithRescanButton,
    }),
    useGenGridCol("wallet_address", t("wallet_address"), {}),
    useGenGridCol("wallet_tag", t("wallet_tag"), {}),
    useGenGridCol("from_address", t("from_address"), {}),
    useGenGridCol("to_address", t("to_address"), {}),
    useGenGridCol("to_wallet_tag", t("to_wallet_tag"), {}),
    useGenGridCol("chain_name", t("chain_name"), {}),
    useGenGridCol("asset_name", t("asset_name"), {}),
    useGenGridCol("amount", t("amount"), { minWidth: 130 }),
    useGenGridCol("trans_fee", t("trans_fee"), {}),
    useGenGridCol("post_balance", t("post_balance"), { minWidth: 170 }),
    useGenGridCol("wallet_type_post_balance", t("wallet_type_post_balance"), {
      minWidth: 130,
    }),
    useGenGridCol("is_fee", t("isFeeRecord"), { minWidth: 130 }),
    useGenGridCol("check_point_id", t("check_point_id"), {
      minWidth: 130,
      renderCell: CheckPointAction,
    }),
    useGenGridCol(
      "certifiedWithOnChainBalance",
      t("certifiedWithOnChainBalance"),
      { minWidth: 175 }
    ),
    useGenGridCol("tx_hash", t("tx_hash"), {}),
    useGenGridCol("ref_no", t("ref_no"), {}),
    useGenGridCol("last_modified_date", t("last_modified_date"), {}),
  ].filter((col) => {
    if (col.field === "1" && !hasPermission(Pkey.ViewTransaction)) {
      return false;
    }
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={list.count}
        columns={columns}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
      {openTransactionDialog && (
        <MoreDetailsDialog
          openTransactionDialog={openTransactionDialog}
          setOpenTransactionDialog={setOpenTransactionDialog}
          tableCellsObject={tableCellsObject}
        />
      )}
    </>
  );
}
