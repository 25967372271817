import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { uniq } from "../utils/helper";
import { ChainFace, chainPieceFace, selectChainByPieces } from "./chainsSlice";

export interface AssetsFace {
  [id: string]: OneAssetFace;
}
export interface OneAssetFace {
  id: string;
  chain_type: number;
  chain_id: string;
  asset_name: string;
  address: string;
  decimals: number;
  min_reserve_balance: string;
  created_date: string;
  last_modified_date: string;
}

const initialState: { list: AssetsFace } = {
  list: {},
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    setAssets: (state, action: PayloadAction<AssetsFace>) => {
      state.list = action.payload;
    },
  },
});

export const { setAssets } = assetsSlice.actions;
export const selectAssets = (state: RootState) => state.assets.list;
export const selectAllAssetsName = (state: RootState) => {
  return uniq(
    Object.values(state.assets.list)
      .map((item) => item.asset_name)
      .sort()
  );
};
export const selectAssetsByChain =
  (state: RootState) => (params: Partial<chainPieceFace>) => {
    const { chain_id, chain_type } = selectChainByPieces(state)(
      params
    ) as ChainFace;
    if (!chain_id || !chain_type) return [];
    const output = Object.values(state.assets.list).filter((item) => {
      const boolType = item.chain_type === chain_type;
      const boolId = item.chain_id === chain_id;
      return boolType && boolId;
    });
    return output;
  };
//
export const selectEnableAssetByChain =
  (state: RootState) =>
  (params: Partial<chainPieceFace>, enableAssetList: AssetsFace) => {
    const { chain_id, chain_type } = selectChainByPieces(state)(
      params
    ) as ChainFace;
    if (!chain_id || !chain_type) return [];
    const output = Object.values(enableAssetList).filter((item) => {
      const boolType = item.chain_type === chain_type;
      const boolId = item.chain_id === chain_id;
      return boolType && boolId;
    });
    const enableAssetArr = output.map((item) => item.asset_name);
    return enableAssetArr;
  };
//

export const selectAssetsNameByChain =
  (state: RootState) => (params: Partial<chainPieceFace>) => {
    const assets = uniq(
      selectAssetsByChain(state)(params)
        .map((item) => item.asset_name)
        .sort()
    );
    return assets;
  };
export default assetsSlice.reducer;
