import { useChains } from "../../../../components/FetchConfig";
import { GetListSearchParams } from "../types/api";
import { FilterFields } from "../types/filter";

interface GetListParamsProps {
  defaultParams: {
    [key: string]: any;
    page: number;
    pageSize: number;
  };
}

// const convertBatchIds = (batchId: string) => {
//   const splittedBatchId = batchId.split(",");

//   return splittedBatchId.length > 1 ? splittedBatchId : batchId;
// };

const convertOperationType = (operationType: string) => {
  if (operationType === "") return undefined;

  return [operationType];
};

const useGetListParams = (props: GetListParamsProps) => {
  const { defaultParams } = props;
  const { selectChainByPieces } = useChains();

  const getChainNameApiSearchParams = (chain_name: string) => {
    return selectChainByPieces({
      chain_name: chain_name,
    });
  };

  const getListSearchParams = (fields: FilterFields) => {
    const {
      chainName,
      operationType,
      assetNames,
      batchId,
      createdDateFrom,
      createdDateTo,
    } = fields;

    const { chain_type, chain_id } = getChainNameApiSearchParams(chainName);

    const convertedOperationType = convertOperationType(operationType);

    const params: GetListSearchParams = {
      ...defaultParams,
      chain_type: chain_type,
      chain_id: chain_id,
      operation_type: convertedOperationType,
      asset_names: assetNames,
      batch_id: batchId,
      created_date_from: createdDateFrom,
      created_date_to: createdDateTo,
    };

    const cleanParams = (params: any) => {
      const cleanedParams = params;

      Object.keys(cleanedParams).forEach((key) => {
        if (cleanedParams[key] === undefined || cleanedParams[key] === "") {
          delete cleanedParams[key];
        }
      });

      return cleanedParams;
    };

    return cleanParams(params);
  };

  return {
    getListSearchParams,
  };
};

export default useGetListParams;
