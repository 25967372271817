import React, { useState } from "react";
import { useAssetListStore, useZusDialogStore } from "../../../zustand/store";
import FormLabel from "@mui/material/FormLabel";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useTranslate } from "react-admin";

import { customSx } from "../../../utils/styling";
import { useAlerting, useYubiPrompt } from "../../../hooks";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { assetEdit } from "../../../api/merchant";
import MpTextField from "../../../components/MpTextField";
import YubiPrompter from "../../../components/Prompter";
const DialogContents = () => {
  const zusDialog = useZusDialogStore();
  const translate = useTranslate();
  const t = (key: string) => translate(`assetList.${key}`);
  const { alerting } = useAlerting();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const recordWatch = useAssetListStore((state) => state.recordWatch);
  const setRecordWatch = useAssetListStore((state) => state.setRecordWatch);
  const currentWithdrawStatus = useAssetListStore(
    (state) => state.currentWithdrawStatus
  );
  const { asset_name, asset_config_id, withdraw_status, push_deposit_message } =
    recordWatch;
  const { chain_id = undefined, chain_type = undefined } =
    recordWatch.rawData || {};
  const params = {
    chain_type: chain_type && String(chain_type),
    chain_id: chain_id,
    asset_name: asset_name,
    asset_config_status: String(withdraw_status),
    asset_config_deposit_push: push_deposit_message === "1",
    asset_config_id,
  };

  const handleRadioGroupChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const status = (event.target as HTMLInputElement).value;
      setRecordWatch({ field, value: status });
    };

  const handleConfirm = async () => {
    const res = await getResAfterYubi(assetEdit, params);

    if (res) {
      alerting("success", t("confirmed"));
    }
    zusDialog.closeAll();
  };
  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.match("assetListOpDialog")}
        onClose={zusDialog.close}
        fullWidth={true}
      >
        <DialogTitle>{t("operation")}</DialogTitle>
        <DialogContent>
          <MpTextField
            name={"chain_name"}
            variant="filled"
            fullWidth={true}
            label={t("chain_name")}
            defaultValue={recordWatch.chain_name}
            disabled={true}
          />
          <br />
          <MpTextField
            name={"asset_name"}
            variant="filled"
            defaultValue={recordWatch.asset_name}
            fullWidth={true}
            label={t("asset_name")}
            disabled={true}
          />
          <br />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {t("push_deposit_message")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleRadioGroupChange("push_deposit_message")}
              value={recordWatch.push_deposit_message}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={t("Enable")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio />}
                label={t("Disable")}
              />
            </RadioGroup>
          </FormControl>
          <br />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {t("withdraw_status")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleRadioGroupChange("withdraw_status")}
              value={recordWatch.withdraw_status}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label={t("Enable")}
              />
              <FormControlLabel
                value={-1}
                control={<Radio />}
                label={t("Disable")}
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button color="secondary" variant="contained" onClick={handleConfirm}>
            {t("confirm")}
          </Button>

          <Button variant="contained" color="error" onClick={zusDialog.close}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogContents;
