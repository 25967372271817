import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { listFace } from "../../api/merchant";
import { useTranslate } from "react-admin";
import { CustomPagination } from "../../components/CustomPagination";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
interface propsFace {
  list: listFace;
  rangeType: string;
  listMapping: any;
  page: number;
  isSearchBtnClick: boolean;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
}
export default function MerchantReportList(props: propsFace) {
  const { list, rangeType, listMapping, setPage, page, isSearchBtnClick } =
    props;
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const content: GridRowsProp = listMapping("key", list.rows, {
    translate,
    rangeType,
  });

  const columns: GridColDef[] = [
    useGenGridCol("mark_date", t("date")),
    useGenGridCol("display_name", t("display_name")),
    useGenGridCol("wallet_type", t("wallet_type")),
    useGenGridCol("wallet_address", t("wallet_address")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("opening_balance", t("opening_balance")),
    useGenGridCol("in_hot_wallet_deposit", t("in_hot_wallet_deposit")),
    useGenGridCol("in_sweep_to_hot_wallet", t("in_sweep_to_hot_wallet")),
    useGenGridCol("in_hot_wallet_transfer", t("in_hot_wallet_transfer")),
    useGenGridCol("in_hot_wallet_topup", t("in_hot_wallet_topup")),
    // useGenGridCol("in_withdraw", t("in_withdraw")),
    useGenGridCol("in_provision_withdraw", t("in_provision_withdraw")),
    useGenGridCol("in_invoker_deposit", t("in_invoker_deposit")),
    useGenGridCol("in_invoker_transfer", t("in_invoker_transfer")),
    useGenGridCol("in_merchant_deposit", t("in_merchant_deposit")),
    useGenGridCol("in_sweep", t("in_sweep")),
    useGenGridCol("in_merchant_transfer", t("in_merchant_transfer")),

    // useGenGridCol("in_settlement", t("in_settlement")),
    // useGenGridCol("in_external_transfer", t("in_external_transfer")),
    // useGenGridCol(
    //   "in_settlement_to_hot_wallet",
    //   t("in_settlement_to_hot_wallet")
    // ),
    // useGenGridCol(
    //   "in_settlement_to_invoker_wallet",
    //   t("in_settlement_to_invoker_wallet")
    // ),
    // useGenGridCol(
    //   "in_settlement_to_sweep_dest_wallet",
    //   t("in_settlement_to_sweep_dest_wallet")
    // ),
    useGenGridCol("in_balance_adjustment", t("in_balance_adjustment")),
    useGenGridCol("in_caution", t("in_caution")),
    useGenGridCol("out_withdraw", t("out_withdraw")),
    useGenGridCol("out_merchant_withdraw", t("out_merchant_withdraw")),
    useGenGridCol("out_withdraw_deposit", t("out_withdraw_deposit")),
    useGenGridCol("out_client_wallet_topup", t("out_client_wallet_topup")),
    useGenGridCol("out_hot_wallet_transfer", t("out_hot_wallet_transfer")),
    useGenGridCol("out_hot_wallet_topup", t("out_hot_wallet_topup")),
    useGenGridCol(
      "out_provision_for_withdraw",
      t("out_provision_for_withdraw")
    ),
    useGenGridCol("out_invoker_transfer", t("out_invoker_transfer")),
    useGenGridCol("out_merchant_transfer", t("out_merchant_transfer")),
    // useGenGridCol("out_client_deposit", t("out_client_deposit")),
    // useGenGridCol("out_hot_wallet_deposit", t("out_hot_wallet_deposit")),
    // useGenGridCol("out_invoker_deposit", t("out_invoker_deposit")),
    useGenGridCol("out_settlement", t("out_settlement")),
    // useGenGridCol("out_external_transfer", t("out_external_transfer")),

    // useGenGridCol("out_withdraw_fee", t("out_withdraw_fee")),
    // useGenGridCol("out_topup_fee", t("out_topup_fee")),
    // useGenGridCol("out_merchant_withdraw_fee", t("out_merchant_withdraw_fee")),
    useGenGridCol("out_balance_adjustment", t("out_balance_adjustment")),
    useGenGridCol("out_caution", t("out_caution")),
    useGenGridCol("trans_fee", t("trans_fee")),
    useGenGridCol("closing_balance", t("closingBalance")),
  ].filter((col) => !(rangeType === "custom" && col.field === "mark_date"));

  const totalRecords = list.count;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig(rangeType)
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
