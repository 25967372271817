import React from "react";
import { OpEdit } from "../../assets/icons";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "../../components/MuiGenerals";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
import { displayAmount } from "../../utils/helper";
import { useTranslation } from "../../hooks";
const translatePrefix = "withdrawApprovalSetting";
interface IRecordWatchProps {
  params: GridRenderCellParams;
  configTime?: any;
}
const RenderDialog = ({ params, configTime }: IRecordWatchProps) => {
  //{- - Zustand- -}
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const zusDialog = useZusDialogStore();

  // const { email } = useAppSelector(selectProfile);
  const openDialogWindow = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    zusDialog.open("operationDialog");
    const targetRow = params.row;
    const { rawData } = targetRow;

    const withdrawArr = rawData.withdraw_approval_regulations?.filter(
      (item: any) => !item.wallet_type
    );
    const settleMemetwArr = rawData.withdraw_approval_regulations?.filter(
      (item: any) => item.wallet_type
    );

    const currentRegulation = mergeArrays(
      withdrawArr,
      settleMemetwArr,
      rawData.decimal
    );

    const dialogContent = {
      ...targetRow,
      rawData: {
        ...rawData,
        withdraw_approval_regulations: currentRegulation,
      },
    };

    dialogContent.rawData.withdraw_approval_regulations.sort(
      (a: any, b: any) => a.seq - b.seq
    );
    useStore.setRecordForEdit({ ...dialogContent, configTime });
    useStore.setCurrentValues({
      editField: {
        field: "currentThreshold",
        value: rawData.threshold_amount,
      },
    });
    useStore.setCurrentValues({
      editField: {
        field: "curr_hourly_auto_approval_limit",
        value: rawData.hourly_auto_approval_limit,
      },
    });
    useStore.setCurrentValues({
      editField: {
        field: "currentRegulation",
        value: currentRegulation,
      },
    });
    useStore.setCurrentValues({
      editField: {
        field: "curr_mx_hourly_limit",
        value: rawData.mx_hourly_limit,
      },
    });
    useStore.setCurrentValues({
      editField: {
        field: "curr_mx_addr_hrly_count",
        value: rawData.mx_addr_hrly_count,
      },
    });
    useStore.setCurrentValues({
      editField: {
        field: "curr_mx_addr_hrly_limit",
        value: rawData.mx_addr_hrly_limit,
      },
    });
  };
  return (
    <>
      <IconButton type="edit" openDialogWindow={openDialogWindow} />
    </>
  );
};

export default RenderDialog;
// {--- - - -}
const IconButton = (p: {
  type: "edit";
  disabled?: boolean;
  openDialogWindow: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}) => {
  const { tc } = useTranslation(translatePrefix);
  const sx = {
    box: {
      width: "2rem",
      cursor: p.disabled ? "" : "pointer",
      margin: "0 0.5rem",
      "&:hover": {
        filter: p.disabled ? "brightness(100%)" : "brightness(130%)",
      },
    },
    img: {
      filter: p.disabled ? "grayscale(100%)" : "grayscale(0)",
      width: "100%",
    },
  };
  const icon = {
    edit: { name: tc("edit"), url: OpEdit },
  }[p.type];
  // const permType = p.type === "re_broadcast" ? "re_broadcast" : p.type;
  // if (!hasPermission(permType)) return null;
  return (
    <Tooltip title={icon.name}>
      <Box sx={sx.box}>
        <img
          src={icon.url}
          onClick={(e) => !p.disabled && p.openDialogWindow(e)}
          style={sx.img}
          alt=""
        />
      </Box>
    </Tooltip>
  );
};
interface IProps {
  amount: any;
  id: string;
  seq: number;
  wallet_types: number;
  withdraw_approval_regulation_fk: number;
  withdraw_threshold_id: number;
}

export const mergeArrays = (
  firstArr: Array<IProps>,
  secondArr: Array<IProps>,
  decimal: string | number
) => {
  const withdrawAmtArr = firstArr.map((item) => {
    const copyObj = { ...item };
    const newObj: any = {};
    newObj["withdraw_amt"] = displayAmount(
      copyObj["amount"],
      decimal
    ).replaceAll(",", "");
    newObj["settle_amt"] = "";
    newObj["seq"] = copyObj["seq"];
    return newObj;
  });

  const settleAmtArr = secondArr.map((item) => {
    const newObj: any = {};
    const copyObj = { ...item };
    newObj["settle_amt"] = displayAmount(copyObj["amount"], decimal).replaceAll(
      ",",
      ""
    );
    newObj["withdraw_amt"] = "";
    newObj["seq"] = copyObj["seq"];
    return newObj;
  });

  const mergedArr = withdrawAmtArr.concat(settleAmtArr);

  const mergedMap = new Map();
  mergedArr.forEach((element: any) => {
    // Get the seq property
    const seq = element.seq;
    // Check if this seq is already in the map
    if (mergedMap.has(seq)) {
      // If it is, merge the current object with the existing one
      const existingObj = mergedMap.get(seq);
      const mergedObj = {
        seq: seq,
        withdraw_amt: element.withdraw_amt || existingObj.withdraw_amt,
        settle_amt: element.settle_amt || existingObj.settle_amt,
      };
      mergedMap.set(seq, mergedObj);
    } else {
      // If it's not, add the current object to the map
      mergedMap.set(seq, element);
    }
  });
  // Convert the map back to an array and return it
  const mergedArrWithNoDuplicates = Array.from(mergedMap.values());

  return mergedArrWithNoDuplicates ? mergedArrWithNoDuplicates : [];
};
