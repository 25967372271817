import OpTextButton from "../../components/OpTextButton";
import { useTranslate } from "react-admin";
import {
  FeatureCodes,
  enumWalletBalanceAdjustmentApproval,
  tableConfig,
} from "../../utils/constant";
import {
  formatBigNumber,
  formatLargeNumberforBigNumber,
  toDisplayTime,
} from "../../utils/helper";
import BigNumber from "bignumber.js";
import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "../../components/MuiGenerals";
import { customSx } from "../../utils/styling";
import { TableHeadComponent } from "../TableHeadComponent";
import { approveAdjustBalance, rejectAdjustBalance } from "../../api/merchant";
import { useRefresh } from "../../utils/HelperComp";
import { useAlerting, usePermission, useYubiPrompt } from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import YubiPrompter from "../Prompter";
const Pkey = FeatureCodes.walletManagement.WalletBalanceAdjustment;
interface ApprovalDialogFace {
  approvalDialogBol: boolean;
  setApprovalDialogBol: React.Dispatch<React.SetStateAction<boolean>>;
  tableCellsObject: any;
  setFreshTableBol: React.Dispatch<React.SetStateAction<boolean>>;
}
interface TableRowFace {
  leftTableCellProp: string;
  rightTableCellProp?: string | undefined;
  tableCellsObject: any;
}

const approvalTableProps = [
  {
    leftTableCellProp: "proposal_no",
    rightTableCellProp: "wallet_name",
  },
  {
    leftTableCellProp: "chain_name",
    rightTableCellProp: "wallet_type",
  },
  {
    leftTableCellProp: "asset_name",
    rightTableCellProp: "currentWalletBalance",
  },
  {
    leftTableCellProp: "amount",
    rightTableCellProp: "futureWalletBalance",
  },
  {
    leftTableCellProp: "created_by_email",
    rightTableCellProp: "date_created",
  },
];

export function ApprovalDialogTable({
  approvalDialogBol,
  setApprovalDialogBol,
  tableCellsObject,
  setFreshTableBol,
}: ApprovalDialogFace) {
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { email } = useAppSelector(selectProfile);
  const [refreshBool, refresh] = useRefresh();
  const { alerting } = useAlerting();
  const onConfirmation = async (reviewInfo: string) => {
    const noPermission =
      (reviewInfo === enumWalletBalanceAdjustmentApproval.approve &&
        !hasPermission(Pkey.Approve)) ||
      (reviewInfo === enumWalletBalanceAdjustmentApproval.reject &&
        !hasPermission(Pkey.Reject));
    if (noPermission) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    const id = tableCellsObject.proposal_no;
    const isCreator = email === tableCellsObject.created_by_email;
    if (reviewInfo === enumWalletBalanceAdjustmentApproval.approve) {
      try {
        if (isCreator) {
          return alerting("error", t("alert_creator_approver_same"));
        }
        const approveOperationsRes = await getResAfterYubi(
          approveAdjustBalance,
          {
            id,
          }
        );

        if (approveOperationsRes) {
          alerting("success", t("transactionApproved"));
          setApprovalDialogBol(false);
          setFreshTableBol(true);
          refresh();
          return;
        }
      } catch (error) {
        console.error("approveAdjustBalance", error);
      }
    } else if (reviewInfo === enumWalletBalanceAdjustmentApproval.reject) {
      try {
        const rejectOperationsRes = await getResAfterYubi(rejectAdjustBalance, {
          id,
        });

        if (rejectOperationsRes) {
          setApprovalDialogBol(false);
          setFreshTableBol(true);
          refresh();
          return alerting("success", t("transactionRejected"));
        }
      } catch (error) {
        console.error("rejectAdjustBalance", error);
      }
    }
  };
  if (!refreshBool) return <></>;
  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={approvalDialogBol}
        onClose={() => setApprovalDialogBol(false)}
        fullWidth={true}
      >
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHeadComponent header={t("approvalDetails")} />
              <TableBody>
                {approvalTableProps.map((i, index) => (
                  <TableRowElement
                    key={index}
                    tableCellsObject={tableCellsObject}
                    leftTableCellProp={i.leftTableCellProp}
                    rightTableCellProp={i.rightTableCellProp}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <OpTextButton
            text={t("approve")}
            // isHidden={!hasPermission(Pkey.Approve)}
            onClick={() =>
              onConfirmation(enumWalletBalanceAdjustmentApproval.approve)
            }
            sx={{ borderRadius: ".3rem" }}
          />
          <OpTextButton
            text={t("reject")}
            // isHidden={!hasPermission(Pkey.Reject)}
            onClick={() =>
              onConfirmation(enumWalletBalanceAdjustmentApproval.reject)
            }
            sx={{ borderRadius: ".3rem" }}
          />
          <Button
            variant="contained"
            color="error"
            onClick={() => setApprovalDialogBol(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {/* <ReviewConfirmationDialog
        setFreshTableBol={setFreshTableBol}
        confirmationInfo={{ reviewInfo, tableCellsObject }}
        confirmationDialogBol={confirmationDialogBol}
        setConfirmationDialogBol={setConfirmationDialogBol}
        setApprovalDialogBol={setApprovalDialogBol}
      /> */}
    </>
  );
}

export function TableRowElement({
  leftTableCellProp,
  rightTableCellProp,
  tableCellsObject,
}: TableRowFace) {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const amountArray = ["amount", "currentWalletBalance"];
  const formatValue = (property: string) => {
    if (tableConfig.dateFields.includes(property)) {
      return toDisplayTime(tableCellsObject[property]);
    } else if (property === "futureWalletBalance") {
      return getFutureWalletBalance();
    } else if (amountArray.includes(property)) {
      return amountFormatting(property);
    } else if (!tableCellsObject[property]) {
      return "N/A";
    } else {
      return tableCellsObject[property];
    }
  };

  const getFutureWalletBalance = () => {
    const bal = BigNumber(
      formatLargeNumberforBigNumber(tableCellsObject.currentWalletBalance)
    ).plus(formatLargeNumberforBigNumber(tableCellsObject.amount));
    return formatBigNumber(bal);
  };

  const amountFormatting = (property: string) => {
    const bal = BigNumber(
      formatLargeNumberforBigNumber(tableCellsObject[property])
    );
    return formatBigNumber(bal);
  };

  return (
    <TableRow sx={customSx.tableRow}>
      <TableCell sx={customSx.tableCell}>
        {leftTableCellProp === "amount"
          ? t("adjustment_amount")
          : t(`${leftTableCellProp}`)}
        : {formatValue(leftTableCellProp)}
      </TableCell>
      {rightTableCellProp ? (
        <TableCell sx={customSx.tableCell}>
          {t(`${rightTableCellProp}`)}: {formatValue(rightTableCellProp)}
        </TableCell>
      ) : (
        <TableCell sx={customSx.tableCell}></TableCell>
      )}
    </TableRow>
  );
}
