import { Dispatch, SetStateAction, useEffect } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { getClientBalance } from '../../../api/merchant';
import { CustomPagination } from '../../../components/CustomPagination';
import LoadingDialog from '../../../components/LoadingDialog';
import { NoRowsOverlay } from '../../../components/NoRowsOverlay';
import { useTranslation } from '../../../hooks';
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig
} from '../../../hooks/useColumnHide';
import { dataGridDefaults } from '../../../utils/constant';
import { useGenGridCol } from '../../../utils/HelperComp';
import { useZusParams } from './ClientBalance';

const translatePrefix = "ledgerClientBalance";
const api = {
  getClientBalance,
};
export default function ClientBalanceList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: ["clientBalance", zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.getClientBalance(zusParams.body),
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol("client_id", t("client_id"), { minWidth: 150 }),
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 150 }),
    useGenGridCol("currency", t("currency")),
    useGenGridCol("client_balance", t("client_balance"), { minWidth: 150 }),
    useGenGridCol("frozen_balance", t("frozen_balance"), { minWidth: 150 }),
    useGenGridCol("credit", t("credit"), { minWidth: 150 }),
    useGenGridCol("creation_time", t("creation_time"), { minWidth: 150 }),
    useGenGridCol("last_modified_date", t("last_modified_date"), {
      minWidth: 150,
    }),
  ];

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
