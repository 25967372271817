import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Typography } from "./MuiGenerals";
import { useTranslate } from "react-admin";

export function CustomPagination(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  const sx = {
    container: {
      display: "flex",
      justifyContent: "space-between",
      padding: "10px",
      backgroundColor: "white",
    },
    footerString: {
      paddingTop: "5px",
      color: "#A2A2A2",
    },
  };

  return (
    <div style={sx.container}>
      <Typography variant="body1" sx={sx.footerString}>
        {!props.noshowFooter ? (
          <>
            {t("itemTotalStart")}
            <span style={{ color: "black" }}>{props.totalRecords}</span>
            {t("itemTotalEnd")}
          </>
        ) : (
          <></>
        )}
      </Typography>
      <Pagination
        color="secondary"
        count={pageCount}
        page={page + 1}
        size="small"
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </div>
  );
}
