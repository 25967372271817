import { Box } from "@mui/material";
import { OpRedirect } from "../../assets/icons";

//{- -Redirect Button for checkpoint ID - -}
const CheckPointButton = (props: any) => {
  const { checkPointId } = props;

  const sx = {
    box: {
      width: "2rem",
      cursor: "pointer",
      marginTop: "0.2rem",
      "&:hover": {
        filter: "brightness(130%)",
      },
    },
    img: {
      width: "50%",
    },
  };
  const icon = {
    checkPointRedirect: { name: "", url: OpRedirect },
  };
  const redirect = () => {
    window.open(`balanceCheckpoint?checkpointId=${checkPointId}`, "_blank");
  };
  return (
    <>
      {/* <Tooltip title={t("checkpoint")}> */}
      <Box sx={sx.box}>
        <img
          src={icon.checkPointRedirect.url}
          onClick={redirect}
          style={sx.img}
        />
      </Box>
      {/* </Tooltip> */}
    </>
  );
};

export default CheckPointButton;
