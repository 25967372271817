import { useTranslate } from 'react-admin';

import { Tooltip } from '../../../components/MuiGenerals';
import style from './WIndow.module.css';

const OpDatagridFooter = ({ footerprops }: any) => {
  const {
    addressCount,
    asset_name,
    trxHrCount,
    trxHrAmt,
    trxDayAmt,
    checkingHour,
  } = footerprops;
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  return (
    <>
      <div
        style={{
          backgroundColor: "#f1f2f4",
          borderBottom: "solid #d9d9d9 1px",
          marginLeft: "7px",
          marginRight: "7px",
        }}
      ></div>
      <div
        id="container"
        className={
          checkingHour !== 24
            ? `${style.gridContainer}`
            : `${style.gridWithoutDayAmt}`
        }
      >
        <div className={style.total}>{t("total")}</div>
        <div className={style.address}>
          {addressCount}
          <span> {t("address")}</span>
        </div>

        <div id="num" className={style.trxCount}>
          {trxHrCount}
        </div>

        <Tooltip title={trxHrAmt + " " + asset_name} placement="bottom-end">
          <div id="hrlyAmt" className={style.OpStatAmt}>
            {trxHrAmt + " " + asset_name}
          </div>
        </Tooltip>
        {checkingHour !== 24 && (
          <Tooltip title={trxDayAmt + " " + asset_name} placement="bottom-end">
            <div id="dayAmt" className={style.OpStatAmt}>
              {trxDayAmt + " " + asset_name}
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default OpDatagridFooter;
