import endOfMonth from 'date-fns/endOfMonth';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYesterday from 'date-fns/startOfYesterday';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import { SelectChangeEvent } from '@mui/material/Select';

import {
  getClientRangeReport,
  getClientReport,
  listFace
} from '../../api/merchant';
import { AssetMultiSelection } from '../../components/AssetSelection';
import { ChainSingleSelection } from '../../components/ChainSelection';
import { useDatePicker } from '../../components/DatePicker';
import { useAssets, useChains } from '../../components/FetchConfig';
import GeneralBtn from '../../components/GeneralBtn';
import MpTextField from '../../components/MpTextField';
import {
  Box,
  Button,
  Container,
  InputAdornment
} from '../../components/MuiGenerals';
import { useAlerting, usePermission, useTabs } from '../../hooks';
import {
  enumWalletType,
  FeatureCodes,
  tableConfig
} from '../../utils/constant';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  moreThan2Months,
  separateBatchStrings,
  toOnlyDate
} from '../../utils/helper';
import { DivideLine, genField } from '../../utils/HelperComp';
import { customSx } from '../../utils/styling';
import { useZusDialogStore } from '../../zustand/store';
import ClientReportList from './ClientReportList';
import ClientReportSummary from './ClientReportSummary';

const Pkey = FeatureCodes.report.ClientReport;

export default function ClientReport() {
  const translate = useTranslate();

  const t = (key: string) => translate(`report.${key}`);
  const { hasPermission } = usePermission();
  const tabsArr = [
    hasPermission(Pkey.TabDaily.prefix) && {
      name: t("dailyReport"),
      value: "daily",
    },
    hasPermission(Pkey.TabCustom.prefix) && {
      name: t("customReport"),
      value: "custom",
    },
    hasPermission(Pkey.TabSummary.prefix) && {
      name: t("summary_report"),
      value: "summary",
    },
  ].filter((tab) => tab) as { name: string; value: string }[];
  const { Tabs, tabValue } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Tabs children={<Report rangeType={tabValue} />} />
    </Box>
  );
}

function Report(props: { rangeType: string }) {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const { hasPermission } = usePermission();
  const { rangeType } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  if (rangeType === "daily" && !hasPermission(Pkey.TabDaily.prefix))
    return <></>;
  if (rangeType === "custom" && !hasPermission(Pkey.TabCustom.prefix))
    return <></>;
  if (rangeType === "summary" && hasPermission(Pkey.TabSummary.prefix))
    return <ClientReportSummary Tabs={Tabs} />;
  return (
    <>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          setPage={setPage}
          page={page}
          rangeType={rangeType}
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <ClientReportList
            list={list}
            page={page}
            setPage={setPage}
            rangeType={rangeType}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </>
  );
}
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
  walletTypes: string[];
  clientIdsString: string;
  wallet_address: string;
  display_name: string;
}
const initFields = {
  asset_names: [],
  chain_name: "",
  walletTypes: [],
  clientIdsString: "",
  wallet_address: "",
  display_name: "",
};

function FilterBar(props: any) {
  const {
    count,
    setList,
    page,
    setPage,
    rangeType,
    isSearchBtnClick,
    setSearchBtnClick,
  } = props;
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string, option?: Object) =>
    translate(`report.${key}`, option);
  const tc = (key: string) => translate(`common.${key}`);
  const pageSize = tableConfig.pageSize;
  const now = new Date();
  const zusDialog = useZusDialogStore();
  const dateConfigObj = {
    daily: {
      start: { defaultDate: startOfYesterday() },
      end: { defaultDate: endOfYesterday() },
    },
    custom: {
      start: { defaultDate: startOfMonth(now) },
      end: { defaultDate: endOfMonth(now) },
    },
  };
  const defaultDate = dateConfigObj[rangeType as keyof typeof dateConfigObj];
  const DateObj = useDatePicker({
    ...defaultDate,
    // isTimezoneConvert: false,
    isEndOfSecond: false,
  });
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const {
      clientIdsString,
      walletTypes,
      asset_names,
      chain_name,
      wallet_address,
      display_name,
      ...rest
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const clientIds = separateBatchStrings(clientIdsString);
    const walletAddressString = separateBatchStrings(wallet_address);
    const displayNameString = separateBatchStrings(display_name);
    // const walletAddressString = separateBatchStrings(wallet_address);
    const walletTypesId = Object.entries(enumWalletType)
      .filter(([_, name]) => walletTypes.join(",").includes(name))
      .map(([id]) => id);
    return {
      ...rest,
      chain_type,
      chain_id,
      wallet_address: walletAddressString,
      displayNames: displayNameString,
      asset_names: asset_names,
      client_ids: clientIds,
      walletTypesId,
      mark_date_from: DateObj.start,
      mark_date_to: DateObj.end,
      page,
      pageSize,
    };
  };
  const apiFn = rangeType === "daily" ? getClientReport : getClientRangeReport;
  const params = getParams();
  const { alerting } = useAlerting();
  const [cacheParams, setCacheParams] = useState(params);
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await apiFn({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, isSearchBtnClick]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSearch = async () => {
    const isExceedMonths = moreThan2Months(
      new Date(params.mark_date_from),
      new Date(params.mark_date_to)
    );
    if (isExceedMonths)
      return alerting("warning", t("date_range_exceed", { months: 2 }));
    setPage(0);
    setCacheParams(params);
    setSearchBtnClick(true);
  };

  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue =
      typeof value === "string"
        ? value.split(",").map((string) => string.trim())
        : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((filter) => ({ ...filter, [name]: newValue }));
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiPagingFn = (page: number, pageSize: number, signal: any) =>
      apiFn({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiPagingFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { rangeType, translate });
    const reportRange = rangeType === "daily" ? "Daily" : "Custom";
    const config = {};
    downloadFiles(`Client ${reportRange} Report`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    // if (rangeType === "custom") {
    DateObj.clearDate();
    // } else {
    //   DateObj.clearDate();
    // }
  };
  const F = genField({ t }, [
    ["date", <DateObj.Picker type="date" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_names",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "client_id",
      <MpTextField
        label={t("phClient_id")}
        value={fields.clientIdsString}
        onChange={onChange("clientIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.client_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_address",
      <MpTextField
        name="wallet_address"
        label={t("phWallet_address")}
        value={fields.wallet_address}
        onChange={onChange("wallet_address")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_address.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "display_name",
      <MpTextField
        name="display_name"
        label={t("phDisplay_name")}
        value={fields.display_name}
        onChange={onChange("display_name")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.displayNames.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);

  const showDailyExport =
    rangeType === "daily" && hasPermission(Pkey.TabDaily.Export);
  const showCustomExport =
    rangeType === "custom" && hasPermission(Pkey.TabCustom.Export);
  const showExport = showDailyExport || showCustomExport;
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn label="export" isHidden={!showExport} onClick={onExport} />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.date}
        {F.client_id}
        {F.chain_name}
        {F.asset_names}
        {F.wallet_address}
        {F.display_name}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <Button onClick={onReset} className="resetBtn" variant="contained">
          {t("reset")}
        </Button>
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { rangeType = "custom", translate } = config;
  const t = (key: string) => translate(`report.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const isDaily = rangeType === "daily";
    const isCustom = rangeType === "custom";
    const markDateCol = isDaily &&
      item.mark_date && ["mark_date", t("date"), toOnlyDate(item.mark_date)];
    const mappedResult = [
      markDateCol,
      [
        "display_name",
        t("display_name"),
        isCustom ? item.display_name : item?.wallet?.display_name,
      ],
      [
        "client_id",
        t("client_id"),
        isDaily ? item?.wallet?.client_id : item.client_id,
      ],
      ["wallet_address", t("wallet_address"), item.wallet_address],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      ["asset_name", t("asset_names"), item.asset_name],
      [
        "opening_balance",
        t("opening_balance"),
        item.opening_balance === null ? "0" : toAmount(item.opening_balance),
      ],
      [
        "in_client_deposit",
        t("in_client_deposit"),
        toAmount(item.in_client_deposit),
      ],
      [
        "in_withdraw_deposit",
        t("in_withdraw_deposit"),
        toAmount(item.in_withdraw_deposit),
      ],
      [
        "in_client_wallet_topup",
        t("in_client_wallet_topup"),
        toAmount(item.in_client_wallet_topup),
      ],
      // [
      //   "in_settlement-deposit",
      //   t("in_settlement-deposit"),
      //   toAmount(item["in_settlement-deposit"]),
      // ],
      [
        "in_balance_adjustment",
        t("in_balance_adjustment"),
        toAmount(item.in_balance_adjustment),
      ],
      ["in_caution", t("in_caution"), toAmount(item.in_caution)],
      [
        "out_sweep_to_hot_wallet",
        t("out_sweep_to_hot_wallet"),
        toAmount(item.out_sweep_to_hot_wallet),
      ],
      ["out_sweep", t("out_sweep"), toAmount(item.out_sweep)],
      //["out_sweep_fee", t("out_sweep_fee"), toAmount(item.out_sweep_fee)],
      [
        "out_balance_adjustment",
        t("out_balance_adjustment"),
        toAmount(item.out_balance_adjustment),
      ],
      ["out_caution", t("out_caution"), toAmount(item.out_caution)],
      ["trans_fee", t("trans_fee"), toAmount(item.trans_fee)],
      ["closing_balance", t("closingBalance"), toAmount(item.closing_balance)],
    ].filter((col) => col);
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
