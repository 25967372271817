import { Container, Box, Button } from "../../components/MuiGenerals";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useTranslate } from "react-admin";
import { useState, Dispatch, SetStateAction } from "react";
import { rescanBlocks, rescanTrx } from "../../api/merchant";
import { useChains } from "../../components/FetchConfig";
import { customSx } from "../../utils/styling";
import { genField } from "../../utils/HelperComp";
import { useAlerting, usePermission } from "../../hooks";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import omit from "lodash/omit";
import { FeatureCodes } from "../../utils/constant";
import MpTextField from "../../components/MpTextField";
interface fieldsFace {
  chain_name: string;
  block_number: string;
  to_block: string;
  tx_hashes: string;
}

const initFields = {
  chain_name: "",
  block_number: "",
  to_block: "",
  tx_hashes: "",
};
const api = {
  rescanTrx,
  rescanBlocks,
};
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const Pkey = FeatureCodes.tools.Rescan;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index} //hidden all if no index==value
      id={`simple-tabpanel-${index}`} //showing which tab
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && ( //if value match, then display the target tab-content
        <Box>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const BlockRescan = () => {
  const [tabsIndex, setTabIndex] = useState(0); //the default index :which tab
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`tools.${key}`);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  const rescanTab = [
    {
      name: "trx_hash_rescan_tab",
      path: Pkey.TransactionRescan,
      index: 0,
      jsxNode: <RescanTab tab={"trx_hash_rescan_tab"} />,
    },
    {
      name: "block_rescan_tab",
      path: Pkey.BlockRescan,
      index: 1,
      jsxNode: <RescanTab tab={"block_rescan_tab"} />,
    },
  ].filter((item) => hasPermission(item.path));
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {rescanTab.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {/* Real Tab Content */}
      {rescanTab.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};
export default BlockRescan;

function RescanTab(props: { tab: "trx_hash_rescan_tab" | "block_rescan_tab" }) {
  const { tab } = props;
  const [fields, setFields] = useState<fieldsFace>(initFields);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar fields={fields} setFields={setFields} tab={tab} />
      </Container>
    </Box>
  );
}
function FilterBar(props: {
  fields: fieldsFace;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
  tab: string;
}) {
  const { fields, setFields, tab } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  const { selectChainByPieces } = useChains();
  const { alerting } = useAlerting();
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const { chain_type, chain_id } = selectChainByPieces({
    chain_name: fields.chain_name,
  });
  const params = {
    chain_type: Number(chain_type),
    chain_id: Number(chain_id),
    blocks: fields.block_number
      ? [
          { from_block: fields.block_number, to_block: fields.block_number }, //only search for single block.
        ]
      : "",
    tx_hashes: fields.tx_hashes ? [fields.tx_hashes] : "",
  };

  const rescan = async () => {
    //{- -Checking if User hasn't input anything- -}
    const ifChainIdAndTypeEmpty = params.chain_id && params.chain_type;
    const validationResult =
      ifChainIdAndTypeEmpty && tab === "trx_hash_rescan_tab" && fields.tx_hashes
        ? { hasInput: true, rescanBy: api.rescanTrx }
        : tab === "block_rescan_tab" &&
          ifChainIdAndTypeEmpty &&
          fields.block_number
        ? { hasInput: true, rescanBy: api.rescanBlocks }
        : { hasInput: false, rescanBy: () => {} };
    if (!validationResult.hasInput && tab === "trx_hash_rescan_tab")
      return alerting("warning", t("trxhashFieldIsEmpty"));
    if (!validationResult.hasInput && tab === "block_rescan_tab")
      return alerting("warning", t("blockNumberFieldIsEmpty"));
    //{- -filter the empty attribute - -}
    const paramsAfterFilter = !Boolean(fields.tx_hashes)
      ? omit(params, ["tx_hashes"])
      : !Boolean(fields.block_number)
      ? omit(params, ["blocks"])
      : params;
    //
    const res = await validationResult.rescanBy(paramsAfterFilter);
    if (!res) {
      return;
    }
    alerting("success", t("rescan_success"));
  };
  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "block_number",
      <MpTextField
        name="block_number"
        label={t("ph_block_number")}
        value={fields.block_number}
        onChange={onChange("block_number")}
      />,
    ],
    [
      "tx_hashes",
      <MpTextField
        name="tx_hashes"
        label={t("ph_trx_hash")}
        value={fields.tx_hashes}
        onChange={onChange("tx_hashes")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {tab === "trx_hash_rescan_tab" && F.tx_hashes}
        {tab === "block_rescan_tab" && F.block_number}
      </Box>
      <Box sx={customSx.filterB}>
        <Button onClick={rescan} color="secondary" variant="contained">
          {t("rescan")}
        </Button>
      </Box>
    </>
  );
}
