import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { FeatureCodes, dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { CustomPagination } from "../../components/CustomPagination";
import RenderDialog from "../../components/WithDrawApprovalSetting/RenderDialog";
import {
  useWithDrawApprovalSettingStore,
  useZusListStore,
} from "../../zustand/store";
import React from "react";
import { usePermission, useTranslation } from "../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabSetting;
const translatePrefix = "withdrawApprovalSetting";
const WithdrawApprovalSettingList = (props: { listMapping: any }) => {
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const { listMapping } = props;
  const zusList = useZusListStore();
  const totalRecords = zusList.count;
  const configTime = useWithDrawApprovalSettingStore(
    (state) => state.configTime
  );

  const content: GridRowsProp = listMapping("key", zusList.rows, { t });
  const cellButton = (params: GridRenderCellParams) => {
    const props = {
      params,
      configTime: configTime,
    };

    return <RenderDialog {...props} />;
  };
  //withdraw_checking_time
  const columns: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    // useGenGridCol("threshold_amount", t("approval_threshold_amount")),
    useGenGridCol(
      "hourly_auto_approval_limit",
      t("max_hourly_approve_limit", configTime),
      {
        minWidth: 20,
      }
    ),
    //new added fields
    useGenGridCol("mx_hourly_limit", t("mx_hourly_limit", configTime), {
      minWidth: 20,
    }),
    useGenGridCol("mx_addr_hrly_limit", t("mx_addr_hrly_limit", configTime), {
      minWidth: 20,
    }),
    useGenGridCol("mx_addr_hrly_count", t("mx_addr_hrly_count", configTime), {
      minWidth: 20,
    }),
    useGenGridCol("edit_record", t("operation"), {
      minWidth: 100,
      renderCell: cellButton,
    }),
  ].filter((col) => {
    if (col.field === "edit_record") {
      return hasPermission(Pkey.EditRecord);
    }
    return true;
  });
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={zusList.count}
        onPageChange={zusList.setPage}
        page={zusList.meta.page}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
};

export default React.memo(WithdrawApprovalSettingList);
