import { useEffect } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import { getNoncePriorityTab } from "../../../api/signer";
import { OpDelete } from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import { useChains } from "../../../components/FetchConfig";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box, Tooltip } from "../../../components/MuiGenerals";
import OpIconButton, { sxOpTextButton } from "../../../components/OpIconButton";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import { dataGridDefaults, FeatureCodes } from "../../../utils/constant";
import { copyToClipboard } from "../../../utils/helper";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./PriorityListTab";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "NonceTool";
const Pkey = FeatureCodes.tools.NonceTool.TabPriorityList;
export default function PriorityListTabList(props: {
  isSearched: boolean;
  listMapping: (
    which: "key" | "name",
    chain_name: string | undefined,
    array: any[],
    config?: any
  ) => any[][];
}) {
  const { hasPermission } = usePermission();
  const { t, te } = useTranslation(translatePrefix);
  const { email } = useAppSelector(selectProfile);
  const { alerting } = useAlerting();
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping, isSearched } = props;
  const listRes = useQuery({
    queryKey: [
      "portalUserProposals",
      zusParams.body,
      zusParams.refetchCounter,
      isSearched,
    ],
    queryFn: () =>
      isSearched ? getNoncePriorityTab(zusParams.body) : undefined,
  });
  const { selectChainByPieces } = useChains();
  // const listRes = {
  //   data: { rows: [{ id: "", status: "" }], count: -1 },
  //   refetch: () => {},
  // };
  const {
    chain_type,
    chain_id,
    rows,
    count = 0,
  } = (listRes.data as any as {
    chain_type: number;
    chain_id: number;
    rows: any[];
    count: number;
  }) || {};

  const { name } = selectChainByPieces({ chain_type, chain_id });

  const content: GridRowsProp = listMapping("key", name, rows, {
    t,
    te,
  });

  const DeleteButton = (params: GridRenderCellParams) => {
    const ripeData = { ...params.row, chain_type, chain_id };
    const isUnused = ripeData.status === te("Unused");
    return (
      <Box sx={{ ...sxOpTextButton, marginTop: "0.2rem" }}>
        <OpIconButton
          title={t("delete")}
          url={OpDelete}
          isDisabled={!isUnused}
          onClick={() => zusDialog.open("deleteDialog", { ripeData })}
        />
      </Box>
    );
  };

  const AddressCell = (params: GridRenderCellParams) => {
    const translate = useTranslate();
    const addressOnClick = (wallet_address: string) => {
      copyToClipboard(wallet_address);
      alerting("success", translate("report.copied"));
    };
    return (
      <Tooltip
        title={params.row.sending_address}
        onClick={() => addressOnClick(params.row.sending_address)}
      >
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            color: "#4A90F7",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {params.row.sending_address}
        </div>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("sending_address", t("sending_address"), {
      renderCell: AddressCell,
    }),
    useGenGridCol("nonce", t("nonce")),
    useGenGridCol("status", t("status")),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("last_modified_time", t("last_modified_time")),
    useGenGridCol("1", t("operation"), { renderCell: DeleteButton }),
  ].filter((col) => {
    if (col.field === "1" && !hasPermission(Pkey.Delete)) {
      return false;
    }
    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("priority")
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {isSearched ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const sx = {
  DialogContent: {
    padding: "2rem",
    paddingLeft: 0,
    "& .MuiBox-root": { display: "flex", marginBottom: "0.2rem" },
    "& .MuiBox-root span": { flex: 1 },
  },
};
