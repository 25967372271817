import { useTranslate } from "react-admin";
import { Button, Tooltip } from "../../../../components/MuiGenerals";

interface ResubmitButtonProps {
  onClick: () => void;
  isDisabled?: boolean;
  width?: string;
  height?: string;
}

const ResubmitButton = (props: ResubmitButtonProps) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`resubmit.${key}`);
  const { onClick, isDisabled = false, width = "60%", height = "70%" } = props;

  return (
    <Tooltip title={t("resubmitBtn")}>
      <span style={{ width, height }}>
        <Button
          sx={{
            borderRadius: "18px",
            backgroundColor: "#4a90f7",
            width: "100%",
            height: "100%",
          }}
          variant="contained"
          onClick={onClick}
          disabled={isDisabled}
        >
          {t("resubmitBtn")}
        </Button>
      </span>
    </Tooltip>
  );
};

export default ResubmitButton;
