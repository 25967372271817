import React from "react";
import { Box, Button } from "../MuiGenerals";
import AddIcon from "@mui/icons-material/Add";
import { useAlerting, usePermission, useTranslation } from "../../hooks";
import { containsOnlyNumbers } from "../../utils/helper";
import { useWithDrawApprovalSettingStore } from "../../zustand/store";
import style from "./regulationDialogCss.module.css";
import MpTextField from "../MpTextField";
import { FeatureCodes } from "../../utils/constant";
const translatePrefix = "withdrawApprovalSetting";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabSetting;
const sx = {
  table: {
    backgroundColor: "#EAEAEA",
    p: 1,
    my: 1,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "> span:first-of-type": {
      flex: 1,
      display: "flex",
      "& span": {
        flex: 1,
      },
      "& span:first-of-type": {
        marginRight: "3.5rem",
      },
    },
    "& Button": {
      color: "black",
      backgroundColor: "white",
    },
  },
  tableContent: {
    my: 2,
    maxHeight: "auto",
  },
  tableRow: {
    columnGap: "1rem",
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-root": { flex: 1 },
    my: 1,
  },
};
interface Items {
  seq: number;
  withdraw_amt: string;
  settle_amt: string;
}
const ApproveRegulationEditOperation = () => {
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const regulations =
    useStore.recordForEdit.rawData.withdraw_approval_regulations;

  const { hasPermission } = usePermission();
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const currentId = regulations!
    .map((item) => item.seq)
    .sort()
    .slice(-1)[0];

  //{- -Handle Functions - -}
  const handleAddItems = () => {
    if (regulations!.length === 0) {
      const firstRegulationArr = [{ seq: 0, withdraw_amt: "", settle_amt: "" }];
      useStore.setUpdateRecordForEdit({
        editFields: {
          field: "withdraw_approval_regulations",
          value: firstRegulationArr,
        },
      });
      return;
    }
    if (regulations!.length === 4) {
      alerting("warning", t("maximumOfApprovers"));
      return;
    }
    //{- -default Item Format with s only - -}
    const newItem: Items[] = [
      { seq: currentId + 1, withdraw_amt: "", settle_amt: "" },
    ];
    const sumOfRegulationsArr = [...regulations!, ...newItem];

    useStore.setUpdateRecordForEdit({
      editFields: {
        field: "withdraw_approval_regulations",
        value: sumOfRegulationsArr,
      },
    });
  };
  //{- -Delete Items  - - }
  const handleDeleteItems = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { id } = e.target as HTMLInputElement;
    if (Number(id) < Number(currentId)) {
      return alerting("warning", t("onlyAllowDeleteTheLargesNo"));
    }
    const remainingRegulationItems = regulations!.filter(
      (item) => item.seq !== Number(id)
    );

    useStore.setUpdateRecordForEdit({
      editFields: {
        field: "withdraw_approval_regulations",
        value: remainingRegulationItems,
      },
    });
  };
  //{- - Handle Onchange Input Value- -}
  const handleInputAmountEvent = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value, id, name } = e.target;

    const resp = containsOnlyNumbers(String(value));
    if (!resp) return;
    const regulationArrAfterUpdated = regulations!.map((item) => {
      //{- -avoid nested object refer to old obj --}
      let newObj = { ...item };
      if (newObj.seq === Number(id)) {
        // newObj[`${name}`] = value;
        newObj = { ...newObj, [name]: value };
      }
      return newObj;
    });

    useStore.setUpdateRecordForEdit({
      editFields: {
        field: "withdraw_approval_regulations",
        value: regulationArrAfterUpdated,
      },
    });
  };

  return (
    <>
      <Box sx={sx.tableHeader}>
        <span>
          <span style={{ fontWeight: "bold" }}>{t("regulation")}</span>
        </span>
        {hasPermission(Pkey.EditRecord) && (
          <Button
            onClick={handleAddItems}
            startIcon={<AddIcon />}
            sx={{ fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"] }}
          >
            {t("add_item")}
          </Button>
        )}
      </Box>
      <Box sx={sx.tableContent}>
        <section className={style.gridHeaderContainer}>
          <div>{t("noOfApprover")}</div>
          <div> {t("type")}</div>
          <div>{t("amountIslargerThan")}</div>
        </section>
      </Box>
      {/* Regulation */}
      {regulations!.map((item, index) => (
        <div key={index} style={{ display: "flex" }}>
          <Box sx={sx.table}>
            <Box>
              <section className={style.gridSection}>
                <div
                  style={{
                    gridColumn: 1 / 3,
                    gridRow: "span 3",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                >
                  {item.seq + 1}
                </div>
                <div className={style.type}>{t("withdraw")}</div>
                <MpTextField
                  name={"withdraw_amt"}
                  id={String(item.seq)}
                  // value={""}
                  value={item.withdraw_amt ? item.withdraw_amt : ""}
                  sx={{
                    backgroundColor: "white",
                    minWidth: "200px",
                    gridColumn: "3/5",
                  }}
                  onChange={handleInputAmountEvent}
                  disabled={!hasPermission(Pkey.EditRecord)}
                />
                <div className={style.type}>{t("settlement")}</div>
                <MpTextField
                  name={"settle_amt"}
                  label={item.seq !== 0 && t("ph_settle")}
                  id={String(item.seq)}
                  sx={{
                    backgroundColor: "white",
                    minWidth: "200px",
                    gridColumn: "3/5",
                  }}
                  value={item.settle_amt ? item.settle_amt : ""}
                  onChange={handleInputAmountEvent}
                  disabled={!hasPermission(Pkey.EditRecord)}
                />
              </section>
            </Box>
          </Box>
          {item.seq !== 0 && (
            <div className={style.deleteBtn}>
              <Button
                disabled={!hasPermission(Pkey.EditRecord)}
                id={String(item.seq)}
                onClick={handleDeleteItems}
              >
                X
              </Button>
            </div>
          )}
        </div>
      ))}
    </>
    // </Box>
  );
};

export default ApproveRegulationEditOperation;
