import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import { useZusDialogStore } from "../../../zustand/store";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import MpTextField from "../../../components/MpTextField";
import { customSx } from "../../../utils/styling";
import { useChains } from "../../../components/FetchConfig";
import { getBtcSeriesChain } from "../../../utils/helper";
import { syncBtcUtxo, syncAdaUtxo } from "../../../api/merchant";
import { FeatureCodes } from "../../../utils/constant";
const Pkey = FeatureCodes.tools.UTXOTool;
const initStates = {
  walletAddress: "",
  chain_name: "",
};
const translatePrefix = "utxoManagement";
const Modal = (props: { AdaTab?: boolean }) => {
  const { AdaTab } = props;
  const [fields, setFields] = useState(initStates);
  const { chainObj, selectChainByPieces } = useChains();
  const btcSeriesChainObj = getBtcSeriesChain(chainObj, [
    "BTC",
    "BCH",
    "BCHT",
    "LTCT",
    "LTC",
    "DOGE",
    "DOGET",
  ]);
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { t, tc } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();

  const handleClose = () => {
    zusDialog.close();
    setFields(initStates);
  };
  const handleOnchangeEvent =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const handleConfirm = async () => {
    if (!fields.chain_name && !AdaTab && !fields.walletAddress) {
      return alerting("warning", t("syncAlert"));
    }
    if (!fields.chain_name && !AdaTab) {
      return alerting("warning", t("mustInputChainName"));
    }
    if (!fields.walletAddress) {
      return alerting("warning", t("ph_wallet_address"));
    }
    // return alerting("warning", t("alertIfNotHotWallet"));
    const params = {
      chain_id,
      chain_type,
      address: fields.walletAddress,
    };
    const payload = AdaTab ? { ...params, chain_type: 8, chain_id: 2 } : params;
    const api = AdaTab ? syncAdaUtxo : syncBtcUtxo;
    const res = await api(payload);
    if (res) {
      zusDialog.close();
      setFields(initStates);
      return alerting("success", t("syncSuccessful"));
    }
  };
  const handleOnSelectEvent = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };
  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "chain_name") {
      const output = key === fields.chain_name ? "" : key;
      setFields((pre) => ({ ...pre, chain_name: output }));
    }
  };
  return (
    <>
      <Dialog
        open={zusDialog.match("utxoDialog")}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>{t("sync_utxo")}</DialogTitle>

        <DialogContent>
          {!AdaTab && (
            <FormControl fullWidth={true}>
              <InputLabel id="select-label">{t("phChain_name")}</InputLabel>
              <Select
                name="chain_name"
                onChange={handleOnSelectEvent}
                defaultValue=""
                disabled={!hasPermission(Pkey.TabBTCSeries.SyncBTCUTXO)}
                value={fields.chain_name}
              >
                {Object.entries(btcSeriesChainObj).map(([key, { name }], i) => (
                  <MenuItem
                    key={key}
                    value={key}
                    onClick={(e) => handleClick(e, key, "chain_name")}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <MpTextField
            name={"walletAddress"}
            variant="filled"
            value={fields.walletAddress}
            fullWidth={true}
            label={t("wallet_address")}
            onChange={handleOnchangeEvent("walletAddress")}
          />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button color="secondary" variant="contained" onClick={handleConfirm}>
            {tc("confirm")}
          </Button>

          <Button variant="contained" color="error" onClick={handleClose}>
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modal;
