import {
  addDays,
  addHours,
  addMonths,
  addSeconds,
  addYears,
  isValid,
  subDays,
  subHours,
  subMonths,
  subSeconds,
  subYears,
} from "date-fns";
import { useMemo, useState } from "react";

import DateHourSelection from "../components/DateHourSelection";
import { Box } from "../components/MuiGenerals";
import {
  EnumHoursEnd24HrsFormatWithSec,
  EnumHoursStart24HrsFormatWithSec,
} from "../utils/constant";
import { toDBTime } from "../utils/helper";
import useTranslation from "./useTranslation";

type LimitIntervalType = "years" | "months" | "days" | "hours";

export default function useDateHourRangeSelection(
  config?: {
    defaultValues?: { start: Date | null; end: Date | null };
    limitInterval?: {
      type: LimitIntervalType;
      value: number;
      upperLimit?: Date | null;
    };
    isTimezoneConvert: boolean;
  } & (
    | { isDisabledFuture: true; includesUpcomingHr: boolean }
    | { isDisabledFuture: false }
  )
) {
  const DateFnsMethodByIntervalType =
    (type: LimitIntervalType, value: number) =>
    (direction: "subtract" | "add") => {
      const isSub = direction === "subtract";
      if (!type || !value) {
        return (date: Date) => date;
      }

      return (date: Date) =>
        ({
          years: isSub ? subYears : addYears,
          months: isSub ? subMonths : addMonths,
          days: isSub ? subDays : addDays,
          hours: isSub ? subHours : addHours,
        }[type](date, value));
    };

  const dateFnsMethodByConfigInterval =
    config?.limitInterval &&
    DateFnsMethodByIntervalType(
      config.limitInterval.type,
      config.limitInterval.value
    );

  type DateEditMinReturns<T, K> = T extends { isOnlyTimeStr: false } | undefined
    ? K extends Date
      ? Date
      : null
    : T extends { isOnlyTimeStr: true }
    ? K extends Date
      ? string
      : null
    : never;

  type DateEditConfig = { isOnlyTimeStr: boolean } | undefined;

  const dateEditMinSec = <T extends DateEditConfig, K extends Date | null>(
    date: K,
    minSecStr: ":00:00" | ":59:59",
    config?: T
  ): DateEditMinReturns<T, K> => {
    const { isOnlyTimeStr } = config || {};

    if (!date || !isValid(date)) {
      return null as DateEditMinReturns<T, K>;
    }

    const newDateTimeStr =
      date.toTimeString().split(" ")[0].slice(0, 2) + minSecStr;

    if (isOnlyTimeStr) {
      return newDateTimeStr as DateEditMinReturns<T, K>;
    }

    const dateStr = date.toDateString();

    const newDate = new Date(`${dateStr} ${newDateTimeStr}`);

    return newDate as DateEditMinReturns<T, K>;
  };

  const { t } = useTranslation("common");

  const current = new Date();

  const currentDateStr = current.toDateString();

  const currentPlus1HrTimeStr = dateEditMinSec(current, ":59:59", {
    isOnlyTimeStr: true,
  });

  const currentPlus1Hr = new Date(`${currentDateStr} ${currentPlus1HrTimeStr}`);

  const isDisabledFuture = config?.isDisabledFuture;

  const isTimezoneConvert = config?.isTimezoneConvert;

  const isUpcommingHrIncluded = isDisabledFuture && config?.includesUpcomingHr;

  const disableFutureDate = isDisabledFuture
    ? isUpcommingHrIncluded
      ? currentPlus1Hr
      : current
    : null;
  const disableFutureDateTimeStr = isDisabledFuture
    ? isUpcommingHrIncluded
      ? currentPlus1HrTimeStr
      : current.toTimeString().split(" ")[0]
    : null;

  const rawIntervalUpperLimit = config?.limitInterval?.upperLimit
    ? config.limitInterval.upperLimit
    : null;

  const rawIntervalUpperLimitDateStr =
    rawIntervalUpperLimit && rawIntervalUpperLimit.toDateString();

  const rawIntervalUpperLimitPlus1HrTimeStr =
    rawIntervalUpperLimit &&
    dateEditMinSec(rawIntervalUpperLimit, ":59:59", { isOnlyTimeStr: true });

  const intervalUpperLimitPlus1Hr =
    rawIntervalUpperLimit &&
    new Date(
      `${rawIntervalUpperLimitDateStr} ${rawIntervalUpperLimitPlus1HrTimeStr}`
    );

  const intervalUpperLimit = isUpcommingHrIncluded
    ? intervalUpperLimitPlus1Hr
    : rawIntervalUpperLimit;

  const intervalUpperLimitTimeStr = intervalUpperLimit
    ?.toTimeString()
    .split(" ")[0];

  const intervalUpperLimit0Min0SecTimeStr =
    intervalUpperLimit &&
    dateEditMinSec(intervalUpperLimit, ":00:00", { isOnlyTimeStr: true });

  const intervalUpperLimit0Min0Sec =
    rawIntervalUpperLimit &&
    new Date(
      `${rawIntervalUpperLimitDateStr} ${intervalUpperLimit0Min0SecTimeStr}`
    );

  const [startDate, setStartDate] = useState<Date | null>(
    config?.defaultValues?.start &&
      isValid(new Date(config.defaultValues.start.toDateString()))
      ? new Date(config.defaultValues.start.toDateString())
      : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    config?.defaultValues?.end &&
      isValid(new Date(config.defaultValues.end.toDateString()))
      ? new Date(config.defaultValues.end.toDateString())
      : null
  );

  const [startDateHr, setStartDateHr] = useState<string>(
    (config?.defaultValues?.start &&
      toDBTime(config.defaultValues.start, isTimezoneConvert)) ||
      ""
  );
  const [endDateHr, setEndDateHr] = useState<string>(
    (config?.defaultValues?.end &&
      toDBTime(config.defaultValues.end, isTimezoneConvert)) ||
      ""
  );

  const startDateStr = startDate?.toDateString();
  const endDateStr = endDate?.toDateString();

  const [startTime, setStartTime] = useState<string>(
    config?.defaultValues?.start &&
      isValid(new Date(config.defaultValues.start))
      ? config.defaultValues.start.toTimeString().split(" ")[0]
      : ""
  );
  const [endTime, setEndTime] = useState<string>(
    config?.defaultValues?.end && isValid(new Date(config.defaultValues.end))
      ? config.defaultValues.end.toTimeString().split(" ")[0]
      : ""
  );

  const isValidStartDateTime = isValid(
    new Date(`${startDateStr} ${startTime}`)
  );
  const isValidEndDateTime = isValid(new Date(`${endDateStr} ${endTime}`));

  const isSelectingStartDateEqSelectedLowerLimit =
    dateFnsMethodByConfigInterval &&
    endDateStr &&
    endTime &&
    dateFnsMethodByConfigInterval("subtract")(
      new Date(`${endDateStr} ${endTime}`)
    ).toDateString() === startDateStr;

  const isSelectingStartDateEqConfigUpperLimit =
    dateFnsMethodByConfigInterval &&
    intervalUpperLimit &&
    dateFnsMethodByConfigInterval("subtract")(
      intervalUpperLimit
    ).toDateString() === startDateStr;

  const isSelectingStartDateEqDisableFutureDate =
    dateFnsMethodByConfigInterval &&
    disableFutureDate &&
    dateFnsMethodByConfigInterval("subtract")(
      disableFutureDate
    ).toDateString() === startDateStr;

  const startTimeEmptyDefaultValue = isSelectingStartDateEqConfigUpperLimit
    ? addSeconds(intervalUpperLimit, 1).toTimeString().split(" ")[0]
    : isSelectingStartDateEqDisableFutureDate
    ? addSeconds(disableFutureDate, 1).toTimeString().split(" ")[0]
    : isSelectingStartDateEqSelectedLowerLimit
    ? addSeconds(new Date(`${endDateStr} ${endTime}`), 1)
        .toTimeString()
        .split(" ")[0]
    : "00:00:00";

  const isSelectingEndDateEqConfigUpperLimit =
    intervalUpperLimit && intervalUpperLimit.toDateString() === endDateStr;

  const isSelectingEndDateEqDisableFutureDate =
    disableFutureDate && disableFutureDate.toDateString() === endDateStr;

  const isSelectingEndDateEqSelectedUpperLimit =
    dateFnsMethodByConfigInterval &&
    startDateStr &&
    startTime &&
    dateFnsMethodByConfigInterval("add")(
      new Date(`${startDateStr} ${startTime}`)
    ).toDateString() === endDateStr;

  const endTimeEmptyDefaultValue = isSelectingEndDateEqConfigUpperLimit
    ? intervalUpperLimitTimeStr
    : isSelectingEndDateEqDisableFutureDate
    ? disableFutureDateTimeStr
    : isSelectingEndDateEqSelectedUpperLimit
    ? subSeconds(new Date(`${startDateStr} ${startTime}`), 1)
        .toTimeString()
        .split(" ")[0]
    : "23:59:59";

  const selectedStartDateTime =
    isValidStartDateTime && startTime
      ? new Date(`${startDateStr} ${startTime}`)
      : isValidStartDateTime
      ? new Date(`${startDateStr} ${startTimeEmptyDefaultValue}`)
      : null;

  const selectedEndDateTime =
    isValidEndDateTime && endTime
      ? new Date(`${endDateStr} ${endTime}`)
      : isValidEndDateTime
      ? new Date(`${endDateStr} ${endTimeEmptyDefaultValue}`)
      : null;

  const disabledHoursListStartDate = Object.entries(
    EnumHoursStart24HrsFormatWithSec
  )
    .filter(([_name, value]) => {
      const dateHour = new Date(`${startDateStr} ${value}`);
      const validateDate = isValid(dateHour) ? dateHour : null;
      if (!validateDate) {
        return false;
      }

      const isTargetLargerThanDisableFutureDate =
        disableFutureDate &&
        validateDate.getTime() >
          dateEditMinSec(disableFutureDate, ":00:00").getTime();

      const isTargetLargerThanConfigUpperLimit =
        intervalUpperLimit0Min0Sec &&
        validateDate.getTime() > intervalUpperLimit0Min0Sec.getTime();

      const isTargetSmallerOrEqToConfigUpperLimitMinusInterval =
        dateFnsMethodByConfigInterval &&
        intervalUpperLimit0Min0Sec &&
        validateDate.getTime() <=
          dateFnsMethodByConfigInterval("subtract")(
            intervalUpperLimit0Min0Sec
          ).getTime();

      const isTargetLargerThanSelectedEndDate =
        selectedEndDateTime &&
        validateDate.getTime() > selectedEndDateTime.getTime();

      const isTargetSmallerThanSelectedEndDateMinusInterval =
        dateFnsMethodByConfigInterval &&
        selectedEndDateTime &&
        validateDate.getTime() <
          dateFnsMethodByConfigInterval("subtract")(
            addSeconds(selectedEndDateTime, 1)
          ).getTime();

      return (
        isTargetLargerThanDisableFutureDate ||
        isTargetLargerThanConfigUpperLimit ||
        isTargetSmallerOrEqToConfigUpperLimitMinusInterval ||
        isTargetLargerThanSelectedEndDate ||
        isTargetSmallerThanSelectedEndDateMinusInterval
      );
    })
    .map(([name]) => name);

  const disabledHoursListEndDate = Object.entries(
    EnumHoursEnd24HrsFormatWithSec
  )
    .filter(([_name, value]) => {
      const dateHour = new Date(`${endDateStr} ${value}`);
      const validateDate = isValid(dateHour) ? dateHour : null;
      if (!validateDate) {
        return false;
      }

      const isTargetSmallerOrEqToConfigUpperLimitMinusInterval =
        dateFnsMethodByConfigInterval &&
        intervalUpperLimit &&
        validateDate.getTime() <=
          dateFnsMethodByConfigInterval("subtract")(
            intervalUpperLimit
          ).getTime();

      const isTargetLargerThanDisableFutureDate =
        disableFutureDate &&
        validateDate.getTime() > disableFutureDate.getTime();

      const isTargetLargerThanConfigUpperLimit =
        intervalUpperLimit &&
        validateDate.getTime() > intervalUpperLimit.getTime();

      const isTargetLargerThanSelectedStartDatePlusInterval =
        dateFnsMethodByConfigInterval &&
        selectedStartDateTime &&
        validateDate.getTime() >
          dateFnsMethodByConfigInterval("add")(
            subSeconds(selectedStartDateTime, 1)
          ).getTime();

      const isTargetSmallerThanSelectedStartDate =
        selectedStartDateTime &&
        validateDate.getTime() < selectedStartDateTime.getTime();

      return (
        isTargetSmallerOrEqToConfigUpperLimitMinusInterval ||
        isTargetLargerThanDisableFutureDate ||
        isTargetLargerThanConfigUpperLimit ||
        isTargetLargerThanSelectedStartDatePlusInterval ||
        isTargetSmallerThanSelectedStartDate
      );
    })
    .map(([name]) => name);

  const limitedIntervalMinStartDate =
    dateFnsMethodByConfigInterval && intervalUpperLimit0Min0Sec
      ? dateFnsMethodByConfigInterval("subtract")(intervalUpperLimit0Min0Sec)
      : dateFnsMethodByConfigInterval && selectedEndDateTime
      ? dateFnsMethodByConfigInterval("subtract")(
          addSeconds(selectedEndDateTime, 1)
        )
      : null;

  const limitedIntervalMinEndDate =
    config?.limitInterval && selectedStartDateTime
      ? selectedStartDateTime
      : dateFnsMethodByConfigInterval && intervalUpperLimit0Min0Sec
      ? dateFnsMethodByConfigInterval("subtract")(intervalUpperLimit0Min0Sec)
      : null;

  const maxStartDate =
    selectedEndDateTime ||
    intervalUpperLimit0Min0Sec ||
    disableFutureDate ||
    undefined;

  const startDateAddedInterval =
    selectedStartDateTime &&
    dateFnsMethodByConfigInterval &&
    subSeconds(dateFnsMethodByConfigInterval("add")(selectedStartDateTime), 1);

  const isStartDateAddedIntervalSmallerThenUpperLimitConfig =
    startDateAddedInterval &&
    intervalUpperLimit &&
    startDateAddedInterval.getTime() < intervalUpperLimit.getTime();

  const isStartDateAddedIntervalSmallerThenDisableFutureDate =
    startDateAddedInterval &&
    disableFutureDate &&
    startDateAddedInterval.getTime() < disableFutureDate.getTime();

  const maxEndDateHavingStartDate =
    (startDateAddedInterval && !intervalUpperLimit && !isDisabledFuture) ||
    isStartDateAddedIntervalSmallerThenUpperLimitConfig ||
    isStartDateAddedIntervalSmallerThenDisableFutureDate
      ? startDateAddedInterval
      : null;

  const maxEndDate =
    maxEndDateHavingStartDate ||
    intervalUpperLimit ||
    disableFutureDate ||
    undefined;

  const hyphenStyle = {
    verticalAlign: "bottom",
    fontSize: "2rem",
    margin: "0 10px",
  };

  const DateHrRangeSelection = useMemo(
    () => () =>
      (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            ">div": {
              width: "50%",
            },
          }}
        >
          <DateHourSelection
            customDateLabel={t("date_hr_start_date")}
            dateValue={startDate}
            maxStartTime={maxStartDate}
            minEndTime={limitedIntervalMinStartDate || undefined}
            setDateValue={(value) => setStartDate(value)}
            timeValue={startTime}
            setTimeValue={(value) => setStartTime(value)}
            setDateHour={(value) => setStartDateHr(value)}
            clearTimeValue={() => setStartTime("")}
            disabledHoursList={disabledHoursListStartDate}
            isTimezoneConvert
            hourEnum={EnumHoursStart24HrsFormatWithSec}
            sx={{
              datePicker: {
                width:
                  "calc((100% - 24.046999999999997px) / 2 + 8px)!important",
                marginRight: 0,
                "& .MuiInputBase-input": {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                "& .MuiFormLabel-root": { maxWidth: "calc(100% - -20px)" },
              },
              hourPicker: {
                formControl: {
                  width:
                    "calc((100% - 17.046999999999997px) / 2 + 8px)!important",

                  "& .MuiSelect-select": {
                    paddingRight: "8px !important",
                    paddingLeft: "8px !important",
                  },
                },
              },
            }}
          />
          <span style={hyphenStyle}> - </span>

          <DateHourSelection
            customDateLabel={t("date_hr_end_date")}
            dateValue={endDate}
            maxStartTime={maxEndDate}
            minEndTime={limitedIntervalMinEndDate || undefined}
            setDateValue={(value) => setEndDate(value)}
            timeValue={endTime}
            setTimeValue={(value) => setEndTime(value)}
            setDateHour={(value) => setEndDateHr(value)}
            clearTimeValue={() => setEndTime("")}
            disabledHoursList={disabledHoursListEndDate}
            isTimezoneConvert={isTimezoneConvert}
            isEndOfSecond
            // hourEnum={EnumHoursEnd24HrsFormat}
            hourEnum={EnumHoursEnd24HrsFormatWithSec}
            sx={{
              datePicker: {
                width:
                  "calc((100% - 24.046999999999997px) / 2 + 8px)!important",
                marginRight: 0,
                "& .MuiInputBase-input": {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
                "& .MuiFormLabel-root": { maxWidth: "calc(100% - -20px)" },
              },
              hourPicker: {
                formControl: {
                  width:
                    "calc((100% - 17.046999999999997px) / 2 + 8px)!important",
                  "& .MuiSelect-select": {
                    paddingRight: "8px !important",
                    paddingLeft: "8px !important",
                  },
                },
              },
            }}
          />
        </Box>
      ),
    [startDate, startTime, endDate, endTime]
  );

  const resetDateHr = () => {
    setStartDate(null);
    setStartTime("");
    setEndDate(null);
    setEndTime("");
    setStartDateHr("");
    setEndDateHr("");
  };

  return {
    DateHrRangeSelection,
    resetDateHr,
    dateHrStartValue: startDateHr,
    dateHrEndValue: endDateHr,
  };
}
