import Tooltip from "@mui/material/Tooltip";

import { customSx } from "../utils/styling";

export default function ToolText(props: {
  tooltip: string;
  content?: React.ReactElement;
  width: string;
}) {
  const displayContent = props.content || props.tooltip;
  return (
    <Tooltip title={props.tooltip}>
      <span
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: props.width,
        }}
      >
        {displayContent}
      </span>
    </Tooltip>
  );
}
export function ToolCryptoText(props: {
  tooltip: string;
  content?: React.ReactElement;
  width?: string;
}) {
  return (
    <ToolText
      tooltip={props.tooltip}
      content={props.content}
      width={props.width || customSx.cryptoAmount.width}
    />
  );
}
