import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { customSx } from "../../utils/styling";
import { useChains } from "../FetchConfig";
import { alterWithdrawRequestApprovalSetting } from "../../api/signer";
import { useAppSelector } from "../../reducer/hooks";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
import {
  toDBInDecimals,
  containsOnlyNumbers,
  findDecimalByChainNameAndAsset,
  bigNumberCompare,
  duplicationGuard,
  nextAmtGreaterThanPreviousGuard,
  getAmtsArr,
  checkIfAmtContainsEmptyString,
  onlyAllowInteger,
  checkIfPreviousAmtEmptyFunc,
} from "../../utils/helper";
import { selectProfile } from "../../reducer/profileSlice";
import ApproveRegulationEditOperation from "./ApproveRegulationEditOperation";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../hooks";
import { FeatureCodes } from "../../utils/constant";
import MpTextField from "../MpTextField";
import YubiPrompter from "../Prompter";
import GeneralBtn from "../GeneralBtn";
const translatePrefix = "withdrawApprovalSetting";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabSetting;
const WithDrawApprovalSettingDialog = () => {
  //{- - translation- -}
  const { t } = useTranslation(translatePrefix);
  //{- - Redux Stuff - -}
  const { email } = useAppSelector(selectProfile);
  const { chainObj, selectChainByPieces } = useChains();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  //{- - Zustand- - }
  const zusDialog = useZusDialogStore();
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const recordForEdit = useStore.recordForEdit;
  const zusRawData = recordForEdit.rawData;
  const regulations = recordForEdit.rawData.withdraw_approval_regulations;
  const {
    // currentThreshold,
    curr_hourly_auto_approval_limit,
    currentRegulation,
    curr_mx_hourly_limit,
    curr_mx_addr_hrly_count,
    curr_mx_addr_hrly_limit,
  } = useStore.currentValues;

  //{- - - -Get chain_id, chain_type - - - -}
  const chain_name = recordForEdit.chain_name
    ? Object.entries(chainObj).filter(
        ([assetKey, info]) => recordForEdit.chain_name == info.name
      )[0][0]
    : "";
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: chain_name,
  });
  //{- - find_decimal- -}
  const decimals = findDecimalByChainNameAndAsset(
    String(chain_type),
    String(chain_id),
    String(recordForEdit.asset_name)
  );

  const getProperAmtWithDecimal = (inputValue = "") => {
    return inputValue ? toDBInDecimals(inputValue, decimals) : "";
  };
  //{- - Convert Regulation With decimal- -}
  const withdrawApprovalRegulations = regulations
    .map((item) => {
      const newObj = { ...item };
      let arr = [];
      if (item.withdraw_amt) {
        arr.push(
          Object.assign(
            {},
            {
              seq: item.seq,
              amount: getProperAmtWithDecimal(newObj.withdraw_amt),
            }
          )
        );
      }
      if (item.settle_amt) {
        arr.push(
          Object.assign(
            {},
            {
              seq: item.seq,
              amount: getProperAmtWithDecimal(newObj.settle_amt),
              wallet_type: "5",
            }
          )
        );
      }
      return arr;
    })
    .flat();
  // {- - - - Params for send to backend- - - - -}

  const params = {
    chain_type: chain_type!,
    chain_id: chain_id!,
    asset_name: recordForEdit.asset_name,
    // amount: getProperAmtWithDecimal(zusRawData.threshold_amount),
    hourly_auto_approval_limit: getProperAmtWithDecimal(
      zusRawData.hourly_auto_approval_limit
    ),
    email,
    withdrawApprovalRegulations: withdrawApprovalRegulations,
    to_addr_hourly_limit: getProperAmtWithDecimal(
      zusRawData.mx_addr_hrly_limit
    ),
    to_addr_hourly_count: zusRawData.mx_addr_hrly_count,
    hourly_limit: getProperAmtWithDecimal(zusRawData.mx_hourly_limit),
  };

  //{- - - -handle confirm - - - -}
  const handleConfirm = async () => {
    const {
      // threshold_amount, //updatedThresholdAmt
      hourly_auto_approval_limit, //updateHrly_auto_approve_limitAmt
      mx_hourly_limit, //update_mx_hourly_limit
      mx_addr_hrly_count, //update_mx_addr_hrly_count
      mx_addr_hrly_limit, //update_mx_addr_hrly_limit
    } = zusRawData;

    const edit_withdraw_amt_arr = getAmtsArr(regulations, "withdraw_amt");
    const edit_settle_amt_arr = getAmtsArr(regulations, "settle_amt");

    const exist_nonRegulationSetting = [
      {
        // threshold_amount: currentThreshold,
        hourly_auto_approval_limit: curr_hourly_auto_approval_limit,
        mx_hourly_limit: curr_mx_hourly_limit,
        mx_addr_hrly_limit: curr_mx_addr_hrly_limit,
        mx_addr_hrly_count: curr_mx_addr_hrly_count,
      },
    ];
    const edit_nonRegulationSetting = [
      {
        // threshold_amount: threshold_amount,
        hourly_auto_approval_limit: hourly_auto_approval_limit,
        mx_hourly_limit: mx_hourly_limit,
        mx_addr_hrly_count: mx_addr_hrly_count,
        mx_addr_hrly_limit: mx_addr_hrly_limit,
      },
    ];

    const hasValueNotChanged = compareTwoArrays(
      [...exist_nonRegulationSetting, ...currentRegulation],
      [...edit_nonRegulationSetting, ...regulations]
    );

    //{- - Checking Begins- -}
    //Threshold_amt cannot be empty
    if (!regulations[0].withdraw_amt!) {
      return alerting("warning", t("pleaseEnterWithDrawAmt"));
    }
    if (!regulations[0].settle_amt!) {
      return alerting("warning", t("pleaseEnterSettleMentAmt"));
    }

    //-1: smaller than, 0: equal, 1: greater than
    if (
      hourly_auto_approval_limit &&
      bigNumberCompare(
        hourly_auto_approval_limit,
        regulations[0].withdraw_amt!
      ) === -1
    ) {
      return alerting(
        "warning",
        t("hourlyLimistMustBeGreaterThanAmtInRegulation", useStore.configTime)
      );
    }
    //取款少于或等于多少自动审批的值
    //{- -Compare and see if any vlaue has been changed among all settings
    if (hasValueNotChanged) {
      alerting("warning", t("amountDidntChange"));
      return;
    }
    //{- -Checking if regulation has length- - }
    if (regulations.length > 0) {
      const withdrawAmtRes = duplicationGuard(edit_withdraw_amt_arr);
      if (withdrawAmtRes) {
        return alerting("warning", t("withdrawAmtMustBeGreater"));
      }

      const settleAmtRes = duplicationGuard(edit_settle_amt_arr);
      if (settleAmtRes) {
        return alerting("warning", t("settleAmtMustBeGreater"));
      }
      const isPreviousWithdrawEmpty = checkIfPreviousAmtEmptyFunc(
        edit_withdraw_amt_arr
      );
      const isPreviousSettleEmpty =
        checkIfPreviousAmtEmptyFunc(edit_settle_amt_arr);
      if (isPreviousWithdrawEmpty) {
        return alerting("warning", t("previousWithdrawLimitIsEmpty"));
      }
      if (isPreviousSettleEmpty) {
        return alerting("warning", t("previousSettleLimitIsEmpty"));
      }

      const hasEmptyStrWithdraw = checkIfAmtContainsEmptyString(
        edit_withdraw_amt_arr,
        "cannotBeEmptyToken"
      );

      if (hasEmptyStrWithdraw)
        return alerting("warning", t("pleaseEnterWithDrawAmt"));
      const hasEmptyStrSettle = checkIfAmtContainsEmptyString(
        edit_settle_amt_arr,
        "optionalToken"
      );
      if (hasEmptyStrSettle)
        return alerting("warning", t("pleaseEnterSettleMentAmt"));

      //{- -checking and compare if each regulationAmt isn't greater than previous one - -}

      const withdrawAmtgreaterThanRes = nextAmtGreaterThanPreviousGuard(
        edit_withdraw_amt_arr
      );

      if (!withdrawAmtgreaterThanRes) {
        return alerting("warning", t("withdrawAmtMustBeGreater"));
      }

      const settleAmtgreaterThanRes =
        nextAmtGreaterThanPreviousGuard(edit_settle_amt_arr);
      if (!settleAmtgreaterThanRes) {
        return alerting("warning", t("settleAmtMustBeGreater"));
      }
    }
    // {- -Api Call -- }

    const resp = await getResAfterYubi(
      alterWithdrawRequestApprovalSetting,
      params
    );

    if (resp) {
      alerting("success", t("edit_request_created"));
      zusDialog.close();
    }
  };
  //{- - - -Handle update states - - - -}
  const handleOnchangeEvent = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const resp =
      name == "mx_addr_hrly_count"
        ? onlyAllowInteger(value)
        : containsOnlyNumbers(value);

    if (!resp) return;
    useStore.setUpdateRecordForEdit({
      editFields: {
        field: name,
        value: value,
      },
    });
  };

  const handleCancel = () => {
    zusDialog.close();
  };
  const canEdit = hasPermission(Pkey.EditRecord);

  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.match("operationDialog")}
        onClose={handleCancel}
        fullWidth={true}
      >
        <DialogTitle>{t("edit_record")}</DialogTitle>
        <DialogContent>
          <MpTextField
            variant="filled"
            defaultValue={recordForEdit.chain_name}
            fullWidth={true}
            label={t("chain_name")}
            disabled={true}
          />
          <br />
          <MpTextField
            variant="filled"
            defaultValue={recordForEdit.asset_name}
            fullWidth={true}
            label={t("chain_asset")}
            disabled={true}
          />

          <br />
          <MpTextField
            name={"hourly_auto_approval_limit"}
            variant="filled"
            value={
              recordForEdit.rawData.hourly_auto_approval_limit
                ? recordForEdit.rawData.hourly_auto_approval_limit
                : ""
            }
            fullWidth={true}
            label={t("phAutoApproval_limit", recordForEdit.configTime)}
            disabled={!canEdit}
            onChange={handleOnchangeEvent}
          />
          {/*  */}
          <br />
          <MpTextField
            name={"mx_hourly_limit"}
            variant="filled"
            value={
              recordForEdit.rawData.mx_hourly_limit
                ? recordForEdit.rawData.mx_hourly_limit
                : ""
            }
            fullWidth={true}
            label={t("phMx_hourly_limit", recordForEdit.configTime)}
            disabled={!canEdit}
            onChange={handleOnchangeEvent}
          />

          <br />
          <MpTextField
            name={"mx_addr_hrly_limit"}
            variant="filled"
            value={
              recordForEdit.rawData.mx_addr_hrly_limit
                ? recordForEdit.rawData.mx_addr_hrly_limit
                : ""
            }
            fullWidth={true}
            label={t("phMx_addr_hrly_limit", recordForEdit.configTime)}
            disabled={!canEdit}
            onChange={handleOnchangeEvent}
          />

          <MpTextField
            name={"mx_addr_hrly_count"}
            variant="filled"
            value={
              recordForEdit.rawData.mx_addr_hrly_count
                ? recordForEdit.rawData.mx_addr_hrly_count
                : ""
            }
            fullWidth={true}
            label={t("phMx_addr_hrly_count", recordForEdit.configTime)}
            disabled={!canEdit}
            onChange={handleOnchangeEvent}
          />
          <br />

          <div
            style={{
              fontSize: "0.9rem",
              color: "rgb(133,133,133)",
            }}
          >
            {t("limit_inputBox_description")}
          </div>

          <ApproveRegulationEditOperation />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          {canEdit && <GeneralBtn label="confirm" onClick={handleConfirm} />}
          <GeneralBtn label="cancel" color="error" onClick={handleCancel} />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(WithDrawApprovalSettingDialog);

export function compareTwoArrays(a: Array<any>, b: Array<any>) {
  if (a.length !== b.length) {
    return false;
  }
  const result = a.every((item: any, index: number) => {
    const hasValueNotChanged =
      item.threshold_amount === b[index].threshold_amount &&
      item.hourly_auto_approval_limit === b[index].hourly_auto_approval_limit &&
      item.mx_hourly_limit === b[index].mx_hourly_limit &&
      item.mx_addr_hrly_limit === b[index].mx_addr_hrly_limit &&
      item.mx_addr_hrly_count === b[index].mx_addr_hrly_count &&
      item.settle_amt === b[index].settle_amt &&
      item.withdraw_amt === b[index].withdraw_amt;
    return hasValueNotChanged;
  });
  return result;
}
