import { Box, Button } from "./MuiGenerals";
import Tooltip from "@mui/material/Tooltip";
import { sxOpTextButton } from "./OpTextButton";
import { ReactNode } from "react";
export { sxOpTextButton };
export default function OpIconButtonWrapped(p: {
  title: ReactNode;
  url: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onHover?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onMouseLeave?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  size?: string;
}) {
  const { isHidden = false, isDisabled = false, size = "2rem" } = p;
  const sx = {
    button: {
      padding: 0,
      minWidth: `${size}!important`,
      width: `${size}!important`,
      cursor: isDisabled ? "" : "pointer",
      margin: "0 0.5rem",
      "&:hover": {
        filter: isDisabled ? "brightness(100%)" : "brightness(130%)",
      },
      span: {
        width: "0",
      },
    },
    img: {
      filter: isDisabled ? "grayscale(100%)" : "grayscale(0)",
      width: "100%",
    },
  };
  if (isHidden) return null;
  return (
    <Tooltip title={p.title} sx={sx.button}>
      <Button
        onClick={p.isDisabled ? undefined : p.onClick}
        sx={sx.button}
        onMouseOver={p.onHover}
        onMouseLeave={p.onMouseLeave}
      >
        <img
          src={p.url}
          style={sx.img}
          // alt=""
        />
      </Button>
    </Tooltip>
  );
}
