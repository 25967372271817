import {
  InputAdornment,
  Container,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useTranslate } from "react-admin";
import SweepRequestList from "./SweepRequestList";
import { useState, useEffect } from "react";
import { useDatePicker } from "../../components/DatePicker";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  separateBatchStrings,
  sortItemsAlphabetically,
  toDisplayTime,
  listMappingTransform,
} from "../../utils/helper";
import {
  tableConfig,
  enumOperationBatchStatus,
  FeatureCodes,
} from "../../utils/constant";
import { listFace, getSweepRequest } from "../../api/merchant";
import { useAssets, useChains } from "../../components/FetchConfig";
import { customSx } from "../../utils/styling";
import { DivideLine, genField, useRefresh } from "../../utils/HelperComp";
import { useTabs, usePermission, useAlerting } from "../../hooks";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";
import { useZusDialogStore } from "../../zustand/store";
import { getMinerFeeCurrency } from "./helpers/getMinerFeeCurrency";
import { getMinerFee } from "./helpers/getMinerFee";

const Pkey = FeatureCodes.assetManagement.SweepRequestRecord;

interface fieldsFace {
  chain_name: string;
  statusArr: string[];
  asset_names: string[];
  batchIdsString: string;
}
const initFields = {
  chain_name: "",
  statusArr: [],
  asset_names: [],
  batchIdsString: "",
};

const defaultDates = {
  start: { defaultDate: null },
  end: { defaultDate: null },
};

export default function SweepRequestRecord() {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [shouldRefresh, refresh] = useRefresh();

  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          setPage={setPage}
          page={page}
          count={list.count}
          listMapping={listMapping}
          shouldRefresh={shouldRefresh}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <SweepRequestList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            refresh={refresh}
          />
        </Tabs>
      </Container>
    </Box>
  );
}
export function FilterBar(props: any) {
  const { setList, setPage, page, count, shouldRefresh } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const DateObj = useDatePicker(defaultDates);
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces } = useChains();
  const all_asset_names = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const { asset_names, statusArr, batchIdsString, chain_name, ...rest } =
      fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    const batch_ids = separateBatchStrings(batchIdsString);
    return {
      ...rest,
      batch_ids,
      operationStatuses: statusArr,
      asset_names,
      chain_id,
      chain_type,
      date_from: DateObj.start,
      date_to: DateObj.end,
      page: page,
      pageSize: tableConfig.pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getSweepRequest({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, shouldRefresh]);
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onReset = () => {
    setFields(initFields);
    DateObj.clearDate();
    setPage(0);
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getSweepRequest({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, translate, ["rawData"]);
    const config = {};
    downloadFiles(`Sweep Request Records`, res, config);
  };

  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const F = genField({ t }, [
    ["created_date", <DateObj.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={all_asset_names}
      />,
    ],
    [
      "status",
      <FormControl>
        <InputLabel>{t("phStatus")}</InputLabel>
        <Select
          name="statusArr"
          multiple
          value={fields.statusArr}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(
            Object.entries(enumOperationBatchStatus)
          ).map(([id, name]: any, i: string) => (
            <MenuItem key={i} value={id}>
              {translate(
                `enumConstants.${enumOperationBatchStatus[String(id)]}`
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "batch_id",
      <MpTextField
        label={t("phBatch_id")}
        value={fields.batchIdsString}
        onChange={onChange("batchIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.status}
        {F.chain_name}
        {F.asset_name}
        {F.batch_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  translate: any,
  omitKeys: string[] = []
): any[][] => {
  const t = (key: string) => translate(`operations.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const minerFeeDecimal = getDecimal({
      chain_type: item.batch.chain_type,
      chain_id: item.batch.chain_id,
      asset_name: item.batch.miner_fee_asset_name,
    });

    const toAmount = (amount: string | number, dec: string = decimal) =>
      displayAmount(amount, dec);

    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["gether_address", t("to_address"), item.gether_address],
      ["invoker_address", t("invoker_address"), item.invoker_address],
      [
        "status",
        t("status"),
        translate(
          `enumConstants.${enumOperationBatchStatus[item.batch.status]}`
        ),
      ],
      [
        "miner_fee_currency",
        t("miner_fee_currency"),
        getMinerFeeCurrency(item.batch.status, item.batch.miner_fee_asset_name),
      ],
      [
        "miner_fee",
        t("miner_fee"),
        getMinerFee(
          item.batch.status,
          toAmount(item?.batch?.miner_fee, minerFeeDecimal)
        ),
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["total_amount", t("totalAmount"), toAmount(item.total_amount)],
      ["actual_amount", t("actual_amount"), toAmount(item.batch.actual_amount)],
      ["total_count", t("total_count"), item.total_count],
      ["created_by", t("created_by"), item?.batch?.created_by],
      [
        "created_date",
        t("created_date"),
        toDisplayTime(item?.batch?.created_date),
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item?.batch?.last_modified_date),
      ],
      ["rawData", "", item],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
