import { useState, useEffect } from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
} from "../components/MuiGenerals";
import { useAppSelector } from "../reducer/hooks";
import { selectStuff } from "../reducer/stuffSlice";

const sx = {
  circular: {
    "& .MuiCircularProgress-circle": { transition: "none" },
  },
};
export function Circular(props: CircularProgressProps & { value: number }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} sx={sx.circular} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function LoadingSpin() {
  const [isOpen, setIsOpen] = useState(false);
  const { progress, showLoadingSpin } = useAppSelector(selectStuff);
  useEffect(() => {
    setIsOpen(showLoadingSpin);
  }, [showLoadingSpin]);
  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <Circular value={progress * 100} />
      </DialogContent>
    </Dialog>
  );
}
