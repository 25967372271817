import BigNumber from "bignumber.js";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import addHours from "date-fns/addHours";
import addMonths from "date-fns/addMonths";
import endOfDay from "date-fns/endOfDay";
import endOfSecond from "date-fns/endOfSecond";
import dateFormat from "date-fns/format";
import isValidTime from "date-fns/isValid";
import dateParse from "date-fns/parseISO";
import { saveAs } from "file-saver";
import { parse as parseCsv } from "json2csv";
import JSZip from "jszip";
import jwt_decode from "jwt-decode";
import { isNil } from "lodash";

import { store } from "../reducer/store";
import { endLoading, startLoading } from "../reducer/stuffSlice";
import { endpointLists } from "../utils/endpointsList";
import { dateInFormat, dateTimeInFormat } from "./config";
import {
  chainObjFace,
  csvRecordDivisor,
  csvRecordPageSize,
} from "./constant";
import { TransferTransactionType } from "@wallet-manager/node-types/dist/src/postgres/const";

export function generateNonce() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const renameObjKey =
  (oldName: string, newName: string) => (obj: any) => {
    const { [oldName]: value, ...rest } = obj;
    return {
      ...rest,
      [newName]: value,
    };
  };

function toTime(timeAny: any): Date | false {
  if (!timeAny) return false;
  const maybeTime = typeof timeAny === "string" ? dateParse(timeAny) : timeAny;
  if (!isValidTime(maybeTime)) {
    console.warn(`${maybeTime} is not valid Time`);
    return false;
  }
  return maybeTime;
}
export function toOnlyDate(time: string) {
  return dateFormat(new Date(time), dateInFormat);
}
function timeTransform(timeAny: Date | string | null) {
  const time = toTime(timeAny);
  if (!time) return "";
  const localUTCdiff = Number(store.getState().profile.timezone) || 0;
  return addHours(time, localUTCdiff);
}
export function customTimezoneToIso(timeAny: Date | string | null) {
  const time = toTime(timeAny);
  if (!time) return "";
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  return addHours(time, localUTCdiff);
}
export function toDBTime(
  timeAny: Date | string | null,
  isConvertingTimezone?: boolean
): string {
  const time = toTime(timeAny);
  if (!time) return "";
  if (!isConvertingTimezone) {
    const newTime = zonedTimeToUtc(time, "UTC");
    return newTime.toISOString();
  }
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const addedTimezoneOffset = addHours(time, localUTCdiff);
  const newConvertedTime = zonedTimeToUtc(addedTimezoneOffset, "UTC");
  return newConvertedTime.toISOString();
}
export function toDisplayTime(
  timeAny: Date | string | null,
  withoutSecond?: boolean
): string {
  const time = timeTransform(timeAny);
  if (!time) return "";
  const newTime = utcToZonedTime(time, "UTC");

  if (withoutSecond) {
    return dateFormat(newTime, dateTimeInFormat)
      .split(":")
      .slice(0, 2)
      .join(":");
  }

  return dateFormat(newTime, dateTimeInFormat);
}
export function jsonParse(
  input: any,
  config = { slient: true }
): [string, any?] {
  if (typeof input !== "string") return ["not a string"];
  if (input === "") return ["empty string"];
  try {
    return ["", JSON.parse(input)];
  } catch (err) {
    if (err instanceof SyntaxError) {
      if (!config?.slient) console.error(err);
      return ["syntax error"];
    }
    const errMessage: string = (err as Error).message;
    return [errMessage];
  }
}

export async function delayFn(fn: Function, delay: number) {
  return new Promise((r) => setTimeout(() => r(fn()), delay));
}
// const delay = 300;
type apiFn = (page: number, pageSize: number, signal?: any) => any;
async function getFullApiMap(
  apiFn: apiFn,
  times: number,
  pageSize: number,
  exportApi?: boolean
) {
  store.dispatch(startLoading(0));
  let resArray: any = [];
  if (exportApi) {
    for (let i = 0; i < times; i++) {
      resArray = [...resArray, ...(await apiFn(i, pageSize))];
      store.dispatch(startLoading((i + 1) / times));
    }
    return resArray;
  } else {
    for (let i = 0; i < times; i++) {
      resArray.push(await apiFn(i, pageSize));
      store.dispatch(startLoading((i + 1) / times));
    }
    return resArray.flatMap((res: any) => res.rows);
  }
  // const apiArray = new Array(times)
  //   .fill(null)
  //   .map((_, page) => delayFn(() => apiFn(page, pageSize), delay * page));
  // return axios.all(apiArray);
}

export async function getFullApiResponse(
  rawApiFn: apiFn,
  total: number,
  exportApi?: boolean
): Promise<any[]> {
  const pageSize = csvRecordPageSize;
  const times = Math.ceil(total / pageSize);
  // const estimatedTime = (times - 1) * delay;
  const controller = new AbortController();
  const apiFn = (page: number, pageSize: number) =>
    rawApiFn(page, pageSize, controller.signal);
  let allRes = [];
  try {
    allRes = await getFullApiMap(apiFn, times, pageSize, exportApi);
  } catch (err) {
    controller.abort();
  } finally {
    store.dispatch(endLoading());
  }
  return allRes;
  // const resFn = async () =>
  //   (await getFullApiMap(apiFn, times, pageSize)).flatMap(
  //     (res: any) => res?.rows
  //   );
  // const onError = () => {
  //   controller.abort();
  //   store.dispatch(endLoading());
  //   return [];
  // };
  // store.dispatch(startLoading(estimatedTime));
  // return await resFn().catch(onError);
}
export function downloadFiles(
  rawFilename: string,
  data: any[],
  inputConfig?: {}
) {
  const config = { needDate: true, ...inputConfig };
  const timeStamp = todaysDateForFileName();
  const filename = config.needDate
    ? `${rawFilename}_${timeStamp}`
    : rawFilename;
  const divisor = csvRecordDivisor;
  if (data.length <= divisor) return downloadCsv(filename, data, config);
  return downloadInZip(filename, prepareCsv(data, divisor), config);
}
export function prepareCsv(data: any[], divisor: number = csvRecordDivisor) {
  const csvs = divideToCsv(divisor, data);
  return csvs.map((c, id) => ({ name: `${String(id)}.csv`, data: c }));
}
function divideToCsv(divisor: number, data: any[]) {
  if (divisor <= 0) throw Error("divisor must >0");
  if (data?.length === 0) throw Error("empty data");
  const times = Math.ceil(data.length / divisor);
  let array = [];
  for (let i = 0; i < times; i++) {
    const piece = toCsv(data.splice(-divisor));
    array.push(piece);
  }
  return array;
}
export function toCsv(data: any) {
  let csv: BlobPart[] = [];
  try {
    csv = [parseCsv(data)];
  } catch (err) {
    console.error(err);
  }
  return new Blob(csv, { type: "text/csv;charset=utf-8" });
}
export function downloadCsv(filename: string, data: any, config?: any) {
  const blob = toCsv(data);
  const name = `${filename}.csv`;
  saveAs(blob, name);
}
/**
 example
    const data = [
      { a: 1, b: 2 },
      { a: 3, b: 4 },
    ];
    const config={
      prefix: "custom name",
    }
    downloadInZip("exam", prepareCsv(data), config);
 */
interface zipFace {
  name: string;
  data: any;
}
export function downloadInZip(
  zipName: string,
  list: zipFace[],
  config: any = {}
) {
  const { prefix = zipName, date = "" } = config;
  const zip = new JSZip();
  list.forEach(({ name, data }) => {
    const dateStr = date ? dateFormat(date, "yyyy-MM-dd") : "";
    const fileName = [prefix, dateStr, name].filter((s) => s).join("-");
    zip.file(fileName, data);
  });
  zip
    .generateAsync({ type: "blob" })
    .then((content) => saveAs(content, `${zipName}.zip`));
}
export function findChainByName(fullChain: any, chain_name: string) {
  return fullChain[chain_name];
}
export function findAssetsDropdownByChain(chain_name: string) {
  const assets = Object.values(store.getState().assets.list);
  const { chain_id, chain_type } =
    store.getState().chains.list[chain_name] || {};
  const theAssets = assets.filter(
    (item: any) => item.chain_id === chain_id && item.chain_type === chain_type
  );

  let output: FreeObj = {};
  for (let key in theAssets) {
    const obj = theAssets[key];
    // output[a.asset_name]=a.asset_name
    output[obj.asset_name] = obj.asset_name;
  }
  return output;
}

// export const findChainInfoByFullChainName = (chain_name: string) => {
//   const chainsArr = Object.values(store.getState().chains.list);
//   const foundChain = chainsArr.filter((chain) => chain.name === chain_name);
//   return foundChain;
// };

interface FreeObj {
  [code: string]: any;
}
export function findAssetsByChain(chain_name: string) {
  const assets = Object.values(store.getState().assets.list);
  const { chain_id, chain_type } =
    store.getState().chains.list[chain_name] || {};
  const theAssets = assets.filter(
    (item: any) => item.chain_id === chain_id && item.chain_type === chain_type
  );
  return theAssets;
}
export const findChainInfo = (chainType: string, chainId: string) => {
  const chains = Object.values(store.getState().chains.list);
  return chains.find(
    (item: any) => item.chain_id == chainId && item.chain_type == chainType
  );
};
export const findChainInfoByChainTypeOnly = (chainType: string) => {
  const chains = Object.values(store.getState().chains.list);
  return chains.find((item: any) => item.chain_type == chainType);
};
export const findAllETHChainInfoByChainTypeOnly = (chainType: string) => {
  const chains = Object.values(store.getState().chains.list);
  return chains.filter((item: any) => item.chain_type == chainType);
};

export const getEnumChainIntegerByString = (chainTypeString: string) => {
  const chains = Object.values(store.getState().chains.list);
  return findChainByName(chains, chainTypeString)?.chain_type;
};
export function getDecimal({ chain_type, chain_id, asset_name }: any): string {
  const missingParams = [chain_type, chain_id, asset_name].filter(isNil);
  if (missingParams.length > 0) {
    let message = "missing ";
    if (isNil(chain_type)) message += " chain_type ";
    if (isNil(chain_id)) message += " chain_id ";
    if (isNil(asset_name)) message += " asset_name ";
    // console.error(`invalid param, ${message}`);
    return "1";
  }
  return findDecimalByChainNameAndAsset(chain_type, chain_id, asset_name);
}
export const findDecimalByChainNameAndAsset = (
  chainType: string,
  chainId: string,
  asset_name: string
) => {
  const assets = Object.values(store.getState().assets.list);
  // creates a key by the three parameter to return a decimal/denomination for the currency based on chain name
  const theChain = assets.find(
    (item) =>
      item.chain_type === Number(chainType) &&
      item.chain_id === chainId &&
      item.asset_name === asset_name
  );
  const decimals = theChain?.decimals;
  if (!decimals || !Number.isInteger(decimals)) return "---";
  return String(decimals);
};
export function toDBInDecimals(
  rawValue: number | string,
  rawDecimals: number | string
): string {
  const [value, decimals] = [new BigNumber(rawValue), Number(rawDecimals)];
  if (decimals === 0 || Number.isNaN(decimals)) return "";
  const times = Math.pow(10, decimals);
  return value.multipliedBy(times).toFixed();
}

export function amountDivideDecimals(number: string, decimals: number = 10) {
  if (Number.isNaN(decimals)) return "-";
  return BigNumber(number).dividedBy(Math.pow(10, decimals)).toString();
}
export function displayAmount(
  amount: string | number,
  decimal: number | string
) {
  const strAmount = String(amount);
  const numberDecimal = Number(decimal);
  if (strAmount === "0") return "0";
  if (!strAmount) return "";
  const val = amountDivideDecimals(strAmount, numberDecimal);
  if (Number.isNaN(Number(val))) return "";
  return BigNumber(val).toFormat();
}

export function numberToFormattedString(
  number: number | string,
  maxDecimals: number = 8
) {
  var output = "0.00000000";
  try {
    output = number.toLocaleString("en-US", {
      maximumFractionDigits: maxDecimals,
    });
    if (output === "0") {
      output = "0.00000000";
    }
  } catch (error) {
    console.error(error);
  }
  return output;
}

export function separateBatchStrings(strings: string): string[] {
  return strings
    .split(/,/g)
    .map((str) => str.trim())
    .filter((id) => id);
}

export function todaysDateForFileName() {
  return dateFormat(new Date(), "yyyyMMddhhmmss");
}

export function checkIfAddressIsNull(
  walletAddress: string,
  transactionType: string
) {
  if (
    walletAddress === "0x0000000000000000000000000000000000000000" &&
    TransferTransactionType.Adjustment.toString() == transactionType
  ) {
    return "";
  } else {
    return walletAddress;
  }
}
export function uniq(a: any[]) {
  let seen: FreeObj = {};
  return a.filter((item) =>
    seen.hasOwnProperty(item) ? false : (seen[item] = true)
  );
}

export function searchEndOfSecond(timeAny: any) {
  const time = toTime(timeAny);
  if (!time) return "";
  return endOfSecond(time);
}
export function searchEndOfDay(timeAny: any) {
  const time = toTime(timeAny);
  if (!time) return "";
  return endOfDay(time);
}

// removed comma from bigNumber, otherwise cannot calculate or use BigNumber library
export function formatLargeNumberforBigNumber(num: any) {
  return num.toString().replace(/,/g, "");
}

// checks if number is in thousands, if so, formats with comma, otherwise auto format from scientific figure
export function formatBigNumber(num: any) {
  return Number(num) < 999 ? num.toFixed() : num.toFormat();
}

// allows the copy function in http
export function copyToClipboard(textToCopy: string) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    let textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = "fixed";
    textArea.style.left = "-999999px";
    textArea.style.top = "-999999px";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
}

export function sortAlphabetically(arr: any[]) {
  return arr.sort((a: any, b: any) => a[0].localeCompare(b[0]));
}
export function sortItemsAlphabetically(arr: any) {
  return arr.sort((a: any, b: any) => a[1].localeCompare(b[1]));
}

export function listMappingTransform(which: "key" | "name") {
  return (item: any, id: number) => {
    const initValue = which === "key" ? { id } : {};
    const reduceFn = (acc: any, [key, name, value]: [string, string, any]) => {
      const newObjKey = which === "key" ? key : name;
      return { ...acc, [newObjKey]: value };
    };
    return item.reduce(reduceFn, initValue);
  };
}
//only allow decimal/int
export function containsOnlyNumbers(str: string) {
  return /^[0-9]{0,}(,[0-9]{3})*(([\\.]{1}[0-9]*)|())$/.test(str);
}
//only allow negative/decimal/int
export const allowNegativeAmt = (input: string) => {
  return /^(-?(?![.-])\d{0,}(\.\d*)?|\d{1,3}(,\d{3})*(\.\d*)?)$/.test(input);
};

//{- -Convert array to String and for Comparison - -}
export function compareArrays(a: Array<any>, b: Array<any>) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function noDuplicated(arr: Array<string>) {
  const newArr = [...arr];
  return newArr.filter((item, index) => newArr.indexOf(item) == index);
}
export const bigNumberCompare = (firstValue: string, secondValue: string) => {
  const firstAmt = new BigNumber(firstValue);
  const secondAmt = new BigNumber(secondValue);
  return firstAmt.comparedTo(secondAmt);
};

export function findEnumKey(theEnum: unknown, value: string | number) {
  if (!theEnum) return "";
  return Object.entries(theEnum).find(([_, val]) => String(value) === val)?.[0];
}

//Get endpoints by function name and Operation Type in Audit Log Page
export const getEndpointsByFuncWithOpType = (
  funcEnumArr = [""],
  opTypeEnumArr = [""] //operationType
) => {
  let endpointList = endpointLists;
  if (opTypeEnumArr.length + funcEnumArr.length === 0) {
    return [];
  }
  if (funcEnumArr.length > 0) {
    endpointList = endpointList.filter((item) =>
      funcEnumArr.includes(item.name)
    );
  }
  if (opTypeEnumArr.length > 0) {
    endpointList = endpointList.filter((item) =>
      opTypeEnumArr.includes(item.type)
    );
  }
  let endpointArray = endpointList.map((item) => item.endpoint);
  return endpointArray;
};

//{- - find FunctionName and OpType via API url from backend- -}
export const findFunctionNameAndOpType = (endpoint: string) => {
  const functionName = endpointLists.filter(
    (item) => item.endpoint === endpoint
  );
  return functionName[0];
};

type ValueOf<T> = T[keyof T];

export function enumMapping<T extends Record<string, string>>(
  target: T
): T & Record<ValueOf<T>, keyof T> & Record<string, string> {
  let preObj: any = {};
  for (let key in target) {
    let value = String(target[key as keyof typeof target]);
    preObj[key] = value;
    preObj[value] = key;
  }
  return preObj;
}

export function objectEntries<
  T extends Record<PropertyKey, unknown>,
  K extends keyof T,
  V extends T[K]
>(o: T) {
  return Object.entries(o) as [K, V][];
}

// separated to three digits:
export const convertThousandth = (input: string | number) => {
  let value = new BigNumber(input);
  return value.toFormat();
};
//get the sum by each field together in a single array
export function calculateSum(array: any, property: string) {
  let sum = new BigNumber(0);
  array.forEach((item: any) => {
    const newObj = { ...item };
    const value = new BigNumber(newObj[`${property}`]);
    sum = sum.plus(value);
  });

  return sum.toFormat();
}
export const readRefreshToken = () =>
  localStorage.getItem("refreshToken") || "";
export const readAccessToken = () => localStorage.getItem("accessToken") || "";
export const writeTokens = ({ access_token = "", refresh_token = "" }) => {
  localStorage.setItem(`accessToken`, access_token);
  localStorage.setItem(`refreshToken`, refresh_token);
};
export const clearTokens = () => {
  localStorage.setItem(`accessToken`, "");
  localStorage.setItem(`refreshToken`, "");
};
export const decodeJwt = (token: string) => {
  try {
    return jwt_decode<{
      appUuid: string;
      loginId: number;
      email: string;
      username: string;
      status: number;
      role: number[];
      merchantId: string;
      ip: string;
      iat: number;
      exp: number;
      sessionExpires?: number;
      features?: Record<string, boolean>;
    }>(token);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const duplicationGuard = (inputAmtArray: Array<any>) => {
  //get only valid amt
  const getValidAmtOnly = inputAmtArray.filter((item) => item);
  const noDuplciatedArrLength = getValidAmtOnly.filter(
    (item, index) => getValidAmtOnly.indexOf(item) == index
  );
  //checking if it is duplicated, length not equal then it is duplicated, return true
  if (noDuplciatedArrLength.length !== getValidAmtOnly.length) {
    return true;
  }
  return false;
};

export const checkIfPreviousAmtEmptyFunc = (inputAmtArray: Array<any>) => {
  // Check for invalid cases
  //Not allow empty string between
  for (let i = 0; i < inputAmtArray.length - 1; i++) {
    if (inputAmtArray[i] === "" && inputAmtArray[i + 1] !== "") {
      return true;
    }
  }
};

export const checkIfAmtContainsEmptyString = (
  inputAmtArray: Array<any>,
  signString?: string
) => {
  const checkIfAllEmpty = inputAmtArray.every((item) => item == "");
  const containsLeastEmptyStr = inputAmtArray.includes("");
  if (signString === "cannotBeEmptyToken") {
    //only withdraw amt is mandatory field, which cannot be empty
    if (containsLeastEmptyStr) {
      return true;
    }
  }
  //if empty string in a consecutive row, then allow passed , so return false
  if (checkIfAllEmpty) return false;
};

export const nextAmtGreaterThanPreviousGuard = (inputAmtArray: Array<any>) => {
  const newAmtWithoutComma = inputAmtArray.map((element) => {
    if (element) {
      return element;
    } else return element;
  });

  const ascOrder = [...newAmtWithoutComma].sort((a, b) =>
    BigNumber(a).minus(b).toNumber()
  );
  return compareArrays(newAmtWithoutComma, ascOrder);
};

export const getAmtsArr = (inputArray: Array<any>, property: string) => {
  const res = inputArray.map((item: any) => item[property]);
  return res;
};

export const writeAccessToken = (token: string) =>
  localStorage.setItem("accessToken", token);

export const onlyAllowInteger = (input: string) => {
  return /^$|^[0-9]+$/.test(input);
};
export const moreThan2Months = (min: Date, max: Date) =>
  addMonths(min, 2).getTime() <= max.getTime();

export const getBtcSeriesChain = (
  chainObj: chainObjFace,
  keysToExtract: any
) => {
  const btcSeriesObject: chainObjFace = {};
  for (const key of keysToExtract) {
    if (chainObj.hasOwnProperty(key)) {
      btcSeriesObject[key] = chainObj[key];
    }
  }
  return btcSeriesObject;
};

export const filterValidPayload = (params: any) => {
  const arrayForm = Object.entries(params);

  const result = arrayForm.filter(
    ([_, value]) => value !== "" || value.length > 0
  );
  return Object.fromEntries(result);
};

export function removeCommasFromNumStr(numStr: string) {
  return numStr.replaceAll(",", "");
}

export const bigNumStrMulitpleDecimals = (
  numStr: string,
  decimals: number | string
) => {
  if (Number.isNaN(Number(decimals))) {
    return "-";
  }
  const numStrNoCommas = removeCommasFromNumStr(numStr);
  if (!Number(numStrNoCommas)) {
    return "0";
  }

  return BigNumber(numStrNoCommas)
    .multipliedBy(Math.pow(10, Number(decimals)))
    .toString();
};

export const createThrottleFunc = (callback: any, delay = 1000) => {
  let timerId: any;

  return function (...args: any) {
    if (timerId) {
      return;
    }
    timerId = setTimeout(() => {
      callback(...args);
      timerId = null;
    }, delay);
  };
};

export const setClientDateTimeDiff = (serverTime: number = 0) => {
  let clientDateTimeDiff;

  if (!serverTime) {
    clientDateTimeDiff = 0;
  } else {
    clientDateTimeDiff = new Date().getTime() - serverTime * 1000;
  }

  localStorage.setItem("clientDateTimeDiff", String(clientDateTimeDiff));

  return clientDateTimeDiff;
};

export const getClientDateTimeDiffFromLocalStorage = () => {
  return Number(localStorage.getItem("clientDateTimeDiff")) || 0;
};
