import { Container, Box, Button } from "../../../components/MuiGenerals";
import { useTranslate } from "react-admin";
import { useState, Dispatch, SetStateAction } from "react";
import { yubiKeyReBind, yubiRegister } from "../../../api/merchant";
import { customSx } from "../../../utils/styling";
import { genField } from "../../../utils/HelperComp";
import { useAlerting } from "../../../hooks";
import {
  useYubiKeysStoreForReg,
  zusRefetchStore,
} from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";
interface fieldsFace {
  otp: string;
}

const initFields = {
  otp: "",
};
export default function YubiRegister() {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar fields={fields} setFields={setFields} />
      </Container>
    </Box>
  );
}
export function FilterBar(props: {
  fields: fieldsFace;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
}) {
  const refetchFunc = zusRefetchStore((state) => state.refetchFunc);
  const { fields, setFields } = props;
  const myYubiDetail = useYubiKeysStoreForReg((state) => state.myDetail);
  const proposalStatus = myYubiDetail?.proposals
    ? myYubiDetail.proposals[0]
    : { act: 999, status: 999 };
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  const { alerting } = useAlerting();
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const register = async () => {
    const res = myYubiDetail?.proposals
      ? await yubiKeyReBind({ new_otp: fields.otp })
      : await yubiRegister({ otp: fields.otp });
    if (!res) return;
    alerting("success", t("register_submitted"));
    refetchFunc({ where: "yubiKeyRegPage" });
  };
  const F = genField({ t }, [
    [
      "otp",
      <MpTextField
        name="otp"
        label={t("ph_otp")}
        value={fields.otp}
        onChange={onChange("otp")}
        // disabled={yubikeyStatus}
      />,
    ],
  ]);
  return (
    <>
      {(proposalStatus.act === 1 || proposalStatus.act === 4) &&
      proposalStatus.status === 1 ? (
        <Section
          tzKey={"status"}
          value={t("pendingApproval")}
          textColor={"#4a90f7"}
        />
      ) : (
        <>
          <Box sx={customSx.gridFilter} className="gridFilter">
            {F.otp}
          </Box>
          <Box sx={customSx.filterB}>
            <Button onClick={register} color="secondary" variant="contained">
              {t("register")}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}

export const Section = (props: any) => {
  const { value, tzKey, textColor } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  return (
    <>
      <section style={{ display: "flex", marginTop: "1.5rem" }}>
        <div style={{ minWidth: "10rem" }}>{t(`${tzKey}`)}</div>
        <div style={{ textAlign: "left", color: `${textColor}` }}>{value}</div>
      </section>
    </>
  );
};
