import { useTranslate } from "react-admin";
import {
  GridRenderCellParams,
  DataGrid,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import React from "react";
import { CustomPagination } from "../../../components/CustomPagination";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { FeatureCodes, dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusListStore } from "../../../zustand/store";
import Operation from "./Operation";
import { translatePrefix } from "./assetListTypes";
import { usePermission } from "../../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const Pkey = FeatureCodes.assetManagement.AssetList;
const AssetListGrid = (props: any) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { listMapping } = props;
  const zusList = useZusListStore();
  const { hasPermission } = usePermission();
  const totalRecords = zusList.count;
  const content: GridRowsProp = listMapping("key", zusList.rows, { translate });
  const cellButtons = (params: GridRenderCellParams) => {
    const props = {
      params,
    };
    return <Operation {...props} />;
  };
  const columns: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("decimals", t("decimals")),
    useGenGridCol("push_deposit_message_display", t("push_deposit_message")),
    useGenGridCol("withdraw_status_display", t("withdraw_status")),
    useGenGridCol("last_operated_by", t("last_operated_by")),
    useGenGridCol("last_operation_time", t("last_operation_time")),
    useGenGridCol("1", t("operation"), {
      minWidth: 200,
      renderCell: cellButtons,
    }),
    //EDIT
  ].filter((col) => {
    if (col.field === "1" && !hasPermission(Pkey.Edit)) {
      return false;
    }
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={zusList.count}
        columns={columns}
        page={zusList.meta.page}
        onPageChange={zusList.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
};
export default React.memo(AssetListGrid);
