import { useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';

import { createAddressBookProposals } from '../../api/merchant';
import { useAlerting, usePermission, useYubiPrompt } from '../../hooks';
import { enumWalletType } from '../../utils/constant';
import { customSx } from '../../utils/styling';
import { ChainMultipleSelection } from '../ChainSelection';
import { useChains } from '../FetchConfig';
import MpTextField from '../MpTextField';
import YubiPrompter from '../Prompter';

interface WalletAssignmentFieldsFace {
  chain_name: string[];
  walletType: string;
  display_name: string;
  wallet_address: string;
  observe_address: boolean;
}

const initAssignmentFields = {
  chain_name: [],
  walletType: "",
  display_name: "",
  wallet_address: "",
  observe_address: false,
};

export default function AddAddressBookDialog(props: any) {
  const { openAddAddressBook, setOpenAddAddressBook, refresh } = props;
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [fields, setFields] =
    useState<WalletAssignmentFieldsFace>(initAssignmentFields);
  const { chainObj, selectChainByPieces } = useChains();
  const assignWalletTypeArr = ["0", "5"];
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };

  interface SelectedChainsObj {
    chain_type: number;
    chain_ids: string[];
  }
  const selectedChainsObj: SelectedChainsObj = {
    chain_ids: [],
    chain_type: -1,
  };
  fields.chain_name.forEach((chain_name) => {
    const selectedchain = selectChainByPieces({
      chain_name,
    });
    if (selectedchain.chain_type)
      selectedChainsObj.chain_type = selectedchain.chain_type;
    if (selectedchain.chain_id)
      selectedChainsObj.chain_ids.push(selectedchain.chain_id);
  });
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { hasPermission } = usePermission();

  const onConfirmAssignAddress = async () => {
    if (
      !selectedChainsObj.chain_type &&
      typeof selectedChainsObj.chain_type !== "number"
    ) {
      alerting("error", t("chainTypeSeriesError"));
    } else if (
      selectedChainsObj.chain_type === -1 ||
      !selectedChainsObj.chain_ids.length
    ) {
      alerting("error", t("chainTypeError"));
    } else if (!fields.walletType) {
      alerting("error", t("walletTypeError"));
    } else if (!fields.display_name) {
      alerting("error", t("walletNameError"));
    } else if (!fields.wallet_address) {
      alerting("error", t("walletAddressError"));
    } else {
      const params = {
        wallet_address: fields.wallet_address,
        wallet_type: Number(fields.walletType),
        display_name: fields.display_name,
        wallet_name: fields.display_name,
        chain_type: selectedChainsObj.chain_type,
        chain_ids: selectedChainsObj.chain_ids,
        save_records: fields.observe_address,
      };
      const apiRes = await getResAfterYubi(createAddressBookProposals, params);

      try {
        // For assigning XRP chain
        if (!apiRes) return;
        alerting("success", t("request_record_created"));
      } catch (error) {
        console.error("createWalletAddressResonse error", error);
      }
      setFields(initAssignmentFields);
      setOpenAddAddressBook(false);
      refresh();
    }
  };

  return (
    <>
      <Dialog
        open={openAddAddressBook}
        onClose={() => {
          setOpenAddAddressBook(false);
          setFields(initAssignmentFields);
        }}
        fullWidth={true}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle>{t("addAddressBook")}</DialogTitle>
        <DialogContent>
          {/* <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("chainTypeError")}</InputLabel>
            <Select name="chain_name" defaultValue="" onChange={onSelectChange}>
              {Object.entries(chainObj).map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <ChainMultipleSelection
            isFullWidth
            label={t("chainTypeError")}
            choices={fields.chain_name}
            setChoices={(choices) => {
              setFields({ ...fields, chain_name: choices });
            }}
          />
          <br />
          <FormControl fullWidth={true}>
            <InputLabel>{t("walletTypeError")}</InputLabel>
            <Select
              name="walletType"
              value={fields.walletType}
              onChange={onSelectChange}
              defaultValue=""
            >
              {Object.entries(enumWalletType)
                .filter(([key, val]) => assignWalletTypeArr.includes(key))
                .map(([key, value], i) => (
                  <MenuItem key={i} value={key}>
                    {translate(`enumConstants.${value}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <MpTextField
            fullWidth={true}
            label={t("walletNameError")}
            onChange={onChange("display_name")}
          />
          <br />
          <MpTextField
            fullWidth={true}
            label={t("walletAddressError")}
            onChange={onChange("wallet_address")}
          />
          <br />
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={fields.observe_address}
                onChange={() =>
                  setFields({
                    ...fields,
                    observe_address: !fields.observe_address,
                  })
                }
              />
            }
            label={t("observeWalletError")}
          />

          <br /> */}
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmAssignAddress}
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenAddAddressBook(false);
              setFields(initAssignmentFields);
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
