import { useTranslate } from "react-admin";

import en from "../components/i18nProvider/en";

export type Iprefix = keyof typeof en;
export type IcommonKey = keyof typeof en.common;
export type IenumKey = keyof typeof en.enumConstants;
export default function useTranslation(prefix: Iprefix) {
  const translate = useTranslate();
  const t = (key: string, params = {}) =>
    prefix ? translate(`${prefix}.${key}`, params) : "";
  const tc = (key: IcommonKey, params = {}) =>
    translate(`common.${key}`, params);
  const te = (key: IenumKey, params = {}) =>
    translate(`enumConstants.${key}`, params);
  return { translate, t, tc, te };
}
