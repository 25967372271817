import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import { getGeneratedAddressList, listFace } from "../../../api/merchant";
import { useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import { usePermission } from "../../../hooks";
import {
  enumWalletType,
  FeatureCodes,
  tableConfig,
} from "../../../utils/constant";
import {
  findChainInfo,
  listMappingTransform,
  sortItemsAlphabetically,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import AddressGenerationDialog from "./AddressGenerationDialog";
import AddressGenerationList from "./AddressGenerationList";
import AddressImportDialog from "./AddressImportDialog";
import SolChainSelection from "./SolChainSelection";
import { translatePrefix } from "./type";

const Pkey = FeatureCodes.tools.AddressGeneration;
interface fieldsFace {
  chain_name: string;
  wallet_types: Array<string>;
}

const walletTypeArr = ["1", "3"]; // Client Wallet and Invoker Wallet

const AddressGeneration = () => {
  const { chainObj } = useChains();
  const solObjArr =
    chainObj?.SOLD || chainObj?.SOL
      ? Object.entries({ ...chainObj }).filter(([key, value]: any) => {
          return (
            (value.chain_type === 5 && value.chain_id === "2") ||
            (value.chain_type === 5 && value.chain_id === "1")
          );
        })
      : [];

  const initFields = {
    chain_name: solObjArr[0]?.[1]?.chain_code,
    wallet_types: [],
  };

  const [fields, setFields] = useState<fieldsFace>(initFields);
  const [page, setPage] = useState(0);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const ListProps = {
    list,
    setPage,
    page,
    listMapping,
  };

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <AddressGenerationDialog />
        <AddressImportDialog />
        {solObjArr.length > 0 && (
          <FilterBar
            {...{
              fields,
              setFields,
              list,
              setPage,
              setList,
              page,
              chainObj,
              onReset: () => setFields(initFields),
            }}
          />
        )}
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <AddressGenerationList {...ListProps} />
      </Container>
    </Box>
  );
};
//{- - Debug- -}

export function FilterBar(props: any) {
  const { hasPermission } = usePermission();
  const { fields, setFields, setPage, setList, page, onReset } = props;
  const [loading, setLoding] = useState(true);
  const zusDialog = useZusDialogStore();
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { selectChainByPieces, chainObj } = useChains();

  const solObjArr = Object.entries({ ...chainObj }).filter(
    ([key, value]: any) => {
      return (
        (value.chain_type === 5 && value.chain_id === "2") ||
        (value.chain_type === 5 && value.chain_id === "1")
      );
    }
  );

  const getParams = () => {
    const item = solObjArr.map(([key, value]) => value)[0];
    const { chain_name, wallet_types } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    return {
      chain_type: !chain_name ? item.chain_type : chain_type,
      chain_id: !chain_name ? item.chain_id : chain_id,
      wallet_types: wallet_types.length ? wallet_types : walletTypeArr,
      page: page,
      pageSize: tableConfig.pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getGeneratedAddressList({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    }
    fetch();
    setLoding(false);
  }, [page]);
  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "chain_name") {
      if (key === fields.chain_name)
        setFields((fields: any) => ({
          ...fields,
          chain_name: "",
        }));
    }
  };
  const search = async () => {
    zusDialog.openExtra("loadingDialog");
    setPage(0);
    setCacheParams(params);
    const res = await getGeneratedAddressList(params);
    zusDialog.closeExtra();
    if (!res) return;
    setList(res);
  };
  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) {
      newValue = [];
    }

    return setFields((filterText: any) => ({
      ...filterText,
      [name]: newValue,
    }));
  };

  const F = genField({ t }, [
    [
      "chain_name",
      <SolChainSelection
        label={t("phChain_name")}
        setChoice={(chain_name) =>
          setFields((f: any) => ({ ...f, chain_name }))
        }
        choice={fields.chain_name}
        disableUnselect
      />,
    ],
    [
      "wallet_types",
      <FormControl>
        <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
        <Select
          multiple
          name="wallet_types"
          value={fields.wallet_types}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem
                key={key}
                value={key}
                onClick={(e) => handleClick(e, value, "wallet_types")}
              >
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
  ]);
  const handleAddressGeneration = () => {
    zusDialog.open("gen_address_dialog");
  };
  const handleImportFile = (e: any) => {
    zusDialog.open("address_upload_dialog");
  };

  if (loading) return <></>;

  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.GenerateAddress) && (
          <Button onClick={handleAddressGeneration} variant="outlined">
            {t("address_generation")}
          </Button>
        )}

        {hasPermission(Pkey.ImportAddress) && (
          <Button variant="outlined" onClick={handleImportFile}>
            {t("Import")}
          </Button>
        )}
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.wallet_types}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn onClick={search} label="search" />
        <GeneralBtn onClick={onReset} label="reset" />
      </Box>
    </>
  );
}
const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const res = array.map((item: any, index: number) => {
    const walletType =
      item.wallet_type != null
        ? translate(
            `enumConstants.${enumWalletType[item?.wallet_type.toString()]}`
          )
        : "";

    const unusedAddressCount =
      isNaN(Number(item.generated_count)) || isNaN(Number(item.assigned_count))
        ? ""
        : Number(item.generated_count) - Number(item.assigned_count);

    const mappedResult = [
      ["id", "", index],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["wallet_types", t("wallet_types"), walletType],

      ["wallet_version", t("wallet_version"), item.wallet_version],
      ["sum_addr_generated", t("sum_addr_generated"), item.generated_count],
      ["addr_unused_count", t("addr_unused_count"), unusedAddressCount],
      ["addr_used_count", t("addr_used_count"), item.assigned_count],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

export default AddressGeneration;
