import React, { useEffect, useState } from "react";
import {
  alterWithdrawRequestApprovalSetting,
  WithdrawThreshold,
} from "../../api/signer";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import {
  toDBInDecimals,
  containsOnlyNumbers,
  findDecimalByChainNameAndAsset,
  bigNumberCompare,
  duplicationGuard,
  nextAmtGreaterThanPreviousGuard,
  checkIfAmtContainsEmptyString,
  onlyAllowInteger,
  checkIfPreviousAmtEmptyFunc,
} from "../../utils/helper";
import { customSx } from "../../utils/styling";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
import { useAssets, useChains } from "../FetchConfig";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../MuiGenerals";
import ApprovalRegulation from "./ApprovalRegulation";
import { AssetSelectionSingleForApproveSetting } from "./AssetSelectionSingleForApproveSetting";
import MpTextField from "../MpTextField";
import { FeatureCodes } from "../../utils/constant";
import YubiPrompter from "../Prompter";
import GeneralBtn from "../GeneralBtn";
const translatePrefix = "withdrawApprovalSetting";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting;

interface IRecord {
  chain_name: string;
  asset_name: string;
  threshold: string;
  hourly_auto_approval_limit: string;
  mx_hourly_limit: string;
  to_addr_count: string;
  to_addr_limit: string;
}
interface IRootObject {
  id: string;
  merchant_id: string;
  chain_type: number;
  chain_id: string;
  asset_name: string;
  threshold_amount: string;
  created_date: Date;
  last_modified_date: Date;
}
interface IRegulationItems {
  seq: number;
  withdraw_amt: string;
  settle_amt: string;
}
//{-- - Initial States- - -}
const initialRecord = {
  chain_name: "",
  asset_name: "",
  threshold: "",
  hourly_auto_approval_limit: "",
  mx_hourly_limit: "",
  to_addr_count: "",
  to_addr_limit: "",
};

//{- - - -}
const WithDrawApprovalAddRecordDialog = () => {
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  //{- - Translation- -}

  const { t } = useTranslation(translatePrefix);
  //{- -Local States - -}
  const [list, setList] = useState<any>({ rows: [], count: 0 });
  const [fields, setFields] = useState<IRecord>(initialRecord);
  const [regulationItems, setRegulationItems] = useState<
    Array<IRegulationItems>
  >([{ seq: 0, withdraw_amt: "", settle_amt: "" }]);

  const chain_name = fields.chain_name;
  //{- -Zustand - -}
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  //{- - Redux stuff- -}
  const { alerting } = useAlerting();
  const { email } = useAppSelector(selectProfile);
  const { chainObj, selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });
  const assetsNames = selectAssetNamesByChain({ chain_name });

  //{- -Handle Value Input - -}
  const handleOnchangeEvent =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      const resp =
        name == "to_addr_count"
          ? onlyAllowInteger(value)
          : containsOnlyNumbers(value);
      if (!resp) return;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  //{- - Threshold Input Validation- -}
  const handleOnSelectEvent = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };
  const setAssets = (assets: string) => {
    setFields((fields) => ({ ...fields, asset_name: assets }));
  };
  //{- - Side_Effect- -}
  useEffect(() => {
    setAssets("");
  }, [chain_name]);
  //{- -find decimal - -}
  const decimals = findDecimalByChainNameAndAsset(
    String(chain_type),
    String(chain_id),
    fields.asset_name
  );
  const getProperAmtWithDecimal = (inputValue = "") => {
    return inputValue ? toDBInDecimals(inputValue, decimals) : "";
  };

  const regulationItemsWithDecimal = regulationItems
    .map((item) => {
      const newObj = { ...item };
      let arr = [];
      if (item.withdraw_amt) {
        arr.push(
          Object.assign(
            {},
            {
              seq: item.seq,
              amount: getProperAmtWithDecimal(newObj.withdraw_amt),
            }
          )
        );
      }
      if (item.settle_amt) {
        arr.push(
          Object.assign(
            {},
            {
              seq: item.seq,
              amount: getProperAmtWithDecimal(newObj.settle_amt),
              wallet_type: "5",
            }
          )
        );
      }
      return arr;
    })
    .flat();

  //{- - Params send to backend- -}
  const params = {
    chain_type: chain_type,
    chain_id: chain_id,
    asset_name: fields.asset_name,
    // amount: getProperAmtWithDecimal(fields.threshold),
    hourly_auto_approval_limit: getProperAmtWithDecimal(
      fields.hourly_auto_approval_limit
    ),
    withdrawApprovalRegulations: regulationItemsWithDecimal,
    email,
    to_addr_hourly_limit: getProperAmtWithDecimal(fields.to_addr_limit),
    to_addr_hourly_count: fields.to_addr_count,
    hourly_limit: getProperAmtWithDecimal(fields.mx_hourly_limit),
  };
  //{- -Buttons Function Handlers- -}
  const handleConfirm = async () => {
    const existingItem = list.rows.filter((item: IRootObject) => {
      const isChain_idMatch = item.chain_id == chain_id;
      const isChain_typeMatch = item.chain_type == chain_type;
      const isAsset_nameMatch = item.asset_name == fields.asset_name;
      const ifAllTrue =
        isChain_idMatch && isChain_typeMatch && isAsset_nameMatch;
      return ifAllTrue && item;
    });
    //{- -fire alert if chain name empty- -}
    if (!fields.chain_name) {
      return alerting("warning", t("chainNameIsEmpty"));
    }
    //{- -fire alert if asset name empty- -}
    if (!fields.asset_name) {
      return alerting("warning", t("assetIsEmpty"));
    }

    //{- -check if regulationAmt has length- -}
    if (regulationItems.length > 0) {
      const withdraw_amt = regulationItems[0].withdraw_amt!;
      const settle_amt = regulationItems[0].settle_amt!;
      //{- -fire alert if threshold empty- -}

      if (!withdraw_amt! && !settle_amt!) {
        return alerting("warning", t("pleaseEnterWithdrawAmtAndSettlementAmt"));
      }
      if (!withdraw_amt!) {
        return alerting("warning", t("pleaseEnterWithDrawAmt")); //to be updated message
      }
      if (!settle_amt!) {
        return alerting("warning", t("pleaseEnterSettleMentAmt")); //to be updated message
      }
      if (
        fields.hourly_auto_approval_limit &&
        regulationItems[0].withdraw_amt!
      ) {
        const result = bigNumberCompare(
          fields.hourly_auto_approval_limit,
          regulationItems[0].withdraw_amt!
        );
        if (result === -1) {
          return alerting(
            "warning",
            t(
              "hourlyLimistMustBeGreaterThanAmtInRegulation",
              useStore.configTime
            )
          );
        }
      }
      //{- -fire alert if first regulationAmt is equal or smaller than thresholdAmt- -}
      const withdrawAmtArr = regulationItems.map((item) => item.withdraw_amt);
      const settleAmtArr = regulationItems.map((item) => item.settle_amt);

      const withdrawAmtRes = duplicationGuard(withdrawAmtArr);

      if (withdrawAmtRes) {
        return alerting("warning", t("withdrawAmtMustBeGreater"));
      }
      const settleAmtArrRes = duplicationGuard(settleAmtArr);

      if (settleAmtArrRes) {
        return alerting("warning", t("settleAmtMustBeGreater"));
      }
      const isPreviousWithdrawEmpty =
        checkIfPreviousAmtEmptyFunc(withdrawAmtArr);
      const isPreviousSettleEmpty = checkIfPreviousAmtEmptyFunc(settleAmtArr);
      if (isPreviousWithdrawEmpty) {
        return alerting("warning", t("previousWithdrawLimitIsEmpty"));
      }
      if (isPreviousSettleEmpty) {
        return alerting("warning", t("previousSettleLimitIsEmpty"));
      }

      const hasEmptyStrWithdraw = checkIfAmtContainsEmptyString(
        withdrawAmtArr,
        "cannotBeEmptyToken"
      );
      if (hasEmptyStrWithdraw)
        return alerting("warning", t("pleaseEnterWithDrawAmt"));
      const hasEmptyStrSettle = checkIfAmtContainsEmptyString(
        settleAmtArr,
        "optionalToken"
      );
      if (hasEmptyStrSettle)
        return alerting("warning", t("pleaseEnterSettleMentAmt"));

      const withdrawAmtgreaterThanRes =
        nextAmtGreaterThanPreviousGuard(withdrawAmtArr);
      if (!withdrawAmtgreaterThanRes) {
        return alerting("warning", t("withdrawAmtMustBeGreater"));
      }
      const settleAmtgreaterThanRes =
        nextAmtGreaterThanPreviousGuard(settleAmtArr);
      if (!settleAmtgreaterThanRes) {
        return alerting("warning", t("settleAmtMustBeGreater"));
      }
    }

    //{- -Not Allow Add if Record exists - -}
    if (existingItem.length !== 0) return alerting("warning", t("recordExist"));

    const resp = await getResAfterYubi(
      alterWithdrawRequestApprovalSetting,
      params
    );

    if (resp) {
      alerting("success", t("Success"));
      zusDialog.close();
      setFields(initialRecord);
      setRegulationItems([]);
    }
  };
  //{- -Fetch ALL existing records- -}
  useEffect(() => {
    const totalCount = useStore.totalCountForThresholdRecord;
    if (!totalCount) return;
    const params = {
      page: 0,
      pageSize: Number(totalCount),
    };
    async function fetch() {
      const res = await WithdrawThreshold(params);
      if (!res) return;
      setList(res);
    }
    fetch();
  }, [useStore.totalCountForThresholdRecord]);

  const handleCloseResetStates = () => {
    zusDialog.close();
    setFields(initialRecord);
    setRegulationItems([{ seq: 0, withdraw_amt: "", settle_amt: "" }]);
  };

  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.match("recordDialog")}
        onClose={handleCloseResetStates}
        fullWidth={true}
      >
        <DialogTitle>{t("add_record")}</DialogTitle>

        <DialogContent>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("phChain_name")}</InputLabel>
            <Select
              name="chain_name"
              onChange={handleOnSelectEvent}
              defaultValue=""
              disabled={!hasPermission(Pkey.TabSetting.AddRecord)}
            >
              {Object.entries(chainObj).map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />

          <AssetSelectionSingleForApproveSetting
            addRecordProps={true}
            label={t("phAsset_name")}
            setChoices={setAssets}
            choices={fields.asset_name}
            allItems={assetsNames}
          />
          <br />

          <MpTextField
            name={"hourly_auto_approval_limit"}
            variant="filled"
            value={fields.hourly_auto_approval_limit}
            fullWidth={true}
            label={t("phAutoApproval_limit", useStore.configTime)}
            onChange={handleOnchangeEvent("hourly_auto_approval_limit")}
          />
          {/*  */}
          <br />
          <MpTextField
            name={"mx_hourly_limit"}
            variant="filled"
            value={fields.mx_hourly_limit}
            fullWidth={true}
            label={t("phMx_hourly_limit", useStore.configTime)}
            onChange={handleOnchangeEvent("mx_hourly_limit")}
          />
          <br />
          <MpTextField
            name={"to_addr_limit"}
            variant="filled"
            value={fields.to_addr_limit}
            fullWidth={true}
            label={t("phMx_addr_hrly_limit", useStore.configTime)}
            onChange={handleOnchangeEvent("to_addr_limit")}
          />
          <br />
          <MpTextField
            name={"to_addr_count"}
            variant="filled"
            value={fields.to_addr_count}
            fullWidth={true}
            label={t("phMx_addr_hrly_count", useStore.configTime)}
            onChange={handleOnchangeEvent("to_addr_count")}
          />
          <div
            style={{
              fontSize: "0.9rem",
              color: "rgb(133,133,133)",
            }}
          >
            {t("limit_inputBox_description")}
          </div>
          {/* {- -Work Begins- -} */}

          <ApprovalRegulation
            setRegulationItems={setRegulationItems}
            regulationItems={regulationItems}
          />

          {/* {- -Work Ends- -} */}
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          {hasPermission(Pkey.TabSetting.AddRecord) && (
            <GeneralBtn label="confirm" onClick={handleConfirm} />
          )}
          <GeneralBtn
            label="cancel"
            color="error"
            onClick={handleCloseResetStates}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(WithDrawApprovalAddRecordDialog);
