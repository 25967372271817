import {
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  addYears,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subSeconds,
  subYears
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { CalendarPickerView } from '@mui/x-date-pickers/CalendarPicker';
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import { useAlerting, useTranslation } from '../hooks';
import { store } from '../reducer/store';
import { dateInFormat, dateTimeInFormat } from '../utils/config';
import { searchEndOfDay, searchEndOfSecond, toDBTime } from '../utils/helper';
import MpTextField from './MpTextField';
import { Box } from './MuiGenerals';

type PropsFace = {
  label?: string;
  timeValue: Date | null;
  minEndTime?: any;
  maxStartTime?: any;
  disableFuture?: boolean;
  notPassingSxToFreeInput?: boolean;
  setTimeValue:
    | React.Dispatch<React.SetStateAction<Date | null>>
    | ((value: Date | null) => void);
  sx?: any;
} & (
  | {
      type: "date";
      views?: CalendarPickerView[];
    }
  | {
      type: "dateTime";
      views?: CalendarOrClockPickerView[];
      isOnlyHourEnable?: boolean;
    }
);
export default function TimePicker(props: PropsFace) {
  const {
    type,
    label,
    setTimeValue,
    timeValue = null,
    minEndTime,
    maxStartTime,
    views,
    disableFuture = false,
    notPassingSxToFreeInput,
    sx,
  } = props;
  const [timeShow, setTimeShow] = useState<Date | null | undefined>(timeValue);
  const { alerting } = useAlerting();
  const { t } = useTranslation("common");
  useEffect(() => {
    setTimeShow(timeValue);
  }, [timeValue]);
  const hasViews = views && views.length > 0;
  const dateViews =
    hasViews && type === "date" ? views : (["day"] as CalendarPickerView[]);
  const dateTimeViews =
    hasViews && type === "dateTime"
      ? views
      : (["day", "hours", "minutes", "seconds"] as CalendarOrClockPickerView[]);
  const handleChange = (newValue: Date | null) => {
    const isOnlyAcceptHours = type === "dateTime" && !!props.isOnlyHourEnable;
    if (
      isOnlyAcceptHours &&
      (!!newValue?.getMinutes() || !!newValue?.getSeconds())
    ) {
      const onlyHoursDateValue = newValue.toDateString();
      const onlyHoursTimeValue = newValue.getHours() + ":00:00";
      const onlyHoursDateTimeValue = new Date(
        onlyHoursDateValue + " " + onlyHoursTimeValue
      );
      setTimeShow(onlyHoursDateTimeValue);
      alerting("warning", t("input_hours_only_warning"));
      return;
    }

    setTimeShow(newValue);
  };
  const onAccept = () => {
    if (!timeShow) return;

    setTimeValue(timeShow);
  };
  return (
    <Box sx={{ position: "relative", ">div": { width: "100%" }, ...sx }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {type === "date" && (
          <MobileDatePicker
            views={dateViews}
            disableFuture={disableFuture}
            label={label}
            inputFormat={dateInFormat}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDate={minEndTime}
            maxDate={maxStartTime}
            renderInput={(params) => (
              <MpTextField
                sx={notPassingSxToFreeInput ? undefined : sx}
                {...params}
              />
            )}
          />
        )}
        {type === "dateTime" && (
          <MobileDateTimePicker
            views={dateTimeViews}
            disableFuture={disableFuture}
            label={label}
            inputFormat={dateTimeInFormat}
            value={timeShow}
            onAccept={onAccept}
            onChange={handleChange}
            minDateTime={minEndTime}
            maxDateTime={maxStartTime}
            renderInput={(params) => (
              <MpTextField
                sx={notPassingSxToFreeInput ? undefined : sx}
                {...params}
                error={props.isOnlyHourEnable ? false : undefined}
              /> // to eliminate the error red line
            )}
            shouldDisableTime={(_, clockType) =>
              !!props.isOnlyHourEnable &&
              (clockType === "minutes" || clockType === "seconds")
            }
          />
        )}
      </LocalizationProvider>
    </Box>
  );
}

type maybeDate = Date | null;
interface useDatePickerFace {
  start: {
    defaultDate: maybeDate;
    label?: String;
  };
  end: {
    defaultDate: maybeDate;
    label?: String;
  };
  isTimezoneConvert: boolean;
  isEndOfSecond: boolean;
}
//to put default date
//useDatePicker({ start: { defaultDate: new Date() } })
export function useDatePicker(inputConfig?: Partial<useDatePickerFace>) {
  const defaultConfig = {
    start: {
      defaultDate: null,
      label: "Time Start",
    },
    end: {
      defaultDate: null,
      label: "Time End",
    },
    isTimezoneConvert: true,
    isEndOfSecond: true,
  };
  const config = { ...defaultConfig, ...inputConfig };
  const { tc } = useTranslation("common");
  const [dateStart, setDateStart] = useState<maybeDate>(
    config.start.defaultDate
  );
  const [dateEnd, setDateEnd] = useState<maybeDate>(config.end.defaultDate);
  const clearDate = () => {
    setDateStart(null);
    setDateEnd(null);
  };
  const resetDate = () => {
    setDateStart(config.start.defaultDate);
    setDateEnd(config.end.defaultDate);
  };
  const hyphenStyle = {
    verticalAlign: "bottom",
    fontSize: "2rem",
    margin: "0 10px",
  };

  type DateRangePickerProps = {
    startLabel?: string;
    endLabel?: string;
  } & (
    | {
        type: "date";
        interval?: { type: CalendarPickerView; value: number };
        view?: CalendarPickerView[];
        disableFuture?: boolean;
      }
    | {
        type: "dateTime";
        isOnlyHourEnable?: boolean;
        interval?: { type: CalendarOrClockPickerView; value: number };
        view?: CalendarOrClockPickerView[];
        disableFuture?: boolean;
      }
  );

  const DateFnsMethodByIntervalType = (direction: "start" | "end") =>
    ({
      year: direction === "end" ? addYears : subYears,
      month: direction === "end" ? addMonths : subMonths,
      day: direction === "end" ? addDays : subDays,
      hours: direction === "end" ? addHours : subHours,
      minutes: direction === "end" ? addMinutes : subMinutes,
      seconds: direction === "end" ? addSeconds : subSeconds,
    } as const);

  const DateRangePicker = useMemo(
    () => (p: DateRangePickerProps) => {
      const rawSpecificIntervalStartTime =
        dateEnd && p.interval
          ? DateFnsMethodByIntervalType("start")[p.interval.type](
              dateEnd,
              p.interval.value
            )
          : undefined;
      const rawSpecificIntervalEndTime =
        dateStart && p.interval
          ? DateFnsMethodByIntervalType("end")[p.interval.type](
              dateStart,
              p.interval.value
            )
          : undefined;

      const specificIntervalStartTime =
        rawSpecificIntervalStartTime &&
        p.type === "dateTime" &&
        p.isOnlyHourEnable
          ? addHours(rawSpecificIntervalStartTime, 1)
          : rawSpecificIntervalStartTime;

      const specificIntervalEndTime =
        rawSpecificIntervalEndTime &&
        p.type === "dateTime" &&
        p.isOnlyHourEnable
          ? subHours(rawSpecificIntervalEndTime, 1)
          : rawSpecificIntervalEndTime;

      return (
        <Box
          // className="datePickerObj"
          sx={{
            display: "flex",
            width: "100%",
            ">div": {
              width: "50%",
            },
          }}
        >
          <TimePicker
            type={p.type}
            label={p.startLabel ?? tc("start_date")}
            timeValue={dateStart}
            setTimeValue={setDateStart}
            maxStartTime={dateEnd}
            minEndTime={specificIntervalStartTime}
            views={p.view as any}
            isOnlyHourEnable={p.type === "dateTime" && p.isOnlyHourEnable}
          />
          <span style={hyphenStyle}> - </span>
          <TimePicker
            type={p.type}
            label={p.endLabel ?? tc("end_date")}
            timeValue={dateEnd}
            setTimeValue={setDateEnd}
            minEndTime={dateStart}
            maxStartTime={specificIntervalEndTime}
            views={p.view as any}
            isOnlyHourEnable={p.type === "dateTime" && p.isOnlyHourEnable}
          />
        </Box>
      );
    },
    [dateStart, dateEnd]
  );
  const localUTCdiff = Number(store.getState().profile.timezone) * -1 || 0;
  const usedUTCDiff = config.isTimezoneConvert ? localUTCdiff : 0;
  const searchEndOfTime = config.isEndOfSecond
    ? searchEndOfSecond
    : searchEndOfDay;

  const startTime = toDBTime(dateStart && addHours(dateStart, usedUTCDiff));
  const endTime = toDBTime(
    dateEnd && addHours(searchEndOfTime(dateEnd) || 0, usedUTCDiff)
  );
  const output = {
    start: startTime ? startTime.slice(0, -4) + "000Z" : "",
    end: endTime ? endTime.slice(0, -4) + "999Z" : "",
  };

  return {
    Picker: DateRangePicker,
    start: output.start,
    end: output.end,
    clearDate,
    resetDate,
  };
}
