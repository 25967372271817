import { useState } from "react";
import { useTranslate } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "../../../components/MuiGenerals";
import { uploadTransactionDetails } from "../../../api/merchant";
import { findChainInfo } from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useAlerting } from "../../../hooks";
import { IoperationTab } from "./MerchantOperation";

export default function UploadFileDialog(props: any) {
  const { setRefreshBatchTable, setUploadDialog, uploadDialog, operationTab } =
    props;

  const opeartionTab = operationTab as IoperationTab;
  enum TabEnum {
    "all" = "get_all",
    "api_withdraw_tab" = "api_withdraw",
    "sweep_tab" = "sweep",
    "portal_withdraw_tab" = "portal_withdraw",
  }

  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);
  const [fileInfo, setFileInfo] = useState({ name: "", text: "" });
  const { alerting } = useAlerting();

  const resetBroadCastInfo = () => {
    setFileInfo({
      name: "",
      text: "",
    });
    setUploadDialog({ bol: false, info: {} });
  };

  const onUploadTransaction = async (event: any, batchId: string) => {
    const reader = new FileReader();
    reader.onload = async (e: any) => {
      if (e)
        setFileInfo({
          name: event.target.files[0].name,
          text: e.target.result,
        });
    };
    reader.readAsText(event.target.files[0]);
  };

  const confirmUploadDoc = async () => {
    if (fileInfo) {
      let text = fileInfo.text;
      try {
        text = JSON.parse(text);
      } catch (e) {
        setFileInfo({
          name: "",
          text: "",
        });
        return alerting("error", t("uploadFailed"));
      }
      const onUploadResponse = await uploadTransactionDetails(
        uploadDialog.info.batch_id,
        text,
        TabEnum[opeartionTab]
      );
      if (!onUploadResponse) {
        setFileInfo({
          name: "",
          text: "",
        });
        if (onUploadResponse === false) {
          return alerting("error", t("uploadFailed"));
        }
        return;
      }
      alerting("success", t("uploadSuccess"));
      setRefreshBatchTable(true);
      resetBroadCastInfo();
    }
  };

  const showChainName = () => {
    const { chain_id, chain_type } = uploadDialog.info?.rawData || {};
    if (uploadDialog && uploadDialog.info && chain_id && chain_type) {
      const chain_name = findChainInfo(String(chain_type), chain_id)?.name;
      return chain_name;
    }
  };
  if (!uploadDialog.bol) return null;
  return (
    <>
      <Dialog
        open={uploadDialog.bol == true}
        onClose={resetBroadCastInfo}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title"> {t("sign")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("batch_id")} : {uploadDialog.info.batch_id}
          </DialogContentText>
          <br />
          <DialogContentText>
            {t("chain_name")} : {showChainName()}
          </DialogContentText>
          <br />
          <DialogContentText>
            {t("asset_name")} : {uploadDialog.info.asset_name}
          </DialogContentText>
          <br />
          <DialogContentText>
            {t("totalAmount")} : {uploadDialog.info.total_amount}
          </DialogContentText>
          <br />
          <DialogContentText>
            {t("fileUpload")} :{" "}
            {fileInfo.name != "" ? (
              <span> {fileInfo.name}</span>
            ) : (
              <Button color="secondary" variant="contained" component="label">
                {t("uploadBtn")}
                <input
                  type="file"
                  hidden
                  onChange={(e) => onUploadTransaction(e, uploadDialog.batchId)}
                />
              </Button>
            )}
          </DialogContentText>
          <br />
          <DialogContentText>{t("uploadFileDialog")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={confirmUploadDoc}
            disabled={!fileInfo.text}
          >
            {t("sign")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={resetBroadCastInfo}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
