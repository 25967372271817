import React, { Fragment, SetStateAction } from "react";
import { useTranslate } from "react-admin";
import { ChainSingleSelection } from "../../../../components/ChainSelection";
import { AssetMultiSelection } from "../../../../components/AssetSelection";
import { useAssets } from "../../../../components/FetchConfig";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
} from "../../../../components/MuiGenerals";
import GeneralBtn from "../../../../components/GeneralBtn";
import { Button } from "../../../../components/MuiGenerals";
import { MenuItem } from "../../../../components/MuiGenerals";
import MpTextField from "../../../../components/MpTextField";
import { customSx } from "../../../../utils/styling";
import { genField } from "../../../../utils/HelperComp";
import { enumOperationType } from "../../../../utils/constant";
import { FilterFieldKey, FilterFields } from "../types/filter";
import { useTranslation } from "../../../../hooks";
import { Iprefix } from "../../../../hooks/useTranslation";

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  DateObj: any;
}

interface RenderField {
  [key: string]: string;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, DateObj } = props;
  const translatePrefix = "resubmit.filter" as Iprefix;
  const { t, te } = useTranslation(translatePrefix);
  const { selectAssetNamesByChain } = useAssets();
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields[FilterFieldKey.ChainName],
  });

  const renderFields = (filterFields: RenderField) => {
    return Object.keys(filterFields).map((key, index) => {
      return <Fragment key={index}>{filterFields[key]}</Fragment>;
    });
  };

  const filterFields = genField({ t }, [
    ["created_date", <DateObj.Picker type="dateTime" />],
    [
      "operation_type",
      <FormControl>
        <InputLabel>{t("phOperation_type")}</InputLabel>
        <Select
          name="operation_type"
          value={fields[FilterFieldKey.OperationType]}
          onChange={(e) =>
            setFields({
              ...fields,
              [FilterFieldKey.OperationType]: e.target.value,
            })
          }
        >
          {Object.entries(enumOperationType).map(([name, value]) => {
            const newName = name as keyof typeof enumOperationType;
            return (
              <MenuItem key={value} value={value}>
                {te(`${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chainName) =>
          setFields({
            ...fields,
            [FilterFieldKey.ChainName]: chainName,
            [FilterFieldKey.AssetNames]: [],
          })
        }
        choice={fields[FilterFieldKey.ChainName]}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={(assetName) => {
          setFields({
            ...fields,
            [FilterFieldKey.AssetNames]: assetName,
          });
        }}
        choices={fields[FilterFieldKey.AssetNames]}
        allItems={allAssetNames}
      />,
    ],
    [
      "batch_id",
      <MpTextField
        fullWidth={true}
        name="batchId"
        label={t("phBatch_id")}
        value={fields[FilterFieldKey.BatchId]}
        onChange={(e) => {
          setFields({
            ...fields,
            [FilterFieldKey.BatchId]: e.target.value,
          });
        }}
      />,
    ],
  ]);

  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {renderFields(filterFields)}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <Button onClick={onReset} className="resetBtn" variant="contained">
          {t("reset")}
        </Button>
      </Box>
    </>
  );
};

export default FilterSection;
