import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import EnumFrozenFund from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerFrozenFund";

import { getLedgerFrozenFundExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import { NodeTypesEnumMultiSelection } from "../../../components/GeneralSelection";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance } from "../../../zustand/store";
import FrozenFundList from "./FrozenFundList";

const translatePrefix = "ledgerFrozenFund";

interface ZusParamsFace {
  chain_id: string | undefined;
  chain_type: number | undefined;
  asset_names: string[];
  statuses: string[];
  client_ids: string[];
  merchant_order_ids: string[];
  date_created_from: string;
  date_created_to: string;
  frozen_date_from: string;
  frozen_date_to: string;
  unfrozen_date_from: string;
  unfrozen_date_to: string;
  date_last_modified_from: string;
  date_last_modified_to: string;
  pageSize: number;
  page: number;
}

interface Ifields {
  chain_name: string;
  asset_names: string[];
  statuses: string[];
  client_id: string;
  merchant_order_id: string;
}
const initFields = {
  chain_name: "",
  asset_names: [] as string[],
  statuses: [] as string[],
  client_id: "",
  merchant_order_id: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;
export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);
const Pkey = FeatureCodes.ledger.FrozenFund;
export const reversedEnumFunction: (
  enumObj: Record<string, string>
) => Record<string, string> = (enumObj) =>
  Object.fromEntries(
    Object.entries(enumObj).map(([key, value]) => [value, key])
  );

export default function FrozenFund() {
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getLedgerFrozenFundExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];
    const res = listMapping("name", rawRes, { t, te, omitKeys });
    const config = {};
    downloadFiles(`Frozen Request`, res, config);
  };
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <FrozenFundList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [searchedParams] = useSearchParams();
  const initSearchParams = {
    ...initFields,
    merchant_order_id: searchedParams.get("merchant_order_id") || "",
  };
  const [fields, setFields] = useState<Ifields>(initSearchParams);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedDate: useDatePicker(),
    FrozenDate: useDatePicker(),
    UnfrozenDate: useDatePicker(),
  };
  useEffect(() => {
    if (initSearchParams.merchant_order_id) {
      zusParams.setBody({
        merchant_order_ids: [initSearchParams.merchant_order_id],
      });
    }
  }, []);
  const getParams = () => {
    const { chain_name, asset_names, statuses, ...rest } = fields;
    const transformFieldFromStrToArr = (fieldValue: string) => {
      const rawArr = fieldValue.split(",");
      const fieldValueArr = rawArr
        .filter((item) => item)
        .map((item) => item.trim());
      return fieldValueArr;
    };
    const { client_id: client_ids, merchant_order_id: merchant_order_ids } =
      Object.fromEntries(
        Object.entries(rest).map(([key, value]) => [
          key,
          transformFieldFromStrToArr(value),
        ])
      );

    const { chain_id, chain_type } = selectChainByPieces({ chain_name });

    const { CreationTime, LastModifiedDate, FrozenDate, UnfrozenDate } =
      DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;
    const { start: date_last_modified_from, end: date_last_modified_to } =
      LastModifiedDate;
    const { start: frozen_date_from, end: frozen_date_to } = FrozenDate;
    const { start: unfrozen_date_from, end: unfrozen_date_to } = UnfrozenDate;
    return {
      ...initZusParams,
      chain_id,
      chain_type,
      asset_names,
      statuses,
      client_ids,
      merchant_order_ids,
      date_created_from,
      date_created_to,
      date_last_modified_from,
      date_last_modified_to,
      frozen_date_from,
      frozen_date_to,
      unfrozen_date_from,
      unfrozen_date_to,
    };
  };

  const apiParams: ZusParamsFace = getParams();
  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedDate.clearDate();
    DateObj.FrozenDate.clearDate();
    DateObj.UnfrozenDate.clearDate();
  };

  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.LastModifiedDate.Picker type="dateTime" />],
    ["frozen_time", <DateObj.FrozenDate.Picker type="dateTime" />],
    ["unfrozen_time", <DateObj.UnfrozenDate.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",
      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextFieldMultiple
        value={fields.merchant_order_id}
        onChange={onChange("merchant_order_id")}
        label={t("ph_merchant_order_id")}
        count={apiParams.merchant_order_ids.length}
      />,
    ],
    [
      "status",
      <NodeTypesEnumMultiSelection
        label={t("status")}
        onChange={(statuses) => setFields((f) => ({ ...f, statuses }))}
        value={fields.statuses}
        enumData={EnumFrozenFund}
        isNoSorting
      />,
    ],
    [
      "client_id",
      <MpTextFieldMultiple
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
        count={apiParams.client_ids.length}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_time}
        {F.frozen_time}
        {F.unfrozen_time}
        {F.chain_name}
        {F.currency}
        {F.status}
        {F.client_id}
        {F.merchant_order_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t } = config || {};

  const res = array.map((item: any) => {
    const { name: chain_name } =
      findChainInfo(item.chain_type, item.chain_id) || {};
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      ["client_id", t("client_id"), item.client_id],
      ["chain_name", t("chain_name"), chain_name],
      ["currency", t("currency"), item.asset_name],
      [
        "frozen_amount",
        t("frozen_amount"),
        displayAmount(item.frozen_amount, decimals),
      ],
      ["status", t("status"), t(EnumFrozenFund[item.status])],
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      ["frozen_date", t("frozen_date"), toDisplayTime(item.frozen_date)],
      ["unfrozen_date", t("unfrozen_date"), toDisplayTime(item.unfrozen_date)],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
