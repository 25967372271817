import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useTranslate } from "react-admin";
import Table from "./components/Table";
import Tabs from "./components/Tabs";
import { useDatePicker } from "../../../components/DatePicker";
import Prompter from "../../../components/Prompter";
import ResubmitPrompterContent from "./components/ResubmitPrompterContent";
import { Box, Container } from "../../../components/MuiGenerals";
import FilterSection from "./components/FilterSection";
import ResubmitButton from "./components/ResubmitButton";
import { useAlerting } from "../../../hooks";
import { useZusDialogStore } from "../../../zustand/store";
import useTableColumns from "./helper/useTableColumns";
import useTableRows from "./helper/useTableRows";
import { checkIsSubmitBatchFailed } from "./helper/check-is-submit-batch-failed";
import useGetListParams from "./helper/useGetListSearchParams";
import { customSx } from "../../../utils/styling";
import { getResubmitList, resubmitTransaction } from "../../../api/resubmit";
import { FilterFieldKey, FilterFields } from "./types/filter";
import { GetListRes } from "./types/api";
import { TableColumnKey } from "./types/table";
import { TableRow } from "./types/table";

const intFields: FilterFields = {
  [FilterFieldKey.OperationType]: "",
  [FilterFieldKey.ChainName]: "",
  [FilterFieldKey.AssetNames]: [],
  [FilterFieldKey.BatchId]: "",
  [FilterFieldKey.createdDateFrom]: "",
  [FilterFieldKey.createdDateTo]: "",
};

const defaultDates = {
  start: { defaultDate: null },
  end: { defaultDate: null },
};

const ReSubmit = () => {
  const zusDialog = useZusDialogStore();
  const translate = useTranslate();
  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(intFields);
  const [selectedRowData, setSelectedRowData] = useState<TableRow>(
    {} as TableRow
  );
  const [isResubmitPrompterOpen, setIsResubmitPrompterOpen] = useState(false);

  const [apiList, setApiList] = useState<GetListRes>({
    rows: [],
    count: 0,
  });

  const initSearchParams = {
    pageSize: 20,
    page,
    // operation_creator_filter: 0,
  };
  const { getListSearchParams } = useGetListParams({
    defaultParams: initSearchParams,
  });

  const [searchParams, setSearchParams] = useState(getListSearchParams(fields));

  const { rows } = useTableRows({ apiList });
  const { columns } = useTableColumns({ renderCellFunc });
  const DateObj = useDatePicker(defaultDates);
  const { alerting } = useAlerting();
  const rowCount = apiList.count;

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldKey.createdDateFrom]: DateObj.start,
        [FilterFieldKey.createdDateTo]: DateObj.end,
      };
    });
  }, [DateObj.start, DateObj.end]);

  function renderCellFunc(params: any) {
    const isBtnDisabled = !checkIsSubmitBatchFailed(params.row.rawData.status);

    const handleClick = () => {
      setIsResubmitPrompterOpen(true);
      setSelectedRowData(params.row);
    };

    return <ResubmitButton onClick={handleClick} isDisabled={isBtnDisabled} />;
  }

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: GetListRes) => {
    if (!data) return;
    setApiList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra("loadingDialog");
    const { currentTime, ...cleanParams } = searchParams;
    return getResubmitList(cleanParams);
  };

  useQuery(["getResubmitList", searchParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  const onFilterSearch = () => {
    setPage(0);
    setSearchParams({
      ...getListSearchParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(intFields);
    DateObj.clearDate();
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setSearchParams({ ...searchParams, page });
  };

  const refreshTable = () => {
    setPage(0);
    setSearchParams({ ...searchParams, page: 0, currentTime: Date.now() });
  };

  const handleResubmitYes = async () => {
    const payload = {
      batch_id: selectedRowData[TableColumnKey.BatchId],
    };
    const res = await resubmitTransaction(payload);

    if (res) {
      alerting("success", translate("resubmit.alert.success"));
      refreshTable();
    }
  };

  return (
    <>
      <Prompter
        title={translate("resubmit.prompter.title")}
        open={isResubmitPrompterOpen}
        onClose={() => setIsResubmitPrompterOpen(false)}
        content={
          <ResubmitPrompterContent
            batch_id={selectedRowData[TableColumnKey.BatchId]}
            operation_type={selectedRowData[TableColumnKey.OperationType]}
            chain_name={selectedRowData[TableColumnKey.ChainName]}
            asset_name={selectedRowData[TableColumnKey.AssetName]}
            total_amount={selectedRowData[TableColumnKey.TotalAmount]}
          />
        }
        onYes={handleResubmitYes}
      />
      <Box sx={customSx.layoutBox}>
        <Container maxWidth={false} disableGutters>
          <FilterSection
            fields={fields}
            setFields={setFields}
            onSearch={onFilterSearch}
            onReset={onFilterReset}
            DateObj={DateObj}
          />
        </Container>
        <Container maxWidth={false} disableGutters>
          <Tabs>
            <Table
              columns={columns}
              rows={rows}
              rowCount={rowCount}
              page={page}
              onPageChange={onPageChange}
            />
          </Tabs>
        </Container>
      </Box>
    </>
  );
};

export default ReSubmit;
