import { useTranslate } from "react-admin";
import { useEffect } from "react";
import { useAlerting } from "../hooks";
import { decodeJwt } from "../utils/helper";
import { store } from "../reducer/store";
import { setRedirectTo } from "../reducer/alerterSlice";

const AutoLogoutController = () => {
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const autoLogoutReason = translate("general.autoLogoutAlert");
  const logoutReason = translate("general.logoutAlert");

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const isAccessTokenChanged = e.key === "accessToken";

      if (isAccessTokenChanged) {
        let oldTokenInfo;
        let newTokenInfo;

        if (e.oldValue) {
          oldTokenInfo = decodeJwt(e.oldValue);
        }

        if (e.newValue) {
          newTokenInfo = decodeJwt(e.newValue);
        }

        const { email: oldEmail, appUuid: oldAppUuid } = oldTokenInfo || {};
        const { email: newEmail, appUuid: newAppUuid } = newTokenInfo || {};

        const isAccessTokenRemoved =
          e.oldValue && (!e.newValue || e.newValue === "");

        const isNewLogin = Boolean(!e.oldValue && e.newValue);

        const isDiffUser =
          !isAccessTokenRemoved && !isNewLogin && oldEmail !== newEmail;

        const isDiffMerchant =
          !Boolean(!oldAppUuid && newAppUuid) && oldAppUuid !== newAppUuid;

        const getAlertReason = () => {
          if (isDiffUser) {
            return autoLogoutReason;
          }

          return logoutReason;
        };

        if (isDiffMerchant || isDiffUser || isAccessTokenRemoved) {
          const reason = getAlertReason();
          alerting("error", reason, {
            autoHideDuration: null,
          });
          store.dispatch(setRedirectTo("/login"));
        }
      }
    });
  }, []);

  return <></>;
};

export default AutoLogoutController;
