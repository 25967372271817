import en from "./en";
import zh_cn from "./zh_cn";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { store } from "../../reducer/store";

export type Ilocale = "en" | "zh_cn";
const defaultLanguage = store.getState().profile.lang || "zh_cn";
const translations: Record<Ilocale, any> = { en, zh_cn };
export default polyglotI18nProvider(
  (locale) => translations[locale as Ilocale],
  defaultLanguage,
  {
    allowMissing: true,
  }
);
