import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FeatureCode } from "@wallet-manager/node-types";

import {
  postLedgerTransferRequestApprove,
  postLedgerTransferRequestReject,
  postLedgerWithdrawRequestApprove,
  postLedgerWithdrawRequestReject,
} from "../../api/merchant";
import CopyableText from "../../components/CopyableText";
import GridBox from "../../components/GridBox";
import { Container } from "../../components/MuiGenerals";
import YubiPrompter from "../../components/Prompter";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import { customSx } from "../../utils/styling";
import { useZusDialogStore } from "../../zustand/store";
import { useZusParams as useZusTransferRequestParams } from "./TransferRequest/TransferRequest";
import { useZusParams as useZusWithdrawRequestParams } from "./WithdrawRequest/WithdrawRequest";

const approvePkeyObj = {
  WithdrawRequest: FeatureCode.FeaturesLedger.WithdrawRequest.Approve,
  TransferRequest: FeatureCode.FeaturesLedger.TransferRequest.Approve,
};
const rejectPkeyObj = {
  WithdrawRequest: FeatureCode.FeaturesLedger.WithdrawRequest.Reject,
  TransferRequest: FeatureCode.FeaturesLedger.TransferRequest.Reject,
};

const approveApiObj = {
  WithdrawRequest: postLedgerWithdrawRequestApprove,
  TransferRequest: postLedgerTransferRequestApprove,
};
const rejectApiObj = {
  WithdrawRequest: postLedgerWithdrawRequestReject,
  TransferRequest: postLedgerTransferRequestReject,
};
enum EnumTranslatePrefix {
  WithdrawRequest = "ledgerWithdrawRequest",
  TransferRequest = "ledgerTransferRequest",
}

export default function ApprovalDialog(props: {
  disableFullWidth?: boolean;
  maxWidth?: false | "sm" | "md" | "lg" | "xl";
}) {
  const { alerting } = useAlerting();
  const { email } = useAppSelector(selectProfile);
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { page } = zusDialog.meta || {};
  const typedPage: "WithdrawRequest" | "TransferRequest" = page;
  const zusTransferRequestParams = useZusTransferRequestParams();
  const zusWithdrawRequestParams = useZusWithdrawRequestParams();
  const { disableFullWidth, maxWidth } = props;
  const { t, tc } = useTranslation(EnumTranslatePrefix[typedPage]);
  const { getResAfterYubiWithRemarks, prompterConfig } = useYubiPrompt();

  const { apiParams } = zusDialog.meta || {};
  const { request_id, created_by, approval_completed } = apiParams || {};

  const clickHandler = async (direction: "approve" | "reject") => {
    if (direction === "approve") {
      if (!hasPermission(approvePkeyObj[typedPage])) {
        zusDialog.close();
        return alerting("error", tc("alert_no_permission"));
      }
      if (email === created_by) {
        zusDialog.close();
        return alerting("error", tc("alert_creator_approver_same"));
      }
    } else {
      if (!hasPermission(rejectPkeyObj[typedPage])) {
        zusDialog.close();
        return alerting("error", tc("alert_no_permission"));
      }
    }

    const res = await getResAfterYubiWithRemarks(
      direction === "approve"
        ? approveApiObj[typedPage]
        : rejectApiObj[typedPage],
      {
        request_id,
        approval_completed:
          direction === "approve" ? approval_completed : undefined,
      }
    );

    if (!res) {
      return;
    }

    alerting(
      "success",
      t(direction === "approve" ? "approve_success" : "reject_success")
    );

    zusDialog.close();
    if (typedPage === "TransferRequest") {
      zusTransferRequestParams.refetch();
    } else {
      zusWithdrawRequestParams.refetch();
    }
  };

  const cloneZusMeta = { ...zusDialog.meta };

  delete cloneZusMeta.apiParams;
  delete cloneZusMeta.page;

  const gridBoxContent = Object.entries(cloneZusMeta).map<
    [string, JSX.Element]
  >(([key, value]) => {
    if (key.includes("address")) {
      return [key, <CopyableText text={value} />];
    }
    return [key, value];
  });

  if (!zusDialog.match("operationDialog")) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={zusDialog.match("operationDialog")}
        onClose={zusDialog.close}
        fullWidth={!disableFullWidth && true}
        maxWidth={maxWidth !== undefined ? maxWidth : "sm"}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle>{t("approval")}</DialogTitle>
        <DialogContent sx={{ padding: "0px 32px !important" }}>
          <DetailContent gridBoxContent={gridBoxContent} />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          {
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => clickHandler("approve")}
              >
                {tc("approve")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => clickHandler("reject")}
              >
                {tc("reject")}
              </Button>
            </>
          }
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              zusDialog.close();
            }}
          >
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function DetailContent(props: {
  gridBoxContent: Array<[string, JSX.Element]>;
}) {
  const { gridBoxContent } = props;
  const zusDialog = useZusDialogStore();
  const { page } = zusDialog.meta || {};
  const translatePrefix =
    EnumTranslatePrefix[page as keyof typeof EnumTranslatePrefix];
  return (
    <Container disableGutters maxWidth={false}>
      <GridBox
        translatePrefix={translatePrefix}
        data={gridBoxContent}
        itemSx={{ ">div": { flex: "none !important" } }}
      />
    </Container>
  );
}
