import { useState } from "react";
import { useTranslate } from "react-admin";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import {
  enumAdjustmentStatus,
  enumWalletType,
  dataGridDefaults,
  FeatureCodes,
} from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import {
  displayAmount,
  findChainInfo,
  getDecimal,
  toDisplayTime,
} from "../../utils/helper";
import { checkCurrentBalanceApi, listFace } from "../../api/merchant";
import { useAlerting, usePermission } from "../../hooks";
import { Button } from "@mui/material";
import { NoRowsOverlay } from "../NoRowsOverlay";
import { ApprovalDialogTable } from "./ApprovalDialogTable";
import { CustomPagination } from "../CustomPagination";
import { useAssets } from "../../components/FetchConfig";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
const Pkey = FeatureCodes.walletManagement.WalletBalanceAdjustment;
interface propsFace {
  list: listFace;
  page: number;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  setFreshTableBol: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WalletBalanceAdjustmentList(props: propsFace) {
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [approvalDialogBol, setApprovalDialogBol] = useState(false);
  const [tableCellsObject, settableCellsObject] = useState<any>({});
  const { list, setPage, page, setFreshTableBol } = props;
  const { selectAssetsByChain } = useAssets();
  const totalRecords = list.count;
  const content: GridRowsProp = (list.rows || []).map(
    (item: any, id: number) => {
      const decimal = getDecimal(item);
      return {
        ...item,
        proposal_no: item?.id,
        chain_name: findChainInfo(String(item?.chain_type), item?.chain_id)
          ?.name,
        wallet_type:
          item.wallet_type == null
            ? ""
            : translate(
                `enumConstants.${enumWalletType[String(item.wallet_type)]}`
              ),
        display_name: item?.wallet_name?.toLocaleString(),
        amount: displayAmount(item?.amount, decimal),
        status: t(`${enumAdjustmentStatus[String(item.status)].toLowerCase()}`),
        created_by_email: item?.created_by_email,
        created_date: toDisplayTime(item.date_created),
        approved_user: item?.approved_by_email,
        approved_date:
          item?.status === 0 ? "" : toDisplayTime(item?.date_approved),
        id,
      };
    }
  );

  const openOperations = async (params: any) => {
    const { chain_type, chain_id, asset_name, wallet_address, ...rest } =
      params.row;
    const assets = selectAssetsByChain({ chain_type, chain_id });
    const selectedAsset = assets.find((item) => item.asset_name === asset_name);
    if (!selectedAsset) return alerting("error", t("assetNotSupported"));
    const decimals = selectedAsset.decimals;
    const arg = { chain_type, chain_id, asset_name, wallet_address };
    const checkCurrentBalanceApiResponse = await checkCurrentBalanceApi(arg);
    const balance =
      checkCurrentBalanceApiResponse === null
        ? 0
        : checkCurrentBalanceApiResponse?.balance;
    const walletBalance = displayAmount(balance, decimals);
    params.row.currentWalletBalance = walletBalance;
    setApprovalDialogBol(true);
    settableCellsObject(params.row);
  };

  const isBtnShown = hasPermission(Pkey.Approve) || hasPermission(Pkey.Reject);

  const cellButton = (name: string) => (params: any) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        onClick={() => openOperations(params)}
        disabled={params.row.status === t(`pending`) ? false : true}
      >
        {name}
      </Button>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("proposal_no", t("approvalProposalNo")),
    useGenGridCol("wallet_name", t("wallet_name")),
    useGenGridCol("wallet_type", t("wallet_type")),
    useGenGridCol("wallet_address", t("walletAddress")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("amount", t("adjustment_amount")),
    useGenGridCol("status", t("approval_status")),
    useGenGridCol("created_by_email", t("created_by_email")),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("approved_user", t("approved_user")),
    useGenGridCol("approved_date", t("approved_date")),
    useGenGridCol("remark", t("remark")),
    useGenGridCol("2", t("operation"), {
      renderCell: cellButton(t("approveOrReject")),
      minWidth: 170,
    }),
  ].filter((col) => {
    if (col.field === "2" && !isBtnShown) {
      return false;
    }
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
      <ApprovalDialogTable
        tableCellsObject={tableCellsObject}
        approvalDialogBol={approvalDialogBol}
        setApprovalDialogBol={setApprovalDialogBol}
        setFreshTableBol={setFreshTableBol}
      />
    </>
  );
}
