import { styled } from "@mui/material";
export const customSx = {
  cryptoAmount: {
    width: "5rem",
  },
  dialogBtnMargin: {
    margin: "0px 10px 10px 0px",
  },
  layoutBox: {
    backgroundColor: "white",
    padding: "2rem",
  },
  customeLayoutBox: {
    margin: "0px 0px 10px 0px",
    backgroundColor: "white",
    padding: "0",
  },
  filterContainer: {
    padding: "1rem",
  },
  datagridContainer: {
    backgroundColor: "white",
    paddingTop: "1rem",
  },
  filterCmdBar: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    marginBottom: "1rem",
    "& .MuiButton-root": {
      color: "black",
      borderColor: "#AAAAAA",
    },
  },

  tableExportBar: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
    marginBottom: 0,
    height: 0,
    position: "relative",
    "& .MuiButton-root": {
      color: "black",
      borderColor: "#AAAAAA",
      position: "absolute",
      top: 8.7,
      ":hover": {
        border: "1px solid #1976d2",
        backgroundColor: "rgba(25, 118, 210, 0.04)",
      },
    },
  },
  gridFilter: {
    display: "grid",
    gridTemplateColumns:
      "minmax(max-content, 5rem) 3fr minmax(max-content, 5rem) 3fr",
    // alignItems: "center",
    lineHeight: "4rem",
    columnGap: "2rem",
    "& *": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiInputAdornment-filled": {
      overflow: "initial",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .datePickerObj": {
      display: "inline",
      "& .MuiFormControl-root": {
        width: "calc(50% - 1rem)",
      },
    },
  },
  threeColumnsgridFilter: {
    display: "grid",
    gridTemplateColumns: "3fr 5fr 3fr 2fr 3fr 4fr",
    lineHeight: "4rem",
    columnGap: "1rem",
    "& *": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .MuiInputAdornment-filled": {
      overflow: "initial",
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .datePickerObj": {
      display: "inline",
      "& .MuiFormControl-root": {
        width: "calc(50% - 1rem)",
      },
    },
  },
  filterB: {
    display: "flex",
    justifyContent: "center",
    gap: "2rem",
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  dateSelect: {
    display: "flex",
    gap: "1rem",
    "& .MuiFormControl-root": {
      flex: 1,
    },
  },
  tableCell: {
    width: "450px",
    borderBottom: "none",
  },
  addressValue: {
    color: "#4A90F7",
    cursor: "pointer",
    textDecoration: "underline",
  },
  tableRow: {
    backgroundColor: "#EAEAEA",
    height: "40px",
  },
  operation: {
    btnMargin: {
      margin: "20px 5px 0px 20px",
    },
    operationActionBtnMargin: {
      textTransform: "none",
      margin: "0px 5px",
      borderRadius: "15px",
      padding: "5px",
      minWidth: "90px",
      height: "25px",
      fontSize: "9px",
    },
  },
  dataGrid: {
    "*::-webkit-scrollbar": {
      height: "7px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "gray",
      borderRadius: "10px",
    },
  },
};

export const ContentWithSidebar = styled("main")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
}));

export const Content = styled("div")(({ theme }) => ({
  "*::-webkit-scrollbar": {
    height: "7px",
  },
  "*::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
    borderRadius: "10px",
  },
  display: "flex",
  flexDirection: "column",
  flexGrow: 2,
  padding: theme.spacing(4),
  paddingTop: "120px",
  "& .MuiInputBase-root": {
    backgroundColor: "white",
  },
  "& .MuiInputLabel-shrink": {
    display: "none",
  },
  "& .resetBtn": {
    color: "white",
    backgroundColor: "grey",
  },
  "& .MuiTypography-root": {
    lineHeight: 1,
  },
  "& .filterFieldInput": {
    lineHeight: "3rem",
  },
  "& .MuiFormControl-root": {
    marginTop: 0,
    marginBottom: 0,
  },
}));
