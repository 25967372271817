import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { FeatureCode } from "@wallet-manager/node-types";

import { getDepositAndWithdrawalStatisticsExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  Box,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import {
  useAlerting,
  useDateHourRangeSelection,
  usePermission,
  useTranslation,
} from "../../../hooks";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance } from "../../../zustand/store";
import LedgerTransactionRecordList from "./DepositAndWithdrawalStatisticsList";

const translatePrefix = "depositAndWithdrawalStatistics";

const Pkey = FeatureCode.FeaturesReport.DepositAndWithdrawalStatistics;

interface Ifields {
  chain_name: string;
  asset_names: string[];
}
interface ZusParamsFace {
  chain_type: number | undefined;
  chain_id: string | undefined;
  asset_names: string[];
  block_time_from: string;
  block_time_to: string;
  pageSize: number;
  page: number;
}

const initFields = {
  chain_name: "",
  asset_names: [] as string[],
} as const;

const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;

export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);

export default function DepositAndWithdrawalStatistics() {
  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const { t, tc, te } = useTranslation(translatePrefix);
  const [count, setCount] = useState(0);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getDepositAndWithdrawalStatisticsExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];

    const res = listMapping(
      "name",
      rawRes.map((value) => ({
        ...value,
        block_time_from: zusParams.body.block_time_from,
        block_time_to: zusParams.body.block_time_to,
      })),
      { t, te, omitKeys }
    );
    const config = {};
    downloadFiles(`Deposit and Withdrawal Statistics`, res, config);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setIsFirstRender={setIsFirstRender}
          isFirstRender={isFirstRender}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <LedgerTransactionRecordList
            {...{ listMapping, setCount, isFirstRender }}
          />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar({
  setIsFirstRender,
  isFirstRender,
}: {
  setIsFirstRender: Dispatch<SetStateAction<boolean>>;
  isFirstRender: boolean;
}) {
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();

  const { alerting } = useAlerting();

  const current = new Date();
  const currentDateStr = current.toDateString();
  const currentPlus1HrIsoStr =
    current.toISOString().split(":")[0] + ":59:59.999Z";

  const currentDate12am = new Date(currentDateStr + " 00:00:00");

  const currentDate12amIsoStr = currentDate12am.toISOString();

  const currentPlus1Hr = new Date(currentPlus1HrIsoStr);

  const [fields, setFields] = useState<Ifields>(initFields);

  const {
    DateHrRangeSelection,
    resetDateHr,
    dateHrStartValue,
    dateHrEndValue,
  } = useDateHourRangeSelection({
    defaultValues: { start: currentDate12am, end: currentPlus1Hr },
    limitInterval: { type: "days", value: 3, upperLimit: new Date() },
    isTimezoneConvert: true,
    isDisabledFuture: true,
    includesUpcomingHr: true,
  });

  const getParams: () => ZusParamsFace = () => {
    const { asset_names, chain_name } = fields;

    const { chain_type, chain_id } = selectChainByPieces({ chain_name });

    return {
      ...initZusParams,
      chain_id,
      chain_type,
      asset_names,
      block_time_from: dateHrStartValue,
      block_time_to: dateHrEndValue,
    };
  };

  const apiParams = getParams();

  const onChange = (field: keyof Ifields) => (e: SelectChangeEvent<string>) => {
    setFields((f) => ({ ...f, [field]: e.target.value }));
  };
  const onClearValue = (field: keyof Ifields) => () => {
    setFields((f) => ({ ...f, [field]: "" }));
  };

  const onSearch = () => {
    if (!apiParams.block_time_from || !apiParams.block_time_to) {
      return alerting("warning", t("block_time_warning"));
    }
    if (isFirstRender) {
      setIsFirstRender(false);
    }
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    resetDateHr();
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["block_time", <DateHrRangeSelection />],

    // [
    //   "block_time_from",
    //   <DateHourSelectionStart
    //     setDateHour={(blockTimeFrom) =>
    //       setFields((f) => ({ ...f, blockTimeFrom }))
    //     }
    //   />,
    // ],
    // [
    //   "block_time_to",
    //   <DateHourSelectionEnd
    //     setDateHour={(blockTimeTo) => setFields((f) => ({ ...f, blockTimeTo }))}
    //   />,
    // ],

    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",
      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.block_time}
        {/* {F.block_time_from}
        {F.block_time_to} */}
        {F.chain_name}
        {F.currency}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    const chainInfo = findChainInfo(item.chain_type, item.chain_id);
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      [
        "block_time",
        t("block_time"),
        toDisplayTime(item.block_time_from) +
          " - " +
          toDisplayTime(item.block_time_to),
      ],
      ["chain_name", t("chain_name"), chainInfo?.name],
      ["currency", t("currency"), item.asset_name],
      [
        "deposit_number_of_transactions",
        t("deposit_number_of_transactions"),
        item.deposit_count,
      ],
      [
        "deposit_total_transaction_amount",
        t("deposit_total_transaction_amount"),
        displayAmount(item.deposit_amount, decimals),
      ],
      [
        "withdraw_number_of_transactions",
        t("withdraw_number_of_transactions"),
        item.withdraw_count,
      ],
      [
        "withdraw_total_transaction_amount",
        t("withdraw_total_transaction_amount"),
        displayAmount(item.withdraw_amount, decimals),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
