import api from "@wallet-manager/node-types/dist/src/access/APIEndpoints/index";
import { Direction } from "@wallet-manager/node-types/dist/src/postgres/const";

import { alerting } from "../reducer/alerterSlice";
import { OneAssetFace } from "../reducer/assetsSlice";
import { ChainsFace } from "../reducer/chainsSlice";
import { store } from "../reducer/store";
import axios from "./axiosInstance";
import {
  IFreezeRequestRes,
  IFrozenFundRes,
  IgetWithdrawRequestParams,
  IlistResult,
  IoperationDetailsRes,
  Ipage,
  IsignerGetOperation,
} from "./types";

const { EPtReport } = api;

//deprecated, import {IlistResult} instead
export interface listFace {
  rows: any[];
  count: number;
  withdraw_checking_time?: string;
  chain_type?: string;
  chain_id?: string;
}

export async function resendOpBatchId(
  input: {
    batch_id: number | string;
  },
  config?: any
) {
  return await axios.post<any, listFace>(
    `merchant_portal/resend_batch_operation_status
    `,
    input
  );
}
//get from_index, to_index, wallet_version
export async function getChainIndexDetails(params: any, config?: any) {
  const { chain_id, chain_type, wallet_type } = params;
  const input = {
    params: {
      chain_id: chain_id,
      chain_type: chain_type,
      wallet_type: wallet_type,
    },
    ...config,
  };

  return await axios.get<never, any>(
    `merchant_portal/merchant_chain_configs/get_chain_config_with_index_details`,
    input
  );
}
export async function getGeneratedAddressList(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/merchant_chain_configs/get_all_with_address_count`,
    input
  );
}
export async function confirmFailureWithStuckingBatchid(
  batch_id: string | number,
  config?: any
) {
  const input = { batch_id, ...config };
  return await axios.post<any, IlistResult<any>>(
    `/merchant_portal/cancel_stuck_batch`,
    input
  );
}
export async function getPortalAuditLog(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/audit_log/audit_log_jwt`,
    input
  );
}
export async function getAssetList(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/assets/management`,
    input
  );
}
export async function getApiInLog(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/audit_log/api_in`,
    input
  );
}
export async function getApiOutLog(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/audit_log/api_out`,
    input
  );
}
export async function getAuditLogWithReqId(
  params: {
    pageSize: number;
    page: number;
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/audit_log/get_audit_log_with_req_id`,
    input
  );
}

export async function getWalletManagement(
  params: any,
  config?: any
): Promise<any> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  const res = await axios.get<IlistResult<any>>(
    `merchant_portal/query/wallets`,
    input
  );
  return res;
}

export async function getLedgerBalance(
  params: any,
  config?: any
): Promise<any> {
  const input = {
    params,
    ...config,
  };

  const res = await axios.get<IlistResult<any>>(
    `merchant_portal/query/ledger_balance`,
    input
  );
  return res;
}

export async function getAddressBookProposals(
  params: any,
  config?: any
): Promise<any> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  const res = await axios.get<IlistResult<any>>(
    `merchant_portal/address_book_proposals`,
    input
  );
  return res;
}
export async function createAddressBookProposals(params: any) {
  return await axios.post(
    `merchant_portal/address_book_proposals/register`,
    params
  );
}
export async function editAddressBookProposals(params: any) {
  return await axios.post(
    `merchant_portal/address_book_proposals/update`,
    params
  );
}

export async function approveAddressBookProposals(
  params: { otp: string; id: number },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/address_book_proposals/approve`,
    input
  );
}

export async function rejectAddressBookProposals(
  params: { otp: string; id: number },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/address_book_proposals/reject`,
    input
  );
}
export async function deleteAddressBookWallet(params: any) {
  return await axios.post(
    `/merchant_portal/address_book_proposals/delete`,
    params
  );
}

export async function getWalletBalance(
  params: any,
  config?: any
): Promise<any> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  const res = await axios.get<IlistResult<any>>(
    `merchant_portal/wallet_balances`,
    input
  );
  return res;
}

//this Api is used for batch_withdraw to get enable list and general assetList
export async function fetchAllAssets(status?: string) {
  const input = { params: { status: status } };
  const res = await axios.get<never, OneAssetFace[]>(
    `merchant_portal/query/assets`,
    input
  );
  return res;
}

export async function fetchAllChains() {
  const res = await axios.get<never, ChainsFace[]>(
    `merchant_portal/query/chains`
  );
  return res;
}

export async function batchSweepPreview(
  params: any,
  config?: any
): Promise<any> {
  const input = { ...params, ...config };
  const { gather_address, ...rest } = input;
  const newInput = { ...rest, gether_address: gather_address, preview: true };
  const notNumber = Number.isNaN(Number(newInput?.decimals));
  if (notNumber) {
    store.dispatch(alerting("warning", "asset not supported"));
    return;
  }

  const res = await axios.post(`merchant_portal/batch_sweep`, newInput);
  return res;
}
export async function batchSweep(params: any, config?: any): Promise<any> {
  const input = { ...params, ...config };
  const { gather_address, ...rest } = input;
  const newInput = { ...rest, gether_address: gather_address };
  const notNumber = Number.isNaN(Number(newInput?.decimals));
  if (notNumber) {
    store.dispatch(alerting("warning", "asset not supported"));
    return;
  }

  const res = await axios.post(`merchant_portal/batch_collect`, newInput);
  return res;
}
export async function batchWithdraw(params: any, config?: any): Promise<any> {
  const input = { ...params, ...config };
  const notNumber = Number.isNaN(Number(input?.decimals));
  if (notNumber) {
    store.dispatch(alerting("warning", "asset not supported"));
    return;
  }
  const res = await axios.post(`merchant_portal/batch_withdraw`, input);
  return res;
}

export async function createWalletAddress(params: any) {
  return await axios.post(
    `merchant_portal/wallet_management/get_address`,
    params
  );
}

export async function getSweepRequest(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get("merchant_portal/sweep_requests", input);
}

export async function getWithdrawRequest(
  params: IgetWithdrawRequestParams & Ipage,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link = "merchant_portal/withdraw_requests";
  return await axios.get<never, IgetWithdrawRequestRes>(link, input);
}

export async function getSettingRequest(params?: any, config?: any) {
  const input = { ...params, ...config };
  return await axios.get<never, any>(
    "merchant_portal/query/merchant/setting",
    input
  );
}

export async function updateWithdrawSettingRequest(params: any, config?: any) {
  const input = { ...params, ...config };
  return await axios.post(
    "merchant_portal/asset_management/update_withdraw_setting",
    input
  );
}

type IgetWithdrawRequestRes = IlistResult<{
  id: string;
  merchant_id: string;
  batch_id: number;
  chain_type: number;
  chain_id: string;
  asset_name: string;
  total_amount: string;
  total_count: string;
  hot_wallet_address: string;
  client_data: string;
  created_date: string;
  last_modified_date: string;
  batch: {
    id: string;
    merchant_id: string;
    chain_type: number;
    chain_id: string;
    operation_type: number;
    total_operation: number;
    total_step: number;
    current_step: number;
    status: number;
    created_date: string;
    last_modified_date: string;
    created_by: string;
    creator_type: number;
  };
}>;
export async function getMerchantReport(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link = "/merchant_portal/wallet_balance_snapshots/merchant_wallets";
  return await axios.get(link, input);
}
export async function getMerchantRangeReport(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link =
    "/merchant_portal/wallet_balance_snapshots/merchant_wallets/by_mark_date";
  return await axios.get(link, input);
}
export async function getMerchantReportByWallet(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link =
    "/merchant_portal/wallet_balance_snapshots/merchant_wallets/by_wallet_type";
  return await axios.get(link, input);
}
export async function getClientReport(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link =
    "/merchant_portal/wallet_balance_snapshots/merchant_client_wallets";
  return await axios.get(link, input);
}

export async function getClientRangeReport(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link =
    "merchant_portal/wallet_balance_snapshots/merchant_client_wallets/by_mark_date";
  return await axios.get(link, input);
}
export async function getClientReportByWallet(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link =
    "/merchant_portal/wallet_balance_snapshots/merchant_client_wallets/by_wallet_type";
  return await axios.get(link, input);
}

export async function getDepositAndWithdrawalStatistics(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  const link = EPtReport.EndPtDepositAndWithdrawalStatistics.getAll;
  return await axios.get(link, input);
}
export async function getDepositAndWithdrawalStatisticsExport(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  const link = EPtReport.EndPtDepositAndWithdrawalStatistics.export;

  return await axios.get(link, input);
}

export async function getTransferTransactionsRecord(params: any, config?: any) {
  const { pageSize, page, startDate, endDate, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/transfer_transactions`, input);
}

export const rescanRiskLevel = async (
  data: {
    chain_id: string | number;
    chain_type: string | number;
    block_hash: string;
    tx_hash: string;
    direction: Direction;
  },
  config?: any
) => {
  const url = "/merchant_portal/report/all_transaction/rescan_risk_level";
  const payload = {
    ...data,
    ...config,
  };

  return axios.post(url, payload);
};

export async function setDefaultMerchantWallet(params: any, config?: any) {
  const input = { ...params, ...config };
  return await axios.post(
    `merchant_portal/wallet_management/set_default_wallet`,
    input
  );
}

export async function getWalletOperations(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(`merchant_portal/wallet_operations`, input);
}
export async function getStuckTokenHistory(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<any>>(
    `/merchant_portal/stuck_batch_request/get_all_groups`,
    input
  );
}

export async function getOperationTransaction(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/wallet_operation_transactions`,
    input
  );
}
export async function getNonceToolNonceTab(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/wallet_operation_batches/get_all_with_nonce_and_from_address`,
    input
  );
}
export async function getClientBalance(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_balance/get_all`, input);
}
export async function getClientBalanceExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_balance/export`, input);
}
export async function getClientManagement(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_client/get_all`, input);
}
export async function getClientManagementExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_client/export`, input);
}
export async function postClientManagementAddClient(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(`merchant_portal/ledger_client/create_client`, input);
}
export async function postClientManagementEditClient(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(`merchant_portal/ledger_client/update_client`, input);
}

export async function getLedgerTransactionRecord(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_transaction/get_all`, input);
}
export async function getLedgerTransactionRecordExport(
  params: any,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(`merchant_portal/ledger_transaction/export`, input);
}

export async function postLedgerTransactionRecordResend(
  params: {
    merchant_order_id: string;
    trans_id: string;
    include_original_merchant_order_id: boolean;
    direction: string;
  },
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_rebroadcast_transaction_event`,
    input
  );
}

export async function getWalletManagerDepositRecord(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_wallet_manager_deposit/get_all`,
    input
  );
}
export async function getWalletManagerDepositRecordExport(
  params: any,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_wallet_manager_deposit/export`,
    input
  );
}
export async function getLedgerWithdrawRequest(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_withdraw_request/get_all`,
    input
  );
}
export async function getLedgerWithdrawRequestApprovalDetail(
  params: any,
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_withdraw_request/approval_details`,
    input
  );
}
export async function getLedgerWalletManagerDepositRecordApprovalDetail(
  params: any,
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_wallet_manager_deposit/approval_details`,
    input
  );
}
export async function getLedgerWalletManagerDepositRecordSwitchDetail(
  params: any,
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_wallet_manager_deposit/switch_approval_details`,
    input
  );
}
export async function getLedgerWalletManagerDepositRecordToDoCount(
  config?: any
) {
  const input = {
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_wallet_manager_deposit/get_to_do_count`,
    input
  );
}
export async function getLedgerTransferRequestApprovalDetail(
  params: any,
  config?: any
) {
  const input = {
    params,
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_transfer_request/approval_details`,
    input
  );
}
export async function getLedgerWithdrawRequestExport(
  params: any,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_withdraw_request/export`,
    input
  );
}
export async function getLedgerTransferRequest(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_transfer_request/get_all`,
    input
  );
}
export async function getLedgerTransferRequestExport(
  params: any,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(
    `merchant_portal/ledger_transfer_request/export`,
    input
  );
}
export async function postLedgerTransferRequestCreate(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_transfer_request/create_transfer_request`,
    input
  );
}
export async function postLedgerWithdrawRequestCreate(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_withdraw_request/create_withdraw_request`,
    input
  );
}
export async function postLedgerTransferRequestApprove(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_transfer_request/approve_transfer_request`,
    input
  );
}
export async function postLedgerTransferRequestReject(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_transfer_request/reject_transfer_request`,
    input
  );
}
export async function postLedgerWithdrawRequestApprove(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_withdraw_request/approve_withdraw_request`,
    input
  );
}
export async function postLedgerWithdrawRequestReject(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_withdraw_request/reject_withdraw_request`,
    input
  );
}
export async function postLedgerWalletManagerDepositRecordSwitch(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_wallet_manager_deposit/accept_deposit`,
    input
  );
}
export async function postLedgerWalletManagerDepositRecordApprove(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_wallet_manager_deposit/approve_deposit_request`,
    input
  );
}
export async function postLedgerWalletManagerDepositRecordReject(
  params: any,
  config?: any
) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post(
    `merchant_portal/ledger_wallet_manager_deposit/reject_deposit_request`,
    input
  );
}

export async function getAdjustBalanceTable(
  params: any,
  config?: any
): Promise<IlistResult<any>> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  const link = "/merchant_portal/balance_adjustment_proposals";
  return await axios.get(link, input);
}

export async function checkCurrentBalanceApi(
  params: any,
  config?: any
): Promise<any> {
  const { ...rest } = params;
  const input = {
    params: { ...rest },
    ...config,
  };
  const link = "/merchant_portal/query/wallet_balance";
  return await axios.get(link, input);
}

export async function adjustBalance(params: any, config?: any): Promise<any> {
  const input = { ...params, ...config };
  return await axios.post(
    `merchant_portal/balance_adjustment_proposals/create`,
    input
  );
}

export async function approveAdjustBalance(
  params: { id: string; otp: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(
    `merchant_portal/balance_adjustment_proposals/approve`,
    input
  );
}

export async function rejectAdjustBalance(
  params: { id: string; otp: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post(
    `merchant_portal/balance_adjustment_proposals/reject`,
    input
  );
}

export async function getMessages(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get(`merchant_portal/messages`, input);
}

export async function walletOperationBatchsCount(
  params: {
    pageSize: number;
    page: number;
    chain_type?: number | string;
    chain_id?: number | string;
    asset_names?: string[];
  },
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<any, IlistResult<any>>(
    `merchant_portal/wallet_operation_batches/count_by_tx_status`,
    input
  );
}

export async function rebroadcastTransactions(
  batch_id: string | number,
  config?: any
) {
  const input = { batch_id, ...config };
  return await axios.post(`merchant_portal/rebroadcast_transactions`, input);
}

export async function resetMessages(
  id: string | number,
  config?: any
): Promise<any> {
  const input = { id: [id], ...config };
  return await axios.post(`merchant_portal/messages/reset_attempt`, input);
}

export const rescanBlocks = async (params: any, config?: any): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(`/merchant_portal/rescan_blocks`, input);
  return res;
};
export const rescanTrx = async (params: any, config?: any): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(
    `merchant_portal/rescan/transaction_rescan`,
    input
  );
  return res;
};
export const yubiRegister = async (
  params: { otp: string },
  config?: any
): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(
    `/merchant_portal/portal_users/register`,
    input
  );
  return res;
};
export const yubiKeyBind = async (
  params: { otp: string; new_otp: string },
  config?: any
): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(
    `/merchant_portal/portal_users/bind_yubi_key`,
    input
  );
  return res;
};

//in use, but will be refactored
export const yubiKeyReBind = async (
  params: { new_otp: string },
  config?: any
): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(
    `/merchant_portal/portal_users/bind_yubi_key`,
    input
  );
  return res;
};
export const assetEdit = async (
  params: {
    chain_type?: any;
    chain_id?: any;
    asset_name: string;
    asset_config_status: string;
    asset_config_deposit_push: boolean;
    otp: string;
  },
  config?: any
): Promise<any> => {
  const input = { ...params, ...config };
  const res = await axios.post<any>(`/merchant_portal/assets/edit`, input);
  return res;
};

export async function walletCheckPoint(
  params: {
    pageSize: number;
    page: number;
    id?: string;
    chain_id?: number | string;
    chain_type?: number | string;
    assetNames?: any[];
    mark_time_from?: string;
    mark_time_to?: string;
    created_date_from?: string;
    created_date_to?: string;
    is_certified?: number;
    wallet_address?: string;
    block_number?: string;
  },
  config?: any
): Promise<any> {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get(`merchant_portal/wallet_check_points`, input);
}

export async function rescanTransactions(
  params: {
    chain_id: number | string;
    chain_type: number | string;
    tx_hashes: string[];
  },
  config?: any
): Promise<any> {
  const input = { ...params, ...config };
  return await axios.post<never, { result: boolean }>(
    `merchant_portal/operation_transaction_records/transaction_rescan`,
    input
  );
}
export async function rebroadcastTxn(
  params: {
    batch_id: number | string;
    tx_hash: string;
  },
  config?: any
): Promise<any> {
  const input = { ...params, ...config };
  return await axios.post<never, { result: boolean }>(
    `merchant_portal/rebroadcast_txn`,
    input
  );
}

export async function updateWalletDisplayName(params: any, config?: any) {
  const input = { ...params, ...config };
  return await axios.post(
    `merchant_portal/wallet_management/update_wallet_display_name`,
    input
  );
}

export async function getBtcUtxo(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    ...rest,
    limit: pageSize,
    offset: params.pageSize * params.page,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/get_btc_utxo`,
    input
  );
}
export async function getAdaUtxo(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    ...rest,
    limit: pageSize,
    offset: params.pageSize * params.page,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/get_ada_utxo`,
    input
  );
}
export async function syncBtcUtxo(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/sync_btc_utxo`,
    input
  );
}
export async function syncAdaUtxo(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/sync_ada_utxo`,
    input
  );
}

export async function deleteBtcUtxo(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/delete_btc_utxo`,
    input
  );
}
export async function deleteAdaUtxo(params: any, config?: any) {
  const input = {
    ...params,
    ...config,
  };
  return await axios.post<never, any>(
    `merchant_portal/utxo_tool/delete_ada_utxo`,
    input
  );
}

export async function getOperationAll(params: any, config?: any) {
  const { pageSize, page, batchIdArr, ...rest } = params;
  const input = {
    params: {
      ...rest,
      batch_id: batchIdArr,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IsignerGetOperation>>(
    `merchant_portal/merchant_operation/get_all/operations`,
    input
  );
}
export async function getOperationApi(params: any, config?: any) {
  const { pageSize, page, batchIdArr, ...rest } = params;
  const input = {
    params: {
      ...rest,
      batch_id: batchIdArr,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IsignerGetOperation>>(
    `merchant_portal/merchant_operation/api_withdraw/operations`,
    input
  );
}
export async function getOperationPortal(params: any, config?: any) {
  const { pageSize, page, batchIdArr, ...rest } = params;
  const input = {
    params: {
      ...rest,
      batch_id: batchIdArr,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IsignerGetOperation>>(
    `merchant_portal/merchant_operation/portal_withdraw/operations`,
    input
  );
}
export async function getOperationSweep(params: any, config?: any) {
  const { pageSize, page, batchIdArr, ...rest } = params;
  const input = {
    params: {
      ...rest,
      batch_id: batchIdArr,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IsignerGetOperation>>(
    `merchant_portal/merchant_operation/sweep/operations`,
    input
  );
}
export async function getOperationDetailsAll(params: any, config?: any) {
  const { pageSize, page, batchId, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
      batchId,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IoperationDetailsRes>>(
    `merchant_portal/merchant_operation/get_all/operation_details`,
    input
  );
}
export async function getOperationDetailsApi(params: any, config?: any) {
  const { pageSize, page, batchId, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
      batchId,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IoperationDetailsRes>>(
    `merchant_portal/merchant_operation/api_withdraw/operation_details`,
    input
  );
}
export async function getOperationDetailsPortal(params: any, config?: any) {
  const { pageSize, page, batchId, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
      batchId,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IoperationDetailsRes>>(
    `merchant_portal/merchant_operation/portal_withdraw/operation_details`,
    input
  );
}
export async function getOperationDetailsSweep(params: any, config?: any) {
  const { pageSize, page, batchId, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
      batchId,
    },
    ...config,
  };
  return await axios.get<never, IlistResult<IoperationDetailsRes>>(
    `merchant_portal/merchant_operation/sweep/operation_details`,
    input
  );
}

export async function getOperationDetailStatAll(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<never, any>(
    `merchant_portal/merchant_operation/get_all/operation_details_stat`,
    input
  );
}
export async function getOperationDetailStatApi(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<never, any>(
    `merchant_portal/merchant_operation/api_withdraw/operation_details_stat`,
    input
  );
}
export async function getOperationDetailStatPortal(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<never, any>(
    `merchant_portal/merchant_operation/portal_withdraw/operation_details_stat`,
    input
  );
}
export async function getOperationDetailStatSweep(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<never, any>(
    `merchant_portal/merchant_operation/sweep/operation_details_stat`,
    input
  );
}

export async function downloadTransactionDetailsAll(
  batch_id: string,
  config?: any
) {
  const input = {
    params: { batch_id: batch_id },
    ...config,
  };
  return await axios.get(
    `merchant_portal/merchant_operation/get_all/download_transactions`,
    input
  );
}
export async function downloadTransactionDetailsApi(
  batch_id: string,
  config?: any
) {
  const input = {
    params: { batch_id: batch_id },
    ...config,
  };
  return await axios.get(
    `merchant_portal/merchant_operation/api_withdraw/download_transactions`,
    input
  );
}
export async function downloadTransactionDetailsPortal(
  batch_id: string,
  config?: any
) {
  const input = {
    params: { batch_id: batch_id },
    ...config,
  };
  return await axios.get(
    `merchant_portal/merchant_operation/portal_withdraw/download_transactions`,
    input
  );
}
export async function downloadTransactionDetailsSweep(
  batch_id: string,
  config?: any
) {
  const input = {
    params: { batch_id: batch_id },
    ...config,
  };
  return await axios.get(
    `merchant_portal/merchant_operation/sweep/download_transactions`,
    input
  );
}

export async function uploadTransactionDetails(
  batch_id: string,
  text: any,
  paramByTab: "get_all" | "api_withdraw" | "sweep" | "portal_withdraw",
  config?: any
) {
  const input = {
    batch_id: batch_id,
    data: text,
    ...config,
  };
  return await axios.post(
    `merchant_portal/merchant_operation/${paramByTab}/upload_transactions`,
    input
  );
}

export async function getOpManualReasonAll(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<any>(
    `merchant_portal/merchant_operation/get_all/manual_approval_reasons`,
    input
  );
}
export async function getOpManualReasonApi(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<any>(
    `merchant_portal/merchant_operation/api_withdraw/manual_approval_reasons`,
    input
  );
}
export async function getOpManualReasonPortal(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<any>(
    `merchant_portal/merchant_operation/portal_withdraw/manual_approval_reasons`,
    input
  );
}
export async function getOpManualReasonSweep(params: any, config?: any) {
  const { batch_id, merchant_id } = params;
  const input = {
    params: {
      batch_id: batch_id,
      merchant_id: merchant_id,
    },
    ...config,
  };
  return await axios.get<any>(
    `merchant_portal/merchant_operation/sweep/manual_approval_reasons`,
    input
  );
}
export interface IestimateDetail {
  batch_id: string;
  miner_fee_asset_name: string;
  estimated_miner_fee: string;
  estimated_miner_fee_decimals: string;
  last_estimated_miner_fee_date: string;
}
export async function reEstimateFeeGetAll(
  params: { batchId: string },
  config?: any
) {
  const payload = { ...params, ...config };
  return await axios.post<never, IestimateDetail>(
    `merchant_portal/merchant_operation/get_all/estimate_fee`,
    payload
  );
}
export async function reEstimateFeeApiWithdraw(
  params: { batchId: string },
  config?: any
) {
  const payload = { ...params, ...config };
  return await axios.post<never, IestimateDetail>(
    `merchant_portal/merchant_operation/api_withdraw/estimate_fee`,
    payload
  );
}
export async function reEstimateFeeWithdrawPortal(
  params: { batchId: string },
  config?: any
) {
  const payload = { ...params, ...config };
  return await axios.post<never, IestimateDetail>(
    `merchant_portal/merchant_operation/portal_withdraw/estimate_fee`,
    payload
  );
}
export async function reEstimateFeeSweep(
  params: { batchId: string },
  config?: any
) {
  const payload = { ...params, ...config };
  return await axios.post<never, IestimateDetail>(
    `merchant_portal/merchant_operation/sweep/estimate_fee`,
    payload
  );
}

interface ResubmitTransactionParams {
  (
    data: {
      batch_id: string;
    },
    config?: any
  ): Promise<any>;
}

export const sweepRequestRecordResubmitBatch: ResubmitTransactionParams = (
  data
) => {
  const url =
    "merchant_portal/asset_management/sweep_request_record/resubmit_batch";
  const payload = {
    ...data,
  };
  return axios.post(url, payload);
};

export const withdrawRequestRecordResubmitBatch: ResubmitTransactionParams = (
  data
) => {
  const url =
    "/merchant_portal/asset_management/withdraw_request_record/resubmit_batch";
  const payload = {
    ...data,
  };
  return axios.post(url, payload);
};

export async function getLedgerFreezeRequest(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<never, IFreezeRequestRes>(
    `merchant_portal/ledger_freeze_requests/get_all`,
    input
  );
}
export async function getLedgerFreezeRequestExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<never, IFreezeRequestRes>(
    `merchant_portal/ledger_freeze_requests/export`,
    input
  );
}
export async function getLedgerFrozenFund(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<never, IFrozenFundRes>(
    `merchant_portal/ledger_frozen_funds/get_all`,
    input
  );
}
export async function getLedgerFrozenFundExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };
  return await axios.get<never, IFrozenFundRes>(
    `merchant_portal/ledger_frozen_funds/export`,
    input
  );
}
