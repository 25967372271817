import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../../components/NoRowsOverlay";
import { CustomPagination } from "../../../../components/CustomPagination";
import { dataGridDefaults } from "../../../../utils/constant";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../../hooks/useColumnHide";

interface ReSubmitListProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  page: number;
  onPageChange: (page: number) => void;
  rowCount: number;
}

const ReSubmitList = (props: ReSubmitListProps) => {
  const { rows, columns, page, onPageChange, rowCount } = props;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={rows}
        rowCount={rowCount}
        columns={columns}
        page={page}
        onPageChange={onPageChange}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: rowCount },
        }}
      />
    </>
  );
};

export default ReSubmitList;
