import { useTranslate } from "react-admin";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../components/MuiGenerals";
import { customSx } from "../../../utils/styling";
import { useAuditLogStore, useZusDialogStore } from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";

const OnPortalOpDetailsDialog = () => {
  const translate = useTranslate();
  const t = (key: string) => translate(`auditLog.${key}`);
  const zusDialog = useZusDialogStore();
  const dialogFields = useAuditLogStore(
    (state) => state.onPortalDialogDiaLogFields
  );
  const handleCancel = () => {
    zusDialog.close();
  };
  return (
    <>
      <>
        <Dialog
          open={zusDialog.match("onProtalOpDialog")}
          onClose={handleCancel}
          fullWidth={true}
        >
          <DialogTitle>{t("details")}</DialogTitle>
          <DialogContent>
            <MpTextField
              variant="filled"
              defaultValue={dialogFields.api_url}
              fullWidth={true}
              label={t("api_url")}
              disabled={true}
            />
            <br />
            <MpTextField
              variant="filled"
              defaultValue={dialogFields.req_date}
              fullWidth={true}
              label={t("res_date")}
              disabled={true}
            />
            <br />
            <MpTextField
              label={t("requesHeader")}
              fullWidth={true}
              multiline
              rows={7}
              defaultValue={dialogFields.req_header}
              disabled={true}
            />

            <br />
            <MpTextField
              variant="filled"
              defaultValue={dialogFields.req_body}
              fullWidth={true}
              multiline
              rows={7}
              label={t("requestBody")}
              disabled={true}
            />

            <br />
            <MpTextField
              variant="filled"
              defaultValue={dialogFields.res}
              fullWidth={true}
              multiline
              rows={7}
              label={t("requestResp")}
              disabled={true}
            />
            <MpTextField
              variant="filled"
              defaultValue={dialogFields.res_date}
              fullWidth={true}
              label={t("resposneTime")}
              disabled={true}
            />
          </DialogContent>
          <DialogActions sx={customSx.dialogBtnMargin}>
            <Button variant="contained" color="error" onClick={handleCancel}>
              {t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default OnPortalOpDetailsDialog;
