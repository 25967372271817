import EnumApprovalAction from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerApprovalAction";
import { IenumKey } from "../../hooks/useTranslation";

export const exportApprovalDetailConverter = (
  pagePrefix: "deposit" | "withdraw" | "transfer",
  totalApprovalLevel: number,
  rawRes: any[],
  rawResIndex: number,
  t: (key: string, params?: {}) => string,
  te: (key: IenumKey, params?: {}) => string
) => {
  const approvalArr: Array<[string, string]> = [];
  for (let i = 0; i < totalApprovalLevel; i++) {
    const approvalLevelTranslation = t(`approval_level_${i + 1}`);
    const approvalObj = rawRes[rawResIndex][`${pagePrefix}_approval`][i];
    if (!approvalObj) {
      continue;
    }
    approvalArr.push([
      `${approvalLevelTranslation} ${t("operated_by")}`,
      approvalObj.approved_by,
    ]);
    approvalArr.push([
      `${approvalLevelTranslation} ${t("operation_result")}`,
      te(
        EnumApprovalAction[approvalObj.approval_action] as "Approve" | "Reject"
      ),
    ]);
    approvalArr.push([
      `${approvalLevelTranslation} ${t("operation_time")}`,
      approvalObj.last_modified_date,
    ]);
    approvalArr.push([
      `${approvalLevelTranslation} ${t("remarks")}`,
      approvalObj.remarks,
    ]);
  }
  return Object.fromEntries(approvalArr);
};
