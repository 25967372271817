import { forwardRef, ForwardedRef, HTMLAttributes } from "react";
import { useAlerting, useTranslation } from "../hooks";
import { copyToClipboard } from "../utils/helper";
interface Iprops extends HTMLAttributes<HTMLElement> {
  text: string;
  handleOverflow?: boolean;
}
export default forwardRef<HTMLDivElement, Iprops>(function CopyableText(
  props,
  ref
) {
  const { handleOverflow, text, ...rest } = props;
  const { alerting } = useAlerting();
  const { tc } = useTranslation("common");
  const onClickHandler = () => {
    copyToClipboard(text);
    alerting("success", tc("copied"));
  };
  return (
    <div
      {...rest}
      style={{
        overflow: handleOverflow ? "hidden" : undefined,
        textOverflow: handleOverflow ? "ellipsis" : undefined,
        color: "#4A90F7",
        cursor: "pointer",
        textDecoration: "underline",
      }}
      onClick={onClickHandler}
      ref={ref}
    >
      {text}
    </div>
  );
});
