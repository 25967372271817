import { useTranslate } from "react-admin";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
} from "../../components/MuiGenerals";
import { customSx } from "../../utils/styling";
import { tableConfig } from "../../utils/constant";
import { copyToClipboard } from "../../utils/helper";
import { useAlerting } from "../../hooks";
import {
  payeePayerProps,
  generalInfoProps,
  chainInfoProps,
} from "./tableProps";
import { TableHeadComponent } from "../TableHeadComponent";

interface MoreDetailsDialogFace {
  openTransactionDialog: boolean;
  setOpenTransactionDialog: React.Dispatch<React.SetStateAction<boolean>>;
  tableCellsObject: any;
}

export default function MoreDetailsDialog(props: MoreDetailsDialogFace) {
  const { openTransactionDialog, setOpenTransactionDialog, tableCellsObject } =
    props;
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);

  return (
    <>
      <Dialog
        open={openTransactionDialog}
        onClose={() => setOpenTransactionDialog(false)}
        fullWidth={true}
        PaperProps={{
          style: {
            minWidth: "80vw",
          },
        }}
      >
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHeadComponent header={t("generalInfo")} />
              <TableBody>
                {generalInfoProps.map((i, index) => (
                  <TableRowElement
                    key={index}
                    tableCellsObject={tableCellsObject}
                    leftTableCellProp={i.leftTableCellProp}
                    rightTableCellProp={i.rightTableCellProp}
                  />
                ))}
              </TableBody>
            </Table>
            <Table>
              <TableHeadComponent header={t("payerPayeeInfo")} />
              <TableBody>
                {payeePayerProps.map((i, index) => (
                  <TableRowElement
                    key={index}
                    tableCellsObject={tableCellsObject}
                    leftTableCellProp={i.leftTableCellProp}
                    rightTableCellProp={i.rightTableCellProp}
                  />
                ))}
              </TableBody>
            </Table>
            <Table>
              <TableHeadComponent header={t("baseEnvInfo")} />
              <TableBody>
                {chainInfoProps.map((i, index) => (
                  <TableRowElement
                    key={index}
                    tableCellsObject={tableCellsObject}
                    leftTableCellProp={i.leftTableCellProp}
                    rightTableCellProp={i.rightTableCellProp}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenTransactionDialog(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

interface TableRowFace {
  leftTableCellProp: string;
  rightTableCellProp?: string | undefined;
  tableCellsObject: any;
}

export function TableRowElement({
  leftTableCellProp,
  rightTableCellProp,
  tableCellsObject,
}: TableRowFace) {
  // console.log("tableCellsObject", tableCellsObject);
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const { alerting } = useAlerting();
  const isAddress = (field: string) =>
    tableConfig.addressFields.includes(field);

  const formatValue = (property: string) => {
    return !property
      ? ""
      : !tableCellsObject[property]
      ? "N/A"
      : tableCellsObject[property];
  };

  const cellOnClick = (e: any, field: string, value: string) => {
    if (isAddress(field)) {
      copyToClipboard(value);
      alerting("success", t("copied"));
    }
  };

  return (
    <TableRow sx={customSx.tableRow}>
      <TableCell sx={customSx.tableCell}>
        {t(`${leftTableCellProp}`)}:{" "}
        <span
          style={
            isAddress(leftTableCellProp) ? customSx.addressValue : undefined
          }
          onClick={(e) =>
            cellOnClick(e, leftTableCellProp, formatValue(leftTableCellProp))
          }
        >
          {" "}
          {formatValue(leftTableCellProp)}{" "}
        </span>
      </TableCell>
      {rightTableCellProp ? (
        <TableCell sx={customSx.tableCell}>
          {t(`${rightTableCellProp}`)}:{" "}
          <span
            style={
              isAddress(rightTableCellProp) ? customSx.addressValue : undefined
            }
            onClick={(e) =>
              cellOnClick(
                e,
                rightTableCellProp,
                formatValue(rightTableCellProp)
              )
            }
          >
            {" "}
            {formatValue(rightTableCellProp)}{" "}
          </span>
        </TableCell>
      ) : (
        <TableCell sx={customSx.tableCell}></TableCell>
      )}
    </TableRow>
  );
}
