import { useAppSelector } from "../reducer/hooks";
import { selectPermissions } from "../reducer/profileSlice";
function usePermission() {
  const permissionList = useAppSelector(selectPermissions);

  const hasPermission = (key: string) =>
    permissionList.includes(key.toLowerCase());

  const hasPermissionMultipleAll = (keyArr: string[]) =>
    keyArr.every((key) => permissionList.includes(key.toLowerCase()));

  const hasPermissionMultipleEither = (keyArr: string[]) =>
    keyArr.some((key) => permissionList.includes(key.toLowerCase()));

  return {
    hasPermission,
    hasPermissionMultipleAll,
    hasPermissionMultipleEither,
    permissionList,
  };
}

export default usePermission;
