import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/node-types";
import EnumClientType from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerClientType";

import { getClientManagement } from "../../../api/merchant";
import { OpEdit } from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton from "../../../components/OpIconButton";
import { usePermission, useTranslation } from "../../../hooks";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import AddOrEditDialog from "./AddOrEditDialog";
import { useZusParams } from "./ClientManagement";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "ledgerClientManagement";
const Pkey = FeatureCode.FeaturesLedger.ClientManagement;
const api = {
  getClientManagement,
};
export default function ClientManagementList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: ["clientManagement", zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.getClientManagement(zusParams.body),
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  useEffect(() => setCount(count), [listRes]);

  const EditButton = (params: GridCellParams) => {
    const shouldDisableEditButton =
      params.row.rawData.client_type === EnumClientType.System;

    const onClick = () => {
      const { id, row } = params;
      const rawRow = rows[Number(id)];
      const { client_type } = rawRow || {};

      const { client_id, withdrawable, transferable, disabled_client } = row;

      zusDialog.open("operationDialog", {
        client_id,
        client_type,
        withdrawable,
        transferable,
        disabled_client,
      });
    };

    return (
      <OpIconButton
        title={t("edit")}
        url={OpEdit}
        size="1.9rem"
        onClick={onClick}
        isDisabled={shouldDisableEditButton}
      />
    );
  };

  const { hasPermission } = usePermission();

  const hasEditClientPermission = hasPermission(Pkey.EditClient);

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 70,
      renderCell: EditButton,
    }),
    useGenGridCol("client_id", t("client_id"), { minWidth: 150 }),
    useGenGridCol("client_type", t("client_type"), { minWidth: 100 }),
    useGenGridCol("withdrawable", t("withdrawable"), { minWidth: 100 }),
    useGenGridCol("transferable", t("transferable"), { minWidth: 100 }),
    useGenGridCol("disabled_client", t("disabled_client"), { minWidth: 100 }),
    useGenGridCol("creation_time", t("creation_time"), { minWidth: 150 }),
    useGenGridCol("last_modified_date", t("last_modified_date"), {
      minWidth: 150,
    }),
  ].filter(({ field }) => {
    if (field === "operation") {
      return hasEditClientPermission;
    }

    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <AddOrEditDialog />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
