import { Button } from "./MuiGenerals";
export default function OpIconButton(p: {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  sx?: Object;
}) {
  const { isHidden = false, isDisabled = false } = p;
  if (isHidden) return null;
  return (
    <Button
      sx={{
        borderRadius: "1rem",
        backgroundColor: "#4a90f7",
        maxWidth: "fit-content",
        flex: 1,
        ...p.sx,
      }}
      variant="contained"
      onClick={p.onClick}
      disabled={isDisabled}
    >
      {p.text}
    </Button>
  );
}

export const sxOpTextButton = {
  display: "flex",
  alignSelf: "auto",
  justifyContent: "center",
  width: "90%",
  columnGap: "1rem",
};
