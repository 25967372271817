import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import { FormHelperText } from "@mui/material";
import EnumWalletType from "@wallet-manager/node-types/dist/types/postgres/const/WalletType";

import { getWalletManagement } from "../api/merchant";
import { useAlerting } from "../hooks";
import { useChains } from "./FetchConfig";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "./MuiGenerals";

interface ChoicesFace {
  wallet_address: string;
  display_name: string;
  is_default: boolean;
}
export default function AddressSelection(props: {
  wallet_types: Array<EnumWalletType>;
  chain_name: string;
  gather_address: string;
  setGatherAddress: (value: string) => void;
  helperText?: string;
  tryDefaultWallet?: boolean;
}) {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const {
    wallet_types,
    chain_name = "",
    gather_address = "",
    setGatherAddress,
    helperText,
    tryDefaultWallet,
  } = props;
  const [choices, setChoices] = useState<ChoicesFace[]>([]);
  const { selectChainByPieces } = useChains();
  const { alerting } = useAlerting();
  const filterWalletStatus = [1];

  const { chain_id, chain_type } = selectChainByPieces({ chain_name });
  const params = {
    chain_type,
    chain_id,
    wallet_types,
    statuses: filterWalletStatus,
    page: 0,
    pageSize: 100,
  };
  useEffect(
    function checkDefaultWallet() {
      if (choices.length === 0 || !tryDefaultWallet) {
        return;
      }
      const defaultAddress =
        choices.find((w) => w.is_default)?.wallet_address || "";
      setGatherAddress(defaultAddress);
    },
    [choices]
  );
  useEffect(() => {
    //always clear gather address choices and actual value, if chain_name changes
    setGatherAddress("");
    setChoices([]);
    if (!chain_name) return;
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;
    const fetch = async () => {
      if (!isMounted) return;
      const { rows = [] } = await getWalletManagement(params, { signal });
      setChoices(rows.map((item: any) => item));
      const noResult = chain_name.length > 0 && rows.length === 0;
      if (noResult) alerting("warning", t("noWalletAddressError"));
    };
    fetch();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    chain_name,
    wallet_types.join(",") /* update address when wallet type change */,
  ]);
  const onSelectChange = (e: SelectChangeEvent<string>) => {
    setGatherAddress(e.target.value);
  };
  return (
    <FormControl sx={{ position: "relative", minWidth: "15rem" }}>
      <InputLabel>{t("phAddress")}</InputLabel>
      <Select
        name="gather_address"
        defaultValue=""
        onChange={onSelectChange}
        value={gather_address}
      >
        {choices.map((key, i) => (
          <MenuItem key={i} value={key.wallet_address}>
            [{key.display_name}] {key.wallet_address}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText sx={{ position: "absolute", bottom: -15, left: -14 }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
