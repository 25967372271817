import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { useTranslate } from "react-admin";
import { CustomPagination } from "../CustomPagination";
import { NoRowsOverlay } from "../NoRowsOverlay";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

export default function TotalBalanceOverviewList(props: any) {
  const { list, setPage, page, listMapping } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`totalWalletBalance.${key}`);
  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  const columns: GridColDef[] = [
    useGenGridCol("wallet_type", t("wallet_type")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("balance", t("balance")),
    useGenGridCol("last_modified_date", t("last_modified_date")),
  ];

  const totalRecords = list.count;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
