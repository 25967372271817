import { useTranslate } from "react-admin";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import React from "react";
import { useGenGridCol } from "../../../utils/HelperComp";
import { dataGridDefaults } from "../../../utils/constant";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { CustomPagination } from "../../../components/CustomPagination";
const translatePrefix = "AddressGeneration";
const AddressGenerationList = (props: any) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { list, setPage, page, listMapping } = props;

  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  const operationsColumn: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("wallet_types", t("wallet_types")),
    useGenGridCol("wallet_version", t("wallet_version")),
    useGenGridCol("sum_addr_generated", t("sum_addr_generated")),
    useGenGridCol("addr_unused_count", t("addr_unused_count")),
    useGenGridCol("addr_used_count", t("addr_used_count")),
  ];

  return (
    <Container maxWidth={false} disableGutters>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={totalRecords}
        columns={operationsColumn}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
};
export default React.memo(AddressGenerationList);
