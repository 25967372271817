import { GetListSearchParams } from "../views/Tools/ReSubmit/types/api";
import axios from "./axiosInstance";
import { IlistResult } from "./types";

interface GetResubmitListParams {
  (params: GetListSearchParams, config?: any): Promise<any>;
}

export interface GetResubmitListRes {
  id: string;
  operation_type: number;
  chain_type: number;
  chain_id: string;
  total_operation: number;
  status: number;
  created_by: string;
  created_date: string;
  sweep_request_wallet_operation_batch: any;
  withdraw_request_wallet_operation_batch: {
    asset_name: string;
    total_amount: string;
  };
}

export const getResubmitList: GetResubmitListParams = async (
  params,
  config
) => {
  const { pageSize, page, ...restParams } = params;

  const url = "/merchant_portal/tools/resubmit/get_failed_batches";

  const payload = {
    params: {
      limit: pageSize,
      offset: pageSize * page,
      ...restParams,
    },
    ...config,
  };

  return await axios.get<any, IlistResult<GetResubmitListRes>>(url, payload);
};

interface ResubmitTransactionParams {
  (
    data: {
      batch_id: string;
    },
    config?: any
  ): Promise<any>;
}

export const resubmitTransaction: ResubmitTransactionParams = (data) => {
  const url = "merchant_portal/tools/resubmit/resubmit_batch";
  const payload = {
    ...data,
  };
  return axios.post(url, payload);
};
