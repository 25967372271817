import { useState } from "react";
import { useTranslate } from "react-admin";
import {
  Select,
  SelectChangeEvent,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { enumWalletType } from "../../utils/constant";
import { useChains } from "../FetchConfig";
import { createWalletAddress } from "../../api/merchant";
import { customSx } from "../../utils/styling";
import { useAlerting } from "../../hooks";
import MpTextField from "../MpTextField";

interface WalletAssignmentFieldsFace {
  client_id: string;
  walletType: string;
  chain_name: string;
}

const initAssignmentFields = {
  client_id: "",
  walletType: "",
  chain_name: "",
};

export default function AssignMerchantWalletAddressDialog(props: any) {
  const { openAssignWallet, setOpenAssignWallet } = props;
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [fields, setFields] =
    useState<WalletAssignmentFieldsFace>(initAssignmentFields);
  const { chainObj, selectChainByPieces } = useChains();
  const assignWalletTypeArr = ["2", "3"];
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });

  const onConfirmAssignAddress = async () => {
    if (!fields.client_id) {
      alerting("error", t("accountNoError"));
    } else if (!fields.chain_name) {
      alerting("error", t("chainTypeError"));
    } else if (fields.client_id && fields.chain_name) {
      const params = {
        chain_type: chain_type,
        chain_id: chain_id,
        wallet_name: fields.client_id,
        wallet_type: fields.walletType,
      };
      try {
        const { address, wallet_tag, x_address }: any =
          await createWalletAddress(params);
        // For assigning XRP chain
        if (address && wallet_tag && x_address) {
          alerting(
            "success",
            t("assignWalletSuccess") +
              "\n" +
              "Wallet Address: " +
              address +
              "\n" +
              "Wallet tag: " +
              wallet_tag +
              "\n" +
              "x_address: " +
              x_address
          );
        } else if (address) {
          alerting(
            "success",
            t("assignWalletSuccess") + "\n" + "Wallet Address: " + address
          );
        }
      } catch (error) {
        console.error("createWalletAddressResonse error", error);
      }
      setFields(initAssignmentFields);
      setOpenAssignWallet(false);
    }
  };

  return (
    <>
      <Dialog
        open={openAssignWallet}
        onClose={() => setOpenAssignWallet(false)}
        fullWidth={true}
      >
        <DialogTitle>{t("createAddress")}</DialogTitle>
        <DialogContent>
          <MpTextField
            fullWidth={true}
            label={t("walletNameError")}
            onChange={onChange("client_id")}
          />
          <br />
          <FormControl fullWidth={true}>
            <InputLabel>{t("walletTypeError")}</InputLabel>
            <Select
              name="walletType"
              value={fields.walletType}
              onChange={onSelectChange}
              defaultValue=""
            >
              {Object.entries(enumWalletType)
                .filter(([key, val]) => assignWalletTypeArr.includes(key))
                .map(([key, value], i) => (
                  <MenuItem key={i} value={key}>
                    {translate(`enumConstants.${value}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("chainTypeError")}</InputLabel>
            <Select name="chain_name" defaultValue="" onChange={onSelectChange}>
              {Object.entries(chainObj).map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmAssignAddress}
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenAssignWallet(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
