import { useTranslation } from "../../hooks";
import { useZusDialogStore } from "../../zustand/store";
import DialogInOne from "../DialogInOne";
import { Box, Button, DialogContent } from "../MuiGenerals";
import ApprovalProgress from "./ApprovalProgress";
const translatePrefix = "withdrawApprovalCheckingTimeSetting";

export default function ApprovalDialog(props: any) {
  const { onApproveOrReject } = props;
  const { t } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const TheButton = {
    Approve: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onApproveOrReject(true, "approveBtn")}
      >
        {t("approve")}
      </Button>
    ),
    Reject: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={() => onApproveOrReject(false, "rejectBtn")}
      >
        {t("reject")}
      </Button>
    ),
  };
  const { ripeData = {}, from } = zusDialog.meta || {};

  const isOperation = from === "operation";

  const sx = {
    buttonGroup: { display: "flex", gap: "1rem", marginRight: "1rem" },
  };

  const operationData = [
    { title: t("proposal_no"), value: ripeData?.proposal_no },
    { title: t("before_edit"), value: ripeData?.before_edit },
    { title: t("after_edit"), value: ripeData?.after_edit },
    { title: t("created_by"), value: ripeData?.created_by },
    {
      title: t("creation_time"),
      value: ripeData?.creation_time,
    },
  ];

  const dialogConfig = {
    title: t("approval"),
    self: {
      open: zusDialog.match("approvalDialog"),
      onClose: () => zusDialog.close(),
    },
    content: isOperation ? (
      <DialogContentTable data={operationData} />
    ) : (
      <ApprovalProgress />
    ),
    onCancel: zusDialog.close,
    isConfirmHidden: true,
    onConfirm: () => {},
    actionButtons: isOperation ? (
      <Box sx={sx.buttonGroup}>
        <TheButton.Approve />
        <TheButton.Reject />
      </Box>
    ) : undefined,
  } as const;

  return (
    <>
      <DialogInOne {...dialogConfig} rest={{ maxWidth: "md" }} />
    </>
  );
}
const sx = {
  DialogContent: {
    padding: "2rem",
    paddingLeft: 0,
    "& .MuiBox-root": { display: "flex", marginBottom: "0.2rem" },
    "& .MuiBox-root span": { flex: 1 },
  },
};
function DialogContentTable(p: { data: { title: string; value: string }[] }) {
  const { data = [] } = p;
  return (
    <DialogContent sx={sx.DialogContent}>
      {data.map(({ title, value }) => (
        <Box key={title}>
          <span>{title}</span>
          <span>{value}</span>
        </Box>
      ))}
    </DialogContent>
  );
}
