import React, { useRef } from "react";
import { usePrompter } from "../components/Prompter";
import MpTextField from "../components/MpTextField";
import { Box, Typography } from "../components/MuiGenerals";
import { useTranslation } from ".";
const translatePrefix = "yubiKeyHook";
function useYubiPrompt() {
  const { t, tc } = useTranslation(translatePrefix);
  const [prompter, setPrompter] = usePrompter();

  const yubiKeyRef = useRef<HTMLInputElement>(null); // in callback function, cannot access latest value with useState
  const remarksRef = useRef<HTMLInputElement>(null);

  const getResAfterYubi = async (
    yubiKeyFn: (params: any, config?: any) => Promise<any>,
    params: any,
    config?: any
  ) => {
    const doneFn = (r: any) => {
      const onYes = async () => {
        if (!yubiKeyRef.current) {
          return;
        }
        const res = await yubiKeyFn(
          { ...params, otp: yubiKeyRef.current.value },
          config
        );
        if (!res) {
          yubiKeyRef.current.value = "";
          return false;
        }
        r(res);
        return true;
      };
      return setPrompter(async () => await onYes(), {
        title: tc("enter_yubi_key"),
        content: (
          <MpTextField
            sx={{
              width: "18rem",
              "& .MuiInputBase-input": {
                padding: "3px",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "black",
                },
              },
            }}
            variant="outlined"
            inputRef={yubiKeyRef}
          />
        ),
        yesText: tc("confirm"),
        noText: tc("cancel"),
        onNo: () => r(undefined),
      });
    };
    return await new Promise<any | undefined>(doneFn);
  };

  const getResAfterYubiWithRemarks = async (
    yubiKeyFn: (params: any, config?: any) => Promise<any>,
    params: any,
    config?: any
  ) => {
    const doneFn = (r: any) => {
      const onYes = async () => {
        if (!yubiKeyRef.current || !remarksRef.current) {
          return;
        }
        const res = await yubiKeyFn(
          {
            ...params,
            otp: yubiKeyRef.current.value,
            remarks: remarksRef.current.value,
          },
          config
        );
        if (!res) {
          yubiKeyRef.current.value = "";
          return false;
        }
        r(res);
        return true;
      };
      return setPrompter(async () => await onYes(), {
        title: t("please_enter"),
        content: (
          <Box sx={{ width: "18rem" }}>
            <MpTextField
              sx={{
                width: "18rem",
                "& .MuiInputBase-input": {
                  padding: "3px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                },
              }}
              placeholder={t("ph_yubikey")}
              inputRef={yubiKeyRef}
              variant="outlined"
            />
            <MpTextField
              sx={{
                width: "18rem",
                "& .MuiInputBase-input": {
                  padding: "3px",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "black",
                  },
                },
              }}
              placeholder={t("ph_remarks")}
              inputRef={remarksRef}
              variant="outlined"
            />
          </Box>
        ),
        yesText: tc("confirm"),
        noText: tc("cancel"),
        onNo: () => r(undefined),
      });
    };
    return await new Promise<any | undefined>(doneFn);
  };

  const getResAfterYubiWithCustomContent = async (
    yubiKeyFn: (params: any, config?: any) => Promise<any>,
    content: React.ReactNode,
    customDialogActionSx: any,
    title: string,
    params: any,
    config: any,
    onNo: () => void,
  ) => {
    const doneFn = (r: any) => {
      const onYes = async () => {
        if (!yubiKeyRef.current) {
          return;
        }
        const res = await yubiKeyFn(
          {
            ...params,
            otp: yubiKeyRef.current.value,
          },
          config
        );
        if (!res) {
          yubiKeyRef.current.value = "";
          return false;
        }
        r(res);
        return true;
      };
      return setPrompter(async () => await onYes(), {
        title: title,
        content:<Box sx={{ width: "20rem" }}>
          {content}
        <Typography sx={{
          marginTop: "1rem"
        }}>{`${t("please_enter")} ${t("ph_yubikey")}`}</Typography>
        <MpTextField
          sx={{
            width: "100%",
            "& .MuiInputBase-input": {
              padding: "3px",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "black",
              },
            },
            marginTop: "1rem"
          }}
          inputRef={yubiKeyRef}
          variant="outlined"
        />
      </Box>,
        yesText: tc("confirm"),
        noText: tc("cancel"),
        onNo: onNo,
        displayCloseButton: true,
        customDialogActionSx: customDialogActionSx
      });
    };
    return await new Promise<any | undefined>(doneFn);
  }

  return {
    getResAfterYubi,
    getResAfterYubiWithRemarks,
    getResAfterYubiWithCustomContent,
    // Prompter: () => <Prompter {...prompter} />, // will cause flashing if there is a re-render (should init in component)
    prompterConfig: prompter,
  };
}
export default useYubiPrompt;
/*usage
 */
