import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useTranslate } from "react-admin";
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  Select,
  SelectChangeEvent,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  InputAdornment,
} from "../../components/MuiGenerals";
import { getAdjustBalanceTable, listFace } from "../../api/merchant";
import {
  FeatureCodes,
  enumAdjustmentStatus,
  enumWalletType,
  tableConfig,
} from "../../utils/constant";
import WalletBalanceAdjustmentList from "../../components/WalletBalanceAdjustment/WalletBalanceAdjustmentList";
import { useDatePicker } from "../../components/DatePicker";
import {
  separateBatchStrings,
  getFullApiResponse,
  downloadFiles,
  findChainInfo,
  getDecimal,
  displayAmount,
  toDisplayTime,
  sortItemsAlphabetically,
  listMappingTransform,
} from "../../utils/helper";
import { useAssets, useChains } from "../../components/FetchConfig";
import { AddBalanceAdjustmentDialog } from "../../components/WalletBalanceAdjustment/BalanceAdjustmentDialog";
import { customSx } from "../../utils/styling";
import { DivideLine, genField } from "../../utils/HelperComp";
import { useTabs, usePermission, useAlerting } from "../../hooks";
import { WalletBalanceAdjustmentParamsFace } from "../../api/types";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";
import { useZusDialogStore } from "../../zustand/store";
const Pkey = FeatureCodes.walletManagement.WalletBalanceAdjustment;
export default function WalletBalanceAdjustment() {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [refreshTable, setFreshTableBol] = useState(false);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          setPage={setPage}
          page={page}
          setFreshTableBol={setFreshTableBol}
          refreshTable={refreshTable}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <WalletBalanceAdjustmentList
            list={list}
            page={page}
            setPage={setPage}
            setFreshTableBol={setFreshTableBol}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

const initFields = {
  approvalPropsalString: "",
  walletNameString: "",
  chain_name: "",
  walletAddressString: "",
  asset_names: [],
  statusArr: [],
};

function FilterBar(props: any) {
  const { count, setList, setPage, page, refreshTable, setFreshTableBol } =
    props;
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const [addBalanceAdjustment, setAddBalanceAdjustment] = useState(false);
  const [fields, setFields] =
    useState<WalletBalanceAdjustmentParamsFace>(initFields);
  const DateObj = {
    Created: useDatePicker(),
    Approved: useDatePicker(),
  };
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);

  const getParams = () => {
    const {
      approvalPropsalString,
      walletNameString,
      asset_names,
      walletAddressString,
      chain_name,
      statusArr,
      ...rest
    } = fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    const id = separateBatchStrings(approvalPropsalString);
    const wallet_names = separateBatchStrings(walletNameString);
    const wallet_address = separateBatchStrings(walletAddressString);
    return {
      ...rest,
      ids: id,
      wallet_address,
      chain_id,
      chain_type,
      walletName: wallet_names,
      asset_name: asset_names,
      statuses: statusArr,
      created_date_from: DateObj.Created.start,
      created_date_to: DateObj.Created.end,
      approval_date_from: DateObj.Approved.start,
      approval_date_to: DateObj.Approved.end,
      page,
      pageSize: tableConfig.pageSize,
    };
  };

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getAdjustBalanceTable({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    setFreshTableBol(false);
    fetchFn();
  }, [page, refreshTable]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    setPage(0);
    setCacheParams(params);
    const res = await getAdjustBalanceTable(params);
    zusDialog.closeExtra();
    if (!res) return;
    setList(res);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Created.clearDate();
    DateObj.Approved.clearDate();
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getAdjustBalanceTable({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { t, translate });
    const config = {};
    downloadFiles(`Wallet Balance Adjustment Report`, res, config);
  };

  const onText =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.trim();
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const F = genField({ t }, [
    [
      "approvalProposalNo",
      <MpTextField
        name="id"
        label={t("phApprovalProposalNo")}
        value={fields.approvalPropsalString}
        onChange={onChange("approvalPropsalString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{params.ids.length}</InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_name",
      <MpTextField
        name="wallet_name"
        label={t("phWallet_name")}
        value={fields.walletNameString}
        onChange={onChange("walletNameString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.walletName.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "wallet_address",
      <MpTextField
        name="wallet_address"
        fullWidth={true}
        label={t("phWallet_address")}
        value={fields.walletAddressString}
        onChange={onText("walletAddressString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_address.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "approval_status",
      <FormControl>
        <InputLabel>{t("phApproval_status")}</InputLabel>
        <Select
          name="statusArr"
          multiple
          value={fields.statusArr}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumAdjustmentStatus)).map(
            ([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {t(`${value}`.toLowerCase())}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>,
    ],
    [
      "creationTime",
      <Box sx={customSx.dateSelect}>
        <DateObj.Created.Picker
          type="dateTime"
          startLabel={t("phStartTime")}
          endLabel={t("phEndTime")}
        />
      </Box>,
    ],
    [
      "approvalTime",
      <Box sx={customSx.dateSelect}>
        <DateObj.Approved.Picker
          type="dateTime"
          startLabel={t("phStartTime")}
          endLabel={t("phEndTime")}
        />
      </Box>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.AddBalanceAdjustment) && (
          <Button
            variant="outlined"
            onClick={() => setAddBalanceAdjustment(true)}
          >
            {t("addBalanceAdjustment")}
          </Button>
        )}
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.approvalProposalNo}
        {F.wallet_name}
        {F.chain_name}
        {F.asset_name}
        {F.wallet_address}
        {F.approval_status}
        {F.creationTime}
        {F.approvalTime}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
      <AddBalanceAdjustmentDialog
        setFreshTableBol={setFreshTableBol}
        addBalanceAdjustment={addBalanceAdjustment}
        setAddBalanceAdjustment={setAddBalanceAdjustment}
      />
    </>
  );
}
//listMapping not in use,
const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { t, translate } = config;
  const res = array.map((item: any) => {
    const asset_name = item?.asset_name;
    const decimal = getDecimal({ ...item, asset_name });
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const walletType =
      item.wallet_type != null
        ? translate(
            `enumConstants.${enumWalletType[String(item?.wallet_type)]}`
          )
        : "";
    const approvedDate =
      item?.status === 0 ? "" : toDisplayTime(item?.date_approved);
    const mappedResult = [
      ["proposal_no", t("proposal_no"), item?.id],
      ["wallet_name", t("wallet_name"), item?.wallet_name?.toLocaleString()],
      ["wallet_type", t("wallet_type"), walletType],
      ["wallet_address", t("walletAddress"), item.wallet_address],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item?.chain_type), item?.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), asset_name],
      ["amount", t("adjustment_amount"), toAmount(item?.amount)],
      [
        "status",
        t("approval_status"),
        translate(
          `walletManagement.${enumAdjustmentStatus[
            String(item.status)
          ].toLowerCase()}`
        ),
      ],
      ["approved_user", t("created_by_email"), item?.created_by_email],
      ["created_date", t("created_date"), toDisplayTime(item.date_created)],
      ["approved_user", t("approved_user"), item?.approved_by_email],
      ["approved_date", t("approved_date"), approvedDate],
      ["remark", t("remark"), String(item?.remark)],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
