import { useState } from "react";
import { useTranslate } from "react-admin";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SummarizeIcon from "@mui/icons-material/Summarize";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";

import { listFace, sweepRequestRecordResubmitBatch } from "../../api/merchant";
import { CustomPagination } from "../../components/CustomPagination";
import { Button } from "../../components/MuiGenerals";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import Prompter from "../../components/Prompter";
import { useAlerting, usePermission } from "../../hooks";
import { dataGridDefaults, FeatureCodes } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import ResubmitButton from "../Tools/ReSubmit/components/ResubmitButton";
import ResubmitPrompterContent from "../Tools/ReSubmit/components/ResubmitPrompterContent";
import { checkIsSubmitBatchFailed } from "../Tools/ReSubmit/helper/check-is-submit-batch-failed";
import { TableColumnKey } from "../Tools/ReSubmit/types/table";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

interface propsFace {
  list: listFace;
  page: number;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  listMapping: any;
  refresh: Function;
}

export default function SweepRequestList(props: propsFace) {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const { list, setPage, page, listMapping, refresh } = props;
  const totalRecords = list.count;
  const { hasPermission } = usePermission();
  const content: GridRowsProp = listMapping("key", list.rows, translate);
  const [isResubmitPrompterOpen, setIsResubmitPrompterOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>({});
  const { alerting } = useAlerting();

  const cellButton = (name: string) => (params: any) => {
    const batchId = params?.row?.batch_id;
    const [baseLink, icon] =
      name === "op"
        ? ["/operationRecords", <SummarizeIcon />]
        : ["/operationTransactionRecords", <AttachMoneyIcon />];
    const toNewTab = () => window.open(`${baseLink}?batchId=${batchId}`);
    return (
      <Button onClick={toNewTab} color="secondary" variant="contained">
        {icon}
      </Button>
    );
  };

  const handleResubmitYes = async () => {
    const payload = {
      batch_id: selectedRowData[TableColumnKey.BatchId],
    };

    const res = await sweepRequestRecordResubmitBatch(payload);

    if (res) {
      alerting("success", translate("resubmit.alert.success"));
      refresh();
    }
  };

  const ResubmitButtonCell = (params: any) => {
    const isBtnDisabled = !checkIsSubmitBatchFailed(
      params.row.rawData?.batch?.status
    );

    const handleClick = () => {
      setIsResubmitPrompterOpen(true);
      setSelectedRowData(params.row);
    };

    return <ResubmitButton onClick={handleClick} isDisabled={isBtnDisabled} />;
  };

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 150,
      renderCell: ResubmitButtonCell,
    }),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("gether_address", t("to_address")),
    useGenGridCol("invoker_address", t("invoker_address")),
    useGenGridCol("status", t("status")),
    useGenGridCol("miner_fee_currency", t("miner_fee_currency")),
    useGenGridCol("miner_fee", t("miner_fee"), {
      minWidth: 150,
    }),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("total_amount", t("totalAmount"), {
      minWidth: 150,
    }),
    useGenGridCol("actual_amount", t("actual_amount"), {
      minWidth: 150,
    }),
    useGenGridCol("total_count", t("totalCount")),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("last_modified_date", t("last_modified_date")),
    useGenGridCol("1", t("viewOperation"), { renderCell: cellButton("op") }),
    useGenGridCol("2", t("viewOperationTransaction"), {
      renderCell: cellButton("opt"),
      minWidth: 170,
    }),
  ].filter((col) => {
    if (
      col.field === "1" &&
      !hasPermission(FeatureCodes.assetManagement.OperationRecords.prefix)
    )
      return false;
    if (
      col.field === "2" &&
      !hasPermission(
        FeatureCodes.assetManagement.OperationTransactionRecords.prefix
      )
    ) {
      return false;
    }

    if (
      col.field === "operation" &&
      !hasPermission(FeatureCodes.assetManagement.SweepRequestRecord.Resubmit)
    )
      return false;

    return true;
  }) as GridColDef[];

  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <div>
      <Prompter
        title={translate("resubmit.prompter.title")}
        open={isResubmitPrompterOpen}
        onClose={() => setIsResubmitPrompterOpen(false)}
        content={
          <ResubmitPrompterContent
            batch_id={selectedRowData[TableColumnKey.BatchId]}
            operation_type={selectedRowData[TableColumnKey.OperationType]}
            chain_name={selectedRowData[TableColumnKey.ChainName]}
            asset_name={selectedRowData[TableColumnKey.AssetName]}
            total_amount={selectedRowData[TableColumnKey.TotalAmount]}
          />
        }
        onYes={handleResubmitYes}
      />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
