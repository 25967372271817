/* usage
<DialogInOne
    {...{
        title: t("swim"),
        self: {
            open: zusDialog.match("swimDialog"),
            onClose: () => zusDialog.close(),
        },
        content: <>some content</>,
        onCancel: zusDialog.close,
        onConfirm: () =>
        zusDialog.openExtra("reConfirmDialog", {
            extraTitle: t("confirm_to_swim"),
        }),
        actionButtons:<button>swims</button>      
    }}
/>
*/

import { useTranslate } from "react-admin";
import { ReactNode } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "./MuiGenerals";

export default function DialogInOne(props: {
  title: string;
  self: {
    open: boolean;
    onClose: any;
  };
  content: ReactNode | string;
  onConfirm: () => void;
  onCancel: () => void;
  isConfirmHidden?: boolean;
  isCancelHidden?: boolean;
  actionButtons?: ReactNode;
  rest?: any;
  isLoadingDialog?: boolean;
}) {
  const translate = useTranslate();
  const translatePrefix = "common";
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const {
    self,
    title,
    content,
    onConfirm,
    onCancel,
    isConfirmHidden,
    isCancelHidden,
    actionButtons,
    rest,
    isLoadingDialog,
  } = props;
  return (
    <Dialog open={self.open} onClose={self.onClose} fullWidth={true} {...rest}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        sx={{ padding: `${isLoadingDialog ? "1rem 2.5rem" : "0 2rem"}` }}
      >
        {content}
      </DialogContent>
      <DialogActions>
        {actionButtons}
        {!isConfirmHidden && (
          <Button
            {...{
              color: "secondary",
              variant: "contained",
              children: t("confirm"),
              onClick: onConfirm,
            }}
          />
        )}
        {!isCancelHidden && (
          <Button
            {...{
              color: "error",
              variant: "contained",
              children: t("cancel"),
              onClick: onCancel,
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
