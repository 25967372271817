import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTranslate } from "react-admin";
import { usePermission } from "../../../hooks";

import AuditLogTabView from "../AuditLogTabView";
import { useSearchParams } from "react-router-dom";
import { useZusListStore } from "../../../zustand/store";
import { FeatureCodes } from "../../../utils/constant";

const Pkey = FeatureCodes.tools.AuditLog;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const AuditLog = () => {
  const [searchedParams] = useSearchParams();
  const initSearchParams = searchedParams.get("reqId") ? 2 : 0;
  const [tabsIndex, setTabsIndex] = React.useState(Number(initSearchParams)); //the default index :which tab
  const clearZusList = useZusListStore((state) => state.clear);
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`auditLog.${key}`);
  const handleChange = (event: React.SyntheticEvent, tabsEnum: number) => {
    setTabsIndex(tabsEnum);
    clearZusList();
  };

  const operationTabs = [
    {
      name: "portal_operation_tab",
      path: Pkey.TabPortalOperation.prefix,
      jsxNode: <AuditLogTabView operationTab={"portal_operation_tab"} />,
    },
    {
      name: "api_in_operation_tab",
      path: Pkey.TabApiIn.prefix,
      jsxNode: <AuditLogTabView operationTab={"api_in_operation_tab"} />,
    },
    {
      name: "api_out_operation_tab",
      path: Pkey.TabApiOut.prefix,
      jsxNode: <AuditLogTabView operationTab={"api_out_operation_tab"} />,
    },
  ].filter((item) => hasPermission(item.path) && true);

  //
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {operationTabs.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {/* {- -Tab ContentComponent- - -} */}
      {operationTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default AuditLog;
