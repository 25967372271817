import {
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { Box } from "../components/MuiGenerals";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import { getModal } from "../utils/HelperComp";
interface TabsFace {
  children: ReactNode;
  tabsArr: { name: string; value: string }[];
  tabIndex: number;
  whichPage?: string;
  isUtxo: boolean;
  setTabIndex: Dispatch<SetStateAction<number>>;
}
export function Tabs(props: TabsFace) {
  const {
    children,
    tabsArr,
    tabIndex,
    setTabIndex,
    whichPage = "defaultText",
    isUtxo,
  } = props;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };
  // console.log(whichPage);
  return (
    <Box sx={{ width: "100%", backgroundColor: isUtxo ? "white" : "initial" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider", marginBottom: "1rem" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MuiTabs value={tabIndex} onChange={handleChange} aria-label="tabs">
            {tabsArr.map(({ name }, i) => (
              <MuiTab key={i} label={name} />
            ))}
          </MuiTabs>
          {getModal(whichPage)}
        </div>
      </Box>
      {children}
    </Box>
  );
}
function useTabs(tabsArr: { name: string; value: string }[] | []) {
  const whichPage = window.location.href.split("/").pop();
  const [tabIndex, setTabIndex] = useState(0);
  const PreTabs = useCallback(
    (props: any) => (
      <Tabs
        {...props}
        tabsArr={tabsArr}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        whichPage={whichPage}
      />
    ),
    [tabIndex]
  );
  const tabValue = tabsArr[tabIndex]?.value;
  return { Tabs: PreTabs, tabValue };
}
export default useTabs;
/*usage
  const tabsArr = [{ name: t("table"), value: "" }];
  const [tabIndex, setTabIndex] = useState(0);
 */
