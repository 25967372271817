import { SetStateAction, SyntheticEvent, useRef } from 'react';

import { useChains } from '../components/FetchConfig';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../components/MuiGenerals';

interface singlePropsFace {
  label?: string;
  setChoice: (value: SetStateAction<any>) => void;
  choice: string;
  onlyETHAndXRP?: boolean;
  targetedChainType?: number;
  isFullWidth?: boolean;
  disabled?: boolean;
  isDisabledUnselect?: boolean;
}
interface multiplePropsFace {
  label?: string;
  setChoices: (choices: string[]) => void;
  choices: string[];
  isFullWidth?: boolean;
  disabled?: boolean;
  isFixedChainType?: boolean;
  isDisabledUnselect?: boolean;
}
export function ChainSingleSelection(props: singlePropsFace) {
  const {
    label = "",
    choice,
    setChoice,
    onlyETHAndXRP,
    targetedChainType,
    isFullWidth,
    disabled,
    isDisabledUnselect,
  } = props;
  const { chainObj } = useChains();

  const onSelect = (e: SyntheticEvent<HTMLLIElement, Event>) => {
    const value = e.currentTarget.dataset.value || "";
    const output = !isDisabledUnselect && value === choice ? "" : value;
    setChoice(output);
  };

  const getChainObjArrToBeDisplayed = () => {
    if (onlyETHAndXRP) {
      return Object.entries(chainObj).filter(
        (item) => item[1].chain_type === 2 || item[1].chain_type === 4
      );
    }

    return Object.entries(chainObj);
  };

  const ChainObjArrToBeDisplayed = getChainObjArrToBeDisplayed();

  return (
    <FormControl fullWidth={isFullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select defaultValue="" value={choice} disabled={disabled}>
        {ChainObjArrToBeDisplayed.sort((a, b) =>
          a[1].name.localeCompare(b[1].name)
        ).map(([key, { name, chain_type }]) => {
          const isMenuItemDisabled = Boolean(
            Number(targetedChainType) >= 0 && chain_type !== targetedChainType
          );

          return (
            <MenuItem
              key={key}
              value={key}
              disabled={isMenuItemDisabled}
              onClick={onSelect}
            >
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
export function ChainMultipleSelection(props: multiplePropsFace) {
  const {
    label = "",
    choices,
    setChoices,
    isFullWidth,
    disabled,
    isFixedChainType,
    isDisabledUnselect,
  } = props;
  const { chainObj, selectChainByPieces } = useChains();
  const onSelectChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value;
    const newValue = typeof value === "string" ? value.split(",") : value;
    if (isDisabledUnselect && !newValue.length) {
      return;
    }
    setChoices(newValue);
  };

  const fixedChainTypeRef = useRef<number>(-1);

  interface SelectedChainsObj {
    chain_type: number;
    chain_ids: string[];
  }
  const selectedChainsObj: SelectedChainsObj = {
    chain_ids: [],
    chain_type: -1,
  };

  if (isFixedChainType && fixedChainTypeRef.current >= 0)
    selectedChainsObj.chain_type = fixedChainTypeRef.current;
  choices.forEach((item) => {
    const selectedchain = selectChainByPieces({
      chain_name: item,
    });
    if (selectedchain.chain_type)
      selectedChainsObj.chain_type = selectedchain.chain_type;

    if (selectedchain.chain_id)
      selectedChainsObj.chain_ids.push(selectedchain.chain_id);
  });
  const fixChainType = () => {
    if (selectedChainsObj.chain_type === -1) return;
    fixedChainTypeRef.current = selectedChainsObj.chain_type;
  };
  fixChainType();

  return (
    <FormControl fullWidth={isFullWidth}>
      <InputLabel>{label}</InputLabel>
      <Select
        value={choices}
        onChange={onSelectChange}
        multiple
        disabled={disabled}
      >
        {Object.entries(chainObj)
          .sort((a, b) => a[1].name.localeCompare(b[1].name))
          .map(([key, { name, chain_type }]) => (
            <MenuItem
              key={key}
              value={key}
              disabled={
                selectedChainsObj.chain_type >= 0 &&
                chain_type !== selectedChainsObj.chain_type
              }
            >
              {name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

/*
 usage
    <ChainSingleSelection
        label={t("chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
    />
 */
