import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { listFace } from "../../api/merchant";
import { useTranslate } from "react-admin";

import { CustomPagination } from "../../components/CustomPagination";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
interface propsFace {
  list: listFace;
  rangeType: string;
  listMapping: any;
  page: number;
  isSearchBtnClick: boolean;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
}
export default function ClientReportList(props: propsFace) {
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const { list, rangeType, listMapping, setPage, page, isSearchBtnClick } =
    props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, {
    translate,
    rangeType,
  });
  const columns: GridColDef[] = [
    useGenGridCol("mark_date", t("date")),
    useGenGridCol("display_name", t("display_name")),
    useGenGridCol("client_id", t("client_id")),
    useGenGridCol("wallet_address", t("wallet_address")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("opening_balance", t("opening_balance")),
    useGenGridCol("in_client_deposit", t("in_client_deposit")),
    useGenGridCol("in_withdraw_deposit", t("in_withdraw_deposit")),
    useGenGridCol("in_client_wallet_topup", t("in_client_wallet_topup")),
    // useGenGridCol("in_settlement-deposit", t("in_settlement-deposit")),
    useGenGridCol("in_balance_adjustment", t("in_balance_adjustment")),
    useGenGridCol("in_caution", t("in_caution")),
    useGenGridCol("out_sweep_to_hot_wallet", t("out_sweep_to_hot_wallet")),
    useGenGridCol("out_sweep", t("out_sweep")),
    // useGenGridCol("out_sweep_fee", t("out_sweep_fee")),
    useGenGridCol("out_balance_adjustment", t("out_balance_adjustment")),
    useGenGridCol("out_caution", t("out_caution")),
    useGenGridCol("trans_fee", t("trans_fee")),
    useGenGridCol("closing_balance", t("closingBalance")),
  ].filter((col) => !(rangeType === "custom" && col.field === "mark_date"));
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig(rangeType)
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
