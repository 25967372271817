import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
} from "@mui/material";
import EnumClientType from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerClientType";

import {
  postClientManagementAddClient,
  postClientManagementEditClient,
} from "../../../api/merchant";
import CustomTwoValueSwitch from "../../../components/CustomTwoValueSwitch";
import { NodeTypesEnumSingleSelection } from "../../../components/GeneralSelection";
import MpTextField from "../../../components/MpTextField";
import { Box, Container } from "../../../components/MuiGenerals";
import YubiPrompter from "../../../components/Prompter";
import { useAlerting, useTranslation, useYubiPrompt } from "../../../hooks";
import { genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import {
  EnumClientManagementFilterClientType,
  useZusParams,
} from "./ClientManagement";

interface Ifields {
  client_id: string;
  client_type: string;
  withdrawable: string;
  transferable: string;
  disabled_client: string;
}

interface IfieldsSubmit {
  clientId: string;
  clientType: number;
  withdrawable: boolean;
  transferable: boolean;
  disabled: boolean;
}

const translatePrefix = "ledgerClientManagement";

export default function AddOrEditDialog(props: any) {
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  const {
    client_id = "",
    client_type = "",
    withdrawable = t("no"),
    transferable = t("no"),
    disabled_client = t("no"),
  } = zusDialog.meta || {};

  const initFields: Ifields = {
    client_id,
    client_type,
    withdrawable,
    transferable,
    disabled_client,
  };
  const [fields, setFields] = useState<Ifields>(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [zusDialog.meta]);

  const getParams: () => IfieldsSubmit = () => {
    const fieldsToParams = Object.fromEntries(
      Object.entries(fields).map(([key, value]) => {
        return [
          key,
          value === t("yes") ? true : value === t("no") ? false : value,
        ];
      })
    );
    const {
      client_id: clientId,
      client_type: clientType,
      withdrawable,
      transferable,
      disabled_client: disabled,
    } = fieldsToParams;

    return {
      clientId,
      clientType,
      withdrawable,
      transferable,
      disabled,
    };
  };

  const apiParams = getParams();

  const handleConfirm = async (mode: "edit" | "add") => {
    const { client_id, client_type, ...rest } = fields;
    if (
      mode === "edit" &&
      Object.entries(rest).every(
        ([key, value]) => zusDialog.meta[key] === value
      )
    ) {
      return alerting("error", t("editError"));
    }

    if (mode === "add") {
      if (!fields.client_id) {
        return alerting("error", t("addErrorId"));
      }
      if (!fields.client_type) {
        return alerting("error", t("addErrorType"));
      }
    }

    const res = await getResAfterYubi(
      mode === "add"
        ? postClientManagementAddClient
        : postClientManagementEditClient,
      apiParams
    );

    if (!res) {
      return;
    }
    alerting("success", t(mode === "add" ? "successAdd" : "successEdit"));
    zusDialog.close();
    setFields(initFields);
    zusParams.refetch();
  };

  if (!zusDialog.match("operationDialog")) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={zusDialog.match("operationDialog")}
        onClose={zusDialog.close}
        fullWidth={true}
        maxWidth={"md"}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle>
          {client_id ? t("editClient") : t("addClient")}
        </DialogTitle>
        <DialogContent>
          <InputFieldForm
            setFields={setFields}
            fields={fields}
            mode={client_id ? "edit" : "add"}
          />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleConfirm(client_id ? "edit" : "add")}
          >
            {tc("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              zusDialog.close();
              setFields(initFields);
            }}
          >
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function InputFieldForm(props: {
  setFields: Dispatch<SetStateAction<Ifields>>;
  fields: Ifields;
  mode: "add" | "edit";
}) {
  const { fields, setFields, mode } = props;
  const { t, te } = useTranslation(translatePrefix);

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const F = genField({ t }, [
    [
      "client_id",
      mode === "edit" ? (
        <div style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "0.94rem" }}>
          {fields.client_id}
        </div>
      ) : (
        <MpTextField
          value={fields.client_id}
          onChange={onChange("client_id")}
          label={t("ph_client_id")}
        />
      ),
    ],
    [
      "client_type",
      mode === "edit" ? (
        <div style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: "0.94rem" }}>
          {te(
            EnumClientType[Number(fields.client_type)] as
              | "System"
              | "House"
              | "Client"
          )}
        </div>
      ) : (
        <NodeTypesEnumSingleSelection
          label={t("ph_client_type")}
          onChange={onChange("client_type")}
          value={fields.client_type}
          enumData={EnumClientManagementFilterClientType}
          isNoSorting
          clearSelect={() => {}}
        />
      ),
    ],
    [
      "withdrawable",
      <CustomTwoValueSwitch
        firstValue={t("yes")}
        secondValue={t("no")}
        selectedValue={fields.withdrawable}
        onChange={(_e, withdrawable) => {
          setFields((f) => ({ ...f, withdrawable }));
        }}
      />,
    ],
    [
      "transferable",
      <CustomTwoValueSwitch
        firstValue={t("yes")}
        secondValue={t("no")}
        selectedValue={fields.transferable}
        onChange={(_e, transferable) => {
          setFields((f) => ({ ...f, transferable }));
        }}
      />,
    ],
    [
      "disabled_client",
      <CustomTwoValueSwitch
        firstValue={t("yes")}
        secondValue={t("no")}
        selectedValue={fields.disabled_client}
        onChange={(_e, disabled_client) => {
          setFields((f) => ({ ...f, disabled_client }));
        }}
      />,
    ],
  ]);
  return (
    <Container disableGutters maxWidth={false}>
      <Box
        sx={{
          ...customSx.gridFilter,
          backgroundColor: "white",
          ".MuiInputLabel-shrink": {
            display: "none",
          },
          h6: {
            fontSize: "0.94rem",
            paddingTop: 0,
            height: "100%",
            lineHeight: "unset",
          },
        }}
        className="gridFilter"
      >
        {F.client_id}
        {F.client_type}
        {F.withdrawable}
        {F.transferable}
        {F.disabled_client}
      </Box>
    </Container>
  );
}
