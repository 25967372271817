import { useZusDialogStore, zusShowIconStore } from "../../../zustand/store";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { copyToClipboard } from "../../../utils/helper";
import { FeatureCodes } from "../../../utils/constant";
import OpIconButton from "../../../components/OpIconButton";
import { OpDelete } from "../../../assets/icons";
const translatePrefix = "utxoManagement";
const Pkey = FeatureCodes.tools.UTXOTool;
const GenColumnBtn = (props: any) => {
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const showIcon = zusShowIconStore((state) => state.shouldShowIcon);
  const theSearchHash = zusShowIconStore((state) => state.theSearchHash);
  const { alerting } = useAlerting();

  const { t } = useTranslation(translatePrefix);
  const handleOpenDeleteModal = () => {
    zusDialog.open("utxoDeleteDialog", {
      ...props.params.row.metaData,
      field: props.params.field,
    });
  };

  const canDelete = {
    btc: {
      delete:
        props.tab === "BTC" &&
        hasPermission(Pkey.TabBTCSeries.DeleteBTCUTXO) &&
        showIcon &&
        props.params.row[props.params.field] &&
        props.params.row[props.params.field] === theSearchHash,
    },
    ada: {
      delete:
        props.tab === "ADA" &&
        hasPermission(Pkey.TabADA.DeleteADAUTXO) &&
        showIcon &&
        props.params.row[props.params.field] &&
        props.params.row[props.params.field] === theSearchHash,
    },
  };

  return (
    <>
      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#4A90F7",
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={() => {
          alerting("success", t(`copied`));
          return copyToClipboard(props.params.row[props.params.field]);
        }}
      >
        {props.params.row[props.params.field]}
      </div>
      {(canDelete.btc.delete || canDelete.ada.delete) && (
        <OpIconButton
          title={t("Delete")}
          url={OpDelete}
          size="1.5rem"
          onClick={handleOpenDeleteModal}
          // isHidden={shouldBeHidden}
        />
      )}
    </>
  );
};

export default GenColumnBtn;
