//only import once in App.tsx, and dispatch(open(...)) anywhere
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { close, selectAlerter, setRedirectTo } from "../reducer/alerterSlice";
import { useAppSelector, useAppDispatch } from "../reducer/hooks";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";

export default function Alerter() {
  const state = useAppSelector(selectAlerter);
  const redirectTo = state.redirectTo;
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    if (redirectTo && redirectTo.length > 0) {
      navigate(redirectTo);
      dispatch(setRedirectTo(""));
    }
  }, [redirectTo, dispatch, navigate]);

  return (
    <Snackbar
      open={state.open}
      autoHideDuration={state.autoHideDuration}
      onClose={(event: React.SyntheticEvent<any> | Event, reason: string) => {
        if (reason === "clickaway") return;
        dispatch(close());
      }}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={() => dispatch(close())}
        severity={state.severity}
        sx={{ width: "100%", whiteSpace: "pre-wrap" }}
      >
        {state.text}
      </Alert>
    </Snackbar>
  );
}
