import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslate } from "react-admin";

import FormControlLabel from "@mui/material/FormControlLabel";

import { getWalletBalance, listFace } from "../../api/merchant";
import {
  EnumComparisonOperators,
  MerchantWalletBalanceParamsFace,
  TrueOrFalseString,
} from "../../api/types";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useDatePicker } from "../../components/DatePicker";
import { useAssets, useChains } from "../../components/FetchConfig";
import GeneralBtn from "../../components/GeneralBtn";
import { SingleSelection } from "../../components/GeneralSelection";
import MpTextField from "../../components/MpTextField";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import AssignMerchantWalletAddressDialog from "../../components/WalletBalance/AssignMerchantWalletAddressDialog";
import WalletBalanceList from "../../components/WalletBalance/WalletBalanceList";
import { useAlerting, usePermission, useTabs } from "../../hooks";
import {
  enumCertifiedBalanceObj,
  enumWalletStatus,
  enumWalletType,
  FeatureCodes,
  tableConfig,
} from "../../utils/constant";
import {
  containsOnlyNumbers,
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  separateBatchStrings,
  sortItemsAlphabetically,
  toDBInDecimals,
  toDisplayTime,
} from "../../utils/helper";
import { DivideLine, genField, useRefresh } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import { useZusDialogStore } from "../../zustand/store";

const initSearchParams = {
  walletNamesString: "",
  walletTypesArray: [],
  asset_names: [],
  chain_name: "",
  walletAddressesArray: "",
  statusArray: ["1"],
  is_check_point_certified: "",
  exclude_zero: "true" as TrueOrFalseString,
  balance_comparison: "",
  comparison_operator: "" as EnumComparisonOperators,
};
const Pkey = FeatureCodes.walletManagement.WalletBalance;

//is_is_check_point_certified
export default function WalletBalance() {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [operationBol, setOperationBol] = useState(false);
  const [openAssignWallet, setOpenAssignWallet] = useState(false);
  const [walletStatus, setWalletStatus] = useState("");
  const [refreshBool, refresh] = useRefresh();
  const onConfirmWalletStatus = () => {
    setOperationBol(false);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          list={list}
          setList={setList}
          setPage={setPage}
          page={page}
          refreshBool={refreshBool}
          setOpenAssignWallet={setOpenAssignWallet}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <WalletBalanceList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            refresh={refresh}
          />
        </Tabs>
      </Container>
      <AssignMerchantWalletAddressDialog
        openAssignWallet={openAssignWallet}
        setOpenAssignWallet={setOpenAssignWallet}
      />
      <Dialog
        open={operationBol}
        onClose={() => setOperationBol(false)}
        fullWidth={true}
      >
        <DialogTitle>{t("walletStatus")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("walletStatusError")}</InputLabel>
            <Select
              name={t("enableOrDisable")}
              value={walletStatus}
              onChange={(e) => setWalletStatus(e.target.value)}
            >
              {Object.entries(enumWalletStatus)
                .filter(([key]) => walletStatusArr.includes(key))
                .map(([key, value]) => (
                  <MenuItem key={key} value={Number(key)}>
                    {t(`${value}`.toLowerCase())}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmWalletStatus}
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOperationBol(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { list, setList, setPage, page, refreshBool } = props;
  const { selectChainByPieces } = useChains();
  const { hasPermission } = usePermission();
  const [fields, setFields] =
    useState<MerchantWalletBalanceParamsFace>(initSearchParams);
  const [excludeZeroChecked, setRawExcludeZeroChecked] =
    useState<boolean>(true);
  const [comparasionEnabled, setComparasionEnabled] = useState<boolean>(false);
  const setExcludeZeroChecked: Dispatch<SetStateAction<boolean>> = (bool) => {
    const tf: TrueOrFalseString = bool ? "true" : "false";
    setFields((f) => ({ ...f, exclude_zero: tf }));
    setRawExcludeZeroChecked(bool);
  };
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  const WalletSettlementTime = useDatePicker();
  const getParams = () => {
    const {
      walletTypesArray,
      asset_names,
      walletAddressesArray,
      statusArray,
      walletNamesString,
      chain_name,
      is_check_point_certified,
      balance_comparison,
      ...rest
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const display_names = separateBatchStrings(walletNamesString);
    const walletAddressesString = separateBatchStrings(walletAddressesArray);
    const defaultStatusArr = ["1", "-1"];
    const {
      start: wallet_settlement_date_from,
      end: wallet_settlement_date_to,
    } = WalletSettlementTime;
    const decimals = getDecimal({
      chain_type: String(chain_type),
      chain_id: String(chain_id),
      asset_name: asset_names[0],
    });
    const new_balance_comparison = toDBInDecimals(
      balance_comparison || 0,
      decimals
    );

    return {
      ...rest,
      display_names,
      asset_names,
      wallet_types: walletTypesArray,
      wallet_address: walletAddressesString,
      chain_type,
      chain_id,
      is_check_point_certified,
      statuses: statusArray.length === 0 ? defaultStatusArr : statusArray,
      page: page,
      pageSize: tableConfig.pageSize,
      wallet_settlement_date_from: wallet_settlement_date_from || undefined,
      wallet_settlement_date_to: wallet_settlement_date_to || undefined,
      balance_comparison: new_balance_comparison,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const getWallets = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getWalletBalance({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    getWallets();
  }, [page, refreshBool, cacheParams]);
  const { selectAssetNamesByChain } = useAssets();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(() => {
    setFields((params) => ({ ...params, asset_names: [] }));
  }, [fields.chain_name]);
  let hasTouchComparasionRef = useRef(false);
  useEffect(
    function selectAssetTrigger() {
      const isComparasionEnabled = fields.asset_names.length === 1;
      setComparasionEnabled(isComparasionEnabled);
      const hasFilledContent =
        fields.comparison_operator !== "" || fields.balance_comparison !== "";
      if (
        hasTouchComparasionRef.current &&
        hasFilledContent &&
        !isComparasionEnabled
      ) {
        alerting("warning", t("wallet_balance_filter_has_been_disabled"), {
          autoHideDuration: 1000 * 8,
        });
      }
      if (fields.comparison_operator || fields.balance_comparison) {
        hasTouchComparasionRef.current = true;
      }
    },
    [fields.asset_names, fields.comparison_operator, fields.balance_comparison]
  );

  const onExport = async () => {
    if (list.count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getWalletBalance({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, list.count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { t, translate });
    const config = {};
    downloadFiles(`Wallet Balance Report`, res, config);
  };

  const onReset = () => {
    const { statusArray, ...rest } = initSearchParams;
    const newParams = { ...rest, statusArray: [] };
    setFields(newParams);
    setRawExcludeZeroChecked(true);
    WalletSettlementTime.resetDate();
  };

  const onSearch = async () => {
    const hasFilledSomething =
      fields.comparison_operator.concat(fields.balance_comparison) != "";
    const isIncomplete =
      fields.comparison_operator === "" || fields.balance_comparison === "";
    if (hasFilledSomething && isIncomplete) {
      return alerting(
        "warning",
        t("wb_both_fields_in_wallet_balance_are_required")
      );
    }
    if (
      fields.comparison_operator === "eq" &&
      fields.balance_comparison === "0" &&
      fields.exclude_zero === "true"
    ) {
      return alerting(
        "warning",
        t("wb_should_not_be_zero_as_zero_is_excluded")
      );
    }

    setPage(0);
    setCacheParams(params);
  };
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFields((fields) => ({ ...fields, [type]: value }));
  };
  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    // if (filterField === "is_check_point_certified") {
    setFields((fields) => ({ ...fields, is_check_point_certified: "" }));
    // }
  };
  const onChange =
    (type: keyof MerchantWalletBalanceParamsFace) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      const hasInvalidValue =
        type === "balance_comparison" && !containsOnlyNumbers(value);
      if (hasInvalidValue) {
        return;
      }
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const F = genField({ t }, [
    [
      "display_name",
      <MpTextField
        fullWidth={true}
        name="wallet_name"
        label={t("phDisplayName")}
        value={fields.walletNamesString}
        onChange={onChange("walletNamesString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.display_names.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "wallet_type",
      <FormControl>
        <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
        <Select
          multiple
          name="walletTypesArray"
          value={fields.walletTypesArray}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "walletAddress",
      <MpTextField
        fullWidth={true}
        name="walletAddressesArray"
        label={t("phWalletAddress")}
        value={fields.walletAddressesArray}
        onChange={onChange("walletAddressesArray")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_address.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "walletStatus",
      <FormControl>
        <InputLabel id="select-label">{t("phWalletStatus")}</InputLabel>
        <Select
          multiple
          name="statusArray"
          value={fields.statusArray}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletStatus))
            .filter(([key, val]: any) => walletStatusArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={key}>
                {t(`${value}`.toLowerCase())}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "is_certified_balance",
      <FormControl>
        <InputLabel>{t("ph_certified_balance")}</InputLabel>
        <Select
          name="is_check_point_certified"
          value={fields.is_check_point_certified}
          onChange={onSelectChange("is_check_point_certified")}
        >
          {Object.entries(enumCertifiedBalanceObj).map(([value, name], i) => (
            <MenuItem
              key={i}
              value={value}
              onClick={(e) => handleClick(e, value, "is_check_point_certified")}
            >
              {translate(
                `enumConstants.${enumCertifiedBalanceObj[String(value)]}`
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "wallet_settlement_time",
      <WalletSettlementTime.Picker type={"dateTime"} />,
    ],
    [
      "wallet_balance",
      <Box sx={{ display: "flex", columnGap: "1rem" }}>
        <SingleSelection
          label=""
          disabled={!comparasionEnabled}
          value={fields.comparison_operator}
          onChange={onSelectChange("comparison_operator")}
          enumData={{ ...EnumComparisonOperators, "": "" }}
          clearSelect={() =>
            setFields((f) => ({ ...f, comparison_operator: "" }))
          }
        />
        <MpTextField
          name={"balance_comparison"}
          label={t("ph_wallet_balance")}
          disabled={!comparasionEnabled}
          value={fields.balance_comparison}
          onChange={onChange("balance_comparison")}
        />
        <FormControlLabel
          value="yes"
          control={<Checkbox />}
          label={t("exclude_zero")}
          labelPlacement="start"
          checked={excludeZeroChecked}
          onChange={(_, checked) => setExcludeZeroChecked(checked)}
          sx={{ minWidth: "fit-content" }}
        />
      </Box>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.display_name}
        {F.wallet_type}
        {F.chain_name}
        {F.asset_name}
        {F.walletAddress}
        {F.walletStatus}
        {F.is_certified_balance}
        {F.wallet_settlement_time}
        {F.wallet_balance}
      </Box>
      <Box sx={customSx.filterB}>
        <Button color="secondary" variant="contained" onClick={onSearch}>
          {t("search")}
        </Button>
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const walletStatusArr = ["-1", /* "0", */ "1"];
const walletTypeArr = [/* "0",  */ "1", "2", "3" /* , "4", "5" */];
const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: any
): any[][] => {
  const { t, translate } = config || {};
  const tEnum = (key: string) => translate(`enumConstants.${key}`);
  const res = array.map((item: any) => {
    const asset_name = item?.asset_name;
    const decimal = getDecimal({ ...item, asset_name });
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const status = walletStatusArr.includes(String(item.wallet?.status))
      ? tEnum(enumWalletStatus[String(item.wallet?.status)])
      : "";
    const walletType = walletTypeArr.includes(String(item.wallet_type))
      ? tEnum(enumWalletType[String(item.wallet_type)])
      : "";
    const mappedResult = [
      ["display_name", t("display_name"), item.wallet?.display_name],
      ["wallet_name", t("wallet_name"), item.wallet?.wallet_name],
      ["wallet_type", t("wallet_type"), walletType],
      // ["is_default", t("is_default"), isDefault],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), asset_name],
      ["balance", t("balance"), toAmount(item?.balance)],
      ["wallet_address", t("walletAddress"), item.wallet_address],
      ["status", t("walletStatus"), status],
      //
      [
        "latest_checkpoint_id",
        t("latest_checkpoint_id"),
        item.latest_check_point_id,
      ],
      [
        "latest_checked_blocked_no",
        t("latest_checked_blocked_no"),
        item.latest_check_point_block_number,
      ],
      [
        "certified_with_on_chain_balance",
        t("certified_with_on_chain_balance"),
        translate(
          `enumConstants.${
            enumCertifiedBalanceObj[String(item.is_check_point_certified)]
          }`
        ),
      ],
      [
        "last_certified_checkpointId",
        t("last_certified_checkpointId"),
        item.last_certified_check_point_id,
      ],
      //
      ["created_date", t("created_date"), toDisplayTime(item.created_date)],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
      [
        "wallet_settlement_time",
        t("wallet_settlement_time"),
        toDisplayTime(item.transfer_transaction?.wallet_settlement_date),
      ],
    ];
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
