import { useTranslate } from "react-admin";
import { GridColDef } from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/node-types";
import { usePermission } from "../../../../hooks";
import { useGenGridCol } from "../../../../utils/HelperComp";
import { TableColumnKey } from "../types/table";

interface useTableColumnsProps {
  renderCellFunc: any;
}

const useTableColumns = (props: useTableColumnsProps) => {
  const { renderCellFunc } = props;
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const translatePrefix = "resubmit.columns";
  const t = (key: string) => translate(`${translatePrefix}.${key}`);

  const checkColumnsPermission = (columns: GridColDef[]) => {
    const columnsWithPermission: { key: TableColumnKey; permission: string }[] =
      [
        {
          key: TableColumnKey.Operation,
          permission: FeatureCode.FeaturesTools.Resubmit.resubmit,
        },
      ];
    const tableColumns = columns;

    for (let i = 0; i < columnsWithPermission.length; i++) {
      const havePermission = hasPermission(columnsWithPermission[i].permission);

      if (!havePermission) {
        const index = columns.findIndex(
          (col) => col.field === columnsWithPermission[i].key
        );

        if (index !== -1) {
          tableColumns.splice(index, 1);
        }
      }
    }

    return tableColumns;
  };

  const columns: GridColDef[] = [
    useGenGridCol(TableColumnKey.Operation, t("operation"), {
      minWidth: 150,
      renderCell: renderCellFunc,
    }),
    useGenGridCol(TableColumnKey.BatchId, t("batch_id")),
    useGenGridCol(TableColumnKey.OperationType, t("operation_type")),
    useGenGridCol(TableColumnKey.ChainName, t("chain_name")),
    useGenGridCol(TableColumnKey.AssetName, t("asset_name")),
    useGenGridCol(TableColumnKey.TotalAmount, t("total_amount"), {
      minWidth: 170,
    }),
    useGenGridCol(TableColumnKey.TotalOperation, t("total_count")),
    useGenGridCol(TableColumnKey.Status, t("status"), {
      minWidth: 120,
    }),
    useGenGridCol(TableColumnKey.CreatedBy, t("created_by")),
    useGenGridCol(TableColumnKey.CreatedDate, t("created_date")),
  ];

  return { columns: checkColumnsPermission(columns) };
};

export default useTableColumns;
