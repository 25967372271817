export const generalInfoProps = [
    {
        leftTableCellProp: "created_date",
        rightTableCellProp: "wallet_settlement_date",
    },
    {
        leftTableCellProp: "direction",
        rightTableCellProp: "trans_type",
    },
    {
        leftTableCellProp: "status",
        rightTableCellProp: "asset_name",
    },
    {
        leftTableCellProp: "amount",
        rightTableCellProp: "trans_fee",
    },
    {
        leftTableCellProp: "post_balance",
        rightTableCellProp: "ref_no",
    },
];

export const payeePayerProps = [
    {
        leftTableCellProp: "display_name",
        rightTableCellProp: "client_id",
    },
    {
        leftTableCellProp: "wallet_address",
        rightTableCellProp: "wallet_tag",
    },
    {
        leftTableCellProp: "from_address",
        rightTableCellProp: "to_address",
    },
    {
        leftTableCellProp: "to_wallet_tag",
        rightTableCellProp: "",
    },
];


export const chainInfoProps = [
    {
        leftTableCellProp: "chain_name",
        rightTableCellProp: "block_number",
    },
    {
        leftTableCellProp: "block_hash",
        rightTableCellProp: "tx_hash",
    },
    {
        leftTableCellProp: "confirmation",
        rightTableCellProp: "trans_date",
    },
];