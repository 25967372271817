import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
} from "@mui/material";
import EnumWalletStatus from "@wallet-manager/node-types/dist/types/postgres/const/WalletStatus";
import EnumWalletTypes from "@wallet-manager/node-types/dist/types/postgres/const/WalletType";

import {
  getWalletBalance,
  postLedgerWithdrawRequestCreate,
  getLedgerBalance
} from "../../../api/merchant";
import AddressSelection from "../../../components/AddressSelection";
import { AssetSingleSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GridBox from "../../../components/GridBox";
import MpTextField from "../../../components/MpTextField";
import MpTextFieldNumberOnly from "../../../components/MpTextFieldNumberOnly";
import { Container } from "../../../components/MuiGenerals";
import YubiPrompter from "../../../components/Prompter";
import AddressBookSuggestion from "../../../components/AddressBookSuggestion";

import { useAlerting, useTranslation, useYubiPrompt } from "../../../hooks";
import {
  bigNumStrMulitpleDecimals,
  displayAmount,
  findDecimalByChainNameAndAsset,
  getDecimal,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./WithdrawRequest";

interface Ifields {
  client_id: string;
  chain_name: string;
  asset_name: string;
  sending_address: string;
  receiving_address: string;
  wallet_tag_of_receiving_address: string;
  transaction_amount: string;
}

interface IfieldsSubmit {
  client_id: string;
  from_wallet_address: string;
  to_address: string;
  chain_id: string | undefined;
  chain_type: number | undefined;
  asset_name: string;
  amount: string;
  to_wallet_tag: string;
  decimals: string;
  remarks?: string;
}

const translatePrefix = "ledgerWithdrawRequest";

export default function CreateRequestDialog(props: any) {
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  const { selectChainByPieces } = useChains();

  const initFields: Ifields = {
    client_id: "",
    chain_name: "",
    asset_name: "",
    sending_address: "",
    receiving_address: "",
    wallet_tag_of_receiving_address: "",
    transaction_amount: "",
  };
  const [fields, setFields] = useState<Ifields>(initFields);
  useEffect(() => {
    setFields(initFields);
  }, [zusDialog.meta]);

  const getParams: () => IfieldsSubmit = () => {
    const {
      client_id,
      sending_address: from_wallet_address,
      receiving_address: to_address,
      chain_name,
      asset_name,
      transaction_amount,
      wallet_tag_of_receiving_address: to_wallet_tag,
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });

    const decimals = findDecimalByChainNameAndAsset(
      String(chain_type),
      String(chain_id),
      asset_name
    );

    const amount = bigNumStrMulitpleDecimals(transaction_amount, decimals);

    return {
      client_id,
      from_wallet_address,
      to_address,
      to_wallet_tag,
      chain_id,
      chain_type,
      asset_name,
      amount,
      decimals,
    };
  };

  const apiParams = getParams();

  const handleConfirm = async () => {
    // required checking
    const mandatoryFieldsEntries = Object.entries(fields).filter(
      ([key, _value]) => key !== "wallet_tag_of_receiving_address"
    );
    const emptyFieldsEntries = mandatoryFieldsEntries.filter(
      ([_key, value]) => !value
    );
    const firstEmptyFieldEntries: [key: string, value: string] =
      emptyFieldsEntries[0];
    if (firstEmptyFieldEntries) {
      const field = firstEmptyFieldEntries[0];
      const fieldForDisplay = t(firstEmptyFieldEntries[0]);
      if (
        field === "asset_name" ||
        field === "chain_name" ||
        field === "sending_address"
      ) {
        const verb = t("select");
        return alerting(
          "warning",
          t("create_warning", { verb, field: fieldForDisplay })
        );
      }
      const verb = t("enter");

      return alerting(
        "warning",
        t("create_warning", { verb, field: fieldForDisplay })
      );
    }

    // check transaction amount > 0
    if (Number(fields.transaction_amount) <= 0) {
      const transactionAmountFieldName = t("transaction_amount");

      return alerting(
        "warning",
        t("must_be_larger_than_zero", { fieldName: transactionAmountFieldName })
      );
    }

    const res = await getResAfterYubi(
      postLedgerWithdrawRequestCreate,
      apiParams
    );

    if (!res) {
      return;
    }

    alerting("success", t("success_create"));
    zusDialog.close();
    setFields(initFields);
    zusParams.refetch();
  };

  if (!zusDialog.match("create_request_dialog")) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={zusDialog.match("create_request_dialog")}
        onClose={zusDialog.close}
        fullWidth={true}
        maxWidth={"md"}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle>{t("create_request")}</DialogTitle>
        <DialogContent>
          <InputFieldForm setFields={setFields} fields={fields} />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleConfirm()}
          >
            {tc("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              zusDialog.close();
              setFields(initFields);
            }}
          >
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function InputFieldForm(props: {
  setFields: Dispatch<SetStateAction<Ifields>>;
  fields: Ifields;
}) {
  const { fields, setFields } = props;
  const { t } = useTranslation(translatePrefix);
  const { selectAssetNamesByChain } = useAssets();
  const [displayBalanceObj, setDisplayBalanceObj] = useState<
    { balance: string; assetName: string } | undefined
  >(undefined);

  const [displayLedgerBalanceObj, setDisplayLedgerBalanceObj] = useState<
    { balance: string; assetName: string } | undefined
  >(undefined);
  
  const { selectChainByPieces } = useChains();
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });

  const walletTypeArr = [
    String(EnumWalletTypes.HotWallet),
    String(EnumWalletTypes.ClientWallet),
    String(EnumWalletTypes.ExternalWallet),
    String(EnumWalletTypes.InvokerWallet),
    String(EnumWalletTypes.SettlementWallet),
    String(EnumWalletTypes.SweepDestWallet),
  ]

  const initOrder  ={
    to_address: "",
    amount: "",
    to_wallet_tag: "",
    wallet_type: "",
    display_name: "",
  }
  const [order, setOrder] = useState(initOrder)

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_name: "" })),
    [fields.chain_name]
  );

  useEffect(() => {
    if (!fields.sending_address || !fields.asset_name) {
      return setDisplayBalanceObj(undefined);
    }
    const fetch = async () => {
      const res = await getWalletBalance({
        wallet_address: fields.sending_address,
        chain_type,
        chain_id,
        asset_names: [fields.asset_name],
        statuses: [EnumWalletStatus.Enabled],
      });
      if (!res || !res.rows.length) {
        return setDisplayBalanceObj(undefined);
      }
      const decimals = getDecimal({
        chain_id,
        chain_type,
        asset_name: fields.asset_name,
      });

      setDisplayBalanceObj({
        balance: displayAmount(res.rows[0].balance, decimals),
        assetName: fields.asset_name,
      });
    };
    fetch();
  }, [fields.sending_address, fields.asset_name]);

  useEffect(()=>{
    if(fields.chain_name !== "" && fields.client_id !== "" && fields.asset_name !== ""){
      const fetch = async()=>{
        const { chain_type, chain_id } = selectChainByPieces({ chain_name: fields.chain_name });
        const params = {
          client_id: fields.client_id,
          chain_id: chain_id,
          chain_type: chain_type,
          asset_name: fields.asset_name
        }
        const result: any = await getLedgerBalance(params);
        const decimals = getDecimal({
          chain_id,
          chain_type,
          asset_name: fields.asset_name,
        });

        if (result) {
          setDisplayLedgerBalanceObj({
            balance: displayAmount(result.balance, decimals),
            assetName: fields.asset_name,
          });
        }else if(result === null){
          // Request success, but balance is 0 will be null
          setDisplayLedgerBalanceObj({
            balance: displayAmount(0, decimals),
            assetName: fields.asset_name,
          });
        }
    }
    fetch();
    }else{
      setDisplayLedgerBalanceObj(undefined);
    }
  }, [fields.chain_name, fields.client_id, fields.asset_name])

  const gridBoxContent: Array<[string, JSX.Element]> = [
    [
      "client_id",
      <MpTextField
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) =>{
          setFields((f) => ({
            ...f,
            chain_name,
            wallet_tag_of_receiving_address: "",
            // reset receiving address
            receiving_address: "",
            asset_name: ""
          }))
          // reset receiving address component
          setOrder(initOrder);
        }
      }
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",
      <AssetSingleSelection
        label={t("ph_currency")}
        setChoice={(asset_name) => setFields((f) => ({ ...f, asset_name }))}
        choice={fields.asset_name}
        allItems={allAssetNames}
      />,
    ],
    [
      "hot_wallet_address",
      <AddressSelection
        wallet_types={[EnumWalletTypes.HotWallet]}
        gather_address={fields.sending_address}
        setGatherAddress={(sending_address: string) =>
          setFields((f) => ({ ...f, sending_address }))
        }
        chain_name={fields.chain_name}
        helperText={
          displayBalanceObj &&
          t("available_balance", {
            balance: displayBalanceObj.balance,
            assetName: displayBalanceObj.assetName,
          })
        }
      />,
    ],
    [
      "receiving_address",
      <AddressBookSuggestion
        item={order}
        isAllowFreeInput={true}
        isDisabled={fields.chain_name === ""}
        walletTypes={walletTypeArr}
        chain_name={fields.chain_name}
        setField={(name: keyof typeof initOrder, value: string) =>{
          if(name === "to_address"){
            setFields((fields) => ({ ...fields, receiving_address: value }));
          }
          setOrder((order) => ({ ...order, [name]: value }));
        }
      } 
    />
    ],

    [
      "wallet_tag_of_receiving_address",
      <MpTextField
        value={fields.wallet_tag_of_receiving_address}
        onChange={onChange("wallet_tag_of_receiving_address")}
        label={t("ph_wallet_tag_of_receiving_address")}
        disabled={fields.chain_name !== "XRPT"}
      />,
    ],
    [
      "transaction_amount",
      <MpTextFieldNumberOnly
        value={fields.transaction_amount}
        onChange={onChange("transaction_amount")}
        label={t("ph_transaction_amount")}
        sx ={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
              marginTop: 0,
            }
          }}
        helperText={
          displayLedgerBalanceObj &&
            t("available_client_balance", {
              balance: displayLedgerBalanceObj.balance,
              assetName: displayLedgerBalanceObj.assetName,
            })
        }
      />,
    ],
  ];

  return (
    <Container disableGutters maxWidth={false}>
      <GridBox
        translatePrefix={translatePrefix}
        data={gridBoxContent}
        itemSx={{ flexWrap: "nowrap" }}
      />
    </Container>
  );
}
