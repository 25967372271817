import * as React from "react";
import { Button, Dialog, DialogContent } from "../../components/MuiGenerals";
import { styled } from "@mui/material/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CallbackSettingList from "./CallbackSettingList";
import { usePermission } from "../../hooks";
import Tooltip from "@mui/material/Tooltip";
import { useTranslate } from "react-admin";
import { FeatureCodes } from "../../utils/constant";
const Pkey = FeatureCodes.report.MessageManagement;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 0,
    margin: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
    margin: 0,
  },
}));

export default function CallbackSettingDialogs() {
  const translate = useTranslate();
  const t = (key: string) => translate(`callbackSetting.${key}`);
  const [open, setOpen] = React.useState(false);
  const { hasPermission } = usePermission();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {hasPermission(Pkey.CallbackSettingView) && (
        <Tooltip title={t("callbackSettingToolTip")}>
          <Button sx={{ height: "48px" }} onClick={handleClickOpen}>
            <MoreHorizIcon sx={{ color: "black" }} />
          </Button>
        </Tooltip>
      )}
      <BootstrapDialog onClose={handleClose} open={open}>
        <DialogContent dividers>
          <CallbackSettingList handleClose={handleClose} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
