import { useTranslate } from "react-admin";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import Container from "@mui/material/Container";

import React from "react";
import { CustomPagination } from "../../../components/CustomPagination";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusListStore } from "../../../zustand/store";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Button } from "../../../components/MuiGenerals";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";
const translatePrefix = "tools";
const HistoryTabList = (props: any) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { listMapping } = props;
  const zusList = useZusListStore();
  const totalRecords = zusList.count;
  const content: GridRowsProp = listMapping("key", zusList.rows, { translate });

  const cellButton = (name: string) => (params: any) => {
    const batchId = params?.row?.batch_id;
    const [baseLink, icon] =
      name === "op"
        ? ["/operationRecords", <SummarizeIcon />]
        : ["/operationTransactionRecords", <AttachMoneyIcon />];
    const toNewTab = () => window.open(`${baseLink}?batchId=${batchId}`);
    return (
      <Button onClick={toNewTab} color="secondary" variant="contained">
        {icon}
      </Button>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("operation_type", t("operation_type")),
    useGenGridCol("action", t("action")),
    useGenGridCol("BatchStatus", t("BatchStatus")),
    useGenGridCol("operated_by", t("operated_by")),
    useGenGridCol("operation_time", t("operation_time")),
    useGenGridCol("1", t("viewOperation"), { renderCell: cellButton("op") }),
    useGenGridCol("2", t("viewOperationTransaction"), {
      renderCell: cellButton("opt"),
      minWidth: 170,
    }),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("history")
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={zusList.count}
        columns={columns}
        onPageChange={zusList.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
};
export default React.memo(HistoryTabList);
