import { QueryClient } from "react-query";

import { generateNonce } from "../utils/helper";
import { defaultIntervalPendingOperationsCount } from "./constant";

export const dateTimeInFormat = "yyyy-MM-dd HH:mm:ss";
export const dateInFormat = "yyyy-MM-dd";
export const nonce = generateNonce();
export const LAST_ACTIVE_UPPER_LIMIT_MINS = Number(
  process.env.REACT_APP_LAST_ACTIVE_UPPER_LIMIT_MINS || "30"
);
export const INTERVAL_PENDING_OPERATIONS_COUNT =
  process.env.REACT_APP_INTERVAL_PENDING_OPERATIONS_COUNT ||
  defaultIntervalPendingOperationsCount; // second
export const ENDPOINT_HOST = process.env.REACT_APP_ENDPOINT_HOST;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
//https://react-query-v3.tanstack.com/guides/initial-query-data#staletime-and-initialdataupdatedat
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 200, //ms
      cacheTime: 1 * (60 * 1000), // 1 minute
    },
  },
});
