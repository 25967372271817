import { useState, useEffect } from "react";
import { Button } from "../components/MuiGenerals";
import AlerterOnStart, {
  AlerterOnStartFace,
} from "../components/AlerterOnStart";
import { useLocation } from "react-router-dom";
import { LOGIN_URL } from "../utils/config";

import domainList from "../utils/domainList/domainList";
import { readAccessToken } from "../utils/helper";

export default function Login() {
  const sx = {
    container: {
      display: "flex",
      flexDirection: "column" as const,
      gap: "2rem",
      justifyContent: "center",
      alignItems: "center",
      height: "90vh",
    },
    textField: {
      maxWidth: "5rem",
    },
  };
  interface fieldsFace {
    appUuid: string;
  }
  const initFields = {
    appUuid: "",
  };
  const routerState = useLocation()?.state || {};
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { message, severity } = routerState as AlerterOnStartFace;

  const isLoggedIn = () => {
    const accessToken = readAccessToken();

    return accessToken && accessToken.length > 0;
  };

  useEffect(() => {
    const domainInfo = domainList.find(
      (d: any) => d.url === window.location.host
    );
    if (domainInfo) {
      setFields((fields) => ({ ...fields, appUuid: domainInfo.appUuid }));
    }
  }, []);

  const onSubmit = () => {
    if (isLoggedIn()) {
      window.location.replace("/");
    } else {
      const urlWithAppUuid = `${LOGIN_URL}?appUuid=${fields.appUuid}`;
      window.location.href = urlWithAppUuid || "";
    }
  };

  // const onChange = (
  //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setFields((fields) => ({ ...fields, [name]: value }));
  // };

  const disableLogin = fields.appUuid.length === 0;
  return (
    <div style={sx.container}>
      {/* <MpTextField
        sx={sx.textField}
        autoFocus
        name="appId"
        label="App Id"
        type="number"
        value={fields.appId}
        onChange={onChange}
      /> */}
      <Button variant="contained" onClick={onSubmit} disabled={disableLogin}>
        Login
      </Button>
      <AlerterOnStart message={message} severity={severity} />
    </div>
  );
}
