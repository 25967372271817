import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from "@reduxjs/toolkit";
import alerterReducer from "./alerterSlice";
import profileReducer from "./profileSlice";
import stuffReducer from "./stuffSlice";
import assetsReducer from "./assetsSlice";
import chainsReducer from "./chainsSlice";
import settingReducer from "./settingSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
const STORE_VERSION = Number(process.env.REACT_APP_STORE_VERSION || "0");
const persistConfig = {
  key: "root",
  version: STORE_VERSION,
  storage, //save in localStorage
  whitelist: ["profile", "assets", "chains"], // profile and visitor will be persisted
};

const reducers = combineReducers({
  alerter: alerterReducer,
  profile: profileReducer,
  stuff: stuffReducer,
  assets: assetsReducer,
  chains: chainsReducer,
  setting: settingReducer
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
