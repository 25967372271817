//[name , type] enum , referring to "constant.ts "
export const endpointLists = [
  {
    endpoint: "merchant_portal/assets/edit",
    name: "0",
    type: "1",
  },
  {
    endpoint: "merchant_portal/batch_sweep",
    name: "1",
    type: "0",
  },
  {
    endpoint: "access/batch_sweep",
    name: "1",
    type: "0",
  },

  {
    endpoint: "merchant_portal/batch_withdraw",
    name: "2",
    type: "0",
  },
  {
    endpoint: "access/batch_withdraw",
    name: "2",
    type: "0",
  },
  {
    endpoint: "merchant_portal/rescan_transactions",
    name: "3",
    type: "5",
  },

  {
    endpoint: "signer/approve_operation",
    name: "4",
    type: "2",
  },
  {
    endpoint: "access/verify_transaction_internal",
    name: "4",
    type: "1",
  },
  {
    endpoint: "access/verify_transaction",
    name: "4",
    type: "1",
  },
  {
    endpoint: "access/signed_operation_transactions",
    name: "4",
    type: "1",
  },
  {
    endpoint: "signer/reject_operation",
    name: "4",
    type: "3",
  },
  {
    endpoint: "signer/execute_operation",
    name: "4",
    type: "1",
  },

  {
    endpoint: "signer/upload_transaction",
    name: "4",
    type: "1",
  },
  {
    endpoint: "signer/withdraw_threshold_request",
    name: "5",
    type: "1",
  },
  {
    endpoint: "signer/withdraw_threshold_request/approve",
    name: "5",
    type: "2",
  },
  {
    endpoint: "signer/withdraw_threshold_request/reject",
    name: "5",
    type: "3",
  },

  {
    endpoint: "merchant_portal/wallet_management/get_address",
    name: "6",
    type: "0",
  },
  {
    endpoint: "access/get_address",
    name: "6",
    type: "0",
  },
  {
    endpoint: "merchant_portal/wallet_management/set_default_wallet",
    name: "6",
    type: "1",
  },

  {
    endpoint: "merchant_portal/balance_adjustment_proposals/create",
    name: "8",
    type: "0",
  },
  {
    endpoint: "merchant_portal/balance_adjustment_proposals/approve",
    name: "8",
    type: "2",
  },
  {
    endpoint: "merchant_portal/balance_adjustment_proposals/reject",
    name: "8",
    type: "3",
  },
  {
    endpoint: "merchant_portal/messages/reset_attempt",
    name: "9",
    type: "1",
  },
  {
    endpoint: "merchant_portal/callback_settings/save",
    name: "9",
    type: "1",
  },

  {
    endpoint: "merchant_portal/rescan_blocks",
    name: "10",
    type: "5",
  },

  {
    endpoint: "merchant_portal/rebroadcast_transactions",
    name: "11",
    type: "6",
  },
  {
    endpoint: "merchant_portal/cancel_stuck_batch",
    name: "11",
    type: "1",
  },

  {
    endpoint: "merchant_portal/yubi_users/register",
    name: "12",
    type: "7",
  },
  {
    endpoint: "merchant_portal/yubi_users/delete",
    name: "12",
    type: "4",
  },
  {
    endpoint: "merchant_portal/yubi_users/disable",
    name: "12",
    type: "1",
  },
  {
    endpoint: "merchant_portal/yubi_users/reject",
    name: "12",
    type: "3",
  },
  {
    endpoint: "merchant_portal/yubi_users/approve",
    name: "12",
    type: "2",
  },
  {
    endpoint: "merchant_portal/portal_users/register",
    name: "12",
    type: "0",
  },
  {
    endpoint: "merchant_portal/yubi_users/change-to-pending-enabled",
    name: "12",
    type: "1",
  },
  {
    endpoint: "merchant_portal/portal_users/bind_yubi_key",
    name: "13",
    type: "1",
  },
  {
    endpoint: "merchant_portal/portal_users/enable_oper_status",
    name: "13",
    type: "1",
  },
  {
    endpoint: "merchant_portal/portal_users/disable_oper_status",
    name: "13",
    type: "1",
  },
  {
    endpoint: "merchant_portal/portal_users/unbind_yubi_key",
    name: "13",
    type: "4",
  },
  {
    endpoint: "merchant_portal/portal_user_proposals/approve1",
    name: "13",
    type: "2",
  },
  {
    endpoint: "merchant_portal/portal_user_proposals/approve2",
    name: "13",
    type: "2",
  },
  {
    endpoint: "merchant_portal/portal_user_proposals/reject",
    name: "13",
    type: "3",
  },
  {
    endpoint: "merchant_portal/portal_user_profiles/upsert",
    name: "14",
    type: "1",
  },
  {
    endpoint: "signer/import_addresses",
    name: "15",
    type: "0",
  },
  {
    endpoint: "auth/verifyAccessToken",
    name: "16",
    type: "8",
  },
  {
    endpoint: "merchant_portal/wallet_management/update_wallet_display_name",
    name: "17",
    type: "1",
  },
  {
    endpoint: "merchant_portal/address_book_proposals/delete",
    name: "17",
    type: "4",
  },
  {
    endpoint:
      "merchant_portal/ledger_wallet_manager_deposit/approve_deposit_request",
    name: "18",
    type: "2",
  },
  {
    endpoint:
      "merchant_portal/ledger_wallet_manager_deposit/reject_deposit_request",
    name: "18",
    type: "3",
  },
  {
    endpoint: "merchant_portal/ledger_wallet_manager_deposit/accept_deposit",
    name: "18",
    type: "9",
  },
  {
    endpoint: "merchant_portal/ledger_withdraw_request/create_withdraw_request",
    name: "19",
    type: "0",
  },
  {
    endpoint:
      "merchant_portal/ledger_withdraw_request/approve_withdraw_request",
    name: "19",
    type: "2",
  },
  {
    endpoint: "merchant_portal/ledger_withdraw_request/reject_withdraw_request",
    name: "19",
    type: "3",
  },
  {
    endpoint: "merchant_portal/ledger_transfer_request/create_transfer_request",
    name: "20",
    type: "0",
  },
  {
    endpoint:
      "merchant_portal/ledger_transfer_request/approve_transfer_request",
    name: "20",
    type: "2",
  },
  {
    endpoint: "merchant_portal/ledger_transfer_request/reject_transfer_request",
    name: "20",
    type: "3",
  },
  {
    endpoint: "merchant_portal/ledger_client/create_client",
    name: "21",
    type: "0",
  },
  {
    endpoint: "merchant_portal/ledger_client/update_client",
    name: "21",
    type: "1",
  },
  {
    endpoint:
      "merchant_portal/operation_transaction_records/transaction_rescan",
    name: "3",
    type: "5",
  },
];
