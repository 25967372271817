import { useTranslate } from "react-admin";
import { Button } from "../components/MuiGenerals";
type Ilabel =
  | "search"
  | "reset"
  | "export"
  | "save"
  | "edit"
  | "approve"
  | "reject"
  | "confirm"
  | "cancel";
export default function GeneralBtn(p: {
  label: Ilabel;
  onClick: () => void;
  isHidden?: boolean;
  color?: "secondary" | "info" | "error";
  variant?: "contained" | "outlined";
  rest?: any;
}) {
  const translate = useTranslate();
  const tc = (key: string) => translate(`common.${key}`);
  const additionProps = {
    reset: { color: "info", variant: "contained" },
    export: { variant: "outlined" },
  }[p.label as string];
  const defaultProps = {
    color: "secondary",
    variant: "contained",
    ...additionProps,
  };
  const color = p.color ?? defaultProps.color;
  const variant = p.variant ?? defaultProps.variant;
  if (p.isHidden) return null;
  return (
    <Button
      onClick={p.onClick}
      color={color}
      variant={variant}
      label={p.label}
      {...p.rest}
    >
      {tc(p.label)}
    </Button>
  );
}
