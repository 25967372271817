import { isNil } from "lodash";
import { useRef, useState } from "react";
import { useTranslate } from "react-admin";

import DoneIcon from "@mui/icons-material/Done";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { WalletControlGroups } from "@wallet-manager/node-types/dist/src/access/const/AddressBookProposal";
import { WalletType } from "@wallet-manager/node-types/dist/src/postgres/const";

import {
  deleteAddressBookWallet,
  editAddressBookProposals,
  setDefaultMerchantWallet,
  updateWalletDisplayName,
} from "../../api/merchant";
import { OpDelete, OpEdit } from "../../assets/icons";
import { useAlerting, usePermission, useYubiPrompt } from "../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
import {
  dataGridDefaults,
  enumChainIdToType,
  enumWalletType,
  FeatureCodes,
} from "../../utils/constant";
import { copyToClipboard, findChainInfo } from "../../utils/helper";
import { useGenGridCol } from "../../utils/HelperComp";
import { useZusDialogStore } from "../../zustand/store";
import {
  ChainMultipleSelection,
  ChainSingleSelection,
} from "../ChainSelection";
import { CustomPagination } from "../CustomPagination";
import DialogInOne from "../DialogInOne";
import { useChains } from "../FetchConfig";
import { SingleSelection } from "../GeneralSelection";
import MpTextField from "../MpTextField";
import { Box, Button, Container, FormControl, Tooltip } from "../MuiGenerals";
import { NoRowsOverlay } from "../NoRowsOverlay";
import OpIconButton from "../OpIconButton";
import YubiPrompter from "../Prompter";

const isWalletType0or4or5Fn = (wallet_type: number) =>
  wallet_type === 0 || wallet_type === 4 || wallet_type === 5;
const Pkey = FeatureCodes.walletManagement.WalletManagement.TabWalletManagement;

export default function WalletManagementTabList(props: any) {
  const translate = useTranslate();
  const zusDialog = useZusDialogStore();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { hasPermission } = usePermission();
  const { selectChainByPieces } = useChains();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const { alerting } = useAlerting();
  const { list, page, setPage, listMapping, refresh } = props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, { t, translate });
  const isOperationColumnHidden = !(
    hasPermission(Pkey.SetDefaultWallet) ||
    hasPermission(Pkey.Edit) ||
    hasPermission(Pkey.Delete)
  );

  const WalletNameEditButton = (params: any) => {
    const sx = {
      box: {
        display: "flex",
        alignItems: "center",
        width: "1.5rem",
        minWidth: "1.5rem",
        cursor: "pointer",
        margin: "0 0.5rem",
        "&:hover": {
          filter: "brightness(130%)",
        },
        top: "-4px",
      },
      img: {
        filter: "grayscale(0)",
        width: "100%",
      },
    };

    const item = getItemByWalletId(params.row, list);

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Tooltip
          title={
            <>
              <Box>{t("display_name") + ": " + item.display_name}</Box>
              <Box>{t("wallet_name") + ": " + item.wallet_name}</Box>
            </>
          }
        >
          <span
            style={{
              paddingLeft: "0.5rem",

              textOverflow: "ellipsis",

              overflow: "hidden",
              whiteSpace: "nowrap",
              lineHeight: 2,
            }}
          >
            {item.display_name}
          </span>
        </Tooltip>
        {hasPermission(Pkey.EditDisplayName) && (
          <Box
            sx={sx.box}
            onClick={() =>
              zusDialog.open("operationDialog", {
                oldWalletName: item.display_name,
                walletId: item.id,
              })
            }
          >
            <img src={OpEdit} style={sx.img} alt="" />
          </Box>
        )}
      </div>
    );
  };
  const ChainNameColumn = (params: any) => {
    const sx = {
      box: {
        display: "flex",
        alignItems: "center",
        width: "1.5rem",
        minWidth: "1.5rem",
        cursor: "pointer",
        margin: "0 0.5rem",
        "&:hover": {
          filter: "brightness(130%)",
        },
        top: "-4px",
      },
      img: {
        filter: "grayscale(0)",
        width: "100%",
      },
    };

    const item = getItemByWalletId(params.row, list);
    const isWalletType0or4or5 = isWalletType0or4or5Fn(item.wallet_type);

    const isWalletType1 = item.wallet_type === 1;

    const isETHChainTypeWithSupportedChain =
      item.supported_chains &&
      item.chain_type === Number(enumChainIdToType.ETH);

    const getChainNameByChainTypeOnlyOrBothChainIdAndChainType = item.chain_id
      ? findChainInfo(String(item.chain_type), item.chain_id)?.name
      : selectChainByPieces({ chain_type: String(item.chain_type) })?.name;

    const displayETHSeriesDescriptionByWalletType =
      (isWalletType0or4or5 && "Supported ETH Series") ||
      (isWalletType1 && "All ETH Series");

    return (
      <Tooltip
        title={
          isETHChainTypeWithSupportedChain
            ? item.supported_chains
                .map(
                  (i: { chain_type: number; chain_id: string }) =>
                    findChainInfo(String(i.chain_type), i.chain_id)?.name
                )
                .sort((a: string, b: string) => a.localeCompare(b))
                .join(", ")
            : getChainNameByChainTypeOnlyOrBothChainIdAndChainType
        }
      >
        <div>
          {isETHChainTypeWithSupportedChain
            ? displayETHSeriesDescriptionByWalletType
            : getChainNameByChainTypeOnlyOrBothChainIdAndChainType}
        </div>
      </Tooltip>
    );
  };
  const newWalletName = useRef("");

  const CellButtons = (params: GridRenderCellParams) => {
    const item = getItemByWalletId(params.row, list);
    //{- - SetDefautl fun- -}
    const setDefault = async () => {
      const apiParams = {
        merchant_id: item.merchant_id,
        chain_type: item.chain_type,
        chain_id: item.chain_id,
        wallet_address: item.wallet_address,
        wallet_type: item.wallet_type,
      };
      const res = await setDefaultMerchantWallet(apiParams);
      if (!res) {
        return;
      }
      refresh();
      alerting("success", t("set_wallet_to_default"));
    };

    // "2": "Hot Wallet", "3": "Invoker Wallet",
    const defaulWalletChoice = ["2", "3"].map((id) =>
      translate(`enumConstants.${enumWalletType[id]}`)
    );
    const isDefault = params.row?.is_default === translate("enumConstants.Yes");
    const canBeDefault =
      defaulWalletChoice.includes(params.row?.wallet_type) && !isDefault;

    const handleDeleteWallet = () => {
      zusDialog.open("deleteDialog", {
        wallet_id: item.id,
      });
    };

    const getIsHotWalletOrInvokerWalletType = (walletType: number) => {
      // reference to enumWalletType
      // "2": "Hot Wallet",
      // "3": "Invoker Wallet",
      return Number(walletType) === 2 || Number(walletType) === 3;
    };

    const isHotWalletOrInvokerWalletType = getIsHotWalletOrInvokerWalletType(
      item.wallet_type
    );

    const isWalletType0or4or5 = isWalletType0or4or5Fn(item.wallet_type);

    const handleEditWallet = async () => {
      zusDialog.open("recordDialog", {
        wallet_name: item.wallet_name,
        display_name: item.display_name,
        wallet_address: item.wallet_address,
        wallet_type: item.wallet_type,
        walletId: item.id,
        isWalletType0or4or5,
        isHotWalletOrInvokerWalletType,
        isEnable: item.status === 1,
        observe_address: item.save_records,
        supported_chains: item.supported_chains,
        chain_type: item.chain_type,
        chain_id: Number(item.chain_id),
        controlGroups: item.control_groups || [WalletControlGroups.unassign],
        isDefaultWallet: isDefault,
      });
    };

    return (
      <>
        {hasPermission(Pkey.SetDefaultWallet) && (
          <Tooltip
            title={t("set_default")}
            style={{
              marginRight:
                hasPermission(Pkey.Edit) || hasPermission(Pkey.Delete)
                  ? "14px"
                  : undefined,
            }}
          >
            <div>
              <Button
                onClick={setDefault}
                disabled={!canBeDefault}
                color="secondary"
                variant="contained"
              >
                <DoneIcon />
              </Button>
            </div>
          </Tooltip>
        )}

        {hasPermission(Pkey.Edit) && (
          <div
            style={{
              marginRight: hasPermission(Pkey.Delete) ? "14px" : undefined,
            }}
          >
            <OpIconButton
              title={t("edit_wallet_record")}
              url={OpEdit}
              onClick={handleEditWallet}
            />
          </div>
        )}

        {hasPermission(Pkey.Delete) && (
          <div>
            <OpIconButton
              title={t("delete_record")}
              url={OpDelete}
              isDisabled={!isWalletType0or4or5}
              onClick={handleDeleteWallet}
            />
          </div>
        )}
      </>
    );
  };
  const dialogConfigOnDeleteRecord = {
    title: t("confirm_delete_wallet_record"),
    self: {
      open: zusDialog.match("deleteDialog"),
      onClose: zusDialog.close,
    },
    content: <></>,
    onCancel: zusDialog.close,
    onConfirm: async () => {
      const res = await getResAfterYubi(deleteAddressBookWallet, {
        wallet_id: zusDialog.meta.wallet_id,
      });

      if (!res) {
        return;
      }
      alerting("success", t("request_record_created"));
      zusDialog.closeAll();
      refresh();
    },
  } as const;

  const dialogConfigOnEditDisplayName = {
    title: t("edit_wallet_name"),
    self: {
      open: zusDialog.match("operationDialog"),
      onClose: () => {
        zusDialog.close();
        newWalletName.current = "";
      },
    },
    content: (
      <>
        <div>
          {t("existing_display_name")}: {zusDialog.meta.oldWalletName}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <label htmlFor="newWalletName">{t("new_display_name")}: </label>
          </div>
          <div style={{ paddingLeft: "12px" }}>
            <MpTextField
              id="newWalletName"
              sx={{
                "& .css-nz481w-MuiInputBase-input-MuiInput-input": {
                  fontSize: "12px",
                },
                margin: "12px 0",
                width: "231px",
                fontSize: 16,
              }}
              variant="standard"
              placeholder={t("edit_display_name_placeholder")}
              onChange={(e) => (newWalletName.current = e.target.value)}
            />
          </div>
        </div>
      </>
    ),
    onCancel: () => {
      zusDialog.close();
      newWalletName.current = "";
    },
    onConfirm: async () => {
      if (newWalletName.current.length === 0) {
        alerting("error", t("empty_display_name_error"));
        return;
      }
      // const otp = hasPermission(FeatureCodeYubikeyRegister)
      const res = await getResAfterYubi(updateWalletDisplayName, {
        wallet_id: zusDialog.meta.walletId.toString(),
        display_name: newWalletName.current,
      });

      // const res = await updateWalletDisplayName({
      //   wallet_id: zusDialog.meta.walletId.toString(),
      //   display_name: zusDialog.meta.newWalletName,
      // });
      if (!res) {
        return;
      }
      alerting("success", t("edited_display_name_message"));
      zusDialog.closeAll();
      refresh();
      newWalletName.current = "";
    },
  } as const;

  const dialogConfigOnEditRecord = {
    title: t("edit_wallet_record"),
    self: {
      open: zusDialog.match("recordDialog"),
      onClose: zusDialog.close,
    },
    content: <EditDialogContent />,
    onCancel: zusDialog.close,
    onConfirm: async () => {
      const isChainNameArrayNoChange =
        isNil(zusDialog.meta.updated_chain_name_array) ||
        zusDialog?.meta?.updated_chain_name_array.sort().join(", ") ===
          zusDialog?.meta?.chain_name_array.sort().join(", ");

      const updatedControlGroups = isNil(zusDialog.meta.updated_controlGroups)
        ? zusDialog.meta.updated_controlGroups
        : [zusDialog.meta.updated_controlGroups];

      const isControlGroupsNoChange =
        isNil(updatedControlGroups) ||
        zusDialog.meta.controlGroups?.sort().join(", ") ===
          updatedControlGroups.sort().join(", ");

      const isIsEnableNoChange =
        isNil(zusDialog.meta.updated_isEnable) ||
        zusDialog.meta.updated_isEnable === zusDialog.meta.isEnable;

      if (
        isChainNameArrayNoChange &&
        isIsEnableNoChange &&
        isControlGroupsNoChange

        /* &&
        zusDialog.meta.updated_observe_address ===
          zusDialog.meta.observe_address */
      ) {
        return alerting("error", t("noChangesMade"));
      }

      if (!isNil(zusDialog.meta.updated_chain_type)) {
        if (zusDialog.meta.updated_chain_type !== zusDialog.meta.chain_type) {
          return alerting("error", t("chainTypeDifferent"));
        } else if (
          !zusDialog.meta.updated_chain_type &&
          typeof zusDialog.meta.updated_chain_type !== "number"
        ) {
          return alerting("error", t("chainTypeSeriesError"));
        } else if (
          zusDialog.meta.updated_chain_type === -1 ||
          !zusDialog.meta.updated_chain_ids.length
        ) {
          return alerting("error", t("chainTypeError"));
        }
      }

      const getChainTypeAndChainIdsForApi = () => {
        // walletType: 0, 2, 3, 4, 5
        if (
          zusDialog.meta.isWalletType0or4or5 ||
          zusDialog.meta.isHotWalletOrInvokerWalletType
        ) {
          return {
            chain_type: isNil(zusDialog.meta.updated_chain_type)
              ? zusDialog.meta.chain_type
              : zusDialog.meta.updated_chain_type,
            chain_ids: isNil(zusDialog.meta.updated_chain_ids)
              ? [String(zusDialog.meta.chain_id)]
              : zusDialog.meta.updated_chain_ids,
          };
        }

        return {};
      };

      const chainTypeAndChainIdForApi = getChainTypeAndChainIdsForApi() || {};

      // updateField = 1 -> will ignore chainType and chainId param update
      // updateField = 4 -> will update wallet_supported_chain_id table with the chainType and chainId param
      // updateField = 5 -> will do both steps above
      const getEditApiUpdateFieldNumber = () => {
        // walletType: 0, 4, 5
        const isExternalOrSweepDestOrSettlementWalletType =
          zusDialog.meta.isWalletType0or4or5;
        // walletType: 2, 3
        const isHotWalletOrInvokerWalletType =
          zusDialog.meta.isHotWalletOrInvokerWalletType;
        if (isHotWalletOrInvokerWalletType) {
          return 5;
        }
        return 1;
      };

      const updateFieldNumber = getEditApiUpdateFieldNumber();

      const getStatus = () => {
        if (isNil(zusDialog.meta.updated_isEnable)) {
          return zusDialog.meta.isEnable ? 1 : -1;
        }

        return zusDialog.meta.updated_isEnable ? 1 : -1;
      };

      const status = getStatus();

      // edit api call
      const res = await getResAfterYubi(editAddressBookProposals, {
        wallet_id: zusDialog.meta.walletId,
        wallet_address: zusDialog.meta.wallet_address,
        wallet_type: zusDialog.meta.wallet_type,
        status,
        update_field: updateFieldNumber,
        control_groups: isNil(updatedControlGroups)
          ? zusDialog.meta.controlGroups
          : updatedControlGroups,
        ...chainTypeAndChainIdForApi,
        // display_name: zusDialog.meta.newWalletName,
        // save_records: zusDialog.meta.isWalletType0or4or5
        //   ? zusDialog.meta.updated_observe_address
        //   : undefined,
      });

      if (!res) {
        return;
      }
      alerting("success", t("request_record_created"));
      zusDialog.closeAll();
      refresh();
    },
  } as const;

  const proportionOfOperationBtns = [
    hasPermission(Pkey.SetDefaultWallet),
    hasPermission(Pkey.SetDefaultWallet),
    hasPermission(Pkey.SetDefaultWallet),
    hasPermission(Pkey.Edit),
    hasPermission(Pkey.Edit),
    hasPermission(Pkey.Delete),
    hasPermission(Pkey.Delete),
  ].filter((item) => item).length; // proportion of operationBtns to determine the operation column minWidth (set default btn counts as 3/7 because it is larger , the other two count as 2/7)

  const columns: GridColDef[] = [
    useGenGridCol("display_name", t("display_name"), {
      minWidth: 140,
      renderCell: WalletNameEditButton,
    }),

    // useGenGridCol("wallet_name", t("wallet_name"), { minWidth: 140 }),
    useGenGridCol("client_id", t("client_id"), { minWidth: 140 }),
    useGenGridCol("wallet_type", t("wallet_type"), { minWidth: 120 }),
    useGenGridCol("is_default", t("is_default"), { minWidth: 120 }),
    useGenGridCol("risk_level", t("risk_level")),
    useGenGridCol("chain_name", t("chain_name"), {
      renderCell: ChainNameColumn,
      minWidth: 120,
    }),
    useGenGridCol("wallet_address", t("walletAddress")),
    useGenGridCol("status", t("walletStatus")),
    useGenGridCol("assignWalletTime", t("assignWalletTime"), {
      minWidth: 150,
    }),
    useGenGridCol("last_modified_date", t("last_modified_date")),
    useGenGridCol("3", t("operation"), {
      minWidth: 232 * (proportionOfOperationBtns / 7),
      renderCell: CellButtons,
    }),
  ].filter((col) => {
    if (col.field === "3" && isOperationColumnHidden) {
      return false;
    }
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <Container maxWidth={false} disableGutters>
        <YubiPrompter {...prompterConfig} />
        <DialogInOne {...dialogConfigOnEditDisplayName} />
        <DialogInOne {...dialogConfigOnDeleteRecord} />
        <DialogInOne {...dialogConfigOnEditRecord} />
        <ColumnHiddenMessage hasColHidden={hasColHidden} />
        <DataGrid
          {...dataGridDefaults}
          localeText={localeText}
          columnVisibilityModel={colsShown}
          onColumnVisibilityModelChange={setColsShown}
          rows={content}
          columns={columns}
          rowCount={list.count}
          page={page}
          onPageChange={setPage}
          components={{
            NoRowsOverlay,
            Footer: CustomPagination,
          }}
          componentsProps={{
            footer: { totalRecords },
          }}
        />
      </Container>
    </>
  );
}

const EditDialogContent = () => {
  const zusDialog = useZusDialogStore();
  const { selectChainByPieces } = useChains();

  const isETHChainTypeWithSupportedChain =
    zusDialog.meta.supported_chains &&
    zusDialog.meta.chain_type === Number(enumChainIdToType.ETH);

  const getChainCodeByChainTypeOnlyOrBothChainIdAndChainType = zusDialog.meta
    .chain_id
    ? findChainInfo(String(zusDialog.meta.chain_type), zusDialog.meta.chain_id)
        ?.chain_code
    : selectChainByPieces({
        chain_type: String(zusDialog.meta.chain_type),
      })?.chain_code;

  const chainCodeArr = isETHChainTypeWithSupportedChain
    ? zusDialog.meta.supported_chains.map(
        (i: { chain_type: number; chain_id: string }) =>
          findChainInfo(String(i.chain_type), i.chain_id)?.chain_code
      )
    : [getChainCodeByChainTypeOnlyOrBothChainIdAndChainType];
  zusDialog.meta.chain_name_array = chainCodeArr.sort((a: string, b: string) =>
    a.localeCompare(b)
  );

  interface InitFields {
    isWalletEnable: boolean;
    // for multipleChainSelection
    chain_names: string[];
    // for singleChainSelection
    chain_name: string;
    controlGroups: string;
  }
  const initFields: InitFields = {
    isWalletEnable: zusDialog.meta.isEnable,
    // for multipleChainSelection
    chain_names:
      chainCodeArr.findIndex((item: any) => item === undefined) === -1
        ? chainCodeArr
        : [],
    controlGroups: zusDialog.meta.controlGroups || WalletControlGroups.unassign,
    // for singleChainSelection
    chain_name: getChainCodeByChainTypeOnlyOrBothChainIdAndChainType || "",
  };
  const translatePrefix = "walletManagement";
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const [fields, setFields] = useState(initFields);

  const { alerting } = useAlerting();
  const addressOnClick = (wallet_address: string) => {
    copyToClipboard(wallet_address);
    alerting("success", translate("report.copied"));
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const controlGroupsNameFn = (item: string, value: any) => {
    if (value === WalletControlGroups.unassign) {
      return "Unassigned";
    }
    return capitalizeFirstLetter(item);
  };

  return (
    <>
      <div
        style={{
          margin: "12px 0",
          fontSize: 16,
          display: "flex",
          flexDirection:
            zusDialog?.meta?.wallet_name?.length > 52 ? "column" : undefined,
        }}
      >
        <span style={{ marginRight: "4px" }}>{t("wallet_name")}: </span>
        <span style={{ wordWrap: "break-word" }}>
          {zusDialog.meta.wallet_name}
        </span>
      </div>
      <div style={{ margin: "12px 0", fontSize: 16 }}></div>
      <div
        style={{
          margin: "12px 0",
          fontSize: 16,
          display: "flex",
          flexDirection:
            zusDialog?.meta?.display_name?.length > 52 ? "column" : undefined,
        }}
      >
        <span style={{ marginRight: "4px" }}>{t("display_name")}:</span>
        <span style={{ wordWrap: "break-word" }}>
          {zusDialog.meta.display_name}
        </span>
      </div>
      <div style={{ margin: "12px 0", fontSize: 16 }}>
        {t("wallet_type")}:{" "}
        {translate(
          `enumConstants.${enumWalletType[zusDialog.meta.wallet_type]}`
        )}
      </div>
      <div
        style={{
          margin: "12px 0",
          fontSize: 16,
          display: "flex",
          flexDirection:
            zusDialog?.meta?.wallet_address?.length > 52 ? "column" : undefined,
        }}
      >
        <span style={{ marginRight: "4px" }}>{t("wallet_address")}:</span>
        <span
          style={{
            wordWrap: "break-word",
            color: "#4A90F7",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => addressOnClick(zusDialog.meta.wallet_address)}
        >
          {zusDialog.meta.wallet_address}
        </span>
      </div>

      {zusDialog.meta.isHotWalletOrInvokerWalletType ? (
        <ChainSingleSelection
          isFullWidth
          label={t("chain_name")}
          choice={fields.chain_name}
          setChoice={(chain_name: any) => {
            const chainInfo = selectChainByPieces({
              chain_name,
            });

            zusDialog.meta.updated_chain_type = chainInfo.chain_type;
            zusDialog.meta.updated_chain_ids = [chainInfo.chain_id];

            zusDialog.meta.updated_chain_name_array = [chain_name];

            setFields((prev) => ({ ...prev, chain_name }));
          }}
          targetedChainType={zusDialog.meta.chain_type}
          disabled={zusDialog.meta.isDefaultWallet}
          isDisabledUnselect
        />
      ) : (
        <ChainMultipleSelection
          isFullWidth
          label={
            zusDialog.meta.isWalletType0or4or5
              ? t("supported_chains")
              : t("chain_name")
          }
          choices={fields.chain_names}
          disabled={
            zusDialog.meta.isDefaultWallet || zusDialog.meta.isWalletType0or4or5
          }
          setChoices={(choices) => {
            const chain_ids: string[] = [];

            choices.forEach((chain_name) => {
              const chainInfo = selectChainByPieces({ chain_name });
              if (chainInfo.chain_type) {
                zusDialog.meta.updated_chain_type = chainInfo.chain_type;
              }
              if (chainInfo.chain_id) {
                chain_ids.push(chainInfo.chain_id);
              }
            });
            zusDialog.meta.updated_chain_name_array = choices.sort(
              (a: string, b: string) => a.localeCompare(b)
            );
            zusDialog.meta.updated_chain_ids = chain_ids;
            setFields((prev) => ({ ...prev, chain_names: choices }));
          }}
          isFixedChainType
          isDisabledUnselect
        />
      )}

      <FormGroup style={{ marginTop: "12px" }}>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={fields.isObserveWallet}
              disabled={!zusDialog.meta.isWalletType0or4or5}
              onChange={() =>
                setFields({
                  ...fields,
                  isObserveWallet: !fields.isObserveWallet,
                })
              }
            />
          }
          label={t("observeWalletError")}
        /> */}
        <RadioGroup
          row
          value={fields.isWalletEnable}
          onChange={() => {
            setFields((prev) => {
              const isWalletEnable = !prev.isWalletEnable;

              zusDialog.meta.updated_isEnable = isWalletEnable;

              return { ...fields, isWalletEnable };
            });
          }}
        >
          <span style={{ marginRight: 8, fontSize: 16 }}>
            {t("walletStatus")}:
          </span>
          <FormControlLabel
            style={{ marginTop: "-8px" }}
            control={<Radio value={true} />}
            label={t("enable")}
            disabled={zusDialog.meta.isDefaultWallet}
          />
          <FormControlLabel
            style={{ marginTop: "-8px" }}
            control={<Radio value={false} />}
            label={t("disable")}
            disabled={zusDialog.meta.isDefaultWallet}
          />
        </RadioGroup>
      </FormGroup>

      {zusDialog?.meta?.wallet_type === WalletType.HotWallet && (
        <FormControl fullWidth={true}>
          <SingleSelection
            label={t("ph_control_groups")}
            value={fields.controlGroups}
            onChange={(e) => {
              setFields((fields) => {
                const updated_controlGroups = e.target.value;
                zusDialog.setMeta({ ...zusDialog.meta, updated_controlGroups });
                return { ...fields, controlGroups: updated_controlGroups };
              });
            }}
            nameFn={controlGroupsNameFn}
            enumData={WalletControlGroups}
            isNoSorting={true}
            clearSelect={() => {}}
          />
        </FormControl>
      )}
    </>
  );
};
function getItemByWalletId(theRow: any, list: any) {
  const wallet_id = theRow?.rawData?.wallet_id;
  return list.rows.find((item: any) => item.id === wallet_id);
}
