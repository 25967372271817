import { Dispatch, SetStateAction } from "react";

export interface IInitFields {
  asset_name: string[];
  chain_name: string;
  withdraw_status: string;
  push_deposit_message: string;
}
export type IParentProps = {
  fields: IInitFields;
  setFields: Dispatch<SetStateAction<IInitFields>>;
};
export type IProps = {
  params: any;
};

//init Key-Value Pairs
export const initFields = {
  asset_name: [],
  chain_name: "",
  withdraw_status: "",
  push_deposit_message: "",
};

export const translatePrefix = "assetList";
