import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import {
  downloadTransactionDetailsAll,
  downloadTransactionDetailsApi,
  downloadTransactionDetailsPortal,
  downloadTransactionDetailsSweep,
  getOperationAll,
  getOperationApi,
  getOperationDetailsAll,
  getOperationDetailsApi,
  getOperationDetailsPortal,
  getOperationDetailsSweep,
  getOperationDetailStatAll,
  getOperationDetailStatApi,
  getOperationDetailStatPortal,
  getOperationDetailStatSweep,
  getOperationPortal,
  getOperationSweep,
  getOpManualReasonAll,
  getOpManualReasonApi,
  getOpManualReasonPortal,
  getOpManualReasonSweep,
  getWalletManagement,
  reEstimateFeeApiWithdraw,
  reEstimateFeeGetAll,
  reEstimateFeeSweep,
  reEstimateFeeWithdrawPortal,
} from "../../../api/merchant";
import {
  OpApprove,
  OpDownload,
  OpEstimate,
  OpRedirect,
  OpSign,
  OpView,
} from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import DialogInOne from "../../../components/DialogInOne";
import { ToolCryptoText } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton from "../../../components/OpIconButton";
import { WalletTypeIconWithAddress } from "../../../components/WalletTypeIconWithAddress";
import { useAlerting, usePermission } from "../../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";
import {
  dataGridDefaults,
  enumNewMerchantOperationStatus,
  enumOperationType,
  FeatureCodes,
  tableConfig,
} from "../../../utils/constant";
import {
  calculateSum,
  displayAmount,
  enumMapping,
  getDecimal,
  todaysDateForFileName,
  toDisplayTime,
} from "../../../utils/helper";
import { getFilteredColumns, useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import { opDayStat } from "./types";

import type { IoperationTab } from "./MerchantOperation";
const Pkey = FeatureCodes.assetManagement.MerchantOperation;
const translatePrefix = "assetManagement";
const Enum = {
  newMerchantOperationStatus: enumMapping(enumNewMerchantOperationStatus),
  operationType: enumMapping(enumOperationType),
};
const operationCreatorFilterEnum = {
  all: 0,
  api_withdraw_tab: 1,
  portal_withdraw_tab: 2,
  sweep_tab: 3,
};

const getOneFromAll =
  (apiFn: typeof getOperationAll) =>
  async (operation_creator_filter: number, batchId: string) => {
    const res = await apiFn({
      batchIdArr: [batchId],
      operation_creator_filter,
      pageSize: 20,
      page: 0,
    });
    return res.rows[0] || {};
  };

const tabApis = {
  all: {
    download: downloadTransactionDetailsAll,
    opDetail: getOperationDetailsAll,
    stat: getOperationDetailStatAll,
    opReason: getOpManualReasonAll,
    estimate: reEstimateFeeGetAll,
    getOne: getOneFromAll(getOperationAll),
  },
  api_withdraw_tab: {
    download: downloadTransactionDetailsApi,
    opDetail: getOperationDetailsApi,
    stat: getOperationDetailStatApi,
    opReason: getOpManualReasonApi,
    estimate: reEstimateFeeApiWithdraw,
    getOne: getOneFromAll(getOperationApi),
  },
  portal_withdraw_tab: {
    download: downloadTransactionDetailsPortal,
    opDetail: getOperationDetailsPortal,
    stat: getOperationDetailStatPortal,
    opReason: getOpManualReasonPortal,
    estimate: reEstimateFeeWithdrawPortal,
    getOne: getOneFromAll(getOperationPortal),
  },
  sweep_tab: {
    download: downloadTransactionDetailsSweep,
    opDetail: getOperationDetailsSweep,
    stat: getOperationDetailStatSweep,
    opReason: getOpManualReasonSweep,
    estimate: reEstimateFeeSweep,
    getOne: getOneFromAll(getOperationSweep),
  },
} as const;
export default function MerchantOperationList(props: any) {
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { list, setPage, page, setUploadDialog, listMapping } = props;
  const tabName = props.tabName as IoperationTab;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  const cellButtons = (
    params: GridRenderCellParams,
    canShow: {
      viewDetail: boolean;
      approval: boolean;
      download: boolean;
      sign: boolean;
    }
  ) => {
    return (
      <ActionComponent {...{ params, setUploadDialog, tabName, canShow }} />
    );
  };
  const tabApi = tabApis[tabName];
  const zusDialog = useZusDialogStore();
  const HistoryButton = (item: GridRenderCellParams) => {
    const onClick = async () => {
      const operationType = String(item.row.rawData.operation_type);
      const { batch_id } = item.row;
      const reason: any =
        operationType === enumOperationType.Withdraw
          ? await tabApi.opReason({ batch_id })
          : "";
      const time =
        operationType === enumOperationType.Withdraw
          ? await tabApi.stat({ batch_id })
          : "";

      const checking_hour = time ? time.checking_hour : "";
      const approveReason = reason
        ? reason.map((item: any) => ({
            ...item,
            checking_hour: checking_hour,
          }))
        : "";

      const combineContent = {
        ...item.row,
        approveReason,
        isHistory: true,
        opArrDetail: [],
      };
      zusDialog.open("approvalDialog", {
        from: "progress",
        row: combineContent,
      });
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "55%",
        }}
      >
        <span>{item.row?.approval_progress}</span>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={onClick}
          size="1rem"
        />
      </Box>
    );
  };
  const ListEstimatedCell = (item: Partial<GridRenderCellParams>) => {
    const batch_id = item.row.batch_id;
    const onClick = async () => {
      const operation_creator_filter = operationCreatorFilterEnum[tabName];
      const getOne = tabApis[tabName].getOne;
      const res = await getOne(operation_creator_filter, batch_id);
      const { miner_fee_asset_name } = res;
      const latest_estimated_miner_fee = displayAmount(
        res.estimated_miner_fee,
        res.estimated_miner_fee_decimals
      );

      const display_estimated_miner_fee = res.is_estimating_miner_fee
        ? t("estimating")
        : latest_estimated_miner_fee;
      zusDialog.open("operationDialog", {
        batch_id,
        status: res.status,
        miner_fee_asset_name,
        estimated_miner_fee: display_estimated_miner_fee,
        last_estimated_miner_fee_date: toDisplayTime(
          res.last_estimated_miner_fee_date
        ),
      });
    };

    const old_estimated_miner_fee = item.row.estimated_miner_fee;
    const display_estimated_miner_fee = (function () {
      if (item.row.rawData?.is_estimating_miner_fee) return t("estimating");
      return old_estimated_miner_fee || "";
    })();
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
          columnGap: ".5rem",
        }}
      >
        <ToolCryptoText
          tooltip={display_estimated_miner_fee}
          width={"max-content"}
        />
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={onClick}
          isDisabled={item.row.rawData?.is_estimating_miner_fee}
          size="1rem"
        />
      </Box>
    );
  };

  const isTab = {
    all: tabName === "all",
    api: tabName === "api_withdraw_tab",
    portal: tabName === "portal_withdraw_tab",
    sweep: tabName === "sweep_tab",
  };

  const canShow = {
    viewDetail:
      (isTab.all && hasPermission(Pkey.TabAll.ViewDetail)) ||
      (isTab.api && hasPermission(Pkey.TabApiWithdraw.ViewDetail)) ||
      (isTab.portal && hasPermission(Pkey.TabPortalWithdraw.ViewDetail)) ||
      (isTab.sweep && hasPermission(Pkey.TabSweep.ViewDetail)),
    approval: hasPermission(Pkey.Approve.prefix) || hasPermission(Pkey.Reject),
    download:
      (isTab.all && hasPermission(Pkey.TabAll.Download)) ||
      (isTab.api && hasPermission(Pkey.TabApiWithdraw.Download)) ||
      (isTab.portal && hasPermission(Pkey.TabPortalWithdraw.Download)) ||
      (isTab.sweep && hasPermission(Pkey.TabSweep.Download)),
    sign:
      (isTab.all && hasPermission(Pkey.TabAll.Sign)) ||
      (isTab.api && hasPermission(Pkey.TabApiWithdraw.Sign)) ||
      (isTab.portal && hasPermission(Pkey.TabPortalWithdraw.Sign)) ||
      (isTab.sweep && hasPermission(Pkey.TabSweep.Sign)),
  };

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 200,
      renderCell: (params) => cellButtons(params, canShow),
    }),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("approval_progress", t("approval_progress"), {
      renderCell: HistoryButton,
      minWidth: 120,
    }),
    useGenGridCol("creator_type", t("creator_type"), { minWidth: 120 }),
    useGenGridCol("operation_type", t("operation_type")),
    useGenGridCol("status", t("status"), { minWidth: 120 }),
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 120 }),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("total_amount", t("totalAmount"), { minWidth: 170 }),
    useGenGridCol("total_operation", t("total_operation")),
    useGenGridCol("created_by", t("created_by")),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("last_executed_by", t("last_executed_by"), { minWidth: 120 }),
    useGenGridCol("last_executed_date", t("last_executed_date"), {
      minWidth: 170,
    }),
    useGenGridCol("last_modified_date", t("last_modified_date")),
    useGenGridCol("miner_fee_asset_name", t("miner_fee_asset_name"), {
      minWidth: 130,
    }),
    useGenGridCol("estimated_miner_fee", t("estimated_miner_fee"), {
      minWidth: 170,
      renderCell: ListEstimatedCell,
    }),
    useGenGridCol("prepared_miner_fee", t("prepared_miner_fee"), {
      minWidth: 170,
    }),
    useGenGridCol("remarks", t("remark"), { minWidth: 170 }),
  ];

  const { filteredColumns } = getFilteredColumns(columns, [
    {
      fieldName: "operation",
      shouldFilter: Object.values(canShow).every((val) => !val),
    },
  ]);

  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    filteredColumns,
    getColumnHideConfig()
  );

  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={list.count}
        columns={filteredColumns}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
}

function ActionComponent(props: any) {
  const { params, setUploadDialog } = props;
  const canShow = props.canShow as {
    viewDetail: boolean;
    approval: boolean;
    download: boolean;
    sign: boolean;
  };
  const tabName = props.tabName as IoperationTab;
  const tabApi = tabApis[tabName];
  const translate = useTranslate();
  const { alerting } = useAlerting();

  const t = (key: string, option?: string) =>
    translate(`operations.${key}`, option);
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();

  const moreOperationDetailsApi = async () => {
    const batchId = params.row?.batch_id;
    if (!batchId) alerting("error", `batchId has error, ${batchId}`);
    zusDialog.open("recordDialog", {
      batchId,
      page: 0,
      pageSize: tableConfig.pageSize,
      chain_type: params.row?.chain_type,
      chain_id: params.row?.chain_id,
      creator_type: params.row?.creator_type,
    });
  };

  const onDownloadTransaction = async () => {
    const onDownloadResponse = await tabApi.download(params.row.batch_id);
    if (!onDownloadResponse) {
      return;
    }
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(onDownloadResponse)], {
      type: "text/plain",
    });
    const fileParam = params.row;
    const chain_code = fileParam.rawData?.chain_code || "";
    const timeStamp = todaysDateForFileName();
    element.href = URL.createObjectURL(file);
    element.download = `${fileParam.batch_id}_${chain_code}_${fileParam.asset_name}_${timeStamp}_unsigned_tx.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    return onDownloadResponse;
  };

  const onBroadcast = () => {
    setUploadDialog({ bol: true, info: params.row });
  };

  const mergeArrays = (
    opHourlyStatArr: Array<opDayStat>,
    opDayStatArr: Array<opDayStat>
  ) => {
    const material = {
      chain_type: params.row.chain_type,
      chain_id: params.row.chain_id,
      asset_name: params.row.asset_name,
    };
    const decimal = getDecimal(material);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    // Concatenate the two arrays
    const hourlyStatRes = opHourlyStatArr.map((item: any) => {
      const newObj = { ...item };
      newObj["hrly_amt"] = toAmount(newObj["hourly_amount"]);
      newObj["hrly_count"] = newObj["hourly_count"];
      delete newObj["hourly_amount"];
      delete newObj["hourly_count"];
      return newObj;
    });
    const dayStatRes = opDayStatArr.map((item: any) => {
      const newObj = { ...item };
      newObj["day_hourly_amount"] = toAmount(newObj["hourly_amount"]);
      return newObj;
    });
    const mergedArr = hourlyStatRes.concat(dayStatRes);

    // Create a map to store the merged objects
    const mergedMap = new Map();
    mergedArr.forEach((obj: any) => {
      // Get the address property
      const address = obj.address;
      const operation_seq = obj.operation_seq;
      // Check if this address is already in the map
      if (mergedMap.has(address)) {
        // If it is, merge the current object with the existing one
        const existingObj = mergedMap.get(address);
        const mergedObj = {
          address: address,
          operation_seq,
          hrly_amt: obj.hrly_amt || existingObj.hrly_amt,
          day_hourly_amount:
            obj.day_hourly_amount || existingObj.day_hourly_amount,
          hrly_count: obj.hrly_count || existingObj.hrly_count,
          hourly_count: obj.hourly_count || existingObj.hourly_count,
          asset_name: obj.asset_name || existingObj.asset_name,
        };
        mergedMap.set(address, mergedObj);
      } else {
        // If it's not, add the current object to the map
        mergedMap.set(address, obj);
      }
    });
    // Convert the map back to an array and return it
    const mergedArrWithNoDuplicates = Array.from(mergedMap.values());
    const addIdToMergedArr = mergedArrWithNoDuplicates.map(
      (item, index: number) => {
        return { ...item, id: index + 1 };
      }
    );

    return addIdToMergedArr;
  };

  const onPreApprove = async () => {
    const rawOpDetails = await tabApi.stat({
      batch_id: params.row.batch_id,
    });

    const reason: any = await tabApi.opReason({
      batch_id: params.row.batch_id,
    });
    const opDetailList = mergeArrays(rawOpDetails.hour, rawOpDetails.day);
    const amtWithoutCommas = opDetailList.map((item: any) => {
      const newObj = { ...item };
      if (newObj.day_hourly_amount) {
        newObj.day_hourly_amount = newObj.day_hourly_amount.replaceAll(",", "");
      }
      if (newObj.hrly_amt) {
        newObj.hrly_amt = newObj.hrly_amt.replaceAll(",", "");
      }
      return newObj;
    });
    const trxHrlyCount = calculateSum(amtWithoutCommas, "hrly_count");
    const trxHrlyAmt = calculateSum(amtWithoutCommas, "hrly_amt");
    const trxDailyAmt = calculateSum(amtWithoutCommas, "day_hourly_amount");
    const { checking_hour } = rawOpDetails;

    const reasonWithCheckingTime = reason.map((item: any) => {
      return { ...item, checking_hour: checking_hour };
    });
    const fullParams = {
      ...params.row,
      opArrDetail: opDetailList,
      trxDayAmt: trxDailyAmt ? trxDailyAmt : "",
      trxHrCount: trxHrlyCount ? trxHrlyCount : "",
      trxHrAmt: trxHrlyAmt ? trxHrlyAmt : "",
      approveReason: reasonWithCheckingTime, //each element in an array with checking_hour
      checkTime: checking_hour,
    };

    zusDialog.open("approvalDialog", {
      from: "operation",
      row: fullParams,
      tabName,
    });
  };
  const rowStatus = String(params.row.rawData?.status || NaN);
  const isStatus = {
    prepared: rowStatus === enumNewMerchantOperationStatus.Prepared,
    approve: rowStatus === enumNewMerchantOperationStatus.Verified,
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
    >
      <OpIconButton
        title={t("view")}
        url={OpView}
        onClick={moreOperationDetailsApi}
        isHidden={!canShow.viewDetail}
      />
      <OpIconButton
        title={t("approveAndRejectButton")}
        url={OpApprove}
        isHidden={!canShow.approval}
        isDisabled={!isStatus.approve}
        onClick={onPreApprove}
      />
      <OpIconButton
        title={t("download")}
        url={OpDownload}
        isHidden={!canShow.download}
        isDisabled={!isStatus.prepared}
        onClick={onDownloadTransaction}
      />
      <OpIconButton
        title={t("sign")}
        url={OpSign}
        isHidden={!canShow.sign}
        isDisabled={!isStatus.prepared}
        onClick={onBroadcast}
      />
    </Box>
  );
}
export function MoreDetailsDialog(props: { operationTab: IoperationTab }) {
  const zusDialog = useZusDialogStore();
  const matchedDialog = zusDialog.match("recordDialog");
  const { operationTab } = props;
  const apiParams = zusDialog.meta;
  const onPageChange = (page: number) =>
    zusDialog.setMeta({ ...zusDialog.meta, page });
  const tabApi = tabApis[operationTab];
  const listRes = useQuery(
    ["getOperationDetails", apiParams],
    () => tabApi.opDetail(apiParams),
    { enabled: matchedDialog }
  );
  const { rows = [], count = 0 } = listRes.data || {};
  const newRows = rows.map((item) => ({
    ...item,
    created_date: toDisplayTime(item.created_date),
    last_modified_date: toDisplayTime(item.last_modified_date),
  }));
  const wallet_addresses_set = new Set<string>();
  newRows.forEach((item) => wallet_addresses_set.add(item.to_address));

  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);

  const getParams = () => {
    const { chain_type, chain_id } = zusDialog.meta;

    return {
      chain_type,
      chain_id,
      wallet_address: Array.from(wallet_addresses_set),
      pageSize: 50,
      page: 0,
    };
  };

  const params = getParams();

  const listWalletAddressIconRes = useQuery(
    ["getWalletManagement", params],
    () => getWalletManagement(params),
    { enabled: matchedDialog }
  );

  const list = listWalletAddressIconRes.data;

  const ToAddressWithWalletIcon = (props: any) => {
    const { wallet_type, display_name } = list?.rows?.find(
      (item: any) => item.wallet_address === props.row.to_address
    ) || { display_name: "", wallet_type: -1 };

    return (
      <WalletTypeIconWithAddress
        isCopy={true}
        walletObjFromDatabase={{ wallet_type, display_name }}
        walletAddress={props.row.to_address}
      />
    );
  };

  const { creator_type = "" } = zusDialog.meta || {};

  const detailColumns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id"), {}),
    useGenGridCol("creator_type", t("creator_type"), {
      valueGetter: () => creator_type,
    }),
    useGenGridCol("operation_seq", t("operation_seq"), {
      minWidth: 130,
    }),
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 120,
    }),
    useGenGridCol("wallet_version", t("wallet_version")),
    useGenGridCol("wallet_path", t("path"), {
      minWidth: 120,
    }),
    useGenGridCol("operation_type", t("operation_type"), {
      valueGetter: (params) => {
        const type = Enum.operationType[params.row?.operation_type];
        return translate(`enumConstants.${type}`);
      },
    }),
    useGenGridCol("from_address", t("from_address"), {}),
    useGenGridCol("to_address", t("to_address"), {
      renderCell: ToAddressWithWalletIcon,
      minWidth: 350,
    }),
    useGenGridCol("to_wallet_tag", t("to_wallet_tag"), {}),
    useGenGridCol("asset_name", t("asset_name"), {
      minWidth: 120,
    }),
    useGenGridCol("amount", t("amount"), {
      minWidth: 120,
      valueGetter: (params) => {
        return displayAmount(params.row.amount, params.row.decimals);
      },
    }),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("last_modified_date", t("last_modified_date"), {}),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    detailColumns,
    getColumnHideConfig("detail")
  );
  if (!matchedDialog) return null;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DialogInOne
        {...{
          title: t("operationDetails"),
          self: {
            open: matchedDialog,
            onClose: zusDialog.close,
          },
          content: (
            <DataGrid
              {...dataGridDefaults}
              localeText={localeText}
              columnVisibilityModel={colsShown}
              onColumnVisibilityModelChange={setColsShown}
              rows={newRows}
              rowCount={count}
              columns={detailColumns}
              onPageChange={onPageChange}
              rowsPerPageOptions={tableConfig.rowsPerPageOptions}
              components={{
                NoRowsOverlay,
                Footer: CustomPagination,
              }}
              componentsProps={{
                footer: { totalRecords: count },
              }}
            />
          ),
          onCancel: zusDialog.close,
          isConfirmHidden: true,
          onConfirm: () => {},
          rest: { maxWidth: "md" },
        }}
      />
    </>
  );
}
export function ReEstimateDialog(props: { tabName: IoperationTab }) {
  const zusDialog = useZusDialogStore();
  const matchedDialog = zusDialog.match("operationDialog");
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { tabName } = props;
  const {
    batch_id,
    status,
    miner_fee_asset_name,
    estimated_miner_fee,
    last_estimated_miner_fee_date,
  } = zusDialog.meta;
  const detail = {
    id: -1,
    batch_id,
    miner_fee_asset_name,
    estimated_miner_fee,
    last_estimated_miner_fee_date,
  };
  const ActionCell = (item: GridRenderCellParams) => {
    const { batch_id } = item.row;
    const onEstimate = async () => {
      const tabApi = tabApis[tabName];
      const res = await tabApi.estimate({ batchId: batch_id });
      if (!res) {
        return;
      }
      const estimated_miner_fee = t("estimating");
      zusDialog.setMeta({ ...zusDialog.meta, estimated_miner_fee });
    };
    const isButtonShow = [
      Enum.newMerchantOperationStatus.Verified,
      Enum.newMerchantOperationStatus.Approved,
      Enum.newMerchantOperationStatus.Prepared,
      Enum.newMerchantOperationStatus.Signed,
      Enum.newMerchantOperationStatus.SignFailed,
      Enum.newMerchantOperationStatus.Rejected,
    ].some((s) => s === String(status));
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "55%",
        }}
      >
        <ToolCryptoText tooltip={estimated_miner_fee} />
        <OpIconButton
          title={t("re_estimate")}
          url={OpEstimate}
          onClick={onEstimate}
          isHidden={!isButtonShow}
          isDisabled={
            String(status) !== Enum.newMerchantOperationStatus.Verified
          }
          size="1rem"
        />
      </Box>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("miner_fee_asset_name", t("miner_fee_asset_name")),
    useGenGridCol("estimated_miner_fee", t("estimated_miner_fee"), {
      renderCell: ActionCell,
    }),
    useGenGridCol(
      "last_estimated_miner_fee_date",
      t("last_estimated_miner_fee_date")
    ),
  ];
  if (!matchedDialog) return null;
  return (
    <DialogInOne
      {...{
        title: t("estimated_miner_fee"),
        self: {
          open: matchedDialog,
          onClose: zusDialog.close,
        },
        content: (
          <DataGrid
            {...dataGridDefaults}
            disableColumnMenu
            paginationMode="client"
            hideFooterPagination
            rows={[detail]}
            columns={columns}
            onPageChange={() => {}}
            rowsPerPageOptions={tableConfig.rowsPerPageOptions}
            components={{
              NoRowsOverlay,
            }}
          />
        ),
        onCancel: () => zusDialog.close(),
        isConfirmHidden: true,
        onConfirm: () => {},
        rest: { maxWidth: "md" },
      }}
    />
  );
}
