import endOfYesterday from 'date-fns/endOfYesterday';
import startOfYesterday from 'date-fns/startOfYesterday';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowsProp
} from '@mui/x-data-grid';

import { getMerchantReportByWallet, listFace } from '../../api/merchant';
import { AssetMultiSelection } from '../../components/AssetSelection';
import { ChainSingleSelection } from '../../components/ChainSelection';
import { CustomPagination } from '../../components/CustomPagination';
import { useDatePicker } from '../../components/DatePicker';
import { useAssets, useChains } from '../../components/FetchConfig';
import GeneralBtn from '../../components/GeneralBtn';
import {
  Box,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../../components/MuiGenerals';
import { useAlerting, usePermission } from '../../hooks';
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig
} from '../../hooks/useColumnHide';
import {
  dataGridDefaults,
  enumWalletType,
  FeatureCodes,
  tableConfig
} from '../../utils/constant';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  separateBatchStrings
} from '../../utils/helper';
import { DivideLine, genField, useGenGridCol } from '../../utils/HelperComp';
import { customSx } from '../../utils/styling';
import { useZusDialogStore } from '../../zustand/store';

const Pkey = FeatureCodes.report.MerchantReport;
export default function MerchantReportSummary(props: {
  Tabs: (props: any) => JSX.Element;
}) {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const { Tabs } = props;
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  return (
    <>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          page={page}
          setPage={setPage}
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <TheList
            list={list}
            setPage={setPage}
            page={page}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </>
  );
}
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
  walletTypes: string[];
  walletNamesString: string;
  walletAddress: string;
}
const initFields = {
  asset_names: [],
  chain_name: "",
  walletTypes: [],
  walletNamesString: "",
  walletAddress: "",
};

// filter only Hot Wallet, Invoker Wallet from constant
const walletTypes = "Hot Wallet,Invoker Wallet".split(",");
// const walletTypes =
//   "External Wallet,Hot Wallet,Invoker Wallet,Settlement Wallet,Sweep Dest Wallet".split(
//     ","
//   );
function FilterBar(props: any) {
  const { count, setList, page, setPage, isSearchBtnClick, setSearchBtnClick } =
    props;
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const defaultDate = {
    start: { defaultDate: startOfYesterday() },
    end: { defaultDate: endOfYesterday() },
  };
  const DateObj = useDatePicker({
    ...defaultDate,
    // isTimezoneConvert: false,
    isEndOfSecond: false,
  });
  const [pageSize] = useState(tableConfig.pageSize);
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces } = useChains();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const { walletNamesString, walletTypes, chain_name, ...rest } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const walletNames = separateBatchStrings(walletNamesString);
    const walletTypesId = Object.entries(enumWalletType)
      .filter(([_, name]) => walletTypes.join(",").includes(name))
      .map(([id]) => id);
    return {
      ...rest,
      chain_type,
      chain_id,
      walletNames,
      wallet_types: !walletTypesId.length ? [2, 3] : walletTypesId,
      mark_date_from: DateObj.start,
      mark_date_to: DateObj.end,
      page,
      pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getMerchantReportByWallet({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, isSearchBtnClick]);
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
    setSearchBtnClick(true);
  };
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiPagingFn = (page: number, pageSize: number, signal: any) =>
      getMerchantReportByWallet({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiPagingFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, {
      translate,
      omitKeys: ["wallet_address"],
    });
    const config = {};
    downloadFiles(`Merchant Summary Report`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    // DateObj.resetDate();
    DateObj.clearDate();
  };
  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const F = genField({ t }, [
    ["date", <DateObj.Picker type="date" />],
    [
      "wallet_type",
      <FormControl>
        <InputLabel>{t("phWallet_type")}</InputLabel>
        <Select
          name="walletTypes"
          multiple
          value={fields.walletTypes}
          onChange={onArraySelectChange}
        >
          {Object.values(walletTypes).map((name, i) => (
            <MenuItem key={i} value={name}>
              {translate(`enumConstants.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_names",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
  ]);
  const showExport = hasPermission(Pkey.TabSummary.Export);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn label="export" isHidden={!showExport} onClick={onExport} />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.date}
        {F.wallet_type}
        {F.chain_name}
        {F.asset_names}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { translate, omitKeys = [] } = config || {};
  const t = (key: string) => translate(`report.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const mappedResult = [
      [
        "wallet_type",
        t("wallet_type"),
        translate(`enumConstants.${enumWalletType[item?.wallet_type]}`),
      ],
      ["wallet_address", t("wallet_address"), item.wallet_address],
      ["asset_name", t("asset_names"), item.asset_name],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      [
        "opening_balance",
        t("opening_balance"),
        item.opening_balance === null ? "0" : toAmount(item.opening_balance),
      ],
      [
        "in_hot_wallet_deposit",
        t("in_hot_wallet_deposit"),
        toAmount(item.in_hot_wallet_deposit),
      ],
      [
        "in_sweep_to_hot_wallet",
        t("in_sweep_to_hot_wallet"),
        toAmount(item.in_sweep_to_hot_wallet),
      ],
      [
        "in_hot_wallet_transfer",
        t("in_hot_wallet_transfer"),
        toAmount(item.in_hot_wallet_transfer),
      ],
      [
        "in_hot_wallet_topup",
        t("in_hot_wallet_topup"),
        toAmount(item.in_hot_wallet_topup),
      ],
      // ["in_withdraw", t("in_withdraw"), toAmount(item.in_withdraw)],
      [
        "in_provision_withdraw",
        t("in_provision_withdraw"),
        toAmount(item.in_provision_withdraw),
      ],
      [
        "in_invoker_deposit",
        t("in_invoker_deposit"),
        toAmount(item.in_invoker_deposit),
      ],
      [
        "in_invoker_transfer",
        t("in_invoker_transfer"),
        toAmount(item.in_invoker_transfer),
      ],
      [
        "in_merchant_deposit",
        t("in_merchant_deposit"),
        toAmount(item.in_merchant_deposit),
      ],
      ["in_sweep", t("in_sweep"), toAmount(item.in_sweep)],
      [
        "in_merchant_transfer",
        t("in_merchant_transfer"),
        toAmount(item.in_merchant_transfer),
      ],
      // ["in_settlement", t("in_settlement"), toAmount(item.in_settlement)],
      // [
      //   "in_external_transfer",
      //   t("in_external_transfer"),
      //   toAmount(item.in_external_transfer),
      // ],
      // [
      //   "in_settlement_to_hot_wallet",
      //   t("in_settlement_to_hot_wallet"),
      //   toAmount(item.in_settlement_to_hot_wallet),
      // ],
      // [
      //   "in_settlement_to_invoker_wallet",
      //   t("in_settlement_to_invoker_wallet"),
      //   toAmount(item.in_settlement_to_invoker_wallet),
      // ],
      // [
      //   "in_settlement_to_sweep_dest_wallet",
      //   t("in_settlement_to_sweep_dest_wallet"),
      //   toAmount(item.in_settlement_to_sweep_dest_wallet),
      // ],
      [
        "in_balance_adjustment",
        t("in_balance_adjustment"),
        toAmount(item.in_balance_adjustment),
      ],
      ["in_caution", t("in_caution"), toAmount(item.in_caution)],
      ["out_withdraw", t("out_withdraw"), toAmount(item.out_withdraw)],
      [
        "out_merchant_withdraw",
        t("out_merchant_withdraw"),
        toAmount(item.out_merchant_withdraw),
      ],
      [
        "out_withdraw_deposit",
        t("out_withdraw_deposit"),
        toAmount(item.out_withdraw_deposit),
      ],
      [
        "out_client_wallet_topup",
        t("out_client_wallet_topup"),
        toAmount(item.out_client_wallet_topup),
      ],
      [
        "out_hot_wallet_transfer",
        t("out_hot_wallet_transfer"),
        toAmount(item.out_hot_wallet_transfer),
      ],
      [
        "out_hot_wallet_topup",
        t("out_hot_wallet_topup"),
        toAmount(item.out_hot_wallet_topup),
      ],
      [
        "out_provision_for_withdraw",
        t("out_provision_for_withdraw"),
        toAmount(item.out_provision_for_withdraw),
      ],
      [
        "out_invoker_transfer",
        t("out_invoker_transfer"),
        toAmount(item.out_invoker_transfer),
      ],
      [
        "out_merchant_transfer",
        t("out_merchant_transfer"),
        toAmount(item.out_merchant_transfer),
      ],
      // [
      //   "out_client_deposit",
      //   t("out_client_deposit"),
      //   toAmount(item.out_client_deposit),
      // ],
      // [
      //   "out_hot_wallet_deposit",
      //   t("out_hot_wallet_deposit"),
      //   toAmount(item.out_hot_wallet_deposit),
      // ],
      // [
      //   "out_invoker_deposit",
      //   t("out_invoker_deposit"),
      //   toAmount(item.out_invoker_deposit),
      // ],
      ["out_settlement", t("out_settlement"), toAmount(item.out_settlement)],
      // [
      //   "out_external_transfer",
      //   t("out_external_transfer"),
      //   toAmount(item.out_external_transfer),
      // ],
      [
        "out_balance_adjustment",
        t("out_balance_adjustment"),
        toAmount(item.out_balance_adjustment),
      ],
      ["out_caution", t("out_caution"), toAmount(item.out_caution)],
      ["trans_fee", t("trans_fee"), toAmount(item.trans_fee)],
      ["closing_balance", t("closingBalance"), toAmount(item.closing_balance)],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

function TheList(props: {
  list: listFace;
  listMapping: any;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  isSearchBtnClick: boolean;
  page: number;
}) {
  const { list, listMapping, setPage, page, isSearchBtnClick } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const content: GridRowsProp = listMapping("key", list.rows, {
    translate,
  });

  const columns: GridColDef[] = [
    useGenGridCol("wallet_type", t("wallet_type")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("opening_balance", t("opening_balance")),
    useGenGridCol("in_hot_wallet_deposit", t("in_hot_wallet_deposit")),
    useGenGridCol("in_sweep_to_hot_wallet", t("in_sweep_to_hot_wallet")),
    useGenGridCol("in_hot_wallet_transfer", t("in_hot_wallet_transfer")),
    useGenGridCol("in_hot_wallet_topup", t("in_hot_wallet_topup")),
    // useGenGridCol("in_withdraw", t("in_withdraw")),
    useGenGridCol("in_provision_withdraw", t("in_provision_withdraw")),
    useGenGridCol("in_invoker_deposit", t("in_invoker_deposit")),
    useGenGridCol("in_invoker_transfer", t("in_invoker_transfer")),
    useGenGridCol("in_merchant_deposit", t("in_merchant_deposit")),
    useGenGridCol("in_sweep", t("in_sweep")),
    useGenGridCol("in_merchant_transfer", t("in_merchant_transfer")),
    // useGenGridCol("in_settlement", t("in_settlement")),
    // useGenGridCol("in_external_transfer", t("in_external_transfer")),
    useGenGridCol("in_balance_adjustment", t("in_balance_adjustment")),
    useGenGridCol("in_caution", t("in_caution")),
    useGenGridCol("out_withdraw", t("out_withdraw")),
    useGenGridCol("out_merchant_withdraw", t("out_merchant_withdraw")),
    useGenGridCol("out_withdraw_deposit", t("out_withdraw_deposit")),
    useGenGridCol("out_client_wallet_topup", t("out_client_wallet_topup")),
    useGenGridCol("out_hot_wallet_transfer", t("out_hot_wallet_transfer")),
    useGenGridCol("out_hot_wallet_topup", t("out_hot_wallet_topup")),
    useGenGridCol(
      "out_provision_for_withdraw",
      t("out_provision_for_withdraw")
    ),
    useGenGridCol("out_invoker_transfer", t("out_invoker_transfer")),
    useGenGridCol("out_merchant_transfer", t("out_merchant_transfer")),
    // useGenGridCol("out_client_deposit", t("out_client_deposit")),
    // useGenGridCol("out_hot_wallet_deposit", t("out_hot_wallet_deposit")),
    // useGenGridCol("out_invoker_deposit", t("out_invoker_deposit")),
    useGenGridCol("out_settlement", t("out_settlement")),
    // useGenGridCol("out_external_transfer", t("out_external_transfer")),
    useGenGridCol("out_balance_adjustment", t("out_balance_adjustment")),
    useGenGridCol("out_caution", t("out_caution")),
    useGenGridCol("trans_fee", t("trans_fee")),
    useGenGridCol("closing_balance", t("closingBalance")),
  ];

  const totalRecords = list.count;
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("summary")
  );
  return (
    <div>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </div>
  );
}
