import endOfMonth from 'date-fns/endOfMonth';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfMonth from 'date-fns/startOfMonth';
import startOfYesterday from 'date-fns/startOfYesterday';
import { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  getMerchantRangeReport,
  getMerchantReport,
  listFace
} from '../../api/merchant';
import { AssetMultiSelection } from '../../components/AssetSelection';
import { ChainSingleSelection } from '../../components/ChainSelection';
import { useDatePicker } from '../../components/DatePicker';
import { useAssets, useChains } from '../../components/FetchConfig';
import GeneralBtn from '../../components/GeneralBtn';
import MpTextField from '../../components/MpTextField';
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '../../components/MuiGenerals';
import { useAlerting, usePermission, useTabs } from '../../hooks';
import {
  enumWalletType,
  FeatureCodes,
  tableConfig
} from '../../utils/constant';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findChainInfoByChainTypeOnly,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  separateBatchStrings,
  toOnlyDate
} from '../../utils/helper';
import { DivideLine, genField } from '../../utils/HelperComp';
import { customSx } from '../../utils/styling';
import { useZusDialogStore } from '../../zustand/store';
import MerchantReportList from './MerchantReportList';
import MerchantReportSummary from './MerchantReportSummary';

const Pkey = FeatureCodes.report.MerchantReport;
export default function MerchantReport() {
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const { hasPermission } = usePermission();
  const tabsArr = [
    hasPermission(Pkey.TabDaily.prefix) && {
      name: t("dailyReport"),
      value: "daily",
    },
    hasPermission(Pkey.TabCustom.prefix) && {
      name: t("customReport"),
      value: "custom",
    },
    hasPermission(Pkey.TabSummary.prefix) && {
      name: t("summary_report"),
      value: "summary",
    },
  ].filter((tab) => tab) as { name: string; value: string }[];
  const { Tabs, tabValue } = useTabs(tabsArr);
  if (tabsArr.length === 0) return null;
  return (
    <Box sx={customSx.layoutBox}>
      <Tabs children={<Report rangeType={tabValue} />} />
    </Box>
  );
}

function Report(props: { rangeType: string }) {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const { rangeType } = props;
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  if (rangeType === "daily" && !hasPermission(Pkey.TabDaily.prefix))
    return <></>;
  if (rangeType === "custom" && !hasPermission(Pkey.TabCustom.prefix))
    return <></>;
  if (rangeType === "summary" && hasPermission(Pkey.TabSummary.prefix))
    return <MerchantReportSummary Tabs={Tabs} />;

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          setPage={setPage}
          page={page}
          rangeType={rangeType}
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <MerchantReportList
            list={list}
            page={page}
            setPage={setPage}
            rangeType={rangeType}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </>
  );
}
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
  walletTypes: string[];
  walletNamesString: string;
  walletAddress: string;
}
const initFields = {
  asset_names: [],
  chain_name: "",
  walletTypes: [],
  walletNamesString: "",
  walletAddress: "",
};

// filter only Hot Wallet, Invoker Wallet from constant
const walletTypes = "Hot Wallet,Invoker Wallet".split(",");
// const walletTypes =
//   "External Wallet,Hot Wallet,Invoker Wallet,Settlement Wallet,Sweep Dest Wallet".split(
//     ","
//   );
function FilterBar(props: any) {
  const {
    count,
    setList,
    page,
    setPage,
    rangeType,
    isSearchBtnClick,
    setSearchBtnClick,
  } = props;

  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const now = new Date();
  const dateConfigObj = {
    daily: {
      start: { defaultDate: startOfYesterday() },
      end: { defaultDate: endOfYesterday() },
    },
    custom: {
      start: { defaultDate: startOfMonth(now) },
      end: { defaultDate: endOfMonth(now) },
    },
  };
  const defaultDate = dateConfigObj[rangeType as keyof typeof dateConfigObj];
  const DateObj = useDatePicker({
    ...defaultDate,
    // isTimezoneConvert: false,
    isEndOfSecond: false,
  });
  const pageSize = tableConfig.pageSize;
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces } = useChains();
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const { walletNamesString, walletTypes, chain_name, ...rest } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const walletNames = separateBatchStrings(walletNamesString);
    const walletTypesId = Object.entries(enumWalletType)
      .filter(([_, name]) => walletTypes.join(",").includes(name))
      .map(([id]) => id);
    return {
      ...rest,
      chain_type,
      chain_id,
      display_names: walletNames,
      wallet_types: !walletTypesId.length ? [2, 3] : walletTypesId,
      mark_date_from: DateObj.start,
      mark_date_to: DateObj.end,
      page,
      pageSize,
    };
  };
  const apiFn =
    rangeType === "daily" ? getMerchantReport : getMerchantRangeReport;
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await apiFn({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, isSearchBtnClick]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
    setSearchBtnClick(true);
  };
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiPagingFn = (page: number, pageSize: number, signal: any) =>
      apiFn({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiPagingFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, {
      rangeType,
      translate,
    });
    const reportRange = rangeType === "daily" ? "Daily" : "Custom";
    const config = {};
    downloadFiles(`Merchant ${reportRange} Report`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    // DateObj.resetDate();
    DateObj.clearDate();
  };
  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const F = genField({ t }, [
    ["date", <DateObj.Picker type="date" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_names",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "wallet_type",
      <FormControl>
        <InputLabel>{t("phWallet_type")}</InputLabel>
        <Select
          name="walletTypes"
          multiple
          value={fields.walletTypes}
          onChange={onArraySelectChange}
        >
          {Object.values(walletTypes).map((name, i) => (
            <MenuItem key={i} value={name}>
              {translate(`enumConstants.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "display_name",
      <MpTextField
        label={t("phDisplayName")}
        value={fields.walletNamesString}
        onChange={onChange("walletNamesString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.display_names.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_address",
      <MpTextField
        label={t("phWallet_address")}
        value={fields.walletAddress}
        onChange={onChange("walletAddress")}
      />,
    ],
  ]);
  const showDailyExport =
    rangeType === "daily" && hasPermission(Pkey.TabDaily.Export);
  const showCustomExport =
    rangeType === "custom" && hasPermission(Pkey.TabCustom.Export);
  const showExport = showDailyExport || showCustomExport;
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn label="export" isHidden={!showExport} onClick={onExport} />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.date}
        {F.wallet_type}
        {F.chain_name}
        {F.asset_names}
        {F.display_name}
        {F.wallet_address}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { rangeType = "custom", translate } = config;
  const t = (key: string) => translate(`report.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const isDaily = rangeType === "daily";
    const markDateCol = item.mark_date &&
      isDaily && ["mark_date", t("date"), toOnlyDate(item.mark_date)];
    const mappedResult = [
      markDateCol,
      [
        "display_name",
        t("display_name"),
        isDaily ? item?.wallet?.display_name : item.display_name,
      ],
      [
        "wallet_type",
        t("wallet_type"),
        translate(`enumConstants.${enumWalletType[item?.wallet_type]}`),
      ],
      ["wallet_address", t("wallet_address"), item.wallet_address],
      [
        "chain_name",
        t("chain_name"),
        item.chain_id
          ? findChainInfo(String(item.chain_type), item.chain_id)?.name
          : findChainInfoByChainTypeOnly(String(item.chain_type)),
      ],
      ["asset_name", t("asset_names"), item.asset_name],
      [
        "opening_balance",
        t("opening_balance"),
        item.opening_balance === null ? "0" : toAmount(item.opening_balance),
      ],
      [
        "in_hot_wallet_deposit",
        t("in_hot_wallet_deposit"),
        toAmount(item.in_hot_wallet_deposit),
      ],
      [
        "in_sweep_to_hot_wallet",
        t("in_sweep_to_hot_wallet"),
        toAmount(item.in_sweep_to_hot_wallet),
      ],
      [
        "in_hot_wallet_transfer",
        t("in_hot_wallet_transfer"),
        toAmount(item.in_hot_wallet_transfer),
      ],
      [
        "in_hot_wallet_topup",
        t("in_hot_wallet_topup"),
        toAmount(item.in_hot_wallet_topup),
      ],
      // ["in_withdraw", t("in_withdraw"), toAmount(item.in_withdraw)],
      [
        "in_provision_withdraw",
        t("in_provision_withdraw"),
        toAmount(item.in_provision_withdraw),
      ],
      [
        "in_invoker_deposit",
        t("in_invoker_deposit"),
        toAmount(item.in_invoker_deposit),
      ],
      [
        "in_invoker_transfer",
        t("in_invoker_transfer"),
        toAmount(item.in_invoker_transfer),
      ],
      [
        "in_merchant_deposit",
        t("in_merchant_deposit"),
        toAmount(item.in_merchant_deposit),
      ],
      ["in_sweep", t("in_sweep"), toAmount(item.in_sweep)],
      [
        "in_merchant_transfer",
        t("in_merchant_transfer"),
        toAmount(item.in_merchant_transfer),
      ],
      // ["in_settlement", t("in_settlement"), toAmount(item.in_settlement)],
      // [
      //   "in_external_transfer",
      //   t("in_external_transfer"),
      //   toAmount(item.in_external_transfer),
      // ],
      // [
      //   "in_settlement_to_hot_wallet",
      //   t("in_settlement_to_hot_wallet"),
      //   toAmount(item.in_settlement_to_hot_wallet),
      // ],
      // [
      //   "in_settlement_to_invoker_wallet",
      //   t("in_settlement_to_invoker_wallet"),
      //   toAmount(item.in_settlement_to_invoker_wallet),
      // ],
      // [
      //   "in_settlement_to_sweep_dest_wallet",
      //   t("in_settlement_to_sweep_dest_wallet"),
      //   toAmount(item.in_settlement_to_sweep_dest_wallet),
      // ],
      [
        "in_balance_adjustment",
        t("in_balance_adjustment"),
        toAmount(item.in_balance_adjustment),
      ],
      ["in_caution", t("in_caution"), toAmount(item.in_caution)],
      ["out_withdraw", t("out_withdraw"), toAmount(item.out_withdraw)],
      [
        "out_merchant_withdraw",
        t("out_merchant_withdraw"),
        toAmount(item.out_merchant_withdraw),
      ],
      [
        "out_withdraw_deposit",
        t("out_withdraw_deposit"),
        toAmount(item.out_withdraw_deposit),
      ],
      [
        "out_client_wallet_topup",
        t("out_client_wallet_topup"),
        toAmount(item.out_client_wallet_topup),
      ],
      [
        "out_hot_wallet_transfer",
        t("out_hot_wallet_transfer"),
        toAmount(item.out_hot_wallet_transfer),
      ],
      [
        "out_hot_wallet_topup",
        t("out_hot_wallet_topup"),
        toAmount(item.out_hot_wallet_topup),
      ],
      [
        "out_provision_for_withdraw",
        t("out_provision_for_withdraw"),
        toAmount(item.out_provision_for_withdraw),
      ],
      [
        "out_invoker_transfer",
        t("out_invoker_transfer"),
        toAmount(item.out_invoker_transfer),
      ],
      [
        "out_merchant_transfer",
        t("out_merchant_transfer"),
        toAmount(item.out_merchant_transfer),
      ],
      // [
      //   "out_client_deposit",
      //   t("out_client_deposit"),
      //   toAmount(item.out_client_deposit),
      // ],
      // [
      //   "out_hot_wallet_deposit",
      //   t("out_hot_wallet_deposit"),
      //   toAmount(item.out_hot_wallet_deposit),
      // ],
      // [
      //   "out_invoker_deposit",
      //   t("out_invoker_deposit"),
      //   toAmount(item.out_invoker_deposit),
      // ],
      ["out_settlement", t("out_settlement"), toAmount(item.out_settlement)],
      // [
      //   "out_external_transfer",
      //   t("out_external_transfer"),
      //   toAmount(item.out_external_transfer),
      // ],

      // [
      //   "out_withdraw_fee",
      //   t("out_withdraw_fee"),
      //   toAmount(item.out_withdraw_fee),
      // ],
      // ["out_topup_fee", t("out_topup_fee"), toAmount(item.out_topup_fee)],
      // [
      //   "out_merchant_withdraw_fee",
      //   t("out_merchant_withdraw_fee"),
      //   toAmount(item.out_merchant_withdraw_fee),
      // ],
      [
        "out_balance_adjustment",
        t("out_balance_adjustment"),
        toAmount(item.out_balance_adjustment),
      ],
      ["out_caution", t("out_caution"), toAmount(item.out_caution)],
      ["trans_fee", t("trans_fee"), toAmount(item.trans_fee)],
      ["closing_balance", t("closingBalance"), toAmount(item.closing_balance)],
    ].filter((col) => col);
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
