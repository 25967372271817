import TableTab from "../../../components/TableTab";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  SelectChangeEvent,
  Container,
  Box,
} from "../../../components/MuiGenerals";
import {
  toDisplayTime,
  listMappingTransform,
  enumMapping,
} from "../../../utils/helper";
import { useDatePicker } from "../../../components/DatePicker";
import { useState } from "react";
import {
  Ipage,
  IportalUserProposalsParams,
  IYubikeyProposalType,
  IportalUserYubiKeyStatus,
} from "../../../api/types";
import { customSx } from "../../../utils/styling";
import {
  enumPortalUserYubiKeyStatus,
  enumYubikeyProposalType,
  enumPortalUserProposalStatus,
  tableConfig,
} from "../../../utils/constant";
import RequestRecordList from "./RequestRecordList";
import GeneralBtn from "../../../components/GeneralBtn";
import { MultiSelection } from "../../../components/GeneralSelection";
import { useTranslation } from "../../../hooks";
import { createZusInstance } from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";

const translatePrefix = "userApprovalManagement";
const Enum = {
  portalUserProposalStatus: enumMapping(enumPortalUserProposalStatus),
  portalUserYubiKeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
  yubikeyProposalType: enumMapping(enumYubikeyProposalType),
};
interface Ifields {
  acts: IYubikeyProposalType[];
  email: string;
  pub_id: string; //yubikey_id
  statuses: IportalUserYubiKeyStatus[];
}

const initFields = {
  acts: [] as IYubikeyProposalType[],
  email: "",
  pub_id: "",
  statuses: [] as IportalUserYubiKeyStatus[],
} as const;

const initZusParams = {
  date_created_from: "",
  date_created_to: "",
  page: 0,
  pageSize: tableConfig.pageSize,
  ...initFields,
};
export const useZusParams = createZusInstance<
  IportalUserProposalsParams & Ipage
>(initZusParams);
export default function RequestRecord() {
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <RequestRecordList {...{ listMapping }} />
        </TableTab>
      </Container>
    </Box>
  );
}
export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const DateObj = useDatePicker();
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (type === "pub_id") {
        return setFields({ ...fields, pub_id: value.slice(0, 12) });
      }
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onArraySelectChange =
    (name: keyof Ifields) => (e: SelectChangeEvent<string[]>) => {
      const { value } = e.target;
      const newValue = typeof value === "string" ? value.split(",") : value;
      return setFields((fields) => ({ ...fields, [name]: newValue }));
    };
  const onSearch = () => {
    zusParams.setBody({
      ...fields,
      date_created_from: DateObj.start,
      date_created_to: DateObj.end,
      page: 0,
    });
    zusParams.refetch();
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.clearDate();
  };
  const F = genField({ t }, [
    ["created_date", <DateObj.Picker type="dateTime" />],
    [
      "proposal_type",
      <MultiSelection
        label={t("ph_proposal_type")}
        value={fields.acts}
        onChange={onArraySelectChange("acts")}
        enumData={enumYubikeyProposalType}
      />,
    ],
    [
      "login_email",
      <MpTextField
        value={fields.email}
        onChange={onChange("email")}
        label={t("ph_login_email")}
      />,
    ],
    [
      "yubi_id",
      <MpTextField
        value={fields.pub_id}
        onChange={onChange("pub_id")}
        label={t("ph_yubi_id")}
      />,
    ],
    [
      "approval_statuses",
      <MultiSelection
        label={t("ph_approval_statuses")}
        value={fields.statuses}
        onChange={onArraySelectChange("statuses")}
        enumData={enumPortalUserProposalStatus}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.proposal_type}
        {F.login_email}
        {F.yubi_id}
        {F.approval_statuses}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn onClick={onSearch} label="search" />
        <GeneralBtn onClick={onReset} label="reset" />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    let decided = 0;
    if (item?.date_appr1?.length > 0) decided++;
    if (item?.date_appr2?.length > 0) decided++;
    if (String(item?.status) === enumPortalUserProposalStatus.Rejected)
      decided++;
    const maximum = 2;
    const approval_progress_ratio = `${decided}/${maximum}`;
    const isFull = decided >= maximum;
    const act = String(item.act);
    const isDisableOrUnbind =
      act === enumYubikeyProposalType.DisableApprovalPermission ||
      act === enumYubikeyProposalType.UnbindYubiKey;
    const isAuto = isFull && isDisableOrUnbind;
    const approval_progress = isAuto ? te("auto") : approval_progress_ratio;
    const yubikey_id =
      act === enumYubikeyProposalType.RebindYubiKey ||
      act === enumYubikeyProposalType.UpdateYubiKey ||
      act === enumYubikeyProposalType.YubiKeyRegistration
        ? item.new_val
        : "";
    const mappedResult = [
      ["id", t("id_serial"), item.id],
      [
        "proposal_type",
        t("proposal_type"),
        te(Enum.yubikeyProposalType[item.act]),
      ],
      ["approval_progress", t("approval_progress"), approval_progress],
      ["email", t("email"), (item.portal_user || {}).email],
      ["yubikey_id", t("yubikey_id"), yubikey_id],
      [
        "proposal_status",
        t("proposal_status"),
        te(Enum.portalUserProposalStatus[item.status]),
      ],
      ["created_by", t("created_by"), item.created_by],
      ["creation_time", t("creation_time"), toDisplayTime(item.date_created)],
      ["metaData", "", { decided, isFull, isAuto }],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
