import TableTab from "../../../components/TableTab";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  Container,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import {
  listMappingTransform,
  enumMapping,
  getBtcSeriesChain,
  toDisplayTime,
  findChainInfo,
  filterValidPayload,
} from "../../../utils/helper";
import { useEffect, useState } from "react";
import { customSx } from "../../../utils/styling";
import {
  FeatureCodes,
  enumUtxoStatus,
  tableConfig,
} from "../../../utils/constant";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  useZusDialogStore,
  useZusListStore,
  zusRefetchStore,
  zusShowIconStore,
} from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";
import BtcUtxoTabList from "./BtcUtxoTabList";
import { useDatePicker } from "../../../components/DatePicker";
import Modal from "./Modal";
import { useChains } from "../../../components/FetchConfig";
import DeleteModal from "./DeleteModal";
import { getBtcUtxo } from "../../../api/merchant";
const translatePrefix = "utxoManagement";
const { pageSize } = tableConfig;
const Pkey = FeatureCodes.tools.UTXOTool.TabBTCSeries;
const Enum = {
  status: enumMapping(enumUtxoStatus),
};
const BtcUtxoTab = () => {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);

  return (
    <Box sx={customSx.layoutBox}>
      <Modal />
      <DeleteModal type="btc" />
      <Container disableGutters maxWidth={false}>
        <FilterBar
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <BtcUtxoTabList {...{ listMapping, isSearchBtnClick }} />
        </TableTab>
      </Container>
    </Box>
  );
};
interface ILocalField {
  wallet_address: string;
  tx_hash: string;
  chain_name: string;
}

const initFields = {
  wallet_address: "",
  tx_hash: "",
  chain_name: "",
};

export function FilterBar(props: any) {
  const { isSearchBtnClick, setSearchBtnClick } = props;
  const DateObj = {
    Created: useDatePicker(),
    LastModified: useDatePicker(),
  };
  const [fields, setFields] = useState<ILocalField>(initFields);
  const zusShowIcon = zusShowIconStore((state) => state.setShowIcon);
  const zusSetSearchHash = zusShowIconStore((state) => state.setSearchHash);
  const refetBool = zusRefetchStore((state) => state.refetchBoolean);
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const zusList = useZusListStore();
  const page = zusList.meta.page;
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const onChange =
    (type: keyof ILocalField) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((pre) => ({ ...pre, [type]: value }));
    };
  const { chainObj, selectChainByPieces } = useChains();

  const btcSeriesChainObj = getBtcSeriesChain(chainObj, [
    "BTC",
    "BCH",
    "BCHT",
    "LTCT",
    "LTC",
    "DOGE",
    "DOGET",
  ]);
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });
  const handleModal = () => {
    zusDialog.open("utxoDialog");
  };
  const params = {
    chain_type,
    chain_id,
    tx_hash: fields.tx_hash,
    pageSize,
    page,
    address: fields.wallet_address,
    created_date_from: DateObj.Created.start,
    created_date_to: DateObj.Created.end,
    last_modified_date_from: DateObj.LastModified.start,
    last_modified_date_to: DateObj.LastModified.end,
  };

  const [cacheParam, setCacheParams] = useState(params);
  useEffect(() => {
    const necessaryCacheParams = filterValidPayload(cacheParam);
    if (!isSearchBtnClick) return;
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getBtcUtxo({ ...necessaryCacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
      if (fields.tx_hash) {
        zusShowIcon(true);
      } else {
        zusShowIcon(false);
      }
    }
    fetch();
  }, [page, cacheParam, isSearchBtnClick, refetBool]);

  const onSearch = async () => {
    if (!fields.chain_name && !fields.wallet_address) {
      return alerting("warning", t("inputChainNameAddress"));
    }
    if (!fields.chain_name) {
      return alerting("warning", t("mustInputChainName"));
    }
    if (!fields.wallet_address) {
      return alerting("warning", t("mustInputWalletAddress"));
    }
    zusSetSearchHash(params.tx_hash);
    setSearchBtnClick(true);
    zusList.setPage(0);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Created.clearDate();
    DateObj.LastModified.clearDate();
  };
  const handleOnSelectEvent = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };
  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "chain_name") {
      const output = key === fields.chain_name ? "" : key;
      setFields((pre) => ({ ...pre, chain_name: output }));
    }
  };
  const F = genField({ t }, [
    [
      "chain_name",
      <FormControl fullWidth={true}>
        <InputLabel id="select-label">{t("phChain_name")}</InputLabel>
        <Select
          name="chain_name"
          onChange={handleOnSelectEvent}
          defaultValue=""
          value={fields.chain_name}
        >
          {Object.entries(btcSeriesChainObj).map(([key, { name }], i) => (
            <MenuItem
              key={key}
              value={key}
              onClick={(e) => handleClick(e, key, "chain_name")}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "create_time",
      <DateObj.Created.Picker
        type="dateTime"
        startLabel={t("phStartTime")}
        endLabel={t("phEndTime")}
      />,
    ],
    [
      "last_modified_time",
      <DateObj.LastModified.Picker
        type="dateTime"
        startLabel={t("phStartTime")}
        endLabel={t("phEndTime")}
      />,
    ],
    [
      "wallet_address",
      <MpTextField
        value={fields.wallet_address}
        onChange={onChange("wallet_address")}
        label={t("ph_wallet_address")}
      />,
    ],
    [
      "tx_hash",
      <>
        <MpTextField
          value={fields.tx_hash}
          onChange={onChange("tx_hash")}
          label={t("ph_tx_hash")}
          helperText={t("helperDelText")}
        />
      </>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.SyncBTCUTXO) && (
          <Button onClick={handleModal} variant="outlined">
            {t("sync_utxo")}
          </Button>
        )}
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.create_time}
        {F.last_modified_time}
        {F.chain_name}
        {F.wallet_address}
        {F.tx_hash}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: {
    omitKeys: string[];
    t: any;
    te: any;
    chain_id: string;
    chain_type: string;
  }
): any[][] => {
  const { omitKeys = [], t, chain_type, chain_id } = config || {};
  const res = array.map((item: any) => {
    const mappedResult = [
      ["wallet_address", t("wallet_address"), item.address],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(chain_type), chain_id!)?.name,
      ],
      ["tx_hash", t("tx_hash"), item.tx_hash],
      ["vout", t("vout"), item.v_out],
      ["spent_tx_hash", t("spent_tx_hash"), item.spent_tx_hash],
      ["status", t("status"), t(Enum.status[item.status])],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
      [
        "metaData",
        "",
        {
          chain_type: chain_type,
          chain_id: chain_id,
          address: item.address,
          spent_tx_hash: item.spent_tx_hash,
          tx_hash: item.tx_hash,
          v_out: item.v_out,
        },
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
export default BtcUtxoTab;
