import { Button, ClearIcon, Tooltip } from "../../../components/MuiGenerals";
import CellTowerIcon from "@mui/icons-material/CellTower";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
} from "@mui/x-data-grid";
import {
  dataGridDefaults,
  batchIdUrlString,
  FeatureCodes,
} from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import {
  confirmFailureWithStuckingBatchid,
  listFace,
  rebroadcastTransactions,
} from "../../../api/merchant";
import { useTranslate } from "react-admin";
import { CustomPagination } from "../../../components/CustomPagination";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { useAlerting, usePermission } from "../../../hooks";
import Prompter, { usePrompter } from "../../../components/Prompter";
import React from "react";
import { zusRefetchStore } from "../../../zustand/store";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const Pkey = FeatureCodes.tools.ReBroadcast.TabReBroadcast;

interface propsFace {
  list: listFace;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  listMapping: any;
}
const ReBroadcastList = (props: propsFace) => {
  const translate = useTranslate();
  const t = (key: string, option?: Object) => translate(`tools.${key}`, option);
  const { list, setPage, listMapping } = props;
  const totalRecords = list.count;
  const { hasPermission } = usePermission();
  const canViewRebroadcastBtn = hasPermission(Pkey.Broadcast);
  const canviewConfirmFailureBtn = hasPermission(Pkey.ConfirmFailure);
  const [prompter, setPromter] = usePrompter();
  const refetchFun = zusRefetchStore((state) => state.refetchFunc);
  const { alerting } = useAlerting();
  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  const cellButton = (name: string) => (params: any) => {
    const batchId = params?.row?.batch_id;
    const [baseLink, icon] =
      name === "op"
        ? ["/operationRecords", <SummarizeIcon />]
        : ["/operationTransactionRecords", <AttachMoneyIcon />];
    const toNewTab = () =>
      window.open(`${baseLink}?${batchIdUrlString}${batchId}`);
    return (
      <Button onClick={toNewTab} color="secondary" variant="contained">
        {icon}
      </Button>
    );
  };
  const btnBroadcast = (params: any) => {
    const displayObj = [
      [t("batch_id"), params.row?.batch_id],
      [t("operation_type"), params.row?.operation_type],
      [t("chain_name"), params.row?.chain_name],
      [t("asset_name"), params.row?.asset_name],
      [t("no_of_tx"), params.row?.no_of_tx],
    ];
    const content = displayObj.map(([name, value]) => (
      <div key={name} style={{ display: "flex" }}>
        <span style={{ flex: 2 }}>{name}</span>
        <span style={{ flex: 1 }}>{value}</span>
      </div>
    ));
    const batch_id = params.row?.batch_id;
    const onBroadcast = async () => {
      if (!batch_id) return alerting("error", t("batch_id_is_empty"));
      const res = await rebroadcastTransactions(batch_id);
      if (!res) return;
      alerting("success", t("broadcast_bacth_id_success", { batch_id }));
      refetchFun({ where: "rebroadcastTab" });
    };
    const onFailure = async () => {
      if (!batch_id) return alerting("error", t("batch_id_is_empty"));
      const res = await confirmFailureWithStuckingBatchid(batch_id);
      if (!res) return;
      alerting("success", t("confirmFailureSuccess", { batch_id }));
      refetchFun({ where: "rebroadcastTab" });
    };
    const onClick = () =>
      setPromter(onBroadcast, {
        title: t("confirm_re_broadcast"),
        content,
      });
    const handleConfirmFailure = async () => {
      setPromter(onFailure, {
        title: t("confirm_failure"),
        content,
      });
    };

    return (
      <>
        {canviewConfirmFailureBtn && (
          <Tooltip title={t("confirmFail")}>
            <Button
              sx={{ marginRight: "5px" }}
              onClick={handleConfirmFailure}
              color="secondary"
              variant="contained"
            >
              <ClearIcon />
            </Button>
          </Tooltip>
        )}

        {/* Divider */}
        {canViewRebroadcastBtn && (
          <Tooltip title={t("re_broadcast")}>
            <Button
              sx={{ marginLeft: "5px" }}
              onClick={onClick}
              color="secondary"
              variant="contained"
            >
              <CellTowerIcon />
            </Button>
          </Tooltip>
        )}
      </>
    );
  };
  const showColumnBy = canViewRebroadcastBtn || canviewConfirmFailureBtn;
  const columns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("operation_type", t("operation_type")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("total_amount", t("total_amount")),
    useGenGridCol("total_count", t("total_count")),
    useGenGridCol("no_of_tx", t("no_of_tx")),
    useGenGridCol("batch_status", t("batch_status")),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("created_date", t("created_date")),
    useGenGridCol("1", t("viewOperation"), {
      renderCell: cellButton("op"),
    }),
    useGenGridCol("2", t("viewOperationTransaction"), {
      renderCell: cellButton("opt"),
    }),
    useGenGridCol("3", t("operation"), {
      minWidth: 200,
      renderCell: btnBroadcast,
    }),
  ].filter((col) => {
    if (
      col.field === "1" &&
      !hasPermission(FeatureCodes.assetManagement.OperationRecords.prefix)
    )
      return false;
    if (
      col.field === "2" &&
      !hasPermission(
        FeatureCodes.assetManagement.OperationTransactionRecords.prefix
      )
    ) {
      return false;
    }
    if (col.field === "3" && !showColumnBy) return false;
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <Prompter {...prompter} dialogMinWidth={"30rem"} />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </>
  );
};

export default React.memo(ReBroadcastList);
