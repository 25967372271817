import { useTranslate } from "react-admin";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import { useGenGridCol } from "../../utils/HelperComp";
import { dataGridDefaults } from "../../utils/constant";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { CustomPagination } from "../../components/CustomPagination";
import { Tooltip } from "../../components/MuiGenerals";
import { translateErrorCode } from "../../utils/errorHandler";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

export default function OperationRecordList(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`operations.${key}`);
  const { list, setPage, page, listMapping } = props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, translate);
  const ErrorReasonCell = (params: GridRenderCellParams) => {
    const { error_code = "" } = params.row?.rawData || {};
    const errorReason = translateErrorCode(error_code, {});
    const displayErrorReason = error_code ? errorReason : "";
    return (
      <Tooltip title={displayErrorReason}>
        <span>{displayErrorReason}</span>
      </Tooltip>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id"), {}),
    useGenGridCol("chain_name", t("chain_name"), {}),
    useGenGridCol("operation_seq", t("operation_seq"), { minWidth: 120 }),
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 130,
    }),
    useGenGridCol("wallet_version", t("wallet_version"), {}),
    useGenGridCol("operation_type", t("operation_type"), {}),
    useGenGridCol("from_address", t("from_address"), {}),
    useGenGridCol("to_address", t("to_address"), {}),
    useGenGridCol("to_wallet_tag", t("to_wallet_tag"), {}),
    useGenGridCol("invoker_address", t("invoker_address"), {}),
    useGenGridCol("status", t("status"), { minWidth: 120 }),
    useGenGridCol("asset_name", t("asset_name"), { minWidth: 170 }),
    useGenGridCol("amount", t("amount"), { minWidth: 170 }),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("created_date", t("created_date"), {}),
    useGenGridCol("last_modified_date", t("last_modified_date"), {}),
    useGenGridCol("error_reason", t("error_reason"), {
      renderCell: ErrorReasonCell,
    }),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={list.count}
        columns={columns}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
}
