import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { enumChainIdToType } from "../utils/constant";
import { RootState } from "./store";

export interface ChainsFace {
  [chain_code: string]: ChainFace;
}
export interface ChainFace {
  id: string;
  chain_type: number;
  chain_id: string;
  chain_code: string;
  type: string;
  key: string;
  native_asset_name: string;
  created_date: string;
  last_modified_date: string;
  name: string;
}

const initialState: { list: ChainsFace } = {
  list: {},
};

export const chainsSlice = createSlice({
  name: "chains",
  initialState,
  reducers: {
    setChains: (state, action: PayloadAction<ChainsFace>) => {
      state.list = action.payload;
    },
  },
});
//info={chain_type:'xxx', chain_id:'yyy'}
export const { setChains } = chainsSlice.actions;
export const selectChains = (state: RootState) => state.chains.list;
export const selectChainByInfo =
  (targetType: string, targetId: string) => (state: RootState) => {
    return (
      Object.values(state.chains.list).find(
        (item) =>
          String(item.chain_type) === targetType &&
          String(item.chain_id) === targetId
      ) || {}
    );
  };
export const selectChainByChainType =
  (targetType: string) => (state: RootState) => {
    return (
      Object.values(state.chains.list).find(
        (item) => String(item.chain_type) === targetType
      ) || {}
    );
  };
export interface chainPieceFace {
  chain_name: string;
  chain_type: string | number;
  chain_id: string | number;
}
export const selectChainByPieces =
  (state: RootState) =>
  (params: Partial<chainPieceFace>): Partial<ChainFace> => {
    const chain_type = String(params.chain_type || "");
    const chain_id = String(params.chain_id || "");
    const chain_name = params.chain_name;
    const hasName = chain_name && chain_name.length > 0;
    const hasInfo = chain_type.length > 0 && chain_id.length > 0;
    const notETH =
      chain_type !== enumChainIdToType.ETH && chain_type.length > 0;
    if (hasName) {
      return state.chains.list[chain_name] || {};
    }
    if (hasInfo) {
      return selectChainByInfo(chain_type, chain_id)(state) || {};
    }
    if (notETH) {
      return selectChainByChainType(chain_type)(state) || {};
    }
    return {};
  };
export default chainsSlice.reducer;
