import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { enumLanguage } from "../utils/constant";
import EnumRiskLevel from "@wallet-manager/node-types/dist/types/postgres/const/RiskLevel";
import { Itimezone } from "../api/types";
export interface ProfileLoginFace {
  isLogged: boolean;
  featureList: string[];
  appName: string;
  email: string;
  lang: keyof typeof enumLanguage;
  timezone: Itimezone;
  acceptance_level: EnumRiskLevel;
  chain_analysis: boolean;
  appUuid: string;
}

const initialState: ProfileLoginFace = {
  isLogged: false,
  featureList: [],
  appName: "",
  email: "",
  lang: getBrowserSetting().lang,
  timezone: getBrowserSetting().timezone,
  acceptance_level: EnumRiskLevel.NoAssessment,
  chain_analysis: false,
  appUuid: "",
};
function getBrowserSetting() {
  const defaultLang = navigator.language || "en";
  const lang = defaultLang.includes("zh")
    ? enumLanguage.zh_cn
    : enumLanguage.en;
  const defaultTimezone = (new Date().getTimezoneOffset() * -1) / 60;
  const timezone = (
    defaultTimezone < 0 ? String(defaultTimezone) : `+${defaultTimezone}`
  ) as Itimezone;
  return { lang, timezone };
}

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Partial<ProfileLoginFace>>) => {
      const {
        appName = "",
        featureList = [],
        email = "",
        acceptance_level = -1,
        chain_analysis = false,
        appUuid = "",
      } = action.payload;
      state.isLogged = true;
      state.featureList = featureList;
      state.appName = appName;
      state.email = email;
      state.acceptance_level = acceptance_level;
      state.chain_analysis = chain_analysis;
      state.appUuid = appUuid;
    },
    setAccountSetting: (
      state,
      action: PayloadAction<{
        lang: keyof typeof enumLanguage;
        timezone: Itimezone;
      }>
    ) => {
      state.lang = action.payload.lang;
      state.timezone = action.payload.timezone;
    },
    logout: (state) => ({ ...initialState, lang: state.lang }),
  },
});

export const { logout, login, setAccountSetting } = profileSlice.actions;

export const selectProfile = (state: RootState) => state.profile;
export const selectExpiryTime = (state: RootState) => {
  // const date = new Date(state.profile.expiryTimeStr);
  // return isValidDate(date) ? date : new Date();
  return new Date();
};
export const selectPermissions = (state: RootState) =>
  state.profile.featureList;

export default profileSlice.reducer;
