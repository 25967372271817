import { useAppDispatch } from "../reducer/hooks";
import {
  open,
  close,
  AlerterState,
  setRedirectTo as setRedirectToFn,
} from "../reducer/alerterSlice";

function useAlerting() {
  const dispatch = useAppDispatch();
  const alerting = (
    severity: AlerterState["severity"],
    text: string,
    config?: object
  ) => {
    const params = {
      text,
      severity,
      ...config,
    };
    dispatch(close());
    setTimeout(() => {
      dispatch(open(params));
    }, 100);
  };
  const setRedirectTo = (url: string) => dispatch(setRedirectToFn(url));
  return { alerting, setRedirectTo };
}

export default useAlerting;
/*usage
const { alerting } = useAlerting();
alerting("success", "an alert!")
 */
