//ApprovalWindow

import { useEffect } from "react";
import { useQuery } from "react-query";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import EnumRequestStatus from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerRequestStatus";
import EnumApprovalAction from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerApprovalAction";

import {
  getLedgerTransferRequestApprovalDetail,
  getLedgerWalletManagerDepositRecordApprovalDetail,
  getLedgerWithdrawRequestApprovalDetail,
} from "../../api/merchant";
import DialogInOne from "../../components/DialogInOne";
import { Box } from "../../components/MuiGenerals";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { useTranslation } from "../../hooks";
import { INTERVAL_PENDING_OPERATIONS_COUNT } from "../../utils/config";
import { toDisplayTime } from "../../utils/helper";
import { useZusDialogStore } from "../../zustand/store";

enum EnumTranslatePrefix {
  DepositRecord = "ledgerWalletManagerDepositRecord",
  TransferRequest = "ledgerTransferRequest",
  WithdrawRequest = "ledgerWithdrawRequest",
}

const apiObj = {
  DepositRecord: getLedgerWalletManagerDepositRecordApprovalDetail,
  TransferRequest: getLedgerTransferRequestApprovalDetail,
  WithdrawRequest: getLedgerWithdrawRequestApprovalDetail,
};

export default function ApprovalProgressDialog() {
  const zusDialog = useZusDialogStore();
  const { page } = zusDialog.meta || {};
  const { t } = useTranslation(
    EnumTranslatePrefix[page as keyof typeof EnumTranslatePrefix]
  );
  const dialogConfig = {
    title: t("approval_progress"),
    self: {
      open: zusDialog.match("approvalDialog"),
      onClose: () => zusDialog.close(),
    },
    content: <ApprovalProgressContent />,
    onCancel: zusDialog.close,
    isConfirmHidden: true,
    onConfirm: () => {},
  } as const;

  if (!page) return <></>;

  return (
    <>
      <DialogInOne {...dialogConfig} rest={{ maxWidth: "md" }} />
    </>
  );
}

export function ApprovalProgressContent() {
  const zusDialog = useZusDialogStore();
  const {
    page,
    approval_progress,
    id: request_id,
    status,
    last_modified_time,
  } = zusDialog.meta || {};
  const typedPage: "DepositRecord" | "TransferRequest" | "WithdrawRequest" =
    page;
  const { t, te } = useTranslation(EnumTranslatePrefix[typedPage]);

  const approvalProgressDisplay = approval_progress && `(${approval_progress})`;

  const autoApprovedStatusArr = [
    EnumRequestStatus.Approved,
    EnumRequestStatus.Execute,
    EnumRequestStatus.ExecuteSuccess,
    EnumRequestStatus.RefundSuccess,
  ];

  const { approvalFetchedData, isFetching } = useFetchLedgerApproverArr(
    page,
    request_id
  );

  const approversArr =
    autoApprovedStatusArr.includes(status) &&
    approval_progress === t("auto_approve")
      ? [
          {
            approved_by: t("auto_approve"),
            last_modified_time,
            approval_action: EnumApprovalAction.Approved,
            remarks: "",
          },
        ]
      : approvalFetchedData;

  useEffect(() => {
    zusDialog.setMeta({ ...zusDialog.meta, approversArr });
  }, [isFetching]);

  if (!approversArr || isFetching || !page) return <></>;

  return (
    <Box>
      <div style={{ fontSize: "12.5px", paddingLeft: "4px" }}>
        {t("approval_progress")} {approvalProgressDisplay}
      </div>
      <div
        style={{ borderBottom: "solid #F1F2F4 1px", margin: "10px 0 10px 0" }}
      ></div>
      <Table
        aria-label="table"
        sx={{
          mb: 3,
          borderCollapse: "separate",
          borderSpacing: "0px 4px",
          marginBottom: !approversArr.length ? 0 : undefined,
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              th: {
                border: "none",
                padding: "0 !important",
                paddingBottom: "8px !important",
                textAlign: "center",
              },
            }}
          >
            <TableCell sx={{ width: "110px" }}>
              {t("approval_sequence")}
            </TableCell>
            <TableCell sx={{ width: "120px" }}>{t("operated_by")}</TableCell>
            <TableCell sx={{ width: "120px" }}>{t("operation_time")}</TableCell>
            <TableCell sx={{ width: "110px" }}>
              {t("operation_result")}
            </TableCell>
            <TableCell>{t("remarks")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approversArr
            ?.sort((a: any, b: any) => {
              const aDate = new Date(a.last_modified_date);
              const bDate = new Date(b.last_modified_date);
              return aDate.getTime() - bDate.getTime();
            })
            .map((row: any, i: any) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  background: "#EAEAEA",
                  td: {
                    textAlign: "center",
                  },
                }}
              >
                <TableCell sx={{ width: "110px" }}>{i + 1} </TableCell>
                <TableCell sx={{ width: "120px" }}>{row.approved_by}</TableCell>
                <TableCell sx={{ width: "120px" }}>
                  {row.last_modified_time ||
                    toDisplayTime(row.last_modified_date)}
                </TableCell>
                <TableCell
                  style={{
                    color:
                      row.approval_action === EnumApprovalAction.Approved
                        ? "#3dd598"
                        : "#FF0000",
                    width: "110px",
                  }}
                >
                  {te(
                    EnumApprovalAction[row.approval_action] as
                      | "Approved"
                      | "Rejected"
                  )}
                </TableCell>
                <TableCell>{row.remarks}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {!approversArr.length && (
        <Box
          sx={{
            background: "#EAEAEA",
            fontSize: "0.6875rem",
            padding: "20px 0",
            marginBottom: "24px",
          }}
        >
          <NoRowsOverlay />
        </Box>
      )}
    </Box>
  );
}

export function useFetchLedgerApproverArr(
  page: "DepositRecord" | "TransferRequest" | "WithdrawRequest",
  request_id: string | number
) {
  const api =
    page === "DepositRecord"
      ? getLedgerWalletManagerDepositRecordApprovalDetail
      : page === "TransferRequest"
      ? getLedgerTransferRequestApprovalDetail
      : getLedgerWithdrawRequestApprovalDetail;

  const res = useQuery({
    queryKey: "getLedgerApproverArr",
    queryFn: () => api({ request_id }),
  });

  const { data, isFetching } = res;

  const typedApprovalResData = data as unknown as Record<any, any>[];

  return {
    approvalFetchedData: typedApprovalResData || [],
    isFetching,
  };
}
