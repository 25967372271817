import { useEffect, useState } from "react";

import FormControlLabel from "@mui/material/FormControlLabel";
import { FeatureCode } from "@wallet-manager/node-types";

import { getClientBalanceExport } from "../../../api/merchant";
import {
  EnumComparisonOperators,
  Ipage,
  TrueOrFalseString,
} from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import { SingleSelection } from "../../../components/GeneralSelection";
import MpTextField from "../../../components/MpTextField";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Checkbox,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import {
  containsOnlyNumbers,
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  toDBInDecimals,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance } from "../../../zustand/store";
import ClientBalanceList from "./ClientBalanceList";

const translatePrefix = "ledgerClientBalance";
const Pkey = FeatureCode.FeaturesLedger.ClientBalance;

interface Ifields {
  chain_name: string;
  asset_names: string[];
  client_id: string;
  comparison_operator: string;
  comparison_value: string;
  is_zero_excluded: boolean;
}
interface ZusParamsFace {
  chain_type: number | undefined;
  chain_id: string | undefined;
  asset_names: string[];
  client_ids: string[];
  date_created_from: string;
  date_modified_from: string;
  date_created_to: string;
  date_modified_to: string;
  exclude_zero: TrueOrFalseString;
  comparison_operator: string;
  balance_comparison: string;
  pageSize: number;
  page: number;
}

const initFields = {
  chain_name: "",
  asset_names: [] as string[],
  client_id: "",
  comparison_operator: "",
  comparison_value: "",
  is_zero_excluded: true,
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;
export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);

export default function ClientBalance() {
  const zusParams = useZusParams();
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getClientBalanceExport({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];

    const res = listMapping("name", rawRes, { t, omitKeys });
    const config = {};
    downloadFiles(`Client Balance`, res, config);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <ClientBalanceList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedDate: useDatePicker(),
  };

  const getParams: () => ZusParamsFace = () => {
    const clientIdRawArr = fields.client_id.split(",");

    const client_ids = clientIdRawArr
      .filter((item) => item)
      .map((item) => item.trim());
    const {
      asset_names,
      chain_name,
      is_zero_excluded,
      comparison_operator,
      comparison_value,
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const { CreationTime, LastModifiedDate } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;
    const { start: date_modified_from, end: date_modified_to } =
      LastModifiedDate;

    const exclude_zero = is_zero_excluded ? "true" : "false";

    const decimals = getDecimal({
      chain_type: String(chain_type),
      chain_id: String(chain_id),
      asset_name: asset_names[0],
    });

    const balance_comparison = toDBInDecimals(comparison_value || 0, decimals);

    return {
      ...initZusParams,
      client_ids,
      chain_id,
      chain_type,
      asset_names,
      date_created_from,
      date_created_to,
      date_modified_from,
      date_modified_to,
      exclude_zero,
      comparison_operator,
      balance_comparison,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      const hasInvalidValue =
        type === "comparison_value" && !containsOnlyNumbers(value);
      if (hasInvalidValue) {
        return;
      }
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    const hasFilledSomething =
      fields.comparison_operator || fields.comparison_value;
    const isIncomplete =
      fields.comparison_operator === "" || fields.comparison_value === "";
    if (hasFilledSomething && isIncomplete) {
      return alerting(
        "warning",
        t("cb_both_fields_in_client_balance_are_required")
      );
    }
    if (
      fields.comparison_operator === "eq" &&
      Number(fields.comparison_value) === 0 &&
      fields.is_zero_excluded
    ) {
      return alerting(
        "warning",
        t("cb_should_not_be_zero_as_zero_is_excluded")
      );
    }

    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedDate.clearDate();
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );
  useEffect(() => {
    if (fields.asset_names.length === 1) {
      return;
    }

    setFields((f) => ({
      ...f,
      comparison_operator: "",
      comparison_value: "",
    }));
  }, [fields.asset_names]);

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_date", <DateObj.LastModifiedDate.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "client_id",
      <MpTextFieldMultiple
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
        count={apiParams.client_ids.length}
      />,
    ],
    [
      "client_balance",
      <Box sx={{ display: "flex", columnGap: "1rem" }}>
        <SingleSelection
          label=""
          disabled={fields.asset_names.length !== 1} // disabled when it is not selected single asset name
          value={fields.comparison_operator}
          onChange={onChange("comparison_operator")}
          enumData={EnumComparisonOperators}
          clearSelect={() =>
            setFields((f) => ({ ...f, comparison_operator: "" }))
          }
        />
        <MpTextField
          name={"comparison_value"}
          label={t("ph_client_balance")}
          disabled={fields.asset_names.length !== 1} // disabled when it is not selected single asset name
          value={fields.comparison_value}
          onChange={onChange("comparison_value")}
        />
        <FormControlLabel
          value="yes"
          control={<Checkbox />}
          label={t("exclude_zero")}
          labelPlacement="start"
          checked={fields.is_zero_excluded}
          onChange={(_, checked) =>
            setFields((prev) => ({ ...prev, is_zero_excluded: checked }))
          }
          sx={{ minWidth: "fit-content" }}
        />
      </Box>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_date}
        {F.chain_name}
        {F.currency}
        {F.client_id}
        {F.client_balance}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any }
): any[][] => {
  const { omitKeys = [], t } = config || {};
  const res = array.map((item: any) => {
    const chainInfo = findChainInfo(item.chain_type, item.chain_id);
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      ["client_id", t("client_id"), item.client_id],
      ["chain_name", t("chain_name"), chainInfo?.name],
      ["currency", t("currency"), item.asset_name],

      [
        "client_balance",
        t("client_balance"),
        displayAmount(item.balance, decimals),
      ],
      [
        "frozen_balance",
        t("frozen_balance"),
        displayAmount(item.frozen_balance, decimals),
      ],
      ["credit", t("credit"), displayAmount(item.credit, decimals)],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
