import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { getWithdrawCheckingTimeControlRequest } from "../../../api/signer";
import { useDatePicker } from "../../../components/DatePicker";
import GeneralBtn from "../../../components/GeneralBtn";
import MpTextField from "../../../components/MpTextField";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import RequestRecordTabList from "../../../components/WithdrawApprovalCheckingTimeSetting/RequestRecordTabList";
import {
  tableConfig,
  enumAdjustmentStatus,
  enumWithdrawCheckingTimeControlApprovalStatus,
} from "../../../utils/constant";
import {
  getDecimal,
  displayAmount,
  toDisplayTime,
  listMappingTransform,
  enumMapping,
  sortItemsAlphabetically,
} from "../../../utils/helper";
import { DivideLine, genField, useRefresh } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import { listFace } from "../../Tools/AuditLogTabView";

const { pageSize } = tableConfig;
const Enum = {
  approvalStatus: enumMapping(enumWithdrawCheckingTimeControlApprovalStatus),
};
interface fieldsFace {
  approval_status: string[];
  proposal_no: string[];
}
const initFields = {
  approval_status: [],
  proposal_no: [],
};

export default function RequestRecordTab() {
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [refreshBool, refresh] = useRefresh();
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar {...{ setList, page, refreshBool }} />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <RequestRecordTabList {...{ list, listMapping, setPage, refresh }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar(props: {
  page: number;
  setList: Dispatch<SetStateAction<listFace>>;
  refreshBool: boolean;
}) {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { page, setList, refreshBool } = props;
  const translate = useTranslate();
  const t = (key: string) =>
    translate(`withdrawApprovalCheckingTimeSetting.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const zusDialog = useZusDialogStore();

  const DateObj = {
    CreationTime: useDatePicker(),
    // OperationTime: useDatePicker(),
  };

  const params = {
    createdDateFrom: DateObj.CreationTime.start,
    createdDateTo: DateObj.CreationTime.end,
    // operatedDateFrom: DateObj.OperationTime.start,
    // operatedDateTo: DateObj.OperationTime.end,
    ids: fields.proposal_no,
    statuses: fields.approval_status,
    page,
    pageSize,
  };

  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getWithdrawCheckingTimeControlRequest(params);
      zusDialog.closeExtra();

      if (!res) return;
      setList(res);
    }
    fetch();
  }, [page, refreshBool]);

  const onSearch = async () => {
    const paramsWithResetPage = { ...params, page: 0 };
    zusDialog.openExtra("loadingDialog");
    const res = await getWithdrawCheckingTimeControlRequest(
      paramsWithResetPage
    );
    zusDialog.closeExtra();
    if (!res) return;
    setList(res);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    // DateObj.OperationTime.clearDate();
  };

  const onFilterChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let { value }: any = e.target;
      const newValue =
        value === ""
          ? value
          : typeof value === "string"
          ? value.split(",")
          : value;
      setFields((fields) => ({ ...fields, [type]: newValue }));
    };

  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    // ["operation_time", <DateObj.OperationTime.Picker type="dateTime" />],

    [
      "approval_status",
      <FormControl>
        <InputLabel>{t("phApproval_status")}</InputLabel>
        <Select
          name="approval_status"
          multiple
          value={fields.approval_status}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumAdjustmentStatus)).map(
            ([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {te(`${value}`)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>,
    ],
    [
      "proposal_no",
      <MpTextField
        name="proposal_no"
        label={t("phProposal_no")}
        value={fields.proposal_no}
        onChange={onFilterChange("proposal_no")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{params.ids.length}</InputAdornment>
          ),
        }}
      />,
    ],
  ]);

  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {/* {F.operation_time} */}
        {F.approval_status}
        {F.proposal_no}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) =>
    translate(`withdrawApprovalCheckingTimeSetting.${key}`);
  const res = array
    .filter((item) => item.act !== -2)
    .map((item: any) => {
      const mappedResult = [
        ["proposal_no", t("proposal_no"), item.id],
        [
          "before_edit",
          t("before_edit"),
          `${item.old_checking_hour} ${
            item.old_checking_hour > 1 ? t("hours") : t("hour")
          }`,
        ],
        [
          "after_edit",
          t("after_edit"),
          `${item.new_checking_hour} ${
            item.new_checking_hour > 1 ? t("hours") : t("hour")
          }`,
        ],
        [
          "approval_status",
          t("approval_status"),
          translate(
            `enumConstants.${Enum.approvalStatus[item.general_proposal.status]}`
          ),
        ],
        ["created_by", t("created_by"), item.general_proposal.created_by],
        [
          "creation_time",
          t("creation_time"),
          toDisplayTime(item.general_proposal.created_date),
        ],
        [
          "rawData",
          "",
          {
            totalApproversRequired: item.general_proposal.no_of_approvers,
            numberOfApprovedApprovers:
              item.general_proposal.general_proposal_details.length,
          },
        ],
      ];
      return mappedResult;
    });
  const output = res.map(listMappingTransform(which));
  return output;
};
