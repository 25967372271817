import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { FeatureCode } from '@wallet-manager/node-types';
import EnumRequestStatus from '@wallet-manager/node-types/dist/src/ledger/enums/LedgerRequestStatus';
import EnumRiskLevel from '@wallet-manager/node-types/dist/src/postgres/const/RiskLevel';
import EnumAllTransactionStatus from '@wallet-manager/node-types/dist/types/postgres/const/TransferTransactionStatus';

import {
  getLedgerWalletManagerDepositRecordToDoCount,
  getWalletManagerDepositRecordExport
} from '../../../api/merchant';
import { Ipage } from '../../../api/types';
import { AssetMultiSelection } from '../../../components/AssetSelection';
import { ChainSingleSelection } from '../../../components/ChainSelection';
import { useDatePicker } from '../../../components/DatePicker';
import { useAssets, useChains } from '../../../components/FetchConfig';
import GeneralBtn from '../../../components/GeneralBtn';
import { NodeTypesEnumMultiSelection } from '../../../components/GeneralSelection';
import MpTextField from '../../../components/MpTextField';
import MpTextFieldMultiple from '../../../components/MpTextFieldMultiple';
import {
  Box,
  Container,
  SelectChangeEvent
} from '../../../components/MuiGenerals';
import TableTab from '../../../components/TableTab';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import { INTERVAL_PENDING_OPERATIONS_COUNT } from '../../../utils/config';
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime
} from '../../../utils/helper';
import { DivideLine, genField } from '../../../utils/HelperComp';
import { customSx } from '../../../utils/styling';
import {
  createZusInstance,
  useZusDialogStore,
  useZusTabsStore
} from '../../../zustand/store';
import { exportApprovalDetailConverter } from '../commonFunction';
import StatusButtonGroup from './StatusButtonGroup';
import WalletManagerDepositRecordList from './WalletManagerDepositRecordList';

const translatePrefix = "ledgerWalletManagerDepositRecord";
const Pkey = FeatureCode.FeaturesLedger.WalletManagerDepositRecord;

const EnumTransactionStatus = (() => {
  const { None, ...rest } = EnumAllTransactionStatus;
  return rest;
})();

enum RequestStatusNoRefund {
  Failed = -2,
  Rejected = -1,
  initial = 1,
  Pending = 2,
  Approved = 3,
  Execute = 4,
  ExecuteSuccess = 5,
  AwaitRiskLevel = 8,
}
interface Ifields {
  chain_name: string;
  asset_names: string[];
  client_id: string;
  tx_statuses: string[];
  wallet_address: string;
  from_address: string;
  tx_hash: string;
  ref_no: string;
  risk_levels: string[];
  statuses: string[];
}
interface ZusParamsFace {
  date_created_from: string;
  date_created_to: string;
  date_last_modified_from: string;
  date_last_modified_to: string;
  chain_type: number | undefined;
  chain_id: string | undefined;
  asset_names: string[];
  client_ids: string[];
  deposit_statuses: string[];
  wallet_addresses: string[];
  from_addresses: string[];
  tx_hashes: string[];
  ref_no: string;
  risk_levels: string[];
  statuses: string[] | EnumRequestStatus[];
  pageSize: number;
  page: number;
}

const initFields: Ifields = {
  chain_name: "",
  asset_names: [] as string[],
  client_id: "",
  tx_statuses: [] as string[],
  wallet_address: "",
  from_address: "",
  tx_hash: "",
  ref_no: "",
  risk_levels: [] as string[],
  statuses: [] as string[],
} as const;

const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;

export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);

export default function WalletManagerDepositRecord() {
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, tc, te } = useTranslation(translatePrefix);
  const [count, setCount] = useState(0);
  const { hasPermission } = usePermission();
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getWalletManagerDepositRecordExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];
    const totalApprovalLevel = 2;

    const res = listMapping("name", rawRes, { t, te, omitKeys }).map(
      (item, rawResIndex) => ({
        ...item,
        ...exportApprovalDetailConverter(
          "deposit",
          totalApprovalLevel,
          rawRes,
          rawResIndex,
          t,
          te
        ),
      })
    );
    const config = {};
    downloadFiles(`Wallet Manager Deposit Record`, res, config);
  };
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    return () => {
      zusDialog.close();
      zusDialog.closeAll();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <WalletManagerDepositRecordList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedDate: useDatePicker(),
  };

  const zusTab = useZusTabsStore();

  const filterDataByTabValue = (tabValue: number) => {
    switch (tabValue) {
      case 0:
        return [];
      case 1:
        return [EnumRequestStatus.Pending, EnumRequestStatus.AwaitRiskLevel];
      case 2:
        return [
          EnumRequestStatus.Approved,
          EnumRequestStatus.Rejected,
          EnumRequestStatus.Execute,
          EnumRequestStatus.ExecuteSuccess,
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    zusParams.setBody({
      ...apiParams,
      page: 0,
      statuses: filterDataByTabValue(zusTab.gridTabsEumn),
    });
    setFields((f) => ({
      ...f,
      statuses: filterDataByTabValue(zusTab.gridTabsEumn).map((status) =>
        String(status)
      ),
    }));
    zusParams.refetch();
  }, [zusTab.gridTabsEumn]);

  const getParams: () => ZusParamsFace = () => {
    const {
      asset_names,
      chain_name,
      tx_statuses: deposit_statuses,
      ref_no,
      risk_levels,
      statuses: input_statuses,
      ...rest
    } = fields;
    const transformFieldFromStrToArr = (fieldValue: string) => {
      const rawArr = fieldValue.split(",");

      const fieldValueArr = rawArr
        .filter((item) => item)
        .map((item) => item.trim());
      return fieldValueArr;
    };
    const {
      client_id: client_ids,
      wallet_address: wallet_addresses,
      from_address: from_addresses,
      tx_hash: tx_hashes,
    } = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        transformFieldFromStrToArr(value),
      ])
    );
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const { CreationTime, LastModifiedDate } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;
    const { start: date_last_modified_from, end: date_last_modified_to } =
      LastModifiedDate;

    const statuses = input_statuses.length
      ? input_statuses
      : filterDataByTabValue(zusTab.gridTabsEumn);

    return {
      ...initZusParams,
      chain_id,
      chain_type,
      asset_names,
      ref_no,
      deposit_statuses,
      risk_levels,
      statuses,
      client_ids,
      wallet_addresses,
      from_addresses,
      tx_hashes,
      date_created_from,
      date_created_to,
      date_last_modified_from,
      date_last_modified_to,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields({
      ...initFields,
      statuses: filterDataByTabValue(zusTab.gridTabsEumn).map((status) =>
        String(status)
      ),
    });

    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedDate.clearDate();
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.LastModifiedDate.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "client_id",
      <MpTextFieldMultiple
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
        count={apiParams.client_ids.length}
      />,
    ],
    [
      "transaction_status",
      <NodeTypesEnumMultiSelection
        label={t("ph_transaction_status")}
        value={fields.tx_statuses}
        onChange={(tx_statuses) => setFields((f) => ({ ...f, tx_statuses }))}
        enumData={EnumTransactionStatus}
        isNoSorting
      />,
    ],
    [
      "wallet_address",
      <MpTextFieldMultiple
        value={fields.wallet_address}
        onChange={onChange("wallet_address")}
        label={t("ph_wallet_address")}
        count={apiParams.wallet_addresses.length}
      />,
    ],
    [
      "sending_address",
      <MpTextFieldMultiple
        value={fields.from_address}
        onChange={onChange("from_address")}
        label={t("ph_sending_address")}
        count={apiParams.from_addresses.length}
      />,
    ],
    [
      "transaction_hash",
      <MpTextFieldMultiple
        value={fields.tx_hash}
        onChange={onChange("tx_hash")}
        label={t("ph_transaction_hash")}
        count={apiParams.tx_hashes.length}
      />,
    ],
    [
      "reference_no",
      <MpTextField
        value={fields.ref_no}
        onChange={onChange("ref_no")}
        label={t("ph_reference_no")}
      />,
    ],

    [
      "risk_level",
      <NodeTypesEnumMultiSelection
        value={fields.risk_levels}
        label={t("ph_risk_level")}
        enumData={EnumRiskLevel}
        isNoSorting
        onChange={(risk_levels) => setFields((f) => ({ ...f, risk_levels }))}
      />,
    ],
    [
      "request_status",
      <NodeTypesEnumMultiSelection
        disabled={!!filterDataByTabValue(zusTab.gridTabsEumn).length}
        value={fields.statuses}
        label={t("ph_request_status")}
        enumData={RequestStatusNoRefund}
        // enumDataSelectable={
        //   filterDataByTabValue(zusTab.gridTabsEumn).length
        //     ? filterDataByTabValue(zusTab.gridTabsEumn)
        //     : undefined
        // }
        isNoSorting
        onChange={(statuses) => setFields((f) => ({ ...f, statuses }))}
      />,
    ],
  ]);
  return (
    <>
      <StatusButtonGroup />
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_time}
        {F.chain_name}
        {F.currency}
        {F.client_id}
        {F.transaction_status}
        {F.wallet_address}
        {F.sending_address}
        {F.transaction_hash}
        {F.reference_no}
        {F.risk_level}
        {F.request_status}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    const chainInfo = findChainInfo(item.chain_type, item.chain_id);
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );

    const getApprovalProgressDisplay = () => {
      if (item.approvals_required === null) return "";

      if (item.status === RequestStatusNoRefund.AwaitRiskLevel) return "";

      if (item.approvals_required === 0) return t("auto_approve");

      return `${item.approvals}/${item.approvals_required}`;
    };

    const approvalProgress = getApprovalProgressDisplay();

    const mappedResult = [
      ["approval_progress", t("approval_progress"), approvalProgress],
      ["client_id", t("client_id"), item.client_id],
      ["chain_name", t("chain_name"), chainInfo?.name],
      ["currency", t("currency"), item.asset_name],
      [
        "transaction_amount",
        t("transaction_amount"),
        displayAmount(item.amount, decimals),
      ],
      [
        "transaction_status",
        t("transaction_status"),
        te(EnumTransactionStatus[item.deposit_status]),
      ],
      ["risk_level", t("risk_level"), te(EnumRiskLevel[item.risk_level])],
      [
        "request_status",
        t("request_status"),
        te(EnumRequestStatus[item.status]),
      ],
      ["transaction_hash", t("transaction_hash"), item.tx_hash],
      ["wallet_address", t("wallet_address"), item.wallet_address],
      ["wallet_tag", t("wallet_tag"), item.wallet_tag],
      ["sending_address", t("sending_address"), item.from_address],
      ["reference_no", t("reference_no"), item.ref_no],

      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};

export function useAllPendingDepositRecordCount(enabled: boolean = true) {
  const refetchInterval = Number(INTERVAL_PENDING_OPERATIONS_COUNT) * 1000;

  const res = useQuery(
    "getToDoCountDepositRecord",
    getLedgerWalletManagerDepositRecordToDoCount,
    { refetchInterval, enabled }
  );

  return String(res.data);
}
