import React from 'react';
import { Box } from '@mui/material'

interface IStaticBarProps{
    position:"top" | "bottom" | "left"| "right"
    content: React.ReactNode
    isShow?: boolean
}

const StaticBar: React.FC<IStaticBarProps> = ({
    position,
    content,
    isShow = true
})=>{
    const sx = {
        position: "sticky",
        left: position === "top" || position === "left" ? 0 : "",
        top: position === "top" || position === "left" || position === "right"? 0:"",
        bottom: position === "bottom" ? 0 : "",
        right: position === "right" ? 0: "",
        zIndex: 1000,
        width: position === "top" || position === "bottom" ? "100%" :"5%",
        height: position === "top" || position === "bottom" ? "5%" :"100%",
    }
    return <>
    {isShow &&
        <Box sx={sx}>
            {content}
        </Box>
    }
    </>
}

export default StaticBar;
