import { useTranslate } from "react-admin";

import Stack from "@mui/material/Stack";

export function NoRowsOverlay({ customWording }: { customWording?: string }) {
  const translate = useTranslate();
  const t = (key: string) => translate(`component.${key}`);
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      {customWording || t("noRecords")}
    </Stack>
  );
}
