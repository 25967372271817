export enum FilterFieldKey {
  OperationType = "operationType",
  ChainName = "chainName",
  AssetNames = "assetNames",
  BatchId = "batchId",
  createdDateFrom = "createdDateFrom",
  createdDateTo = "createdDateTo",
}

export interface FilterFields {
  [FilterFieldKey.OperationType]: string;
  [FilterFieldKey.ChainName]: string;
  [FilterFieldKey.AssetNames]: string[];
  [FilterFieldKey.BatchId]: string;
  [FilterFieldKey.createdDateFrom]: string;
  [FilterFieldKey.createdDateTo]: string;
}
