import { useEffect, useState } from "react";

import EnumClientType from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerClientType";

import { getClientManagementExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  NodeTypesEnumMultiSelection,
  SingleSelection,
} from "../../../components/GeneralSelection";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Button,
  Container,
  SelectChangeEvent,
  Tooltip,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { enumYesAndNo, FeatureCodes } from "../../../utils/constant";
import {
  downloadFiles,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance, useZusDialogStore } from "../../../zustand/store";
import ClientManagementList from "./ClientManagementList";

const translatePrefix = "ledgerClientManagement";
interface Ifields {
  client_id: string;
  client_types: string[];
  withdrawable: string;
  transferable: string;
  disabled_client: string;
}
interface ZusParamsFace {
  client_ids: string[];
  client_types: string[];
  withdrawable: boolean;
  transferable: boolean;
  disabled: boolean;
  date_created_from: string;
  date_created_to: string;
  pageSize: number;
  page: number;
}

export enum EnumClientManagementFilterClientType {
  House = EnumClientType.House,
  Client = EnumClientType.Client,
}

const initFields = {
  client_id: "",
  client_types: [] as string[],
  withdrawable: "",
  transferable: "",
  disabled_client: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;
export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);
const Pkey = FeatureCodes.ledger.ClientManagement;

export const reversedEnumFunction: (
  enumObj: Record<string, string>
) => Record<string, string> = (enumObj) =>
  Object.fromEntries(
    Object.entries(enumObj).map(([key, value]) => [value, key])
  );

export default function ClientManagement() {
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();

  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getClientManagementExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];

    const res = listMapping("name", rawRes, { t, te, omitKeys });
    const config = {};
    downloadFiles(`Client Management`, res, config);
  };

  useEffect(() => {
    return () => {
      zusDialog.close();
      zusDialog.closeAll();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <Box sx={customSx.filterCmdBar}>
          {hasPermission(Pkey.AddClient) && (
            <Tooltip
              title={t("addNewClient")}
              children={
                <Button
                  variant="outlined"
                  onClick={() => zusDialog.open("operationDialog")}
                >
                  {t("addClient")}
                </Button>
              }
            />
          )}
        </Box>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <ClientManagementList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
  };

  const getParams: () => ZusParamsFace = () => {
    const fieldsToParams = Object.fromEntries(
      Object.entries(fields).map(([key, value]) => [
        key,
        value === enumYesAndNo.yes
          ? true
          : value === enumYesAndNo.no
          ? false
          : value,
      ])
    );
    const {
      client_id,
      client_types,
      withdrawable,
      transferable,
      disabled_client: disabled,
    } = fieldsToParams;

    const clientIdRawArr = client_id.split(",");

    const client_ids = clientIdRawArr
      .filter((item: string) => item)
      .map((item: string) => item.trim());

    const { CreationTime } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;

    return {
      ...initZusParams,
      client_ids,
      client_types,
      withdrawable,
      transferable,
      disabled,
      date_created_from,
      date_created_to,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    [
      "client_id",
      <MpTextFieldMultiple
        value={fields.client_id}
        onChange={onChange("client_id")}
        label={t("ph_client_id")}
        count={apiParams.client_ids.length}
      />,
    ],
    [
      "client_type",
      <NodeTypesEnumMultiSelection
        label={t("ph_client_type")}
        onChange={(client_types) => setFields((f) => ({ ...f, client_types }))}
        value={fields.client_types}
        enumData={EnumClientManagementFilterClientType}
        isNoSorting
      />,
    ],
    [
      "withdrawable",
      <SingleSelection
        value={fields.withdrawable}
        label={t("ph_withdrawable")}
        enumData={enumYesAndNo}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, withdrawable: "" }))}
        onChange={onChange("withdrawable")}
      />,
    ],
    [
      "transferable",
      <SingleSelection
        value={fields.transferable}
        label={t("ph_transferable")}
        enumData={enumYesAndNo}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, transferable: "" }))}
        onChange={onChange("transferable")}
      />,
    ],
    [
      "disabled_client",
      <SingleSelection
        value={fields.disabled_client}
        label={t("ph_disabled_client")}
        enumData={enumYesAndNo}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, disabled_client: "" }))}
        onChange={onChange("disabled_client")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.client_id}
        {F.client_type}
        {F.withdrawable}
        {F.transferable}
        {F.disabled_client}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};

  const res = array.map((item: any) => {
    const mappedResult = [
      ["client_id", t("client_id"), item.client_id],
      ["client_type", t("client_type"), te(EnumClientType[item.client_type])],
      [
        "withdrawable",
        t("withdrawable"),
        item.withdrawable ? t("yes") : t("no"),
      ],
      [
        "transferable",
        t("transferable"),
        item.transferable ? t("yes") : t("no"),
      ],
      [
        "disabled_client",
        t("disabled_client"),
        item.disabled ? t("yes") : t("no"),
      ],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
      ["rawData", "", item],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
