import axios from "./axiosInstance";
import { listFace } from "./merchant";
//get balance for different types of wallet
export const getWalletBalance = async (
  params: any,
  config?: any
): Promise<any> => {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { limit: pageSize, offset: pageSize * page, ...rest },
    ...config,
  };
  const res = await axios.get<listFace>(
    `/merchant_portal/wallet_type_balance/get_all_with_chain`,
    input
  );
  return res;
};
