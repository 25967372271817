import BigNumber from "bignumber.js";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import { adjustBalance, checkCurrentBalanceApi } from "../../api/merchant";
import { AdjustmentFieldsFace } from "../../api/types";
import { useAssets, useChains } from "../../components/FetchConfig";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from "../../components/MuiGenerals";
import { useAlerting, useYubiPrompt } from "../../hooks";
import {
  allowNegativeAmt,
  displayAmount,
  findDecimalByChainNameAndAsset,
  formatBigNumber,
  formatLargeNumberforBigNumber,
  toDBInDecimals
} from "../../utils/helper";
import { customSx } from "../../utils/styling";
import MpTextField from "../MpTextField";
import YubiPrompter from "../Prompter";

const initAjustmentFields = {
  chain_name: "",
  asset_name: "",
  walletAddress: "",
  currentWalletBalance: "",
  adjustment_amount: "",
  remark: "",
};

interface PropsFace {
  addBalanceAdjustment: boolean;
  setAddBalanceAdjustment: React.Dispatch<React.SetStateAction<boolean>>;
  setFreshTableBol: React.Dispatch<React.SetStateAction<boolean>>;
}

export function AddBalanceAdjustmentDialog(props: PropsFace) {
  const { addBalanceAdjustment, setAddBalanceAdjustment, setFreshTableBol } =
    props;
  const { chainObj, selectChainByPieces } = useChains();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [fields, setFields] =
    useState<AdjustmentFieldsFace>(initAjustmentFields);
  const { selectAssetNamesByChain, selectAssetsByChain } = useAssets();
  useEffect(() => {
    if (fields.chain_name && fields.asset_name && fields.walletAddress) {
      checkCurrentBalance();
    } else {
      setFields((fields) => ({ ...fields, currentWalletBalance: "" }));
    }
  }, [fields.chain_name, fields.walletAddress, fields.asset_name]);
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value, name } = e.target;
      if (name === "adjustment_amount" && !allowNegativeAmt(String(value))) {
        return;
      }
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (name === "chain_name") {
      setFields((fields) => ({ ...fields, chain_name: value, asset_name: "" }));
    } else {
      setFields((fields) => ({ ...fields, [name]: value }));
    }
  };

  const checkCurrentBalance = async () => {
    const {
      chain_name,
      currentWalletBalance,
      remark,
      adjustment_amount,
      walletAddress: walletAddressBeforeTrimmed,
      asset_name,
    } = fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    const assets = selectAssetsByChain({ chain_name });
    const selectedAsset = assets.find(
      (item) => item.asset_name === fields.asset_name
    );
    if (!selectedAsset) return alerting("error", t("assetNotSupported"));
    const decimals = selectedAsset.decimals;

    const walletAddress = walletAddressBeforeTrimmed.trim();

    const params = {
      asset_name,
      chain_id,
      chain_type,
      walletAddress,
    };
    const checkCurrentBalanceApiResponse = await checkCurrentBalanceApi(params);
    const balance =
      JSON.stringify(checkCurrentBalanceApiResponse) === JSON.stringify({})
        ? "0"
        : checkCurrentBalanceApiResponse?.balance;
    setFields((fields) => ({
      ...fields,
      currentWalletBalance: balance ? displayAmount(balance, decimals) : "", // if displayed error message will return undefined and fall to else condition
    }));
  };

  const confirmAdjustmentBalance = async () => {
    const { chain_name, currentWalletBalance, adjustment_amount, ...rest } =
      fields;
    const { chain_id, chain_type } = selectChainByPieces({
      chain_name: fields.chain_name,
    });
    const decimals = findDecimalByChainNameAndAsset(
      String(chain_type),
      String(chain_id),
      fields.asset_name
    );
    const params = {
      // adjustment: {
      chain_type: chain_type,
      chain_id: chain_id,
      amount: toDBInDecimals(fields.adjustment_amount, decimals),
      ...rest,
      // },
    };
    try {
      const adjustBalanceResponse = await getResAfterYubi(
        adjustBalance,
        params
      );

      if (adjustBalanceResponse) {
        setFreshTableBol(true);
        setAddBalanceAdjustment(false);
        setFields(initAjustmentFields);
        return alerting("success", t("adjustBalanceSuccess"));
      } else {
        return;
      }
    } catch (error) {
      console.error("createWalletAddressResonse error", error);
      setFields(initAjustmentFields);
    }
    setFields(initAjustmentFields);
    setAddBalanceAdjustment(false);
  };

  const calculateFutureBalance = () => {
    const bal = BigNumber(
      formatLargeNumberforBigNumber(fields.currentWalletBalance)
    ).plus(formatLargeNumberforBigNumber(fields.adjustment_amount));
    return formatBigNumber(bal);
  };

  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={addBalanceAdjustment}
        onClose={() => {
          setAddBalanceAdjustment(false);
          setFields(initAjustmentFields);
        }}
        fullWidth={true}
      >
        <DialogTitle>{t("addBalanceAdjustment")}</DialogTitle>
        <DialogContent>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("chainTypeError")}</InputLabel>
            <Select
              name="chain_name"
              value={fields.chain_name}
              onChange={onSelectChange}
            >
              {Object.entries(chainObj).map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("currencyError")}</InputLabel>
            <Select
              name="asset_name"
              value={fields.asset_name}
              onChange={onSelectChange}
            >
              {assetsNames.map((asset_name, i) => (
                <MenuItem key={i} value={asset_name}>
                  {asset_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <MpTextField
            label={t("walletAddressError")}
            fullWidth={true}
            onBlur={onChange("walletAddress")}
          />
          <MpTextField
            label={t("currentWalletBalance")}
            fullWidth={true}
            value={fields.currentWalletBalance}
            disabled={true}
          />
          <MpTextField
            label={t("adjustAmountError")}
            fullWidth={true}
            type="string"
            name="adjustment_amount"
            value={fields.adjustment_amount}
            onChange={onChange("adjustment_amount")}
          />
          <MpTextField
            label={t("futureWalletBalance")}
            fullWidth={true}
            value={
              fields.currentWalletBalance && fields.adjustment_amount
                ? calculateFutureBalance()
                : ""
            }
            disabled={true}
          />
          <MpTextField
            label={t("remarksError")}
            fullWidth={true}
            value={fields.remark}
            onChange={onChange("remark")}
          />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={confirmAdjustmentBalance}
            disabled={
              !fields.currentWalletBalance || !Number(fields.adjustment_amount)
            }
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setAddBalanceAdjustment(false);
              setFields(initAjustmentFields);
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
