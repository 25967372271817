import { useEffect } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import { getNonceToolNonceTab } from "../../../api/merchant";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { Tooltip } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { useAlerting, useTranslation } from "../../../hooks";
import { dataGridDefaults } from "../../../utils/constant";
import { copyToClipboard } from "../../../utils/helper";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./NonceTab";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "NonceTool";
const api = {
  getNonceToolNonceTab,
};
export default function NonceTabList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
}) {
  const { t, te } = useTranslation(translatePrefix);
  const translate = useTranslate();
  const { alerting } = useAlerting();
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping } = props;
  const listRes = useQuery({
    queryKey: ["nonceTool", zusParams.body, zusParams.refetchCounter],
    queryFn: () => api.getNonceToolNonceTab(zusParams.body),
    // cacheTime: 0,
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  const CopyableCell =
    (field: "sending_address" | "transaction_hash") =>
    (params: GridRenderCellParams) => {
      const copyableText = params.row[field];
      const addressOnClick = (copyableText: string) => {
        copyToClipboard(copyableText);
        alerting("success", translate("report.copied"));
      };
      const isApi = copyableText === "api";
      return (
        <Tooltip
          title={copyableText}
          onClick={() => !isApi && addressOnClick(copyableText)}
        >
          <div
            style={
              isApi
                ? undefined
                : {
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    color: "#4A90F7",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }
            }
          >
            {copyableText}
          </div>
        </Tooltip>
      );
    };

  const columns: GridColDef[] = [
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("step_sequence", t("step_sequence")),
    useGenGridCol("transaction_sequence", t("transaction_sequence")),
    useGenGridCol("sending_address", t("sending_address"), {
      renderCell: CopyableCell("sending_address"),
      minWidth: 210,
    }),
    useGenGridCol("status", t("status")),
    useGenGridCol("nonce", t("nonce")),
    useGenGridCol("operation_type", t("operation_type")),
    useGenGridCol("transaction_hash", t("transaction_hash"), {
      renderCell: CopyableCell("transaction_hash"),
      minWidth: 210,
    }),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("last_modified_time", t("last_modified_time")),
  ];

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
