import React, { useState } from "react";
import { useTranslate } from "react-admin";
import { yubiKeyBind } from "../../../api/merchant";
import { Box, Button } from "../../../components/MuiGenerals";
import { useAlerting } from "../../../hooks";
import { toDisplayTime } from "../../../utils/helper";
import {
  useYubiKeysStoreForReg,
  zusRefetchStore,
} from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";

interface IInitFields {
  otp: string;
  new_otp: string;
}
const initFields = {
  otp: "",
  new_otp: "",
};

const YubikeyAlteration = () => {
  const [fields, setFields] = useState<IInitFields>(initFields);
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);

  const refetchFun = zusRefetchStore((state) => state.refetchFunc);

  const myYubiDetail = useYubiKeysStoreForReg((state) => state.myDetail);
  const yubikeyStatus = myYubiDetail?.yubi_key_status;
  const { alerting } = useAlerting();
  const yubiKeyUpdatedDate = myYubiDetail?.date_last_yubi_key_updated
    ? toDisplayTime(myYubiDetail?.date_last_yubi_key_updated || null)
    : toDisplayTime(myYubiDetail?.date_created || null);
  const yubiKeyId = myYubiDetail?.pub_id;
  const handleOtpInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFields((pre) => ({ ...pre, [name]: value }));
  };

  const proposalStatus = myYubiDetail?.proposals
    ? myYubiDetail.proposals[0]
    : { act: 999, status: 999 }; //return an false content for if-else condition{}

  const handleAlterBtn = async () => {
    if (!fields.otp || !fields.new_otp)
      return alerting("warning", t("enterOtpAndNewOtp"));
    const res = await yubiKeyBind(fields);
    refetchFun({ where: "yubiKeyRegPage" });
    if (res) return alerting("success", t("register_submitted"));
  };

  const statusByColors = [
    { status: "pendingApproval", textColor: "#4a90f7", enum: 0 },
    { status: "bound", textColor: "#3DD598", enum: 1 },
  ].filter((item) => yubikeyStatus === item.enum);
  const yubiIdWithDate = `${yubiKeyId}   (${t(
    "Last_Update_Time"
  )}  : ${yubiKeyUpdatedDate})`;

  const status = t(statusByColors[0].status);

  return (
    <>
      {(proposalStatus.act === 4 || proposalStatus.act === 6) &&
      proposalStatus.status === 1 ? (
        <Box sx={{ marginLeft: "1.5rem", marginTop: "1.5rem" }}>
          {" "}
          <Section
            tzKey={"status"}
            value={t("pendingApproval")}
            textColor={"#4a90f7"}
          />
        </Box>
      ) : (
        <Box
          sx={{ marginLeft: "1.5rem", marginTop: "1.5rem" }}
          className="gridFilter"
        >
          <Section tzKey={"yubikeyID"} value={yubiIdWithDate} />

          <Section
            tzKey={"yubikey_status"}
            value={status}
            textColor={statusByColors[0].textColor}
          />
          <InputSection
            tzKey={"old_otp"}
            labelName={"phOldOtp"}
            handleInput={handleOtpInput}
            fieldValue={fields.otp}
            keyName="otp"
          />
          <InputSection
            tzKey={"new_otp"}
            labelName={"phNewOtp"}
            handleInput={handleOtpInput}
            fieldValue={fields.new_otp}
            keyName="new_otp"
          />
          <Button
            sx={{
              margin: "2rem 0",
              transform: "translateX(38rem)",
            }}
            onClick={handleAlterBtn}
            color="secondary"
            variant="contained"
          >
            {t("alter")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default YubikeyAlteration;

export const Section = (props: any) => {
  const { value, tzKey, textColor } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  return (
    <>
      <section style={{ display: "flex", marginTop: "2.5rem" }}>
        <div style={{ minWidth: "15rem" }}>{t(`${tzKey}`)}</div>
        <div style={{ textAlign: "left", color: `${textColor}` }}>{value}</div>
      </section>
    </>
  );
};

export const InputSection = (props: any) => {
  const { tzKey, handleInput, fieldValue, keyName, labelName } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  return (
    <>
      <section
        style={{
          display: "flex",
          marginTop: "1.5rem",
        }}
      >
        <div
          style={{
            minWidth: "14.5rem",
            paddingTop: "1rem",
          }}
        >
          {t(`${tzKey}`)}
        </div>
        <div style={{ minWidth: "330px", textAlign: "left" }}>
          <MpTextField
            name={keyName}
            fullWidth={true}
            label={t(labelName)}
            value={fieldValue}
            onChange={handleInput}
          />
        </div>
      </section>
    </>
  );
};
