import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCallbackDetails,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import React from "react";
import { useTranslate } from "react-admin";
import {
  approveWithdrawCheckingTimeControlRequest,
  rejectWithdrawCheckingTimeControlRequest,
} from "../../api/signer";
import { OpApprove, OpRedirect } from "../../assets/icons";
import { useAlerting, usePermission, useYubiPrompt } from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import { FeatureCodes, dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";

import { listFace } from "../../views/Tools/AuditLogTabView";
import { useZusDialogStore } from "../../zustand/store";
import { CustomPagination } from "../CustomPagination";
import { Box } from "../MuiGenerals";
import { NoRowsOverlay } from "../NoRowsOverlay";
import OpIconButton, { sxOpTextButton } from "../OpIconButton";
import YubiPrompter from "../Prompter";
import ApprovalDialog from "./ApprovalDialog";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

const Pkey =
  FeatureCodes.assetManagement.WithdrawApprovalCheckingTimeSetting.TabRecord;

interface propsFace {
  list: listFace;
  setPage: (page: number, details: GridCallbackDetails<any>) => void;
  listMapping: any;
  refresh: Function;
}
const RequestRecordTabList = (props: propsFace) => {
  const translate = useTranslate();
  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();

  const { list, setPage, listMapping, refresh } = props;
  const t = (key: string, option?: Object) =>
    translate(`withdrawApprovalCheckingTimeSetting.${key}`, option);
  const totalRecords = list.count;
  const { hasPermission } = usePermission();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { email } = useAppSelector(selectProfile);

  const content: GridRowsProp = listMapping("key", list.rows, { translate });
  async function onApproveOrReject(isApprove: boolean, direction?: string) {
    const { rawData = {} } = zusDialog.meta;
    const approvalCompleted =
      rawData.general_proposal.general_proposal_details.length;
    const noApprovePermission =
      direction === "approveBtn" &&
      !(approvalCompleted
        ? hasPermission(Pkey.SecondApprover)
        : hasPermission(Pkey.FirstApprover));

    const noPermission =
      noApprovePermission ||
      (direction === "rejectBtn" && !hasPermission(Pkey.Reject));

    if (noPermission) {
      return alerting("error", t(`no_approval_permission`));
    }

    const isCreator = rawData.general_proposal.created_by === email;
    const isOperatedBefore =
      rawData.general_proposal.general_proposal_details[0]?.last_modified_by ===
      email;

    if (isCreator && isApprove) {
      return alerting("error", t("alert_creator_approver_same"));
    }
    if (isOperatedBefore && isApprove) {
      return alerting("error", t("alert_approvers_same"));
    }

    const apiFn = isApprove
      ? approveWithdrawCheckingTimeControlRequest
      : rejectWithdrawCheckingTimeControlRequest;
    const apiParams = isApprove
      ? { id: String(rawData.id), approvalCompleted }
      : { id: String(rawData.id) };

    const apiRes = await getResAfterYubi(apiFn, apiParams);

    if (!apiRes) {
      return;
    }

    isApprove
      ? alerting("success", t(`approve_success`))
      : alerting("success", t(`reject_success`));

    zusDialog.closeAll();
    refresh();
  }

  const approvalButton = (params: GridRenderCellParams) => {
    const rawData: any = list.rows.find(
      (item: any) => item.id === String(params.row?.proposal_no)
    );
    if (!rawData) {
      return false;
    }
    if (!rawData) return null;

    return (
      <Box sx={sxOpTextButton}>
        <OpIconButton
          title={t("approve_reject_btn")}
          url={OpApprove}
          isDisabled={
            params.row.approval_status !==
            translate("enumConstants.Pending") /* || isDecided */
          }
          onClick={() =>
            zusDialog.open("approvalDialog", {
              from: "operation",
              rawData,
              ripeData: params.row,
            })
          }
        />
      </Box>
    );
  };

  const ApprovalProgressButton = (params: GridRenderCellParams) => {
    const rawData: any = list.rows.find(
      (item: any) => item.id === String(params.row?.proposal_no)
    );
    const onClick = async () => {
      zusDialog.open("approvalDialog", {
        from: "progress",
        rawData,
      });
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "37%",
          justifyContent: "space-between",
        }}
      >
        <span>
          {params.row.rawData.numberOfApprovedApprovers}/
          {params.row.rawData.totalApproversRequired}
        </span>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={onClick}
          size="1rem"
        />
      </Box>
    );
  };
  const showBtn =
    hasPermission(Pkey.FirstApprover) ||
    hasPermission(Pkey.SecondApprover) ||
    hasPermission(Pkey.Reject);
  const columns: GridColDef[] = [
    useGenGridCol("1", t("operation"), {
      maxWidth: 80,
      renderCell: approvalButton,
    }),
    useGenGridCol("proposal_no", t("proposal_no"), { maxWidth: 80 }),
    useGenGridCol("approval_progress", t("approval_progress"), {
      renderCell: ApprovalProgressButton,
      maxWidth: 130,
    }),
    useGenGridCol("before_edit", t("before_edit"), { maxWidth: 80 }),
    useGenGridCol("after_edit", t("after_edit"), { maxWidth: 80 }),
    useGenGridCol("approval_status", t("approval_status"), { maxWidth: 128 }),
    useGenGridCol("created_by", t("created_by"), {}),
    useGenGridCol("creation_time", t("creation_time")),
  ].filter((col) => {
    if (col.field === "1" && !showBtn) {
      return false;
    }
    return true;
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={list.count}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
      <YubiPrompter {...prompterConfig} />
      <ApprovalDialog {...{ onApproveOrReject }} />
    </>
  );
};

export default React.memo(RequestRecordTabList);
