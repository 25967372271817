import TableTab from "../../../components/TableTab";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  SelectChangeEvent,
  Container,
  Box,
  Button,
} from "../../../components/MuiGenerals";
import {
  toDisplayTime,
  listMappingTransform,
  enumMapping,
  findChainByName,
} from "../../../utils/helper";
import { useDatePicker } from "../../../components/DatePicker";
import { Dispatch, SetStateAction, useState } from "react";
import {
  Ipage,
  IportalUserProposalsParams,
  IYubikeyProposalType,
  IportalUserYubiKeyStatus,
} from "../../../api/types";
import { customSx } from "../../../utils/styling";
import {
  enumPortalUserYubiKeyStatus,
  enumYubikeyProposalType,
  enumPortalUserProposalStatus,
  tableConfig,
  enumNonceToolPriorityListStatus,
  FeatureCodes,
} from "../../../utils/constant";
import PriorityListTabList from "./PriorityListTabList";
import GeneralBtn from "../../../components/GeneralBtn";
import { MultiSelection } from "../../../components/GeneralSelection";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { createZusInstance, useZusDialogStore } from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useTranslate } from "react-admin";
import { useChains } from "../../../components/FetchConfig";
import { selectChainByPieces } from "../../../reducer/chainsSlice";
import DialogInOne from "../../../components/DialogInOne";
import {
  getLatestNonce,
  postDeleteNonce,
  postInsertNonce,
} from "../../../api/signer";
import Tooltip from "@mui/material/Tooltip";

const translatePrefix = "NonceTool";
const Pkey = FeatureCodes.tools.NonceTool.TabPriorityList;
const Enum = {
  portalUserProposalStatus: enumMapping(enumPortalUserProposalStatus),
  portalUserYubiKeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
  yubikeyProposalType: enumMapping(enumYubikeyProposalType),
};
interface Ifields {
  chain_name: string;
  address: string;
  statuses: string[];
}

const initFields = {
  chain_name: "",
  address: "",
  statuses: [] as string[],
} as const;

interface ZusParamsFace {
  created_date_from: string;
  created_date_to: string;
  last_modified_date_from: string;
  last_modified_date_to: string;
  chain_type: number;
  chain_id: number;
  statuses: string[];
  address: string;
}

const initZusParams = {
  page: 0,
  pageSize: tableConfig.pageSize,
} as const;
export const useZusParams = createZusInstance<Partial<ZusParamsFace> & Ipage>(
  initZusParams
);
export default function PriorityListTab() {
  const { hasPermission } = usePermission();
  const zusDialog = useZusDialogStore();
  const { t } = useTranslation(translatePrefix);
  const [openInsertNonce, setOpenInsertNonce] = useState(false);
  const handleInsert = () => {
    zusDialog.open("operationDialog");
  };
  const [isSearched, setIsSearched] = useState(false);

  return (
    <>
      {/* <Box sx={customSx.layoutBox}> */}
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.Insert) && (
          <Button variant="outlined" onClick={handleInsert}>
            {t("insert")}
          </Button>
        )}
      </Box>
      <Container disableGutters maxWidth={false}>
        <FilterBar setIsSearched={setIsSearched} />
      </Container>
      <DivideLine />
      {
        <Container
          style={customSx.datagridContainer}
          maxWidth={false}
          disableGutters
        >
          <TableTab>
            <PriorityListTabList {...{ isSearched, listMapping }} />
          </TableTab>
        </Container>
      }
      <LocalDialogs />

      {/* </Box> */}
    </>
  );
}
export function FilterBar(props: {
  setIsSearched: Dispatch<SetStateAction<boolean>>;
}) {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { setIsSearched } = props;
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const { selectChainByPieces } = useChains();
  const zusParams = useZusParams();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onArraySelectChange =
    (name: keyof Ifields) => (e: SelectChangeEvent<string[]>) => {
      const { value } = e.target;
      const newValue = typeof value === "string" ? value.split(",") : value;
      return setFields((fields) => ({ ...fields, [name]: newValue }));
    };
  const onSearch = () => {
    const { chain_name, ...rest } = fields;
    if (!chain_name) {
      return alerting("error", t("select_chain_name_first"));
    }
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const { start: created_date_from, end: created_date_to } =
      DateObj.CreationTime;
    const { start: last_modified_date_from, end: last_modified_date_to } =
      DateObj.LastModifiedTime;
    zusParams.setBody({
      ...rest,
      created_date_from,
      created_date_to,
      last_modified_date_from,
      last_modified_date_to,
      chain_type,
      chain_id: Number(chain_id),
      page: 0,
    });
    setIsSearched(true);
    zusParams.refetch();
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };
  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.LastModifiedTime.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        onlyETHAndXRP
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],

    [
      "status",
      <MultiSelection
        label={t("ph_status")}
        value={fields.statuses}
        onChange={onArraySelectChange("statuses")}
        isNoSorting={true}
        enumData={enumNonceToolPriorityListStatus}
      />,
    ],
    [
      "sending_address",
      <MpTextField
        value={fields.address}
        onChange={onChange("address")}
        label={t("ph_sending_address")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_time}
        {F.chain_name}
        {F.status}
        {F.sending_address}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn onClick={onSearch} label="search" />
        <GeneralBtn onClick={onReset} label="reset" />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  chain_name: string | undefined,
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const reversedEnumNonceToolPriorityListStatus = Object.fromEntries(
    Object.entries(enumNonceToolPriorityListStatus).map(([key, value]) => [
      value,
      key,
    ])
  );
  if (!array) {
    return [];
  }
  const res = array.map((item: any) => {
    const displayLastModifiedDate =
      item.last_modified_date || item.created_date;
    const mappedResult = [
      ["id", t("id"), item.id],
      ["chain_name", t("chain_name"), chain_name],
      ["sending_address", t("sending_address"), item.address],
      ["nonce", t("nonce"), item.nonce],
      [
        "status",
        t("status"),
        te(reversedEnumNonceToolPriorityListStatus[item.status]),
      ],
      ["batch_id", t("batch_id"), item.used_at_batch_id],
      ["transaction_hash", t("transaction_hash"), item.used_at_tx],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(displayLastModifiedDate),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

function LocalDialogs(props: any) {
  const { t, te } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const [isNexted, setIsNexted] = useState(false);
  const { selectChainByPieces } = useChains();
  interface FieldFace {
    chain_name: string;
    address: string;
    nonce: string;
  }
  const initFields: FieldFace = {
    chain_name: "",
    address: "",
    nonce: "",
  };
  const [fields, setFields] = useState<FieldFace>(initFields);
  interface LatestNonceObjFace {
    next_onchain_nonce: number;
    next_system_nonce: number;
  }
  const initLatestNonceObj: LatestNonceObjFace = {
    next_onchain_nonce: -1,
    next_system_nonce: -1,
  };

  const [latestNonceObj, setLatestNonceObj] = useState(initLatestNonceObj);
  const TheButton = {
    Next: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={async () => {
          const { chain_name, address } = fields;
          if (!chain_name || !address) {
            return alerting("error", t("allFieldsShouldBeFilled"));
          }

          const chainInfo = selectChainByPieces({ chain_name: chain_name });
          const { chain_id, chain_type } = chainInfo;
          zusDialog.openExtra("loadingDialog");

          const res = await getLatestNonce({
            chain_id: Number(chain_id),
            chain_type,
            address,
          });

          zusDialog.closeExtra();

          if (!res) {
            return;
          }
          setLatestNonceObj((prev) => ({
            ...prev,
            next_onchain_nonce:
              res.next_onchain_nonce === null
                ? -1
                : Number(res.next_onchain_nonce),
            next_system_nonce:
              res.next_onchain_nonce === null
                ? -1
                : Number(res.next_system_nonce),
          }));
          setIsNexted(true);
        }}
      >
        {t("next")}
      </Button>
    ),
    Confirm: () => (
      <Button
        color="secondary"
        variant="contained"
        onClick={async () => {
          const { chain_name, nonce, address } = fields;
          const { next_system_nonce, next_onchain_nonce } = latestNonceObj;
          const latest_onchain_nonce = next_onchain_nonce - 1;

          const { chain_type, chain_id } = selectChainByPieces({ chain_name });
          if (!nonce) {
            return alerting("error", t("insert_nonce_error"));
          }
          if (
            next_system_nonce - latest_onchain_nonce <= 0 ||
            Number(nonce) >= next_system_nonce ||
            Number(nonce) <= latest_onchain_nonce
          ) {
            return alerting("error", t("inserted_fail_alert"));
          }
          const res = await postInsertNonce({
            chain_id: Number(chain_id),
            chain_type: chain_type!,
            nonce,
            address,
          });
          if (!res) {
            return;
          }
          alerting("success", t("inserted_success_alert"));
          setIsNexted(false);
          setFields(initFields);
          zusDialog.closeAll();
          zusParams.refetch();
        }}
      >
        {t("confirm")}
      </Button>
    ),
    Back: () => (
      <Button
        color="inherit"
        variant="contained"
        onClick={() => setIsNexted(false)}
        style={{ marginRight: "-16px" }}
      >
        {t("back")}
      </Button>
    ),
  };
  const { ripeData = {} } = zusDialog.meta || {};

  const sx = {
    buttonGroup: { display: "flex", gap: "1rem", marginRight: "1rem" },
  };
  const dialogConfigOnDelete = {
    title: "",
    self: {
      open: zusDialog.match("deleteDialog"),
      onClose: () => zusDialog.close(),
    },
    content: <div>{t("delete_dialog")}</div>,
    onCancel: () => zusDialog.close(),
    onConfirm: async () => {
      const {
        id,
        chain_type,
        chain_id,
        sending_address: address,
        status,
      } = ripeData;
      if (status === te("Used")) {
        return alerting("error", t("deleted_fail_alert"));
      }
      const res = await postDeleteNonce({
        chain_type,
        chain_id,
        id,
        address,
      });
      if (!res) {
        return;
      }
      alerting("success", t("deleted_success_alert"));
      zusDialog.closeAll();
      zusParams.refetch();
    },
  } as const;

  const onChange =
    (
      type: keyof {
        chain_name: string;
        address: string;
        nonce: string;
      }
    ) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const { next_system_nonce, next_onchain_nonce } = latestNonceObj;

  const latest_onchain_nonce = next_onchain_nonce - 1;

  const inputRangeLowerValue =
    latest_onchain_nonce + 1 >= 0 ? latest_onchain_nonce + 1 : undefined;
  const inputRangeHigherValue =
    next_system_nonce - 1 >= 0 ? next_system_nonce - 1 : undefined;

  const inputRangeHasValue =
    !isNaN(Number(inputRangeLowerValue)) &&
    !isNaN(Number(inputRangeHigherValue));

  const isDisplayingInputRange = inputRangeHasValue
    ? inputRangeHigherValue! >= inputRangeLowerValue!
    : false;

  const isInputRangeSameValue = inputRangeHigherValue === inputRangeLowerValue;

  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        onlyETHAndXRP
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "sending_address",
      <MpTextField
        value={fields.address}
        onChange={onChange("address")}
        label={t("ph_sending_address")}
      />,
    ],
    [
      "chain_name_confirm",
      <div style={{ color: "#4a90f7" }}>
        {selectChainByPieces({ chain_name: fields.chain_name }).name}
      </div>,
    ],
    [
      "sending_address_confirm",
      // <div >

      <Tooltip
        title={`${fields.address}`}
        style={{ color: "#4a90f7" }}
        placement="top-start"
      >
        <div>{fields.address}</div>
      </Tooltip>,

      // </div>,
    ],
    [
      "next_nonce",
      <div style={{ color: "#4a90f7" }}>
        {next_system_nonce >= 0 ? next_system_nonce : "N/A"}{" "}
        <span style={{ color: "#aaa", fontSize: "12px" }}>
          {t("next_nonce_notice")}
        </span>
      </div>,
    ],
    [
      "last_chain_nonce",
      <div style={{ color: "#4a90f7" }}>
        {latest_onchain_nonce >= 0 ? latest_onchain_nonce : "N/A"}{" "}
        <span style={{ color: "#aaa", fontSize: "12px" }}>
          {t("last_nonce_notice")}
        </span>
      </div>,
    ],
    [
      "input_range",

      !isDisplayingInputRange ? (
        <div style={{ color: "#4a90f7" }}>{t("no_input_range")}</div>
      ) : isInputRangeSameValue ? (
        <div style={{ color: "#4a90f7" }}>{inputRangeLowerValue}</div>
      ) : (
        <div>
          <span style={{ color: "#4a90f7" }}>{inputRangeLowerValue}</span>
          {" - "}
          <span style={{ color: "#4a90f7" }}>{inputRangeHigherValue}</span>
        </div>
      ),
    ],
    [
      "nonce",
      <MpTextField
        value={fields.nonce}
        onChange={onChange("nonce")}
        label={t("ph_nonce")}
        style={{ marginTop: "2px" }}
      />,
    ],
  ]);

  const beforeHittingNextContent = () => (
    <Box
      sx={{
        ...customSx.gridFilter,
        ".MuiInputLabel-shrink": {
          display: "none",
        },
      }}
      className="gridFilter"
    >
      {F.chain_name}
      {F.sending_address}
    </Box>
  );

  const afterHittingNextContent = () => (
    <>
      <div>{t("insert_details")}</div>
      <Box
        sx={{
          ...customSx.gridFilter,
          background: "#eee",
          margin: "8px 0",
          padding: "16px",
          fontSize: "14px !important",
          lineHeight: "normal",

          "div>div": {
            margin: "14px 0",
          },

          h6: {
            padding: "0px",
            fontSize: "14px !important",
            // lineHeight: "3rem",
            margin: "12px 0",
          },
        }}
        className="gridFilter"
      >
        {F.chain_name_confirm}
        {F.sending_address_confirm}
        {F.next_nonce}
        {F.last_chain_nonce}
      </Box>
      <Box
        sx={{
          ...customSx.gridFilter,
          gridTemplateColumns: "minmax(max-content, 5rem) 3fr",
          lineHeight: "3rem",
          fontSize: "14px !important",
          h6: {
            padding: "0px",
            fontSize: "14px !important",
            lineHeight: "3rem",
          },
          ".MuiInputLabel-shrink": {
            display: "none",
          },
        }}
      >
        {F.input_range}
        {F.nonce}
      </Box>
    </>
  );

  const dialogConfigOnInsert = {
    title: t("insert"),
    self: {
      open: zusDialog.match("operationDialog"),
      onClose: () => {
        setIsNexted(false);
        setFields(initFields);
        zusDialog.close();
      },
    },
    actionButtons: isNexted ? (
      <Box sx={sx.buttonGroup}>
        <TheButton.Confirm />
        <TheButton.Back />
      </Box>
    ) : (
      <Box sx={sx.buttonGroup}>
        <TheButton.Next />
      </Box>
    ),
    content: isNexted ? afterHittingNextContent() : beforeHittingNextContent(),
    onCancel: () => {
      setFields(initFields);
      zusDialog.close();
    },
    isConfirmHidden: true,
    isCancelHidden: isNexted,
    onConfirm: () => {}, // onConfirm button function refer to <TheButton.Confirm />
  } as const;

  return (
    <>
      <DialogInOne {...dialogConfigOnDelete} />
      <DialogInOne {...dialogConfigOnInsert} rest={{ maxWidth: "md" }} />
    </>
  );
}
