import { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SelectChangeEvent,
} from "@mui/material";

import { postLedgerTransferRequestCreate } from "../../../api/merchant";
import { AssetSingleSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GridBox from "../../../components/GridBox";
import MpTextField from "../../../components/MpTextField";
import MpTextFieldNumberOnly from "../../../components/MpTextFieldNumberOnly";
import { Container } from "../../../components/MuiGenerals";
import YubiPrompter from "../../../components/Prompter";
import { useAlerting, useTranslation, useYubiPrompt } from "../../../hooks";
import {
  bigNumStrMulitpleDecimals,
  findDecimalByChainNameAndAsset,
} from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./TransferRequest";

interface Ifields {
  sending_client_id: string;
  receiving_client_id: string;
  chain_name: string;
  asset_name: string;
  transaction_amount: string;
}

interface IfieldsSubmit {
  to_client_id: string;
  from_client_id: string;
  chain_id: string | undefined;
  chain_type: number | undefined;
  asset_name: string;
  amount: string;
  decimals: string;
  remarks?: string;
}

const translatePrefix = "ledgerTransferRequest";

export default function CreateRequestDialog(props: any) {
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  const { selectChainByPieces } = useChains();

  const initFields: Ifields = {
    sending_client_id: "",
    receiving_client_id: "",
    chain_name: "",
    asset_name: "",
    transaction_amount: "",
  };
  const [fields, setFields] = useState<Ifields>(initFields);

  useEffect(() => {
    setFields(initFields);
  }, [zusDialog.meta]);

  const getParams: () => IfieldsSubmit = () => {
    const {
      chain_name,
      asset_name,
      sending_client_id: from_client_id,
      receiving_client_id: to_client_id,
      transaction_amount,
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });

    const decimals = findDecimalByChainNameAndAsset(
      String(chain_type),
      String(chain_id),
      asset_name
    );

    const amount = bigNumStrMulitpleDecimals(transaction_amount, decimals);

    return {
      to_client_id,
      from_client_id,
      chain_id,
      chain_type,
      asset_name,
      amount,
      decimals,
    };
  };

  const apiParams = getParams();

  const handleConfirm = async () => {
    // check required fields
    const fieldsEntries = Object.entries(fields);
    const emptyFieldsEntries = fieldsEntries.filter(([_key, value]) => !value);
    const firstEmptyFieldEntries: [key: string, value: string] =
      emptyFieldsEntries[0];
    if (firstEmptyFieldEntries) {
      const field = firstEmptyFieldEntries[0];
      const fieldForDisplay = t(firstEmptyFieldEntries[0]);
      if (field === "asset_name" || field === "chain_name") {
        const verb = t("select");
        return alerting(
          "warning",
          t("create_warning", { verb, field: fieldForDisplay })
        );
      }
      const verb = t("enter");

      return alerting(
        "warning",
        t("create_warning", { verb, field: fieldForDisplay })
      );
    }

    // check sending_client_id and receiving_client_id is different
    if (fields.sending_client_id.trim() === fields.receiving_client_id.trim()) {
      return alerting("warning", t("receiving_and_sending_client_same"));
    }

    // check transaction amount > 0
    if (Number(fields.transaction_amount) <= 0) {
      const transactionAmountFieldName = t("transaction_amount");

      return alerting(
        "warning",
        t("must_be_larger_than_zero", { fieldName: transactionAmountFieldName })
      );
    }

    const res = await getResAfterYubi(
      postLedgerTransferRequestCreate,
      apiParams
    );

    if (!res) {
      return;
    }
    alerting("success", t("success_create"));
    zusDialog.close();
    setFields(initFields);
    zusParams.refetch();
  };

  if (!zusDialog.match("create_request_dialog")) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={zusDialog.match("create_request_dialog")}
        onClose={zusDialog.close}
        fullWidth={true}
        maxWidth={"md"}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle>{t("create_request")}</DialogTitle>
        <DialogContent>
          <InputFieldForm setFields={setFields} fields={fields} />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleConfirm()}
          >
            {tc("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              zusDialog.close();
              setFields(initFields);
            }}
          >
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function InputFieldForm(props: {
  setFields: Dispatch<SetStateAction<Ifields>>;
  fields: Ifields;
}) {
  const { fields, setFields } = props;
  const { t } = useTranslation(translatePrefix);
  const { selectAssetNamesByChain } = useAssets();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const genBoxData = [
    [
      "sending_client_id",
      <MpTextField
        value={fields.sending_client_id}
        onChange={onChange("sending_client_id")}
        label={t("ph_sending_client_id")}
      />,
    ],
    [
      "receiving_client_id",
      <MpTextField
        value={fields.receiving_client_id}
        onChange={onChange("receiving_client_id")}
        label={t("ph_receiving_client_id")}
      />,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetSingleSelection
        label={t("ph_currency")}
        setChoice={(asset_name) => setFields((f) => ({ ...f, asset_name }))}
        choice={fields.asset_name}
        allItems={allAssetNames}
      />,
    ],
    [
      "transaction_amount",
      <MpTextFieldNumberOnly
        value={fields.transaction_amount}
        onChange={onChange("transaction_amount")}
        label={t("ph_transaction_amount")}
      />,
    ],
  ] as [string, JSX.Element][];
  return (
    <Container disableGutters maxWidth={false}>
      <GridBox translatePrefix={translatePrefix} data={genBoxData} />
    </Container>
  );
}
