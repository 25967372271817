import { usePermission, useTranslation } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import BtcUtxoTab from "./BtcUtxoTab";
import AdaUtxoTab from "./AdaUtxoTab";
import { Box, Tab, Tabs } from "../../../components/MuiGenerals";
import React from "react";
import { useZusListStore } from "../../../zustand/store";

const Pkey = FeatureCodes.tools.UTXOTool;
const translatePrefix = "utxoManagement";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const UtxoManagement = () => {
  const { t } = useTranslation(translatePrefix);
  const [tabsIndex, setTabIndex] = React.useState(0); //the default index :which tab
  const { hasPermission } = usePermission();
  const clearZusList = useZusListStore((state) => state.clear);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    clearZusList();
  };
  const utxoTabs = [
    {
      name: "btc_utxo_tab",
      path: Pkey.TabBTCSeries.prefix,
      index: 0,
      jsxNode: <BtcUtxoTab />,
    },
    {
      name: "ada_utxo_tab",
      path: Pkey.TabADA.prefix,
      index: 1,
      jsxNode: <AdaUtxoTab />,
    },
  ].filter((item) => hasPermission(item.path));

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {utxoTabs.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {utxoTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default UtxoManagement;
