import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";

import { getLedgerFrozenFund } from "../../../api/merchant";
import { OpRedirect } from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box, Tooltip } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton from "../../../components/OpIconButton";
import { useTranslation } from "../../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";
import {
  dataGridDefaults,
  merchantOrderIdString,
} from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusParams } from "./FrozenFund";

const translatePrefix = "ledgerFrozenFund";
export default function FrozenFundList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: ["ledgerFrozenFund", zusParams.body, zusParams.refetchCounter],
    queryFn: () => getLedgerFrozenFund(zusParams.body),
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  useEffect(() => setCount(count), [listRes]);

  const RelatedRecords = (params: GridCellParams) => {
    const merchantOrderId = params?.row?.merchant_order_id;
    const toNewTab = () =>
      window.open(
        `/ledgerFreezeRequest?${merchantOrderIdString}${merchantOrderId}`
      );
    if (!merchantOrderId) return <></>;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: ".5rem",
          width: "90%",
        }}
      >
        <Tooltip title={merchantOrderId}>
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              maxWidth: "80%",
            }}
          >
            {merchantOrderId}
          </Box>
        </Tooltip>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={toNewTab}
          size="1rem"
        />
      </Box>
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 150,
      renderCell: RelatedRecords,
    }),
    useGenGridCol("client_id", t("client_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("currency", t("currency")),
    useGenGridCol("frozen_amount", t("frozen_amount")),
    useGenGridCol("status", t("status"), {
      minWidth: 120,
    }),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("frozen_date", t("frozen_date")),
    useGenGridCol("unfrozen_date", t("unfrozen_date")),
    useGenGridCol("last_modified_time", t("last_modified_time")),
  ];

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
