import { useQuery } from "react-query";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FeatureCode } from "@wallet-manager/node-types";
import EnumRequestStatus from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerRequestStatus";

import {
  getLedgerWalletManagerDepositRecordSwitchDetail,
  postLedgerWalletManagerDepositRecordApprove,
  postLedgerWalletManagerDepositRecordReject,
  postLedgerWalletManagerDepositRecordSwitch,
} from "../../../api/merchant";
import CopyableText from "../../../components/CopyableText";
import GridBox from "../../../components/GridBox";
import { Container } from "../../../components/MuiGenerals";
import YubiPrompter from "../../../components/Prompter";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../../hooks";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import { INTERVAL_PENDING_OPERATIONS_COUNT } from "../../../utils/config";
import { findChainInfo, toDisplayTime } from "../../../utils/helper";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore } from "../../../zustand/store";
import { ApprovalProgressContent } from "../ApprovalProgressDialog";
import { useZusParams } from "./WalletManagerDepositRecord";

const translatePrefix = "ledgerWalletManagerDepositRecord";
const Pkey = FeatureCode.FeaturesLedger.WalletManagerDepositRecord;

export default function OperationDialog() {
  const { alerting } = useAlerting();
  const { t, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const zusParams = useZusParams();
  const { email } = useAppSelector(selectProfile);
  const { hasPermission } = usePermission();
  const { getResAfterYubiWithRemarks, prompterConfig } = useYubiPrompt();
  const {
    rawData,
    direction,
    created_by,
    approversArr = [{ approved_by: "" }],
  } = zusDialog.meta || {};
  const { id: request_id, approvals: approval_completed } = rawData || {};

  const clickHandler = async (direction: "switch" | "approve" | "reject") => {
    if (direction === "approve") {
      if (
        !hasPermission(
          Pkey.Approve[
            `ApproverLevel${approval_completed + 1}` as
              | "ApproverLevel1"
              | "ApproverLevel2"
          ]
        )
      ) {
        return alerting("error", tc("alert_no_permission"));
      }
      if (
        approversArr.some((approver: any) => approver.approved_by === email)
      ) {
        return alerting("error", tc("alert_approvers_same"));
      }
      if (email === created_by) {
        return alerting("error", tc("alert_creator_approver_same"));
      }
    } else if (direction === "reject") {
      if (!hasPermission(Pkey.Reject)) {
        return alerting("error", tc("alert_no_permission"));
      }
    } else {
      if (!hasPermission(Pkey.SwitchToManualApprovalProcess)) {
        return alerting("error", tc("alert_no_permission"));
      }
    }
    const res = await getResAfterYubiWithRemarks(
      direction === "switch"
        ? postLedgerWalletManagerDepositRecordSwitch
        : direction === "approve"
        ? postLedgerWalletManagerDepositRecordApprove
        : postLedgerWalletManagerDepositRecordReject,
      {
        request_id: direction !== "switch" ? request_id : undefined,
        deposit_id: direction === "switch" ? request_id : undefined,
        approval_completed:
          direction === "approve" ? approval_completed : undefined,
      }
    );

    if (!res) {
      return;
    }
    alerting(
      "success",
      t(
        direction === "switch"
          ? "switch_success"
          : direction === "approve"
          ? "approve_success"
          : "reject_success"
      )
    );
    zusDialog.close();
    zusParams.refetch();
  };

  if (!zusDialog.match("operationDialog")) {
    return <></>;
  }

  return (
    <>
      <Dialog
        open={zusDialog.match("operationDialog")}
        onClose={zusDialog.close}
        // fullWidth={true}
        maxWidth={"lg"}
      >
        <YubiPrompter {...prompterConfig} />
        <DialogTitle></DialogTitle>
        <DialogContent sx={{ padding: "0px 32px !important" }}>
          <DetailContent />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          {direction === "approve" && (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => clickHandler("approve")}
              >
                {tc("approve")}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => clickHandler("reject")}
              >
                {tc("reject")}
              </Button>
            </>
          )}
          {direction === "switch" && (
            <Button
              color="secondary"
              variant="contained"
              onClick={() => clickHandler("switch")}
            >
              {t("switch")}
            </Button>
          )}
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              zusDialog.close();
            }}
          >
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function DetailContent() {
  const zusDialog = useZusDialogStore();
  const {
    transaction_status_display,
    request_status_display,
    risk_level_display,
    direction,
    rawData,
    transaction_amount,
    creation_time,
    last_modified_time,
  } = zusDialog.meta || {};
  const {
    id,
    chain_type,
    chain_id,
    client_id,
    asset_name: currency,
    block_number,
    block_time,
    block_hash,
    tx_status,
    tx_hash,
    ref_no,
    wallet_address,
    from_address,
    wallet_tag,
    status,
  } = rawData;
  const chainInfo = findChainInfo(chain_type, chain_id);
  const { switchDetail, isFetching } = useFetchLedgerApproverArr(id);
  const { name: chain_name } = chainInfo || {};
  const isAwaitRiskLevel = status === EnumRequestStatus.AwaitRiskLevel;
  const isInitial = status === EnumRequestStatus.initial;

  const { t, tc } = useTranslation(translatePrefix);

  const displayNAWhenEmpty = (value: any) => (value ? value : "N/A");

  const gridBoxContent: {
    general: Array<[string, JSX.Element]>;
    payeeAndPayer: Array<[string, JSX.Element]>;
    chainInfo: Array<[string, JSX.Element]>;
    switchingInfo: Array<[string, JSX.Element]>;
  } = {
    general: [
      ["creation_time", displayNAWhenEmpty(creation_time)],
      ["last_modified_time", displayNAWhenEmpty(last_modified_time)],
      ["chain_name", displayNAWhenEmpty(chain_name)],
      ["currency", displayNAWhenEmpty(currency)],
      ["client_id", displayNAWhenEmpty(client_id)],
      ["transaction_amount", displayNAWhenEmpty(transaction_amount)],
      ["transaction_status", displayNAWhenEmpty(transaction_status_display)],
      ["risk_level", displayNAWhenEmpty(risk_level_display)],
      ["request_status", displayNAWhenEmpty(request_status_display)],
      ["transaction_hash", <CopyableText text={displayNAWhenEmpty(tx_hash)} />],
      ["reference_no", <CopyableText text={displayNAWhenEmpty(ref_no)} />],
    ],
    payeeAndPayer: [
      [
        "wallet_address",
        <CopyableText text={displayNAWhenEmpty(wallet_address)} />,
      ],
      ["wallet_tag", displayNAWhenEmpty(wallet_tag)],
      [
        "sending_address",
        <CopyableText text={displayNAWhenEmpty(from_address)} />,
      ],
    ],
    chainInfo: [
      ["block_no", displayNAWhenEmpty(block_number)],
      ["block_hash", <CopyableText text={displayNAWhenEmpty(block_hash)} />],
      ["block_time", displayNAWhenEmpty(toDisplayTime(block_time))],
      ["on_chain_transaction_status", tx_status ? tc("true") : tc("false")],
    ],
    switchingInfo: [
      [
        "switched_by",
        !isFetching && displayNAWhenEmpty(switchDetail?.approved_by),
      ],
      [
        "switched_time",
        !isFetching &&
          displayNAWhenEmpty(toDisplayTime(switchDetail?.last_modified_date)),
      ],
      [
        "switching_remarks",
        !isFetching && displayNAWhenEmpty(switchDetail?.remarks),
      ],
    ],
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ marginBottom: "12px" }}>
      <h3>{t("subtitle_1st_box")}</h3>
      <GridBox
        translatePrefix={translatePrefix}
        data={gridBoxContent.general}
        containerSx={{
          backgroundColor: "#e1e1e1",
          padding: "10px 20px",
        }}
        itemSx={{ ">div": { flex: "none !important" } }}
      />

      <h3>{t("subtitle_2nd_box")}</h3>
      <GridBox
        translatePrefix={translatePrefix}
        data={gridBoxContent.payeeAndPayer}
        containerSx={{
          backgroundColor: "#e1e1e1",
          padding: "10px 20px",
        }}
        itemSx={{ ">div": { flex: "none !important" } }}
      />

      <h3>{t("subtitle_3rd_box")}</h3>
      <GridBox
        translatePrefix={translatePrefix}
        data={gridBoxContent.chainInfo}
        containerSx={{
          backgroundColor: "#e1e1e1",
          padding: "10px 20px",
        }}
        itemSx={{ ">div": { flex: "none !important" } }}
      />

      {!isInitial && !isAwaitRiskLevel && direction !== "switch" && (
        <>
          <h3>{t("subtitle_4th_box")}</h3>
          <GridBox
            translatePrefix={translatePrefix}
            data={gridBoxContent.switchingInfo}
            containerSx={{
              backgroundColor: "#e1e1e1",
              padding: "10px 20px",
            }}
            itemSx={{ ">div": { flex: "none !important" } }}
          />
        </>
      )}

      {direction === "approve" && (
        <>
          <h3>{t("subtitle_5th_box")}</h3>
          <ApprovalProgressContent />
        </>
      )}
    </Container>
  );
}

export function useFetchLedgerApproverArr(request_id: string | number) {
  const refetchInterval = Number(INTERVAL_PENDING_OPERATIONS_COUNT) * 1000;

  const api = getLedgerWalletManagerDepositRecordSwitchDetail;

  const res = useQuery({
    queryKey: "getLedgerSwitchArr",
    queryFn: () => api({ request_id }),
    refetchInterval,
  });

  const { data, isFetching } = res;
  const typedSwitchResData = data as unknown as Record<any, any>[];

  return {
    isFetching,
    switchDetail: (typedSwitchResData || [])[0],
  };
}
