import { Button, Box } from "../../components/MuiGenerals";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import {
  getCallbackSetting,
  postSettingStatus,
} from "../../api/callbacksetting";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAlerting, usePermission } from "../../hooks";
import { FeatureCodes } from "../../utils/constant";
const Pkey = FeatureCodes.report.MessageManagement;
const CallbackSettingList = ({ handleClose }: any) => {
  const translate = useTranslate();
  const { hasPermission } = usePermission();

  const t = (key: string) => translate(`callbackSetting.${key}`);
  const [settingContent, setSettingContent]: any = useState<any>([]);
  const [updateUi, setUpdateUi] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const { alerting } = useAlerting();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const asyncFuncWrapper = async () => {
      const result: any = await getCallbackSetting();
      if (!result) return handleClose();
      setLoading(false);
      setSettingContent(result);
    };
    asyncFuncWrapper();
  }, [updateUi]);
  const handleOnChangeSetting = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = (event.target as HTMLInputElement).value;
    const targetName = (event.target as HTMLInputElement).name;
    const newArray = settingContent.map((element: any) => {
      if (element.data_type === targetName) {
        element["send"] = newValue;
      }
      return { ...element };
    });
    setSettingContent(newArray);
  };
  /*Send Updated Setting To DB*/
  const handleConfirmSetting = async () => {
    const resp = await postSettingStatus(settingContent);
    if (resp) {
      alerting("success", t("confirm"));
    }
    setUpdateUi(!updateUi);

    setEditMode(true);
  };

  const handleEditMode = () => {
    setEditMode(false);
  };
  const handleCancelEdit = () => {
    setUpdateUi(!updateUi);
    setEditMode(true);
  };
  const hasPermissionSaveSetting =
    editMode && hasPermission(Pkey.CallbackSettingEdit);
  if (loading) return <></>;
  return (
    <>
      <Box
        style={{
          width: "390px",
          height: "640px",
        }}
      >
        <div style={{ width: "100%", textAlign: "end", height: "12px" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "relative",
              right: 8,
              top: 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/*  */}
        <div
          // id="the-top-bar-container"
          style={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-around",
            margin: "20px 0px 12px 0px",
          }}
        >
          <div
            // id="empty-container"
            style={{
              width: "120px",
              height: "20px",
              textAlign: "center",
              fontSize: "10px",
              fontFamily: "Roboto",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <div
              // id="dropdown-box"
              style={{
                // padding: "10px",
                height: "23px",
                width: "120px",
                textAlign: "center",
                alignItems: "center",
                fontSize: "15px",
              }}
            ></div>
          </div>
          {/*  */}
          <div
            // id="Top-bar-container"
            style={{
              display: "inline-flex",
              justifyContent: "space-around",
              width: "153px",
              fontSize: "12px",
              textAlign: "center",
              alignItems: "center",
              fontFamily: "Roboto",
              transform: "translate(-20px, 0px)",
            }}
          >
            <div
              // id="word-sweep"
              style={{ paddingLeft: "8px", letterSpacing: "1px" }}
            >
              {t("activate")}
            </div>
            <div
              // id="word-withdraw"
              style={{ marginRight: "2.5px", letterSpacing: "1px" }}
            >
              {t("disable")}
            </div>
          </div>
          {/*  */}
        </div>
        <div
          // id="line"
          style={{
            border: "1px solid #D6D6D6",
            width: "132px",
            transform: "translate(200px, 0px)",
            marginBottom: "10px",
          }}
        ></div>
        {/*Main-body  */}
        <FormControl sx={{ transform: "translate(65px, 0px)" }}>
          {settingContent.map((setting: any) => (
            <div
              key={setting.id}
              // id="the-main-body-container"
              style={{
                display: "inline-flex",
                width: "100%",
                // marginTop: "10px",
              }}
            >
              <div
                // id="word-tag"
                style={{
                  width: "140px",
                  textAlign: "center",
                  fontSize: "12px",
                  fontFamily: "Roboto",
                  display: "inline-flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  transform: "translate(-33px,0px)",
                }}
              >
                <div /* id="word-tag" */ style={{}}>
                  {t(`${setting.data_type}`)}
                </div>
              </div>
              <RadioGroup value={setting.send} onChange={handleOnChangeSetting}>
                <div
                  // id="main-pair-word-container"
                  style={{
                    display: "inline-flex",
                    justifyContent: "space-around",
                    width: "140px",
                    fontSize: "12px",
                    textAlign: "center",
                    height: "60px",
                    alignItems: "center",
                    fontFamily: "Roboto",
                    margin: 0,

                    transform: "translate(-8px, 0px)",
                  }}
                >
                  <div
                    // id="main-wording-body1"
                    style={{
                      width: "60px",
                      height: "60px",
                      backgroundColor: "#EAEAEA",
                      marginRight: "2px",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      disabled={editMode}
                      value="true"
                      name={setting.data_type}
                      sx={{
                        // transform: "translate(11px, 0px)",
                        padding: 0,
                        margin: 0,
                      }}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                              {
                                color: "#FFFFFF",
                              },
                            "& .MuiSvgIcon-root": {
                              fontSize: 15,
                              textAlign: "center",
                              backgroundColor: "#FFFFFF",
                              color: "FFFFFF",
                              border: "1px solid #FFFFFF",
                              borderRadius: "50%",
                              "&,&.Mui-checked": {
                                color: "black",
                              },
                            },
                          }}
                        />
                      }
                      label=""
                    />
                  </div>

                  <div
                    // id="main-wording-body2"
                    style={{
                      width: "60px",
                      height: "60px",

                      backgroundColor: "#EAEAEA",
                      marginLeft: "2px",
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FormControlLabel
                      disabled={editMode}
                      value="false"
                      name={setting.data_type}
                      sx={{
                        // transform: "translate(11px, 0px)",
                        padding: 0,
                        margin: 0,
                      }}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)":
                              {
                                color: "#FFFFFF",
                              },
                            "& .MuiSvgIcon-root": {
                              fontSize: 15,
                              textAlign: "center",
                              backgroundColor: "#FFFFFF",
                              color: "FFFFFF",
                              border: "1px solid #FFFFFF",
                              borderRadius: "50%",
                              "&,&.Mui-checked": {
                                color: "black",
                              },
                            },
                          }}
                        />
                      }
                      label=""
                    />
                  </div>
                </div>
              </RadioGroup>
            </div>
          ))}
        </FormControl>
        <div
          // id="buttonContainer"
          style={{ textAlign: "center", marginTop: "20px" }}
        >
          {hasPermissionSaveSetting && (
            <Button
              onClick={handleEditMode}
              variant="contained"
              color="primary"
              sx={{
                // transform: "translate(-18.5px, 0px)",
                // padding: "5px 15px",
                textAlign: "center",
                minWidth: "80px",
                minHeight: "31.25px",
              }}
            >
              {t("Edit")}
            </Button>
          )}
          {/* Show Below Confirm button when EditButton is Clicked  */}
          {editMode == false && (
            <>
              <Button
                onClick={handleConfirmSetting}
                variant="contained"
                color="primary"
                sx={{
                  transform: "translate(-18.5px, 0px)",
                  padding: "5px 15px",
                  minWidth: "80px",
                  minHeight: "31.25px",
                }}
              >
                {t("Confirm")}
              </Button>
              <Button
                onClick={handleCancelEdit}
                variant="outlined"
                color="primary"
                sx={{ transform: "translate(6px, 0px)", minWidth: "80px" }}
              >
                {t("cancel")}
              </Button>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default CallbackSettingList;
