import "./App.module.scss";

import { useEffect, useState } from "react";
import { Admin, CustomRoutes, localStorageStore, Resource } from "react-admin";
import { Route } from "react-router-dom";

import { ensureAccessToken } from "./api/axiosInstance";
import FetchConfig from "./components/FetchConfig";
import i18nProvider from "./components/i18nProvider";
import LayoutBar from "./components/LayoutBar";
import LoadingDialog from "./components/LoadingDialog";
import LoadingSpin from "./components/LoadingSpin";
import { useRoutePermission } from "./hooks";
import { useAppDispatch, useAppSelector } from "./reducer/hooks";
import { selectStuff, setIsRefreshingToken } from "./reducer/stuffSlice";
import { queryClient } from "./utils/config";
import { versionNumber } from "./utils/constant";
import { theme } from "./utils/theme";
import AccountSetting from "./views/AccountSetting";
import {
  AssetList,
  BatchSweep,
  BatchWithdraw,
  MerchantOperation,
  OperationRecords,
  OperationTransactionsRecords,
  SweepRequestRecord,
  WithdrawRequestRecord,
  WithdrawSetting
} from "./views/AssetManagement";
import WithdrawApprovalCheckingTimeSetting from "./views/AssetManagement/WithdrawApprovalCheckingTimeSetting/WithdrawApprovalCheckingTimeSetting";
import WithDrawApprovalSettingSwitchTabs from "./views/AssetManagement/WithDrawApprovalSettingSwitchTabs";
import Dashboard from "./views/Dashboard";
import {
  ClientBalance,
  ClientManagement,
  LedgerFreezeRequest,
  LedgerFrozenFund,
  LedgerTransactionRecord,
  TransferRequest,
  WalletManagerDepositRecord,
  WithdrawRequest as LedgerWithdrawRequest,
} from "./views/Ledger";
import {
  AllTransactionReport,
  ClientReport,
  DepositAndWithdrawalStatistics,
  MerchantReport,
  MessageManagement,
} from "./views/Report";
import {
  AddressGeneration,
  AuditLog,
  BalanceCheckpoint,
  BlockRescan,
  NonceTool,
  ReBroadcast,
  UserApprovalManagement,
  UtxoManagement,
  YubiKeysReg,
} from "./views/Tools";
import ReSubmit from "./views/Tools/ReSubmit";
import {
  TotalBalanceOverview,
  WalletBalance,
  WalletBalanceAdjustment,
  WalletManagement,
} from "./views/WalletManagement";

//###add-new-page
const rawList = {
  accountSetting: <AccountSetting />,
  batchSweep: <BatchSweep />,
  sweepRequestRecord: <SweepRequestRecord />,
  batchWithdraw: <BatchWithdraw />,
  withdrawRequestRecord: <WithdrawRequestRecord />,
  operationRecords: <OperationRecords />,
  operationTransactionRecords: <OperationTransactionsRecords />,
  merchantOperation: <MerchantOperation />,
  withdrawSetting: <WithdrawSetting/>,
  withdrawApprovalSetting: <WithDrawApprovalSettingSwitchTabs />,
  withdrawApprovalCheckingTimeSetting: <WithdrawApprovalCheckingTimeSetting />,
  nonceTool: <NonceTool />,
  assetList: <AssetList />,
  walletManagement: <WalletManagement />,
  walletBalance: <WalletBalance />,
  walletBalanceAdjustment: <WalletBalanceAdjustment />,
  merchantReport: <MerchantReport />,
  clientReport: <ClientReport />,
  depositAndWithdrawalStatistics: <DepositAndWithdrawalStatistics />,
  transactionReport: <AllTransactionReport />,
  messageManagement: <MessageManagement />,
  totalWalletBalance: <TotalBalanceOverview />,
  blockRescan: <BlockRescan />,
  reBroadcast: <ReBroadcast />,
  reSubmit: <ReSubmit />,
  yubiKeyRegister: <YubiKeysReg />,
  userApprovalManagement: <UserApprovalManagement />,
  balanceCheckpoint: <BalanceCheckpoint />,
  addressGeneration: <AddressGeneration />,
  utxoToolManagement: <UtxoManagement />,
  auditLog: <AuditLog />,
  clientBalance: <ClientBalance />,
  clientManagement: <ClientManagement />,
  ledgerTransactionRecord: <LedgerTransactionRecord />,
  transferRequest: <TransferRequest />,
  walletManagerDepositRecord: <WalletManagerDepositRecord />,
  withdrawRequest: <LedgerWithdrawRequest />,
  ledgerFreezeRequest: <LedgerFreezeRequest />,
  ledgerFrozenFund: <LedgerFrozenFund />,
};
const App = () => {
  useEffect(() => console.log("versionNumber", versionNumber), []);

  const { hasRoutePermission } = useRoutePermission();

  const { isRefreshingToken } = useAppSelector(selectStuff);
  const dispatch = useAppDispatch();

  // prevent isRefreshingToken dead lock
  useEffect(() => {
    const TIMEOUT_SECOND = 10 * 1000;

    let timeoutId: ReturnType<typeof setTimeout>;

    if (isRefreshingToken) {
      timeoutId = setTimeout(() => {
        dispatch(setIsRefreshingToken(false));
      }, TIMEOUT_SECOND);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefreshingToken]);

  const list: { [key: string]: any } =
    Object.entries(rawList).map(([key, comp]) => {
      const element = hasRoutePermission(key) ? comp : <></>;
      return [key, element];
    }) || {};

  const isTokenReady = useToken();

  if (!isTokenReady) return <></>;

  return (
    <>
      <LoadingSpin />
      <LoadingDialog forceOpen={isRefreshingToken} />
      <FetchConfig />
      <Admin
        theme={theme}
        store={localStorageStore("0.1")}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        queryClient={queryClient}
        layout={LayoutBar}
      >
        <Resource name="notExist" />
        <CustomRoutes>
          {list.map(([path, element]: [string, JSX.Element], i: number) => (
            <Route key={i} path={path} element={element} />
            // <Route path="/batchSweep" element={<BatchSweep />} />
            // OR
            // <Route path="/batchSweep" element={<></>} />
          ))}
        </CustomRoutes>
      </Admin>
    </>
  );
};
function useToken() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    async function fetch() {
      await ensureAccessToken();
      setIsReady(true);
    }
    fetch();
  }, []);
  return isReady;
}
export default App;
