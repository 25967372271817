export default function WalletManagerLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164.577"
      height="48.161"
      viewBox="0 0 164.577 48.161"
    >
      <g
        id="Group_10025"
        data-name="Group 10025"
        transform="translate(-32 -32.865)"
      >
        <text
          id="WALLET"
          transform="translate(95.577 47.293)"
          fill="#4a90f7"
          fontSize="16"
          fontFamily="Helvetica"
          letterSpacing="0.2em"
        >
          <tspan x="0" y="0">
            WALLET
          </tspan>
        </text>
        <text
          id="MANAGER"
          transform="translate(95.577 66.707)"
          fill="#4a90f7"
          fontSize="16"
          fontFamily="Helvetica"
          letterSpacing="0.2em"
        >
          <tspan x="0" y="0">
            MANAGER
          </tspan>
        </text>
        <g
          id="Group_10024"
          data-name="Group 10024"
          transform="translate(32 32.865)"
        >
          <g
            id="Group_10022"
            data-name="Group 10022"
            transform="translate(0 0)"
          >
            <rect
              id="Rectangle_2961"
              data-name="Rectangle 2961"
              width="18.002"
              height="32.901"
              rx="1.985"
              transform="translate(0 34.949) rotate(-66.324)"
              fill="#4a90f7"
              opacity="0.5"
            />
            <rect
              id="Rectangle_2962"
              data-name="Rectangle 2962"
              width="36.352"
              height="51.656"
              rx="2.949"
              transform="translate(52.282 0.334) rotate(90.37)"
              fill="#4a90f7"
            />
          </g>
          <g
            id="Group_10023"
            data-name="Group 10023"
            transform="translate(25.696 9.776)"
          >
            <path
              id="Path_3211"
              data-name="Path 3211"
              d="M325.356,557h-1.977a1,1,0,0,0-.71.3l-1.509,1.521a.985.985,0,0,1-1.421,0L318.23,557.3a1,1,0,0,0-.71-.3h-1.978c-.016,0-.029.007-.044.007s-.028-.007-.044-.007h-1.979a1,1,0,0,0-.71.3l-1.509,1.521a.983.983,0,0,1-1.42,0L308.33,557.3a1,1,0,0,0-.71-.3h-1.979a1.144,1.144,0,0,0-.71,1.955l4.907,4.95a.983.983,0,0,0,1.42,0l3.531-3.56a.983.983,0,0,1,1.42,0l3.53,3.56a.985.985,0,0,0,1.421,0l4.907-4.95A1.144,1.144,0,0,0,325.356,557Z"
              transform="translate(-304.597 -557)"
              fill="#fff"
            />
            <path
              id="Path_3212"
              data-name="Path 3212"
              d="M305.641,571.766h1.979a1,1,0,0,0,.71-.3l1.509-1.521a.983.983,0,0,1,1.42,0l1.509,1.521a1,1,0,0,0,.71.3h1.979c.016,0,.028-.007.044-.007s.028.007.044.007h1.978a1,1,0,0,0,.71-.3l1.509-1.521a.985.985,0,0,1,1.421,0l1.509,1.521a1,1,0,0,0,.71.3h1.977a1.144,1.144,0,0,0,.711-1.955l-4.907-4.95a.985.985,0,0,0-1.421,0l-3.53,3.56a.983.983,0,0,1-1.42,0l-3.531-3.56a.983.983,0,0,0-1.42,0l-4.907,4.95A1.144,1.144,0,0,0,305.641,571.766Z"
              transform="translate(-304.597 -554.547)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
