import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import { dataGridDefaults } from "../../utils/constant";
import { useGenGridCol } from "../../utils/HelperComp";
import { useTranslate } from "react-admin";
import { CustomPagination } from "../../components/CustomPagination";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { useZusListStore } from "../../zustand/store";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";

export default function BalanceCheckpointList(props: { listMapping: any }) {
  const zusList = useZusListStore();
  const translate = useTranslate();
  const t = (key: string, option?: Object) => translate(`tools.${key}`, option);
  const { listMapping } = props;
  const content: GridRowsProp = listMapping("key", zusList.rows, {
    translate,
  });
  const columns: GridColDef[] = [
    useGenGridCol("mark_time", t("check_time"), { minWidth: 140 }),
    useGenGridCol("id", t("checkpoint_id")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("asset_name", t("asset_name")),
    useGenGridCol("wallet_address", t("wallet_address")),
    useGenGridCol("balance", t("onchain_balance")),
    useGenGridCol("systemBalance", t("systemBalance")),
    useGenGridCol("differenceBalance", t("differenceBalance")),
    useGenGridCol("is_certified", t("is_certified_balance")),
    useGenGridCol("block_number", t("block_number")),
    useGenGridCol("block_time", t("block_time")),
    useGenGridCol("created_date", t("created_date")),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={zusList.count}
        page={zusList.meta.page}
        onPageChange={zusList.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: zusList.count },
        }}
      />
    </>
  );
}
