import { IYubiDetails } from "../zustand/types";
import axios from "./axiosInstance";

// let refreshTokenTime: Date | undefined;
// function setRefreshTokenTime(now: Date) {
//   refreshTokenTime = addMinutes(now, 37); // hard limit is 36 mins
// }
interface getAccessTokenRes {
  token: string;
  merchant_name: string;
  features: Object;
}

interface IloginResult {
  access_token: string;
  refresh_token: string;
  merchant_name: string;
  acceptance_level: number;
  chain_analysis: boolean;
  features: Record<string, boolean>;
  version: string;
}
export const getAccessToken = async (authToken: string, config = {}) => {
  const params = { access_token: authToken, clientCurrentTime: new Date() };
  const res = await axios.post<never, IloginResult>(
    "/auth/verifyAccessToken",
    params,
    config
  );
  return res;
};

export const getNewTokens = async (refresh_token: string, config = {}) => {
  return await axios.post<never, IloginResult>(
    "/auth/refresh_tokens",
    { refresh_token },
    { ...config, ignoreToken: true } as any
  );
};

export const getMyYubiKeyStaus = async () => {
  const res = await axios.get<never, IYubiDetails>(
    "/merchant_portal/portal_users/my_details"
  );
  return res;
};
