import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../../../components/MuiGenerals";
import { useZusDialogStore, zusRefetchStore } from "../../../zustand/store";
import { useAlerting, useTranslation } from "../../../hooks";
import { customSx } from "../../../utils/styling";
import { deleteBtcUtxo, deleteAdaUtxo } from "../../../api/merchant";

const translatePrefix = "utxoManagement";
const DeleteModal = (props: { type: "btc" | "ada" }) => {
  const zusDialog = useZusDialogStore();
  const { t, tc } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const refetch = zusRefetchStore((state) => state.refetchFunc);
  const handleClose = () => {
    zusDialog.close();
  };
  const { tx_hash, v_out, spent_tx_hash, field, ...rest } = zusDialog.meta;
  const handleConfirm = async () => {
    const whichPayload =
      field === "tx_hash"
        ? { tx_hash, v_out, ...rest }
        : { spent_tx_hash, ...rest };
    const api = props.type === "btc" ? deleteBtcUtxo : deleteAdaUtxo;
    const res = await api(whichPayload);
    if (res) {
      zusDialog.close();
      refetch({ where: "refetchBoolean" });
      return alerting("success", t("successDeleteAlert"));
    }
  };
  return (
    <>
      <Dialog
        open={zusDialog.match("utxoDeleteDialog")}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>{t("confirmDelete")}</DialogTitle>
        <DialogContent sx={{ marginLeft: "0.3rem" }}>
          {t(`${field}`)}:
          <div>
            {field === "tx_hash"
              ? zusDialog.meta.tx_hash
              : zusDialog.meta.spent_tx_hash}
          </div>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button color="secondary" variant="contained" onClick={handleConfirm}>
            {tc("confirm")}
          </Button>

          <Button variant="contained" color="error" onClick={handleClose}>
            {tc("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteModal;
