import { TableColumnKey } from "../types/table";
import { useTranslate } from "react-admin";

interface ResubmitPrompterContentProps {
  [TableColumnKey.BatchId]?: string;
  [TableColumnKey.OperationType]?: string;
  [TableColumnKey.ChainName]?: string;
  [TableColumnKey.AssetName]?: string;
  [TableColumnKey.TotalAmount]?: string;
}

const Row = ({ title, content }: { title: string; content?: string }) => {
  if (!content) {
    return <></>;
  }

  return (
    <div style={{ display: "flex", marginBottom: "8px" }}>
      <span style={{ flex: 2 }}>{title}</span>
      <span style={{ flex: 1 }}>{content || ""}</span>
    </div>
  );
};

const ResubmitPrompterContent = (props: ResubmitPrompterContentProps) => {
  const { batch_id, operation_type, chain_name, asset_name, total_amount } =
    props;
  const translate = useTranslate();

  return (
    <>
      <Row title={translate("resubmit.columns.batch_id")} content={batch_id} />
      <Row
        title={translate("resubmit.columns.operation_type")}
        content={operation_type}
      />
      <Row
        title={translate("resubmit.columns.chain_name")}
        content={chain_name}
      />

      <Row
        title={translate("resubmit.columns.asset_name")}
        content={asset_name}
      />
      <Row
        title={translate("resubmit.columns.total_amount")}
        content={total_amount}
      />
    </>
  );
};

export default ResubmitPrompterContent;
