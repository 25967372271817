import { InputAdornment, ListSubheader } from "@mui/material";
import React, { useState, KeyboardEvent } from "react";
import { sortItemsAlphabetically } from "../../utils/helper";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../MuiGenerals";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "../../hooks";
import MpTextField from "../MpTextField";
import { FeatureCodes } from "../../utils/constant";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting;
const translatePrefix = "withdrawApprovalSetting";
export function AssetSelectionSingleForApproveSetting(props: any) {
  const { t } = useTranslation(translatePrefix);
  const {
    label = "",
    searchLabel = t("typeToSearch"),
    choices,
    setChoices,
    nameField = "",
    allItems,
    addRecordProps,
  } = props;
  const [rawKeyword, setKeyword] = useState("");
  const keyword = rawKeyword.toLowerCase();
  const allMenuItems = sortItemsAlphabetically(allItems).map(
    (value: string, i: string) => {
      const isShow =
        keyword === "" ? true : value.toLowerCase().includes(keyword);
      const sx = isShow ? {} : { display: "none" };
      return (
        <MenuItem key={i} value={value} sx={sx}>
          {value}
        </MenuItem>
      );
    }
  );
  const onSelectChange = (e: SelectChangeEvent<string[]>) => {
    const value = e.target.value;
    // const newValue = typeof value === "string" ? value.split(",") : value;
    // setChoices(newValue);
    setChoices(value);
  };
  return (
    <FormControl fullWidth={addRecordProps}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={nameField}
        // multiple
        value={choices}
        onChange={onSelectChange}
        onClose={() => setKeyword("")}
        disabled={!Pkey.TabSetting.AddRecord}
      >
        <SearchField searchLabel={searchLabel} setKeyword={setKeyword} />
        {allMenuItems}
      </Select>
    </FormControl>
  );
}

function SearchField(props: any) {
  const { searchLabel, setKeyword } = props;
  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key !== "Escape") {
      // Prevents autoselecting item while typing (default Select behaviour)
      e.stopPropagation();
    }
  };
  return (
    <ListSubheader>
      <MpTextField
        size="small"
        autoFocus
        placeholder={searchLabel}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" children={<SearchIcon />} />
          ),
        }}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={onKeyDown}
      />
    </ListSubheader>
  );
}
