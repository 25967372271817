import axios from "./axiosInstance";
import { IlistResult, IportalUsersParams, Ipage } from "./types";
export async function portalUsers(
  params: IportalUsersParams & Ipage,
  config?: any
) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: { ...rest, limit: pageSize, offset: pageSize * page },
    ...config,
  };

  return await axios.get<
    never,
    IlistResult<{
      id: string;
      pub_id: string;
      pub_id_in_dec: string;
      oper_status: number;
      yubi_key_status: number;
      merchant_id: string;
      email: string;
      date_created: string;
      date_last_modified: string;
    }>
  >(`/merchant_portal/portal_users`, input);
}
export async function enablePortalUsers(
  params: { otp: string; email: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_users/enable_oper_status`,
    input
  );
}
export async function disablePortalUsers(
  params: { otp: string; email: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_users/disable_oper_status`,
    input
  );
}
export async function unbindPortalUsers(
  params: { otp: string; email: string },
  config?: any
) {
  const input = { ...params, ...config };
  return await axios.post<never, any>(
    `/merchant_portal/portal_users/unbind_yubi_key`,
    input
  );
}
