import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface ISettingState {
    withdrawable: boolean;
}

const initState: ISettingState = {
    withdrawable: true,
}

export const settingSlice = createSlice({
    name: "setting",
    initialState: initState,
    reducers: {
        setWithDrawable: (state, action: PayloadAction<any>) => {
            state.withdrawable = action.payload;
        },
    },
});

export const { setWithDrawable } = settingSlice.actions;

export const getWithdrawSetting = (state: RootState) => state.setting.withdrawable;

export default settingSlice.reducer;
