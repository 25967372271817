import { useEffect } from "react";
import {
  InputAdornment,
  Container,
  Box,
} from "../../../components/MuiGenerals";
import ReBroadcastList from "./ReBroadcastList";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import TableTab from "../../../components/TableTab";
import { useTranslate } from "react-admin";
import { useState, Dispatch, SetStateAction } from "react";
import { listFace, walletOperationBatchsCount } from "../../../api/merchant";
import { useChains } from "../../../components/FetchConfig";
import { customSx } from "../../../utils/styling";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  tableConfig,
  enumOperationType,
  enumOperationBatchStatus,
} from "../../../utils/constant";
import {
  displayAmount,
  findChainInfo,
  getDecimal,
  separateBatchStrings,
  toDisplayTime,
  listMappingTransform,
  findEnumKey,
} from "../../../utils/helper";

import GeneralBtn from "../../../components/GeneralBtn";
import MpTextField from "../../../components/MpTextField";
import { useZusDialogStore, zusRefetchStore } from "../../../zustand/store";

const { pageSize } = tableConfig;
interface fieldsFace {
  batchIdsString: string;
  chain_name: string;
}
const initFields = {
  batchIdsString: "",
  chain_name: "",
};
export default function ReBroadcastTab() {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar {...{ fields, setFields, setList, page, setPage }} />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <ReBroadcastList {...{ list, listMapping, setPage }} />
        </TableTab>
      </Container>
    </Box>
  );
}
export function FilterBar(props: {
  fields: fieldsFace;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
  setList: Dispatch<SetStateAction<listFace>>;
}) {
  const { fields, page, setPage, setFields, setList } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  const { selectChainByPieces } = useChains();
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  const chain_name = fields.chain_name;
  const refetchBool = zusRefetchStore((state) => state.rebroadcastTab);
  useEffect(() => {
    setAssets([]);
  }, [chain_name]);
  const { chain_type, chain_id } = selectChainByPieces({
    chain_name,
  });
  const batchIds = separateBatchStrings(fields.batchIdsString);
  const params = {
    chain_type,
    chain_id,
    batch_ids: batchIds,
    batch_statuses: [7], // 7 === Stucking
    page,
    pageSize,
  };
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await walletOperationBatchsCount({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    }
    fetch();
  }, [page, cacheParams, refetchBool]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };
  const onReset = () => setFields(initFields);
  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "batch_id",
      <MpTextField
        label={t("phBatch_id")}
        value={fields.batchIdsString}
        onChange={onChange("batchIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.batch_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`tools.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "operation_type",
        t("operation_type"),
        te(findEnumKey(enumOperationType, item.operation_type) || ""),
      ],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["total_amount", t("totalAmount"), toAmount(item.total_amount)],
      ["total_count", t("total_count"), item.total_count],
      ["no_of_tx", t("no_of_tx"), item.no_of_tx],
      [
        "batch_status",
        t("batch_status"),
        te(enumOperationBatchStatus[String(item.batch_status)]),
      ],
      ["created_by", t("created_by"), item.created_by],
      ["created_date", t("created_date"), toDisplayTime(item.created_date)],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
