import React from 'react';

import { Box, Typography } from '../../../components/MuiGenerals';
import { useAlerting, usePermission, useTranslation, useYubiPrompt } from '../../../hooks';
import { useAppSelector, useAppDispatch } from "../../../reducer/hooks";
import CustomTwoValueSwitch from '../../../components/CustomTwoValueSwitch';
import { customSx } from '../../../utils/styling';
import YubiPrompter from "../../../components/Prompter";
import { getWithdrawSetting, setWithDrawable } from '../../../reducer/settingSlice';
import { selectProfile } from '../../../reducer/profileSlice';
import { updateWithdrawSettingRequest } from '../../../api/merchant';
import { FeatureCode } from '@wallet-manager/node-types';

interface IWithdrawSettingProps {}

const WithdrawSetting: React.FC<IWithdrawSettingProps> = () => {
    const withdrawable = useAppSelector(getWithdrawSetting);
    const profile = useAppSelector(selectProfile);

    const PKey = FeatureCode.FeaturesAssetManagement.WithdrawSetting
    const { hasPermission } = usePermission();
    const { t, tc } = useTranslation("withdrawSetting");

    const { alerting } = useAlerting();
    const dispatch = useAppDispatch();
    const { getResAfterYubiWithCustomContent, prompterConfig } = useYubiPrompt();

    const onSubmit = async(status:string) => {
        if (!hasPermission(PKey.UpdateWithdrawStatus)){
            alerting("error", tc("alert_no_permission"));
            return;
        }

        const content = <Box sx={{
            backgroundColor: "#F1F2F4",
            display: "flex",
            flexDirection: "row",
            padding: "1rem",
        }}>
            <Typography>{`${t('switchWithdrawStatusReminder')}\t`} </Typography>
            <Typography sx={{
                color: "#5A99FA",
                marginLeft: "0.5rem"
            }}>
                {status}
            </Typography>
        </Box>

        const params = {
            withdrawable: status === t("enable")
        }
        const res = await getResAfterYubiWithCustomContent(updateWithdrawSettingRequest, content, {justifyContent: "center"}, t('switchWithdrawStatus'), params, {},  ()=>{});
        if (res !== null || res !== undefined) {
            alerting("success", t("updateSettingSuccess"));
            dispatch(setWithDrawable(status === t("enable")));
        }else{
            alerting("error", t("updateSettingFailed"));
        }
    }

    return (
    <Box sx={customSx.layoutBox}>
        <YubiPrompter {...prompterConfig}/>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
        }}>
            <Box sx={{
                display: "flex",
                flex: 1,
                padding: "1rem",
                flexDirection: "row",
            }}>
                <Box sx={{minWidth: "15rem"}}>{t('merchantName')}</Box>
                <Box>{profile.appName}</Box>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                padding: "1rem"

            }}>
                <Box sx={{
                    minWidth: "15rem"
                }}>{t('merchantWithdrawStatus')}</Box>
                <Box
                    sx={{
                        width: "20rem",
                    }}
                >
                    <CustomTwoValueSwitch 
                        firstValue={t("enable")}
                        secondValue={t("disable")}
                        selectedValue={withdrawable ? t("enable"): t("disable")}
                        onChange={async (_e, withdrawEnabled) => {
                            await onSubmit(withdrawEnabled);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    </Box>
    );
    };

    export default WithdrawSetting;
