import { useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";

import apis from "../../../api/portalUserProposals";
import { OpApprove, OpRedirect } from "../../../assets/icons";
import { CustomPagination } from "../../../components/CustomPagination";
import DialogInOne from "../../../components/DialogInOne";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box, DialogContent } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton, { sxOpTextButton } from "../../../components/OpIconButton";
import OpTextButton from "../../../components/OpTextButton";
import YubiPrompter from "../../../components/Prompter";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import useYubiPrompt from "../../../hooks/useYubiPrompt";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import {
  dataGridDefaults,
  enumPortalUserProposalStatus,
  FeatureCodes,
} from "../../../utils/constant";
import { toDisplayTime } from "../../../utils/helper";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import { useZusParams } from "./RequestRecord";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "userApprovalManagement";
const Pkey = FeatureCodes.tools.UserApprovalManagement.TabRecord;
export default function RequestRecordList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
}) {
  const { hasPermission } = usePermission();
  const { t, te } = useTranslation(translatePrefix);
  const { email } = useAppSelector(selectProfile);
  const { alerting } = useAlerting();
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const { listMapping } = props;
  const listRes = useQuery({
    queryKey: ["portalUserProposals", zusParams.body, zusParams.refetchCounter],
    queryFn: () => apis.portalUserProposals(zusParams.body),
  });
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const { rows = [], count = 0 } = listRes.data || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });
  const approvalButton = (params: GridRenderCellParams) => {
    const rawData = rows.find((item) => item.id === String(params.row?.id));
    if (!rawData) {
      console.error("id not found", params.row);
      return false;
    }
    const status = String(rawData.status);
    const isDecided =
      status === enumPortalUserProposalStatus.Approved ||
      status === enumPortalUserProposalStatus.Rejected;
    if (!rawData) return null;
    return (
      <Box sx={sxOpTextButton}>
        <OpIconButton
          title={t("approveOrReject")}
          url={OpApprove}
          isDisabled={params.row.metaData?.isFull || isDecided}
          onClick={() =>
            zusDialog.open("approvalDialog", { rawData, ripeData: params.row })
          }
        />
      </Box>
    );
  };
  async function onApproveOrReject(isApprove: boolean) {
    const { rawData = {} } = zusDialog.meta;
    // const otp = await getResAfterYubi();
    const isOperatedBefore = email === rawData.appr_by1;
    const isCreator = email === rawData.created_by;
    const noApprovalPermission =
      isApprove &&
      !(
        hasPermission(Pkey.FirstApprover) || hasPermission(Pkey.SecondApprover)
      );
    const noPermission =
      noApprovalPermission || (!isApprove && !hasPermission(Pkey.Reject));

    if (noPermission) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    if (isApprove && isOperatedBefore) {
      return alerting("error", t("alert_approvers_same"));
    }
    if (isApprove && isCreator) {
      return alerting("error", t("alert_creator_approver_same"));
    }

    const apiParams = { id: rawData.id };
    if (isApprove) {
      onApprove(apiParams, rawData);
    } else {
      onReject(apiParams);
    }
  }
  async function onApprove(apiParams: { id: string }, data: any) {
    const indexApprover = data?.appr_by1?.length > 0 ? 2 : 1;
    const theApi =
      indexApprover === 1
        ? apis.approve1PortalUserProposals
        : apis.approve2PortalUserProposals;
    const apiRes = await getResAfterYubi(theApi, apiParams);
    if (!apiRes) {
      return;
    }
    alerting("success", t(`approve_success`));
    listRes.refetch();
    zusDialog.closeAll();
  }
  async function onReject(apiParams: { id: string }) {
    const apiRes = await getResAfterYubi(
      apis.rejectPortalUserProposals,
      apiParams
    );
    if (!apiRes) {
      return;
    }
    alerting("success", t(`reject_success`));
    listRes.refetch();
    zusDialog.closeAll();
  }
  const HistoryButton = (params: GridRenderCellParams) => {
    const rawData = rows.find((item) => item.id === String(params.row?.id));
    if (!rawData) return null;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "57%",
        }}
      >
        <span>{params.row?.approval_progress}</span>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={() =>
            zusDialog.open("recordDialog", { rawData, ripeData: params.row })
          }
          size="1rem"
        />
      </Box>
    );
  };
  const columns: GridColDef[] = [
    useGenGridCol("id", t("proposal_no")),
    useGenGridCol("proposal_type", t("proposal_type"), { minWidth: 180 }),
    useGenGridCol("approval_progress", t("approval_progress"), {
      renderCell: HistoryButton,
    }),
    useGenGridCol("email", t("login_email"), { minWidth: 150 }),
    useGenGridCol("yubikey_id", t("requested_yubi_key_id")),
    useGenGridCol("proposal_status", t("proposal_status")),
    useGenGridCol("created_by", t("created_by"), { minWidth: 150 }),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("1", t("operation"), { renderCell: approvalButton }),
  ].filter((col) => {
    if (
      col.field === "1" &&
      !(
        hasPermission(Pkey.FirstApprover) ||
        hasPermission(Pkey.SecondApprover) ||
        hasPermission(Pkey.Reject)
      )
    ) {
      return false;
    }
    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("request")
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
      <YubiPrompter {...prompterConfig} />
      <LocalDialogs {...{ onApproveOrReject }} />
    </>
  );
}
function LocalDialogs(props: any) {
  const { onApproveOrReject } = props;
  const { t, te } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();
  const canApprove =
    hasPermission(Pkey.FirstApprover) || hasPermission(Pkey.SecondApprover);
  const TheButton = {
    Approve: () => (
      <OpTextButton
        text={t("approve")}
        // isHidden={!canApprove}
        onClick={() => onApproveOrReject(true)}
        sx={{ borderRadius: ".3rem" }}
      />
    ),
    Reject: () => (
      <OpTextButton
        text={t("reject")}
        // isHidden={!hasPermission(Pkey.Reject)}
        onClick={() => onApproveOrReject(false)}
        sx={{ borderRadius: ".3rem" }}
      />
    ),
  };
  const { rawData = {}, ripeData = {} } = zusDialog.meta || {};
  let historyArray = [];
  if (rawData?.appr_by1)
    historyArray.push({
      by: ripeData?.metaData?.isAuto ? te("auto") : rawData?.appr_by1,
      result: t("approved"),
      time: rawData?.date_appr1,
    });
  if (rawData?.appr_by2)
    historyArray.push({
      by: ripeData?.metaData?.isAuto ? te("auto") : rawData?.appr_by2,
      result: t("approved"),
      time: rawData?.date_appr2,
    });
  if (String(rawData.status) === enumPortalUserProposalStatus.Rejected)
    historyArray.push({
      by: rawData?.last_modified_by,
      result: t("rejected"),
      time: rawData?.date_last_modified,
    });
  const sx = {
    buttonGroup: { display: "flex", gap: "1rem", marginRight: "1rem" },
  };
  const dialogConfig = {
    history: {
      title: t("approval_progress"),
      self: {
        open: zusDialog.match("recordDialog"),
        onClose: () => zusDialog.close(),
      },
      content: <DialogHistoryTable data={historyArray} />,
      onCancel: zusDialog.close,
      isConfirmHidden: true,
      onConfirm: () => {},
    },
    approve: {
      title: t("approval"),
      self: {
        open: zusDialog.match("approvalDialog"),
        onClose: () => zusDialog.close(),
      },
      content: (
        <DialogContentTable
          data={[
            { title: t("proposal_no"), value: ripeData?.id },
            { title: t("proposal_type"), value: ripeData?.proposal_type },
            { title: t("login_email"), value: ripeData?.email },
            {
              title: t("requested_yubi_key_id"),
              value: ripeData?.yubikey_id,
            },
          ]}
        />
      ),
      onCancel: zusDialog.close,
      isConfirmHidden: true,
      onConfirm: () => {},
      actionButtons: (
        <Box sx={sx.buttonGroup}>
          <TheButton.Approve />
          <TheButton.Reject />
        </Box>
      ),
    },
  } as const;
  return (
    <>
      <DialogInOne {...dialogConfig.history} rest={{ maxWidth: "md" }} />
      <DialogInOne {...dialogConfig.approve} />
    </>
  );
}
const sx = {
  DialogContent: {
    padding: "2rem",
    paddingLeft: 0,
    "& .MuiBox-root": { display: "flex", marginBottom: "0.2rem" },
    "& .MuiBox-root span": { flex: 1 },
  },
};
function DialogContentTable(p: { data: { title: string; value: string }[] }) {
  const { data = [] } = p;
  return (
    <DialogContent sx={sx.DialogContent}>
      {data.map(({ title, value }) => (
        <Box key={title}>
          <span>{title}</span>
          <span>{value}</span>
        </Box>
      ))}
    </DialogContent>
  );
}
function DialogHistoryTable(p: {
  data: { by: string; result: string; time: string }[];
}) {
  const { t } = useTranslation(translatePrefix);
  const { data = [] } = p;
  return (
    <DialogContent sx={sx.DialogContent}>
      <Box>
        <span>{t("approval_sequence")}</span>
        <span>{t("operated_by")}</span>
        <span>{t("operation_result")}</span>
        <span>{t("operation_time")}</span>
      </Box>
      {data.map(({ by, result, time }, seq) => (
        <Box key={seq}>
          <span>{seq + 1}</span>
          <span>{by}</span>
          <span>{result}</span>
          <span>{toDisplayTime(time)}</span>
        </Box>
      ))}
    </DialogContent>
  );
}
