import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { MouseEvent } from "react";
import OpTextButton from "./OpTextButton";

export default function CustomTwoValueSwitch(props: {
  firstValue: string;
  secondValue: string;
  selectedValue: string;
  onChange: (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    value: any
  ) => void;
}) {
  const { firstValue, secondValue, selectedValue, onChange } = props;
  return (
    <ToggleButtonGroup
      color={"primary"}
      value={selectedValue}
      exclusive
      onChange={onChange}
      sx={{
        width: "98.7%",
        height: "60%",
        backgroundColor: "#c1c1c1 !important",
        border: "1px solid grey !important",
      }}
    >
      <ToggleButton
        sx={{
          width: "50%",
          backgroundColor:
            firstValue === selectedValue
              ? "#fff !important"
              : "#c1c1c1 !important",
          color:
            firstValue === selectedValue
              ? "#000 !important"
              : "#818181 !important",
          border: "0 !important",
          borderTopRightRadius:
            firstValue === selectedValue ? "6px !important" : undefined,
          borderBottomRightRadius:
            firstValue === selectedValue ? "6px !important" : undefined,
          borderRight:
            secondValue === selectedValue ? "0 !important" : undefined,
            textTransform: "none",
        }}
        value={firstValue}
        onClick={(e, value) => {
          if (value === selectedValue) {
            e.preventDefault();
          }
        }}
      >
        <div>{firstValue}</div>
      </ToggleButton>
      <ToggleButton
        sx={{
          width: "50%",
          marginLeft: "0 !important",
          backgroundColor:
            secondValue === selectedValue
              ? "#fff !important"
              : "#c1c1c1 !important",
          color:
            secondValue === selectedValue
              ? "#000 !important"
              : "#818181 !important",
          border: "0 !important",

          borderTopLeftRadius:
            secondValue === selectedValue ? "6px !important" : undefined,
          borderBottomLeftRadius:
            secondValue === selectedValue ? "6px !important" : undefined,
          borderLeft: firstValue === selectedValue ? "0 !important" : undefined,
          textTransform: "none",
        }}
        value={secondValue}
        onClick={(e, value) => {
          if (value === selectedValue) {
            e.preventDefault();
          }
        }}
      >
        <div>{secondValue}</div>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
