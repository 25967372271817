import {
  Container,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useState, useEffect } from "react";
import {
  toDisplayTime,
  findChainInfo,
  sortItemsAlphabetically,
  displayAmount,
  downloadFiles,
  getFullApiResponse,
  listMappingTransform,
} from "../../utils/helper";
import { useAssets, useChains } from "../../components/FetchConfig";
import { enumWalletType, tableConfig } from "../../utils/constant";
import { customSx } from "../../utils/styling";
import { useTranslate } from "react-admin";
import { listFace } from "../../api/merchant";
import { DivideLine, genField } from "../../utils/HelperComp";

import { usePermission, useTabs } from "../../hooks";
import TotalBalanceOverviewList from "../../components/TotalBalanceOverView/TotalBalanceOverviewList";
import { getWalletBalance } from "../../api/walletbalance";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import GeneralBtn from "../../components/GeneralBtn";
import { useZusDialogStore } from "../../zustand/store";
interface IInitParams {
  walletTypesArray: Array<string>;
  chain_name: string;
  asset_name: Array<string>;
}

const walletTypeArr = ["1", "2", "3" /* , "4" */];
const initSearchParams = {
  walletTypesArray: [],
  chain_name: "",
  asset_name: [],
};

export default function TotalBalanceOverview() {
  const translate = useTranslate();
  const t = (key: string) => translate(`totalWalletBalance.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);

  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          list={list}
          setList={setList}
          setPage={setPage}
          page={page}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <TotalBalanceOverviewList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`totalWalletBalance.${key}`);
  const { list, setList, setPage, page } = props;
  const { selectChainByPieces } = useChains();
  const { hasPermission } = usePermission();
  const { selectAssetNamesByChain } = useAssets();
  const [fields, setFields] = useState<IInitParams>(initSearchParams);
  const setAssets = (asset_name: string[]) =>
    setFields((fields) => ({ ...fields, asset_name }));
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const getParams = () => {
    const { walletTypesArray, chain_name, asset_name, ...rest } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const chainTypeArr = [];
    chainTypeArr.push(String(chain_type));

    return {
      ...rest,
      asset_name,
      wallet_types: !walletTypesArray.length ? walletTypeArr : walletTypesArray,
      chain_types: chainTypeArr[0] === "undefined" ? [] : chainTypeArr,
      chain_id,
      page: page,
      pageSize: tableConfig.pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    const asyncWrapper = async () => {
      zusDialog.openExtra("loadingDialog");
      const res: listFace = await getWalletBalance({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    asyncWrapper();
  }, [page]);

  useEffect(() => {
    setFields((params: any) => ({ ...params, asset_names: [] }));
  }, [fields.chain_name]);

  const onReset = () => {
    const { ...rest } = initSearchParams;
    const newParams = { ...rest };
    setFields(newParams);
  };

  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    setPage(0);
    setCacheParams(params);
    const getWalletBalanceByTypes: listFace = await getWalletBalance(params);
    zusDialog.closeExtra();
    if (!getWalletBalanceByTypes) return;
    setList(getWalletBalanceByTypes);
  };

  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields: any) => ({
      ...fields,
      [name]: newValue,
    }));
  };

  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) =>
          setFields((f: any) => ({
            ...f,
            chain_name,
          }))
        }
        choice={fields.chain_name}
      />,
    ],
    [
      "wallet_type",
      <FormControl>
        <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
        <Select
          multiple
          name="walletTypesArray"
          value={fields.walletTypesArray}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_name}
        allItems={assetsNames}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar} />
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.wallet_type}
        {F.asset_name}
      </Box>
      <Box sx={customSx.filterB}>
        <Button color="secondary" variant="contained" onClick={onSearch}>
          {t("search")}
        </Button>
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}
const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { translate } = config;
  const t = (key: string) => translate(`totalWalletBalance.${key}`);

  const res = array.map((item: any) => {
    const walletType =
      item.wallet_type != null
        ? translate(
            `enumConstants.${enumWalletType[item?.wallet_type.toString()]}`
          )
        : "";
    const amount = item.decimals
      ? displayAmount(item?.balance, item.decimals)
      : "";
    const chain_name = findChainInfo(item.chain_type, item.chain_id)?.name;
    const mappedResult = [
      // ["id", t("id"), item.id],
      ["wallet_type", t("wallet_type"), walletType],
      ["chain_name", t("chain_name"), chain_name],
      ["asset_name", t("asset_name"), item.asset_name],
      ["balance", t("balance"), amount],
      // ["created_date", t("created_date"), toDisplayTime(item.created_date)],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
    ];

    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
