import { Box, Tooltip } from "@mui/material";
import { OpApprove, OpView } from "../../assets/icons";
import { usePermission, useTranslation } from "../../hooks";
import { enumAdjustmentStatus, FeatureCodes } from "../../utils/constant";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabRecord;
const translatePrefix = "withdrawApprovalSetting";
const MoreOperations = (props: any) => {
  const { params } = props;
  const { translate } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const zusDialog = useZusDialogStore();
  const pendingStatus = translate(
    `enumWithDrawApprovalSetting.${enumAdjustmentStatus[0].toLowerCase()}`
  );
  const recordWatch = params.row;
  const isPendingStatus = recordWatch.status === pendingStatus;
  const onApproveOrReject = () => {
    const withdrawRegulation =
      recordWatch.withdraw_approval_regulation_requests;
    withdrawRegulation.sort((a: any, b: any) => a.seq - b.seq);

    useStore.setRecordWatch(recordWatch);
    zusDialog.open("approvalDialog");
  };

  return (
    <>
      <IconButton
        type={
          isPendingStatus &&
          (hasPermission(Pkey.ApproveRecord) ||
            hasPermission(Pkey.RejectRecord))
            ? "approveOrReject"
            : "view"
        }
        onClick={onApproveOrReject}
      />
    </>
  );
};
export default MoreOperations;

const IconButton = (p: {
  type: "approveOrReject" | "view";
  disabled?: boolean;
  onClick: () => void;
}) => {
  const { t } = useTranslation(translatePrefix);
  const sx = {
    box: {
      width: "2rem",
      cursor: p.disabled ? "" : "pointer",
      margin: "0 0.5rem",
      "&:hover": {
        filter: p.disabled ? "brightness(100%)" : "brightness(130%)",
      },
    },
    img: {
      filter: p.disabled ? "grayscale(100%)" : "grayscale(0)",
      width: "100%",
    },
  };
  const icon = {
    approveOrReject: { name: t("approveOrReject"), url: OpApprove },
    view: { name: t("view"), url: OpView },
  }[p.type];

  return (
    <Tooltip title={icon.name}>
      <Box sx={sx.box}>
        <img
          src={icon.url}
          onClick={() => !p.disabled && p.onClick()}
          style={sx.img}
        />
      </Box>
    </Tooltip>
  );
};
