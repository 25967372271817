import { useEffect, useState } from "react";

import EnumCreatorType from "@wallet-manager/node-types/dist/src/postgres/const/CreatorType";
import EnumRequestStatus from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerRequestStatus";

import { getLedgerTransferRequestExport } from "../../../api/merchant";
import { Ipage } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  NodeTypesEnumMultiSelection,
  NodeTypesEnumSingleSelection,
} from "../../../components/GeneralSelection";
import MpTextFieldMultiple from "../../../components/MpTextFieldMultiple";
import {
  Box,
  Button,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  findDecimalByChainNameAndAsset,
  getFullApiResponse,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance, useZusDialogStore } from "../../../zustand/store";
import { exportApprovalDetailConverter } from "../commonFunction";
import TransferRequestList from "./TransferRequestList";

const translatePrefix = "ledgerTransferRequest";

enum EnumCreatorTypeForDisplay {
  Portal = EnumCreatorType.Portal,
  Api = EnumCreatorType.Api,
}
interface Ifields {
  chain_name: string;
  asset_names: string[];
  sending_client_id: string;
  receiving_client_id: string;
  merchant_order_id: string;
  request_statuses: string[];
  creator_type: string;
}
interface ZusParamsFace {
  chain_id: string | undefined;
  chain_type: number | undefined;
  asset_names: string[];
  from_client_ids: string[];
  to_client_ids: string[];
  merchant_order_ids: string[];
  statuses: string[];
  creator_type: string;
  date_created_from: string;
  date_created_to: string;
  date_last_modified_from: string;
  date_last_modified_to: string;
  pageSize: number;
  page: number;
}

const initFields = {
  chain_name: "",
  asset_names: [] as string[],
  sending_client_id: "",
  receiving_client_id: "",
  merchant_order_id: "",
  request_statuses: [] as string[],
  creator_type: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
} as const;
export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);
const Pkey = FeatureCodes.ledger.TransferRequest;

export const reversedEnumFunction: (
  enumObj: Record<string, string>
) => Record<string, string> = (enumObj) =>
  Object.fromEntries(
    Object.entries(enumObj).map(([key, value]) => [value, key])
  );

export default function TransferRequest() {
  const zusParams = useZusParams();
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();

  const [count, setCount] = useState(0);
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getLedgerTransferRequestExport(
        { ...zusParams.body, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = ["rawData"];
    const totalApprovalLevel = 1;
    const res = listMapping("name", rawRes, { t, te, omitKeys }).map(
      (item, rawResIndex) => ({
        ...item,
        ...exportApprovalDetailConverter(
          "transfer",
          totalApprovalLevel,
          rawRes,
          rawResIndex,
          t,
          te
        ),
      })
    );
    const config = {};
    downloadFiles(`Transfer Request`, res, config);
  };

  useEffect(() => {
    return () => {
      zusDialog.close();
      zusDialog.closeAll();
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Container disableGutters maxWidth={false}>
        <Box sx={customSx.filterCmdBar}>
          {hasPermission(Pkey.CreateTransferRequest) && (
            <Button
              variant="outlined"
              onClick={() => zusDialog.open("create_request_dialog")}
            >
              {t("create_request")}
            </Button>
          )}
        </Box>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Box sx={customSx.tableExportBar}>
          {hasPermission(Pkey.Export) && (
            <GeneralBtn label="export" onClick={onExport} />
          )}
        </Box>
        <TableTab>
          <TransferRequestList {...{ listMapping, setCount }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedDate: useDatePicker(),
  };

  const getParams: () => ZusParamsFace = () => {
    const {
      chain_name,
      asset_names,
      request_statuses: statuses,
      creator_type,
      ...rest
    } = fields;
    const transformFieldFromStrToArr = (fieldValue: string) => {
      const rawArr = fieldValue.split(",");

      const fieldValueArr = rawArr
        .filter((item) => item)
        .map((item) => item.trim());
      return fieldValueArr;
    };
    const {
      sending_client_id: from_client_ids,
      receiving_client_id: to_client_ids,
      merchant_order_id: merchant_order_ids,
    } = Object.fromEntries(
      Object.entries(rest).map(([key, value]) => [
        key,
        transformFieldFromStrToArr(value),
      ])
    );

    const { chain_id, chain_type } = selectChainByPieces({ chain_name });

    const { CreationTime, LastModifiedDate } = DateObj;
    const { start: date_created_from, end: date_created_to } = CreationTime;
    const { start: date_last_modified_from, end: date_last_modified_to } =
      LastModifiedDate;

    return {
      ...initZusParams,
      chain_id,
      chain_type,
      asset_names,
      from_client_ids,
      to_client_ids,
      merchant_order_ids,
      statuses,
      creator_type,
      date_created_from,
      date_created_to,
      date_last_modified_from,
      date_last_modified_to,
    };
  };

  const apiParams = getParams();

  const onChange =
    (type: keyof Ifields) =>
    (
      e:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        | SelectChangeEvent<string>
    ) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSearch = () => {
    zusParams.setBody({ ...apiParams, page: 0 });
    zusParams.refetch();
  };

  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedDate.clearDate();
  };

  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  useEffect(
    () => setFields((f) => ({ ...f, asset_names: [] })),
    [fields.chain_name]
  );

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    ["last_modified_time", <DateObj.LastModifiedDate.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "currency",

      <AssetMultiSelection
        label={t("ph_currency")}
        setChoices={(asset_names) => setFields((f) => ({ ...f, asset_names }))}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "sending_client_id",
      <MpTextFieldMultiple
        value={fields.sending_client_id}
        onChange={onChange("sending_client_id")}
        label={t("ph_sending_client_id")}
        count={apiParams.from_client_ids.length}
      />,
    ],
    [
      "receiving_client_id",
      <MpTextFieldMultiple
        value={fields.receiving_client_id}
        onChange={onChange("receiving_client_id")}
        label={t("ph_receiving_client_id")}
        count={apiParams.to_client_ids.length}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextFieldMultiple
        value={fields.merchant_order_id}
        onChange={onChange("merchant_order_id")}
        label={t("ph_merchant_order_id")}
        count={apiParams.merchant_order_ids.length}
      />,
    ],

    [
      "request_status",
      <NodeTypesEnumMultiSelection
        label={t("ph_request_status")}
        onChange={(request_statuses) =>
          setFields((f) => ({ ...f, request_statuses }))
        }
        value={fields.request_statuses}
        enumData={EnumRequestStatus}
        isNoSorting
      />,
    ],
    [
      "creator_type",

      <NodeTypesEnumSingleSelection
        value={fields.creator_type}
        label={t("ph_creator_type")}
        enumData={EnumCreatorTypeForDisplay}
        isNoSorting
        clearSelect={() => setFields((f) => ({ ...f, creator_type: "" }))}
        onChange={onChange("creator_type")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.last_modified_time}
        {F.chain_name}
        {F.currency}
        {F.sending_client_id}
        {F.receiving_client_id}
        {F.merchant_order_id}
        {F.request_status}
        {F.creator_type}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[] => {
  const { omitKeys = [], t, te } = config || {};

  const res = array.map((item: any) => {
    const { name: chain_name } =
      findChainInfo(item.chain_type, item.chain_id) || {};
    const decimals = findDecimalByChainNameAndAsset(
      String(item.chain_type),
      String(item.chain_id),
      String(item.asset_name)
    );
    const mappedResult = [
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      [
        "approval_progress",
        t("approval_progress"),
        item.approvals_required === null
          ? null
          : !item.approvals_required
          ? t("auto_approve")
          : `${item.approvals}/${item.approvals_required}`,
      ],
      ["creator_type", t("creator_type"), te(EnumCreatorType[item.creator_type])],
      ["sending_client_id", t("sending_client_id"), item.from_client_id],
      ["receiving_client_id", t("receiving_client_id"), item.to_client_id],
      ["chain_name", t("chain_name"), chain_name],
      ["currency", t("currency"), item.asset_name],
      [
        "transaction_amount",
        t("transaction_amount"),
        displayAmount(item.amount, decimals),
      ],
      ["currency_of_fee", t("currency_of_fee"), item.fee_asset_name],
      [
        "amount_of_fee",
        t("amount_of_fee"),
        displayAmount(item.fee_amount, decimals),
      ],
      [
        "request_status",
        t("request_status"),
        te(EnumRequestStatus[item.status]),
      ],
      ["created_by", t("created_by"), item.created_by],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
      ["remarks", t("remarks"), item.remarks],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });

  const output = res.map(listMappingTransform(which));
  return output;
};
