import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import useTabs from "../../hooks/useTabs";
import {
  findFunctionNameAndOpType,
  getEndpointsByFuncWithOpType,
  listMappingTransform,
  toDisplayTime,
} from "../../utils/helper";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useZusDialogStore, useZusListStore } from "../../zustand/store";
import { useDatePicker } from "../../components/DatePicker";
import {
  enumFunctionName,
  enumOperation,
  tableConfig,
} from "../../utils/constant";
import AuditLogList from "./AuditLog/AuditLogList";
import OnPortalOpDetailsDialog from "./AuditLog/OnPortalOpDetailsDialog";
import {
  getApiInLog,
  getApiOutLog,
  getAuditLogWithReqId,
  getPortalAuditLog,
} from "../../api/merchant";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";

const translatePrefix = "auditLog";
interface ILocalState {
  function_name: string[];
  operator: string[];
  operation: string[];
  serial_no: string[];
  batch_id: string[];
  req_id: string;
}
const initSearchParams = {
  function_name: [],
  operator: [],
  operation: [],
  serial_no: [],
  batch_id: [],
  req_id: "",
};
export interface listFace {
  rows: Object[];
  count: number;
}
type IoperationTab =
  | "portal_operation_tab"
  | "api_in_operation_tab"
  | "api_out_operation_tab";
const AuditLogTabView = (props: { operationTab: IoperationTab }) => {
  const { operationTab } = props;

  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const ListProps = {
    listMapping,
    operationTab,
  };

  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    //{[ - - customeLayoutBox for Switch Tabs -- ]}
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar {...ListProps} />
      </Container>
      <DivideLine />
      <>
        <Container
          style={customSx.datagridContainer}
          maxWidth={false}
          disableGutters
        >
          <Tabs>
            <AuditLogList {...ListProps} />
          </Tabs>
        </Container>
        <OnPortalOpDetailsDialog />
      </>
    </Box>
  );
};

export function FilterBar(props: { operationTab: IoperationTab }) {
  const { operationTab } = props;
  const zusList = useZusListStore();
  const page = zusList.meta.page;

  //Pending: need to handle this Params issue
  const [searchedParams] = useSearchParams();
  const initSearchStates = {
    ...initSearchParams,
    req_id: searchedParams.get("reqId") || "",
  };
  const direction =
    operationTab === "api_in_operation_tab"
      ? 0
      : operationTab === "api_out_operation_tab"
      ? 1
      : [];
  const getAuditLogByTab = {
    portal_operation_tab: getPortalAuditLog,
    api_in_operation_tab: getApiInLog,
    api_out_operation_tab: getApiOutLog,
  }[operationTab];
  const filterFunctionBy = initSearchStates.req_id
    ? getAuditLogWithReqId
    : getAuditLogByTab;

  const [fields, setFields] = useState<ILocalState>(initSearchStates);

  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const DateObj = {
    Created: useDatePicker(),
    Modified: useDatePicker(),
    WalletSettled: useDatePicker(),
  };

  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const params = {
    req_date_from: DateObj.Created.start,
    req_date_to: DateObj.Created.end,
    res_date_from: DateObj.Modified.start,
    res_date_to: DateObj.Modified.end,
    operators: fields.operator,
    ids: fields.serial_no,
    req_id: fields.req_id,
    batch_ids: fields.batch_id,
    //{- -** -}
    // endpoints: fields.function_name,
    // operation: fields.operation, //no need to pass to backend
    direction,
    page,
    pageSize: tableConfig.pageSize,
  };
  const [cacheParams, setCacheParams] = useState(params);

  // console.log(params)
  const zusDialog = useZusDialogStore();

  useEffect(() => {
    async function fetch() {
      let endpoints = getEndpointsByFuncWithOpType(
        fields.function_name,
        fields.operation
      );
      const moreParams = { ...cacheParams, endpoints, page };
      zusDialog.openExtra("loadingDialog");
      const res = await filterFunctionBy(moreParams);
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
    }
    fetch();
  }, [page]);

  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    zusList.setPage(0);
    setCacheParams(params);
    let endpoints = getEndpointsByFuncWithOpType(
      fields.function_name,
      fields.operation
    );
    const moreParams = { ...params, endpoints, page: 0 };
    const res = await filterFunctionBy(moreParams);
    zusDialog.closeExtra();
    if (!res) return;
    zusList.setGeneralList(res);
  };
  const onReset = () => {
    setFields(initSearchParams);
    DateObj.Created.clearDate();
    DateObj.Modified.clearDate();
    DateObj.WalletSettled.clearDate();
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "function_name") {
      setFields((fields) => ({
        ...fields,
        operation_type: [],
      }));
    }
  };
  const onFilterChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let { value }: any = e.target;
      const newValue =
        value == ""
          ? value
          : typeof value === "string"
          ? value.split(",")
          : value;
      setFields((fields) => ({ ...fields, [type]: newValue }));
    };
  // console.log(params);
  const F = genField({ t }, [
    ["request_time", <DateObj.Created.Picker type="dateTime" />],
    ["response_time", <DateObj.Modified.Picker type="dateTime" />],
    [
      "function_name",
      <FormControl>
        <InputLabel>{t("phFunction_name")}</InputLabel>
        <Select
          multiple
          name="function_name"
          value={fields.function_name}
          onChange={onArraySelectChange}
        >
          {Object.entries(enumFunctionName).map(([key, name]) => (
            <MenuItem
              key={key}
              value={key}
              onClick={(e) => handleClick(e, key, "function_name")}
            >
              {translate(`enumConstants.${enumFunctionName[String(key)]}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "operator",
      <MpTextField
        name="operator"
        label={t("phOperator")}
        value={fields.operator}
        onChange={onFilterChange("operator")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.operators.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "operation",
      <FormControl>
        <InputLabel>{t("phOperation")}</InputLabel>
        <Select
          multiple
          name="operation"
          value={fields.operation}
          onChange={onArraySelectChange}
        >
          {Object.entries(enumOperation).map(([key, name]) => (
            <MenuItem
              key={key}
              value={key}
              onClick={(e) => handleClick(e, key, "operation")}
            >
              {translate(`enumConstants.${enumOperation[String(key)]}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],

    [
      "serial_no",
      <MpTextField
        name="serial_no"
        label={t("phSerial_no")}
        value={fields.serial_no}
        onChange={onFilterChange("serial_no")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{params.ids.length}</InputAdornment>
          ),
        }}
      />,
    ],
    [
      "batch_id",
      <MpTextField
        name="batch_id"
        label={t("phBatch_id")}
        value={fields.batch_id}
        onChange={onFilterChange("batch_id")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}></Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.request_time}
        {F.response_time}
        {F.function_name}
        {F.operator}
        {F.operation}
        {F.serial_no}
        {F.batch_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

interface IAuditLog {
  id: string;
  batch_id: string;
  merchant_id: string;
  endpoint: string;
  req_id: string;
  req_header: string;
  req_body: string;
  req_date: Date;
  res: string;
  res_success: boolean;
  res_date: Date;
  res_status: number;
  ip: string;
  operator: string;
  jwt: string;
  yubi_key?: any;
  yubi_verified?: any;
}

const listMapping = (
  which: "key" | "name",
  array: IAuditLog[],
  config?: any
): any[][] => {
  const { omitKeys = [], translate } = config || {};
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const res = array.map((item) => {
    const funcNameAndOpType = findFunctionNameAndOpType(item.endpoint);
    const enumOfFuncName = funcNameAndOpType?.name;
    const enumOfActionType = funcNameAndOpType?.type;
    //backend  return '-'
    const parseJson = (input: any) => {
      if (input == "-") {
        input = "";
      }
      return input ? JSON.stringify(JSON.parse(input), null, 3) : "";
    };
    const mappedResult = [
      //
      [
        "rawData",
        "",
        {
          req_id: item.req_id,
          req_date: toDisplayTime(item.req_date),
          req_header: parseJson(item.req_header),
          req_body: parseJson(item.req_body),
          res: parseJson(item.res),
        },
      ],
      //
      ["res_date", t("resposneTime"), toDisplayTime(item.res_date)],
      ["serial_no", t("serial_no"), item.id],
      ["request_time", t("request_time"), toDisplayTime(item.req_date)],
      ["operator", t("operator"), item.operator],
      [
        "function_name",
        t("function_name"),
        translate(`enumConstants.${enumFunctionName[enumOfFuncName]}`),
      ],
      [
        "operation",
        t("operation"),
        translate(`enumConstants.${enumOperation[enumOfActionType]}`),
      ],
      ["batch_id", t("batch_id"), item.batch_id],
      ["api_url", t("api_url"), item.endpoint],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

export default AuditLogTabView;
