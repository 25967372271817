import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { listFace } from "../../api/merchant";
import { getRequestRecordList } from "../../api/signer";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useDatePicker } from "../../components/DatePicker";
import { useChains, useAssets } from "../../components/FetchConfig";
import GeneralBtn from "../../components/GeneralBtn";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import ApproveOrRejectDialog from "../../components/WithDrawApprovalSetting/ApproveOrRejectDialog";
import RejectDialog from "../../components/WithDrawApprovalSetting/RejectDialog";
import RequestRecordList from "../../components/WithDrawApprovalSetting/RequestRecordList";
import { enumAdjustmentStatus, tableConfig } from "../../utils/constant";
import {
  getDecimal,
  displayAmount,
  findChainInfo,
  toDisplayTime,
  sortItemsAlphabetically,
  listMappingTransform,
  convertThousandth,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import { useZusDialogStore, zusRefetchStore } from "../../zustand/store";
import MpTextField from "../../components/MpTextField";
import { useTabs, useTranslation } from "../../hooks";
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
  statusArr: string[];
  proposal_no: string[];
}
const initFields = {
  asset_names: [],
  chain_name: "",
  statusArr: [],
  proposal_no: [],
};
const { pageSize } = tableConfig;
const translatePrefix = "withdrawApprovalSetting";
const WithDrawApproveSettingRequestRecord = () => {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const { tc } = useTranslation(translatePrefix);
  const tabsArr = [{ name: tc("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar {...{ fields, setFields, setList, page, setPage }} />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <RequestRecordList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
          />
        </Tabs>
      </Container>

      <RejectDialog />
      <ApproveOrRejectDialog />
    </Box>
  );
};

export function FilterBar(props: {
  fields: fieldsFace;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
  setList: Dispatch<SetStateAction<listFace>>;
}) {
  const zusDialog = useZusDialogStore();
  const refetcbool = zusRefetchStore((state) => state.withdrawSettingReordTab);
  const { fields, page, setPage, setFields, setList } = props;
  const { t } = useTranslation(translatePrefix);
  const DateObj = {
    Created: useDatePicker(),
    Modified: useDatePicker(),
  };
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  const chain_name = fields.chain_name;

  useEffect(() => {
    setAssets([]);
  }, [chain_name]);
  const assetsNames = selectAssetNamesByChain({ chain_name });
  const { chain_type, chain_id } = selectChainByPieces({
    chain_name,
  });
  const params = {
    chain_type,
    chain_id,
    asset_names: fields.asset_names,
    page,
    pageSize,
    statuses: fields.statusArr,
    ids: fields.proposal_no,
    created_date_from: DateObj.Created.start,
    created_date_to: DateObj.Created.end,
    operated_date_from: DateObj.Modified.start,
    operated_date_to: DateObj.Modified.end,
  };
  const [cacheParams, setCacheParams] = useState(params);
  useEffect(() => {
    async function asyncFuncWrapper() {
      zusDialog.openExtra("loadingDialog");
      const res = await getRequestRecordList({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (res) setList(res);
    }
    asyncFuncWrapper();
  }, [page, cacheParams, refetcbool]);
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
    const res = await getRequestRecordList(params);
    if (!res) return;
    setList(res);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Created.clearDate();
    DateObj.Modified.clearDate();
  };
  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const onFilterChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let { value }: any = e.target;
      const newValue =
        value == ""
          ? value
          : typeof value === "string"
          ? value.split(",")
          : value;
      setFields((fields) => ({ ...fields, [type]: newValue }));
    };
  const F = genField({ t }, [
    ["create_time", <DateObj.Created.Picker type="dateTime" />],
    ["operation_time", <DateObj.Modified.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "approval_status",
      <FormControl>
        <InputLabel>{t("phApproval_status")}</InputLabel>
        <Select
          name="statusArr"
          multiple
          value={fields.statusArr}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumAdjustmentStatus)).map(
            ([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {t(`${value}`.toLowerCase())}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>,
    ],
    [
      "proposal_no",
      <MpTextField
        name="proposal_no"
        label={t("phProposal_no")}
        value={fields.proposal_no}
        onChange={onFilterChange("proposal_no")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{params.ids.length}</InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.asset_name}
        {F.creation_time}
        {F.operation_time}
        {F.approval_status}
        {F.proposal_no}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

export default WithDrawApproveSettingRequestRecord;

const listMapping = (
  which: "key" | "name",
  array: any[],
  {
    t,
    translate,
  }: {
    t: (key: string, params?: any) => string;
    translate: (key: string, params?: any) => string;
  }
): any[][] => {
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);
    const toCount = (input: string) => (input ? convertThousandth(input) : "");
    const mappedResult = [
      //{- -NotShow onto UI Or Csv- -}
      ["chain_id", "", item.chain_id],
      ["id", "", item.id],
      ["chain_type", "", item.chain_type],
      [
        "withdraw_approval_regulation_requests",
        "",
        item.withdraw_approval_regulation_requests,
      ],
      //
      [
        "existing_to_addr_hourly_limit",
        "",
        toAmount(item.existing_to_addr_hourly_limit),
      ],
      [
        "existing_to_addr_hourly_count",
        "",
        toCount(item.existing_to_addr_hourly_count),
      ],

      // ["existing_amount", "", toAmount(item.existing_threshold_amount)],
      [
        "existing_hourly_auto_approval_limit",
        "",
        toAmount(item.existing_hourly_auto_approval_limit),
      ],
      ["existing_mx_hourly_limit", "", toAmount(item.existing_hourly_limit)],

      //
      ["proposal_no", t("proposal_no"), item.id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],

      // ["edited_amount", t("edited_amount"), toAmount(item.threshold_amount)],

      [
        "hourly_auto_approval_limit",
        t("request_hourly_limit"), //req_hourly_auto_approval_limit
        toAmount(item.hourly_auto_approval_limit),
      ],
      //new added field mileStone_7
      [
        "reqEditMaxLimitWithin1Hr", //req_add_hourly_limit
        t("reqEditMaxLimitWithin1Hr"),
        toAmount(item.to_addr_hourly_limit),
      ],
      [
        "reqEditMaxNoTrxs",
        t("reqEditMaxNoTrxs"),
        toCount(item.to_addr_hourly_count),
      ], //req_hourly_count
      [
        "reqEditMaxNoTrxAmt", //req_max_hourly_limit
        t("reqEditMaxNoTrxAmt"),
        toAmount(item.hourly_limit),
      ],

      //

      [
        "status",
        t("status"),
        translate(
          `enumWithDrawApprovalSetting.${enumAdjustmentStatus[
            String(item.status)
          ].toLowerCase()}`
        ),
      ],

      ["created_by", t("created_by"), item.created_by],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      ["operated_by", t("operated_by"), item.operated_by],
      [
        "operation_time",
        t("operation_time"),
        toDisplayTime(item.operated_date),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
