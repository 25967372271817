import { Box } from "./MuiGenerals";
import Tooltip from "@mui/material/Tooltip";
import { sxOpTextButton } from "./OpTextButton";
export { sxOpTextButton };
export default function OpIconButton(p: {
  title: string;
  url: string;
  onClick: () => void;
  isDisabled?: boolean;
  isHidden?: boolean;
  size?: "2rem" | "1.2rem" | "1rem" | "1.5rem" | "1.9rem";
}) {
  const { isHidden = false, isDisabled = false, size = "2rem" } = p;
  const sx = {
    box: {
      width: size,
      cursor: isDisabled ? "" : "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        filter: isDisabled ? "brightness(100%)" : "brightness(130%)",
      },
    },
    img: {
      filter: isDisabled ? "grayscale(100%)" : "grayscale(0)",
      width: "100%",
    },
  };
  if (isHidden) return null;
  return (
    <Tooltip title={p.title}>
      <Box sx={sx.box}>
        <img
          src={p.url}
          onClick={() => !isDisabled && p.onClick()}
          style={sx.img}
          // alt=""
        />
      </Box>
    </Tooltip>
  );
}
