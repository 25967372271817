import {
  chainKeyAndName,
  enumNewOperationBatchStatus,
  enumPortalUserOperStatus,
  enumPortalUserYubiKeyStatus,
  enumTimezone,
  enumYubikeyProposalType,
} from "../utils/constant";

export type ValueOf<T> = T[keyof T];
export type YesOrNo = "yes" | "no";
export type TrueOrFalseString = "true" | "false";
export enum EnumComparisonOperators {
  eq = "eq",
  lt = "lt",
  lte = "lte",
  gt = "gt",
  gte = "gte",
}
export type ComparisonOperators<
  T extends keyof typeof EnumComparisonOperators
> = T;
export interface IlistResult<T> {
  rows: T[];
  count: number;
}
export interface Ipage {
  page: number;
  pageSize: number;
}
export type IassetName = keyof typeof chainKeyAndName;

export interface IZusParams<Body> {
  body: Body;
  setBody: (params: Partial<Body>) => void;
  setPage: (page: number) => void;
  refetch: () => void;
  refetchCounter: number;
  clear: () => void;
}

export interface ClientWalletParamsFace {
  clientIdsString: string;
  walletNamesString: string;
  chain_name: any;
  asset_names: string[];
  walletAddressesArray: string[];
  statusArray: string[];
  is_check_point_certified: string;
}

export interface MerchantWalletParamsFace {
  displayNamesString: string;
  clientIdsString: string;
  walletNamesString: string;
  walletTypesArray: string[];
  asset_names: string[];
  chain_name: string;
  walletAddressesArray: any;
  statusArray: string[];
  is_check_point_certified: string;
  risk_levels: string[];
}
export interface MerchantWalletBalanceParamsFace {
  walletNamesString: string;
  walletTypesArray: string[];
  asset_names: string[];
  chain_name: string;
  walletAddressesArray: any;
  statusArray: string[];
  is_check_point_certified: string;
  comparison_operator: keyof typeof EnumComparisonOperators | "";
  balance_comparison: string;
  exclude_zero: TrueOrFalseString;
}

export interface OperationsSearchParamsFace {
  batchId: string;
  statuses: string[];
  chain_name: string;
  assetName: string[];
  merchantOrderIdsString: string;
  merchant_order_ids: string[];
}
export interface OperationsTransactionSearchParamsFace {
  batchId: string;
  statuses: string[];
  chain_name: string;
  assetName: string[];
}
export interface MerchantOperationsParamsFace {
  batchId: string[];
  chain_name: string;
  asset_names: string[];
  operation_type: any;
  status: string[];
  callback_status: string[];
  merchant_id: string;
  creator_type: string[];
  approval_completed: string;
  created_date_from?: string;
  created_date_to?: string;
  last_modified_date_from?: string;
  last_modified_date_to?: string;
}
export interface WalletBalanceAdjustmentParamsFace {
  approvalPropsalString: string;
  walletNameString: string;
  chain_name: string;
  walletAddressString: string;
  asset_names: string[];
  statusArr: string[];
}
export interface AllTransactionParamsFace {
  clientIdsString: string;
  walletNamesString: string;
  walletTagsString: string;
  wallet_types?: string[];
  chain_name: string;
  asset_names: string[];
  directions: string[];
  trans_types: string[];
  statusArr: string[];
  wallet_address: string;
  from_address: string;
  to_address: string;
  is_fee: string;
  tx_hash: string;
  ref_no: string;
  check_point_certified: string;
  merchant_id: string;
  risk_levels: string[];
  batchIdsString: string;
}
export interface AdjustmentFieldsFace {
  chain_name: string;
  asset_name: string;
  walletAddress: string;
  currentWalletBalance: string;
  adjustment_amount: string;
  remark: string;
}
export interface IsignerGetOperation {
  id: string;
  merchant_id: string;
  operation_type: number;
  batch_id: string;
  chain_type: number;
  chain_id: string;
  miner_fee_asset_name: string;
  estimated_miner_fee: string;
  estimated_miner_fee_decimals: number;
  is_estimating_miner_fee: boolean;
  prepared_miner_fee: string;
  prepared_miner_fee_decimals: number;
  asset_name: string;
  total_amount: string;
  total_operation: number;
  received_operation: number;
  client_data: string;
  request_time: string;
  status: number;
  created_by: string;
  creator_type: number;
  approval_required: number;
  approval_completed: number;
  callback_status: number;
  created_date: string;
  last_modified_date: string;
  last_executed_by: string;
  last_executed_date: string;
  last_estimated_miner_fee_date: string;
  remarks: any;
  transaction_approval_details: {
    id: string;
    transaction_batch_id: string;
    seq: number;
    approval_result: 1 | -1;
    operated_by: string;
    operated_date: string;
    created_date: string;
    last_modified_date: string;
    transaction_approval_details_fk: string;
  }[];
}
export type Itimezone = ValueOf<typeof enumTimezone>;
export type IoperationBatchStatus = ValueOf<typeof enumNewOperationBatchStatus>;
export type IportalUserOperStatus = ValueOf<typeof enumPortalUserOperStatus>;
export type IportalUserYubiKeyStatus = ValueOf<
  typeof enumPortalUserYubiKeyStatus
>;
export type IYubikeyProposalType = ValueOf<typeof enumYubikeyProposalType>;
export interface IportalUsersParams {
  email: string;
  oper_statuses: IportalUserOperStatus[];
  yubi_key_statuses: IportalUserYubiKeyStatus[];
  pub_id: string; //yubikey_id
}
export interface IportalUserProposalsParams {
  date_created_from: string;
  date_created_to: string;
  acts: IYubikeyProposalType[];
  email: string;
  pub_id: string; //yubikey_id
  statuses: IportalUserYubiKeyStatus[];
}
export interface IoperationDetailsRes {
  id: string;
  transaction_batch_id: string;
  merchant_order_id: string;
  merchant_id: string;
  operation_seq: number;
  operation_type: number;
  batch_id: string;
  wallet_version: number;
  wallet_path: string;
  asset_name: string;
  amount: string;
  decimals: number;
  from_address: string;
  to_address: string;
  to_wallet_tag: string;
  to_wallet_type: number;
  to_wallet_version: number;
  to_wallet_path: string;
  invoker_address: any;
  invoker_wallet_version: any;
  invoker_wallet_path: any;
  request_time: string;
  created_date: string;
  last_modified_date: string;
  transaction_transaction_batch_id: string;
}
export interface IgetWithdrawRequestParams {
  date_from: null | string;
  date_to: null | string;
  operationStatuses?: IoperationBatchStatus[];
  batch_ids?: string[];
  asset_names?: IassetName | string[];
  merchant_order_ids?: string[];
  chain_id?: string;
  chain_type?: string;
}

export interface IFreezeRequestRes {
  id: string;
  merchant_id: string;
  client_id: string;
  action: string;
  merchant_order_id: string;
  seq: number;
  frozen_order_id: string;
  related_order_id: string;
  chain_type: number;
  chain_id: string;
  asset_name: string;
  amount: string;
  decimals: number;
  status: number;
  created_by: string;
  remarks: any;
  approvals_required: number;
  approvals: number;
  creator_type: number;
  created_date: string;
  last_modified_date: string;
  error_reason: any;
}
export interface IFrozenFundRes {
  id: string;
  merchant_id: string;
  merchant_order_id: string;
  client_id: string;
  chain_type: number;
  chain_id: string;
  asset_name: string;
  frozen_amount: string;
  status: number;
  frozen_date: string;
  unfrozen_date: string;
  created_date: string;
  last_modified_date: string;
}
