import { Box } from "../../../components/MuiGenerals";
import { usePermission, useTabs, useTranslation } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import NonceTab from "./NonceTab";
import PriorityListTab from "./PriorityListTab";

const Pkey = FeatureCodes.tools.NonceTool;
const translatePrefix = "NonceTool";
export default function NonceTool() {
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();

  const tabsArr = [
    hasPermission(Pkey.TabNonce.prefix) && {
      name: t("nonceTab"),
      value: "tab_nonce",
    },
    hasPermission(Pkey.TabPriorityList.prefix) && {
      name: t("priorityListTab"),
      value: "tab_priority_list",
    },
  ].filter((a) => a) as { name: string; value: string }[];

  const { Tabs, tabValue } = useTabs(tabsArr);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
        paddingLeft: "2rem",
        paddingRight: "2rem",
      }}
    >
      <Tabs sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}>
        {tabValue === "tab_nonce" && <NonceTab />}
        {tabValue === "tab_priority_list" && <PriorityListTab />}
      </Tabs>
    </Box>
  );
}
