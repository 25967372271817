import React from "react";
import { useTranslate } from "react-admin";
import { useAlerting } from "../hooks";
import { copyToClipboard } from "../utils/helper";
import { Tooltip } from "./MuiGenerals";
import WalletTypeIcon from "./WalletTypeIcon";

interface WalletTypeIconAddressFace {
  isCopy: boolean;
  walletObjFromDatabase: {
    display_name: string;
    wallet_type: number;
  };
  walletAddress: string;
  isTextEllipsis?: boolean;
  shouldHandleBreakLine?: boolean;
  textLengthLimit?: number;
}

export function WalletTypeIconWithAddress(props: WalletTypeIconAddressFace) {
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const {
    isCopy,
    walletObjFromDatabase,
    walletAddress,
    isTextEllipsis,
    shouldHandleBreakLine = true,
  } = props;

  const breakLineCss = shouldHandleBreakLine
    ? {
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
      }
    : {};

  const walletTypeIconColorArr = [
    "#3B5DB9",
    "#3BAFB9",
    "#583BB9",
    "#3B8DB9",
    "#7AB93B",
    "#973BB9",
  ];

  const { display_name, wallet_type } = walletObjFromDatabase;
  const hasDataFromDatabase = display_name && wallet_type >= 0;

  const cellOnClick = () => {
    copyToClipboard(walletAddress);
    alerting("success", translate("report.copied"));
  };

  return (
    <>
      {hasDataFromDatabase && (
        <WalletTypeIcon
          walletTypeIconColorArr={walletTypeIconColorArr}
          addressBookIconData={{
            display_name,
            wallet_type: String(wallet_type),
          }}
        />
      )}
      <Tooltip title={walletAddress}>
        <div
          style={{
            marginLeft: hasDataFromDatabase ? undefined : "8px",
            overflow: "hidden",
            textOverflow: isTextEllipsis ? "ellipsis" : undefined,
            color: isCopy ? "#4A90F7" : undefined,
            cursor: isCopy ? "pointer" : undefined,
            textDecoration: isCopy ? "underline" : undefined,
            ...(breakLineCss as React.CSSProperties),
          }}
          onClick={isCopy ? cellOnClick : undefined}
        >
          {walletAddress}
        </div>
      </Tooltip>
    </>
  );
}
