import { useEffect } from "react";
import { Container, Box, Button } from "../../components/MuiGenerals";
import WithdrawApprovalSettingList from "./WithdrawApprovalSettingList";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { useState, Dispatch, SetStateAction } from "react";
import { WithdrawThreshold } from "../../api/signer";
import { useAssets, useChains } from "../../components/FetchConfig";
import { customSx } from "../../utils/styling";
import { DivideLine, genField } from "../../utils/HelperComp";
import {
  convertThousandth,
  displayAmount,
  findChainInfo,
  getDecimal,
  listMappingTransform,
} from "../../utils/helper";
import WithDrawApprovalSettingDialog from "../../components/WithDrawApprovalSetting/WithDrawApprovalSettingDialog";
import WithDrawApprovalAddRecordDialog from "../../components/WithDrawApprovalSetting/WithDrawApprovalAddRecordDialog";
import {
  useZusListStore,
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
import GeneralBtn from "../../components/GeneralBtn";
import { usePermission, useTabs, useTranslation } from "../../hooks";
import { FeatureCodes, tableConfig } from "../../utils/constant";

const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting;
const { pageSize } = tableConfig;
interface fieldsFace {
  asset_names: string[];
  chain_name: string;
}
const initFields = {
  asset_names: [],
  chain_name: "",
};
const translatePrefix = "withdrawApprovalSetting";

export default function WithdrawApprovalSetting() {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { tc } = useTranslation(translatePrefix);
  const tabsArr = [{ name: tc("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <WithDrawApprovalAddRecordDialog />
        <FilterBar {...{ fields, setFields }} />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <WithdrawApprovalSettingList listMapping={listMapping} />
        </Tabs>
        <WithDrawApprovalSettingDialog />
      </Container>
    </Box>
  );
}
//{- - Debug- -}

export function FilterBar(props: {
  fields: fieldsFace;
  setFields: Dispatch<SetStateAction<fieldsFace>>;
}) {
  const { fields, setFields } = props;
  const zusDialog = useZusDialogStore();
  const zusList = useZusListStore();
  const { hasPermission } = usePermission();
  const page = zusList.meta.page;
  const { t } = useTranslation(translatePrefix);
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  const chain_name = fields.chain_name;
  const handleAddRecord = () => {
    zusDialog.open("recordDialog");
  };
  useEffect(() => {
    setAssets([]);
  }, [chain_name]);
  const assetsNames = selectAssetNamesByChain({ chain_name });
  const { chain_type, chain_id } = selectChainByPieces({
    chain_name,
  });
  const params = {
    chain_type,
    chain_id,
    asset_names: fields.asset_names,
    page,
    pageSize,
  };
  const [cacheParam, setCacheParams] = useState(params);
  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await WithdrawThreshold({ ...cacheParam, page });
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
      const totalCount = String(res?.count);
      useStore.setTotalCountForThresholdRecord(totalCount);
      const time = res.withdraw_checking_time ? res.withdraw_checking_time : "";
      useStore.setConfigTime({ time });
    }
    fetch();
  }, [page, cacheParam]);
  const onSearch = async () => {
    zusList.setPage(0);
    setCacheParams(params);
    const paramsWithResetPage = { ...params, page: 0 };
    const res = await WithdrawThreshold(paramsWithResetPage);
    if (!res) return;
    zusList.setGeneralList(res);
  };
  const onReset = () => setFields(initFields);
  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.TabSetting.AddRecord) && (
          <Button onClick={handleAddRecord} variant="outlined">
            {t("add_record")}
          </Button>
        )}
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.asset_name}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { t }: { t: (key: string) => string }
): any[][] => {
  // const t = (key: string) => t(`assetManagement.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);

    //
    const toNoCommaAmt = (input: number) => toAmount(input).replaceAll(",", "");
    const toNoComma = (input: string) =>
      input ? input.replaceAll(",", "") : "";
    const toCount = (input: string) => (input ? convertThousandth(input) : "");
    const mappedResult = [
      ["id", "", item.id],
      [
        "rawData",
        "",
        {
          chain_type: item.chain_type,
          chain_id: item.chain_id,
          // threshold_amount: toNoCommaAmt(item.threshold_amount),
          hourly_auto_approval_limit: toNoCommaAmt(
            item.hourly_auto_approval_limit
          ),
          mx_hourly_limit: toNoCommaAmt(item.hourly_limit),
          mx_addr_hrly_count: toNoComma(item.to_addr_hourly_count),
          mx_addr_hrly_limit: toNoCommaAmt(item.to_addr_hourly_limit),

          withdraw_approval_regulations: item.withdraw_approval_regulations,
          decimal: decimal,
        },
      ],
      //
      ["chain_type", "", item.chain_type],
      ["chain_id", "", item.chain_id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      // [
      //   "threshold_amount",
      //   t("approval_threshold_amount"),
      //   toAmount(item.threshold_amount),
      // ],
      [
        "hourly_auto_approval_limit",
        t("max_hourly_approve_limit"),
        toAmount(item.hourly_auto_approval_limit),
      ],
      ["mx_hourly_limit", t("mx_hourly_limit"), toAmount(item.hourly_limit)],
      //
      [
        "mx_addr_hrly_count",
        t("mx_addr_hrly_count"),

        toCount(item.to_addr_hourly_count),
      ],
      [
        "mx_addr_hrly_limit",
        t("mx_addr_hrly_limit"),
        toAmount(item.to_addr_hourly_limit),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
