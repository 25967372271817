//ApprovalWindow

import BigNumber from 'bignumber.js';
import flow from 'lodash/flow';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { getWalletManagement } from '../../../api/merchant';
import { CustomPagination } from '../../../components/CustomPagination';
import { Box } from '../../../components/MuiGenerals';
import { NoRowsOverlay } from '../../../components/NoRowsOverlay';
import { WalletTypeIconWithAddress } from '../../../components/WalletTypeIconWithAddress';
import {
  enumNewApprovalStatus,
  enumOperationType
} from '../../../utils/constant';
import {
  enumMapping,
  formatLargeNumberforBigNumber,
  toDisplayTime
} from '../../../utils/helper';
import { useGenGridCol } from '../../../utils/HelperComp';
import { useZusDialogStore } from '../../../zustand/store';
import OpDatagridFooter from './OpDatagridFooter';

interface ItransactionApprovalDetails {
  seq: number;
  operated_by: string;
  approval_result: number;
  operated_date: string;
}
const Enum = {
  approvalStatus: enumMapping(enumNewApprovalStatus),
};
const ApprovalWindow = (props: {
  rows: ItransactionApprovalDetails[];
  isOperation: boolean;
}) => {
  const zusDialog = useZusDialogStore();
  const rowData = zusDialog.meta?.row || {};
  const checkingHour = rowData.checkTime ? rowData.checkTime : "";

  const operationDetails = rowData.opArrDetail
    ? rowData.opArrDetail.map((obj: any) => ({
        ...obj,
        hrly_amt: obj.hrly_amt + " " + obj.asset_name,
        day_hourly_amount: obj.day_hourly_amount + " " + obj.asset_name,
      }))
    : [];
  const opArrDetails: Array<any> = operationDetails;
  const translate = useTranslate();
  const t = (key: string, configAmt = {}) =>
    translate(`assetManagement.${key}`, configAmt);
  const rows = props.rows.map((row) => {
    const approval_result: string = flow([
      (val) => Enum.approvalStatus[val],
      (val) => (val || "").toLowerCase(),
      (str: string) => (str ? t(str) : ""),
    ])(row.approval_result);

    return { ...row, approval_result, resultEnum: row.approval_result }; //replace
  });

  const headerCellID = () => {
    return (
      <span
        style={{
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1,
        }}
      >
        #
      </span>
    );
  };
  const headerCellTrxCount = () => {
    return (
      <>
        <span
          style={{
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          }}
        >
          {t("noOfTrxsBeforeCreateTimeWithinOneHr", { checkingHour })}
        </span>
      </>
    );
  };
  const headerCellHrlyAmt = () => {
    return (
      <>
        <span
          style={{
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          }}
        >
          {t("totalTrxsAmtWithOneHr", { checkingHour })}
        </span>
      </>
    );
  };
  const headerCellDayAmt = () => {
    return (
      <>
        <span
          style={{
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
          }}
        >
          {t("totalTrxsAmtWithinTwentyFourhr")}
        </span>
      </>
    );
  };

  const getParams = () => {
    const { chain_type, chain_id } = rowData;
    return {
      chain_type,
      chain_id,
      pageSize: 50,
      page: 0,
    };
  };

  const walletAddressesArr = Array.from(
    new Set<string>(rowData?.opArrDetail?.map((item: any) => item.address))
  );

  const dataInAddressBookArrRef = useRef<
    {
      wallet_address: string;
      display_name: string;
      wallet_type: number;
    }[]
  >([]);

  const [fetching, setFetching] = useState(false);

  const fetchWalletDetailForIcon = async () => {
    const pageSize = 50;
    const params = getParams();
    for (let index = 0; index < walletAddressesArr.length; index += pageSize) {
      setFetching(true);
      const result = await getWalletManagement({
        ...params,
        wallet_address: walletAddressesArr.slice(index, index + pageSize),
      });
      if (!result) {
        return;
      }
      if (result && result?.rows) {
        dataInAddressBookArrRef.current = [
          ...dataInAddressBookArrRef.current,
          ...result?.rows,
        ];
      }

      setFetching(false);
    }
  };

  useEffect(() => {
    fetchWalletDetailForIcon();
  }, []);

  const ToAddressWithWalletIcon = (props: any) => {
    const { wallet_type, display_name } = useMemo(
      () =>
        dataInAddressBookArrRef.current.find(
          (item: any) => item.wallet_address === props.row.address
        ) || { display_name: "", wallet_type: -1 },
      [fetching]
    );

    return (
      <WalletTypeIconWithAddress
        isCopy={false}
        walletObjFromDatabase={{ wallet_type, display_name }}
        walletAddress={props.row.address}
      />
    );
  };

  const columns: GridColDef[] = [
    useGenGridCol("operation_seq", "#", {
      renderHeader: headerCellID,
      minWidth: 45,
      headerAlign: "right",
      align: "right",
    }),
    useGenGridCol("address", t("receivingAddress"), {
      minWidth: 350,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: ToAddressWithWalletIcon,
    }),
    useGenGridCol("hrly_count", t("noOfTrxsBeforeCreateTimeWithinOneHr"), {
      maxWidth: 120,
      sortable: true,
      align: "right",
      renderHeader: headerCellTrxCount,
    }),
    useGenGridCol("hrly_amt", t("totalTrxsAmtWithOneHr"), {
      minWidth: checkingHour == 24 ? 340 : 170,
      sortable: true,
      align: "right",
      renderHeader: headerCellHrlyAmt,
      sortComparator: (value1, value2) => {
        const valueNum1 = new BigNumber(formatLargeNumberforBigNumber(value1));
        const valueNum2 = new BigNumber(formatLargeNumberforBigNumber(value2));
        return valueNum2.comparedTo(valueNum1);
      },
    }),
    useGenGridCol("day_hourly_amount", t("totalTrxsAmtWithinTwentyFourhr"), {
      minWidth: 170,
      sortable: true,
      align: "right",
      renderHeader: headerCellDayAmt,
    }),
  ].filter((col) => {
    if (col.field === "day_hourly_amount" && checkingHour == 24) return false;
    return true;
  }) as GridColDef[];

  const footerprops = {
    addressCount: opArrDetails.length,
    trxHrCount: rowData.trxHrCount,
    trxHrAmt: rowData.trxHrAmt,
    trxDayAmt: rowData.trxDayAmt,
    asset_name: rowData.asset_name,
    checkingHour: checkingHour,
  };
  return (
    <Box
      sx={{
        height: `${
          rowData.rawData.operation_type == enumOperationType.Withdraw
            ? "350px"
            : "auto"
        }`,
        width: "830px",
      }}
    >
      {rowData.approvalReasons && (
        <div
          //DivideLine
          style={{
            borderBottom: "solid #F1F2F4 1px",
            margin: "10px 4px 10px 4px",
          }}
        ></div>
      )}
      {props.isOperation &&
        rowData.rawData.operation_type == enumOperationType.Withdraw && (
          <>
            <div
              style={{
                borderBottom: "solid #d9d9d9 1px",
                margin: "0 3px",
              }}
            ></div>
            <DataGrid
              sx={{
                "& .MuiDataGrid-footerContainer": {
                  minHeight: 0,
                  backgroundColor: "#F1F2F4",
                },
                "& .MuiDataGrid-cell": {
                  borderBottomStyle: "none",
                  backgroundColor: "#F1F2F4",
                },
                backgroundColor: "#F1F2F4",
                width: "100%",
                borderBottomStyle: "none",
              }}
              rows={opArrDetails}
              columns={columns}
              disableColumnFilter={true}
              rowsPerPageOptions={[5]}
              experimentalFeatures={{ newEditingApi: true }}
              hideFooterPagination
              hideFooterSelectedRowCount
              paginationMode="client"
              disableColumnMenu
              headerHeight={64}
              rowHeight={30}
              components={{
                NoRowsOverlay,
                Footer: (params: any) => (
                  <>
                    <OpDatagridFooter footerprops={params.footerprops} />
                    <CustomPagination noshowFooter={true} />
                  </>
                ),
              }}
              componentsProps={{
                footer: { footerprops },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: "hrly_amt", sort: null }],
                },
              }}
            />
          </>
        )}
      <div style={{ fontSize: "12.5px", paddingLeft: "4px" }}>
        {t("approval_op")} ({rowData.approval_progress})
      </div>
      <div
        style={{ borderBottom: "solid #F1F2F4 1px", margin: "10px 0 10px 0" }}
      ></div>
      <Table
        aria-label="table"
        sx={{ mb: 3, borderCollapse: "separate", borderSpacing: "0px 4px" }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: "none" }}>{t("seq")}</TableCell>
            <TableCell sx={{ border: "none" }}>{t("operated_by")}</TableCell>
            <TableCell sx={{ border: "none" }}>{t("operated_date")}</TableCell>
            <TableCell sx={{ border: "none" }}>
              {t("approval_result")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows
            .sort((a, b) => a.seq - b.seq)
            .map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  background: "#EAEAEA",
                }}
              >
                <TableCell>{row.seq} </TableCell>
                <TableCell>{row.operated_by}</TableCell>
                <TableCell>{toDisplayTime(row.operated_date)}</TableCell>
                <TableCell
                  style={{
                    color:
                      String(row.resultEnum) == enumOperationType.Withdraw
                        ? "#3dd598"
                        : "#FF0000",
                  }}
                >
                  {row.approval_result}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ApprovalWindow;
