import axios from "./axiosInstance";

export const getCallbackSetting = async () => {
  return await axios.get(`merchant_portal/callback_settings`);
};

export const postSettingStatus = async (settingArray: any) => {
  return await axios.post("merchant_portal/callback_settings/save", {
    data: settingArray,
  });
};
