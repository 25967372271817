import { rebroadcastTxn, rescanTransactions } from "../../api/merchant";
import { useAlerting } from "../../hooks";
import { Button } from "../../components/MuiGenerals";
import RetryIcon from "@mui/icons-material/Replay";
import CellTowerIcon from "@mui/icons-material/CellTower";
import { usePermission } from "../../hooks";
import Tooltip from "@mui/material/Tooltip";
import { useTranslate } from "react-admin";
import {
  GridRenderCellParams,
  DataGrid,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import Container from "@mui/material/Container";
import { useGenGridCol } from "../../utils/HelperComp";
import { FeatureCodes, dataGridDefaults } from "../../utils/constant";
import { NoRowsOverlay } from "../../components/NoRowsOverlay";
import { CustomPagination } from "../../components/CustomPagination";
import { CSSProperties, useState } from "react";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../hooks/useColumnHide";
const Pkey = FeatureCodes.assetManagement.OperationTransactionRecords;
export default function OperationTransactionRecordList(props: any) {
  const translatePrefix = "assetManagement";
  const translate = useTranslate();
  const [hasScanDict, setHasScanDict] = useState<Record<string, boolean>>({});

  const onetimeScan = (tx_hash: string) =>
    setHasScanDict((state) => ({ ...state, [tx_hash]: true }));
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { list, page, setPage, listMapping, refresh } = props;
  const totalRecords = list.count;
  const content: GridRowsProp = listMapping("key", list.rows, translate);
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();

  const IconButton = (p: {
    buttonType: "rescan" | "rebroadcast";
    onClick: () => void;
    isDisabled: boolean;
    style?: CSSProperties;
  }) => {
    const translate = useTranslate();
    const t = (key: string) => translate(`${translatePrefix}.${key}`);
    const { buttonType, style, isDisabled, onClick } = p;
    return (
      <Tooltip title={t(buttonType)} style={style}>
        <span>
          <Button
            onClick={onClick}
            color="secondary"
            variant="contained"
            disabled={isDisabled}
            children={
              <>
                {buttonType === "rescan" && <RetryIcon />}
                {buttonType === "rebroadcast" && <CellTowerIcon />}
              </>
            }
          />
        </span>
      </Tooltip>
    );
  };

  const onRescan = async (
    batch: { chain_id: string | number; chain_type: string | number },
    tx_hash: string
  ) => {
    const { chain_id, chain_type } = batch || {};
    const res = await rescanTransactions({
      chain_id,
      chain_type,
      tx_hashes: [tx_hash],
    });
    if (!res) return;
    onetimeScan(tx_hash);
    alerting("success", t("rescan_transaction_succeeded"));
  };

  const onRebroadcast = async (batch_id: string, tx_hash: string) => {
    const res = await rebroadcastTxn({ batch_id, tx_hash });
    if (!res) {
      return;
    }
    refresh();
    alerting("success", t("rebroadcast_transaction_succeeded"));
  };
  const cellButtons = (params: GridRenderCellParams) => {
    const theRow =
      list.rows.find((item: any) => item.tx_hash === params.row?.tx_hash) || {};
    const { status, batch, tx_hash, batch_id } = theRow;

    const isStatusEnable = [1, 2, 3].includes(status);
    const isRebroadcastDisabled = status !== -2 && status !== 2;

    return (
      <>
        {hasPermission(Pkey.Rescan) && (
          <IconButton
            buttonType={"rescan"}
            onClick={() => onRescan(batch, tx_hash)}
            isDisabled={!isStatusEnable || hasScanDict[tx_hash]}
            style={{
              marginRight: hasPermission(Pkey.ReBroadcast) ? "12px" : undefined,
            }}
          />
        )}
        {hasPermission(Pkey.ReBroadcast) && (
          <IconButton
            buttonType={"rebroadcast"}
            onClick={() => onRebroadcast(batch_id, tx_hash)}
            isDisabled={isRebroadcastDisabled}
          />
        )}
      </>
    );
  };
  const showAllBtns =
    hasPermission(Pkey.Rescan) && hasPermission(Pkey.ReBroadcast);
  const columns: GridColDef[] = [
    useGenGridCol("1", t("operation"), {
      renderCell: cellButtons,
      minWidth: showAllBtns ? 170 : 120,
    }),
    useGenGridCol("batch_id", t("batch_id"), {}),
    useGenGridCol("chain_name", t("chain_name"), {}),
    useGenGridCol("step_seq", t("step_seq"), {}),
    useGenGridCol("tx_seq", t("tx_seq"), {
      minWidth: 130,
    }),
    useGenGridCol("status", t("status"), {
      minWidth: 170,
    }),
    useGenGridCol("tx_status", t("tx_status"), {
      minWidth: 130,
    }),
    useGenGridCol("operation_type", t("operation_type"), {}),
    useGenGridCol("asset_name", t("asset_name"), {}),
    useGenGridCol("tx_hash", t("tx_hash"), {
      minWidth: 420,
    }),
    useGenGridCol("error_reason", t("error_reason"), {
      minWidth: 300,
    }),
    useGenGridCol("remark", t("remark"), {
      minWidth: 300,
    }),
    useGenGridCol("created_date", t("created_date"), {}),
    useGenGridCol("last_modified_date", t("last_modified_date"), {}),
  ].filter((col) => {
    if (
      col.field === "1" &&
      !hasPermission(Pkey.Rescan) &&
      !hasPermission(Pkey.ReBroadcast)
    ) {
      return false;
    }
    return true;
  });
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={list.count}
        columns={columns}
        page={page}
        onPageChange={setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
}
