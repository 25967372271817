import { isValid } from "date-fns";

import { SxProps } from "@mui/system";

import { useTranslation } from "../hooks";
import { EnumHoursStart24HrsFormat } from "../utils/constant";
import { toDBTime } from "../utils/helper";
import TimePicker from "./DatePicker";
import { SingleSelection } from "./GeneralSelection";
import { Box } from "./MuiGenerals";

type DateHourSelectionProps = {
  customDateLabel?: string;
  customTimeLabel?: string;
  setDateHour: (value: string) => void;
  dateValue: Date | null;
  setDateValue: (value: Date | null) => void;
  maxStartTime?: Date;
  minEndTime?: Date;

  timeValue: string;
  setTimeValue: (value: string) => void;
  clearTimeValue: () => void;
  hourEnum?: Record<string, string>;
  isEndOfSecond?: boolean;
  disableFuture?: boolean;
  disabledHoursList?: string[];
  isTimezoneConvert?: boolean;
  sx?: {
    datePicker?: SxProps;
    hourPicker?: { formControl?: SxProps; select?: SxProps };
  };
};

export default function DateHourSelection(props: DateHourSelectionProps) {
  const {
    customDateLabel,
    customTimeLabel,
    setDateHour,
    dateValue,
    setDateValue,
    maxStartTime,
    minEndTime,
    timeValue,
    setTimeValue,
    clearTimeValue,
    hourEnum = EnumHoursStart24HrsFormat,
    disableFuture,
    disabledHoursList,
    isTimezoneConvert,
    isEndOfSecond,
    sx,
  } = props;

  const dateStr = dateValue?.toDateString();

  const { t } = useTranslation("common");

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <TimePicker
        label={customDateLabel || t("date")}
        type={"date"}
        timeValue={dateValue}
        maxStartTime={maxStartTime}
        minEndTime={minEndTime}
        setTimeValue={(value: Date | null) => {
          setDateValue(value);
          setTimeValue("");
          setDateHour("");
        }}
        disableFuture={disableFuture}
        notPassingSxToFreeInput
        sx={{
          marginRight: "8px",
          width: "calc((100% - 32.047px) / 2 + 8px) !important",
          ...sx?.datePicker,
        }}
      />

      <SingleSelection
        disabled={!dateValue}
        label={customTimeLabel || t("time")}
        value={timeValue}
        onChange={(e) => {
          setTimeValue(e.target.value);

          const combinedDateTime = new Date(`${dateStr} ${e.target.value}`);

          const selectedDateTime = isValid(combinedDateTime)
            ? combinedDateTime
            : null;

          const dbFormatDateHrStr = toDBTime(
            selectedDateTime,
            isTimezoneConvert
          );

          const finalDateHrStr = isEndOfSecond
            ? dbFormatDateHrStr
              ? dbFormatDateHrStr.slice(0, -4) + "999Z"
              : ""
            : dbFormatDateHrStr;

          setDateHour(finalDateHrStr);
        }}
        enumData={hourEnum}
        nameFn={(name) => name}
        clearSelect={clearTimeValue}
        sx={{
          formControl: {
            width: "calc((100% - 32.047px) / 2 + 8px) !important",
            ...sx?.hourPicker?.formControl,
          },
          select: { width: "100%", ...sx?.hourPicker?.select },
        }}
        disabledItemList={disabledHoursList}
      />
    </Box>
  );
}
