import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AlerterState {
  open?: boolean;
  text: string;
  severity: "error" | "warning" | "info" | "success";
  autoHideDuration?: number | null; // ms, 1000= 1 second
  redirectTo?: string;
}

const initialState: AlerterState = {
  open: false,
  text: "",
  severity: "error",
  redirectTo: "",
  autoHideDuration: 30000,
};

const getAutoHideDurationValue = (duration?: number | null) => {
  if (duration === null) return null;

  if (typeof duration === "number") return duration;

  return 30000;
};

export const alerterSlice = createSlice({
  name: "alerter",
  initialState,
  reducers: {
    open: (state, action: PayloadAction<AlerterState>) => {
      state.open = true;
      state.text = action.payload.text;
      state.severity = action.payload.severity;
      state.autoHideDuration = getAutoHideDurationValue(
        action.payload.autoHideDuration
      );
    },
    close: (state) => {
      state.open = false;
      state.text = "";
    },
    setRedirectTo: (state, action: PayloadAction<string>) => {
      state.redirectTo = action.payload;
    },
  },
});
export const alerting = (
  severity: AlerterState["severity"],
  text: string,
  config?: object
) =>
  open({
    text,
    severity,
    ...config,
  });

export const { open, close, setRedirectTo } = alerterSlice.actions;
export const selectAlerter = (state: RootState) => state.alerter;

export default alerterSlice.reducer;
