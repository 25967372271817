import {
  Container,
  Box,
  InputAdornment,
} from "../../../components/MuiGenerals";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { usePermission, useAlerting, useYubiPrompt } from "../../../hooks";
import { customSx } from "../../../utils/styling";
import { DivideLine, genField } from "../../../utils/HelperComp";

import GeneralBtn from "../../../components/GeneralBtn";
import MpTextField from "../../../components/MpTextField";
import {
  getWithdrawCheckingTimeControl,
  getWithdrawCheckingTimeControlRequest,
  postWithdrawCheckingTimeControlRequest,
} from "../../../api/signer";
import { useZusDialogStore } from "../../../zustand/store";
import { FeatureCodes } from "../../../utils/constant";
import YubiPrompter from "../../../components/Prompter";
const Pkey =
  FeatureCodes.assetManagement.WithdrawApprovalCheckingTimeSetting.TabSetting;
interface FieldsFace {
  checking_time: string;
}
const initFields: FieldsFace = {
  checking_time: "",
};

const sx = {
  wallet_name: {
    fontWeight: "bold",
  },
  filterWarning: {
    pt: 3,
    color: "red",
  },
  preview: {
    maxWidth: "10rem",
    margin: " 0 auto",
  },
  previewHeader: {
    display: "flex",
    margin: "auto",
    maxWidth: "40rem",
    justifyContent: "space-evenly",
  },
  previewActions: {
    display: "flex",
    justifyContent: "space-around",
  },
  buttonsGroup: {
    display: "flex",
    justifyContent: "start",
    gap: "2rem",
    marginTop: "1.5rem",
    marginBottom: "2rem",
  },
};

export default function CheckingTimeSettingTab() {
  const [list, setList] = useState<any[]>([]);
  const [fields, setFields] = useState<FieldsFace>(initFields);

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar fields={fields} setFields={setFields} setList={setList} />
      </Container>
      {list.length > 0 && (
        <>
          <DivideLine />
          <Container
            style={customSx.datagridContainer}
            maxWidth={false}
            disableGutters
          ></Container>
        </>
      )}
    </Box>
  );
}
function getParams(fields: FieldsFace) {
  const { checking_time } = fields;
  return {
    checking_time,
  };
}
export function FilterBar(props: any) {
  const { fields, setFields } = props;
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string, params = {}) =>
    translate(`withdrawApprovalCheckingTimeSetting.${key}`, params);
  const { alerting } = useAlerting();
  const params = getParams(fields);
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.trim();

      if (value[0] === "0" || !/^[0-9]*$/.test(value)) {
        return;
      }

      setFields((fields: any) => ({ ...fields, [type]: value }));
    };

  const onEdit = async () => {
    const result = await getWithdrawCheckingTimeControlRequest({
      statuses: ["0"],
    });
    if (!result) return;
    else if (!!result.rows.length)
      return alerting("warning", t("hasPendingRequest"));
    setInView(false);
  };
  const onSave = async () => {
    if (Number(fields.checking_time) > 24) {
      return alerting("info", t("limited24Hours"));
    }
    if (!params.checking_time) {
      const message = `Input cannot be empty`;
      return alerting("warning", message);
    }

    const result = await getResAfterYubi(
      postWithdrawCheckingTimeControlRequest,
      {
        withdrawCheckingTime: Number(params.checking_time),
      }
    );

    if (!result) return;
    setInView(true);
    alerting("success", t("success"));
  };
  const onCancel = () => {
    setFields(initFields);
    setInView(true);
  };
  const [isFocused, setIsFocused] = useState(false);
  const [inView, setInView] = useState(true);
  const [withdrawCheckingTime, setWithdrawCheckingTime] = useState<number>(-1);

  useEffect(() => {
    const getWithdrawCheckingTimeApi = async () => {
      zusDialog.openExtra("loadingDialog");
      const result = await getWithdrawCheckingTimeControl();
      zusDialog.closeExtra();
      if (!result) return;
      setWithdrawCheckingTime(result.withdraw_checking_time);
    };
    getWithdrawCheckingTimeApi();
  }, []);

  const F = genField({ t }, [
    [
      "checkingTime",
      inView ? (
        <div style={{ height: "100%", paddingTop: "7px" }}>
          {withdrawCheckingTime > 0
            ? `${withdrawCheckingTime} ${
                withdrawCheckingTime > 1 ? t("hours") : t("hour")
              }`
            : undefined}
        </div>
      ) : (
        <MpTextField
          label={t("phCheckingTime")}
          InputProps={{
            endAdornment: (isFocused || fields.checking_time) && (
              <InputAdornment position="end" style={{ paddingTop: 16 }}>
                {fields.checking_time === "" || fields.checking_time === "1"
                  ? t("hour")
                  : t("hours")}
              </InputAdornment>
            ),
          }}
          style={{ height: "55px" }}
          value={fields.checking_time}
          onChange={onChange("checking_time")}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      ),
    ],
  ]);

  return (
    <Container disableGutters maxWidth={false}>
      <YubiPrompter {...prompterConfig} />
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.checkingTime}
      </Box>

      <Box sx={sx.buttonsGroup}>
        {inView && hasPermission(Pkey.Edit) && (
          <GeneralBtn onClick={onEdit} label="edit" />
        )}
        {!inView && <GeneralBtn onClick={onSave} label="confirm" />}
        {!inView && (
          <GeneralBtn
            onClick={onCancel}
            label="cancel"
            color="error"
            rest={{ style: { marginLeft: "40px" } }}
          />
        )}
      </Box>
    </Container>
  );
}
