export enum TableColumnKey {
  Operation = "operation",
  BatchId = "batch_id",
  OperationType = "operation_type",
  ChainName = "chain_name",
  AssetName = "asset_name",
  TotalAmount = "total_amount",
  TotalOperation = "total_operation",
  Status = "status",
  CreatedBy = "created_by",
  CreatedDate = "created_date",
}

export interface TableRow {
  id: number;
  [TableColumnKey.BatchId]: string;
  [TableColumnKey.OperationType]: string;
  [TableColumnKey.ChainName]: string;
  [TableColumnKey.AssetName]: string;
  [TableColumnKey.TotalAmount]: string;
  [TableColumnKey.TotalOperation]: number;
  [TableColumnKey.Status]: string;
  [TableColumnKey.CreatedBy]: string;
  [TableColumnKey.CreatedDate]: string;
  rawData: any;
}
