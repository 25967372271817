import omit from "lodash/omit";
import {
  Ipage,
  IoperationBatchStatus,
  IgetWithdrawRequestParams,
} from "../../api/types";
import { MultiSelection } from "../../components/GeneralSelection";
import GeneralBtn from "../../components/GeneralBtn";
import {
  InputAdornment,
  Container,
  Box,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import WithdrawRequestRecordList from "./WithdrawRequestRecordList";
import { useEffect, useState } from "react";
import { useDatePicker } from "../../components/DatePicker";
import {
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  separateBatchStrings,
  toDisplayTime,
  listMappingTransform,
  enumMapping,
} from "../../utils/helper";
import {
  tableConfig,
  enumNewOperationBatchStatus,
  FeatureCodes,
} from "../../utils/constant";
import { getWithdrawRequest } from "../../api/merchant";
import { useAssets, useChains } from "../../components/FetchConfig";
import { customSx } from "../../utils/styling";
import { DivideLine, genField } from "../../utils/HelperComp";
import {
  useTabs,
  usePermission,
  useTranslation,
  useAlerting,
} from "../../hooks";
import { useQuery } from "react-query";
import { createZusInstance } from "../../zustand/store";
import MpTextField from "../../components/MpTextField";
import LoadingDialog from "../../components/LoadingDialog";
import { getMinerFeeCurrency } from "./helpers/getMinerFeeCurrency";
import { getMinerFee } from "./helpers/getMinerFee";

const Pkey = FeatureCodes.assetManagement.WithdrawRequestRecord;
const translatePrefix = "withdrawRequestRecord";
const Enum = {
  operationBatchStatus: enumMapping(enumNewOperationBatchStatus),
};

export default function WithdrawRequestRecord() {
  const { tc } = useTranslation(translatePrefix);
  const tabsArr = [{ name: tc("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <WithdrawRequestRecordList listMapping={listMapping} />
        </Tabs>
      </Container>
    </Box>
  );
}
interface Ifields {
  chain_name: string;
  operationStatuses: IoperationBatchStatus[];
  asset_names: string[];
  batchIdsString: string;
  merchantIdsString: string;
}
const initFields = {
  chain_name: "",
  operationStatuses: [] as IoperationBatchStatus[],
  asset_names: [] as string[],
  batchIdsString: "",
  merchantIdsString: "",
} as const;

const initZusParams = {
  date_from: "",
  date_to: "",
  batch_ids: [] as string[],
  merchant_order_ids: [] as string[],
  page: 0,
  pageSize: tableConfig.pageSize,
  ...omit(initFields, ["batchIdsString", "merchantIdsString"]),
};
export const useZusParams = createZusInstance<
  IgetWithdrawRequestParams & Ipage
>(initZusParams);
function FilterBar() {
  const DateObj = useDatePicker();
  const { hasPermission } = usePermission();
  const [fields, setFields] = useState<Ifields>(initFields);
  const { selectAssetNamesByChain } = useAssets();
  const { selectChainByPieces } = useChains();
  const asset_names = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const { alerting } = useAlerting();
  const { t, te, tc } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  useEffect(() => setAssets([]), [fields.chain_name]);
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  function getParams() {
    const { batchIdsString, merchantIdsString, chain_name, ...rest } = fields;
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    return {
      ...rest,
      batch_ids: separateBatchStrings(fields.batchIdsString),
      merchant_order_ids: separateBatchStrings(fields.merchantIdsString),
      chain_id,
      chain_type: String(chain_type || ""),
      date_from: DateObj.start,
      date_to: DateObj.end,
      page: 0,
    };
  }
  const apiParams = getParams();
  const onSearch = () => {
    zusParams.setBody(apiParams);
    zusParams.refetch();
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.clearDate();
  };
  const listRes = useQuery({
    queryKey: ["getWithdrawRequest", zusParams.body, zusParams.refetchCounter],
    queryFn: () => getWithdrawRequest(zusParams.body),
  });
  const onExport = async () => {
    const count = listRes.data?.count || 0;
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getWithdrawRequest({ ...apiParams, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { t, te, omitKeys: ["rawData"] });
    const config = {};
    downloadFiles(`Withdraw Request Records`, res, config);
  };

  const onArraySelectChange =
    (key: keyof Ifields) => (e: SelectChangeEvent<string[]>) => {
      const { value } = e.target;
      const newValue = typeof value === "string" ? value.split(",") : value;
      return setFields((fields) => ({ ...fields, [key]: newValue }));
    };
  const onFilterChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const F = genField({ t }, [
    ["created_date", <DateObj.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("ph_asset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={asset_names}
      />,
    ],
    [
      "status",
      <MultiSelection
        label={t("ph_status")}
        value={fields.operationStatuses}
        onChange={onArraySelectChange("operationStatuses")}
        enumData={enumNewOperationBatchStatus}
      />,
    ],
    [
      "batch_id",
      <MpTextField
        label={t("ph_batch_id")}
        value={fields.batchIdsString}
        onChange={onChange("batchIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {apiParams.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextField
        label={t("ph_merchant_order_id")}
        value={fields.merchantIdsString}
        onChange={onFilterChange("merchantIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {apiParams.merchant_order_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.status}
        {F.chain_name}
        {F.asset_name}
        {F.batch_id}
        {F.merchant_order_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: { t: any; te: any; omitKeys?: string[] }
): any[][] => {
  const { t, te, omitKeys = [] } = config;
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const minerFeeDecimal = getDecimal({
      chain_type: item.batch.chain_type,
      chain_id: item.batch.chain_id,
      asset_name: item.batch.miner_fee_asset_name,
    });

    const toAmount = (amount: number | string, dec: string = decimal) =>
      displayAmount(amount, dec);

    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["hot_wallet_address", t("from_address"), item.hot_wallet_address],
      ["status", t("status"), te(Enum.operationBatchStatus[item.batch.status])],
      [
        "miner_fee_currency",
        t("miner_fee_currency"),
        getMinerFeeCurrency(item.batch.status, item.batch.miner_fee_asset_name),
      ],
      [
        "miner_fee",
        t("miner_fee"),
        getMinerFee(
          item.batch.status,
          toAmount(item?.batch?.miner_fee, minerFeeDecimal)
        ),
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["total_amount", t("total_amount"), toAmount(item.total_amount)],
      ["actual_amount", t("actual_amount"), toAmount(item.batch.actual_amount)],

      ["total_count", t("total_count"), item.total_count],
      ["created_by", t("created_by"), item?.batch?.created_by],
      [
        "created_date",
        t("created_date"),
        toDisplayTime(item?.batch?.created_date),
      ],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item?.batch?.last_modified_date),
      ],
      ["rawData", "", item],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
