import { useTranslate } from "react-admin";
import { OpEdit } from "../../../assets/icons";
import OpIconButton from "../../../components/OpIconButton";
import { useAssetListStore, useZusDialogStore } from "../../../zustand/store";
import { IProps } from "./assetListTypes";

const Operation = (props: IProps) => {
  const zusDialog = useZusDialogStore();
  const setRecord = useAssetListStore((state) => state.setRecord);
  const setCurrentStatus = useAssetListStore((state) => state.setCurrentStatus);
  const translate = useTranslate();
  const t = (key: string) => translate(`assetList.${key}`);
  const { params } = props;
  const handleOpenDialog = () => {
    const {
      chain_name,
      asset_name,
      rawData,
      withdraw_status,
      push_deposit_message,
      asset_config_id,
    } = params.row;

    setRecord({
      chain_name,
      asset_name,
      rawData,
      withdraw_status,
      push_deposit_message,
      asset_config_id,
    });
    setCurrentStatus(withdraw_status);
    zusDialog.open("assetListOpDialog");
  };

  return (
    <OpIconButton title={t("edit")} url={OpEdit} onClick={handleOpenDialog} />
  );
};

export default Operation;
