import React, { useEffect, useState } from "react";

import { useTranslate } from "react-admin";
import {
  useYubiKeysStoreForReg,
  useZusListStore,
  zusRefetchStore,
} from "../../../zustand/store";
import { Box, Tab, Tabs } from "../../../components/MuiGenerals";

import YubiRegister from "./YubiRegister";
import YubikeyAlteration from "./YubikeyAlteration";
import { getMyYubiKeyStaus } from "../../../api/auth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const YubiKeysReg = () => {
  const [tabsIndex, setTabsIndex] = React.useState(0); //the default index :which tab
  const setMyYubiDetails = useYubiKeysStoreForReg(
    (state) => state.setYuBiDetails
  );

  const refetchBool = zusRefetchStore((state) => state.yubiKeyRegPage);
  const myYubiDetail = useYubiKeysStoreForReg((state) => state.myDetail);
  const clearZusList = useZusListStore((state) => state.clear);
  const [loading, setLoading] = useState(true);
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);

  const handleChange = (event: React.SyntheticEvent, tabsEnum: number) => {
    setTabsIndex(tabsEnum);
    clearZusList();
  };

  useEffect(() => {
    const asyncWrapper = async () => {
      const data: any = await getMyYubiKeyStaus();
      if (!data) return;
      setMyYubiDetails(data);
      setLoading(false);
    };
    asyncWrapper();
  }, [refetchBool]);

  const operationTabs = [
    {
      name: "yubikey_alteration_tab",
      jsxNode: <YubikeyAlteration />,
      bindedStatus: 1,
    },
    {
      name: "yubikey_register_tab",
      jsxNode: <YubiRegister />,
      pendingStatus: 0,
      unbindedStatus: -1,
    },
  ].filter((item) => {
    if (myYubiDetail?.yubi_key_status === item.bindedStatus) {
      return true;
    }
    if (
      myYubiDetail?.yubi_key_status === item.pendingStatus ||
      myYubiDetail?.yubi_key_status === item.unbindedStatus
    ) {
      return true;
    }
    return false;
  });
  if (loading) return <></>;
  //
  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {operationTabs.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {/* {- -Tab ContentComponent- - -} */}
      {operationTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default YubiKeysReg;
