import { addHours } from "date-fns";
import subByTime from "date-fns/sub";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import {
  getOperationAll,
  getOperationApi,
  getOperationPortal,
  getOperationSweep,
} from "../../../api/merchant";
import { MerchantOperationsParamsFace } from "../../../api/types";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useAssets, useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import MpTextField from "../../../components/MpTextField";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import { useAlerting, usePermission } from "../../../hooks";
import { useAppSelector } from "../../../reducer/hooks";
import { selectProfile } from "../../../reducer/profileSlice";
import {
  batchIdUrlString,
  enumNewApprovalStatus,
  enumNewCreatorType,
  enumNewMerchantOperationStatus,
  enumOperationType,
  FeatureCodes,
  tableConfig,
} from "../../../utils/constant";
import {
  downloadFiles,
  enumMapping,
  getFullApiResponse,
  separateBatchStrings,
  sortAlphabetically,
  toDisplayTime,
} from "../../../utils/helper";
import { genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { useZusDialogStore, useZusTabsStore } from "../../../zustand/store";
import { IoperationTab } from "./MerchantOperation";
import StatusButtonGroup from "./StatusButtonGroup";

const Pkey = FeatureCodes.assetManagement.MerchantOperation;
const Enum = {
  approvalStatus: enumMapping(enumNewApprovalStatus),
};
const tabApis = {
  all: getOperationAll,
  api_withdraw_tab: getOperationApi,
  portal_withdraw_tab: getOperationPortal,
  sweep_tab: getOperationSweep,
} as const;
export default function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`assetManagement.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const profile = useAppSelector(selectProfile);

  const { setList, setPage, page, refreshBatchTable, count, listMapping } =
    props;
  const operationTab = props.operationTab as IoperationTab;
  const zusTabsStore = useZusTabsStore((state) => state);
  const operationTypeEnum =
    operationTab === "api_withdraw_tab"
      ? "1"
      : operationTab === "portal_withdraw_tab"
      ? "1"
      : operationTab === "sweep_tab"
      ? "2"
      : "";
  const operationCreatorFilterEnum =
    operationTab === "api_withdraw_tab"
      ? "1"
      : operationTab === "portal_withdraw_tab"
      ? "2"
      : operationTab === "sweep_tab"
      ? "3"
      : "0";

  function getNow() {
    const timezone = profile.timezone;
    const defaultTimezone = (new Date().getTimezoneOffset() * -1) / 60;
    const totalDiff = Number(timezone) - defaultTimezone;
    return addHours(new Date(), totalDiff);
  }

  const now = getNow();

  const oneMonthAgo = new Date(
    subByTime(now, { months: 1 }).toDateString() + ", 00:00:00"
  );
  const endOfToday = new Date(now.toDateString() + ", 23:59:59");
  const defaultCreatedDates = {
    start: { defaultDate: oneMonthAgo },
    end: { defaultDate: endOfToday },
  };
  const DateObj = {
    Created: useDatePicker(defaultCreatedDates),
    Modified: useDatePicker(),
  };
  const rawSearchParams = {
    batchId: [],
    chain_name: "",
    asset_names: [],
    operation_type: operationTypeEnum,
    operation_creator_filter: operationCreatorFilterEnum,
    status: zusTabsStore.gridTabsEumn === 1 ? ["4"] : [],
    callback_status: [],
    merchant_id: "",
    creator_type: [],
    approval_completed: "",
    created_date_from: DateObj.Created.start || undefined,
    created_date_to: DateObj.Created.end || undefined,
    last_modified_date_from: DateObj.Modified.start || undefined,
    last_modified_date_to: DateObj.Modified.end || undefined,
  };

  const urlBatchId: any = window.location.search.split(batchIdUrlString).pop();
  const initSearchParams = urlBatchId
    ? { ...rawSearchParams, batchId: [urlBatchId] }
    : { ...rawSearchParams };
  const [fields, setFields] =
    useState<MerchantOperationsParamsFace>(initSearchParams);
  const [apiParams, setApiParams] =
    useState<MerchantOperationsParamsFace>(initSearchParams);

  useEffect(() => {
    let theStatus = [] as string[];
    if (zusTabsStore.gridTabsEumn == 1) {
      setFields((fields) => ({
        ...fields,
        status: ["3", "5"],
      }));
      theStatus = ["3", "5"];
    } else if (zusTabsStore.gridTabsEumn == 2) {
      setFields((fields) => ({
        ...fields,
        status: ["-3", "-2", "-1", "4"],
      }));
      theStatus = ["-3", "-2", "-1", "4"];
    } else if (zusTabsStore.gridTabsEumn == 0) {
      setFields((fields) => ({ ...fields, status: [] }));
      theStatus = [];
    }
    DateObj.Created.resetDate();
    const timer = setTimeout(() => {
      //runs search() with latest state and params
      const dom = document.querySelector('[label="search"]') as HTMLElement;
      dom.click();
    }, 100);
    setPage(0);
    return () => {
      clearTimeout(timer);
      setFields((fields) => ({ ...fields, status: [] }));
    };
  }, [zusTabsStore.gridTabsEumn]);
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });

  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  const getParams = () => {
    const { chain_name, batchId, merchant_id, operation_type, ...rest } =
      apiParams;
    const merchant_ids = separateBatchStrings(merchant_id);
    const { chain_id, chain_type } = selectChainByPieces({ chain_name });
    return {
      ...rest,
      batchIdArr: batchId,
      merchant_order_ids: merchant_ids,
      chain_id,
      chain_type,
      page,
      pageSize: tableConfig.pageSize,
      operation_type: [...operation_type],
    };
  };
  const zusDialog = useZusDialogStore();
  const params = getParams();
  const theFetchApi = tabApis[operationTab];
  const apiRes = useQuery(
    ["getOperation", params, refreshBatchTable],
    () => {
      zusDialog.openExtra("loadingDialog");
      return theFetchApi(params);
    },
    {
      onSettled: () => zusDialog.closeExtra(),
      onSuccess: (data) => {
        if (!data) return;
        setList(data);
      },
    }
  );

  const refetch = () => setTimeout(apiRes.refetch, 0);

  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);

  const onSearch = async () => {
    setPage(0);
    setApiParams({
      ...fields,
      created_date_from: DateObj.Created.start || undefined,
      created_date_to: DateObj.Created.end || undefined,
      last_modified_date_from: DateObj.Modified.start || undefined,
      last_modified_date_to: DateObj.Modified.end || undefined,
    });
    refetch();
  };

  const onReset = () => {
    setFields({ ...rawSearchParams, status: [] });
    DateObj.Created.clearDate();
    DateObj.Modified.clearDate();
  };

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      theFetchApi({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const transformApprovals = ({
      transaction_approval_details,
      ...item
    }: any) => {
      const approvals = [0, 1, 2, 3].map((i) => {
        // const details = transaction_approval_details[i] || {};
        const details =
          transaction_approval_details.find((d: any) => d?.seq === i + 1) || {};
        const position = t("position" + (i + 1));
        return {
          [`${position} ${t("operated_by")}`]: details.operated_by || "",
          [`${position} ${t("approval_result")}`]:
            Enum.approvalStatus[details.approval_result] || "",
          [`${position} ${t("operated_date")}`]: toDisplayTime(
            details.operated_date
          ),
        };
      });
      return { ...item, ...Object.assign({}, ...approvals) };
    };
    const omitKeys = ["rawData", "chain_id", "chain_type"];
    const res = listMapping("name", rawRes, { translate, omitKeys }).map(
      transformApprovals
    );
    const config = {};
    downloadFiles(`Merchant Operation`, res, config);
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "operation_type") {
      setFields((fields) => ({
        ...fields,
        operation_type: [],
      }));
    }
  };

  const onFilter =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let { value }: any = e.target;
      const newValue =
        value == ""
          ? value
          : typeof value === "string"
          ? value.split(",")
          : value;
      setFields((fields) => ({ ...fields, [type]: newValue }));
    };

  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;

    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    return setFields((fields: any) => ({
      ...fields,
      [type]: value,
    }));
  };
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onFilterChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const F = genField({ t }, [
    ["created_date", <DateObj.Created.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "operation_type",
      <FormControl>
        <InputLabel>{t("phOperation_type")}</InputLabel>
        <Select
          name="operation_type"
          value={fields.operation_type}
          onChange={onSelectChange("operation_type")}
        >
          {Object.entries(enumOperationType).map(([name, value]) => {
            const newName = name as keyof typeof enumOperationType;
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={(e) => handleClick(e, value, "operation_type")}
              >
                {te(`${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
    [
      "status",
      <FormControl>
        <InputLabel>{t("phStatus")}</InputLabel>
        <Select
          name="status"
          multiple
          value={fields.status}
          onChange={onArraySelectChange}
        >
          {sortAlphabetically(
            Object.entries(enumNewMerchantOperationStatus)
          ).map(([name, id]: string[], i) => (
            <MenuItem key={i} value={id}>
              {te(name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "batch_id",
      <MpTextField
        fullWidth={true}
        name="batchId"
        label={t("phBatch_id")}
        value={fields.batchId}
        onChange={onFilter("batchId")}
      />,
    ],
    [
      "approval_completed",
      <MpTextField
        fullWidth={true}
        name="approval_completed"
        label={t("ph_approval_completed")}
        value={fields.approval_completed}
        onChange={onChange("approval_completed")}
      />,
    ],
    [
      "merchant_order_id",
      <MpTextField
        name="merchant_id"
        label={t("phMerchant_id")}
        value={fields.merchant_id}
        onChange={onFilterChange("merchant_id")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.merchant_order_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "creator_type",
      <FormControl>
        <InputLabel>{t("phCreator_type")}</InputLabel>
        <Select
          name="creator_type"
          multiple
          value={fields.creator_type}
          onChange={onArraySelectChange}
        >
          {sortAlphabetically(Object.entries(enumNewCreatorType)).map(
            ([name, id]: any, i) => (
              <MenuItem key={i} value={id}>
                {te(name)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>,
    ],
    ["last_modified_time", <DateObj.Modified.Picker type="dateTime" />],
  ]);
  const showExportButton =
    (operationTab === "all" && hasPermission(Pkey.TabAll.Export)) ||
    (operationTab === "api_withdraw_tab" &&
      hasPermission(Pkey.TabApiWithdraw.Export)) ||
    (operationTab === "portal_withdraw_tab" &&
      hasPermission(Pkey.TabPortalWithdraw.Export)) ||
    (operationTab === "sweep_tab" && hasPermission(Pkey.TabSweep.Export));
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1rem",
          marginBottom: "1rem",
          "& .MuiButton-root": {
            color: "black",
            borderColor: "#AAAAAA",
          },
        }}
      >
        <div>
          <StatusButtonGroup />
        </div>
        <div>
          <GeneralBtn
            label="export"
            isHidden={!showExportButton}
            onClick={onExport}
          />
        </div>
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.last_modified_time}
        {F.chain_name}
        {F.asset_name}
        {F.status}
        {F.batch_id}
        {F.merchant_order_id}
        {operationTab === "all" && F.operation_type}
        {operationTab == "sweep_tab"
          ? F.creator_type
          : operationTab == "all"
          ? F.creator_type
          : ""}
        {F.approval_completed}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}
