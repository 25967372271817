import { useState } from "react";

import { Ipage } from "../../../api/types";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { useDatePicker } from "../../../components/DatePicker";
import { useChains } from "../../../components/FetchConfig";
import GeneralBtn from "../../../components/GeneralBtn";
import { MultiSelection } from "../../../components/GeneralSelection";
import MpTextField from "../../../components/MpTextField";
import {
  Box,
  Container,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import { useTranslation } from "../../../hooks";
import {
  enumOperationTransactionStatus,
  enumOperationTransactionStatusNonceTool,
  enumOperationType,
  enumPortalUserOperStatus,
  enumPortalUserYubiKeyStatus,
} from "../../../utils/constant";
import {
  enumMapping,
  findChainInfo,
  findEnumKey,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { createZusInstance } from "../../../zustand/store";
import NonceTabList from "./NonceTabList";

const translatePrefix = "NonceTool";
const Enum = {
  portalUserYubiKeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
  portalUserOperStatus: enumMapping(enumPortalUserOperStatus),
};

interface Ifields {
  chain_name: string;
  sending_address: string;
  statuses: string[];
  batch_id: string;
}
interface ZusParamsFace {
  chain_type: number;
  chain_id: string;
  from_addresses: string[];
  statuses: string[];
  batch_ids: string[];
  created_date_from: string;
  created_date_to: string;
}

const initFields = {
  chain_name: "",
  sending_address: "",
  statuses: [] as string[],
  batch_id: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
  created_date_from: "",
  created_date_to: "",
} as const;

export const useZusParams = createZusInstance<Ipage & Partial<ZusParamsFace>>(
  initZusParams
);

export default function NonceTab() {
  return (
    <>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <NonceTabList {...{ listMapping }} />
        </TableTab>
      </Container>
    </>
  );
}

export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const { selectChainByPieces } = useChains();
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onArraySelectChange =
    (name: keyof Ifields) => (e: SelectChangeEvent<string[]>) => {
      const { value } = e.target;
      const newValue = typeof value === "string" ? value.split(",") : value;
      return setFields((fields) => ({ ...fields, [name]: newValue }));
    };
  const onSelectChange =
    (type: keyof Ifields) => (e: SelectChangeEvent<string>) => {
      const { value } = e.target;
      return setFields((fields: any) => ({
        ...fields,
        [type]: value,
      }));
    };
  const unselectClicked = (key: keyof Ifields) => {
    setFields((fields) => ({ ...fields, [key]: initFields[key] }));
  };
  const onSearch = () => {
    const { batch_id, chain_name, sending_address, statuses } = fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const { start: created_date_from, end: created_date_to } =
      DateObj.CreationTime;
    zusParams.setBody({
      statuses,
      from_addresses: sending_address ? [sending_address] : [],
      batch_ids: batch_id ? [batch_id] : [],
      chain_id,
      chain_type,
      created_date_from,
      created_date_to,
      page: 0,
    });
    zusParams.refetch();
  };
  const DateObj = {
    CreationTime: useDatePicker(),
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        onlyETHAndXRP
        label={t("ph_chain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],

    [
      "sending_address",
      <MpTextField
        value={fields.sending_address}
        onChange={onChange("sending_address")}
        label={t("ph_sending_address")}
      />,
    ],

    [
      "status",
      <MultiSelection
        label={t("ph_status")}
        value={fields.statuses}
        onChange={onArraySelectChange("statuses")}
        enumData={enumOperationTransactionStatusNonceTool}
      />,
    ],
    [
      "batch_id",
      <MpTextField
        value={fields.batch_id}
        onChange={onChange("batch_id")}
        label={t("ph_batch_id")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.chain_name}
        {F.sending_address}
        {F.status}
        {F.batch_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    const chainInfo = findChainInfo(item.chain_type, item.chain_id);
    const mappedResult = [
      ["batch_id", t("batch_id"), item.batch_id],
      ["chain_name", t("chain_name"), chainInfo?.name],
      ["step_sequence", t("step_sequence"), item.step_seq],
      ["transaction_sequence", t("transaction_sequence"), item.tx_seq],
      ["sending_address", t("sending_address"), item.from_address],
      [
        "status",
        t("status"),
        te(`${enumOperationTransactionStatus[String(item.status)]}`),
      ],
      ["nonce", t("nonce"), item.nonce],
      [
        "operation_type",
        t("operation_type"),
        te(`${findEnumKey(enumOperationType, item.operation_type)}`),
      ],
      ["transaction_hash", t("transaction_hash"), item.tx_hash],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
