import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/node-types";
import EnumRequestStatus from "@wallet-manager/node-types/dist/src/ledger/enums/LedgerRequestStatus";

import { getWalletManagerDepositRecord } from "../../../api/merchant";
import { OpApprove, OpRedirect, OpSwitch, OpView } from "../../../assets/icons";
import CopyableText from "../../../components/CopyableText";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box, Tooltip } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpIconButton from "../../../components/OpIconButton";
import { usePermission, useTranslation } from "../../../hooks";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusDialogStore } from "../../../zustand/store";
import ApprovalProgressDialog from "../ApprovalProgressDialog";
import OperationDialog from "./OperationDialog";
import { useZusParams } from "./WalletManagerDepositRecord";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "ledgerWalletManagerDepositRecord";
const Pkey = FeatureCode.FeaturesLedger.WalletManagerDepositRecord;
const PAGE = "DepositRecord";

export default function WalletManagerDepositRecordList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const zusDialog = useZusDialogStore();
  const {
    hasPermission,
    hasPermissionMultipleAll,
    hasPermissionMultipleEither,
  } = usePermission();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: [
      "ledgerWalletManagerDepositRecord",
      zusParams.body,
      zusParams.refetchCounter,
    ],
    queryFn: () => getWalletManagerDepositRecord(zusParams.body),
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  const CopyableCell =
    (direction: "fromAddress" | "toAddress" | "hash") =>
    (params: GridCellParams) => {
      const { sending_address, wallet_address, transaction_hash } = params.row;
      const address =
        direction === "fromAddress" ? sending_address : wallet_address;
      const displayValue = direction === "hash" ? transaction_hash : address;

      return (
        <Tooltip title={displayValue}>
          <CopyableText text={displayValue} handleOverflow />
        </Tooltip>
      );
    };

  const OperationBtnStack = (params: GridCellParams) => {
    const { id: rowId, row } = params;
    const {
      transaction_status: transaction_status_display,
      request_status: request_status_display,
      risk_level: risk_level_display,
      approval_progress,
      transaction_amount,
      last_modified_time,
      creation_time,
    } = row;
    const rawData = rows[Number(rowId)];
    const { status, id } = rawData;
    const isPending = status === EnumRequestStatus.Pending;
    const isAwaitRiskLevel = status === EnumRequestStatus.AwaitRiskLevel;

    const onClick = (direction: "view" | "switch" | "approve") => () => {
      zusDialog.open("operationDialog", {
        rawData,
        transaction_status_display,
        request_status_display,
        risk_level_display,
        direction,
        approval_progress,
        transaction_amount,
        id,
        status,
        last_modified_time,
        creation_time,
        page: PAGE,
      });
    };
    const isViewBtnShown = hasPermission(Pkey.ViewTransactionDetails);
    const isSwitchBtnShown = hasPermission(Pkey.SwitchToManualApprovalProcess);
    const isApproveBtnShown = hasPermissionMultipleEither([
      Pkey.Approve.prefix,
      Pkey.Reject,
    ]);
    return (
      <Box
        sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}
      >
        {isViewBtnShown && (
          <OpIconButton
            title={t("viewBtn")}
            url={OpView}
            size="1.9rem"
            onClick={onClick("view")}
          />
        )}
        {isSwitchBtnShown && (
          <OpIconButton
            title={t("switch_to_manual_approval_process")}
            url={OpSwitch}
            isDisabled={!isAwaitRiskLevel}
            size="1.9rem"
            onClick={onClick("switch")}
          />
        )}
        {isApproveBtnShown && (
          <OpIconButton
            title={t("approveBtn")}
            url={OpApprove}
            isDisabled={!isPending}
            size="1.9rem"
            onClick={onClick("approve")}
          />
        )}
      </Box>
    );
  };
  const ApprovalProgress = (params: GridCellParams) => {
    const { id: rowId, row } = params;
    const rawRow = rows[Number(rowId)];
    const { id, status } = rawRow || {};
    const { approval_progress, last_modified_time } = row;
    const onClick = () => {
      zusDialog.open("approvalDialog", {
        approval_progress,
        id,
        status,
        last_modified_time,
        page: PAGE,
      });
    };
    if (!approval_progress) return <></>;
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "40%",
        }}
      >
        <span>{approval_progress}</span>
        <OpIconButton
          title={""}
          url={OpRedirect}
          onClick={onClick}
          size="1rem"
        />
      </Box>
    );
  };

  useEffect(() => setCount(count), [listRes]);

  const hasApprovePermission = hasPermission(Pkey.Approve.prefix);
  const hasRejectPermission = hasPermission(Pkey.Reject);
  const hasViewTransactionDetailsPermission = hasPermission(
    Pkey.ViewTransactionDetails
  );
  const hasSwitchToManualApprovalProcessPermission = hasPermission(
    Pkey.SwitchToManualApprovalProcess
  );

  const shouldHideOperationColumn =
    !hasApprovePermission &&
    !hasRejectPermission &&
    !hasViewTransactionDetailsPermission &&
    !hasSwitchToManualApprovalProcessPermission;

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 160,
      renderCell: OperationBtnStack,
    }),
    useGenGridCol("approval_progress", t("approval_progress"), {
      minWidth: 130,
      renderCell: ApprovalProgress,
    }),
    useGenGridCol("client_id", t("client_id"), { minWidth: 150 }),
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 150 }),
    useGenGridCol("currency", t("currency")),
    useGenGridCol("transaction_amount", t("transaction_amount"), {
      minWidth: 150,
    }),
    useGenGridCol("transaction_status", t("transaction_status"), {
      minWidth: 150,
    }),
    useGenGridCol("risk_level", t("risk_level"), {
      minWidth: 150,
    }),
    useGenGridCol("request_status", t("request_status"), {
      minWidth: 150,
    }),
    useGenGridCol("transaction_hash", t("transaction_hash"), {
      minWidth: 150,
      renderCell: CopyableCell("hash"),
    }),
    useGenGridCol("wallet_address", t("wallet_address"), {
      minWidth: 150,
      renderCell: CopyableCell("toAddress"),
    }),
    useGenGridCol("wallet_tag", t("wallet_tag"), {
      minWidth: 150,
    }),

    useGenGridCol("sending_address", t("sending_address"), {
      minWidth: 200,
      renderCell: CopyableCell("fromAddress"),
    }),
    useGenGridCol("reference_no", t("reference_no"), {
      minWidth: 150,
    }),
    useGenGridCol("creation_time", t("creation_time"), { minWidth: 150 }),
    useGenGridCol("last_modified_time", t("last_modified_time"), {
      minWidth: 150,
    }),
  ].filter((col) => {
    if (col.field === "operation") {
      return !shouldHideOperationColumn;
    }

    return true;
  });

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <OperationDialog />
      <ApprovalProgressDialog />
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
