import GeneralBtn from "../components/GeneralBtn";
import { SingleSelection } from "../components/GeneralSelection";
import { genField } from "../utils/HelperComp";
import {
  enumTimezone,
  enumPortalUserYubiKeyStatus,
  enumLanguage,
} from "../utils/constant";
import { useYubikeyDetail } from "../components/FetchConfig";
import { enumMapping } from "../utils/helper";
import { saveProfile, userProfile } from "../api/portalUserProfiles";
import { Itimezone, IportalUserYubiKeyStatus } from "../api/types";
import { useLocaleState, useTranslate } from "react-admin";
import { Ilocale } from "../components/i18nProvider";
import { useState } from "react";
import { SelectChangeEvent, Container, Box } from "../components/MuiGenerals";
import { customSx } from "../utils/styling";
import { useAppDispatch, useAppSelector } from "../reducer/hooks";
import { selectProfile, setAccountSetting } from "../reducer/profileSlice";

const translatePrefix = "accountSetting";
const api = { saveProfile, userProfile };
const Enum = {
  timezones: enumMapping(enumTimezone),
  yubikeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
};

interface Ifields {
  email: string;
  yubikey_status: IportalUserYubiKeyStatus;
  timezone: Itimezone;
  lang: keyof typeof enumLanguage;
}
const initFields = {
  email: "",
  yubikey_status: enumPortalUserYubiKeyStatus.Unbound,
  timezone: enumTimezone.timezone8,
  lang: enumLanguage.zh_cn,
} as const;
const sx = {
  Box: {
    display: "grid",
    gridTemplateColumns: "minmax(8rem, max-content) minmax(10rem, max-content)",
    lineHeight: "3rem",
    columnGap: "1rem",
    marginBottom: "1rem",
  },
};
export default function AccountSetting() {
  const dispatch = useAppDispatch();
  const { email, timezone, lang } = useAppSelector(selectProfile);
  const yubikeyDetail = useYubikeyDetail();
  const yubikey_status_value = String(
    yubikeyDetail?.yubi_key_status
  ) as IportalUserYubiKeyStatus;

  const yubikey_status =
    Object.entries(Enum.yubikeyStatus).find(
      ([key, _value]) => key === yubikey_status_value
    ) && Enum.yubikeyStatus[yubikey_status_value];
  const [, setLocale] = useLocaleState();
  const [fields, setFields] = useState<Ifields>({
    ...initFields,
    email,
    timezone,
    lang,
  });
  const [inView, setInView] = useState(true);
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    setFields((fields: any) => ({ ...fields, [type]: value }));
  };
  const onSave = async () => {
    const res = await api.saveProfile({
      lang: fields.lang,
      timezone: fields.timezone,
    });
    if (!res) return;
    setLocale(fields.lang);
    dispatch(
      setAccountSetting({ lang: fields.lang, timezone: fields.timezone })
    );
    setInView(true);
  };

  const onCancel = () => {
    setFields({ ...fields, email, timezone, lang });
    setInView(true);
  };

  const F = genField({ t }, [
    [
      "timezone",
      <SingleSelection
        value={fields.timezone}
        onChange={onSelectChange("timezone")}
        enumData={enumTimezone}
        arrayFn={(arr) => arr.sort((a, b) => Number(a[1]) - Number(b[1]))}
        nameFn={(name) => `GMT${te(name)}`}
        clearSelect={() => {}}
      />,
    ],
    [
      "lang",
      <SingleSelection
        value={fields.lang}
        onChange={onSelectChange("lang")}
        enumData={enumLanguage}
        nameFn={(name) => {
          if (name === "en") return "English";
          return "简中";
        }}
        clearSelect={() => {}}
      />,
    ],
  ]);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <Box sx={sx.Box}>
          <span>{t("email")}</span>
          <span>{email}</span>
          <span>{t("yubikey_status")}</span>
          <span>{yubikey_status && te(yubikey_status)}</span>
          {inView && (
            <>
              <span>{t("timezone")}</span>
              <span>GMT{te(Enum.timezones[timezone])}</span>
              <span>{t("language")}</span>
              <span>{te(lang)}</span>
            </>
          )}
          {!inView && (
            <>
              {F.timezone}
              {F.lang}
            </>
          )}
        </Box>
        {inView && <GeneralBtn onClick={() => setInView(false)} label="edit" />}
        {!inView && <GeneralBtn onClick={onSave} label="confirm" />}
        {!inView && (
          <GeneralBtn
            onClick={onCancel}
            label="cancel"
            color="error"
            rest={{ style: { marginLeft: "40px" } }}
          />
        )}{" "}
      </Container>
    </Box>
  );
}

export function LanguageSelection() {
  const profile = useAppSelector(selectProfile);
  const timezone = profile.timezone;
  const dispatch = useAppDispatch();
  const [, setLocale] = useLocaleState();
  const onSelectChange =
    (type: string) => async (e: SelectChangeEvent<string>) => {
      if (type !== "lang") return;
      const lang = e.target.value as Ilocale;
      const res = await api.saveProfile({ lang, timezone });
      if (!res) return;
      dispatch(setAccountSetting({ lang, timezone }));
      setLocale(lang);
    };
  /**
     
    border-radius: .5rem
    background-color: rgba(0,0,0,0)
     */
  const propSx = {
    backgroundColor: "rgba(0,0,0,0)",
    select: {
      "& .MuiSelect-select": {
        paddingTop: ".3rem",
        // backgroundColor: "white",
      },
    },
    // formControl: { borderRadius: ".5rem" },
    formControl: {
      "& .MuiInputBase-root": {
        backgroundColor: "white",
        borderRadius: ".5rem",
      },
      "& .MuiInputBase-root:before": {
        borderBottom: "none",
      },
    },
  };

  return (
    <SingleSelection
      value={profile.lang}
      onChange={onSelectChange("lang")}
      enumData={enumLanguage}
      nameFn={(name) => {
        if (name === "en") return "English";
        return "简中";
      }}
      clearSelect={() => {}}
      sx={propSx}
    />
  );
}
