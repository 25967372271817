import { useEffect } from "react";
import { useTabs, usePermission, useTranslation } from "../../../hooks";
import UserApproval, {
  useZusParams as userApprovalParams,
} from "./UserApproval";
import RequestRecord, {
  useZusParams as requestRecordParams,
} from "./RequestRecord";
import { FeatureCodes } from "../../../utils/constant";
const Pkey = FeatureCodes.tools.UserApprovalManagement;
const translatePrefix = "userApprovalManagement";
export default function UserApprovalManagement() {
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const useApproval = userApprovalParams();
  const useRequest = requestRecordParams();
  const tabsArr = [
    hasPermission(Pkey.TabUserApproval.prefix) && {
      name: t("user_approval"),
      value: "user_approval",
    },
    hasPermission(Pkey.TabRecord.prefix) && {
      name: t("request_record"),
      value: "request_record",
    },
  ].filter((a) => a) as { name: string; value: string }[];
  const { Tabs, tabValue } = useTabs(tabsArr);
  useEffect(() => {
    useApproval.clear();
    useRequest.clear();
  }, [tabValue]);
  return (
    <Tabs>
      {tabValue === "user_approval" && <UserApproval />}
      {tabValue === "request_record" && <RequestRecord />}
    </Tabs>
  );
}
