import { Routes, Route, Navigate } from "react-router-dom";
import Redirect from "./views/Redirect";
import App from "./App";
import Login from "./views/Login";
import Alerter from "./components/Alerter";
import { useAppSelector } from "./reducer/hooks";
import { selectProfile } from "./reducer/profileSlice";
const LoginRouter = () => {
  const profile = useAppSelector(selectProfile);
  const isLogin = profile.isLogged;
  return (
    <>
      <Alerter />
      <Routes>
        <Route path="redirect" element={<Redirect />} />
        <Route path="login" element={<Login />} />
        {!isLogin && (
          <Route path="*" element={<Navigate replace to="/login" />} />
        )}
        {isLogin && <Route path="*" element={<App />} />}
      </Routes>
    </>
  );
};

export default LoginRouter;
