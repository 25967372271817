import {
  useAccountSetting,
  useIsActive,
  useYubikeyDetail,
} from "./FetchConfig";
import { LanguageSelection } from "../views/AccountSetting";
import { useRedirect } from "react-admin";
import OPSetting from "../assets/icons/op-setting.svg";
import OpIconButton from "../components/OpIconButton";
import Menu from "../views/Menu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Typography, Button, Box } from "@mui/material";
import { Sidebar } from "react-admin";
import { Content, ContentWithSidebar } from "../utils/styling";
import { useAppSelector } from "../reducer/hooks";
import { selectProfile } from "../reducer/profileSlice";
import { selectStuff } from "../reducer/stuffSlice";
import { useTranslation } from "../hooks";
import { enumMapping } from "../utils/helper";
import { enumTimezone } from "../utils/constant";
import { forceLogout } from "../api/axiosInstance";
import AutoLogoutController from "../controller/AutoLogoutController";
import WithdrawStatusStaticBar from "./WithdrawStatusStaticBar";

const Enum = {
  timezones: enumMapping(enumTimezone),
};
const sx: Record<string, any> = {
  AppBarSpan: {
    display: "flex",
    backgroundColor: "white",
  },
  SideBarParent: {
    backgroundColor: "white",
    color: "white",
    minHeight: "100vh",
    boxShadow: "rgba(0,0,0,.1) 0px 0 5px 6px",
  },
  SideBar: {
    // stop mui docker collasped, fix the menu position
    marginTop: "0 !important",
    "& .RaSidebar-fixed": {
      height: "100vh",
    },
  },
  topRightBar: {
    position: "absolute",
    top: "1rem",
    right: ".5rem",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    "& Button.MuiButton-text": {
      fontSize: "1rem",
    },
  },
  topLeftBar: {
    position: "absolute",
    top: "1rem",
    left: "345px",
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    padding: "6px 8px",
  },
  pageName: {
    position: "absolute",
    top: "4.5rem",
    left: "345px",
    padding: "6px 8px",
  },
  email: {
    color: "#4A90F7",
    fontSize: "1rem",
  },
  logoutBtn: {
    color: "#4A90F7",
    backgroundColor: "white",
    borderRadius: "15px",
    padding: ".3rem 7px",
    boxShadow: "none",
  },
  appName: {
    color: "gray",
    fontSize: "1rem",
  },
} as const;

export default function LayoutBar(props: any) {
  const { t, te } = useTranslation("menu");
  const { appName, email, timezone } = useAppSelector(selectProfile);
  const { viewPermission } = useAppSelector(selectStuff);
  const redirect = useRedirect();
  const logoutFn = () => {
    forceLogout("", { from: "menu-button" });
  };
  useIsActive();
  useAccountSetting(); // only call once
  useYubikeyDetail(); // first time call for cache
  const timezoneDisplay = `GMT${te(Enum.timezones[timezone])}`;

  return (
    <>
      <AutoLogoutController />
      <WithdrawStatusStaticBar/>
      <ContentWithSidebar>
        <div style={sx.SideBarParent}>
          <Sidebar sx={sx.SideBar}>
            <Menu />
          </Sidebar>
        </div>
        <Box style={sx.topLeftBar}>
          <Typography variant="caption" sx={sx.appName}>
            {appName}
          </Typography>
        </Box>
        <Box sx={sx.topRightBar}>
          <LanguageSelection />
          <Box
            onClick={() => redirect("/accountSetting")}
            sx={{
              marginTop: "6px",
              padding: ".3rem .7rem",
              backgroundColor: "white",
              borderRadius: ".5rem",
              cursor: "pointer",
            }}
          >
            {timezoneDisplay}
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "1rem",
              display: "flex",
            }}
          >
            {t("welcome")}, <span style={sx.email}>{email}</span>
            <span style={{ transform: "translate(5px, 5px)" }}>
              <OpIconButton
                title={""}
                size={"1rem"}
                url={OPSetting}
                onClick={() => redirect("/accountSetting")}
              />
            </span>
          </Typography>
          <Button
            variant="contained"
            startIcon={<PowerSettingsNewIcon />}
            onClick={logoutFn}
            sx={sx.logoutBtn}
          >
            {t("logout")}
          </Button>
        </Box>
        <div style={sx.pageName}>
          <Typography variant="h6">
            {viewPermission && t(viewPermission)}
          </Typography>
        </div>
        <Content>{props.children}</Content>
      </ContentWithSidebar>
    </>
  );
}
