import React from 'react';

import StaticBar from '../StaticBar';
import { Box,Typography } from '../MuiGenerals';
import { useTranslation } from '../../hooks';
import { useAppSelector } from "../../reducer/hooks";
import { getWithdrawSetting } from '../../reducer/settingSlice';

interface IWithdrawStatusStaticBarProps {}

const WithdrawStatusStaticBar: React.FC<IWithdrawStatusStaticBarProps> = () => {
    const { t } = useTranslation("component");
    const withdrawable = useAppSelector(getWithdrawSetting)

    return <StaticBar position='top' content={
        <Box sx={{
            backgroundColor:"#F50636",
            color: "white",
            padding: "0.1rem",
            paddingLeft:"0.5rem"

        }}>
            <Typography sx={{
                fontWeight: "bold",
            }}>{`${t('merchantWithdrawStatus')} : ${t('disable')}`}</Typography>
        </Box>
    } isShow={!withdrawable}/>;
};

export default WithdrawStatusStaticBar;
