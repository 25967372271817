import addHours from "date-fns/addHours";
import subMonths from "date-fns/subMonths";
import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import EnumRiskLevel from "@wallet-manager/node-types/dist/types/postgres/const/RiskLevel";

import { getTransferTransactionsRecord, listFace } from "../../api/merchant";
import { AllTransactionParamsFace } from "../../api/types";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useDatePicker } from "../../components/DatePicker";
import { useAssets, useChains } from "../../components/FetchConfig";
import GeneralBtn from "../../components/GeneralBtn";
import { NodeTypesEnumMultiSelection } from "../../components/GeneralSelection";
import MpTextField from "../../components/MpTextField";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useAlerting, usePermission, useTabs } from "../../hooks";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import {
  bankAddressArr,
  enumCertifiedBalanceObj,
  enumDirectionObj,
  enumIsFeeObj,
  enumTransactionStatus,
  enumTransationType,
  enumWalletType,
  FeatureCodes,
  tableConfig,
} from "../../utils/constant";
import {
  checkIfAddressIsNull,
  displayAmount,
  downloadFiles,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  listMappingTransform,
  separateBatchStrings,
  sortItemsAlphabetically,
  toDisplayTime,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import { useZusDialogStore } from "../../zustand/store";
import AllTransactionReportList from "./AllTransactionReportList";

const Pkey = FeatureCodes.report.AllTransactions;
export default function AllTransactionReport() {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          count={list.count}
          setPage={setPage}
          page={page}
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <AllTransactionReportList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

const initFields = {
  clientIdsString: "",
  walletNamesString: "",
  walletTagsString: "",
  wallet_types: [],
  chain_name: "",
  asset_names: [],
  directions: [],
  trans_types: [],
  statusArr: [],
  wallet_address: "",
  from_address: "",
  to_address: "",
  is_fee: "",
  tx_hash: "",
  ref_no: "",
  check_point_certified: "",
  merchant_id: "",
  risk_levels: [],
  batchIdsString: "",
};

function FilterBar(props: any) {
  const { selectAssetNamesByChain } = useAssets();
  const [checked, setChecked] = useState(false);

  const { chainObj } = useChains();
  const { hasPermission } = usePermission();
  const translate = useTranslate();
  const t = (key: string) => translate(`report.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { setList, setPage, page, count, isSearchBtnClick, setSearchBtnClick } =
    props;
  const profile = useAppSelector(selectProfile);
  function getNow() {
    const timezone = profile.timezone;
    const defaultTimezone = (new Date().getTimezoneOffset() * -1) / 60;
    const totalDiff = Number(timezone) - defaultTimezone;
    return addHours(new Date(), totalDiff);
  }
  const now = getNow();
  const dateConfigObj = {
    start: {
      defaultDate: new Date(subMonths(now, 1).toDateString() + ", 00:00:00"),
    },
    end: { defaultDate: new Date(now.toDateString() + ", 23:59:59") },
  };
  const DateObj = {
    Created: useDatePicker(dateConfigObj),
    Modified: useDatePicker(),
    WalletSettled: useDatePicker(),
  };
  const [fields, setFields] = useState<AllTransactionParamsFace>(initFields);
  const assetsNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (asset_names: string[]) =>
    setFields((fields) => ({ ...fields, asset_names }));
  useEffect(() => {
    setFields((fields) => ({ ...fields, asset_names: [] }));
  }, [fields.chain_name]);
  const getParams = () => {
    const {
      clientIdsString,
      walletNamesString,
      walletTagsString,
      asset_names,
      chain_name,
      statusArr,
      check_point_certified,
      merchant_id,
      tx_hash,
      batchIdsString,
      ...rest
    } = fields;

    const clientIds = separateBatchStrings(clientIdsString);
    const walletNames = separateBatchStrings(walletNamesString);
    const wallet_tags = separateBatchStrings(walletTagsString);
    const merchant_ids = separateBatchStrings(merchant_id);
    const tx_hashesArr = separateBatchStrings(tx_hash);
    const batch_ids = separateBatchStrings(batchIdsString);

    const key = chain_name as keyof typeof chainObj;
    const { chain_id, chain_type } = chainObj[key] || {};
    return {
      ...rest,
      batch_ids,
      created_date_from: DateObj.Created.start,
      created_date_to: DateObj.Created.end,
      last_modified_date_from: DateObj.Modified.start,
      last_modified_date_to: DateObj.Modified.end,
      wallet_settlement_date_from: DateObj.WalletSettled.start,
      wallet_settlement_date_to: DateObj.WalletSettled.end,
      chain_id,
      chain_type,
      client_ids: clientIds,
      display_names: walletNames,
      walletTags: wallet_tags,
      asset_name: asset_names,
      statuses: statusArr,
      check_point_certified,
      merchant_order_ids: merchant_ids,
      page,
      pageSize: tableConfig.pageSize,
      tx_hashes: tx_hashesArr,
      is_check_point_id_exists: checked ? checked : [], //a boolean that filter and only show non-zero trx when checked
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getTransferTransactionsRecord({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, isSearchBtnClick]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFields((fields) => ({ ...fields, [type]: value }));
  };
  const onSearch = async () => {
    setSearchBtnClick(true);
    setPage(0);
    setCacheParams(params);
  };
  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      getTransferTransactionsRecord({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, {
      omitKeys: ["block_hash", "block_number", "confirmation", "trans_date"],
      translate,
    });
    if (res.length === 0) return;
    const config = {};
    downloadFiles(`All Transaction Report`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Created.clearDate();
    // DateObj.Created.resetDate();
    DateObj.Modified.clearDate();
    DateObj.WalletSettled.clearDate();
  };

  const handleClick = (e: any, key: any, filterField: string) => {
    if (filterField === "chain_name") {
      if (key === fields.chain_name)
        setFields((fields) => ({
          ...fields,
          chain_name: "",
        }));
    } else if (filterField === "is_fee") {
      setFields((fields) => ({
        ...fields,
        is_fee: "",
      }));
    } else if (filterField === "check_point_certified") {
      setFields((fields) => ({ ...fields, check_point_certified: "" }));
    }
  };

  const onText =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value.trim();
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const onFilterChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const walletTypeArr = ["1", "2", "3"];
  // const walletTypeArr = ["0", "1", "2", "3", "4", "5"];

  const F = genField({ t }, [
    ["created_date", <DateObj.Created.Picker type="dateTime" />],
    ["last_modified_date", <DateObj.Modified.Picker type="dateTime" />],
    [
      "client_id",
      <MpTextField
        label={t("phClient_id")}
        value={fields.clientIdsString}
        onChange={onChange("clientIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.client_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "display_name",
      <MpTextField
        label={t("phDisplayName")}
        value={fields.walletNamesString}
        onChange={onChange("walletNamesString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.display_names.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_tag",
      <MpTextField
        label={t("wallet_tag")}
        value={fields.walletTagsString}
        onChange={onChange("walletTagsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.walletTags.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_type",
      <FormControl>
        <InputLabel id="select-label">{t("phWallet_type")}</InputLabel>
        <Select
          multiple
          name="wallet_types"
          value={fields.wallet_types}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={assetsNames}
      />,
    ],
    [
      "direction",
      <FormControl>
        <InputLabel>{t("phDirection")}</InputLabel>
        <Select
          name="directions"
          multiple
          value={fields.directions}
          onChange={onArraySelectChange}
        >
          {Object.entries(enumDirectionObj).map(([value, name], i) => (
            <MenuItem key={i} value={value}>
              {translate(`enumConstants.${enumDirectionObj[String(value)]}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "trans_type",
      <FormControl>
        <InputLabel>{t("phTrans_type")}</InputLabel>
        <Select
          name="trans_types"
          multiple
          value={fields.trans_types}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumTransationType))
            .filter(
              ([_value, name]: any) =>
                name !== "External Transfer" && name !== "External Miner Fee"
            )
            .map(([value, name]: any, i: string) => (
              <MenuItem key={i} value={value}>
                {translate(`enumConstants.${name}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "status",
      <FormControl>
        <InputLabel>{t("phStatus")}</InputLabel>
        <Select
          name="statusArr"
          multiple
          value={fields.statusArr}
          onChange={onArraySelectChange}
        >
          {sortItemsAlphabetically(Object.entries(enumTransactionStatus)).map(
            ([id, name]: any, i: string) => (
              <MenuItem key={i} value={id}>
                {translate(`enumConstants.${name}`)}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>,
    ],
    [
      "wallet_address",
      <MpTextField
        value={fields.wallet_address}
        onChange={onText("wallet_address")}
        label={t("phWallet_address")}
      />,
    ],
    [
      "from_address",
      <MpTextField
        value={fields.from_address}
        onChange={onText("from_address")}
        label={t("phFrom_address")}
      />,
    ],
    [
      "to_address",
      <MpTextField
        value={fields.to_address}
        onChange={onText("to_address")}
        label={t("phTo_address")}
      />,
    ],
    [
      "is_fee",
      <FormControl>
        <InputLabel>{t("phIs_fee")}</InputLabel>
        <Select value={fields.is_fee} onChange={onSelectChange("is_fee")}>
          {Object.entries(enumIsFeeObj).map(([id, name], i) => (
            <MenuItem
              key={i}
              value={id}
              onClick={(e) => handleClick(e, id, "is_fee")}
            >
              {translate(`enumConstants.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "tx_hash",
      <MpTextField
        value={fields.tx_hash}
        onChange={onText("tx_hash")}
        label={t("phTx_hash")}
      />,
    ],
    [
      "ref_no",
      <MpTextField
        value={fields.ref_no}
        onChange={onText("ref_no")}
        label={t("phRef_no")}
      />,
    ],
    [
      "is_certified_balance",
      <FormControl>
        <InputLabel>{t("ph_certified_balance")}</InputLabel>
        <Select
          name="check_point_certified"
          value={fields.check_point_certified}
          onChange={onSelectChange("check_point_certified")}
        >
          {Object.entries(enumCertifiedBalanceObj).map(([value, name], i) => (
            <MenuItem
              key={i}
              value={value}
              onClick={(e) => handleClick(e, value, "check_point_certified")}
            >
              {translate(
                `enumConstants.${enumCertifiedBalanceObj[String(value)]}`
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "merchant_order_id",
      <MpTextField
        name="merchant_id"
        label={t("phMerchant_id")}
        value={fields.merchant_id}
        onChange={onFilterChange("merchant_id")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.merchant_order_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
    [
      "wallet_settlement_date",
      <DateObj.WalletSettled.Picker type="dateTime" />,
    ],
    [
      "checkpoint",
      <Checkbox checked={checked} onChange={() => setChecked(!checked)} />,
    ],
    [
      "risk_level",
      <NodeTypesEnumMultiSelection
        label={t("ph_risk_level")}
        value={fields.risk_levels}
        onChange={(risk_levels) => setFields((f) => ({ ...f, risk_levels }))}
        enumData={EnumRiskLevel}
        disabled={!profile.chain_analysis}
        isNoSorting
      />,
    ],
    [
      "batch_id",
      <MpTextField
        label={t("phBatch_id")}
        value={fields.batchIdsString}
        onChange={onChange("batchIdsString")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.batch_ids.length}
            </InputAdornment>
          ),
        }}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn
          label="export"
          isHidden={!hasPermission(Pkey.Export)}
          onClick={onExport}
        />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.created_date}
        {F.last_modified_date}
        {F.chain_name}
        {F.asset_name}
        {F.client_id}
        {F.display_name}
        {F.wallet_tag}
        {F.wallet_type}
        {F.direction}
        {F.trans_type}
        {F.status}
        {F.wallet_address}
        {F.from_address}
        {F.to_address}
        {F.is_fee}
        {F.tx_hash}
        {F.ref_no}
        {F.is_certified_balance}
        {F.merchant_order_id}
        {F.wallet_settlement_date}
        {F.checkpoint}
        {F.risk_level}
        {F.batch_id}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: any
): any[][] => {
  const { omitKeys = [], translate } = config || {};
  const t = (key: string) => translate(`report.${key}`);
  const clearBankAddress = (val: any) =>
    bankAddressArr.includes(val) ? "" : val;
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);

    const mappedResult = [
      ["created_date", t("created_date"), toDisplayTime(item.created_date)],
      [
        "wallet_settlement_date",
        t("wallet_settlement_date"),
        toDisplayTime(item.wallet_settlement_date),
      ],
      ["batch_id", t("batch_id"), item.batch_id],
      ["merchant_order_id", t("merchant_order_id"), item?.merchant_order_id],
      //
      ["block_hash", "", item.block_hash],
      ["block_number", "", item.block_number],
      ["confirmation", "", item.confirmation],
      //
      ["display_name", t("display_name"), item?.wallet?.display_name],
      ["client_id", t("client_id"), item?.client_id],
      [
        "wallet_type",
        t("wallet_type"),
        translate(`enumConstants.${enumWalletType[String(item?.wallet_type)]}`),
      ],

      [
        "trans_type",
        t("trans_type"),
        translate(
          `enumConstants.${enumTransationType[String(item.trans_type)]}`
        ),
      ],
      [
        "direction",
        t("direction"),
        translate(`enumConstants.${enumDirectionObj[String(item.direction)]}`),
      ],
      [
        "status",
        t("status"),
        translate(
          `enumConstants.${enumTransactionStatus[String(item.status)]}`
        ),
      ],
      [
        "risk_level",
        t("risk_level"),
        translate(`enumConstants.${EnumRiskLevel[item.risk_level]}`),
      ],
      [
        "wallet_address",
        t("wallet_address"),
        clearBankAddress(item.wallet_address),
      ],
      ["wallet_tag", t("wallet_tag"), item?.client_tag],
      [
        "from_address",
        t("from_address"),
        clearBankAddress(
          checkIfAddressIsNull(item.from_address, String(item.trans_type))
        ),
      ],
      [
        "to_address",
        t("to_address"),
        clearBankAddress(
          checkIfAddressIsNull(item.to_address, String(item.trans_type))
        ),
      ],
      ["to_wallet_tag", t("to_wallet_tag"), item?.to_wallet_tag],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["amount", t("amount"), toAmount(item.amount)],

      ["trans_fee", t("trans_fee"), toAmount(item.trans_fee)],
      [
        "post_balance",
        t("post_balance"),
        clearBankAddress(toAmount(item.post_balance)),
      ],
      [
        "wallet_type_post_balance",
        t("wallet_type_post_balance"),
        toAmount(item.wallet_type_post_balance),
      ],
      [
        "is_fee",
        t("isFeeRecord"),
        translate(`enumConstants.${enumIsFeeObj[String(item.is_fee)]}`),
      ],
      ["check_point_id", t("check_point_id"), item.check_point_id],
      [
        "certifiedWithOnChainBalance",
        t("certifiedWithOnChainBalance"),
        translate(
          `enumConstants.${
            enumCertifiedBalanceObj[String(item.check_point_certified)]
          }`
        ),
      ],
      ["trans_date", "", toDisplayTime(item.trans_date)],
      ["tx_hash", t("tx_hash"), clearBankAddress(item.tx_hash)],
      ["ref_no", t("ref_no"), item.ref_no],
      [
        "last_modified_date",
        t("last_modified_date"),
        toDisplayTime(item.last_modified_date),
      ],
      ["chain_type", "", item.chain_type],
      ["chain_id", "", item.chain_id],
      ["rawData", "", item],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
