import { initMyYubiDetails } from "../zustand/initialStatesFactory";
import { useLocaleState, useTranslate } from "react-admin";
import { useQuery } from "react-query";
import { useCallback, useEffect, useState } from "react";
import { userProfile } from "../api/portalUserProfiles";
import { selectProfile, setAccountSetting } from "../reducer/profileSlice";
import { setWithDrawable } from "../reducer/settingSlice";
import {
  setAssets,
  selectAssets,
  selectAllAssetsName,
  selectAssetsByChain,
  selectAssetsNameByChain,
  selectEnableAssetByChain,
} from "../reducer/assetsSlice";
import {
  setChains,
  selectChains,
  selectChainByPieces,
} from "../reducer/chainsSlice";
import { useAppSelector, useAppDispatch } from "../reducer/hooks";
import {
  fetchAllAssets,
  fetchAllChains,
  getSettingRequest,
} from "../api/merchant";
import { getMyYubiKeyStaus } from "../api/auth";
import { Itimezone } from "../api/types";
import {
  FeatureCodeYubikeyRegister,
  chainKeyAndName,
  enumLanguage,
} from "../utils/constant";
import { useAlerting, usePermission } from "../hooks";
import { useYubiKeysStoreForReg } from "../zustand/store";
import { IYubiDetails } from "../zustand/types";
import { ensureAccessToken, forceLogout } from "../api/axiosInstance";
import { LAST_ACTIVE_UPPER_LIMIT_MINS } from "../utils/config";
import { createThrottleFunc } from "../utils/helper";
import { selectStuff } from "../reducer/stuffSlice";

interface FreeObj {
  [code: string]: any;
}
const api = { userProfile };

export default function FetchConfig() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchAssets = async () => {
      const assetsRes = await fetchAllAssets();
      let assets: FreeObj = {};
      for (let one of assetsRes) {
        assets[`${one.chain_type}_${one.chain_id}_${one.asset_name}`] = one;
      }
      dispatch(setAssets(assets));
    };
    fetchAssets();
  }, []);
  useEffect(() => {
    const fetchChains = async () => {
      const chainsRes = await fetchAllChains();
      const chainsWithName = chainsRes.map((c: any) => {
        const defaultName = `${c.chain_code}`;
        const knownName =
          chainKeyAndName[c.chain_code as keyof typeof chainKeyAndName];
        const names = knownName || { name: defaultName };
        const type = c.chain_code;
        return { ...c, ...names, type };
      });
      let chains: FreeObj = {};
      for (let one of chainsWithName) {
        chains[one.chain_code] = one;
      }
      dispatch(setChains(chains));
    };
    fetchChains();
  }, []);
  
  useEffect(() => {
    (async () => {
      const res = await getSettingRequest();
      dispatch(setWithDrawable(res.withdrawable));
    })();
  }, []);
  return <></>;
}

/*
const { chain_type, chain_id } = selectChainByPieces({
  chain_name: "RIN",
});
OR
const oneChain = selectChainByPieces({
  chain_type:1, chain:id:1,
});
*/
export function useChains() {
  const chainObj = useAppSelector(selectChains);
  const selectByChainFn = useAppSelector(selectChainByPieces);
  return { chainObj, selectChainByPieces: selectByChainFn };
}
export function useAssets() {
  const assetObj = useAppSelector(selectAssets);
  const allAssetNames = useAppSelector(selectAllAssetsName);
  const selectByChainFn = useAppSelector(selectAssetsByChain);
  const selectNamesByChainFn = useAppSelector(selectAssetsNameByChain);
  const selectEnableAsset = useAppSelector(selectEnableAssetByChain);
  return {
    assetObj,
    allAssetNames,
    selectAssetsByChain: selectByChainFn,
    selectAssetNamesByChain: selectNamesByChainFn,
    selectEnableAssetByChain: selectEnableAsset,
  };
}
export function useAccountSetting() {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectProfile);
  const [_, setLocaleState] = useLocaleState();
  useQuery("userProfile", api.userProfile, {
    staleTime: 60 * 60 * 12,
    onSuccess: (dbRes) => {
      const hasDBprofile = !!dbRes;
      const localSetting = {
        timezone: profile.timezone,
        lang: profile.lang,
      };
      const { lang, timezone } = hasDBprofile ? dbRes : localSetting;
      dispatch(setAccountSetting({ lang, timezone }));
      setLocaleState(lang);
    },
  });
}

export function useYubikeyDetail(p = { isCache: false }): IYubiDetails {
  const { myDetail, setYuBiDetails } = useYubiKeysStoreForReg();
  const { isCache } = p;
  const hasRegisterYubikey = usePermission().hasPermission(
    FeatureCodeYubikeyRegister
  );
  useQuery("getMyYubiKeyStaus", getMyYubiKeyStaus, {
    onSuccess: (res) => {
      setYuBiDetails(res);
    },
    enabled: hasRegisterYubikey && !isCache,
  });
  if (!hasRegisterYubikey) return initMyYubiDetails;
  return myDetail;
}

const getNow = () => new Date().getTime();

export function useLastActiveTime() {
  const [lastActiveTime, rawSetActiveTime] = useState(0);
  const { isRefreshingToken } = useAppSelector(selectStuff);
  const setActiveTime = (time: number) => {
    localStorage.setItem("lastActiveTime", String(time));
    rawSetActiveTime(time);
  };

  const hasAction = createThrottleFunc(() => {
    setActiveTime(getNow());
    ensureAccessToken();
  }, 1000);

  useEffect(() => {
    // login as a trigger to update lastActiveTime
    localStorage.setItem("lastActiveTime", String(getNow()));
    hasAction();
  }, []);

  useEffect(() => {
    window.addEventListener("mousemove", hasAction);
    window.addEventListener("keydown", hasAction);
    return () => {
      window.removeEventListener("mousemove", hasAction);
      window.removeEventListener("keydown", hasAction);
    };
  }, []);
  return lastActiveTime;
}
export function useIsActive() {
  const lastActiveTime = useLastActiveTime();
  const upperLimit = 1000 * 60 * LAST_ACTIVE_UPPER_LIMIT_MINS;
  const [isActive, setIsActive] = useState(
    getNow() - lastActiveTime < upperLimit
  );
  useEffect(() => {
    function checker() {
      const storageActiveTime =
        Number(localStorage.getItem("lastActiveTime")) || 0;
      const latestTime = Math.max(storageActiveTime, lastActiveTime);
      const isActive = getNow() - latestTime < upperLimit;
      setIsActive(isActive);
      if (!isActive) {
        const message = `idle for ${LAST_ACTIVE_UPPER_LIMIT_MINS} minutes`;
        return forceLogout(message);
      }
      return isActive;
    }
    // check isActive every 10 second
    let timerId = setInterval(checker, 1000 * 10);
    return () => {
      clearInterval(timerId);
    };
  }, [lastActiveTime]);
  return isActive;
}
