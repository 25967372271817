import React from "react";
import { Box, Button } from "../MuiGenerals";
import AddIcon from "@mui/icons-material/Add";
import { useAlerting, usePermission, useTranslation } from "../../hooks";
import { containsOnlyNumbers } from "../../utils/helper";
import { FeatureCodes } from "../../utils/constant";
import style from "./regulationDialogCss.module.css";
import MpTextField from "../MpTextField";
const translatePrefix = "withdrawApprovalSetting";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabSetting;
const sx = {
  table: {
    backgroundColor: "#EAEAEA",
    p: 1,
    my: 1,
  },
  tableHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "> span:first-of-type": {
      flex: 1,
      display: "flex",
      "& span": {
        flex: 1,
      },
      "& span:first-of-type": {
        marginRight: "3.5rem",
      },
    },
    "& Button": {
      color: "black",
      backgroundColor: "white",
    },
  },
  tableContent: {
    my: 2,
    maxHeight: "50vh",
    overflowY: "auto",
  },
  tableRow: {
    columnGap: "1rem",
    display: "flex",
    alignItems: "center",
    "& .MuiFormControl-root": { flex: 1 },
    my: 1,
  },
};
interface IRegulationItems {
  seq: number;
  withdraw_amt: string;
  settle_amt: string;
}
type TLocalProps = {
  setRegulationItems: React.Dispatch<React.SetStateAction<IRegulationItems[]>>;
  regulationItems: IRegulationItems[];
};

const ApprovalRegulation = ({
  setRegulationItems,
  regulationItems,
}: TLocalProps) => {
  const { t } = useTranslation(translatePrefix);
  const { hasPermission } = usePermission();
  const { alerting } = useAlerting();
  const currentId = regulationItems
    .map((item) => item.seq)
    .sort()
    .slice(-1)[0];

  //{- -Handle Functions - -}
  const handleAddItems = () => {
    if (regulationItems.length === 4) {
      alerting("warning", t("maximumOfApprovers"));
      return;
    }
    //{- -default regulationItem Format with s only - -}
    const newItem: IRegulationItems[] = [
      { seq: currentId + 1, withdraw_amt: "", settle_amt: "" },
    ];
    setRegulationItems((pre) => [...pre, ...newItem]);
  };
  //{- -Delete Items  - - }
  const handleDeleteItems = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { id } = e.target as HTMLInputElement;
    //{- -The first one is default exist ,not allow to delete - -}
    if (Number(id) < Number(currentId)) {
      return alerting("warning", t("onlyAllowDeleteTheLargesNo"));
    }
    const remainingitems = regulationItems.filter(
      (item) => item.seq !== Number(id)
    );
    setRegulationItems(remainingitems);
  };
  //{- - Handle Onchange Input Value- -}
  const handleInputAmountEvent = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value, id, name } = e.target;
    const resp = containsOnlyNumbers(String(value));
    if (!resp) return;
    const newArr = regulationItems.map((item) => {
      let newObj = { ...item };
      if (newObj.seq === Number(id)) {
        newObj = { ...newObj, [name]: value };
      }
      return newObj;
    });
    setRegulationItems(newArr);
  };
  const showAddRecordBtn = hasPermission(Pkey.AddRecord);
  return (
    <>
      <Box sx={sx.tableHeader}>
        <span>
          <span style={{ fontWeight: "bold" }}>{t("regulation")}</span>
        </span>
        {showAddRecordBtn && (
          <Button
            onClick={handleAddItems}
            startIcon={<AddIcon />}
            sx={{ fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"] }}
          >
            {t("add_item")}
          </Button>
        )}
      </Box>
      <Box sx={sx.tableContent}>
        <section className={style.gridHeaderContainer}>
          <div>{t("noOfApprover")}</div>
          <div> {t("type")}</div>
          <div>{t("amountIslargerThan")}</div>
        </section>
      </Box>
      {/* Regulation */}
      {regulationItems.map((item, index) => (
        <div key={index} style={{ display: "flex" }}>
          <Box sx={sx.table}>
            <Box>
              <section className={style.gridSection}>
                <div
                  style={{
                    gridColumn: 1 / 3,
                    gridRow: "span 3",
                    justifySelf: "center",
                    alignSelf: "center",
                  }}
                >
                  {item.seq + 1}
                </div>
                <div className={style.type}>{t("withdraw")}</div>
                <MpTextField
                  name="withdraw_amt"
                  id={String(item.seq)}
                  sx={{
                    backgroundColor: "white",
                    minWidth: "200px",
                    gridColumn: "3/5",
                  }}
                  value={item.withdraw_amt}
                  onChange={handleInputAmountEvent}
                  disabled={!showAddRecordBtn}
                />
                <div className={style.type}>{t("settlement")}</div>
                <MpTextField
                  name="settle_amt"
                  label={item.seq !== 0 && t("ph_settle")}
                  id={String(item.seq)}
                  sx={{
                    backgroundColor: "white",
                    minWidth: "200px",
                    gridColumn: "3/5",
                  }}
                  value={item.settle_amt}
                  onChange={handleInputAmountEvent}
                  disabled={!showAddRecordBtn}
                />
              </section>
            </Box>
          </Box>

          <>
            {showAddRecordBtn && item.seq !== 0 && (
              <div className={style.deleteBtn}>
                <Button id={String(item.seq)} onClick={handleDeleteItems}>
                  X
                </Button>
              </div>
            )}
          </>
        </div>
      ))}
    </>
  );
};

export default ApprovalRegulation;
