import omit from "lodash/omit";
import TableTab from "../../../components/TableTab";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  SelectChangeEvent,
  Container,
  Box,
} from "../../../components/MuiGenerals";
import {
  SingleSelection,
  MultiSelection,
} from "../../../components/GeneralSelection";
import { listMappingTransform, enumMapping } from "../../../utils/helper";
import { useState } from "react";
import {
  IportalUserOperStatus,
  IportalUserYubiKeyStatus,
  IportalUsersParams,
  Ipage,
} from "../../../api/types";
import { customSx } from "../../../utils/styling";
import {
  enumPortalUserOperStatus,
  enumPortalUserYubiKeyStatus,
} from "../../../utils/constant";
import UserApprovalList from "./UserApprovalList";
import { useTranslation } from "../../../hooks";
import GeneralBtn from "../../../components/GeneralBtn";
import { createZusInstance } from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";

const translatePrefix = "userApprovalManagement";
const Enum = {
  portalUserYubiKeyStatus: enumMapping(enumPortalUserYubiKeyStatus),
  portalUserOperStatus: enumMapping(enumPortalUserOperStatus),
};
export default function UserApproval() {
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <UserApprovalList {...{ listMapping }} />
        </TableTab>
      </Container>
    </Box>
  );
}
interface Ifields {
  email: string;
  oper_status: "" | IportalUserOperStatus;
  yubi_key_statuses: IportalUserYubiKeyStatus[];
  pub_id: string;
}

const initFields = {
  email: "",
  oper_status: "",
  yubi_key_statuses: [] as IportalUserYubiKeyStatus[],
  pub_id: "",
} as const;
const initZusParams = {
  page: 0,
  pageSize: 20,
  oper_statuses: [] as IportalUserOperStatus[],
  ...omit(initFields, "oper_status"),
} as const;
export const useZusParams = createZusInstance<IportalUsersParams & Ipage>(
  initZusParams
);
export function FilterBar() {
  const [fields, setFields] = useState<Ifields>(initFields);
  const { t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const onChange =
    (type: keyof Ifields) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      if (type === "pub_id")
        return setFields({ ...fields, pub_id: value.slice(0, 12) });
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onArraySelectChange =
    (name: keyof Ifields) => (e: SelectChangeEvent<string[]>) => {
      const { value } = e.target;
      const newValue = typeof value === "string" ? value.split(",") : value;
      return setFields((fields) => ({ ...fields, [name]: newValue }));
    };
  const onSelectChange =
    (type: keyof Ifields) => (e: SelectChangeEvent<string>) => {
      const { value } = e.target;
      return setFields((fields: any) => ({
        ...fields,
        [type]: value,
      }));
    };
  const unselectClicked = (key: keyof Ifields) => {
    setFields((fields) => ({ ...fields, [key]: initFields[key] }));
  };
  const onSearch = () => {
    const { oper_status } = fields;
    const oper_statuses = oper_status ? [oper_status] : [];
    zusParams.setBody({ ...fields, oper_statuses, page: 0 });
    zusParams.refetch();
  };
  const onReset = () => {
    setFields(initFields);
  };
  const F = genField({ t }, [
    [
      "login_email",
      <MpTextField
        value={fields.email}
        onChange={onChange("email")}
        label={t("ph_login_email")}
      />,
    ],
    [
      "yubi_id",
      <MpTextField
        value={fields.pub_id}
        onChange={onChange("pub_id")}
        label={t("ph_yubi_id")}
      />,
    ],
    [
      "approval_permission",
      <SingleSelection
        label={t("ph_approval_permission")}
        value={fields.oper_status}
        onChange={onSelectChange("oper_status")}
        enumData={enumPortalUserOperStatus}
        clearSelect={() => unselectClicked("oper_status")}
      />,
    ],
    [
      "yubikey_status",
      <MultiSelection
        label={t("ph_yubikey_status")}
        value={fields.yubi_key_statuses}
        onChange={onArraySelectChange("yubi_key_statuses")}
        enumData={enumPortalUserYubiKeyStatus}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.login_email}
        {F.approval_permission}
        {F.yubi_id}
        {F.yubikey_status}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: { omitKeys: string[]; t: any; te: any }
): any[][] => {
  const { omitKeys = [], t, te } = config || {};
  const res = array.map((item: any) => {
    const mappedResult = [
      ["email", t("email"), item.email],
      [
        "oper_status",
        t("approval_permission"),
        te(Enum.portalUserOperStatus[item.oper_status]),
      ],
      ["pub_id", t("yubi_id"), item.pub_id],
      [
        "yubi_key_status",
        t("yubi_key_status"),
        te(Enum.portalUserYubiKeyStatus[item.yubi_key_status]),
      ],
      [
        "metaData",
        "",
        {
          oper_status: String(item.oper_status),
          yubi_key_status: String(item.yubi_key_status),
        },
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
