//ApprovalWindow

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";
import { useTranslate } from "react-admin";
import {
  enumApprovalStatus,
  enumNewApprovalStatus,
} from "../../utils/constant";
import { enumMapping, toDisplayTime } from "../../utils/helper";
import { useZusDialogStore } from "../../zustand/store";
import { Box } from "../MuiGenerals";

export interface ItransactionApprovalDetails {
  seq: number;
  operated_by: string;
  approval_result: number;
  operated_date: string;
}

interface ApproverDetail {
  id: string;
  general_proposal_id: string;
  status: number;
  last_modified_by: string;
  last_modified_date: Date;
  general_proposal_general_proposal_details: string;
}

const ApprovalProgress = () => {
  const zusDialog = useZusDialogStore();
  const rawData = zusDialog.meta?.rawData || {};
  const approversArr: ApproverDetail[] | undefined =
    rawData?.general_proposal?.general_proposal_details;
  const translate = useTranslate();
  const t = (key: string, configAmt = {}) =>
    translate(`assetManagement.${key}`, configAmt);

  return (
    <Box>
      <div style={{ fontSize: "12.5px", paddingLeft: "4px" }}>
        {t("approval_op")} ({approversArr?.length}/
        {rawData?.general_proposal?.no_of_approvers})
      </div>
      <div
        style={{ borderBottom: "solid #F1F2F4 1px", margin: "10px 0 10px 0" }}
      ></div>
      <Table
        aria-label="table"
        sx={{ mb: 3, borderCollapse: "separate", borderSpacing: "0px 4px" }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: "none" }}>{t("seq")}</TableCell>
            <TableCell sx={{ border: "none" }}>{t("operated_by")}</TableCell>
            <TableCell sx={{ border: "none" }}>{t("operated_date")}</TableCell>
            <TableCell sx={{ border: "none" }}>
              {t("approval_result")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {approversArr
            ?.sort((a: any, b: any) => {
              const aDate = new Date(a.last_modified_date);
              const bDate = new Date(b.last_modified_date);
              return aDate.getTime() - bDate.getTime();
            })
            .map((row: any, i: any) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  background: "#EAEAEA",
                }}
              >
                <TableCell>{i + 1} </TableCell>
                <TableCell>{row.last_modified_by}</TableCell>
                <TableCell>{toDisplayTime(row.last_modified_date)}</TableCell>
                <TableCell
                  style={{
                    color:
                      String(row.status) == enumNewApprovalStatus.Approved
                        ? "#3dd598"
                        : "#FF0000",
                  }}
                >
                  {translate(`enumConstants.${enumApprovalStatus[row.status]}`)}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ApprovalProgress;
