import TextField, { TextFieldProps } from "@mui/material/TextField";
import { ChangeEvent, RefObject } from "react";
import { inputCharacterMaxLength } from "../utils/constant";
import { containsOnlyNumbers } from "../utils/helper";

type MpTextFieldProps = TextFieldProps & {
  innerRef?: RefObject<HTMLDivElement>;
};
export default function MpTextField(props: MpTextFieldProps) {
  const { innerRef, onChange, ...rest } = props;
  const newProps = {
    ...rest,
    ref: innerRef,
    inputProps: { maxLength: inputCharacterMaxLength, ...props.inputProps },
  };

  const onChangeOnlyNumber = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!containsOnlyNumbers(e.target.value)) {
      return;
    }
    if (onChange) {
      onChange(e);
    }
  };

  return <TextField {...newProps} onChange={onChangeOnlyNumber} />;
}
