import OpTextButton from "../../components/OpTextButton";
import {
  approveWithDrawApprovalSetting,
  rejectWithDrawApprovalSetting,
} from "../../api/signer";
import {
  useAlerting,
  usePermission,
  useTranslation,
  useYubiPrompt,
} from "../../hooks";
import { displayAmount, getDecimal } from "../../utils/helper";
import { customSx } from "../../utils/styling";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
  zusRefetchStore,
} from "../../zustand/store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../MuiGenerals";
import { FeatureCodes, enumAdjustmentStatus } from "../../utils/constant";
import React from "react";
import style from "./regulationDialogCss.module.css";
import MpTextField from "../MpTextField";
import { useAppSelector } from "../../reducer/hooks";
import { selectProfile } from "../../reducer/profileSlice";
import YubiPrompter from "../Prompter";

import GeneralBtn from "../GeneralBtn";
const translatePrefix = "withdrawApprovalSetting";
const Pkey = FeatureCodes.assetManagement.WithdrawApprovalSetting.TabRecord;
const ApproveOrRejectDialog = () => {
  const { alerting } = useAlerting();
  const { email } = useAppSelector(selectProfile);
  //{- - translation- -}
  const { t, translate } = useTranslation(translatePrefix);
  const configTime = useWithDrawApprovalSettingStore(
    (state) => state.configTime
  );
  //{- -Reduxt Tools - -}
  const { hasPermission } = usePermission();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  //{- - Zustand Tools- -}
  const refetFunc = zusRefetchStore((state) => state.refetchFunc);

  const zusDialog = useZusDialogStore();
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const recordWatch = useStore.recordWatch;
  /* separate two array from a single array by wallet_type,  */
  const withdrawArr = recordWatch.withdraw_approval_regulation_requests?.filter(
    (item) => !item.wallet_type
  );
  const settleMemetwArr =
    recordWatch.withdraw_approval_regulation_requests?.filter(
      (item) => item.wallet_type
    );
  /*Finally Merge */
  const regulationArr = mergeArrays(withdrawArr, settleMemetwArr);
  /* separate two array from a single array by wallet_type,  */
  const getProperValues = (value: string) => {
    const decimal = getDecimal(recordWatch);
    const properAmount = displayAmount(value, decimal);
    return properAmount;
  };
  // {- - - - Params for send to backend- - - - -}
  const params = {
    id: recordWatch.id ? Number(recordWatch.id) : "",
  };
  //
  //{- - - -handle confirm - - - -}
  const onApprove = async () => {
    const isCreator = recordWatch.created_by === email;
    if (!hasPermission(Pkey.ApproveRecord)) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    if (isCreator) {
      return alerting("error", t("alert_creator_approver_same"));
    }
    const resp = await getResAfterYubi(approveWithDrawApprovalSetting, params);

    if (resp) {
      alerting("success", t("ConfirmApprove"));
      refetFunc({ where: "withdrawSettingReordTab" });
      zusDialog.closeAll();
    }
  };
  //{- -handle Reject  - -}
  const onReject = async () => {
    if (!hasPermission(Pkey.RejectRecord)) {
      return alerting("error", t("alert_no_approval_permission"));
    }
    const resp = await getResAfterYubi(rejectWithDrawApprovalSetting, params);

    if (resp) {
      alerting("success", t("ConfirmReject"));
      refetFunc({ where: "withdrawSettingReordTab" });
      zusDialog.closeAll();
    }
  };
  //{- - - -Debug - - - -}
  const pendingStatus = translate(
    `enumWithDrawApprovalSetting.${enumAdjustmentStatus[0].toLowerCase()}`
  );
  const isPendingStatus = recordWatch.status == pendingStatus;

  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.match("approvalDialog")}
        onClose={zusDialog.close}
        fullWidth={true}
      >
        <DialogTitle>{t("withDrawApprovalSettingDialog")}</DialogTitle>
        <DialogContent>
          <MpTextField
            variant="filled"
            defaultValue={recordWatch?.chain_name}
            fullWidth={true}
            label={t("chain_name")}
            disabled={true}
          />
          <br />
          <MpTextField
            variant="filled"
            defaultValue={recordWatch?.asset_name}
            fullWidth={true}
            label={t("chain_asset")}
            disabled={true}
          />
          {/* Divide Line */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
              }}
            >
              <div style={{ fontSize: "12px", minWidth: "170px" }}>
                {t("before_amt")}
              </div>
              <div style={{ fontSize: "12px", minWidth: "170px" }}>
                {t("after_amt")}
              </div>
            </div>
          </div>
          <HorizotalDiplayFormat
            beforeAmt={recordWatch?.existing_hourly_auto_approval_limit}
            afterAmt={recordWatch?.hourly_auto_approval_limit}
            labelName={t("max_hourly_approve_limit", configTime)}
          />
          <HorizotalDiplayFormat
            beforeAmt={recordWatch?.existing_mx_hourly_limit}
            afterAmt={recordWatch.reqEditMaxNoTrxAmt}
            labelName={t("mx_hourly_limit", configTime)}
          />
          <HorizotalDiplayFormat
            beforeAmt={recordWatch?.existing_to_addr_hourly_limit}
            afterAmt={recordWatch?.reqEditMaxLimitWithin1Hr}
            labelName={t("mx_addr_hrly_limit", configTime)}
          />

          <HorizotalDiplayFormat
            beforeAmt={recordWatch?.existing_to_addr_hourly_count}
            afterAmt={recordWatch.reqEditMaxNoTrxs}
            labelName={t("mx_addr_hrly_count", configTime)}
          />
          <br />
          {/*  */}
          <div style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            {t("regulation")}
          </div>
          <section
            style={{
              display: "grid",
              gridTemplateColumns: "minmax(100px, 140px ) 2.3fr 5fr",
              marginBottom: "1rem",
            }}
          >
            <div className={style.color}>{t("noOfApprover")}</div>
            <div className={style.color}>{t("type")}</div>
            <div className={style.color}>{t("amountIslargerThan")}</div>
          </section>
          <section className={style.beforeAfterTextContainer}>
            <div className={style.beforeEdit}>{t("before_amt")}</div>
            <div className={style.afterEdit}>{t("after_amt")}</div>
          </section>
          {regulationArr?.map((item) => {
            return (
              <section
                id="gridContainerSection"
                key={item.id}
                className={style.recordsWatchRegulation}
              >
                <div className={style.noOfApprover}>{item.seq + 1}</div>
                <div>{t("withdraw")}</div>
                <div>{getProperValues(item.withdraw_existing_amt!)}</div>
                <div>{getProperValues(item.withdraw_request_amt)}</div>
                <div> {t("settlement")}</div>
                <div>{getProperValues(item.settle_existing_amt!)}</div>
                <div>{getProperValues(item.settle_request_amt)}</div>
              </section>
            );
          })}
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <GeneralBtn
            label="approve"
            isHidden={
              !(
                isPendingStatus &&
                (hasPermission(Pkey.ApproveRecord) ||
                  hasPermission(Pkey.RejectRecord))
              )
            }
            onClick={onApprove}
          />
          <GeneralBtn
            label="reject"
            isHidden={
              !(
                isPendingStatus &&
                (hasPermission(Pkey.ApproveRecord) ||
                  hasPermission(Pkey.RejectRecord))
              )
            }
            onClick={onReject}
          />
          <GeneralBtn label="cancel" color="error" onClick={zusDialog.close} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(ApproveOrRejectDialog);

const HorizotalDiplayFormat = (props: {
  beforeAmt: string;
  afterAmt: string;
  labelName: string;
  row?: any;
}) => {
  const { beforeAmt, afterAmt, labelName } = props;
  return (
    <section
      // key={item.id}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div
        style={{
          minWidth: "200px",
          maxWidth: "200px",
          fontSize: "11px",
          whiteSpace: "pre-wrap",
          alignSelf: "center",
        }}
      >
        {labelName}
      </div>
      <MpTextField
        name={"before_amt"}
        variant="filled"
        defaultValue={beforeAmt}
        sx={{
          textAlign: "center",
          marginRight: "1.5px",
          "& .MuiInputBase-input": {
            minHeight: "35px",
            minWidth: "150px",
            transform: "translateY(-9px)",
          },
        }}
        disabled={true}
      />
      <MpTextField
        name={"after_amt"}
        variant="filled"
        defaultValue={afterAmt}
        sx={{
          marginLeft: "1.5px",
          "& .MuiInputBase-input": {
            minHeight: "35px",
            minWidth: "150px",
            transform: "translateY(-9px)",
          },
        }}
        disabled={true}
      />
    </section>
  );
};

export const mergeArrays = (firstArr: Array<any>, secondArr: Array<any>) => {
  // Concatenate the two arrays
  const withdrawArrWithNewFieldNames = firstArr.map((item: any) => {
    const newObj = { ...item };
    newObj["withdraw_request_amt"] = newObj["amount"];
    newObj["withdraw_existing_amt"] = newObj["existing_amount"];
    delete newObj["amount"];
    delete newObj["existing_amount"];
    delete newObj["wallet_type"];
    return newObj;
  });
  const settleArrWithNewFieldNames = secondArr.map((item: any) => {
    const newObj = { ...item };
    newObj["settle_request_amt"] = newObj["amount"];
    newObj["settle_existing_amt"] = newObj["existing_amount"];
    delete newObj["amount"];
    delete newObj["existing_amount"];
    delete newObj["wallet_type"];
    return newObj;
  });
  const mergedArr = withdrawArrWithNewFieldNames.concat(
    settleArrWithNewFieldNames
  );

  const mergedMap = new Map();
  mergedArr.forEach((element: any) => {
    // Get the seq property
    const seq = element.seq;
    // Check if this seq is already in the map
    if (mergedMap.has(seq)) {
      // If it is, merge the current object with the existing one
      const existingObj = mergedMap.get(seq);
      const mergedObj = {
        id: element.id || existingObj.id,
        seq: seq,
        withdraw_request_amt:
          element.withdraw_request_amt || existingObj.withdraw_request_amt,
        withdraw_existing_amt:
          element.withdraw_existing_amt || existingObj.withdraw_existing_amt,
        settle_request_amt:
          element.settle_request_amt || existingObj.settle_request_amt,
        settle_existing_amt:
          element.settle_existing_amt || existingObj.settle_existing_amt,
        withdraw_approval_regulation_request_fk:
          element.withdraw_approval_regulation_request_fk ||
          existingObj.withdraw_approval_regulation_request_fk,
        withdraw_threshold_request_id:
          element.withdraw_threshold_request_id ||
          existingObj.withdraw_threshold_request_id,
      };
      mergedMap.set(seq, mergedObj);
    } else {
      // If it's not, add the current object to the map
      mergedMap.set(seq, element);
    }
  });
  // Convert the map back to an array and return it
  const mergedArrWithNoDuplicates = Array.from(mergedMap.values());

  return mergedArrWithNoDuplicates ? mergedArrWithNoDuplicates : [];
};

/*    <section key={item.id} className={style.recordWatchRegulation}>
                <div style={{ alignSelf: "center", fontSize: "13px" }}>
                  <div style={{ gridArea: "no", textAlign: "center" }}>
                    {item.seq + 1}
                  </div>
                </div>
                <section
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    justifySelf: "center",
                  }}
                >
                  <div>
                    <div
                      style={{
                        gridArea: "type",
                        fontSize: "12px",
                      }}
                    >
                      {t("withdraw")}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        gridArea: "type",
                        fontSize: "12px",
                      }}
                    >

                      {t("settlement")}
                    </div>
                  </div>
                </section>
                <section>
                  <div style={{ marginRight: "2.5px" }}>
                    <MpTextField
                      InputLabelProps={{ shrink: true }}
                      name={"amountBeforeEdit"}
                      variant="filled"
                      defaultValue={getProperValues(
                        item.withdraw_existing_amt!
                      )}
                      sx={{ gridArea: "amtBefore", width: "100%" }}
                      label={t("existing_amount")}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginRight: "2.5px" }}>
                    <MpTextField
                      InputLabelProps={{ shrink: true }}
                      name={"amountBeforeEdit"}
                      variant="filled"
                      defaultValue={getProperValues(item.settle_existing_amt!)}
                      sx={{ gridArea: "amtBefore", width: "100%" }}
                      label={t("existing_amount")}
                      disabled={true}
                    />
                  </div>
                </section>

                <section>
                  <div style={{ marginLeft: "2.5px" }}>
                    <MpTextField
                      InputLabelProps={{ shrink: true }}
                      name={"requestEditAmount"}
                      variant="filled"
                      defaultValue={getProperValues(item.withdraw_request_amt)}
                      sx={{ gridArea: "amtAfter", width: "100%" }}
                      label={t("edited_amount")}
                      disabled={true}
                    />
                  </div>
                  <div style={{ marginLeft: "2.5px" }}>
                    <MpTextField
                      InputLabelProps={{ shrink: true }}
                      name={"requestEditAmount"}
                      variant="filled"
                      defaultValue={getProperValues(item.settle_request_amt)}
                      sx={{ gridArea: "amtAfter", width: "100%" }}
                      label={t("edited_amount")}
                      disabled={true}
                    />
                  </div>
                </section>
              </section> */
