import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslate } from "react-admin";
import { useQuery } from "react-query";

import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
} from "@mui/x-data-grid";
import { FeatureCode } from "@wallet-manager/node-types";

import {
  getLedgerTransactionRecord,
  postLedgerTransactionRecordResend,
} from "../../../api/merchant";
import { CustomPagination } from "../../../components/CustomPagination";
import LoadingDialog from "../../../components/LoadingDialog";
import { Tooltip } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import OpTextButton from "../../../components/OpTextButton";
import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";
import { dataGridDefaults } from "../../../utils/constant";
import { copyToClipboard } from "../../../utils/helper";
import { getFilteredColumns, useGenGridCol } from "../../../utils/HelperComp";
import { useZusParams } from "./LedgerTransactionRecord";

const Pkey = FeatureCode.FeaturesLedger.LedgerTransactionRecord;

const translatePrefix = "ledgerTransactionRecord";
const api = {
  getLedgerTransactionRecord,
  postLedgerTransactionRecordResend,
};
export default function LedgerTransactionRecordList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
}) {
  const { t, te } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const zusParams = useZusParams();
  const { listMapping, setCount } = props;
  const listRes = useQuery({
    queryKey: [
      "ledgerTransactionRecord",
      zusParams.body,
      zusParams.refetchCounter,
    ],
    queryFn: () => api.getLedgerTransactionRecord(zusParams.body),
  });
  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  const { hasPermission } = usePermission();

  const CopyableWalletAddress =
    (direction: "from" | "to") => (params: GridCellParams) => {
      const { sending_wallet_address, receiving_wallet_address } = params.row;
      const address =
        direction === "from"
          ? sending_wallet_address
          : receiving_wallet_address;
      const onClickHandler = () => {
        copyToClipboard(address);
        alerting("success", translate("report.copied"));
      };
      return (
        <Tooltip title={address}>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "#4A90F7",
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={onClickHandler}
          >
            {address}
          </div>
        </Tooltip>
      );
    };

  useEffect(() => setCount(count), [listRes]);

  const renderOperationCell = (params: GridRenderCellParams) => {
    const {
      original_merchant_order_id,
      merchant_order_id,
      id: trans_id,
      direction: directionNum,
    } = params.row["rawData"];

    const hasOriginalOrderId = !!original_merchant_order_id;
    const direction = String(directionNum);

    const handleResend = async () => {
      const res = await api.postLedgerTransactionRecordResend({
        merchant_order_id,
        trans_id,
        direction,
        include_original_merchant_order_id: hasOriginalOrderId,
      });

      if (!res) {
        return;
      }

      alerting("success", t("resendSuccess"));
      zusParams.refetch();
    };

    return <OpTextButton text={t("resend")} onClick={handleResend} />;
  };

  const columns: GridColDef[] = [
    useGenGridCol("operation", t("operation"), {
      minWidth: 120,
      renderCell: renderOperationCell,
    }),
    useGenGridCol("ledger_transaction_id", t("ledger_transaction_id"), {
      minWidth: 150,
    }),
    useGenGridCol("original_order_id", t("original_order_id"), {
      minWidth: 150,
    }),
    useGenGridCol("merchant_order_id", t("merchant_order_id"), {
      minWidth: 150,
    }),
    useGenGridCol("client_id", t("client_id"), { minWidth: 150 }),
    useGenGridCol("transaction_type", t("transaction_type"), { minWidth: 100 }),
    useGenGridCol("direction", t("direction"), { minWidth: 100 }),
    useGenGridCol("sending_client_id", t("sending_client_id"), {
      minWidth: 150,
    }),
    useGenGridCol("sending_wallet_address", t("sending_wallet_address"), {
      minWidth: 200,
      renderCell: CopyableWalletAddress("from"),
    }),
    useGenGridCol("receiving_client_id", t("receiving_client_id"), {
      minWidth: 150,
    }),
    useGenGridCol("receiving_wallet_address", t("receiving_wallet_address"), {
      minWidth: 200,
      renderCell: CopyableWalletAddress("to"),
    }),
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 150 }),
    useGenGridCol("currency", t("currency")),
    useGenGridCol("transaction_amount", t("transaction_amount"), {
      minWidth: 150,
    }),
    useGenGridCol("post_balance", t("post_balance"), { minWidth: 150 }),
    useGenGridCol("freeze_amount", t("freeze_amount"), { minWidth: 150 }),
    useGenGridCol("post_frozen_balance", t("post_frozen_balance"), {
      minWidth: 150,
    }),
    useGenGridCol("creation_time", t("creation_time"), { minWidth: 150 }),
    useGenGridCol("remarks", t("remarks"), { minWidth: 150 }),
  ];

  const { filteredColumns } = getFilteredColumns(columns, [
    { shouldFilter: !hasPermission(Pkey.Resend), fieldName: "operation" },
  ]);

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    filteredColumns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={count}
        columns={filteredColumns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}
