import { useEffect } from "react";
import {
  Container,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import { ChainSingleSelection } from "../../../components/ChainSelection";
import { AssetMultiSelection } from "../../../components/AssetSelection";
import { useTranslate } from "react-admin";
import { useState } from "react";
import { useAssets, useChains } from "../../../components/FetchConfig";
import { customSx } from "../../../utils/styling";
import { DivideLine, genField } from "../../../utils/HelperComp";
import {
  enumPushDepositMessage,
  enumWithdrawStatus,
  enumWithdrawStatusCol,
  tableConfig,
} from "../../../utils/constant";
import {
  findChainInfo,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { useZusDialogStore, useZusListStore } from "../../../zustand/store";
import AssetListGrid from "./AssetListGrid";
import DialogContents from "./DialogContents";
import { getAssetList } from "../../../api/merchant";
import { IInitFields, initFields, translatePrefix } from "./assetListTypes";
import GeneralBtn from "../../../components/GeneralBtn";

import { useTabs, useTranslation } from "../../../hooks";
const { pageSize } = tableConfig;
const AssetList = () => {
  const { tc } = useTranslation(translatePrefix);
  const tabsArr = [{ name: tc("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <AssetListGrid listMapping={listMapping} />
        </Tabs>

        <DialogContents />
      </Container>
    </Box>
  );
};
//{- - Debug- -}

export function FilterBar() {
  const [fields, setFields] = useState<IInitFields>(initFields);
  const zusList = useZusListStore();
  const page = zusList.meta.page;
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { selectChainByPieces } = useChains();
  const zusDialog = useZusDialogStore();
  const { selectAssetNamesByChain } = useAssets();
  const setAssets = (asset_name: string[]) =>
    setFields((fields) => ({ ...fields, asset_name }));
  const chain_name = fields.chain_name;
  const assetsNames = selectAssetNamesByChain({ chain_name });
  useEffect(() => {
    setAssets([]);
  }, [chain_name]);
  const getParams = () => {
    const { asset_name, chain_name, withdraw_status, push_deposit_message } =
      fields;
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    return {
      chain_type,
      chain_id,
      asset_names: asset_name,
      // withdraw_status: withdraw_status ? withdraw_status : [],
      asset_config_deposit_push:
        push_deposit_message === "1" || push_deposit_message === "-1"
          ? push_deposit_message === "1"
          : undefined,
      asset_config_status: withdraw_status || undefined,
      page: page,
      pageSize: pageSize,
    };
  };
  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getAssetList({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
    }
    fetch();
  }, [page, zusDialog.main]);
  const handleClick = (value: string, filterField: string) => {
    if (filterField === "withdraw_status" && fields.withdraw_status === value) {
      setFields((fields) => ({
        ...fields,
        withdraw_status: "",
      }));
    } else if (
      filterField === "push_deposit_message" &&
      fields.push_deposit_message === value
    ) {
      setFields((fields) => ({
        ...fields,
        push_deposit_message: "",
      }));
    }
  };
  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    zusList.setPage(0);
    setCacheParams(params);
    const res = await getAssetList(params);
    zusDialog.closeExtra();
    if (!res) return;
    zusList.setGeneralList(res);
  };
  const onReset = () => setFields(initFields);
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    return setFields((fields: any) => ({
      ...fields,
      [type]: value,
    }));
  };
  const F = genField({ t }, [
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_name}
        allItems={assetsNames}
      />,
    ],
    [
      "push_deposit_message",
      <FormControl>
        <InputLabel>{t("ph_push_deposit_message")}</InputLabel>
        <Select
          name="push_deposit_message"
          value={fields.push_deposit_message}
          onChange={onSelectChange("push_deposit_message")}
        >
          {Object.entries(enumPushDepositMessage).map(([name, value]) => {
            const newName = name as keyof typeof enumPushDepositMessage;
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={(e) => handleClick(value, "push_deposit_message")}
              >
                {t(`${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
    [
      "withdraw_status",
      <FormControl>
        <InputLabel>{t("phWithdraw_status")}</InputLabel>
        <Select
          name="withdraw_status"
          value={fields.withdraw_status}
          onChange={onSelectChange("withdraw_status")}
        >
          {Object.entries(enumWithdrawStatus).map(([name, value]) => {
            const newName = name as keyof typeof enumWithdrawStatus;
            return (
              <MenuItem
                key={value}
                value={value}
                onClick={(e) => handleClick(value, "withdraw_status")}
              >
                {t(`${newName}`)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}></Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.chain_name}
        {F.asset_name}
        {F.push_deposit_message}
        {F.withdraw_status}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const flippedEnumPushDepositMessage = Object.fromEntries(
    Object.entries(enumPushDepositMessage).map(([key, value]) => [value, key])
  );

  const res = array.map((item: any) => {
    const mappedResult = [
      ["push_deposit_message", "", item.asset_config_deposit_push ? "1" : "-1"],
      ["asset_config_id", "", item.asset_config_id],
      ["withdraw_status", "", String(item.asset_config_status)],
      [
        "rawData",
        "",
        {
          chain_type: item.chain_type,
          chain_id: item.chain_id,
          // withdraw_status: item.withdraw_status,
        },
      ],
      ["id", "", item.id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["decimals", t("decimals"), item.decimal],
      [
        "push_deposit_message_display",
        t("push_deposit_message"),
        t(
          `${
            flippedEnumPushDepositMessage[
              item.asset_config_deposit_push ? "1" : "-1"
            ]
          }`
        ),
      ],
      [
        "withdraw_status_display",
        t("withdraw_status"),
        t(`${enumWithdrawStatusCol[item.asset_config_status]}`),
      ],
      ["last_operated_by", t("last_operated_by"), item.operated_by],
      [
        "last_operation_time",
        t("last_operation_time"),
        toDisplayTime(item.operated_date),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};

export default AssetList;
