import React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useZusTabsStore } from "../../../zustand/store";
import { useTranslation } from "../../../hooks";
const translatePrefix = "ledgerWalletManagerDepositRecord";
const StatusButtonGroup = () => {
  const [alignment, setAlignment] = React.useState("0");
  const { t } = useTranslation(translatePrefix);
  const zusTabsStore = useZusTabsStore((state) => state);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    zusTabsStore.setGridTabsEumn(Number(newAlignment));
  };

  const statusButtonArray = [
    {
      value: "0",
      name: "showAllRecord",
    },
    {
      value: "1",
      name: "showToDoRecord",
    },
    {
      value: "2",
      name: "showCompleted",
    },
  ];

  return (
    <ToggleButtonGroup
      color="primary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      {statusButtonArray.map((item, index) => {
        return (
          <ToggleButton key={index} value={item.value}>
            {t(item.name)}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default StatusButtonGroup;
