import React from "react";
import { useTranslate } from "react-admin";
import { Box, Tab, Tabs } from "../../../components/MuiGenerals";
import { usePermission } from "../../../hooks";
import { FeatureCodes } from "../../../utils/constant";
import { useZusListStore } from "../../../zustand/store";
import RequestRecordTab from "./RequestRecordTab";
import CheckingTimeSettingTab from "./CheckingTimeSettingTab";

const Pkey = FeatureCodes.assetManagement.WithdrawApprovalCheckingTimeSetting;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const WithdrawApprovalCheckingTimeSetting = () => {
  const [tabsIndex, setTabsIndex] = React.useState(0);
  const clearZusList = useZusListStore((state) => state.clear);
  const translate = useTranslate();
  const { hasPermission } = usePermission();
  const t = (key: string) =>
    translate(`withdrawApprovalCheckingTimeSetting.${key}`);
  const handleChange = (event: React.SyntheticEvent, tabsEnum: number) => {
    setTabsIndex(tabsEnum);
    clearZusList();
  };
  const operationTabs = [
    {
      name: "checkingTimeSettingTab",
      path: Pkey.TabSetting.prefix,
      jsxNode: <CheckingTimeSettingTab />,
    },
    {
      name: "requestRecordTab",
      path: Pkey.TabRecord.prefix,
      jsxNode: <RequestRecordTab />,
    },
  ].filter((item) => hasPermission(item.path) && true);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        paddingTop: "2rem",
      }}
    >
      <Tabs
        sx={{ margin: "0 1.5rem", borderBottom: "solid #E1E4EA 1px" }}
        value={tabsIndex}
        onChange={handleChange}
      >
        {operationTabs.map((item, index) => {
          return (
            <Tab key={index} label={t(`${item.name}`)} {...a11yProps(index)} />
          );
        })}
      </Tabs>
      {/* {- -Tab ContentComponent- - -} */}
      {operationTabs.map((item, index) => {
        return (
          <TabPanel key={index} value={tabsIndex} index={index}>
            {item.jsxNode}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default WithdrawApprovalCheckingTimeSetting;
