import { debounce } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-admin";

import SearchIcon from "@mui/icons-material/Search";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Popper from "@mui/material/Popper";
import { WalletType } from "@wallet-manager/node-types/dist/src/postgres/const";

import { getWalletManagement } from "../api/merchant";
import { OpAddressBook } from "../assets/icons";
import { useChains } from "./FetchConfig";
import MpTextField from "./MpTextField";
import { Box, Button, InputAdornment, MenuItem, Tooltip } from "./MuiGenerals";
import OpIconButtonWrapped from "./OpIconButtonWrapped";
import WalletTypeIcon from "./WalletTypeIcon";

// import CircularProgress from "@mui/material/CircularProgress";


type itemFace = {
  to_address: string;
  amount: string;
  display_name: string;
  wallet_type: string;
  to_wallet_tag: string;
};
const initOrder = {
  to_address: "",
  amount: "",
  to_wallet_tag: "",
  wallet_type: "",
  display_name: "",
};
const sx = {
  walletAddressInputBox: {
    div: {
      padding: 0,
    },
  },
  addressBookIcon: {
    marginLeft: 0,
    marginRight: "0.5rem",
    width: "40px",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  addressBookFilter: {
    minHeight: 0,
    height: 24,
    ">div": {
      width: "16px",
    },
    span: {
      display: "none",
    },
  },
  addressBookResultMenuItem: {
    display: "flex",
    justifyContent: "space-between",
    ">div:first-of-type": {
      width: "70%",
    },
  },
};

const tabBtnStyles: { selectedTabBtn: any; notSelectedTabBtn: any } = {
  selectedTabBtn: {
    border: "1px solid #cccccc",
    borderRadius: "20px",
    marginRight: 8,
    marginBottom: 8,
    fontSize: 9,
    padding: "3px 5px",
    minHeight: 0,
    height: 24,
    backgroundColor: "#1976d2",
    color: "#ffffff",
    textTransform: "none",
  },
  notSelectedTabBtn: {
    border: "1px solid #1976d2",
    color: "#1976d2",
    borderRadius: "20px",
    marginRight: 8,
    marginBottom: 8,
    fontSize: 9,
    padding: "3px 5px",
    minHeight: 0,
    height: 24,
    textTransform: "none",
  },
};

export interface AddressBookIconData {
  display_name: string;
  wallet_type: string;
}

export default function AddressBookSuggestion(props: {
  item: itemFace;
  isAllowFreeInput: boolean;
  isDisabled?: boolean;
  walletTypes: string[];
  chain_name: string;
  setField: (name: keyof typeof initOrder, value: string) => void;
}) {
  const { item, walletTypes, chain_name, setField, isAllowFreeInput, isDisabled = false} = props;

  const allWalletTypesStringRef = useRef(walletTypes.join(", "));

  const [openAddressBook, setOpenAddressBook] = useState(false);

  const walletAddressRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();
  const t = (key: string, variable?: any) =>
    translate(`walletManagement.${key}`, variable);
  const [tabValue, setTabValue] = useState<string>(
    allWalletTypesStringRef.current
  );

  const previousTabValue = useRef("");
  const searchResultPopper = useRef<HTMLDivElement>(null);

  const handleButtonsChange = async (newValue: string) => {
    if (newValue === previousTabValue.current) {
      return;
    }
    searchResultPopper.current?.scrollTo({ top: 0 });
    setTabValue(newValue);
    previousTabValue.current = newValue;
    setList({ ...list, isFetchingData: true });
  };

  const id = openAddressBook ? "simple-popper" : undefined;

  const { selectChainByPieces } = useChains();
  interface InitList {
    count: number;
    rows: any[];
    isFetchingData: boolean;
  }
  const initList: InitList = {
    count: -1,
    rows: [],
    isFetchingData: false,
  };

  const [list, setList] = useState<InitList>(initList);

  const [isScrolling, setIsScrolling] = useState(false);
  const handleEndScrolling = debounce(() => setIsScrolling(false), 1000);

  const [inputField, setInputField] = useState("");
  const getParams = () => {
    const { chain_type, chain_id } = selectChainByPieces({ chain_name });
    const walletTypesArr = tabValue.split(", ");
    return {
      chain_type,
      chain_id,
      statuses: [1],
      wallet_types: walletTypesArr.length > 1 ? walletTypesArr : [tabValue],
      pageSize: 50,
      page: 0,
    };
  };
  const timeoutIDRef = useRef<NodeJS.Timeout>();

  const isSearchingOrInputingRef = useRef<"" | "searching" | "inputting">("");

  const getFetchResult = async (config: "search" | "input") => {
    clearTimeout(timeoutIDRef.current);

    const params = getParams();

    if (config === "search") {
      setList({ ...list, isFetchingData: true });
    }
    const timeoutID = setTimeout(async () => {
      const res = await getWalletManagement(
        config === "search"
          ? {
              ...params,
              searchField: [inputField.trim()],
            }
          : {
              ...params,
              wallet_address: inputField.trim(),
            }
      );
      if (!res) {
        return;
      }

      if (config === "search") {
        setList({ ...res, isFetchingData: false });
      } else {
        const dataInAddressBook: {
          display_name: string;
          wallet_type: number;
        } = res?.rows.length > 1 ? undefined : res?.rows[0];
        setField(
          "display_name",
          dataInAddressBook ? dataInAddressBook.display_name : ""
        );
        setField(
          "wallet_type",
          dataInAddressBook ? String(dataInAddressBook.wallet_type) : ""
        );
        isSearchingOrInputingRef.current = "";
      }
    }, 300);
    timeoutIDRef.current = timeoutID;
  };

  const getIconSearchOnClose = async () => {
    const params = getParams();

    const res = await getWalletManagement({
      ...params,
      wallet_address: inputField.trim(),
    });

    const dataInAddressBook: {
      display_name: string;
      wallet_type: number;
    } = res?.rows.length > 1 ? undefined : res?.rows[0];

    if (!isAllowFreeInput && !dataInAddressBook) {
      setField("to_address", "");
    }

    setField(
      "display_name",
      dataInAddressBook ? dataInAddressBook.display_name : ""
    );
    setField(
      "wallet_type",
      dataInAddressBook ? String(dataInAddressBook.wallet_type) : ""
    );
  };

  const getWallets = async () => {
    const params = getParams();
    setList({ ...list, isFetchingData: true });
    const res = await getWalletManagement(params);
    if (!res) {
      return;
    }
    setList({ ...res, isFetchingData: false });
  };

  useEffect(() => {
    if (isSearchingOrInputingRef.current === "inputting") {
      getFetchResult("input");
    } else if (isSearchingOrInputingRef.current === "searching") {
      if (inputField) {
        getFetchResult("search");
        return;
      }
      clearTimeout(timeoutIDRef.current);
      if (tabValue === allWalletTypesStringRef.current) {
        return setList(initList);
      }
      getWallets();
    }
  }, [inputField, tabValue, isSearchingOrInputingRef.current]);

  const handleCloseAddressBookForClickAway = async (
    event: Event | React.SyntheticEvent
  ) => {
    if (
      walletAddressRef.current &&
      walletAddressRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    await handleCloseAddressBook();
  };

  const handleOpenAddressBook = () => {
    setOpenAddressBook(true);
    isSearchingOrInputingRef.current = "searching";
  };

  const handleCloseAddressBook = async (
    selectedSearchResultValue?: AddressBookIconData
  ) => {
    setOpenAddressBook(false);
    if (selectedSearchResultValue) {
      for (const field in selectedSearchResultValue) {
        const typedField = field as "display_name" | "wallet_type";
        setField(typedField, selectedSearchResultValue[typedField]);
      }
    } else {
      await getIconSearchOnClose();
    }

    isSearchingOrInputingRef.current = "";
    previousTabValue.current = "";
    setList(initList);
    setTabValue(allWalletTypesStringRef.current);
  };

  const walletTypeIconColorArr = [
    "#3B5DB9",
    "#3BAFB9",
    "#583BB9",
    "#3B8DB9",
    "#7AB93B",
    "#973BB9",
  ];

  const onInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (!openAddressBook) {
      isSearchingOrInputingRef.current = "inputting";
    }
    setField("to_address", e.target.value);
    // searchResultPopper.current?.scrollTo({ top: 0 });
  };

  useEffect(() => {
    if (openAddressBook) {
      const searchInput: any =
        walletAddressRef.current?.firstChild?.firstChild?.nextSibling!;
      searchInput.focus();
    }
  }, [openAddressBook]);

  useEffect(() => {
    setInputField(item.to_address);
  }, [item.to_address]);

  const SearchResultMenuItems = (item: any) => (
    <MenuItem
      onClick={() => {
        setField("to_address", item.wallet_address);
        handleCloseAddressBook({
          display_name: item.display_name,
          wallet_type: item.wallet_type,
        });
      }}
      sx={sx.addressBookResultMenuItem}
      key={item.id}
    >
      <WalletTypeIcon
        addressBookIconData={{
          display_name: item.display_name,
          wallet_type: item.wallet_type,
        }}
        isScrolling={isScrolling}
        walletTypeIconColorArr={walletTypeIconColorArr}
        childElement={
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {item.display_name}
          </span>
        }
      />
      <Tooltip title={isScrolling ? "" : item.wallet_address}>
        <div>
          {item.wallet_address.length > 9
            ? `${item.wallet_address.slice(0, 5)}...${item.wallet_address.slice(
                -4
              )}`
            : item.wallet_address}
        </div>
      </Tooltip>
    </MenuItem>
  );

  const sequencedSearchResultListArrMemo = useMemo(
    () => [
      ...list.rows.filter((item) => item.wallet_type === 5),
      ...list.rows.filter((item) => item.wallet_type === 0),
      ...list.rows.filter((item) => item.wallet_type === 2),
      ...list.rows.filter((item) => item.wallet_type === 4),
      ...list.rows.filter((item) => item.wallet_type === 3),
      ...list.rows.filter((item) => item.wallet_type === 1),
    ],
    [list]
  );

  return (
    <>
      <MpTextField
        disabled={
          !openAddressBook && !isAllowFreeInput || isDisabled
        }
        innerRef={walletAddressRef}
        placeholder={openAddressBook ? t("phAddressBookSearch") : undefined}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"} sx={sx.addressBookIcon}>
              <OpIconButtonWrapped
                size="1.5rem"
                title={""}
                isDisabled={isDisabled}
                url={OpAddressBook}
                onClick={() => {
                  if (!openAddressBook) {
                    handleOpenAddressBook();
                  } else {
                    handleCloseAddressBook();
                  }
                }}
              />
            </InputAdornment>
          ),
          startAdornment: openAddressBook ? (
            <SearchIcon style={{ margin: "20px 4px 0 12px", opacity: "50%" }} />
          ) : (
            isSearchingOrInputingRef.current === "" &&
            item.display_name !== "" &&
            item.wallet_type !== "" && (
              <WalletTypeIcon
                addressBookIconData={{
                  display_name: item.display_name,
                  wallet_type: item.wallet_type,
                }}
                walletTypeIconColorArr={walletTypeIconColorArr}
              />
            )
          ),
        }}
        value={item.to_address}
        sx={
          isAllowFreeInput
            ? sx.walletAddressInputBox
            : {
                ...sx.walletAddressInputBox,
                ">div": {
                  input: { WebkitTextFillColor: "#000!important" },
                  backgroundColor: openAddressBook
                    ? undefined
                    : "#fff!important",
                },
              }
        }
        onChange={onInputChange}
        onClick={
          openAddressBook
            ? undefined
            : isAllowFreeInput
            ? undefined
            : handleOpenAddressBook
        }
        aria-describedby={id}
      />
      <ClickAwayListener onClickAway={handleCloseAddressBookForClickAway}>
        <Popper
          id={id}
          open={openAddressBook}
          anchorEl={walletAddressRef.current}
          sx={{
            height: "31vh",
            ">div": {
              width: `${walletAddressRef.current?.offsetWidth}px!important`,
            },
            zIndex: 1500,
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
              overflowY: "scroll",
              height: "31vh",
              borderRadius: "4px",
              bgcolor: "background.paper",
              boxShadow:
                "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
            }}
            onScroll={() => {
              setIsScrolling(true);
              handleEndScrolling();
            }}
            ref={searchResultPopper}
          >
            <div
              style={{
                position: "sticky",
                top: 0,
                background: "#FFFFFF",
                zIndex: 1,
                padding: "8px 16px 0px 16px",
              }}
            >
              <Button
                style={
                  tabValue === allWalletTypesStringRef.current
                    ? tabBtnStyles.selectedTabBtn
                    : tabBtnStyles.notSelectedTabBtn
                }
                onClick={() =>
                  handleButtonsChange(allWalletTypesStringRef.current)
                }
              >
                {t("allWallet")}
              </Button>

              {walletTypes.map((item, index) => (
                <Button
                  style={
                    tabValue === item
                      ? tabBtnStyles.selectedTabBtn
                      : tabBtnStyles.notSelectedTabBtn
                  }
                  onClick={() => {
                    handleButtonsChange(item);
                  }}
                  key={index}
                >
                  {translate(`enumConstants.${WalletType[Number(item)]}`)}
                </Button>
              ))}

              {list.count > 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row-reverse",
                    color: "#aaa",
                    fontSize: "12px",
                    marginTop: "-8px",
                    marginBottom: "2px",
                  }}
                >
                  <div>
                    {list.count > 1
                      ? t("searchResultMultipleCounts", {
                          total: list.count,
                          display: list.rows.length,
                        })
                      : t("searchResultSingleCount", {
                          total: list.count,
                          display: list.rows.length,
                        })}
                  </div>
                </div>
              )}
              <hr style={{ padding: 0, marginTop: 0, marginBottom: 0 }} />
            </div>

            {
              /* list.isFetchingData ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "70%",
                  width: "100%",
                }}
              >
                <CircularProgress
                // style={{ position: "absolute", top: "50%", left: "45%" }}
                />
              </div>
            ) : */ tabValue === allWalletTypesStringRef.current &&
              !inputField &&
              isSearchingOrInputingRef.current === "searching" ? (
                <MenuItem disabled style={{ opacity: "100%" }}>
                  {t("emptySearchFieldNotice")}
                </MenuItem>
              ) : list.isFetchingData || !!list.rows.length ? (
                sequencedSearchResultListArrMemo.map((item) =>
                  SearchResultMenuItems(item)
                )
              ) : (
                <MenuItem disabled style={{ opacity: "100%" }}>
                  {WalletType[Number(tabValue)]
                    ? translate(
                        `enumConstants.no_related_wallet_type_address`,
                        {
                          walletType: translate(
                            `enumConstants.${WalletType[Number(tabValue)]}`
                          ),
                        }
                      )
                    : translate(`enumConstants.no_related_wallet_address`)}
                </MenuItem>
              )
            }
          </Box>
        </Popper>
      </ClickAwayListener>
    </>
  );
}
