import { useState } from 'react';
import { useTranslate } from 'react-admin';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';

import { createWalletAddress } from '../../api/merchant';
import { useAlerting } from '../../hooks';
import { enumWalletType } from '../../utils/constant';
import { customSx } from '../../utils/styling';
import { useChains } from '../FetchConfig';
import MpTextField from '../MpTextField';

interface WalletAssignmentFieldsFace {
  wallet_name: string;
  walletType: string;
  chain_name: string;
  client_id: string;
}

const initAssignmentFields = {
  wallet_name: "",
  walletType: "",
  chain_name: "",
  client_id: "",
};

export default function AssignWalletAddressDialog(props: any) {
  const { openAssignWallet, setOpenAssignWallet, refresh } = props;
  const { alerting } = useAlerting();
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const [fields, setFields] =
    useState<WalletAssignmentFieldsFace>(initAssignmentFields);
  const { chainObj, selectChainByPieces } = useChains();
  const assignWalletTypeArr = ["1", "2", "3"];
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };

  const onSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFields((fields) => ({ ...fields, [name]: value }));
  };
  const { chain_id, chain_type } = selectChainByPieces({
    chain_name: fields.chain_name,
  });

  const onConfirmAssignAddress = async () => {
    if (!fields.wallet_name) {
      alerting("error", t("walletNameError"));
    } else if (!fields.walletType) {
      alerting("error", t("walletTypeError"));
    } else if (!fields.chain_name) {
      alerting("error", t("chainTypeError"));
    } else {
      const params = {
        chain_type: chain_type,
        chain_id: chain_id,
        wallet_name: fields.wallet_name,
        wallet_type: fields.walletType,
        client_id:
          fields.walletType === "1"
            ? fields.client_id || fields.wallet_name
            : undefined,
      };
      const { address, wallet_tag, x_address, is_existing_wallet }: any =
        (await createWalletAddress(params)) || {};

      const successMessage = is_existing_wallet
        ? t("wallet_address_already_existed")
        : t("assignWalletSuccess");

      const getTranslateValueWithColon = (translateKey: string) =>
        t(translateKey) + ": ";

      // For assigning XRP chain
      if (address && wallet_tag && x_address) {
        alerting(
          "success",
          successMessage +
            "\n" +
            getTranslateValueWithColon("wallet_address") +
            address +
            "\n" +
            getTranslateValueWithColon("wallet_tag") +
            wallet_tag +
            "\n" +
            getTranslateValueWithColon("x_address") +
            x_address
        );
      } else if (address) {
        alerting(
          "success",
          successMessage +
            "\n" +
            getTranslateValueWithColon("wallet_address") +
            address
        );
      } else {
        return;
      }

      setFields(initAssignmentFields);
      setOpenAssignWallet(false);
      refresh();
    }
  };

  return (
    <>
      <Dialog
        open={openAssignWallet}
        onClose={() => {
          setOpenAssignWallet(false);
          setFields(initAssignmentFields);
        }}
        fullWidth={true}
      >
        <DialogTitle>{t("createAddress")}</DialogTitle>
        <DialogContent>
          <MpTextField
            fullWidth={true}
            label={t("walletNameError")}
            onChange={onChange("wallet_name")}
          />
          <br />
          <FormControl fullWidth={true}>
            <InputLabel>{t("walletTypeError")}</InputLabel>
            <Select
              name="walletType"
              value={fields.walletType}
              onChange={onSelectChange}
              defaultValue=""
            >
              {Object.entries(enumWalletType)
                .filter(([key, val]) => assignWalletTypeArr.includes(key))
                .map(([key, value], i) => (
                  <MenuItem key={i} value={key}>
                    {translate(`enumConstants.${value}`)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <br />
          <FormControl fullWidth={true}>
            <InputLabel id="select-label">{t("chainTypeError")}</InputLabel>
            <Select name="chain_name" defaultValue="" onChange={onSelectChange}>
              {Object.entries(chainObj).map(([key, { name }], i) => (
                <MenuItem key={key} value={key}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <MpTextField
            fullWidth={true}
            label={t("clientIdError")}
            onChange={onChange("client_id")}
            disabled={!chain_type || fields.walletType !== "1"}
          />
          <br />
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={onConfirmAssignAddress}
          >
            {t("confirm")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => setOpenAssignWallet(false)}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
