import TableTab from "../../components/TableTab";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import BalanceCheckpointList from "./BalanceCheckpointList";
import { tableConfig, enumCertifiedBalanceObj } from "../../utils/constant";
import {
  Container,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useDatePicker } from "../../components/DatePicker";
import { AssetMultiSelection } from "../../components/AssetSelection";
import { ChainSingleSelection } from "../../components/ChainSelection";
import { useTranslate } from "react-admin";
import { useState } from "react";
import { walletCheckPoint } from "../../api/merchant";
import { useAssets, useChains } from "../../components/FetchConfig";
import { customSx } from "../../utils/styling";
import {
  displayAmount,
  findChainInfo,
  getDecimal,
  getFullApiResponse,
  downloadFiles,
  toDisplayTime,
  listMappingTransform,
  onlyAllowInteger,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { useZusDialogStore, useZusListStore } from "../../zustand/store";
import BigNumber from "bignumber.js";
import GeneralBtn from "../../components/GeneralBtn";
import MpTextField from "../../components/MpTextField";
import { useAlerting } from "../../hooks";

interface fieldsFace {
  chain_name: string;
  asset_names: string[];
  checkpoint_id: string;
  is_certified_balance: "" | "true" | "false";
  walletAddress: string;
  block_no: string;
}

const rawInitFields = {
  chain_name: "",
  asset_names: [],
  checkpoint_id: "",
  is_certified_balance: "",
  walletAddress: "",
  block_no: "",
};
export default function BalanceCheckpoint() {
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <BalanceCheckpointList listMapping={listMapping} />
        </TableTab>
      </Container>
    </Box>
  );
}
const defaultDates = {
  start: { defaultDate: null },
  end: { defaultDate: null },
};
export function FilterBar() {
  const zusList = useZusListStore();
  const [searchedParams] = useSearchParams();
  const checkpoint_id = searchedParams.get("checkpointId") || "";
  const initFields = { ...rawInitFields, checkpoint_id } as fieldsFace;
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const translate = useTranslate();
  const t = (key: string) => translate(`tools.${key}`);
  const tc = (key: string) => translate(`common.${key}`);
  const { alerting } = useAlerting();
  const { selectChainByPieces } = useChains();
  const { selectAssetNamesByChain } = useAssets();
  const DateObj = {
    Checking: useDatePicker(defaultDates),
    Creation: useDatePicker(defaultDates),
  };
  const allAssetNames = selectAssetNamesByChain({
    chain_name: fields.chain_name,
  });
  const setAssets = (assets: string[]) => {
    setFields((fields) => ({ ...fields, asset_names: assets }));
  };
  useEffect(() => {
    setAssets([]);
  }, [fields.chain_name]);
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      const name = e.target.name;
      const resp = name === "block_no" ? onlyAllowInteger(value) : true;
      if (!resp) {
        return;
      }

      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const value = e.target.value;
    setFields((fields) => ({ ...fields, [type]: value }));
  };
  const unselectClicked = (key: string) => {
    setFields((fields) => ({ ...fields, [key]: "" }));
  };
  function getParams() {
    const {
      chain_name,
      is_certified_balance,
      block_no,
      checkpoint_id,
      ...rest
    } = fields;
    const { chain_type, chain_id } = selectChainByPieces({
      chain_name: fields.chain_name,
    });
    const is_certified = {
      true: 1,
      false: 0,
      "": undefined,
    }[is_certified_balance];
    return {
      page: zusList.meta.page,
      pageSize: tableConfig.pageSize,
      chain_type,
      chain_id,
      is_certified,
      created_date_from: DateObj.Creation.start,
      created_date_to: DateObj.Creation.end,
      mark_time_from: DateObj.Checking.start,
      mark_time_to: DateObj.Checking.end,
      block_number: block_no,
      id: checkpoint_id,
      ...rest,
    };
  }

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    // onSearch();
    const asyncWrapper = async () => {
      zusDialog.openExtra("loadingDialog");
      const { rows = [], count = 0 } =
        (await walletCheckPoint({ ...cacheParams, page: params.page })) || {};
      zusDialog.closeExtra();
      zusList.setGeneralList({ rows, count });
    };
    asyncWrapper();
  }, [params.page]);

  const onSearch = async () => {
    zusDialog.openExtra("loadingDialog");
    zusList.setPage(0);
    setCacheParams(params);
    const { rows = [], count = 0 } =
      (await walletCheckPoint({ ...params, page: 0 })) || {};
    zusDialog.closeExtra();
    zusList.setGeneralList({ rows, count });
  };
  const onExport = async () => {
    if (zusList.count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiPagingFn = (page: number, pageSize: number, signal: any) =>
      walletCheckPoint({ ...params, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiPagingFn, zusList.count);
    if (rawRes.length === 0) return;
    const res = listMapping("name", rawRes, { translate });
    const config = {};
    downloadFiles(`On Chain Balance Checkpoint`, res, config);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Checking.clearDate();
    DateObj.Creation.clearDate();
  };
  const F = genField({ t }, [
    ["check_time", <DateObj.Checking.Picker type="dateTime" />],
    ["created_date", <DateObj.Creation.Picker type="dateTime" />],
    [
      "chain_name",
      <ChainSingleSelection
        label={t("phChain_name")}
        setChoice={(chain_name) => setFields((f) => ({ ...f, chain_name }))}
        choice={fields.chain_name}
      />,
    ],
    [
      "asset_name",
      <AssetMultiSelection
        label={t("phAsset_name")}
        setChoices={setAssets}
        choices={fields.asset_names}
        allItems={allAssetNames}
      />,
    ],
    [
      "checkpoint_id",
      <MpTextField
        value={fields.checkpoint_id}
        onChange={onChange("checkpoint_id")}
        label={t("ph_check_point_id")}
      />,
    ],
    [
      "is_certified_balance",
      <FormControl>
        <InputLabel>{t("ph_is_certified_balance")}</InputLabel>
        <Select
          value={fields.is_certified_balance}
          onChange={onSelectChange("is_certified_balance")}
        >
          {Object.entries(enumCertifiedBalanceObj).map(([id, name], i) => (
            <MenuItem
              key={i}
              value={id}
              onClick={() => unselectClicked("is_certified_balance")}
            >
              {translate(`enumConstants.${name}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "wallet_address",
      <MpTextField
        label={t("ph_wallet_address")}
        value={fields.walletAddress}
        onChange={onChange("walletAddress")}
      />,
    ],
    [
      "block_no",
      <MpTextField
        name="block_no"
        value={fields.block_no}
        type="string"
        onChange={onChange("block_no")}
        label={t("ph_blockNo")}
      />,
    ],
  ]);
  const showExport = true; // no permission

  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        <GeneralBtn label="export" isHidden={!showExport} onClick={onExport} />
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.check_time}
        {F.created_date}
        {F.chain_name}
        {F.asset_name}
        {F.checkpoint_id}
        {F.is_certified_balance}
        {F.wallet_address}
        {F.block_no}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <Button onClick={onReset} className="resetBtn" variant="contained">
          {t("reset")}
        </Button>
      </Box>
    </>
  );
}
const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`tools.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);
  const res = array.map((item: any) => {
    const decimal = getDecimal(item);
    const toAmount = (amount: number) => displayAmount(amount, decimal);

    const onChainBalance = new BigNumber(item.balance);
    const existSystemBalance = new BigNumber(
      item.transfer_transaction?.post_balance
    );
    const result = onChainBalance.minus(existSystemBalance).toFixed();
    const differenceBalance = displayAmount(result, decimal);
    const markDateCol = item.mark_time && [
      "mark_time",
      t("check_time"),
      toDisplayTime(item.mark_time),
    ];
    const mappedResult = [
      markDateCol,
      ["id", t("checkpoint_id"), item.id],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(item.chain_type, item.chain_id)?.name,
      ],
      ["asset_name", t("asset_name"), item.asset_name],
      ["wallet_address", t("wallet_address"), item.wallet_address],
      ["balance", t("onchain_balance"), toAmount(item.balance)],
      //
      [
        "systemBalance",
        t("systemBalance"),
        toAmount(item.transfer_transaction?.post_balance),
      ],
      ["differenceBalance", t("differenceBalance"), differenceBalance],

      [
        "is_certified",
        t("is_certified_balance"),
        item.is_certified === true ? te("yes") : te("no"),
      ],
      ["block_number", t("block_number"), item.block_number],
      ["block_time", t("block_time"), toDisplayTime(item.block_time)],
      ["created_date", t("created_date"), toDisplayTime(item.created_date)],
    ].filter((item) => item);
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
