// AdaUtxoTab
import TableTab from "../../../components/TableTab";
import { DivideLine, genField } from "../../../utils/HelperComp";
import { Container, Box, Button } from "../../../components/MuiGenerals";
import {
  enumMapping,
  filterValidPayload,
  findChainInfo,
  listMappingTransform,
  toDisplayTime,
} from "../../../utils/helper";
import { useEffect, useState } from "react";
import { customSx } from "../../../utils/styling";

import { useAlerting, usePermission, useTranslation } from "../../../hooks";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  useZusDialogStore,
  useZusListStore,
  zusRefetchStore,
  zusShowIconStore,
} from "../../../zustand/store";
import MpTextField from "../../../components/MpTextField";
import AdaUtxoTabList from "./AdaUtxoTabList";
import { useDatePicker } from "../../../components/DatePicker";
import {
  FeatureCodes,
  enumUtxoStatus,
  tableConfig,
} from "../../../utils/constant";
import { getAdaUtxo } from "../../../api/merchant";
import DeleteModal from "./DeleteModal";
import Modal from "./Modal";
const Pkey = FeatureCodes.tools.UTXOTool.TabADA;
const translatePrefix = "utxoManagement";
const { pageSize } = tableConfig;
const Enum = {
  status: enumMapping(enumUtxoStatus),
};
interface ILocalField {
  wallet_address: string;
  tx_hash: string;
  asso_tx_hash: string;
  date_created_from?: string;
  date_created_to?: string;
}

const initFields = {
  wallet_address: "",
  tx_hash: "",
  asso_tx_hash: "",
} as const;

const AdaUtxoTab = () => {
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);

  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <Modal AdaTab={true} />
        <DeleteModal type="ada" />
        <FilterBar
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <AdaUtxoTabList {...{ listMapping, isSearchBtnClick }} />
        </TableTab>
      </Container>
    </Box>
  );
};

export function FilterBar(props: any) {
  const { isSearchBtnClick, setSearchBtnClick } = props;
  const DateObj = {
    Created: useDatePicker(),
    LastModified: useDatePicker(),
  };
  const [fields, setFields] = useState<ILocalField>(initFields);
  const refetBool = zusRefetchStore((state) => state.refetchBoolean);
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const zusShowIcon = zusShowIconStore((state) => state.setShowIcon);
  const zusSetSearchHash = zusShowIconStore((state) => state.setSearchHash);
  const zusDialog = useZusDialogStore();
  const zusList = useZusListStore();
  const page = zusList.meta.page;
  const { t } = useTranslation(translatePrefix);

  const onChange =
    (type: keyof ILocalField) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((pre) => ({ ...pre, [type]: value }));
    };

  const params = {
    chain_type: 8,
    chain_id: 2,
    tx_hash: fields.tx_hash,
    address: fields.wallet_address,
    page,
    pageSize,
    created_date_from: DateObj.Created.start,
    created_date_to: DateObj.Created.end,
    last_modified_date_from: DateObj.LastModified.start,
    last_modified_date_to: DateObj.LastModified.end,
  };
  // console.log(params);
  const [cacheParam, setCacheParams] = useState(params);
  useEffect(() => {
    const necessaryCacheParams = filterValidPayload(cacheParam);
    if (!isSearchBtnClick) return;
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getAdaUtxo({ ...necessaryCacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      zusList.setGeneralList(res);
      if (fields.tx_hash) {
        zusShowIcon(true);
      } else {
        zusShowIcon(false);
      }
    }
    fetch();
  }, [page, cacheParam, isSearchBtnClick, refetBool]);

  const onSearch = async () => {
    if (!fields.wallet_address) {
      return alerting("warning", t("Adareminder"));
    }
    setSearchBtnClick(true);
    zusList.setPage(0);
    zusSetSearchHash(params.tx_hash);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Created.clearDate();
    DateObj.LastModified.clearDate();
  };
  const handleModal = () => {
    zusDialog.open("utxoDialog");
  };
  const F = genField({ t }, [
    [
      "create_time",
      <DateObj.Created.Picker
        type="dateTime"
        startLabel={t("phStartTime")}
        endLabel={t("phEndTime")}
      />,
    ],
    [
      "last_modified_time",
      <DateObj.LastModified.Picker
        type="dateTime"
        startLabel={t("phStartTime")}
        endLabel={t("phEndTime")}
      />,
    ],
    [
      "wallet_address",
      <MpTextField
        value={fields.wallet_address}
        onChange={onChange("wallet_address")}
        label={t("ph_wallet_address")}
      />,
    ],
    [
      "tx_hash",
      <MpTextField
        value={fields.tx_hash}
        onChange={onChange("tx_hash")}
        label={t("ph_tx_hash")}
        helperText={t("helperDelText")}
      />,
    ],
  ]);
  return (
    <>
      <Box sx={customSx.filterCmdBar}>
        {hasPermission(Pkey.SyncADAUTXO) && (
          <Button onClick={handleModal} variant="outlined">
            {t("sync_utxo")}
          </Button>
        )}
      </Box>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.create_time}
        {F.last_modified_time}
        {F.wallet_address}
        {F.tx_hash}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  config?: {
    omitKeys: string[];
    t: any;
    te: any;
    chain_id: string;
    chain_type: string;
  }
): any[][] => {
  const { omitKeys = [], t, chain_type, chain_id } = config || {};
  const res = array.map((item: any) => {
    const mappedResult = [
      ["wallet_address", t("wallet_address"), item.address],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(chain_type), chain_id!)?.name,
      ],
      ["tx_hash", t("tx_hash"), item.tx_hash],
      ["vout", t("vout"), item.v_out],
      ["spent_tx_hash", t("spent_tx_hash"), item.spent_tx_hash],
      ["status", t("status"), t(Enum.status[item.status])],
      ["creation_time", t("creation_time"), toDisplayTime(item.created_date)],
      [
        "last_modified_time",
        t("last_modified_time"),
        toDisplayTime(item.last_modified_date),
      ],
      [
        "metaData",
        "",
        {
          chain_type: chain_type,
          chain_id: chain_id,
          address: item.address,
          spent_tx_hash: item.spent_tx_hash,
          tx_hash: item.tx_hash,
          v_out: item.v_out,
        },
      ],
    ].filter(([key]) => !omitKeys.includes(key));
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
export default AdaUtxoTab;
