import { Dispatch, SetStateAction } from "react";

export interface Ifields {
  action: string;
  status: string[];
  operation_type: string;
  batchIdsString: string;
}

export type IParentProps = {
  fields: Ifields;
  setFields: Dispatch<SetStateAction<Ifields>>;
};

export const translatePrefix = "tools";
