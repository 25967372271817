import { ReactNode } from "react";
import { useTranslate } from "react-admin";
import { enumWalletType } from "../utils/constant";
import Tooltip from "@mui/material/Tooltip";
import { AddressBookIconData } from "./AddressBookSuggestion";

export default function WalletTypeIcon(props: {
  addressBookIconData: AddressBookIconData;
  isScrolling?: boolean;
  childElement?: ReactNode;
  walletTypeIconColorArr: string[];
}) {
  const translate = useTranslate();
  const {
    addressBookIconData,
    isScrolling,
    childElement,
    walletTypeIconColorArr,
  } = props;
  const t = (key: string) => translate(`walletManagement.${key}`);

  // const walletTypeIconColorArr = [
  //   "#3B5DB9",
  //   "#3BAFB9",
  //   "#583BB9",
  //   "#3B8DB9",
  //   "#7AB93B",
  //   "#973BB9",
  // ];

  const walletTypeIconColorMapping = (walletType: number) =>
    walletTypeIconColorArr[walletType];

  const hasChild = childElement !== undefined;

  return (
    <Tooltip
      title={
        isScrolling ? (
          ""
        ) : (
          <>
            <div>
              {t("display_name")}: {addressBookIconData.display_name}
            </div>
            <div>
              {t("wallet_type")}:{" "}
              {translate(
                `enumConstants.${
                  enumWalletType[String(addressBookIconData.wallet_type)]
                }`
              )}
            </div>
          </>
        )
      }
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            margin: "0 8px",
            marginLeft: hasChild ? "0px" : "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1rem",
            minWidth: "24px",
            maxWidth: "24px",
            minHeight: "24px",
            maxHeight: "24px",
            color: "#fff",
            borderRadius: "80%",
            cursor: "default",
            backgroundColor: walletTypeIconColorMapping(
              Number(addressBookIconData.wallet_type)
            ),
          }}
        >
          <div style={{ userSelect: "none" }}>
            {
              translate(
                `enumConstants.${
                  enumWalletType[String(addressBookIconData.wallet_type)]
                }`
              )[0]
            }
          </div>
        </div>
        {childElement}
      </div>
    </Tooltip>
  );
}
