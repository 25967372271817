import { Dispatch, SetStateAction, useEffect } from "react";
import { useQuery } from "react-query";

import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";

import { getDepositAndWithdrawalStatistics } from "../../../api/merchant";
import LoadingDialog from "../../../components/LoadingDialog";
import { Box } from "../../../components/MuiGenerals";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { useTranslation } from "../../../hooks";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusParams } from "./";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "depositAndWithdrawalStatistics";
const api = {
  getDepositAndWithdrawalStatistics,
};
export default function DepositAndWithdrawalStatisticsList(props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  setCount: Dispatch<SetStateAction<number>>;
  isFirstRender: boolean;
}) {
  const { t, te, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const { listMapping, setCount, isFirstRender } = props;
  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      if (isFirstRender) {
        return;
      }
      return api.getDepositAndWithdrawalStatistics(zusParams.body);
    },
  });
  const rows = (listRes.data as any) || [];
  const content: GridRowsProp = listMapping("key", rows, {
    t,
    te,
  });

  useEffect(() => setCount(rows.length), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol("chain_name", t("chain_name"), { minWidth: 150 }),
    useGenGridCol("currency", t("currency")),
    useGenGridCol(
      "deposit_number_of_transactions",
      t("deposit_number_of_transactions"),
      {
        minWidth: 150,
      }
    ),
    useGenGridCol(
      "deposit_total_transaction_amount",
      t("deposit_total_transaction_amount"),
      { minWidth: 150 }
    ),
    useGenGridCol(
      "withdraw_number_of_transactions",
      t("withdraw_number_of_transactions"),
      { minWidth: 150 }
    ),
    useGenGridCol(
      "withdraw_total_transaction_amount",
      t("withdraw_total_transaction_amount"),
      {
        minWidth: 150,
      }
    ),
  ];

  useEffect(() => () => zusParams.clear(), []);
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig()
  );
  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        paginationMode="client"
        components={{
          NoRowsOverlay: () => (
            <NoRowsOverlay
              customWording={
                isFirstRender ? tc("please_click_search") : undefined
              }
            />
          ),
          Footer: () => (
            <Box sx={{ backgroundColor: "white" }}>
              <br />
              <br />
            </Box>
          ),
        }}
      />
    </>
  );
}
