import React from "react";
import { rejectWithDrawApprovalSetting } from "../../api/signer";
import { useAlerting, useTranslation, useYubiPrompt } from "../../hooks";
import { customSx } from "../../utils/styling";
import {
  useZusDialogStore,
  useWithDrawApprovalSettingStore,
} from "../../zustand/store";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "../MuiGenerals";
import YubiPrompter from "../Prompter";
const translatePrefix = "withdrawApprovalSetting";
const RejectDialog = () => {
  const useStore = useWithDrawApprovalSettingStore((state) => state);
  const { t } = useTranslation(translatePrefix);
  const { alerting } = useAlerting();
  const { getResAfterYubi, prompterConfig } = useYubiPrompt();

  const recordWatch = useStore.recordWatch;
  const params = {
    id: recordWatch.id ? recordWatch.id : "",
  };
  const zusDialog = useZusDialogStore();
  const handleConfirmReject = async () => {
    const resp = await getResAfterYubi(rejectWithDrawApprovalSetting, params);

    if (resp) {
      alerting("success", t("ConfirmReject"));
    }
    zusDialog.closeAll();
  };
  const handleCancel = async () => {
    zusDialog.closeExtra();
  };
  return (
    <>
      <YubiPrompter {...prompterConfig} />
      <Dialog
        open={zusDialog.matchExtra("rejectDialog")}
        onClose={zusDialog.closeExtra}
        fullWidth={true}
      >
        <DialogTitle>{t("withDrawApprovalSettingDialog")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("rejectQuestion")}</DialogContentText>
        </DialogContent>
        <DialogActions sx={customSx.dialogBtnMargin}>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleConfirmReject}
          >
            {t("confirm")}
          </Button>
          <Button variant="contained" color="error" onClick={handleCancel}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(RejectDialog);
