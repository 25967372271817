import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { getAddressBookProposals } from "../../../api/merchant";
import { useDatePicker } from "../../../components/DatePicker";
import GeneralBtn from "../../../components/GeneralBtn";
import {
  Box,
  Container,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "../../../components/MuiGenerals";
import TableTab from "../../../components/TableTab";
import RequestRecordTabList from "../../../components/WalletManagement/RequestRecordTabList";
import {
  tableConfig,
  enumAddressBookApprovalStatus,
  enumWalletType,
  enumAddressBookProposalType,
} from "../../../utils/constant";
import {
  getDecimal,
  displayAmount,
  findChainInfo,
  toDisplayTime,
  listMappingTransform,
  enumMapping,
  sortItemsAlphabetically,
  findChainInfoByChainTypeOnly,
  separateBatchStrings,
} from "../../../utils/helper";
import { DivideLine, genField, useRefresh } from "../../../utils/HelperComp";
import { customSx } from "../../../utils/styling";
import { listFace } from "../../Tools/AuditLogTabView";
import MpTextField from "../../../components/MpTextField";
import { useZusDialogStore } from "../../../zustand/store";

const { pageSize } = tableConfig;
const Enum = {
  proposalType: enumMapping(enumAddressBookProposalType),
  approvalStatus: enumMapping(enumAddressBookApprovalStatus),
};
interface fieldsFace {
  creation_time: string;
  wallet_type: string;
  proposal_type: string;
  wallet_address: string;
  approval_status: string[];
}
const initFields = {
  creation_time: "",
  wallet_type: "",
  proposal_type: "",
  wallet_address: "",
  approval_status: [],
};

const walletTypeArr = ["0", "1", "2", "3", "4", "5"];
export default function RequestRecordTab() {
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const [refreshBool, refresh] = useRefresh();
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar {...{ setList, page, setPage, refreshBool }} />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <TableTab>
          <RequestRecordTabList {...{ list, listMapping, setPage, refresh }} />
        </TableTab>
      </Container>
    </Box>
  );
}

export function FilterBar(props: {
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
  setList: Dispatch<SetStateAction<listFace>>;
  refreshBool: boolean;
}) {
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const { setPage, page, setList, refreshBool } = props;
  const translate = useTranslate();
  const t = (key: string) => translate(`walletManagement.${key}`);
  const te = (key: string) => translate(`enumConstants.${key}`);

  const DateObj = {
    CreationTime: useDatePicker(),
  };
  const walletAddressesString = separateBatchStrings(fields.wallet_address);
  const params = {
    date_created_from: DateObj.CreationTime.start,
    date_created_to: DateObj.CreationTime.end,
    acts: fields.proposal_type ? [fields.proposal_type] : undefined,
    wallet_type: fields.wallet_type,
    wallet_address: walletAddressesString,
    statuses: fields.approval_status,
    page,
    pageSize,
  };
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    async function fetch() {
      zusDialog.openExtra("loadingDialog");
      const res = await getAddressBookProposals({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    }
    fetch();
  }, [page, refreshBool, cacheParams]);

  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const onSearch = async () => {
    setPage(0);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
  };

  const handleClick = (filterField: string) => {
    setFields((fields) => ({
      ...fields,
      [filterField]: "",
    }));
  };
  const onSelectChange = (type: string) => (e: SelectChangeEvent<string>) => {
    const { value } = e.target;
    return setFields((fields: any) => ({
      ...fields,
      [type]: value,
    }));
  };

  const onArraySelectChange = (
    e:
      | SelectChangeEvent<string[] | string>
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let newValue = typeof value === "string" ? value.split(",") : value;
    if (newValue.every((el) => el === "")) newValue = [];
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };

  const F = genField({ t }, [
    ["creation_time", <DateObj.CreationTime.Picker type="dateTime" />],
    [
      "wallet_type",
      <FormControl>
        <InputLabel id="select-wallet-label">
          {t("phWalletSingleType")}
        </InputLabel>
        <Select
          name="wallet_type"
          value={fields.wallet_type}
          onChange={onSelectChange("wallet_type")}
        >
          {sortItemsAlphabetically(Object.entries(enumWalletType))
            .filter(([key, value]: any) => walletTypeArr.includes(key))
            .map(([key, value]: any, i: string) => (
              <MenuItem key={key} value={Number(key)}>
                {translate(`enumConstants.${enumWalletType[String(key)]}`)}
              </MenuItem>
            ))}
        </Select>
      </FormControl>,
    ],
    [
      "proposal_type",
      <FormControl>
        <InputLabel id="select-proposal-label">
          {t("phProposalType")}
        </InputLabel>
        <Select
          name="proposal_type"
          value={fields.proposal_type}
          onChange={onSelectChange("proposal_type")}
        >
          {sortItemsAlphabetically(
            Object.entries(enumAddressBookProposalType)
          ).map(([key, value]: any, i: string) => (
            <MenuItem key={key} value={Number(key)}>
              {translate(
                `enumConstants.${enumAddressBookProposalType[String(key)]}`
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>,
    ],
    [
      "wallet_address",
      <MpTextField
        label={t("phWalletAddress")}
        value={fields.wallet_address}
        onChange={onChange("wallet_address")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {params.wallet_address.length}
            </InputAdornment>
          ),
        }}
      />,
    ],

    [
      "approval_status",
      <FormControl>
        <InputLabel>{t("phApproval_status")}</InputLabel>
        <Select
          multiple
          name="approval_status"
          value={fields.approval_status}
          onChange={onArraySelectChange}
        >
          {Object.entries(enumAddressBookApprovalStatus).map(
            ([value, name]) => {
              return (
                <MenuItem
                  key={value}
                  value={value}
                  onClick={(e) => handleClick("approval_status")}
                >
                  {te(`${name}`)}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>,
    ],
  ]);

  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.creation_time}
        {F.proposal_type}
        {F.wallet_type}
        {F.wallet_address}
        {F.approval_status}
      </Box>
      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}

const listMapping = (
  which: "key" | "name",
  array: any[],
  { translate }: any
): any[][] => {
  const t = (key: string) => translate(`walletManagement.${key}`);
  const tEnum = (key: string) => translate(`enumConstants.${key}`);

  const res = array
    .filter((item) => item.act !== -2)
    .map((item: any) => {
      const decimal = getDecimal(item);
      const toAmount = (amount: number) => displayAmount(amount, decimal);
      const {
        chainType: updated_chain_type,
        chainIds,
        displayName: wallet_name,
      } = JSON.parse(item.new_val);
      const old_chain_type = String(item.chain_type);

      const walletType = walletTypeArr.includes(String(item.wallet_type))
        ? tEnum(enumWalletType[String(item.wallet_type)])
        : "";
      const mappedResult = [
        ["proposal_no", t("proposal_no"), item.id],
        [
          "proposal_type",
          t("proposal_type"),
          translate(
            `enumConstants.${enumAddressBookProposalType[String(item.act)]}`
          ),
        ],
        ["wallet_name", t("wallet_name"), wallet_name || item.remark],
        [
          "chain_name",
          t("chain_name"),
          chainIds
            ? chainIds
                .map(
                  (chainId: any) =>
                    findChainInfo(updated_chain_type || old_chain_type, chainId)
                      ?.name
                )
                .join(", ")
            : findChainInfoByChainTypeOnly(updated_chain_type || old_chain_type)
                ?.name,
        ],
        ["wallet_address", t("wallet_address"), item.wallet_address],
        ["wallet_type", t("wallet_type"), walletType],
        [
          "approval_status",
          t("approval_status"),
          translate(`enumConstants.${Enum.approvalStatus[item.status]}`),
        ],
        ["created_by", t("created_by"), item.created_by],
        ["creation_time", t("creation_time"), toDisplayTime(item.date_created)],
        [
          "operated_by",
          t("operated_by"),
          item.appr_by1 || item.last_modified_by,
        ],
        [
          "operation_time",
          t("operation_time"),
          toDisplayTime(item.date_appr1 || item.date_last_modified),
        ],
      ];
      return mappedResult;
    });
  const output = res.map(listMappingTransform(which));
  return output;
};
