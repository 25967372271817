import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken } from "../api/auth";
import { login, selectProfile } from "../reducer/profileSlice";
import { store } from "../reducer/store";
import jwt_decode from "jwt-decode";
import {
  readAccessToken,
  setClientDateTimeDiff,
  writeTokens,
} from "../utils/helper";
import { useAppSelector } from "../reducer/hooks";

const dispatch = store.dispatch;
const Redirect = () => {
  const profile = useAppSelector(selectProfile);
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isInit, setIsInit] = useState(false);
  const authToken = searchParams.get("accessToken") || "";
  const noToken = !authToken || authToken.length === 0;
  const localAccessToken = readAccessToken();
  const isHaveAccessToken = localAccessToken && localAccessToken.length > 0;

  const writeTokenAndLoginInfo = (info: any) => {
    const {
      access_token,
      refresh_token,
      acceptance_level,
      chain_analysis,
      merchant_name,
      features,
      appUuid,
    } = info;

    writeTokens({ access_token, refresh_token });
    const featureArr = Object.keys(features)
      .map((f) => f.toLowerCase())
      .sort();

    const jwt = jwt_decode<any>(access_token);
    const { email, exp, iat } = jwt;

    setClientDateTimeDiff(iat);

    const loginParams = {
      featureList: featureArr,
      acceptance_level,
      chain_analysis,
      appName: merchant_name,
      email,
      expSecond: exp,
      appUuid,
    };
    dispatch(login(loginParams));
    setIsInit(true);
  };

  useEffect(() => {
    async function fetch() {
      if (noToken) {
        return;
      }

      const res = await getAccessToken(authToken, { ignoreToken: true });
      const { access_token } = res;

      if (!access_token) {
        return;
      }

      const jwt = jwt_decode<any>(access_token);
      const { email, appUuid } = jwt;

      if (isHaveAccessToken) {
        const isDiffUser = email !== profile.email;
        const isDiffMerchant = appUuid !== profile.appUuid;

        if (isDiffUser || isDiffMerchant) {
          return writeTokenAndLoginInfo({ ...res, appUuid });
        }

        setIsInit(true);
      } else {
        // fresh login
        writeTokenAndLoginInfo({ ...res, appUuid });
      }
    }

    fetch();
  }, []);

  useEffect(() => {
    if (profile.appName && localAccessToken) {
      if (isInit) {
        navigate("/");
      }
    }
  }, [profile.appName, localAccessToken, isInit]);

  if (noToken) return null;

  return <>redirecting</>;
};
export default Redirect;
