import { CustomPagination } from "../../../components/CustomPagination";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusListStore } from "../../../zustand/store";
import { useTranslation } from "../../../hooks";
import useYubiPrompt from "../../../hooks/useYubiPrompt";
import GenColumnBtn from "./GenColumnBtn";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";

const translatePrefix = "utxoManagement";
const BtcUtxoTabList = (props: {
  listMapping: (which: "key" | "name", array: any[], config?: any) => any[][];
  isSearchBtnClick: boolean;
}) => {
  const zusList = useZusListStore();
  const totalRecords = zusList.count;
  const { t, te } = useTranslation(translatePrefix);
  const { listMapping, isSearchBtnClick } = props;
  const content: GridRowsProp = listMapping("key", zusList.rows, {
    t,
    te,
    chain_type: zusList.chain_type,
    chain_id: zusList.chain_id,
  });
  const cellButton = (theField: string) => (params: GridRenderCellParams) => {
    const props = {
      params,
      theField,
      tab: "BTC",
    };
    return <GenColumnBtn {...props} />;
  };

  const columns: GridColDef[] = [
    useGenGridCol("wallet_address", t("wallet_address")),
    useGenGridCol("chain_name", t("chain_name")),
    useGenGridCol("tx_hash", t("tx_hash"), {
      minWidth: 500,
      renderCell: cellButton("tx_hash"),
    }),
    useGenGridCol("vout", t("vout"), { minWidth: 150 }),
    useGenGridCol("spent_tx_hash", t("spent_tx_hash"), {
      minWidth: 500,
      renderCell: cellButton("spent_tx_hash"),
    }),
    useGenGridCol("status", t("status")),
    useGenGridCol("creation_time", t("creation_time")),
    useGenGridCol("last_modified_time", t("last_modified_time"), {
      minWidth: 150,
    }),
  ];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig("btc")
  );
  return (
    <>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        columns={columns}
        rowCount={zusList.count}
        onPageChange={zusList.setPage}
        page={zusList.meta.page}
        components={{
          NoRowsOverlay: () => (
            <>
              {!isSearchBtnClick ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  <div>{t("reminder")}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "2rem",
                  }}
                >
                  {t("noRow")}
                </div>
              )}
            </>
          ),
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: totalRecords },
        }}
      />
    </>
  );
};

export default BtcUtxoTabList;
