import { useTranslate } from "react-admin";
import {
  GridRenderCellParams,
  DataGrid,
  GridColDef,
  GridRowsProp,
} from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { CustomPagination } from "../../../components/CustomPagination";
import { NoRowsOverlay } from "../../../components/NoRowsOverlay";
import { Box, Button, Container } from "../../../components/MuiGenerals";
import OpIconButton from "../../../components/OpIconButton";
import { FeatureCodes, dataGridDefaults } from "../../../utils/constant";
import { useGenGridCol } from "../../../utils/HelperComp";
import { useZusListStore } from "../../../zustand/store";
import { usePermission } from "../../../hooks";
import { OpView } from "../../../assets/icons";
import { useZusDialogStore, useAuditLogStore } from "../../../zustand/store";
import useColumnHide, {
  ColumnHiddenMessage,
  getColumnHideConfig,
} from "../../../hooks/useColumnHide";
const Pkey = FeatureCodes.tools.AuditLog;
const translatePrefix = "auditLog";
const AuditLogList = (props: {
  listMapping: any;
  operationTab:
    | "portal_operation_tab"
    | "api_in_operation_tab"
    | "api_out_operation_tab";
}) => {
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const { listMapping, operationTab } = props;
  const zusList = useZusListStore();
  const totalRecords = zusList.count;
  const content: GridRowsProp = listMapping("key", zusList.rows, { translate });
  const { hasPermission } = usePermission();
  //{- -RelatedLog Btn Permission- -}

  const canViewBtns = {
    portal: {
      details:
        operationTab === "portal_operation_tab" &&
        hasPermission(Pkey.TabPortalOperation.ViewDetails),
      log:
        operationTab === "portal_operation_tab" &&
        hasPermission(Pkey.TabPortalOperation.RelatedLogs),
    },
    apiIn: {
      details:
        operationTab === "api_in_operation_tab" &&
        hasPermission(Pkey.TabApiIn.ViewDetails),
      log:
        operationTab === "api_in_operation_tab" &&
        hasPermission(Pkey.TabApiIn.RelatedLogs),
    },
    apiOut: {
      details:
        operationTab === "api_out_operation_tab" &&
        hasPermission(Pkey.TabApiOut.ViewDetails),
    },
  };
  const cellButtons = (params: GridRenderCellParams) => {
    return <ActionButton {...{ params, canViewBtns }} />;
  };

  const columns: GridColDef[] = [
    useGenGridCol("serial_no", t("serial_no")),
    useGenGridCol("request_time", t("request_time")),
    useGenGridCol("operator", t("operator")),
    useGenGridCol("function_name", t("function_name"), { minWidth: 160 }),
    useGenGridCol("operation", t("operation")),
    useGenGridCol("batch_id", t("batch_id")),
    useGenGridCol("api_url", t("api_url"), { minWidth: 210 }),
    useGenGridCol("res_date", t("resposneTime")),
    useGenGridCol("1", t("viewDetailsOperation"), {
      minWidth: 200,
      renderCell: cellButtons,
    }),
  ].filter((col) => {
    if (col.field !== "1") return true;
    if (operationTab === "portal_operation_tab") {
      return canViewBtns.portal.details || canViewBtns.portal.log;
    }
    if (operationTab === "api_in_operation_tab") {
      return canViewBtns.apiIn.details || canViewBtns.apiIn.log;
    }
    if (operationTab === "api_out_operation_tab") {
      return canViewBtns.apiOut.details;
    }
  }) as GridColDef[];
  const { colsShown, setColsShown, hasColHidden, localeText } = useColumnHide(
    columns,
    getColumnHideConfig(operationTab)
  );
  return (
    <Container maxWidth={false} disableGutters>
      <ColumnHiddenMessage hasColHidden={hasColHidden} />
      <DataGrid
        {...dataGridDefaults}
        localeText={localeText}
        columnVisibilityModel={colsShown}
        onColumnVisibilityModelChange={setColsShown}
        rows={content}
        rowCount={totalRecords}
        columns={columns}
        page={zusList.meta.page}
        onPageChange={zusList.setPage}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords },
        }}
      />
    </Container>
  );
};

const ActionButton = (props: any) => {
  const { canViewBtns, params } = props;
  const translatePrefix = "auditLog";
  const translate = useTranslate();
  const t = (key: string) => translate(`${translatePrefix}.${key}`);
  const setOnPortalDialogFields = useAuditLogStore(
    (state) => state.setOnPortalDialogFields
  );
  const zusDialog = useZusDialogStore();
  const { api_url, res_date, rawData } = params.row;
  const handleViewDetails = () => {
    const dialogContent = {
      api_url,
      req_date: rawData.req_date,
      req_header: rawData.req_header,
      req_body: rawData.req_body,
      res: rawData.res,
      res_date,
    };
    setOnPortalDialogFields(dialogContent);
    zusDialog.open("onProtalOpDialog");
  };
  const handleRedirectToApiOut = () => {
    window.open(`auditLog?reqId=${rawData.req_id}`);
  };
  const isShowDetails =
    canViewBtns.portal.details ||
    canViewBtns.apiIn.details ||
    canViewBtns.apiOut.details;
  const isShowLog = canViewBtns.portal.log || canViewBtns.apiIn.log;
  return (
    <Box
      sx={{ justifyContent: "space-between", display: "flex", width: "72%" }}
    >
      {isShowDetails && (
        <OpIconButton
          title={t("view")}
          url={OpView}
          onClick={handleViewDetails}
        />
      )}
      {isShowLog && (
        <Tooltip title={t("relatedLog")}>
          <Button
            sx={{
              borderRadius: "18px",
              backgroundColor: "#4a90f7",
              height: "65%",
              width: "90px",
            }}
            variant="contained"
            onClick={handleRedirectToApiOut}
          >
            {t("relatedLog")}
          </Button>
        </Tooltip>
      )}
    </Box>
  );
};

export default AuditLogList;
